// php
import Vue from 'vue'
import * as API from "../index.js";
import {
  buildParam
} from '@/utils/index'
const BaseApi = process.env.VUE_APP_BASE_API
const SysApi = process.env.VUE_APP_SYS_API

// 任务看板-管理看板
const taskTerm = '/task/taskBasicsTarget/select/taskTerm'
// 查询对应角色权限
const role = '/task/taskMain/role'
// 任务看板-获取详情
const taskDetail = '/task/taskBasicsTarget/select/taskDetail'
// 任务看板-设置任务员工
const setTasks = '/task/taskMain/setUserTasks'
// 任务看板-设置任务市场
const setTasksMarket = '/task/taskBasicsTarget/save'

//3.0优化 客服部到店量明细
const validShopCustomerDetail  = "/task/taskBasicsTarget/validShopCustomerDetail"

// 1.8.53 获取用户不同角色展示的数据看板
const getRolePermission = "/backend/role/getRolePermission"
// 1.8.53 根据角色编码查询按钮资源的权限
const getButtonResource = "/backend/resource/getButtonResource"

export default {

  // 任务看板-管理看板
  taskTermApi: (params) => {
    buildParam(params)
    return API.POST(`${BaseApi}${taskTerm}`, params)
  },
  // 根据登录人查询角色等级
  roleApi: (params) => {
    return API.GET(`${BaseApi}${role}`, params)
  },
  // 任务看板-获取详情
  taskDetailApi: (params) => {
    buildParam(params)
    return API.POST(`${BaseApi}${taskDetail}`, params)
  },
  // 任务看板-设置任务-员工
  setTasksApi: (params) => {
    return API.POST(`${BaseApi}${setTasks}`, params)
  },
  // 任务看板-设置任务-市场
  setTasksMarketApi: (params) => {
    return API.POST(`${BaseApi}${setTasksMarket}`, params)
  },

  // 客服部到店量明细
  validShopCustomerDetailApi: (params) => {
    buildParam(params)
    return API.POST(`${BaseApi}${validShopCustomerDetail}`, params)
  },

  // 获取用户不同角色展示的数据看板
  getRolePermissionApi: (params) => {
    return API.GET(`${SysApi}${getRolePermission}`, params)
  },
  // 根据角色编码查询按钮资源的权限
  getButtonResourceApi: (params) => {
    return API.GET(`${SysApi}${getButtonResource}`, params)
  },
}
