<template>
  <div style="background: #f5f5f5">
    <headerNav leftNav=" " @leftClick="back" title="任务管理中心">
      <template>
        <!-- <span class="headNav-righ-text" @click="openUserPermissionsPopup">
          {{ userPermissions.text }}<van-icon name="arrow-down" />
        </span> -->
        <!-- <span class="headNav-righ-text">{{ userPermissions.text }}</span> -->
        <span class="headNav-righ-icon">
          <van-badge max="99">
            <img src="../../../assets/task-management/approve-icon.png" alt="" @click="openApprove">
            <template #content v-if="approveNum > 0">{{ approveNum }}</template>
          </van-badge>
          <img src="../../../assets/task-management/adjust-icon.png" alt="" @click="openAdjust">
        </span>
      </template>
    </headerNav>
    <div class="page-box" v-if="permission('task-center:president')">
      <van-tabs v-model="tabActive" @click="onClickTab" color="#3DA2FF" :class="tabActive!=3?'':'van-tabs-region'">
        <van-tab title="本月任务" name="1">
          <ul class="output-tabs">
            <li @click="onClickMonthTabs('1')" :class="monthTabActive=='1'? 'output-tabs-active' : ''">转单产值</li>
            <li @click="onClickMonthTabs('2')" :class="monthTabActive=='2'? 'output-tabs-active' : ''">补齐产值</li>
          </ul>
          <ul class="task-content">
            <li class="task-content-li" v-for="(item,index) in dataList" :key="index">
              <div class="task-content-li-title">
                <div>
                  <img src="../../../assets/task-management/set-up-task-output-icon.png" alt="">
                  <span>{{ item.taskDate }}</span>
                  <span class="task-content-li-title-maket">{{ item.indexName }}</span>
                </div>
                <span class="task-content-li-title-button" @click="updataTask(item)">修改</span>
              </div>
              <div class="task-content-li-content">
                <ul class="task-content-li-content-complete-rate">
                  <li :class="item.finishRate&&item.finishRate>100?'grenn':''">{{ item.finishRate&&item.finishRate > 0?(item.finishRate).toFixed(2):"--" }}<span>%</span></li>
                  <li>任务完成率</li>
                </ul>
                <ul>
                  <li>{{ item.finishValue && item.finishValue > 0 ?(item.finishValue).toFixed(2):"--" }}<span>{{ item.unit }}</span></li>
                  <li>实际完成</li>
                </ul>
                <ul>
                  <li>{{ item.productionJobValue && item.productionJobValue > 0 ?(item.productionJobValue).toFixed(2):"--" }}<span>{{ item.unit }}</span></li>
                  <li>{{ item.taskName }}</li>
                </ul>
                <div class="task-content-li-content-divider1">
                  <div></div>
                  <div></div>
                </div>
                <div class="task-content-li-content-divider2"></div>
              </div>
            </li>
          </ul>
        </van-tab>
        <van-tab title="计划任务" name="2">
          <template #title>
            <van-badge v-if="planMonthCnt > 0 " :content="planMonthCnt" :max="99">
              <div>计划任务</div>
            </van-badge>
          </template>
          <ul class="output-tabs">
            <li @click="onClickplanTabs('1')" :class="planTabActive=='1'? 'output-tabs-active' : ''">转单产值</li>
            <li @click="onClickplanTabs('2')" :class="planTabActive=='2'? 'output-tabs-active' : ''">补齐产值</li>
          </ul>
          <ul class="task-content">
            <li class="task-content-li" v-for="(item,index) in dataList" :key="index">
              <div class="task-content-li-title">
                <div>
                  <img src="../../../assets/task-management/set-up-task-output-icon.png" alt="">
                  <span>{{ item.taskDate }}</span>
                  <span class="task-content-li-title-maket">{{ item.indexName }}</span>
                </div>
                <span class="task-content-li-title-button" @click="updataTask(item)">修改</span>
              </div>
              <div class="task-content-li-content">
                <ul class="task-content-li-content-complete-rate">
                  <li>{{ item.productionJobValue && item.productionJobValue > 0 ?(item.productionJobValue).toFixed(2):"--" }}<span>{{ item.unit }}</span></li>
                  <li>{{ item.taskName }}</li>
                </ul>
                <ul>
                  <li>{{ item.finishValue && item.finishValue > 0 ?(item.finishValue).toFixed(2):"--" }}<span>{{ item.unit }}</span></li>
                  <li>实际完成</li>
                </ul>
                <ul>
                  <li>{{ item.finishRate&&item.finishRate > 0?(item.finishRate).toFixed(2):"--" }}<span>%</span></li>
                  <li>任务完成率</li>
                </ul>
                <div class="task-content-li-content-divider1">
                  <div></div>
                  <div></div>
                </div>
                <div class="task-content-li-content-divider2"></div>
              </div>
            </li>
          </ul>
        </van-tab>
        <van-tab title="历史任务" name="3">
          <ul class="output-tabs">
            <li @click="onClickhistoryTabs('1')" :class="historyTabActive=='1'? 'output-tabs-active' : ''">转单产值</li>
            <li @click="onClickhistoryTabs('2')" :class="historyTabActive=='2'? 'output-tabs-active' : ''">补齐产值</li>
          </ul>
          <ul class="task-content">
            <li class="task-content-li" v-for="(item,index) in dataList" :key="index">
              <div class="task-content-li-title">
                <div>
                  <img src="../../../assets/task-management/set-up-task-output-icon.png" alt="">
                  <span>{{ item.taskDate }}</span>
                  <span class="task-content-li-title-maket">{{ item.indexName }}</span>
                </div>
                <span class="task-content-li-title-completion-status" :class="item.isSuccess == 0 ?'incomplete':'completed'" >{{ item.isSuccess == 0 ? '未完成':'已完成'  }}</span>
              </div>
              <div class="task-content-li-content">
                <ul class="task-content-li-content-complete-rate">
                  <li :class="item.finishRate&&item.finishRate>100?'grenn':''">{{ item.finishRate&&item.finishRate > 0?(item.finishRate).toFixed(2):"--" }}<span>%</span></li>
                  <li>任务完成率</li>
                </ul>
                <ul>
                  <li>{{ item.finishValue && item.finishValue > 0 ?(item.finishValue).toFixed(2):"--" }}<span>{{ item.unit }}</span></li>
                  <li>实际完成</li>
                </ul>
                <ul>
                  <li>{{ item.productionJobValue && item.productionJobValue > 0 ?(item.productionJobValue).toFixed(2):"--" }}<span>{{ item.unit }}</span></li>
                  <li>{{ item.taskName }}</li>
                </ul>
                <div class="task-content-li-content-divider1">
                  <div></div>
                  <div></div>
                </div>
                <div class="task-content-li-content-divider2"></div>
              </div>
            </li>
          </ul>
        </van-tab>
      </van-tabs>
      <div class="data-filtering">
        <span @click="openSearch" class="" v-show="tabActive != 1 ">
          <van-icon name="filter-o" /> 筛选
        </span>
      </div>
      <div class="task-set" v-if="tabActive!=3">
        <div class="task-set-button" @click="setTask">任务设置</div>
      </div>
    </div>

    <div class="page-box" v-if="!permission('task-center:president')">
      <van-tabs v-model="tabActive" @click="onClickTab" color="#3DA2FF" class="van-tabs-region">
        <van-tab title="本月任务" name="1">
          <template #title>
            <van-badge v-if="thisMonthCnt > 0 " :content="thisMonthCnt" :max="99">
              <div>本月任务</div>
            </van-badge>
          </template>
          <ul class="output-tabs">
            <li @click="onClickMonthTabs('1')" :class="monthTabActive=='1'? 'output-tabs-active' : ''">我的任务</li>
            <li @click="onClickMonthTabs('2')" :class="monthTabActive=='2'? 'output-tabs-active' : ''">下级任务</li>
          </ul>
          <ul class="task-content">
            <li class="task-content-li" v-for="(item,index) in dataList" :key="index">
              <div class="task-content-li-title">
                <div>
                  <img src="../../../assets/task-management/set-up-task-output-icon2.png" alt="">
                  <span>{{ item.indexName }}</span>
                  <span class="task-content-li-title-date">{{ item.taskDate }}</span>
                </div>
                <div>
                  <span class="task-content-li-title-button" @click="undertakeAndSetUp(item)" v-if="monthTabActive == 1 && item.taskStatus == 0">承接并设置</span>
                  <span class="task-content-li-title-button" @click="resumption(item)" v-else-if="monthTabActive == 1 && item.taskStatus == 2">重新承接</span>
                  <span class="task-content-li-title-button task-content-li-title-button-accepted" v-else-if="monthTabActive == 1 && item.taskStatus == 1">已承接</span>
                  <div class="task-content-li-title-button-update" v-else-if ="monthTabActive == 2 && item.updateStatus == '1'" @click="modifySubordinateTasks(item)">
                    <span class="task-content-li-title-button-update-status" style="background: #FF8C21;" >设置中</span>
                  </div>
                  <div class="task-content-li-title-button-update" v-else-if ="monthTabActive == 2 && item.updateStatus == '2'" @click="modifySubordinateTasks(item)">
                    <span class="task-content-li-title-button-update-status" style="background: #FF8C21;" >设置中</span>
                    <van-icon name="arrow" color="#8C8C8C" v-if="item.isUpdateStatusIcon"/>
                  </div>
                  <div class="task-content-li-title-button-update" v-else-if ="monthTabActive == 2 && item.updateStatus == '3'" @click="modifySubordinateTasks(item)">
                    <span class="task-content-li-title-button-update-status" style="background: #FF6157;">修改中</span>
                    <van-icon name="arrow" color="#8C8C8C" v-if="item.isUpdateStatusIcon"/>
                  </div>
                  <span class="task-content-li-title-button" @click="updataTask2(item)" v-else-if ="monthTabActive == 2 && item.updateStatus == '0' && item.roleCode == 'ROLE_768022'">修改</span>
                  <span class="task-content-li-title-button" @click="updataTask2(item)" v-else-if ="monthTabActive == 2 && item.updateStatus == '0' && item.roleCode == 'ROLE_886285' && (item.termId == 'signed_count'||item.termId == 'shop_count'||item.termId == 'clue_count')">修改</span>
                  <span class="task-content-li-title-button" @click="modifySubordinateTasks(item)" v-else >查看详情</span>
                </div>
              </div>
              <div class="task-content-li-content">
                <ul class="task-content-li-content-complete-rate">
                  <li :class="item.finishRate&&item.finishRate>100?'grenn':''">{{ item.finishRate&&item.finishRate > 0?(item.finishRate).toFixed(2):"--" }}<span>%</span></li>
                  <li>任务完成率</li>
                </ul>
                <ul>
                  <li>{{ item.finishValue && item.finishValue > 0 ?(item.unit == '万'?(item.finishValue).toFixed(2):(item.finishValue)):"--" }}<span>{{ item.unit }}</span></li>
                  <li>实际完成</li>
                </ul>
                <ul>
                  <li>{{ item.productionJobValue && item.productionJobValue > 0 ?(item.unit == '万'?(item.productionJobValue).toFixed(2):(item.productionJobValue)):"--" }}<span>{{ item.unit }}</span></li>
                  <li>{{ item.taskName }}</li>
                </ul>
                <div class="task-content-li-content-divider1">
                  <div></div>
                  <div></div>
                </div>
                <div class="task-content-li-content-divider2"></div>
                <div class="task-content-li-content-prompt1" v-if="item.taskStatus == 2">新</div>
              </div>
            </li>
          </ul>
        </van-tab>
        <van-tab title="计划任务" name="2">
          <template #title>
            <van-badge v-if="planMonthCnt > 0 " :content="planMonthCnt" :max="99">
              <div>计划任务</div>
            </van-badge>
          </template>
          <ul class="output-tabs">
            <li @click="onClickplanTabs('1')" :class="planTabActive=='1'? 'output-tabs-active' : ''">我的任务</li>
            <li @click="onClickplanTabs('2')" :class="planTabActive=='2'? 'output-tabs-active' : ''">下级任务</li>
          </ul>
          <ul class="task-content">
            <li class="task-content-li" v-for="(item,index) in dataList" :key="index">
              <div class="task-content-li-title">
                <div>
                  <img src="../../../assets/task-management/set-up-task-output-icon2.png" alt="">
                  <span>{{ item.indexName }}</span>
                  <span class="task-content-li-title-date">{{ item.taskDate }}</span>
                </div>
                <div>
                  <span class="task-content-li-title-button" @click="undertakeAndSetUp(item)" v-if="planTabActive == 1 && item.taskStatus == 0">承接并设置</span>
                  <span class="task-content-li-title-button" @click="resumption(item)" v-else-if="planTabActive == 1 && item.taskStatus == 2">重新承接</span>
                  <span class="task-content-li-title-button task-content-li-title-button-accepted" v-else-if="planTabActive == 1 && item.taskStatus == 1">已承接</span>
                  <div class="task-content-li-title-button-update" v-else-if ="planTabActive == 2 && item.updateStatus == '1'" @click="modifySubordinateTasks(item)">
                    <span class="task-content-li-title-button-update-status" style="background: #FF8C21;">设置中</span>
                  </div>
                  <div class="task-content-li-title-button-update" v-else-if ="planTabActive == 2 && item.updateStatus == '2'" @click="modifySubordinateTasks(item)">
                    <span class="task-content-li-title-button-update-status" style="background: #FF8C21;">设置中</span>
                    <van-icon name="arrow" color="#8C8C8C" v-if="item.isUpdateStatusIcon"/>
                  </div>
                  <div class="task-content-li-title-button-update" v-else-if ="planTabActive == 2 && item.updateStatus == '3'" @click="modifySubordinateTasks(item)">
                    <span class="task-content-li-title-button-update-status" style="background: #FF6157;">修改中</span>
                    <van-icon name="arrow" color="#8C8C8C" v-if="item.isUpdateStatusIcon"/>
                  </div>
                  <span class="task-content-li-title-button" @click="updataTask2(item)" v-else-if ="planTabActive == 2 && item.updateStatus == '0' && item.roleCode == 'ROLE_768022'">修改</span>
                  <span class="task-content-li-title-button" @click="updataTask2(item)" v-else-if ="planTabActive == 2 && item.updateStatus == '0' && item.roleCode == 'ROLE_886285' && (item.termId == 'signed_count'||item.termId == 'shop_count'||item.termId == 'clue_count')">修改</span>
                  <span class="task-content-li-title-button" @click="modifySubordinateTasks(item)" v-else >查看详情</span>
                </div>
              </div>
              <div class="task-content-li-content">
                <ul class="task-content-li-content-complete-rate">
                  <li>{{ item.productionJobValue && item.productionJobValue > 0 ?(item.unit == '万'?(item.productionJobValue).toFixed(2):(item.productionJobValue)):"--" }}<span>{{ item.unit }}</span></li>
                  <li>{{ item.taskName }}</li>
                </ul>
                <ul>
                  <li>{{ item.finishValue && item.finishValue > 0 ?(item.unit == '万'?(item.finishValue).toFixed(2):(item.finishValue)):"--" }}<span>{{ item.unit }}</span></li>
                  <li>实际完成</li>
                </ul>
                <ul>
                  <li>{{ item.finishRate&&item.finishRate > 0?(item.finishRate).toFixed(2):"--" }}<span>%</span></li>
                  <li>任务完成率</li>
                </ul>
                <div class="task-content-li-content-divider1">
                  <div></div>
                  <div></div>
                </div>
                <div class="task-content-li-content-divider2"></div>
                <div class="task-content-li-content-prompt2" v-if="item.taskStatus == 2">新</div>
              </div>
            </li>
          </ul>
        </van-tab>
        <van-tab title="历史任务" name="3">
          <ul class="output-tabs">
            <li @click="onClickhistoryTabs('1')" :class="historyTabActive=='1'? 'output-tabs-active' : ''">我的任务</li>
            <li @click="onClickhistoryTabs('2')" :class="historyTabActive=='2'? 'output-tabs-active' : ''">下级任务</li>
          </ul>
          <ul class="task-content">
            <li class="task-content-li" v-for="(item,index) in dataList" :key="index">
              <div class="task-content-li-title">
                <div>
                  <img src="../../../assets/task-management/set-up-task-output-icon2.png" alt="">
                  <span>{{ item.indexName }}</span>
                  <span class="task-content-li-title-date">{{ item.taskDate }}</span>
                </div>
                <div>
                  <span class="task-content-li-title-completion-status" :class="item.isSuccess == 0 ?'incomplete':'completed'" >{{ item.isSuccess == 0 ? '未完成':'已完成'  }}</span>
                  <span class="task-content-li-title-button" @click="modifySubordinateTasks(item)" v-if="item.historyTaskDetailBtn&&historyTabActive=='2'">查看详情</span>
                </div>
              </div>
              <div class="task-content-li-content">
                <ul class="task-content-li-content-complete-rate">
                  <li :class="item.finishRate&&item.finishRate>100?'grenn':''">{{ item.finishRate&&item.finishRate > 0?(item.finishRate).toFixed(2):"--" }}<span>%</span></li>
                  <li>任务完成率</li>
                </ul>
                <ul>
                  <li>{{ item.finishValue && item.finishValue > 0 ?(item.unit == '万'?(item.finishValue).toFixed(2):(item.finishValue)):"--" }}<span>{{ item.unit }}</span></li>
                  <li>实际完成</li>
                </ul>
                <ul>
                  <li>{{ item.productionJobValue && item.productionJobValue > 0 ?(item.unit == '万'?(item.productionJobValue).toFixed(2):(item.productionJobValue)):"--" }}<span>{{ item.unit }}</span></li>
                  <li>{{ item.taskName }}</li>
                </ul>
                <div class="task-content-li-content-divider1">
                  <div></div>
                  <div></div>
                </div>
                <div class="task-content-li-content-divider2"></div>
              </div>
            </li>
          </ul>
        </van-tab>
      </van-tabs>
      <div class="data-filtering">
        <span @click="openSearch" class="" v-show="tabActive != 1 ">
          <van-icon name="filter-o" /> 筛选
        </span>
      </div>
    </div>

    <!-- 筛选弹窗 -->
    <van-popup
      v-model="popupShow"
      class="my-popup"
      overlay-class="my-popup-cover"
      position="top"
    >
      <div class="my-popup-box">
        <div class="my-popup-content">
          <p>按时间</p>
          <div class="my-popup-content-date">
            <div
              :class="startDate == '' ? '' : 'my-popup-content-date-content'"
              @click="openChooseDate('1')"
            >
              {{ startDate == "" ? "开始时间" : startDate }}
            </div>
            <div class="my-popup-content-date-interval">-</div>
            <div
              :class="endDate == '' ? '' : 'my-popup-content-date-content'"
              @click="openChooseDate('2')"
            >
              {{ endDate == "" ? "结束时间" : endDate }}
            </div>
          </div>
          <p v-if="permission('task-center:president')">按市场</p>
          <ul v-if="permission('task-center:president')">
            <li
              v-for="item in marketList"
              :class="item.flag == true ? 'active-li' : ''"
              @click="changeMarket(item)"
            >
              {{ item.text }}
            </li>
          </ul>
          <p v-if="!permission('task-center:president')">按任务指标筛选</p>
          <ul v-if="!permission('task-center:president')">
            <li
              v-for="item in indicatorList"
              :class="item.flag == true ? 'active-li' : ''"
              @click="changeIndicator(item)"
            >
              {{ item.name }}
            </li>
          </ul>
          <p v-if="tabActive == 3">按完成状态</p>
          <ul v-if="tabActive == 3">
            <li
              v-for="item in completionStatusList"
              :class="item.value == completionStatus ? 'active-status' : ''"
              @click="changeCompletionStatus(item)"
            >
              {{ item.text }}
            </li>
          </ul>
        </div>
        <div class="my-popup-footer">
          <div @click="restSearch">重置</div>
          <div @click="sureSearch">确认</div>
        </div>
      </div>
    </van-popup>
    
    <!-- 日期弹窗 -->
    <van-popup
      v-model="datePopupShow"
      class="my-date-popup"
      overlay-class="my-popup-cover"
      position="bottom"
      :safe-area-inset-bottom="true"
      :lazy-render ="false"
    >
      <div class="my-date-popup-box">
        <div class="my-date-popup-content">
          <van-picker
            show-toolbar
            :columns="columns"
            toolbar-position="top"
            ref="myPicker"
            style="height: 100%"
            :title = "datePopupTitle"
            @confirm="dateSureSearch"
            @cancel="dateRestSearch"
          >
          </van-picker>
        </div>
      </div>
    </van-popup>

    <!-- 任务修改弹窗 总裁 -->
    <van-popup 
      v-model="taskModificationShow"
      class="my-task-modification-popup"
      overlay-class="my-popup-cover"
      @close="cloneTaskModificationPopup"
      >
      <div class="my-task-modification-popup-title">任务修改</div>
      <van-form @submit="onSubmitTaskModification" :key="fromRestFields">
        <van-field
          v-model="taskModificationList.productionMinValue"
          name="productionMinValue"
          type="digit"
          maxlength="6"
          label="保底产值"
          placeholder="请输入"
          :rules="[{ required: true, message: '' }]"
        >
          <i slot="left-icon" style="color:red">*</i>
          <template #button>万元</template>
        </van-field>
        <van-field
          v-model="taskModificationList.productionJobValue"
          name="productionJobValue"
          type="digit"
          maxlength="6"
          label="任务产值"
          placeholder="请输入"
          :rules="[{ required: true, message: '' }]"
          >
            <i slot="left-icon" style="color:red">*</i>
            <template #button>万元</template>
          </van-field>
        <van-field
          v-model="taskModificationList.productionGoalValue"
          name="productionGoalValue"
          type="digit"
          maxlength="6"
          label="目标产值"
          placeholder="请输入"
          :rules="[{ required: true, message: '' }]"
          >
            <i slot="left-icon" style="color:red">*</i>
            <template #button>{{ unit }}</template>
          </van-field>
        <div class="my-task-modification-popup-button">
          <div @click="taskModificationShow = false">取消</div>
          <van-button native-type="submit">确认</van-button>
        </div>
      </van-form>
    </van-popup>

    <!-- 任务修改弹窗 总经理 -->
    <van-popup 
      v-model="taskModificationShow2"
      class="my-task-modification-popup"
      overlay-class="my-popup-cover"
      @close="cloneTaskModification2Popup"
      >
      <div class="my-task-modification-popup-title">任务修改</div>
      <van-form @submit="onSubmitTaskModification2" :key="fromRestFields2">
        <van-field
          v-model="taskModificationList2.productionJobValue"
          name="productionJobValue"
          type="digit"
          maxlength="6"
          :label="taskName"
          placeholder="请输入"
          :rules="[{ required: true, message: '' }]"
          >
            <i slot="left-icon" style="color:red">*</i>
            <template #button>{{ unit }}</template>
          </van-field>
        <div class="my-task-modification-popup-button">
          <div @click="taskModificationShow2 = false">取消</div>
          <van-button native-type="submit">确认</van-button>
        </div>
      </van-form>
    </van-popup>

    <!-- 用户权限弹窗 -->
    <van-popup
      v-model="userPermissionsPopupShow"
      class="my-user-permissions-popup"
      overlay-class="my-popup-cover"
      position="bottom"
      :safe-area-inset-bottom="true"
      :lazy-render ="false"
    >
      <div class="my-user-permissions-popup-box">
        <div class="my-user-permissions-popup-content">
          <van-picker
            show-toolbar
            title="切换角色"
            :columns="userPermissionsColumns"
            toolbar-position="top"
            ref="userPermissionsPicker"
            style="height: 100%"
            @confirm="userPermissionsSureSearch"
            @cancel="userPermissionsRestSearch"
          >
          </van-picker>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import api from "@/api/task-management/setup.js";
import moment from "moment";
import * as echarts from "echarts";
import { Dialog,Toast } from 'vant';
export default {
  data() {
    return {
      userPermissions:{},
      userPermissionsPopupShow:false,
      userPermissionsColumns:[],
      popupShow: false,
      datePopupShow: false,
      datePopupTitle:"",
      datePopupType:"1",
      year: moment().year(),
      month: moment().month() + 1,
      startDate: "",
      endDate: "",
      columns: [
        { values: [], defaultIndex: moment().year() },
        { values: [], defaultIndex: moment().month() + 1 },
      ],
      marketCode: [],
      marketList: [],
      completionStatus: "",
      completionStatusList: [
        { text: "已完成", value: "1" },
        { text: "未完成", value: "0" },
      ],
      indicatorList: [],
      indicatorCode: [],
      
      planObj:{
        chooseStartDate: "",
        chooseEndDate: "",
        chooseMarketCode: [],
        chooseCompletionStatus: "",
        chooseIndicatorCode: [],
      },
      historyObj:{
        chooseStartDate: "",
        chooseEndDate: "",
        chooseMarketCode: [],
        chooseCompletionStatus: "",
        chooseIndicatorCode: [],
      },

      tabActive:1,
      monthTabActive:"1",
      planTabActive:"1",
      historyTabActive:"1",
      thisMonthCnt:0,
      planMonthCnt:0,

      dataList:[],

      taskModificationShow: false,
      fromRestFields:0,
      taskModificationList:{
        id:'',
        productionMinValue:'',
        productionJobValue:'',
        productionGoalValue:'',
      },
      taskModificationShow2: false,
      fromRestFields2:0,
      taskModificationList2:{
        id:'',
        productionJobValue:'',
      },
      taskTypeList:[
        { text: "转单产值", text1: "任务产值",  value: "transfer_output", unit:'万元',max:5 },
        { text: "补齐产值", text1: "任务产值", value: "supply_output", unit:'万元',max:5 },
        { text: "转单量", text1: "任务量",  value: 'transfer_count', unit:'个',max:4 },
        { text: "补齐量", text1: "任务量", value: 'supply_count', unit:'个',max:4 },
        { text: "签单量", text1: "任务量",  value: 'signed_count', unit:'个',max:4 },
        { text: "到店量", text1: "任务量", value: 'shop_count', unit:'个',max:4 },
        { text: "线索量", text1: "任务量", value: 'clue_count', unit:'个',max:4 },
      ],
      unit:'万元',
      taskName:'任务产值',
      
      //审批数量 
      approveNum:0,
    };
  },
  created() {
    this.setDateColumns();
  },
  mounted() {
    let _this = this;
    let tabActiveObj = JSON.parse(localStorage.getItem('TAB_ACTIVE_OBJ'))||""
    if( tabActiveObj ){
      this.tabActive = tabActiveObj.tabActive
      if(this.tabActive == 1){
        this.monthTabActive = tabActiveObj.subtabActive
      }else if(this.tabActive == 2){
        this.planTabActive = tabActiveObj.subtabActive
      }else if( this.tabActive == 3){
        this.historyTabActive = tabActiveObj.subtabActive
      }
    }else{
      this.tabActive = 1
      this.monthTabActive = 1
      this.planTabActive = 1
      this.historyTabActive = 1
    }

    this.getMarketCode()
    this.getRole()

    sessionStorage.removeItem('TASK_APPROVE_TAB_OBJ')
  },
  methods: {
    // 路由返回
    back() {
      this.$router.go(-1);
    },
    // 获取市场
    getMarketCode(){
      let params = {
        'Q_dict.code_IN': 'DICT771486',
      }
      api.dictItem(params).then((res)=>{
        if(res.code == 200){
          res.data.forEach((item)=>{
              this.marketList.push({ text: item.name, value: item.code, flag:false })
          })
        }else{
          Toast.fail(res.msg)
        }
      })
    },
    // 根据角色查询相关term
    getTermConditionByRole(){
      let params = {
        "roleCode":this.userPermissions.roleCode,
      }
      api.getTermConditionByRole(params).then((res)=>{
        if(res.code == 200){
          res.data.forEach((item)=>{
            item.flag = false
          })
          this.indicatorList = res.data
        }else{
          Toast.fail(res.msg)
        }
      })
    },
    // 角色列表查询
    getRole(){
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      api.getRole().then((res)=>{
        if(res.code == 200){
          Toast.clear()
          res.data.forEach((item)=>{
            item.text = item.roleName
          })
          res.data.sort((a,b)=>{
            return a.level - b.level
          })
          let role = JSON.parse(localStorage.getItem("USER_ROLE"))
          this.userPermissions = role
          this.userPermissionsColumns = res.data
          this.init()
        }else{
          Toast.fail(res.msg)
        }
      })
    },
    // 统计任务数据数量
    statCount(){
      let params = {
        "roleCode":this.userPermissions.roleCode,
        "monthType":this.tabActive
      }
      api.statCount(params).then((res)=>{
        if(res.code == 200){
          this.thisMonthCnt = res.data.thisMonthCnt
          this.planMonthCnt = res.data.planMonthCnt
        }else{
          Toast.fail(res.msg)
        }
      })
    },
    // 查看部门任务（本月/计划/历史）
    getTasksByDepart(){
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      let level , queryStr
      if(this.tabActive == 1){
        level = this.monthTabActive
        queryStr = {
          "roleCode":this.userPermissions.roleCode,
          "Q_termId_IN": this.permission('task-center:president')?(level=="1"?"transfer_output":"supply_output"):"",
          "Q_updateStatus_IN":this.monthTabActive == "1" ?"0,2,3":""
        }
      }else if(this.tabActive == 2){
        level = this.planTabActive
        queryStr = {
          "roleCode":this.userPermissions.roleCode,
          "Q_termId_IN": this.permission('task-center:president')?(level=="1"?"transfer_output":"supply_output"):(this.planObj.chooseIndicatorCode.length>0?this.planObj.chooseIndicatorCode.join(','):""),
          "Q_taskDate_BW":this.planObj.chooseStartDate?this.planObj.chooseStartDate+"~"+this.planObj.chooseEndDate:"",
          "Q_isSuccess_EQ":this.planObj.chooseCompletionStatus,
          "Q_marketCode_IN":this.planObj.chooseMarketCode.length>0?this.planObj.chooseMarketCode.join(','):"",
          "Q_updateStatus_IN":this.planTabActive == "1" ?"0,2,3":""
        }
      }else{
        level = this.historyTabActive
        queryStr = {
          "roleCode":this.userPermissions.roleCode,
          "Q_termId_IN": this.permission('task-center:president')?(level=="1"?"transfer_output":"supply_output"):(this.historyObj.chooseIndicatorCode.length>0?this.historyObj.chooseIndicatorCode.join(','):""),
          "Q_taskDate_BW":this.historyObj.chooseStartDate?this.historyObj.chooseStartDate+"~"+this.historyObj.chooseEndDate:"",
          "Q_isSuccess_EQ":this.historyObj.chooseCompletionStatus,
          "Q_marketCode_IN":this.historyObj.chooseMarketCode.length>0?this.historyObj.chooseMarketCode.join(','):"",
        }
      }
      
      let params={
        "queryStr":JSON.stringify(queryStr),
        "ext":JSON.stringify({
          monthType:this.tabActive,
          level:level,
        }),
        "limit": 999,
        "page": 1
      }
      api.getTasksByDepart(params).then((res)=>{
        if (res.code == 200) {
          Toast.clear()
          if(res.data.record && res.data.record.length > 0){
            res.data.record.forEach((item)=>{
              if(item.indexName.indexOf('产值') != -1){
                item.unit = "万"
                item.taskName = "任务产值"
              }else if(item.indexName.indexOf('市场') != -1){
                item.unit = "万"
                item.taskName = "任务产值"
              }else{
                item.unit = "个"
                item.taskName = "任务量"
              }

              // 判断权限(运营总裁除外) 修改状态是否显示详情icon
              if(item.roleCode == 'ROLE_768022'){
                item.isUpdateStatusIcon = false
              }else if(item.roleCode == 'ROLE_886285' && (item.termId == 'signed_count'||item.termId == 'shop_count'||item.termId == 'clue_count')){
                item.isUpdateStatusIcon = false
              }else {
                item.isUpdateStatusIcon = true
              }
              
              // 根据角色判断显示查看详情按钮（历史任务）
              if(  item.roleCode == 'ROLE_768022' ){//总经理
                item.historyTaskDetailBtn = false
              }else if( item.roleCode == 'ROLE_886285'){//前台总监
                if(item.termId=='transfer_output'||item.termId=='supply_output'||item.termId=='transfer_count'||item.termId=='supply_count'){
                  item.historyTaskDetailBtn = true
                }else{
                  item.historyTaskDetailBtn = false
                }
              }else if( item.roleCode == 'ROLE_113490'){//督导经理
                if(item.termId=='transfer_output'||item.termId=='supply_output'||item.termId=='transfer_count'||item.termId=='supply_count'){
                  item.historyTaskDetailBtn = true
                }else{
                  item.historyTaskDetailBtn = false
                }
              }else if( item.roleCode == 'ROLE_327530'){//转单经理
                if(item.termId=='transfer_output'||item.termId=='supply_output'||item.termId=='transfer_count'||item.termId=='supply_count'){
                  item.historyTaskDetailBtn = true
                }else{
                  item.historyTaskDetailBtn = false
                }
              }else if( item.roleCode == 'ROLE_553504'){//签单经理
                if(item.termId=='signed_count'){
                  item.historyTaskDetailBtn = true
                }else{
                  item.historyTaskDetailBtn = false
                }
              }else if( item.roleCode == 'ROLE_670920'){//话务经理
                if(item.termId=='shop_count'){
                  item.historyTaskDetailBtn = true
                }else{
                  item.historyTaskDetailBtn = false
                }
              }
            })
          }
          this.dataList = res.data.record || []
        }else{
          Toast.fail(res.msg)
        }
      })
    },
    // 打开用户权限
    openUserPermissionsPopup(){
      this.userPermissionsPopupShow = !this.userPermissionsPopupShow;
      this.popupShow = false;
      this.$refs.userPermissionsPicker.setValues([this.userPermissions.text])
    },
    // 用户权限弹窗 取消
    userPermissionsRestSearch(){
      this.userPermissionsPopupShow = false
    },
    // 用户权限弹窗 确认
    userPermissionsSureSearch(){
      this.userPermissions = this.$refs.userPermissionsPicker.getValues()[0]
      this.userPermissionsPopupShow = false
      this.init()
    },
    //打开筛选
    openSearch() {
      this.popupShow = !this.popupShow;
      this.userPermissionsPopupShow = false
      if( this.tabActive == 2 ){
        this.changeQuery(this.planObj)
      }else if( this.tabActive == 3 ){
        this.changeQuery(this.historyObj)
      }
    },
    // 根据tabs 修改参数
    changeQuery(value){
      this.marketCode = [...value.chooseMarketCode];
      if(this.marketCode && this.marketCode.length>0){
        this.marketList.forEach((item,index) => {
          item.flag = false
          this.marketCode.forEach((item1,index1) => {
            if(item.value == item1){
              item.flag = true
            }
          })
        });
      }else{
        this.marketList.forEach((item1,index1) => {
          item1.flag = false
        })
      }
      this.indicatorCode = [...value.chooseIndicatorCode];
      if(this.indicatorCode && this.indicatorCode.length>0){
        this.indicatorList.forEach((item,index) => {
          item.flag = false
          this.indicatorCode.forEach((item1,index1) => {
            if(item.code == item1){
              item.flag = true
            }
          })
        });
      }else{
        this.indicatorList.forEach((item1,index1) => {
          item1.flag = false
        })
      }

      this.startDate = value.chooseStartDate;
      this.endDate = value.chooseEndDate;
      this.completionStatus = value.chooseCompletionStatus;
    },
    //初始化日期选择范围
    setDateColumns() {
      let y = [];
      let nowYear = moment().year() + 2;
      for (let i = nowYear; i > nowYear - 21; i--) {
        y.push(i);
      }
      let m = [];
      for (let i = 1; i < 13; i++) {
        i = i < 10 ? "0" + i : i
        m.push(i);
      }
      this.columns[0].values = y;
      this.columns[1].values = m;
    },
    //打开日期选择
    openChooseDate(type) {
      this.datePopupType = type
      if(type == "1"){
        this.datePopupTitle = "开始时间"
        this.year = this.startDate ? this.startDate.split('-')[0] : moment().year()
        this.month = this.startDate ? (+ this.startDate.split('-')[1] ) : moment().month() + 1 
      }else if(type == "2"){
        this.datePopupTitle = "结束时间"
        this.year = this.endDate ? this.endDate.split('-')[0] : moment().year()
        this.month = this.endDate ?  (+ this.endDate.split('-')[1]) : moment().month() + 1 
      }
      this.$refs.myPicker && this.$refs.myPicker.setValues([Number(this.year), this.month]);
      this.datePopupShow = true;
    },
    // 日期弹窗 取消
    dateRestSearch() {
      this.year = moment().year();
      this.month = moment().month() + 1 ;
      this.datePopupShow = false;
    },
    // 日期弹窗 确认
    dateSureSearch() {
      this.year = this.$refs.myPicker.getValues()[0];
      this.month = this.$refs.myPicker.getValues()[1];
      let date = this.year + "-" + this.month;
      if(this.datePopupType == "1"){
        this.startDate = date
      }else if(this.datePopupType == "2"){
        this.endDate = date
      }
      if(this.endDate && this.startDate){
        if((moment(this.endDate).year() - moment(this.startDate).year()) * 12 + moment(this.endDate).month() - moment(this.startDate).month() < 0){
          Toast('结束时间小于开始时间,请重新选择');
          this.startDate = ""
          this.endDate = ""
        }
      }
      //确认
      this.datePopupShow = false;
    },
    //市场改变
    changeMarket(item) {
      item.flag ? item.flag = false : item.flag = true
      if(item.flag && this.marketCode.indexOf(item.value) == -1){
        this.marketCode.push(item.value)
      }else if(item.flag == false && this.marketCode.indexOf(item.value) != -1){
        this.marketCode.splice(this.marketCode.indexOf(item.value),1)
      }
    },
    // 指标改变
    changeIndicator(item){
      item.flag ? item.flag = false : item.flag = true
      if(item.flag && this.indicatorCode.indexOf(item.code) == -1){
        this.indicatorCode.push(item.code)
      }else if(item.flag == false && this.indicatorCode.indexOf(item.code) != -1){
        this.indicatorCode.splice(this.indicatorCode.indexOf(item.code),1)
      }
    },
    //完成状态改变
    changeCompletionStatus(item) {
      this.completionStatus = item.value;
    },
    //取消
    restSearch() {
      if( this.tabActive == 2 ){
        this.planObj = {
          chooseStartDate: "",
          chooseEndDate: "",
          chooseMarketCode: [],
          chooseCompletionStatus: "",
          chooseIndicatorCode: [],
        }
      }else if( this.tabActive == 3 ){
        this.historyObj = {
          chooseStartDate: "",
          chooseEndDate: "",
          chooseMarketCode: [],
          chooseCompletionStatus: "",
          chooseIndicatorCode: [],
        }
      }
      this.popupShow = false;
      this.getTasksByDepart()
    },
    //确认搜索
    sureSearch() {
      if(this.startDate == "" || this.endDate == ""){
        if(this.startDate == "" && this.endDate == ""){
        }else{
          Toast('开始时间和结束时间不能为空');
          return false
        }
      }
      if( this.tabActive == 2 ){
        if((moment(this.startDate).year() - moment().year()) * 12 + moment(this.startDate).month() - moment().month() < 1){
          Toast('计划任务不能选择本月时间和历史时间');
          this.startDate = ''
          this.endDate = ''
          return 
        }
        this.planObj={
          chooseMarketCode : [...this.marketCode],
          chooseIndicatorCode : [...this.indicatorCode],
          chooseStartDate : this.startDate,
          chooseEndDate : this.endDate,
          chooseCompletionStatus : this.completionStatus,
        }
      }else if( this.tabActive == 3 ){
        if((moment(this.endDate).year() - moment().year()) * 12 + moment(this.endDate).month() - moment().month() >= 0){
          Toast('历史任务不能选择本月时间和计划时间');
          this.startDate = ''
          this.endDate = ''
          return 
        }
        this.historyObj={
          chooseMarketCode : [...this.marketCode],
          chooseIndicatorCode : [...this.indicatorCode],
          chooseStartDate : this.startDate,
          chooseEndDate : this.endDate,
          chooseCompletionStatus : this.completionStatus,
        }
      }
      this.popupShow = false;
      this.getTasksByDepart()
    },
    // 切换Tab
    onClickTab(name, title){
      this.popupShow = false
      this.userPermissionsPopupShow = false
      this.dataList = []
      this.getTasksByDepart()
    },
    // 本月任务 产值切换
    onClickMonthTabs(type){
      this.monthTabActive = type
      this.getTasksByDepart()
    },
    // 计划任务 产值切换
    onClickplanTabs(type){
      this.planTabActive = type
      this.getTasksByDepart()
    },
    // 历史任务 产值切换
    onClickhistoryTabs(type){
      this.historyTabActive = type
      this.getTasksByDepart()
    },
    // 产值修改 (运营总裁)
    updataTask(item){
      this.taskModificationShow = true
      let {productionMinValue,productionJobValue,productionGoalValue,id,termId} = item
      this.taskTypeList.forEach((item) => {
        if(item.value == termId){
          this.unit = item.unit
        }
      });
      this.taskModificationList = {
        productionMinValue,productionJobValue,productionGoalValue,id
      }
    },
    // 产值修改 (总经理/前台总监)
    updataTask2(item){
      this.taskModificationShow2 = true
      let {productionJobValue,id,termId} = item
      this.taskTypeList.forEach((item) => {
        if(item.value == termId){
          this.unit = item.unit
          this.taskName = item.text1
        }
      });
      this.taskModificationList2 = {
        productionJobValue,id
      }
    },
    // 查看详情
    modifySubordinateTasks(item){
      if( this.userPermissions.roleCode == 'ROLE_768022' ){
        return false
      }
      if(item.roleCode == 'ROLE_886285' && (item.termId == 'signed_count'||item.termId == 'shop_count'||item.termId == 'clue_count')){
        return false
      }

      if(item.updateStatus == "1"){
        Toast("你设置的任务正在审批，审批通过后可查看")
        return false
      }
      let routerName,title
      if( this.permission("task-center:foreground-director") ){//前台总监
        routerName = 'TaskManagementSetUpTaskDetailsForegroundDirector'
      }
      if( this.permission("task-center:supervision-manager") ){//督导经理
        routerName = 'TaskManagementSetUpTaskDetails'
        title = '督导部详情'
      }
      if( this.permission("task-center:transfer-manager") ){//转单经理
        routerName = 'TaskManagementSetUpTaskDetails'
        title = '转单部详情'
      }
      if( this.permission("task-center:signing-manager") ){//签单经理
        routerName = 'TaskManagementSetUpTaskDetails'
        title = '签单部详情'
      }
      if( this.permission("task-center:traffic-manager") ){//话务经理
        routerName = 'TaskManagementSetUpTaskDetails'
        title = '话务部详情'
      }
      if(routerName == "") {
        Toast.fail("当前角色无权限，请配置权限");
        return false
      }
      this.recordmenu()
      this.$router.push({
        name:routerName,
        query: {
          item:JSON.stringify(item),
          type:this.tabActive,
          title
        }
      })
    },
    // 根据角色判断跳转路由
    routerFn(){
      let routerName = ""
      if( this.permission("task-center:executive") ){//总经理
        routerName = 'TaskManagementSetUpTaskSetExecutive'
      }
      if( this.permission("task-center:foreground-director") ){//前台总监
        routerName = 'TaskManagementSetUpTaskSetForegroundDirector'
      }
      if( this.permission("task-center:supervision-manager") ){//督导经理
        routerName = 'TaskManagementSetUpTaskSetSupervisionManager'
      }
      if( this.permission("task-center:transfer-manager") ){//转单经理
        routerName = 'TaskManagementSetUpTaskSetTransferManager'
      }
      if( this.permission("task-center:signing-manager") ){//签单经理
        routerName = 'TaskManagementSetUpTaskSetSigningManager'
      }
      if( this.permission("task-center:traffic-manager") ){//话务经理
        routerName = 'TaskManagementSetUpTaskSetTrafficManager'
      }
      return routerName
    },
    // 承接并设置
    undertakeAndSetUp(item){
      let routerName = this.routerFn()
      if (routerName == "") {
        Toast.fail("当前角色无权限，请配置权限");
        return false
      }   
      this.recordmenu()
      this.$router.push({
        name:routerName,
        query: {
          item:JSON.stringify(item),
        }
      })
    },
    // 重新承接
    resumption(item){
      let routerName = this.routerFn()
      if (routerName == "") {
        Toast.fail("当前角色无权限，请配置权限");
        return false
      } 
      this.recordmenu()
      this.$router.push({
        name:routerName,
        query: {
          item:JSON.stringify(item)
        }
      })
    },
    // 任务修改弹窗(总裁) 提交  
    onSubmitTaskModification(values) {
      this.taskModificationList = {
        ...this.taskModificationList,
        ...values,
        "roleCode":this.userPermissions.roleCode,
      }
      api.update(this.taskModificationList).then((res)=>{
        if(res.code == 200){
          this.taskModificationShow = false
          Toast.success('操作成功');
          let _this = this;
          setTimeout(()=>{
            _this.getTasksByDepart()
          },1000)
        }else{
          Toast.fail(res.msg);
        }
      })
    },
    // 任务修改弹窗(总裁) 关闭
    cloneTaskModificationPopup(){
      this.fromRestFields++
      this.taskModificationList = {
        id:'',
        productionMinValue:'',
        productionJobValue:'',
        productionGoalValue:'',
      }
    },
    // 任务修改弹窗(总经理/前台总监) 提交
    onSubmitTaskModification2(values) {
      this.taskModificationList2 = {
        ...this.taskModificationList2,
        ...values,
        "roleCode":this.userPermissions.roleCode,
      }
      api.update(this.taskModificationList2).then((res)=>{
        if(res.code == 200){
          this.taskModificationShow2 = false
          Toast.success('操作成功');
          let _this = this;
          setTimeout(()=>{
            _this.getTasksByDepart()
          },1000)
        }else{
          Toast.fail(res.msg);
        }
      })
    },
    // 任务修改弹窗(总经理) 关闭
    cloneTaskModification2Popup(){
      this.fromRestFields2++
      this.taskModificationList2 = {
        id:'',
        productionJobValue:'',
      }
    },
    // 任务设置
    setTask(){
      this.recordmenu()
      this.$router.push({
        name:'TaskManagementSetUpTaskSetPresident',
      })
    },
    // 记录当前菜单
    recordmenu(){
      let subtabActive
      if(this.tabActive == 1){
        subtabActive = this.monthTabActive
      }else if(this.tabActive == 2){
        subtabActive = this.planTabActive
      }else{
        subtabActive = this.historyTabActive
      }
      localStorage.setItem("TAB_ACTIVE_OBJ",JSON.stringify({tabActive:this.tabActive,subtabActive}))
    },
    //角色权限判断
    permission(v) {
      return this.hasRolePermission(v)
    },
    init(){
      this.getTermConditionByRole()
      this.statCount()
      this.getTasksByDepart()
      this.getApproveNum()
    },
    // 获取审批数量
    getApproveNum(){
      api.getApprovalNumber({}).then((res)=>{
        if(res.code == 200){
          res.data.forEach((item)=>{
            this.approveNum += item.number
          })
        }else{
          Toast.fail(res.msg);
        }
      })
    },
    // 打开审批页面
    openApprove(){
      this.$router.push({
        name:'TaskManagementSetUpTaskApprove',
      })
    },
    // 打开调整记录页面
    openAdjust(){
      this.$router.push({
        name:'TaskManagementSetUpTaskAdjust',
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.headNav-righ-text {
  font-size: 14px;
}

.headNav-righ-icon{
  position: absolute;
  top: 50%;
  margin-top: -12px;
  gap: 20px;
  img{
    width: 20px;
    height: 20px;
  }

}
.my-popup-cover {
  background: rgba(0, 0, 0, 0.5);
}

.my-popup {
  background: #ffffff;
  border-radius: 0px 0px 10px 10px;

  .my-popup-box {
    padding-top: 100px;
    .my-popup-head {
      line-height: 48px;
      border-bottom: 1px solid #dfdfdf;
      text-align: center;
      font-size: 16px;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: bold;
      color: #424242;
      position: relative;

      i {
        position: absolute;
        right: 19px;
        top: calc(50% - 8px);
      }
    }

    .my-popup-content {
      padding: 0 15px 10px 15px;

      & > p {
        text-align: left;
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
      }

      ul {
        display: flex;
        flex-wrap: wrap;
        gap: 14px;
        margin-bottom: 10px;
      }

      li {
        width: 75px;
        line-height: 32px;
        background: #f9f9f9;
        border-radius: 4px 4px 4px 4px;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }

      .active-li {
        background: #e8f1ff;
        color: #0e8bff;
        position: relative;
        &::before {
          content: "";
          width: 20px;
          height: 16px;
          position: absolute;
          right: 0;
          bottom: 0;
          background: url("../../../assets/task-management/set-up-screen-active.png")
            no-repeat center;
          background-size: 95% 95%;
        }
      }
      .active-status{
        background: #e8f1ff;
        color: #0e8bff;
      }

      .my-popup-content-date {
        height: 40px;
        background: #f8f8f8;
        display: flex;
        & > div {
          width: 45%;
          line-height: 40px;
          text-align: center;
          font-size: 16px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #999999;
        }
        .my-popup-content-date-interval {
          width: 10%;
          color: #000000;
        }
        .my-popup-content-date-content {
          color: #000000;
        }
      }
    }

    .my-popup-footer {
      width: 100%;
      display: flex;

      & > div {
        width: 50%;
        line-height: 60px;
        background: #ffffff;
        opacity: 1;
        border: 1px solid #eeeeee;
        font-size: 18px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 500;
        color: #3da2ff;

        &:first-child {
          color: #333333c2;
        }
      }
    }
  }
}
.my-date-popup {
  background: #ffffff;
  border-radius: 16px 16px 0px 0px;

  .my-date-popup-box {
    .my-date-popup-head {
      line-height: 48px;
      border-bottom: 1px solid #dfdfdf;
      text-align: center;
      font-weight: bold;
      color: #424242;
      position: relative;
      font-size: 16px;

      i {
        position: absolute;
        right: 19px;
        top: calc(50% - 8px);
      }
    }

    .my-date-popup-content {
      height: 100%;
      overflow: hidden;
      padding-bottom: 10px;
    }

    .my-date-popup-footer {
      height: 75px;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;

      button {
        width: 50%;
        height: 44px;
        // border-radius: 8px;
      }
    }
  }
}

.my-task-modification-popup{
  width: 85%;
  border-radius: 8px 8px 8px 8px;
  .my-task-modification-popup-title{
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #323233;
    padding: 14px 0 20px;
  }
  .my-task-modification-popup-button{
    width: 100%;
    display: flex;

    & > div {
      width: 50%;
      line-height: 60px;
      background: #ffffff;
      opacity: 1;
      border: 1px solid #eeeeee;
      font-size: 18px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 500;
      color: #3da2ff;

      &:first-child {
        color: #333333c2 !important;
      }
    }
  }
}

.my-user-permissions-popup {
  background: #ffffff;
  border-radius: 16px 16px 0px 0px;
  .my-user-permissions-popup-box {
    .my-user-permissions-popup-head {
      line-height: 48px;
      border-bottom: 1px solid #dfdfdf;
      text-align: center;
      font-weight: bold;
      color: #424242;
      position: relative;
      font-size: 16px;

      i {
        position: absolute;
        right: 19px;
        top: calc(50% - 8px);
      }
    }

    .my-user-permissions-popup-content {
      height: calc(100% - 10px);
      overflow: hidden;
    }

    .my-user-permissions-popup-footer {
      height: 75px;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      gap: 12px;

      button {
        width: 164px;
        height: 44px;
        // border-radius: 8px;
      }
    }
  }
}

.page-box {
  height: calc(100vh - 50px);
  overflow: auto;
  position: relative;

  .planned-task-num{
    display: block;
    background: #FF6157;
    border-radius: 28px 28px 28px 28px;
    padding:1px 6px 0;
    font-size: 11px;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    position: absolute;
    top: 6%;
    left: 80%;
    transform: scale(.75);
  }

  .output-tabs{
    display: flex;

    &>li{
      width: 84px;
      line-height: 32px;
      background: #FFFFFF;
      border-radius: 4px 4px;
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #545454;
      text-align: center;
      margin: 13px 10px 14px;
      padding-top: 1px;
    }
    .output-tabs-active{
      color: #3DA2FF;
      font-weight: bold;
    }
  }

  .task-content{
    height: calc(100vh - 155px);
    overflow: auto;
    .task-content-li{
      background: #FFFFFF;
      border-radius: 8px 8px 8px 8px;
      padding: 15px 12px;
      margin-bottom: 16px;
      .task-content-li-title{
        height: 28px;
        font-size: 20px;
        font-family: PingFang SC-Bold, PingFang SC;
        // font-weight: bold;
        color: #545454;
        text-align: left;
        margin-bottom: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        img{
          width: 20px;
          height: 20px;
          vertical-align: -2.5px;
        }
        .task-content-li-title-maket{
          display: inline-block;
          width: 68px;
          line-height: 22px;
          background: rgba(242,109,12,0.1);
          border-radius: 3px 3px 3px 3px;
          font-size: 14px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #F26D0C;
          text-align: center;
          vertical-align: 2px;
          margin-left: 6px;
        }
        .task-content-li-title-date{
          display: inline-block;
          width: 68px;
          line-height: 22px;
          background: #F9F9F9;
          border-radius: 3px 3px 3px 3px;
          font-size: 14px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #8C8C8C;
          text-align: center;
          vertical-align: 2px;
          margin-left: 6px;
        }

        .task-content-li-title-button{
          // float: right;
          display: inline-block;
          line-height: 25px;
          border-radius: 4px 4px 4px 4px;
          border: 1px solid #0E8BFF;
          font-size: 14px;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          color: #0E8BFF;
          text-align: center;
          width: 85px;
          margin-left: 8px;
        }
        .task-content-li-title-button-accepted{
          border: 1px solid #8C8C8C;
          color: #8C8C8C;
        }
        .task-content-li-title-button-update-status{
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 14px;
          color: #FFFFFF;
          padding: 2px 6px;
          border-radius: 4px 4px 4px 4px;
          line-height: 24px;
          vertical-align: 10%;
        }
        .task-content-li-title-completion-status{
          // float: right;
          display: inline-block;
          width: 54px;
          line-height: 24px;
          border-radius: 4px 4px 4px 4px;
          font-size: 14px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          text-align: center;
          color: #FFFFFF;
          padding-top: 1px;
        }
        .incomplete{
          background: #FF6157;
        }
        .completed{
          background: #17CA5E;
        }
      }
      .task-content-li-content{
        height: 88px;
        background: #F9F9F9;
        box-shadow: 0px 5px 7px 0px rgba(254,242,222,0.2);
        border-radius: 8px 8px 8px 8px;
        display: flex;
        position: relative;

        & > ul{
          width: 33.3%;
          padding: 18px 0;
          
          span{
            font-size: 14px;
          }
        }
        li:nth-child(1){
          line-height: 32px;
          font-size: 22px;
          font-family: Bahnschrift-SemiLight, Bahnschrift;
          font-weight: normal;
          color: #1D2129;
        }
        li:nth-child(2){
          font-size: 14px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #545454;
        }

        .task-content-li-content-complete-rate{
          li:nth-child(1){
            color: #1281E7;
          }
          .grenn{
            color: #16A44F !important;
          }
        }
        .task-content-li-content-divider1{
          position: absolute;
          left: 33.3%;
          height: 100%;
          width: 0;
          border-left: 1px dashed #E2E2E2;
          & > div{
            width: 15px;
            height: 15px;
            background: #FFFFFF;
            border-radius: 50%;
            position: absolute;
            left: -7.5px;
          }
          div:nth-child(1){
            top: -8%;
          }
          div:nth-child(2){
            bottom: -8%;
          }
        }
        .task-content-li-content-divider2{
          position: absolute;
          left: 66.6%;
          top: 50%;
          margin-top: -22px;
          height: 50%;
          width: 0;
          border-left: 1px solid #E2E2E2;
        }
        .task-content-li-content-prompt1{
          width: 25px;
          height: 20px;
          background: url("../../../assets/task-management/set-up-output-prompt.png") no-repeat center;
          background-size: 100% 100%;
          font-size: 12px;
          font-family: PingFang SC-Heavy, PingFang SC;
          font-weight: 800;
          color: #FFFFFF;
          position: absolute;
          top: 10%;
          right: 2%;
        }
        .task-content-li-content-prompt2{
          width: 25px;
          height: 20px;
          background: url("../../../assets/task-management/set-up-output-prompt.png") no-repeat center;
          background-size: 100% 100%;
          font-size: 12px;
          font-family: PingFang SC-Heavy, PingFang SC;
          font-weight: 800;
          color: #FFFFFF;
          position: absolute;
          left: 1%;
          bottom : 16%;
        }
      }
    }
  }

  .data-filtering{
    position: absolute;
    top: 12px;
    right: 3%;
    font-size: 14px;
    color: #545454;
    z-index: 99999999999;
  }
  .task-set{
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px -1px 0px 0px rgba(206,206,206,0.25);
    border-radius: 0px 0px 0px 0px;
    padding: 20px 18px;
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    .task-set-button{
      line-height: 44px;
      background: #4871C0;
      border-radius: 8px 8px 8px 8px;
      font-size: 18px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
    }
  }
  .van-tabs-region{
    height:100% !important
  }
}

.table-empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;

  img {
    width: 160px;
    margin-bottom: 10px;
  }

  span {
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #5e5e5e;
  }
}

::v-deep {
  .van-tabs{
    height: calc(100vh - 130px);
    // overflow-y: auto;
    .van-tabs__content{
      height: calc(100% - 46px);
      .van-tab__pane{
        height: 100%;
        .task-content{
          height: calc(100% - 62px);
          overflow: auto;
        }
      }
    }
  }
  
  .van-tab--active{
    color: #3DA2FF;
  }
  .van-tab{
    font-size: 16px;
    flex: none;
    width: 25%;

  }

  .van-tabs__wrap{
    position: relative;
    z-index: 99999999999;
  }
  .van-sticky--fixed{
    top:49px;
    z-index: 99999999999;
  }

  .van-picker__confirm{
    color: #1989FA;
  }

  .my-task-modification-popup-button .van-button{
    width: 50%;
    height: 62px;
    line-height: 60px;
    background: #ffffff;
    opacity: 1;
    border: 1px solid #eeeeee;
    font-size: 18px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 500;
    color: #3da2ff;
  }

  .van-badge {
    min-width: 13px;
    font-size: 9px;
    background: #FF6157;
    padding: 2px 3px 2px;
    border: 0;
  }

  .van-badge--fixed {
    top: 3px;
    right: -1px;
  }

  .van-tab__text--ellipsis{
    overflow: visible !important;;
  }

  .headNav-righ-icon{
    .van-badge--fixed {
      top: 3px !important;
      right: -1px !important;
    }
  }
  .task-content-li-title-button-update{
    .van-icon{
      margin-left: 4px;
    }
  }
}
</style>
