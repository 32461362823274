<template>
	<div class="patrolDetails-style">
		<headerNav title="详情" leftNav="返回" @leftClick="back"></headerNav>
		<div class="patrolDetails-content">
			<div class="top-detail">
				<img src="@/assets/tx.png" class="tx-img" alt="">
				<div class="name-style">{{info.createId}}</div>
				<div class="time-style">{{getIsToday(info.createDate)}} <span v-if="getIsToday(info.createDate)=='今天'|| getIsToday(info.createTime)=='昨天'">{{info.time}}</span> </div>
			</div>
			<div class="center-details">
				<div class="details-title">巡检时间</div>
				<div class="details-text">{{info.date}}</div>
				<div class="details-title">巡检阶段</div>
				<div class="details-text">{{info.nodeName}}</div>
				<div class="details-title">客户跟随</div>
				<div class="details-text">{{info.isFollow==1?'是':'否'}}</div>
				<div class="details-title">巡检说明</div>
				<div class="details-text">{{info.inspectionRemark}}</div>
			</div>
			<div class="bottom-details">
				<div class="bottomn-title">巡检详情</div>
				<div v-for="item in details" class="patrolProjectlist-style">
					<div class="patrolProjectlist-itemtitle">
						{{item.contentName}}
						<van-tag v-if="item.qualifiedStatus=='1' || item.qualifiedStatus=='2'" class="label-style" plain color="#40D673">符合标准</van-tag>
						<van-tag v-else class="label-style" plain color="#4F7AFD ">整改中</van-tag>
					</div>
					<div class="patrolProjectlist-itemText">
						<span>是否合格：</span><span>{{item.qualifiedStatus=='1' || item.qualifiedStatus=='2'?'是':'否'}}</span>
					</div>
					<div class="patrolProjectlist-itemText">
						<span>提交时间：</span><span>{{item.createDate}}</span>
					</div>
					<div class="patrolProjectlist-itemText">
						<span>备注信息：</span>
						<p>{{item.remark}}</p>
					</div>
					<div class="patrolProjectlist-itemText ">
						<span>现场照片：</span>
						<div class="patrolProjectlist-itemText2">
							<div v-for="(imgItem,imgIndex) in item.imgs" class="img-box"> <img
									@click="photoPreview(item.imgs,imgIndex)" 
									:src="imgItem.webUrl" class="patrolProjectlist-img" alt="">
									</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		ImagePreview
	} from 'vant';
	import api from '@/api/api.js'
	import {
		getBeforeDate,
		isToday
	} from '@/utils/timeCompute'
	export default {
		data() {
			return {
				info: {},
				details: [],
			}
		},
		methods: {
			back() {
				this.$router.go(-1)
			},
			async getPatrolRecordDetails() {
				let obj = {
					order:this.info.id
				}
				let res = await api.getPatrolRecordDetails(obj)
				if (res.code == 200) {
					this.details = res.data
				}
			},
			getIsToday(s) {
				return isToday(s)
			},
			//图片预览
			photoPreview(list, index) {
				let imgList = list.map(e=>e.webUrl)
				let o = {
					images: imgList,
					startPosition: index
				}
				ImagePreview(o);
			},
		},
		mounted() {
			this.info = JSON.parse(sessionStorage.getItem('PatrolDetails'))
			this.info.date = this.info.inspectionTime.slice(0, 10)
			this.info.time = this.info.inspectionTime.slice(11, 16)
			this.getPatrolRecordDetails()
		},
	}
</script>

<style scoped lang="scss">
	.patrolDetails-style {
		.patrolDetails-content {
			height: calc(100vh - 50px);
			overflow: auto;
			padding: 0 18px;
			background: #FFFFFF;

			.top-detail {
				display: flex;
				position: relative;
				height: 56px;
				align-items: center;
				border-bottom: 1px solid #E6E6E6;

				.tx-img {
					width: 36px;
					height: 36px;
					margin-right: 12px;
				}

				.name-style {
					font-size: 16px;
					// font-family: Source Han Sans SC;
					font-weight: 500;
					color: #333333;
				}

				.time-style {
					position: absolute;
					right: 0;
					font-size: 12px;
					font-family: Source Han Sans SC;
					font-weight: 400;
					color: #AAAAAA;
				}
			}

			.center-details {
				text-align: left;

				.details-title {
					font-size: 13px;
					font-family: Source Han Sans SC;
					font-weight: 400;
					color: #AAAAAA;
					margin-top: 37px;
				}

				.details-text {
					font-size: 15px;
					font-weight: 400;
					color: #333333;
				}
			}

			.bottom-details {
				text-align: left;

				.bottomn-title {
					font-size: 13px;
					font-family: Source Han Sans SC;
					font-weight: 400;
					color: #AAAAAA;
					height: 23px;
					border-bottom: 1px solid #E6E6E6;
					text-align: left;
					margin-top: 37px;
				}

				.patrolProjectlist-style {
					border-bottom: 1px solid #E6E6E6;

					.patrolProjectlist-itemtitle {
						font-size: 15px;
						// font-family: Source Han Sans SC;
						font-weight: 400;
						color: #333333;
						// margin-top: 15px;
						line-height: 33px;
						// .label-style-blue{
						// 	color: #4F7AFD ;
						// 	margin-left: 5px;
						// 	font-size: 9px;
						// 	font-family: Source Han Sans SC;
						// 	border-radius: 2px;
						// }
						// .label-style-green{
						// 	color: #40D673;
						// }
					}

					.patrolProjectlist-itemText {
						font-size: 13px;
						font-family: Source Han Sans SC;
						font-weight: 400;
						color: #777777;
						display: flex;
						margin: 6px 0;

						span {
							display: inline-block;
						}

						p {
							margin: 0;
							width: 273px;
						}
						.patrolProjectlist-img {
							width: 85px;
							height: 85px;
							margin: 0;
							margin-right: 4px;
						}
					}
					.patrolProjectlist-itemText2 {
						width: calc(100% - 70px);
						display: flex;
						flex-wrap: wrap;
						// justify-content: space-between;
					}
				}

				.patrolProjectlist-style:last-child {
					border: none;
				}

			}
		}
	}
</style>
