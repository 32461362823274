<template>
  <div class="content">
    <headerNav
      leftNav=" "
      :title="navTitle"
      @leftClick="back"
    ></headerNav>
    <main class="content-main">
      <van-form @submit="onSubmitTaskSet" :key="fromRestFields">
        <div style="overflow: auto;">
          <van-field 
            readonly
            disabled
            name="taskDate"
            :value="taskSetList.taskDate"
            label="时间"
            placeholder="请选择"
            class="from-item"
            @focus="forbid"
          >
          </van-field>
          <div class="task—allocation">
            <div class="task—allocation-title">
              <div></div><span>任务分配</span>
            </div>
            <div class="task—allocation-describe">上级指派任务：未分配 <span>{{ undistributed }}</span> {{ unit }}; 已分配 <span>{{ assigned }}</span>{{ unit }}</div>
          </div>
          <van-field v-for="(item,index) in userList" :key="index"
            v-model="item.value"
            :name="item.code"
            type="digit"
            :maxlength="maxlength"
            :label="item.name"
            placeholder="请输入"
            class="from-item"
            @input="changeAssignedFn(item)"
          >
            <template #button>{{unit}}</template>
          </van-field>
        </div>

        <div class="task-button">
          <span class="task-button-tip">*提示：提交后需上级进行审批，审批通过任务生效</span>
          <div class="my-task-modification-popup-button">
            <van-button native-type="button" @click="back">取消</van-button>
            <van-button native-type="submit">确认</van-button>
          </div>
        </div>
      </van-form>
    </main>

    <!-- 日期弹窗 -->
    <van-popup
      v-model="datePopupShow"
      class="my-date-popup"
      overlay-class="my-popup-cover"
      position="bottom"
      :safe-area-inset-bottom="true"
      :lazy-render="false"
    >
      <div class="my-date-popup-box">
        <div class="my-date-popup-content">
          <van-picker
            show-toolbar
            title="选择日期"
            :columns="columns"
            toolbar-position="top"
            ref="myPicker"
            style="height: 100%"
            @confirm="dateSureSearch"
            @cancel="dateRestSearch"
          >
          </van-picker>
        </div>
      </div>
    </van-popup>

  </div>
</template>

<script>
import moment from "moment";
import { Toast } from 'vant';
import api from "@/api/task-management/setup.js";
export default {
  components: {},
  data() {
    return {
      navTitle:'下级任务设置',
      datePopupShow: false,
      year: moment().year(),
      month: moment().month() + 1,
      columns: [
        { values: [], defaultIndex: moment().year() },
        { values: [], defaultIndex: moment().month() + 1 },
      ],
      showTaskTypePicker: false,
      taskTypeList:[
        { text: "转单产值", value: "transfer_output", unit:'万元',max:5 },
        { text: "补齐产值", value: "supply_output", unit:'万元',max:5 },
        { text: "转单量", value: 'transfer_count', unit:'个',max:4 },
        { text: "补齐量", value: 'supply_count', unit:'个',max:4 },
        { text: "签单量", value: 'signed_count', unit:'个',max:4 },
        { text: "到店量", value: 'shop_count', unit:'个',max:4 },
        { text: "线索量", value: 'clue_count', unit:'个',max:4 },
      ],
      queryInfo:{},
      undistributed:0,
      undistributedTransition:0,
      assigned:0,
      unit:"个",
      maxlength:5,
      fromRestFields: 0,
      taskSetList: {
        id:"",
        roleCode:"",
        taskDate: "",
        termId:"",
        multiTerm:{},
      },
      userList:[],
      taskValueList:[],
    };
  },
  created() {
    this.setDateColumns()
  },
  async mounted() {
    let _this = this;
    this.queryInfo = JSON.parse(this.$route.query.item)
    let {taskDate,productionJobValue,id,roleCode,termId} = this.queryInfo
    this.undistributed = productionJobValue
    this.undistributedTransition = productionJobValue
    this.taskTypeList.forEach((item) => {
      if(item.value == termId){
        this.unit = item.unit
        this.maxlength = item.max
      }
    });
    this.taskSetList = {
      ...this.taskSetList,
      id,
      roleCode,
      taskDate,
      termId,
    }
    await this.getTaskValue(id)
    await this.getUserList(roleCode,termId)
  },
  methods: {
    // 路由返回
    back() {
      this.$router.go(-1);
    },
    //初始化日期选择范围
    setDateColumns() {
      let y = [];
      let nowYear = moment().year() + 2;
      for (let i = nowYear; i > nowYear - 21; i--) {
        y.push(i);
      }
      let m = [];
      for (let i = 1; i < 13; i++) {
        i = i < 10 ? "0" + i : i.toString()
        m.push(i);
      }
      this.columns[0].values = y;
      this.columns[1].values = m;
    },
    //打开日期选择
    openChooseDate(type) {
      this.year = this.taskSetList.taskDate ? this.taskSetList.taskDate.split('-')[0] : moment().year()
      this.month = this.taskSetList.taskDate ? this.taskSetList.taskDate.split('-')[1] : (moment().month() + 1 ).toString()
      this.$refs.myPicker && this.$refs.myPicker.setValues([Number(this.year), this.month]);
      this.datePopupShow = true;
    },
    // 日期弹窗 取消
    dateRestSearch() {
      this.year = moment().year();
      this.month = moment().month() + 1 ;
      this.datePopupShow = false;
    },
    // 日期弹窗 确认
    dateSureSearch() {
      this.year = this.$refs.myPicker.getValues()[0];
      this.month = this.$refs.myPicker.getValues()[1];
      let date = this.year + "-" + this.month;
      this.taskSetList.taskDate = date
      //确认
      this.datePopupShow = false;
    },
    // 查询子级任务列表
    async getTaskValue(id){
      let params = {
        taskParentId:id,
        taskType:"P"
      }
      await api.getTaskMainById(params).then((res)=>{
        if (res.code == 200) {
          this.taskValueList = res.data
        }else{
          Toast.fail(res.msg)
        }
      })
    },
    // 获取分配的角色
    async getUserList(roleCode,termId){
      let params = {
        "roleCode":roleCode,
        "departmentFlag":"1",
        "termId":termId
      }
      await api.getTitleByRole(params).then((res)=>{
        if (res.code == 200) {
          if(this.taskValueList.length > 0){
            this.taskValueList.forEach((item,index)=>{
              res.data.forEach((item1)=>{
                if(item.userId == item1.code){
                  item1.value = item.productionJobValue
                }
              })
            })
          }else{
            res.data.forEach((item)=>{
              item.value = ""
            })
          }
          this.userList = res.data
        }else{
          Toast.fail(res.msg)
        }
      })
    },
    // 计算已分配
    changeAssignedFn(item){
      this.assigned = this.userList.reduce((acc, obj) =>acc + Number(obj.value||0), 0);
      this.undistributed =this.undistributedTransition - this.assigned;
      if(this.undistributed <= 0){
        this.undistributed = 0
      }
    },
    // 禁止弹出软键盘
    forbid(){
      document.activeElement.blur()
    },
    // ---- form表单 提交---------
    onSubmitTaskSet(values) {
      if(this.undistributed > 0){
        Toast.fail('任务未分配完，请重新分配');
        return false
      }
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      let data = this.userList.map((item)=>{
        return {[item.code]:item.value||"0" }
      })
      let result = data.reduce((acc, current) => {
        return {...acc, ...current};
      }, {});
      this.taskSetList.multiTerm = JSON.stringify(result)
      api.setTasks(this.taskSetList).then((res)=>{
        Toast.clear()
        if (res.code == 200) {
          Toast.success('操作成功');
          let _this = this;
          setTimeout(()=>{
            _this.back()
          },1000)
        }else{
          Toast.fail(res.msg)
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.content ::v-deep {
  height: 100vh;
  overflow: auto;
  background: #f3f5f7;
  .van-form {
    height: calc(100vh - 165px);
    overflow: auto;
    .from-item {
      border-bottom: 8px solid #f3f5f7;
      .van-field__label{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .task-button{
    width: 100%;
    padding: 10px 18px 30px;
    position: fixed;
    bottom: 0;
    background: #FFFFFF;
    box-shadow: 0px -1px 8px 0px rgba(0,0,0,0.04);
    box-sizing: border-box;
    .task-button-tip{
      font-size: 12px;
      color: #999999;
      font-family: PingFang SC-Medium, PingFang SC;
      text-align: center;
    }
  }

  .my-task-modification-popup-button{
    margin-top: 10px;
    display: flex;
    gap: 12px;
    .van-button{
      width: 50%;
      height: 50px;
      font-size: 18px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      
      &:nth-child(2){
        color: #FFFFFF;
        background: #4871C0;
        border-radius: 4px 4px 4px 4px;
      }
    }
  }

  .task—allocation{
    margin:8px 0 8px 14px;
    .task—allocation-title{
      height: 24px;
      font-size: 16px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
      text-align: left;
      display: flex;
      align-items: center;
      & > div{
        width: 4px;
        height: 17px;
        background: #0E8BFF;
        border-radius: 18px 18px 18px 18px;
        margin-right: 6px;
      }

    }
    .task—allocation-describe{
      height: 24px;
      font-size: 12px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #999999;
      text-align: left;
      margin-left: 10px;
      & > span{
        color: #4871C0;
      }
    }
  }

}
</style>
