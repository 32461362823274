<template>
    <div style="background: #F5F5F5;">
        <headerNav title="话务总监数据看板">
            <template>
                <span @click="openSearch" class="headNav-righ-text">
                    筛选
                    <van-icon name="filter-o" />
                </span>
            </template>
        </headerNav>
        <div class="page-box">
            <div class="sjzb">
                <h3><img :src="require('@/assets/hw-s1.svg')">数据指标</h3>
                <div class="sjzb-item item1">
                    <div class="item-c1">
                        <div class="item-title">{{ clueDataObj[0].itemName }}</div>
                        <div class="item-item-num">{{ clueDataObj[0].itemNum }}<span>条</span></div>
                        <div class="item-rate"
                            :style="clueDataObj[0].compareRate > 0 ? 'color:#FF6157' : clueDataObj[0].compareRate < 0 ? 'color:#17CA5E' : ''">
                            {{ clueDataObj[0].compareRate }}%
                            <img v-if="clueDataObj[0].compareRate > 0" :src="require('@/assets/hw-s2.svg')">
                            <img v-if="clueDataObj[0].compareRate < 0" :src="require('@/assets/hw-s3.svg')">
                        </div>
                    </div>
                    <div class="item-c2">
                        <div class="item-title">{{ clueDataObj[1].itemName }}</div>
                        <div class="item-item-num">{{ clueDataObj[1].itemNum }}<span>条</span></div>
                    </div>
                    <div class="item-c2">
                        <div class="item-title">{{ clueDataObj[2].itemName }}</div>
                        <div class="item-item-num">{{ clueDataObj[2].itemNum }}<span>条</span></div>
                    </div>
                    <img src="@/assets/hw-bg3.png" alt="">
                </div>
                <div class="sjzb-item item2">
                    <div class="item-c1">
                        <div class="item-title">{{ clueDataObj[3].itemName }}</div>
                        <div class="item-item-num">{{ clueDataObj[3].itemNum }}<span>条</span></div>
                        <div class="item-rate"
                            :style="clueDataObj[3].compareRate > 0 ? 'color:#FF6157' : clueDataObj[3].compareRate < 0 ? 'color:#17CA5E' : ''">
                            {{ clueDataObj[3].compareRate }}%
                            <img v-if="clueDataObj[3].compareRate > 0" :src="require('@/assets/hw-s2.svg')">
                            <img v-if="clueDataObj[3].compareRate < 0" :src="require('@/assets/hw-s3.svg')">
                        </div>
                    </div>
                    <div class="item-c2">
                        <div class="item-title">{{ clueDataObj[4].itemName }}</div>
                        <div class="item-item-num">{{ clueDataObj[4].itemNum }}<span>条</span></div>
                    </div>
                    <div class="item-c2">
                        <div class="item-title">{{ clueDataObj[5].itemName }}</div>
                        <div class="item-item-num">{{ clueDataObj[5].itemNum }}<span>条</span></div>
                    </div>
                    <img src="@/assets/hw-bg4.png" alt="">
                </div>
                <div class="sjzb-item item3">
                    <div class="item-c1">
                        <div class="item-title">{{ clueDataObj[6].itemName }}</div>
                        <div class="item-item-num">{{ clueDataObj[6].itemNum }}<span>条</span></div>
                        <div class="item-rate"
                            :style="clueDataObj[6].compareRate > 0 ? 'color:#FF6157' : clueDataObj[6].compareRate < 0 ? 'color:#17CA5E' : ''">
                            {{ clueDataObj[6].compareRate }}%
                            <img v-if="clueDataObj[6].compareRate > 0" :src="require('@/assets/hw-s2.svg')">
                            <img v-if="clueDataObj[6].compareRate < 0" :src="require('@/assets/hw-s3.svg')">
                        </div>
                    </div>
                    <div class="item-c2">
                        <div class="item-title">{{ clueDataObj[7].itemName }}</div>
                        <div class="item-item-num">{{ clueDataObj[7].itemNum }}<span>条</span></div>
                    </div>
                    <div class="item-c2">
                        <div class="item-title">{{ clueDataObj[8].itemName }}</div>
                        <div class="item-item-num">{{ clueDataObj[8].itemNum }}<span>条</span></div>
                    </div>
                    <img src="@/assets/hw-bg5.png" alt="">
                </div>
                <div class="sjzb-item item4">
                    <div class="item-child">
                        <p>{{ clueDataObj[9].itemName }}</p>
                        <p>{{ clueDataObj[9].itemNum }}<span>个</span></p>
                        <p
                            :style="clueDataObj[9].compareRate > 0 ? 'color:#FF6157' : clueDataObj[9].compareRate < 0 ? 'color:#17CA5E' : ''">
                            {{ clueDataObj[9].compareRate }}%
                            <img v-if="clueDataObj[9].compareRate > 0" :src="require('@/assets/hw-s2.svg')">
                            <img v-if="clueDataObj[9].compareRate < 0" :src="require('@/assets/hw-s3.svg')">
                        </p>
                        <div>
                            <img :src="require('@/assets/hw-s4.svg')">
                        </div>
                        <img class="hw-bg" src="@/assets/hw-b1.png" alt="">
                    </div>
                    <div class="item-child">
                        <p>{{ clueDataObj[10].itemName }}</p>
                        <p>{{ clueDataObj[10].itemNum }}<span>个</span></p>
                        <p
                            :style="clueDataObj[10].compareRate > 0 ? 'color:#FF6157' : clueDataObj[10].compareRate < 0 ? 'color:#17CA5E' : ''">
                            {{ clueDataObj[10].compareRate }}%
                            <img v-if="clueDataObj[10].compareRate > 0" :src="require('@/assets/hw-s2.svg')">
                            <img v-if="clueDataObj[10].compareRate < 0" :src="require('@/assets/hw-s3.svg')">
                        </p>
                        <div>
                            <img :src="require('@/assets/hw-s5.svg')">
                        </div>
                        <img class="hw-bg" src="@/assets/hw-b2.png" alt="">

                    </div>
                </div>
            </div>
            <div class="moudule2-box">
                <h3><img :src="require('@/assets/hw-s6.svg')">话务进线排行榜<span>按“到店率”排序展示前5名</span></h3>
                <el-table :data="inboundLeaderboardData" header-row-class-name="table-head-class">
                    <el-table-column type="index" label="TOP" align="center" width="60">
                        <template #default="scope">
                            <span class="cell-img-box" v-if="scope.$index == 0"><img
                                    :src="require('@/assets/hw-s7.svg')"></span>
                            <span class="cell-img-box" v-else-if="scope.$index == 1"><img
                                    :src="require('@/assets/hw-s8.svg')"></span>
                            <span class="cell-img-box" v-else-if="scope.$index == 2"><img
                                    :src="require('@/assets/hw-s10.svg')"></span>
                            <span v-else>{{ scope.$index + 1 }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="telephonistName" label="话务手" width="100" />
                    <el-table-column prop="marketName" label="市场" width="80">
                        <template #default="scope">
                            {{ scope.row.marketName.replace('市场', '') }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="clueAllocation" label="线索量" align="right" />
                    <el-table-column prop="clueValid" label="有效量" align="right" />
                    <el-table-column prop="validShopNum" label="有效到店量" width="100" align="right" />
                    <el-table-column prop="shopRate" label="到店率" align="right">
                        <template #default="scope">
                            {{ scope.row.shopRate }}%
                        </template>
                    </el-table-column>
                    <div slot="empty">
                        <div class="table-empty">
                            <img :src="require('@/assets/m-empty.png')">
                            <span>暂无数据</span>
                        </div>
                    </div>
                </el-table>
                <div class="checkDetails" @click="checkDetails">
                    查看详情
                    <van-icon name="down" size="16" style="transform: rotate(-90deg);" />
                </div>
            </div>
            <div class="moudule3-box">
                <h3>
                    <img :src="require('@/assets/hw-s11.svg')">渠道线索占比
                </h3>
                <div class="moudele3-content">
                    <ul class="mediaType">
                        <li v-for="item in midiaTypeList" :class="item.value == mediaType ? 'active-li' : ''"
                            @click="mediaTypeChange(item)">{{ item.name
                            }}</li>
                    </ul>
                    <div class="moudele3-echarts">
                        <div class="channelClueEcharts"></div>
                        <ul class="echarts-lend">
                            <li v-for="(item, index) in channelClueDataList">
                                <p><span class="lendcolor" :style="'background:' + lendColors[index]"></span>{{
                                    item.itemName }}</p>
                                <p>{{ item.itemNum }}<span>条</span></p>
                                <p>{{ item.itemRate }}</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="moudule4-box">
                <h3>
                    <img :src="require('@/assets/hw-s12.svg')">市场进线走势
                </h3>
                <div class="moudule4-content">
                    <div class="clueTrendEcharts"></div>
                </div>
            </div>
        </div>


        <van-popup v-model="popupShow" class="my-popup" overlay-class="my-popup-cover" position="bottom"
            :safe-area-inset-bottom="true" :style="{ height: '374px' }">
            <div class="my-popup-box">
                <div class="my-popup-head">
                    筛选
                    <van-icon name="cross" size="16" @click="popupShow = false" />
                </div>
                <div class="my-popup-content">
                    <p>选择时间</p>
                    <ul>
                        <li v-for="item in dateTypeList" :class="item.type == dateType ? 'active-li' : ''"
                            @click="changeDateType(item)">{{ item.name }}</li>
                    </ul>
                    <p>选择市场</p>
                    <ul>
                        <li v-for="item in marketList" :class="item.value == marketCode ? 'active-li' : ''"
                            @click="changeMarket(item)">{{ item.text }}</li>
                    </ul>
                </div>
                <div class="my-popup-footer">
                    <van-button type="default" @click="restSearch">重置</van-button>
                    <van-button type="info" @click="sureSearch">确认</van-button>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import api from '@/api/api.js'
import moment from 'moment';
import * as echarts from 'echarts';

export default {
    data () {
        return {
            popupShow: false,
            lendColors: [],
            dateType: '1',
            chooseSureDateType: '1',
            dateTypeList: [
                {
                    type: '1',
                    name: '今日',
                },
                {
                    type: '2',
                    name: '本周',
                },
                {
                    type: '3',
                    name: '本月',
                },
                {
                    type: '10',
                    name: '本年',
                }
            ],
            marketCode: 'all',
            chooseMarketCode: 'all',
            marketList: [
                { text: '全部', value: 'all' },
                { text: '济南', value: '370100' },
                { text: '青岛', value: '370200' },
                { text: '淄博', value: '370300' },
                { text: '烟台', value: '370600' },
                { text: '潍坊', value: '370700' },
                { text: '威海', value: '371000' },
                { text: '临沂', value: '371300' },
            ],
            mediaType: 'all',
            midiaTypeList: [
                {
                    name: '全部',
                    value: 'all'
                },
                {
                    name: '新媒体',
                    value: '1'
                },
                {
                    name: '传统媒体',
                    value: '0'
                }
            ],
            clueDataObj: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
            inboundLeaderboardData: [],
            channelClueDataList: [],
            clueTrendLineData: {}
        }
    },
    methods: {
        //打开筛选
        openSearch () {
            this.popupShow = true
            this.dateType = this.chooseSureDateType
            this.marketCode = this.chooseMarketCode
        },
        changeDateType (item) {
            //时间类型改变
            this.dateType = item.type
        },
        changeMarket (item) {
            //市场改变
            this.marketCode = item.value
        },
        restSearch () {
            //重置
            this.dateType = '1'
            this.marketCode = 'all'
            this.chooseMarketCode = this.marketCode
            this.chooseSureDateType = this.dateType
            this.popupShow = false
            this.heavyLoad()
        },
        sureSearch () {
            //确认搜索
            this.chooseMarketCode = this.marketCode
            this.chooseSureDateType = this.dateType
            this.popupShow = false
            this.heavyLoad()
        },
        //获取线索数据信息
        async getClueData () {
            const { type, reportData1, reportData2, oldReportData1, oldReportData2, marketCodeValue } = this.getParams()
            let obj = {
                type,
                reportData1,
                reportData2,
                marketCode: marketCodeValue,
                oldReportData1,
                oldReportData2,
            }
            let res = await api.clueData({ queryStr: JSON.stringify(obj) })
            this.clueDataObj = res.data
        },
        //话务进线排行榜
        async getInboundLeaderboard () {
            let { type, marketCodeValue, reportData1, reportData2, contrastData1, contrastData2  } = this.getParams()
            let sortField = 'shop_rate1,valid_shop_num,clue_valid'
            let obj = {
                ext: [1, 2, 5, 6].find(e => e == type) ? 'day' : 'month',
                queryStr: JSON.stringify(
                    {
                        ext: [1, 2, 5, 6].find(e => e == type) ? 'day' : 'month',
                        marketCode: marketCodeValue,
                        reportData1,
                        reportData2,
                        oldReportData1: contrastData1,
                        oldReportData2: contrastData2,
                        type,
                        sortOrder: 'descend',
                        sortField: sortField
                    }
                )
            }
            let res = await api.inboundLeaderboard(obj)
            this.inboundLeaderboardData = res.data?.splice(0, 5)
        },
        //查看详情
        checkDetails () {
            const { marketCodeValue } = this.getParams()
            this.$router.push({
                name: 'TdirectorDetailsName',
                query: {
                    type: this.dateType,
                    marketCodeValue
                }
            })
        },
        //请求参数
        getParams (timeType) {
            let type = this.chooseSureDateType
            let reportData1 = ''
            let reportData2 = ''
            let oldReportData1 = ''
            let oldReportData2 = ''
            let contrastData1 = ''
            let contrastData2 = ''
            if (this.chooseSureDateType == 1) {
                //本日
                reportData1 = moment().format('YYYY-MM-DD');
                reportData2 = moment().format('YYYY-MM-DD');
                oldReportData1 = moment(moment().year(moment().year() - 1)).format('YYYY-MM-DD');
                oldReportData2 = moment(moment().year(moment().year() - 1)).format('YYYY-MM-DD');
                contrastData1 = moment().subtract(1, 'days').format('YYYY-MM-DD');
                contrastData2 = moment().subtract(1, 'days').format('YYYY-MM-DD');
            } else if (this.chooseSureDateType == 2) {
                type = 1
                //本周
                reportData1 = moment().startOf('isoweek').format('YYYY-MM-DD') //本周一
                reportData2 = moment().format('YYYY-MM-DD'); //当天
                oldReportData1 = moment(reportData1).subtract(1, 'years').format('YYYY-MM-DD');
                oldReportData2 = moment(reportData2).subtract(1, 'years').format('YYYY-MM-DD');
                contrastData1 = moment(reportData1).subtract(7, 'days').format('YYYY-MM-DD');
                contrastData2 = moment(reportData2).subtract(7, 'days').format('YYYY-MM-DD')
            } else if (this.chooseSureDateType == 3) {
                type = 1
                //本月
                reportData1 = moment().startOf('month').format('YYYY-MM-DD')
                reportData2 = moment().format('YYYY-MM-DD'); //当天
                oldReportData1 = moment(reportData1).subtract(1, 'years').format('YYYY-MM-DD');
                oldReportData2 = moment(reportData2).subtract(1, 'years').format('YYYY-MM-DD');
                contrastData1 = moment(reportData1).subtract(1, 'month').format('YYYY-MM-DD');
                contrastData2 = moment(reportData2).subtract(1, 'month').format('YYYY-MM-DD')
            } else if (this.chooseSureDateType == 6) {
                type = 1
                //昨日
                reportData1 = moment().subtract(1, 'day').format('YYYY-MM-DD');
                reportData2 = moment().subtract(1, 'day').format('YYYY-MM-DD');
                oldReportData1 = moment(reportData1).subtract(1, 'years').format('YYYY-MM-DD');
                oldReportData2 = moment(reportData2).subtract(1, 'years').format('YYYY-MM-DD');
                contrastData1 = moment().subtract(2, 'days').format('YYYY-MM-DD');
                contrastData2 = moment().subtract(2, 'days').format('YYYY-MM-DD');
            } else if (this.chooseSureDateType == 10) {
                //本年
                if (moment().month() > 1 && timeType != 'day') {
                    //当前本年大于2月 
                    type = '3'
                    reportData1 = moment().startOf('year').format('YYYY-MM')
                    reportData2 = moment().format('YYYY-MM'); //当月
                    oldReportData1 = moment(reportData1).subtract(1, 'years').format('YYYY-MM');
                    oldReportData2 = moment(reportData2).subtract(1, 'years').format('YYYY-MM');
                    contrastData1 = moment(reportData1).subtract(1, 'years').format('YYYY-MM');
                    contrastData2 = moment(reportData2).subtract(1, 'years').format('YYYY-MM');
                } else {
                    //当前本年不大于2月
                    type = '1'
                    reportData1 = moment().startOf('year').format('YYYY-MM-DD')
                    reportData2 = moment().format('YYYY-MM-DD'); //当天
                    oldReportData1 = moment(reportData1).subtract(1, 'years').format('YYYY-MM-DD');
                    oldReportData2 = moment(reportData2).subtract(1, 'years').format('YYYY-MM-DD');
                    contrastData1 = moment(reportData1).subtract(1, 'years').format('YYYY-MM');
                    contrastData2 = moment(reportData2).subtract(1, 'years').format('YYYY-MM');
                }
            }
            return {
                type, reportData1, reportData2,
                oldReportData1, oldReportData2,
                marketCodeValue: this.chooseMarketCode ? this.chooseMarketCode : 'all',
                channelType: this.mediaType ? this.mediaType : 'all',
                contrastData1,
                contrastData2

            }
        },
        mediaTypeChange (item) {
            //媒体类型改变
            this.mediaType = item.value
            this.getChannelClueData()
        },
        //获取渠道线索占比
        async getChannelClueData () {
            const { marketCodeValue, reportData1, reportData2, channelType } = this.getParams('day')
            let obj = {
                queryStr: JSON.stringify({
                    marketCode: marketCodeValue,
                    reportData1,
                    reportData2,
                    channelType,
                })
            }
            let res = await api.channelClueData(obj)
            this.channelClueDataList = res.data?.list
            let data = this.channelClueDataList.map(e => {
                return {
                    value: e.itemNum,
                    name: e.itemName,
                    ...e
                }
            })
            let oprions = {
                tooltip: {
                    trigger: 'item',
                    backgroundColor: '#41A2F4',
                    borderColor: '#41A2F4',
                    textStyle: {
                        color: '#FFFFFF'
                    },
                    position: 'top',
                    padding: 0,
                    formatter: (params) => {
                        // formatter: '{name|{b}}\n{time|{c} 小时}',
                        return ` 
                            <div class="pie-tooltip">
                                <p class="m-span">${params.marker}${params.name}：</p>
                                <p>${params.value}人</p>
                            </div> 
                        `
                    },
                },
                series: [
                    {
                        name: 'Access From',
                        type: 'pie',
                        selectedMode: 'single',
                        animation: false, //去掉动画效果
                        silent: true,
                        radius: [0, '50%'],
                        width: '100%',
                        label: {
                            position: 'center',
                            fontSize: 14,
                            formatter: '{num|{c}}\n{name|{b}}',
                            rich: {
                                num: {
                                    fontSize: 22,
                                    fontWeight: 400,
                                    color: '#3DA2FF',
                                    fontFamily: 'Bahnschrift'
                                },
                                name: {
                                    fontSize: 14,
                                    color: '#8C8C8C',
                                    fontWeight: 500,
                                    padding: [10, 0, 0, 0]
                                },
                            }
                        },
                        data: [
                            { value: res.data?.sum, name: '线索总数', itemStyle: { color: '#FFFFFF' } },
                        ],
                    },
                    {
                        name: 'Access From',
                        type: 'pie',
                        selectedMode: 'single',
                        animation: false, //去掉动画效果
                        silent: true,
                        radius: ['50%', '60%'],
                        width: '100%',
                        label: {
                            position: 'inner',
                            fontSize: 14
                        },
                        data: [
                            { value: 1548, name: '', itemStyle: { color: '#F3F6F7' } },
                        ],
                    },
                    {
                        name: 'Access From',
                        type: 'pie',
                        itemStyle: {
                            borderColor: '#fff',
                            borderWidth: 2
                        },
                        radius: ['60%', '80%'],
                        width: '100%',
                        label: {
                            show: false,
                            position: 'center'
                        },
                        data: data,
                    }
                ]
            }
            this.echarteInit('.channelClueEcharts', oprions)
        },
        //线索仅限走势图折线
        async getClueTrandLine () {
            let { type, reportData1, reportData2, marketCodeValue } = this.getParams()
            let obj = {
                queryStr: JSON.stringify({
                    type,
                    marketCode: marketCodeValue,
                    Q_reportData_BW: reportData1 + '~' + reportData2
                })
            }
            let res = await api.clueTrandLine(obj)
            this.clueTrendLineData = res.data
            let oprions = {
                tooltip: {
                    trigger: 'axis',
                    backgroundColor: '#3DA2FF',
                    borderColor: '#3DA2FF',
                    textStyle: {
                        color: '#FFFFFF'
                    },
                    padding: 0,
                    position: 'top',
                    formatter: (params) => {
                        return `
                            <div class="myecharts-toop-box">  
                                <div class="myecharts-toop"><span class="m-span">${params[0].marker} ${params[0].seriesName}：</span> <span class="m-span">${params[0].data} 条</span></div>    
                                <div class="myecharts-toop"><span class="m-span">${params[1].marker} ${params[1].seriesName}：</span> <span class="m-span">${params[1].data} 条</span></div>
                            </div>   
                        `
                    },
                },
                legend: {
                    selectedMode: false,
                    right: 0,
                    top: 23,
                    icon: 'roundRect',
                    data: ['线索量', '有效线索量']
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true,
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: this.clueTrendLineData.xAxis,
                },
                yAxis: {
                    type: 'value',
                    name: '(条)'
                },
                series: [
                    {
                        name: '线索量',
                        type: 'line',
                        data: this.clueTrendLineData['线索数'],
                        color: '#2CCCAE',
                    },
                    {
                        name: '有效线索量',
                        type: 'line',
                        data: this.clueTrendLineData['有效线索数'],
                        color: '#CC6AFB'
                    }
                ]
            }
            this.echarteInit('.clueTrendEcharts', oprions)
        },
        //数据重载
        heavyLoad () {
            this.getClueData()
            this.getInboundLeaderboard()
            this.getChannelClueData()
            this.getClueTrandLine()
        },
        //加载echarts
        echarteInit (calssName, option) {
            let chartElement = document.querySelector(calssName);
            // 有的话就获取已有echarts实例的DOM节点
            let echart = echarts.getInstanceByDom(chartElement)
            // 如果不存在，就进行初始化
            if (echart == null) {
                echart = echarts.init(chartElement);
            }
            echart.setOption(option, true);
            if (calssName == '.channelClueEcharts') {
                var o = echart.getOption();
                this.lendColors = o.color
            }
        }
    },
    created () {

    },
    mounted () {
        let _this = this
        this.$nextTick(() => {
            _this.heavyLoad()
        })
    }
}
</script>

<style lang="scss" scoped>
.headNav-righ-text {
    font-size: 14px;
}

.my-popup-cover {
    background: rgba(0, 0, 0, 0.5);
}

.my-popup {
    background: #FFFFFF;
    border-radius: 16px 16px 0px 0px;

    .my-popup-box {
        .my-popup-head {
            line-height: 48px;
            border-bottom: 1px solid #DFDFDF;
            text-align: center;
            font-size: 16px;
            font-family: PingFang SC-Bold, PingFang SC;
            font-weight: bold;
            color: #424242;
            position: relative;

            i {
                position: absolute;
                right: 19px;
                top: calc(50% - 8px);
            }
        }

        .my-popup-content {
            padding: 16px;
            padding-bottom: 10px;

            &>p {
                text-align: left;
                font-size: 14px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #424242;
            }

            ul {
                display: flex;
                flex-wrap: wrap;
                gap: 14px;
                margin-bottom: 21px;
            }

            li {
                width: 75px;
                line-height: 32px;
                background: #F9F9F9;
                border-radius: 4px 4px 4px 4px;
                font-size: 14px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
            }

            .active-li {
                background: #3DA2FF;
                color: #FFFFFF;
            }
        }

        .my-popup-footer {
            button {
                width: 164px;
                height: 44px;
                border-radius: 8px;

                &:first-child {
                    margin-right: 12px;
                }
            }
        }
    }


}

.page-box {
    height: calc(100vh - 50px);
    overflow: auto;

    h3 {
        font-size: 19px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #333333;
        margin: 0;
        margin-bottom: 16px;
        display: flex;
        align-items: center;

        img {
            width: 22px;
            height: 22px;
            margin-right: 6px;
        }

        span {
            font-size: 14px;
            font-family: PingFang SC-Reologular, PingFang SC;
            font-weight: 400;
            color: #4F4F4F;
            margin-left: 8px;
            margin-top: 6px;
        }
    }

    .sjzb {
        padding: 12px;
        margin-bottom: 16px;
        background: #FFFFFF;
        border-radius: 0px 0px 8px 8px;

        .sjzb-item {
            height: 100px;
            border-radius: 7px;
            display: flex;
            width: 100%;
            margin-bottom: 11px;
            overflow: hidden;
            z-index: 1;
            position: relative;

            .item-c1 {
                width: 36%;
                height: 100%;
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: center;

                padding: 0 10px;

                .item-title {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 112px;
                    height: 26px;
                    background: #E7F6FF;
                    border-radius: 2px;
                    line-height: 26px;
                    text-align: center;
                    margin-bottom: 13px;
                    font-weight: bold;
                }

                .item-item-num {
                    padding-top: 25px;
                    font-size: 24px;
                }

                .item-rate {
                    padding-top: 5px;
                }
            }

            .item-c2 {
                height: 100%;
                width: 32%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 0 10px;
                position: relative;

                &:nth-child(2)::after {
                    content: '';
                    position: absolute;
                    width: 1px;
                    height: 40px;
                    right: 2px;
                    top: calc(50% - 20px);
                }
            }

            .item-title {
                font-size: 14px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                text-align: left;
                margin-bottom: 6px;
            }

            .item-item-num {
                font-size: 18px;
                font-family: Bahnschrift-SemiLight, Bahnschrift;
                font-weight: normal;
                color: #333333;
                text-align: left;
                font-weight: 300;

                span {
                    font-size: 13px;
                    font-family: Bahnschrift-Light, Bahnschrift;
                    font-weight: 300;
                    color: #333333;
                    margin-left: 3px;
                }
            }

            .item-rate {
                font-size: 16px;
                text-align: left;
                font-family: Bahnschrift-SemiLight, Bahnschrift;
                font-weight: normal;

                img {
                    width: 14px;
                    height: 14px;
                    margin-left: 4px;
                }
            }

            .item-child {
                flex: 1;
                position: relative;
                padding-top: 13px;
                padding-left: 14px;
                text-align: left;

                p {
                    margin: 0;
                }

                p:nth-child(1) {
                    font-size: 16px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    margin-bottom: 6px;
                }

                p:nth-child(2) {
                    font-size: 24px;
                    font-family: Bahnschrift-SemiLight, Bahnschrift;
                    font-weight: normal;
                    color: #333333;
                    margin-bottom: 2px;

                    span {
                        font-size: 13px;
                        font-family: Bahnschrift-SemiLight, Bahnschrift;
                        font-weight: normal;
                        color: #333333;
                        margin-left: 4px;
                    }
                }

                p:nth-child(3) {
                    font-size: 16px;
                    font-family: Bahnschrift-SemiLight, Bahnschrift;
                    font-weight: normal;

                    img {
                        width: 15px;
                        height: 15px;
                        margin-left: 4px;
                    }
                }

                &>div {
                    width: 37px;
                    height: 37px;
                    border-radius: 11px;
                    position: absolute;
                    right: 8px;
                    bottom: 8px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #E9E5FF;
                    box-shadow: 0px 9px 29px 0px rgba(204, 106, 251, 0.38);

                    img {
                        width: 24px;
                        height: 24px;
                    }

                }

                .hw-bg {
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    z-index: -1;
                    left: 0;
                    top: 0;
                }

                &:nth-child(2)>div {
                    background: #D3F1FF;
                    box-shadow: 0px 5px 16px 0px rgba(128, 205, 232, 0.4);
                }
            }
        }

        .item4 {
            gap: 11px;
        }

        .item1,
        .item2,
        .item3 {
            position: relative;

            &>img {
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                z-index: -1;
            }
        }

        .item1 {

            .item-c1 {
                border-right: 1px solid #B4E3FD;

                .item-title {
                    background: #B4E3FD;
                }
            }

            .item-c2::after {
                background: #B4E3FD;
            }
        }

        .item2 {
            .item-c1 {
                border-right: 1px solid #FDE1AF;

                .item-title {
                    background: #FDE1AF;
                }
            }

            .item-c2::after {
                background: #FDE1AF;
            }
        }

        .item3 {
            .item-c1 {
                border-right: 1px solid #B7F1E6;

                .item-title {
                    background: #B7F1E6;
                }
            }

            .item-c2::after {
                background: #B7F1E6;
            }
        }
    }

    .moudule2-box,
    .moudule3-box,
    .moudule4-box {
        border-radius: 8px;
        overflow: hidden;
        background: #FFFFFF;
        padding: 12px 0px;
        margin-bottom: 16px;

        h3 {
            padding: 0 12px;
        }

        .checkDetails {
            height: 48px;
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #8C8C8C;

            i {
                margin-left: 8px;
            }
        }
    }



    .moudule3-box {
        .moudele3-content {
            padding: 12px;
            padding-top: 0;
        }
        h3 img{
            margin-top: 2px;
        }
        .mediaType {
            display: flex;
            height: 40px;
            background: #F8F8F8;
            border-radius: 4px;
            padding: 2px;

            li {
                flex: 1;
                font-size: 14px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #545454;
                line-height: 38px;
                border: 1px solid transparent;
            }

            .active-li {
                border: 1px solid #3DA2FF;
                color: #0E8BFF;
                background: #FFFFFF;
            }

            li:nth-child(1).active-li {
                border-radius: 4px 0px 0px 4px;
            }

            li:nth-child(3).active-li {
                border-radius: 0 4px 4px 0;
            }
        }

        .moudele3-echarts {

            .channelClueEcharts {
                width: 100%;
                height: 200px;
            }

            .echarts-lend {
                padding: 0 12px;
                margin-top: 10px;

                li {
                    display: flex;
                    background: rgba(245, 245, 245, 0.4);
                    border-radius: 4px;
                    margin-bottom: 8px;
                    padding-left: 26px;
                    position: relative;

                    p {
                        &:nth-child(1) {
                            flex: 1.5
                        }

                        flex: 1;
                        text-align: left;
                        color: #333333;
                        font-size: 18px;
                        font-family: Bahnschrift-SemiLight,
                        Bahnschrift;
                        margin: 0;
                        line-height: 42px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        -o-text-overflow: ellipsis;

                        &:nth-child(1) {
                            font-size: 14px;
                            font-family: PingFang SC-Regular, PingFang SC;
                            font-weight: 400;
                        }

                        &:nth-child(2) {
                            text-align: right;

                            span {
                                font-size: 12px;
                                font-weight: 300;
                            }
                        }

                        &:nth-child(3) {
                            text-align: right;
                            padding-right: 10px;
                        }

                        .lendcolor {
                            position: absolute;
                            width: 8px;
                            height: 8px;
                            border-radius: 2px;
                            left: 14px;
                            top: calc(50% - 5px);
                        }
                    }
                }
            }
        }
    }

    .moudule4-box {
        .moudule4-content {
            border-top: 1px solid #F5F5F5;

            .clueTrendEcharts {
                width: 100%;
                height: 300px;
            }
        }
    }
}

.table-empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;

    img {
        width: 160px;
        margin-bottom: 10px
    }

    span {
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #5E5E5E;
    }
}

::v-deep {
    .table-head-class th {
        background: #F5F5F5;
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #1D2129;
    }

    .el-table__body-wrapper {
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #1D2129;

        tr {
            .el-table__cell {

                &:nth-child(4),
                &:nth-child(5),
                &:nth-child(6),
                &:nth-child(7) {
                    font-size: 16px;
                    font-family: Bahnschrift-Light, Bahnschrift;
                    font-weight: 300;
                    color: #1D2129;
                }

                .cell-img-box {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                img {
                    width: 20px;
                }
            }

        }
    }

    .pie-tooltip {
        display: flex;
        color: red;

        p {
            flex: 1;
            padding: 0 11px;
            margin: 0;
            font-size: 14px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 44px;

            &:nth-child(2) {
                font-size: 16px;
                justify-content: flex-end;
            }
        }
    }

    .myecharts-toop-box {
        padding: 0 8px;

        .myecharts-toop {
            line-height: 40px;
            font-size: 17px;
            font-family: Bahnschrift-SemiLight, Bahnschrift;
            font-weight: normal;
            color: #FFFFFF;
            // align-items: center;
            padding: 0 12px;
            position: relative;

            &:first-child::after {
                content: '';
                position: absolute;
                height: 1px;
                width: 100%;
                bottom: 0;
                left: 0;
                background: #2B88F9;
            }

            span {
                display: inline-block;
                width: 50%;
            }


        }
    }

    .m-span {
        width: 50%;
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        text-align: left;

        span {
            border: 1px solid #FFFFFF;
        }

        &:last-child {
            text-align: right;
            padding-right: 10px;
        }
    }

    .el-table__empty-block {
        width: 100% !important;

        .el-table__empty-text {
            width: 100%;
        }
    }
}</style>