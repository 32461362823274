<template>
    <div class="amendRecord-style">
        <headerNav title="整改记录" leftNav="返回" @leftClick="back"></headerNav>
        <div class="content-style">
            <pullRefresh ref="pullRefresh" @refresh="getAmendRecordList">
                <div class="content-box-style">
                    <div class="content-title">
                        {{info.contentName}}
                    </div>
                    <div v-for="(item,index) in list"
                        :class="index>0?'item-box-style item-box-style-topborder':'item-box-style '">
                        <p class="p-style">第{{item.seqNo}}次整改</p>
                        <div class="item-style">
                            <div class="item-title">整改人:</div>
                            <div class="item-text">{{item.createId}}</div>
                        </div>
                        <div class="item-style">
                            <div class="item-title">整改时间:</div>
                            <div class="item-text">{{item.reformTime}}</div>
                        </div>
                        <div class="item-style">
                            <div class="item-title">备注信息:</div>
                            <div class="item-text item-remark">{{item.remark}}</div>
                        </div>
                        <div class="item-style">
                            <div class="item-title">审核人:</div>
                            <div class="item-text">{{item.checkId}}</div>
                        </div>
                        <div class="item-style">
                            <div class="item-title">审核时间:</div>
                            <div class="item-text">{{item.reformTime}}</div>
                        </div>
                        <div class="item-style">
                            <div class="item-title">审核结果:</div>
                            <div class="item-text" v-if="item.resolveFlag=='1'">
                                <span v-if="item.standardFlag=='1'" style="color: #40D673 ;">通过</span>
                                <span v-else style="color: #FC426B ;">拒绝</span>
                            </div>
                        </div>
                        <div class="item-style" v-if="item.resolveFlag=='1' && item.standardFlag!='1'">
                            <div class="item-title">拒绝理由:</div>
                            <div class="item-text item-remark">{{item.resolveRemark}}</div>
                        </div>
                        <div class="item-style">
                            <div class="item-title">整改照片:</div>
                            <div class="item-text item-img">
                                <img v-for="(itemImg,imgIndex) in item.imgDTOS"
                                    @click="photoPreview(item.imgDTOS,imgIndex)" :src="itemImg.webUrl" alt=""
                                    class="shty-img">
                            </div>
                        </div>
                    </div>
                </div>
            </pullRefresh>
        </div>
    </div>
</template>

<script>
	import api from '@/api/api.js'
	import {
		ImagePreview
	} from 'vant';
	export default {
		data() {
			return {
				info: {},
				checkItemId: '',
				list: [],
				isLoading: false,
				id:''
			}
		},
		methods: {
			back() {
				if (this.$route.query.fromRouterName) {
					//如果是解决隐患进入 返回解决隐患
					this.$router.push({
						name: this.$route.query.fromRouterName,
					})
				} else {
					//反之返回现存隐患
					this.$router.push({
						name: 'engineeringExtantDangerName',
						params: {
							activeTabs: this.$route.query.activeTabs
						}
					})
				}

			},
			//整改记录
			async getAmendRecordList() {
				let res = await api.getAmendRecordList(this.id)
				if (res.code == 200) {
					this.list = res.data
					if(this.$refs.pullRefresh){
						this.$refs.pullRefresh.refreshSuccess()
					}
				}
			},
			photoPreview(list, index) {
				let imgList = list.map(e=>e.webUrl)
				let o = {
                    images: imgList,
					startPosition: index
				}
				ImagePreview(o);
			},
		},
		mounted() {
			this.info = JSON.parse(sessionStorage.getItem('abarbeitungInfo'))
			this.id = this.$route.query.id
			this.getAmendRecordList()
		},
	}
</script>

<style scoped lang="scss">
	.amendRecord-style {
		text-align: left;

		.content-style {
			height: calc(100vh - 50px);
			overflow: auto;

			.content-box-style {
				margin-top: 10px;
				background: #FFFFFF;

				.content-title {
					height: 45px;
					line-height: 45px;
					font-size: 16px;
					// font-family: Source Han Sans SC;
					font-weight: 500;
					color: #333333;
					padding-left: 18px;
					border-bottom: 1px solid #E6E6E6;
				}

				.item-box-style {
					padding-left: 18px;
					padding-bottom: 15px;

					.p-style {
						margin: 15px 0 10px;
						font-size: 15px;
						font-weight: 400;
						color: #333333;
					}

					.item-style {
						display: flex;
						font-size: 13px;
						font-weight: 400;
						color: #777777;
						margin-bottom: 4px;

						.item-title {
							width: 60px;
							text-align: right;
							margin-right: 10px;
						}

						.item-remark {
							width: calc(100% - 100px);
						}
					}

					.item-img {
						display: flex;
						flex-wrap: wrap;
						width: calc(100% - 70px);

						.shty-img {
							width: 89px;
							height: 89px;
							margin-right: 4px;
							margin-top: 4px;
						}
					}

				}

				.item-box-style-topborder {
					border-top: 1px solid #E6E6E6;
				}

			}

		}
	}
</style>
