// php
import Vue from "vue";
import * as API from "../index.js";
import { buildParam } from "@/utils/index";
const BaseApi = process.env.VUE_APP_BASE_API;
const SysApi = process.env.VUE_APP_SYS_API;

// 任务筛选
const getTasksByRole = "/task/taskMain/getTasksByRole";
// 修改任务
const update = "/task/taskMain/update";
// 任务设置
const setTasks = "/task/taskMain/setTasks";
// 查看部门任务（本月/计划/历史）
const getTasksByDepart = "/task/taskMain/getTasksByDepart";
// 校验任务设置数据
const validTask = "/task/taskMain/validTask";
// 角色列表查询
const getRole = "/task/taskMain/role";
// 部门任务当月数据
const getThisMonthTasksByDepart = "/task/taskMain/getThisMonthTasksByDepart";
// 根据角色查询相关term（点击承接并设置 ）
const getTitleByRole = "/task/taskMain/getTitleByRole";
// 根据角色查询相关term（筛选条件 ）
const getTermConditionByRole = "/task/taskMain/getTermConditionByRole";
// 转单/补齐查看详情
const getTransferDetail = "/task/taskMain/getTransferDetail";
// 部门分配
const distributeDepart = "/task/taskMain/distributeDepart";
// 监听用户变更部门
const monitorUserDept = "/task/taskMain/monitorUserDept";
// 统计任务数据数量
const statCount = '/task/taskMain/statCount';
// 任务设置-总裁
const setTaskPresident  = "/task/taskMain/save";
// 详情人员
const taskDetail = "/task/taskBasicsTarget/select/taskDetail"
// 字典
const dictItem='/backend/dictItem/r02';
// 人员详情 修改
const setUserTasks = "/task/taskMain/setUserTasks";
// 查询子级任务列表
const getTaskMainById = "/task/taskMain/getTaskMainById"

// 3.0.3
// 前台总监查询转单部门
const getTransferDepartmentDataList = "/task/taskMain/getTransferDepartmentDataList"
// 审批列表
const getCheckList = "/task/approveAccount/getCheckList"
// 审批列表的任务详情
const getCheckListDetail = "/task/approveAccount/getDetail"
// 审批 审批流数据
const getApprovalProcess = "/task/approveAccount/getFlowById"
// 审批详情 审批(通过、拒绝)
const getApprovalCheckResult = "/task/approveAccount/getCheckResult"
// 审批详情 审批(撤回)
const getApprovalRevoke = "/task/taskMain/cancel"
// 审批数量
const getApprovalNumber = "/task/approveAccount/getMessageNumber"
// 查看详情已读接口
const getAlterationMessage = "/task/approveAccount/getAlterationMessage"
// 调整记录
const getAdjustRecordList = "/task/approveAccount/adjustmentRecordList"

export default {
  getTasksByRole: (params) => {
    buildParam(params);
    return API.POST(`${BaseApi}${getTasksByRole}`, params);
  },
  update: (params) => {
    buildParam(params);
    return API.POST(`${BaseApi}${update}`, params);
  },
  setTasks: (params) => {
    buildParam(params);
    return API.POST(`${BaseApi}${setTasks}`, params);
  },
  getTasksByDepart: (params) => {
    // buildParam(params);
    return API.POST(`${BaseApi}${getTasksByDepart}`, params);
  },
  validTask: (params) => {
    buildParam(params);
    return API.POST(`${BaseApi}${validTask}`, params);
  },
  getRole: (params) => {
    return API.GET(`${BaseApi}${getRole}`, params);
  },
  getThisMonthTasksByDepart: (params) => {
    buildParam(params);
    return API.POST(`${BaseApi}${getThisMonthTasksByDepart}`, params);
  },
  getTitleByRole: (params) => {
    buildParam(params);
    return API.POST(`${BaseApi}${getTitleByRole}`, params);
  },
  getTermConditionByRole: (params) => {
    buildParam(params);
    return API.GET(`${BaseApi}${getTermConditionByRole}`, params);
  },
  getTransferDetail: (params) => {
    buildParam(params);
    return API.POST(`${BaseApi}${getTransferDetail}`, params);
  },
  distributeDepart: (params) => {
    buildParam(params);
    return API.POST(`${BaseApi}${distributeDepart}`, params);
  },
  monitorUserDept: (params) => {
    buildParam(params);
    return API.POST(`${BaseApi}${monitorUserDept}`, params);
  },
  statCount: (params) => {
    buildParam(params);
    return API.GET(`${BaseApi}${statCount}`, params);
  },
  setTaskPresident:(params) => {
    buildParam(params);
    return API.POST(`${BaseApi}${setTaskPresident}`, params);
  },
  taskDetail:(params) => {
    buildParam(params);
    return API.POST(`${BaseApi}${taskDetail}`, params);
  },
  // 字典查询
  dictItem:(params) => {
    buildParam(params)
    return API.POST(`${SysApi}${dictItem}`, params)
  },
  setUserTasks:(params) => {
    buildParam(params);
    return API.POST(`${BaseApi}${setUserTasks}`, params);
  },
  getTaskMainById:(params) => {
    buildParam(params);
    return API.GET(`${BaseApi}${getTaskMainById}`, params);
  },
  getTransferDepartmentDataList:(params) => {
    buildParam(params);
    return API.GET(`${BaseApi}${getTransferDepartmentDataList}`, params);
  },
  validUserUpdateStatus:(params) => {
    buildParam(params);
    return API.GET(`${BaseApi}${validUserUpdateStatus}`, params);
  },
  getCheckList:(params) => {
    buildParam(params);
    return API.POST(`${BaseApi}${getCheckList}`, params);
  },
  getCheckListDetail:(params) => {
    buildParam(params);
    return API.POST(`${BaseApi}${getCheckListDetail}`, params);
  },
  getApprovalProcess:(params) => {
    buildParam(params);
    return API.GET(`${BaseApi}${getApprovalProcess}`, params);
  },
  getApprovalCheckResult:(params) => {
    buildParam(params);
    return API.POST(`${BaseApi}${getApprovalCheckResult}`, params);
  },
  getApprovalRevoke:(params) => {
    buildParam(params);
    return API.POST(`${BaseApi}${getApprovalRevoke}`, params);
  },
  getApprovalNumber:(params) => {
    buildParam(params);
    return API.GET(`${BaseApi}${getApprovalNumber}`, params);
  },
  getAlterationMessage:(params) => {
    buildParam(params);
    return API.POST(`${BaseApi}${getAlterationMessage}`, params);
  },
  getAdjustRecordList:(params) => {
    buildParam(params);
    return API.POST(`${BaseApi}${getAdjustRecordList}`, params);
  },
};
