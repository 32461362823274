import { render, staticRenderFns } from "./sub-task-details.vue?vue&type=template&id=4039b296&scoped=true&"
import script from "./sub-task-details.vue?vue&type=script&lang=js&"
export * from "./sub-task-details.vue?vue&type=script&lang=js&"
import style0 from "./sub-task-details.vue?vue&type=style&index=0&id=4039b296&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4039b296",
  null
  
)

export default component.exports