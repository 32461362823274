<template>
    <div class="main_box">
        <headerNav title="消息详情" @leftClick="back"></headerNav>
        <div class="height_style height_style_content">
            <div class="info_main">
                <div class="info_nav">
                    <div class="info_left">{{ customerDetails.consumerName && customerDetails.consumerName.length > 2 ?
                        customerDetails.consumerName.substring(0, 2) : customerDetails.consumerName }}
                    </div>
                    <div class="info_right">
                        <div class="right_flex">
                            <div class="right_a">{{ customerDetails.consumerName }}</div>
                        </div>
                        <div class="right_bottom">
                            <span class="span_back">合同号</span>
                            <span class="span_code">{{ customerDetails.maintainContract }}</span>
                        </div>
                    </div>
                </div>
                <div class="info_content">
                    <div class="cont_main" v-if="isSub">
                        <div class="cont_flex">
                            <div class="flex_a">品牌:</div>
                            <div class="flex_b">{{ customerDetails.brandName }}</div>
                        </div>
                        <div class="cont_flex">
                            <div class="flex_a">供应商:</div>
                            <div class="flex_b">{{ customerDetails.supplierName }}</div>
                        </div>
                        <div class="cont_flex">
                            <div class="flex_a">批次号:</div>
                            <div class="flex_b">{{ customerDetails.batchNo }}</div>
                        </div>
                        <div class="cont_flex">
                            <div class="flex_a">单据类型:</div>
                            <div class="flex_b">{{ customerDetails.typeCode }}</div>
                        </div>
                        <div class="cont_flex">
                            <div class="flex_a">项目经理:</div>
                            <div class="flex_b">{{ customerDetails.projectName }}</div>
                        </div>
                        <div class="cont_flex">
                            <div class="flex_a">深化:</div>
                            <div class="flex_b">{{ customerDetails.deepenName }}</div>
                        </div>
                        <div class="cont_flex">
                            <div class="flex_a">装修地址:</div>
                            <div class="flex_b">{{ customerDetails.detailAddress }}</div>
                        </div>
                        <div class="cont_flex" v-if="customerDetails.orderStatus != 'ITEM446651'">
                            <div class="flex_a">单据备注:</div>
                            <div class="flex_b">{{ customerDetails.remark }}</div>
                        </div>
                        <div class="cont_flex cont_flex_img" v-if="customerDetails.orderStatus != 'ITEM446651'">
                            <div class="flex_a flex_text">附件信息:</div>
                            <div class="flex_b flex_img">
                                <div v-for="item in customerDetails.itemf">
                                    <van-image class="img" @click="onPreview(item.fileAdd)" :src="item.fileAdd" :width="72"
                                        :height="72" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="content_bottom" @click="handleChangeSub">
                        <div class="flex">
                            {{ isSub ? '收起更多' : '查看更多' }}
                            <img :class="isSub ? '' : 'jiantou'" :src="require('@/assets/sqgdpng.png')" alt=""
                                style="width:12px;height:12px;margin-left:4px">
                        </div>
                    </div>
                </div>
            </div>
            <div class="center_count center_count1">
                <div class="left">
                    <div class="left_a">合计</div>
                    <div class="left_b">单价/总金额</div>
                </div>
                <div class="right">
                    <div class="right_a">{{ quantity }}</div>
                    <div class="right_b">{{ '￥' + total }}</div>
                </div>
            </div>
            <div class="bottom_table bottom_tabled">
                <div class="table_mian">
                    <table>
                        <thead>
                            <tr class="tr_th">
                                <th class="fixed_column" style="width:150px;text-align:left;padding-left:15px">商品名称</th>
                                <th style="width:145px;text-align:left;padding-left:15px">材料编号</th>
                                <th style="width:145px;text-align:left;padding-left:15px">品牌</th>
                                <th style="width:60px;text-align:left;padding-left:15px">样式图</th>
                                <th style="width:60px;text-align:left;padding-left:15px">规格</th>
                                <th style="width:60px;text-align:left;padding-left:15px">尺寸</th>
                                <th style="width:60px;text-align:left;padding-left:15px">数量</th>
                                <th style="width:60px;text-align:left;padding-left:15px">单位</th>
                                <th style="width:100px;text-align:left;padding-left:15px">{{Univalent}}</th>
                                <th style="width:70px;text-align:left;padding-left:15px">小计</th>
                                <th style="width:145px;text-align:left;padding-left:15px">
                                    <img :src="require('@/assets/editremark.png')" width="14px" height="14px" alt=""  v-if="customerDetails.orderStatus == 'ITEM446651'"> 备注
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(items, index) in customerDetails.item" class="main_tr" :key="'rol1-' + index">
                                <!-- 商品名称 -->
                                <td class="fixed_column_td" style="text-align:left;padding-left:15px">{{ items.itemName }}
                                </td>
                                <!-- 材料编号 -->
                                <td style="text-align:left;padding-left:15px">{{ items.itemNo }}</td>
                                <!-- 品牌 -->
                                <td style="text-align:left;padding-left:15px">{{ items.brandName }}</td>
                                <!-- 样式图 -->
                                <td style="text-align:left;padding-left:15px">
                                    <div @click="onPreview(items.imgUrl)">
                                        <img :src="items.imgUrl" :width="32" :height="32" alt="">
                                    </div>
                                </td>
                                <!-- 规格 -->
                                <td style="text-align:left;padding-left:15px">{{ items.modelSpec }}</td>
                                <!-- 尺寸 -->
                                <td style="text-align:left;padding-left:15px">{{ items.itemSize }}</td>
                                <!-- 数量 -->
                                <td style="text-align:left;padding-left:15px">{{ items.productQty }}</td>
                                <!-- 单位u -->
                                <td style="text-align:left;padding-left:15px">{{ items.unitName }}</td>
                                <!-- 成本价 -->
                                <td style="text-align:left;padding-left:15px">{{ items.priceStyle == '0'?items.primeCost:items.sellingPrice }}</td>
                                <!-- 小计 -->
                                <td style="text-align:left;padding-left:15px">{{ items.priceStyle == '0'?(items.primeCost *
                                    items.productQty).toFixed(2):items.sellingSubtotal.toFixed(2) }}</td>
                                <!-- 备注 -->
                                <td style="text-align:left;padding-left:15px">
                                    <div @click="handleEditReark(items)" v-if="customerDetails.orderStatus == 'ITEM446651'">
                                        <img :src="require('@/assets/editremark1.png')" width="14px" height="14px" alt="">
                                        {{ items.remark }}
                                    </div>
                                    <div v-else>{{ items.remark }}</div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="uploadimg uploadimg1" v-if="customerDetails.orderStatus == 'ITEM446651'">
                <div class="up-style">
                    <van-cell-group>
                        <van-field v-model="documentsValue" label="单据备注" placeholder="请输入" label-class="label_class" />
                        <van-field label="附件上传" label-class="label_class">
                            <template #input>
                                <div>
                                    <van-uploader :after-read="afterRead" multiple :max-count="30"
                                        :max-size="10 * 1024 * 1024" v-model="fileList">
                                        <div class="up-style-img">
                                            <img :src="require('@/assets/camera1.png')" alt="" width="20px" />
                                            <span>上传图片</span>
                                        </div>
                                    </van-uploader>
                                </div>
                            </template>
                        </van-field>
                    </van-cell-group>
                </div>
            </div>
        </div>
        <div class="bottom">
            <div>
                <p>确认后将提交给财务审核</p>
            </div>
            <van-button type="info" class="bottom_btn" @click="handleBeput" :disabled="customerDetails.orderStatus != 'ITEM446651'" :loading="bePutLoading">入库</van-button>
        </div>
        <!-- 修改备注信息 -->
        <van-dialog v-model="showEdit" title="备注" show-cancel-button confirmButtonColor="#4871C0" @close="handleClose"
            @confirm="handleConfirm">
            <van-field class="remark_style" v-model="editValue" rows="2" autosize type="textarea" maxlength="150"
                placeholder="请输入" show-word-limit />
        </van-dialog>
    </div>
</template>

<script>
import { Dialog, ImagePreview, Toast } from 'vant'
import api from '@/api/api.js'
export default {
    components: {
        Dialog
    },
    data () {
        return {
            id: '',
            logId: '',
            messageId: '',
            isSub: false,
            dataTable: [],
            // 详情传值
            customerDetails: {},
            // 显示提交或者下单分包
            isShowPlace: '',
            // 销售单id
            customerId: '',
            // 合计
            total: 0,
            // 增减量
            quantity: 0,
            // 附件上传
            fileList: [],
            // 单据备注
            documentsValue: '',
            // 入库按钮loading
            bePutLoading: false,
            // 备注信息弹窗
            showEdit: false,
            // 备注信息value
            editValue: '',
            // 当前行数据
            rowsData: {},
            // 单价显示名称
            Univalent:''
        }
    },
    props: {

    },
    methods: {
        back () {
            this.$router.go(-1)
        },

        // 切换收起展开
        handleChangeSub () {
            this.isSub = !this.isSub
        },
        // 查询详情
        async handleDetails () {
            const {data} = await api.orderDetailList1(this.id)
            this.customerDetails = data.ret
            let total = 0, quantity = 0;
            data.ret.item.forEach(items => {
                items.remark1=items.remark
                if (data.ret.priceStyle == 0) { items.sellingPrice = items.primeCost }
                total += Math.round(items.productQty * items.sellingPrice * 100 || 0) / 100
                quantity += items.productQty
            })
            this.total = Math.round(total * 100) / 100
            this.quantity = Math.round(quantity * 1000) / 1000
            console.log(data.ret,)
            if (data.ret.priceStyle == 0) {
                this.Univalent = '成本单价（元）'
            } else if (data.ret.priceStyle == 1) {
                this.Univalent = '客户单价（元）'
            } else {
                this.Univalent = '单价（元）'
            }
            await api.messageRead({ logId: this.logId, messageId: this.messageId, sendChannel: '0' })
        },
        async afterRead (file) {
            const fileList = Array.isArray(file) ? file : [file]
            fileList.forEach(async (e, index) => {
                // 此时可以自行将文件上传至服务器
                const formData = new FormData();
                formData.append('files', e.file);
                formData.append('dir', 'oss');
                const res = await api.upFile(formData)
                if (res.code == 200) {
                    const indexR = this.fileList.length - fileList.length + index
                    this.fileList[indexR].url = res.url
                }
            })
        },
        // 备注信息
        handleEditReark (value) {
            this.showEdit = true
            this.editValue = value.remark
            this.rowsData = value
        },
        // 确认修改
        handleConfirm () {
            this.rowsData.remark = this.editValue
            this.showEdit = false
        },
        // 弹窗关闭
        handleClose () {
            this.showEdit = false
        },
        // 入库
        handleBeput () {
            Dialog.confirm({
                title: '确认入库吗',
                confirmButtonColor: '#4871C0'
            })
                .then(async () => {
                    try {
                        this.bePutLoading = true
                        const itemf = this.fileList.length ? this.fileList.map(item => ({ fileAdd: item, remark: this.documentsValue })) : [{ remark: this.documentsValue }]
                        this.customerDetails.item.map((items,index) =>{
                            items.seqNo = index+1
                        })
                        await api.AddWarehousing2({
                            orderName: '入库单',
                            typeCode: 'ITEM907907',
                            orderId: this.customerDetails.orderId,
                            orderTotal: this.total,
                            orderStatus: "ITEM393489",
                            remark: this.documentsValue,
                            item: this.customerDetails.item,
                            itemf: itemf,
                            brandId: this.customerDetails.brandId,
                            supplierId: this.customerDetails.supplierId,
                            classId: this.customerDetails.classId,
                            batchNo: this.customerDetails.batchNo,
                        })
                        Toast.success('已提交审核')
                        this.handleDetails()
                    } finally {
                        this.bePutLoading = false
                    }
                })
                .catch(() => {
                    // on cancel
                });
        },
        onPreview (url) {
            ImagePreview({
                images: [url],
                closeable: true,
                showIndex: false
            });
        },

    },
    mounted () {

    },
    created () {
        console.log(this.$route.params, "l;iuyop")
        this.id = this.$route.params.id
        this.logId = this.$route.params.logId
        this.messageId = this.$route.params.messageId
        this.handleDetails()
    }
}
</script>


<style lang="scss" src="./Component/component.scss"></style>
<style lang="scss" scoped>
.bottom_tabled {
    .fixed_column {
        background: #EEF4FF;
    }

    th {
        background: #F4F8FF;
    }
}

.height_style_content {
    background: #fff;
}

.uploadimg1 {
    margin-top: 15px;

    .up-style {
        // padding-left: 28px;

        .up-style-img {
            border: 1px dashed #eee;
            background-color: #F8FAFF;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            margin: 0 5px 5px 0;
            width: 64px;
            height: 64px;

            span {
                margin-top: 10px;
            }
        }

        ::v-deep .van-uploader__preview-image {
            border-radius: 5px;
            overflow: hidden;
            width: 64px;
            height: 64px;
        }

        ::v-deep .van-uploader__preview-delete {
            width: 20px;
            height: 20px;
            top: -5px;
            right: -5px;
            border-radius: 100%;

            .van-uploader__preview-delete-icon,
            .van-icon {
                font-size: 24px;
            }
        }
    }
}

.center_count1 {
    margin-top: 0;
}

::v-deep .label_class {
    span {
        color: #333333 !important;
        font-size: 16px !important;
        font-family: PingFangSC-Medium, PingFang SC !important;
        font-weight: 500 !important
    }
}

::v-deep .van-cell__value--alone {
    background: #F9F9F9;
    padding: 8px;
}

.remark_style {}
</style>