<template>
  <div class="main">
    <van-sticky class="topMenu">
      <headerNav leftNav=" " :title="'子任务'" @leftClick="back"></headerNav>
    </van-sticky>
    <main>
      <div class="content" :class="upActiveTab != '3'?'':'content-height'" v-if="list.length > 0">
        <!-- 每一项任务 -->
        <div class="content-element" v-for="(item, index) in list" :key="index"
          @click="onClickTaskDetails(item,index)">
          <div class="content-element-title">
            <div>{{ item.userName }}</div>
            <img v-if="item.isException == '1'" src="../../../../assets/task-management/sub-task-isException.png" alt="">
          </div>
          <div class="content-element-data">
            <div>
              <span class="content-element-data-count">{{ (item.finishRate).toFixed(2) }}%</span>
              <span class="content-element-data-text">{{ item.finishRateName}}</span>
            </div>
            <div>
              <span class="content-element-data-count">{{ item.standardRate }}</span>
              <span class="content-element-data-text">{{ item.standardRateName}}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 暂无任务 -->
      <div class="no-data" v-if="isData && list.length <= 0">
        <img src="@/assets/task-management/task-setup-nodata.png" alt="">
        <div>暂无任务</div>
      </div>
    </main>

    <footer v-if="upActiveTab != '3'">
      <div @click="createTask">添加</div>
    </footer>
  </div>
</template>

<script>
import api from "@/api/task-management/human-resources.js";
import { Toast,ImagePreview } from "vant";
import moment from "moment";

export default {
  data () {
    return {
      upActiveTab:'',
      list:[],
      isData:false,
    }
  },
  mounted() {
    this.upActiveTab = this.$route.query.upActiveTab
    this.getData()
  },
  methods: {
    back () {
      this.$router.go(-1)
    },
    // 查询表格数据
    async getData () {
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      let params = {
        "taskId": this.$route.query.taskId,
      }
      api.getSubTaskList(params).then((res)=>{
        if (res.code == 200) {
          Toast.clear()
          this.list = res.data
        }else{
          Toast.fail(res.msg)
        }
      }).finally(()=>{
        this.isData = this.list.length > 0?false:true
      })
    },
    // 点击详情
    onClickTaskDetails (item,index) {
      this.$router.push({ 
        name: 'TaskSetupProductSubTaskDetails', 
        query: {
          upActiveTab:this.upActiveTab,
          item:JSON.stringify(item),
        }
      })
    },
    // 创建子任务
    createTask(){
      this.$router.push({ 
        name: 'TaskSetupProductCreateSubTask', 
        query:{
          ...this.$route.query
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.main::v-deep {
  height: 100vh;
  overflow: hidden;

  .van-tab {
    font-size: 17px;
    flex: none;
    width: 25%;
  }

  .van-tab--active {
    font-size: 18px;
  }

  .no-data {
    height: calc(100vh - 136px);
    padding-top: 150px;
    box-sizing: border-box;


    img {
      width: 210px;
      height: 137px;
    }

    div {
      font-size: 16px;
      font-family: Bahnschrift, Bahnschrift;
      font-weight: 400;
      color: rgba(51, 51, 51, 0.5);
    }
  }

  .content {
    height: calc(100vh - 136px);
    background: #F5F5F5;
    overflow: auto;

    .content-element {
      background: #ffffff;
      padding: 12px 18px 16px;
      margin-top: 16px;
      width: 100%;
      border-radius: 8px 8px;
      box-sizing: border-box;
      position: relative;

      .content-element-title{
        display: flex;
        align-items: center;
        font-size: 18px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #1D2129;
        position: relative;
        img{
          width: 35px;
          height: 18px;
          margin-left: 8px;
        }
        span{
          background: #F9F9F9;
          border-radius: 3px 3px 3px 3px;
          color: #8C8C8C;
          display: inline-block;
          padding: 0 4px;
        }
        ::after{
          content: "";
          width: 16px;
          height: 16px;
          background:url("../../../../assets/task-management/task-setup-finance-arrows.png") no-repeat center;
          background-size: 100% 100%;
          position: absolute;
          right: 0;
          top: 50%;
          margin-top: -8px;
        }
      }
    }
    .content-element-data{
      height: 88px;
      background: #F9F9F9;
      box-shadow: 0px 5px 7px 0px rgba(254,242,222,0.2);
      border-radius: 8px 8px 8px 8px;
      margin-top: 10px;
      display: flex;
      position: relative;
      text-align: left;
      .content-element-data-count{
        font-size: 22px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #1D2129;
        width: 100%;
        box-sizing: border-box;
      }
      .content-element-data-text{
        font-size: 14px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #545454;
        padding-left: 12px;
        box-sizing: border-box;
      }

      div:nth-child(1){
        width: 35%;
        border-right: 1px dashed #E2E2E2;
        box-sizing: border-box;
        span{
          display: block;
          width: 100%;
        }
        .content-element-data-count{
          font-family: Bahnschrift, Bahnschrift;
          font-weight: normal;
          font-size: 27px;
          color: #1281E7;
          padding: 16px 0 4px 12px;
        }
      }
      
      div:nth-child(2){
        width: 60%;
        box-sizing: border-box;
        span{
          display: block;
          width: 100%;
        }
        .content-element-data-count{
          padding: 18px 0 6px 12px;
        }
      }

      &::after{
        content: "";
        width: 15px;
        height: 15px;
        background: #FFFFFF;
        border-radius: 50%;
        position: absolute;
        top: -10%;
        left: 32.5%;
      }
      &::before{
        content: "";
        width: 15px;
        height: 15px;
        background: #FFFFFF;
        border-radius: 50%;
        position: absolute;
        left: 32.5%;
        bottom: -10%;
      }
    }
  }
  
  .content-height{
    height: calc(100vh - 50px);
  }

  footer{
    div{
      width: 95%;
      height: 44px;
      background: #3975C6;
      border-radius: 4px 4px 4px 4px;
      margin: 12px auto 30px;
      font-size: 18px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 44px;
      
    }
  }

}
</style>