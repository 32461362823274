<template>
    <div class="messageInfo-box">
        <headerNav title="消息详情" />
        <div class="empty-box" v-if="msgInfo.isDel == 1">
            <van-image class="empty" :src="require('@/assets/msgEmpty.png')" />
            <p>该消息已被发布者删除</p>
        </div>
        <div v-else>
            <div class="top">
                <div class="title">
                    {{ msgInfo.messageTitle }}
                </div>
                <div class="annotation">
                    <div>
                        <span class="text">发送人：{{ msgInfo.releaseName }}</span>
                    </div>
                    <span class="time">发送时间：{{ msgInfo.sendTime }}</span>
                    <div class="content">发送内容：{{ msgInfo.messageSuggest }}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import api from '@/api/dataBoard.js'
import {
    ImagePreview
} from 'vant';
export default {
    data () {
        return {
            logId:'',
            msgInfo: {},
        }
    },
    methods: {
        //获取信息
        async messageInfo () {
            let res = await api.messageApi(this.logId)
            if (res.code == 200) {
                this.msgInfo = res.data
                console.log(this.msgInfo);
            }
        },
    },
    mounted () {

    },
    created () {
        this.logId = this.$route.query.logId
        this.messageInfo()
    }
}
</script>

<style scoped lang="scss">
.messageInfo-box {
    .top {
        text-align: left;
        padding: 16px 20px;
        border-bottom: 1px solid #EEEEEE;

        .title {
            font-size: 22px;
            font-family: PingFang SC-Bold, PingFang SC;
            font-weight: bold;
            color: #333333;
            line-height: 31px;
            margin-bottom: 8px;
        }

        .annotation {
            font-size: 14px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #999999;
            line-height: 25px;
        }

        .text {
            margin-right: 16px;
        }
    }

    .html-content {
        padding: 0 20px;
        padding-top: 16px;
        text-align: left;
        overflow: auto;
    }

    .empty-box {
        height: calc(100vh - 100px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #666666;
        line-height: 20px;
        letter-spacing: 1px;
    }

    .empty {
        width: 205px;
        height: 145px;
    }
}
</style>
<style lang="scss">
.messageInfo-box img {
    max-width: 100%;
}
.html-content {
    video {
        max-width: 100% !important;
    }

    img {
        max-width: 100% !important;
    }
    table{
        border-collapse: collapse;
        border-spacing: 0;
    }
    table td,
    table th {
        border: 1px solid var(--w-e-textarea-border-color);
        line-height: 1.5;
        min-width: 30px;
        padding: 3px 5px;
        text-align: left;
    }
}
</style>
