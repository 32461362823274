<template>
    <div class="construction-style">
        <headerNav title="材料申领列表" leftNav="返回" @leftClick="back"></headerNav>
        <div class="content-style">
             <van-pull-refresh ref="pullRefresh" v-model="isLoading" @refresh="onRefresh">
                <van-list class="vant-list-style" v-model="loading" :finished="finished"
                    :finished-text="total ? '·已加载全部数据·' : '·暂无数据·'" @load="onLoad">
                    <div class="empty" v-if="!list.length">
                        · 暂无申领记录 ·
                    </div>
                    <div class="item-style" v-for="(item, index) in list" @click="toDetails(item)">
                        <div class="item-top">
                            <!-- <van-tag round type="warning" v-if="materialsDetails.applyStatus == 0" size="medium">待审核</van-tag>
                            <van-tag round type="success" v-if="materialsDetails.budgetApplyStatus == 1" size="medium">
                                {{ materialsDetails.stockStatus == 1 ? '已备货' : '未备货' }}
                            </van-tag>
                            <van-tag round type="primary" v-if="materialsDetails.budgetApplyStatus == 2" size="medium">已领取</van-tag>
                            <van-tag round type="danger" v-if="materialsDetails.budgetApplyStatus == 3" size="medium">已拒绝</van-tag>
                            <van-tag round type="danger" color="#4A7DFF" v-if="item.budgetApplyStatus == 4" size="medium">已撤销</van-tag> -->
                            <van-tag round type="warning" v-if="item.applyStatus == 0 || item.applyStatus == 1" size="medium">待审核</van-tag>
                            <van-tag round type="success" v-if="['6','7','8','9'].includes(item.applyStatus)" size="medium">
                                {{ item.stockStatus == 1 ? '已备货' :  item.stockStatus == 2 ? '已领取' : '未备货'}}
                            </van-tag>
                            <van-tag round type="danger" v-if="item.applyStatus == 2 || item.applyStatus == 4" size="medium">已拒绝</van-tag>
                            <van-tag round type="danger" color="#4A7DFF" v-if="item.applyStatus == 10" size="medium">已撤销</van-tag>
                        </div>
                        <div class="item-content">
                            <div>单据编号:{{ item.applyCode }}</div>
                            <div>申领种类:{{ item.applyQty }}</div>
                            <div>工程阶段:{{ item.nodeName }}</div>
                            <div>申领时间:{{ item.createDate }}</div>
                        </div>
                    </div>
                </van-list>
            </van-pull-refresh> 
        </div>
        <div class="bottom-btn">
            <van-button type="info" @click="materialsApply">辅材申领</van-button>
        </div>
    </div>
</template>

<script>
import api from '@/api/api.js'
export default {
    data () {
        return {
            searchValue: '',
            list: [],
            action: {},
            actions: [],
            isShowGrade: false,
            isLoading: false,
            loading: false,
            finished: false,
            error: false, // 是否加载失败
            pageNum: 1, // 分页
            pageSize: 10, // 每页条数
            total: 0 // 数据总条数
        }
    },
    methods: {
        //返回
        back () {
            this.$router.push({ name: 'engineeringClicntDetailName' })
        },
        //获取列表
        async getList (e) {
            let projectInfo = JSON.parse(sessionStorage.getItem('clientInfo'))
            let obj = {
                Q_projectId_EQ: projectInfo.id,
                page: this.pageNum,
                limit: 10
            }
            let res = await api.getMaterialsBills(obj)
            // this.list = res.data
            if (res.code == 200) {
                this.total = res.total || res.count;
                if (res.data.length === 0) { // 判断获取数据条数若等于0
                    this.list = []; // 清空数组
                    this.finished = true; // 停止加载
                } else {
                    // 若数据条数不等于0
                    this.list.push(...res.data) // 将数据放入list中
                    this.loading = false; // 加载状态结束 
                    // 如果list长度大于等于总数据条数,数据全部加载完成
                    if (this.list.length >= this.total) {
                        this.finished = true; // 结束加载状态
                    }
                }
                this.isLoading = false
                if (this.$refs.pullRefresh) {
                    this.$refs.pullRefresh.refreshSuccess()
                }
            }
        },
        // 被 @load调用的方法
        onLoad (e) { // 若加载条到了底部
            let timer = setTimeout(() => { // 定时器仅针对本地数据渲染动画效果,项目中axios请求不需要定时器
                // this.getList(); // 调用上面方法,请求数据
                this.getList(e)
                this.pageNum++; // 分页数加一
                this.finished && clearTimeout(timer); //清除计时器
            }, 100);
        },
        // 加载失败调用方法
        onRefresh (e) {
            this.finished = false; // 清空列表数据
            this.loading = true; // 将 loading 设置为 true，表示处于加载状态
            this.pageNum = 1; // 分页数赋值为1
            this.list = []; // 清空数组
            this.onLoad(e); // 重新加载数据
        },

        //前往申领详情
        toDetails (item) {
            sessionStorage.setItem('materialsDetails', JSON.stringify(item))
            if(item.applyStatus == 10){
                this.$router.push({ name: 'engineeringMaterialsApplyEditName' })
            }else{
                this.$router.push({ name: 'engineeringMaterialsDetailsName' })
            }
        },
        //辅材申领
        materialsApply () {
            this.$router.push({ name: 'engineeringMaterialsApplyName' })
        }

    },
    created () {
        // this.getList()
    },
}
</script>

<style scoped lang="scss">
.construction-style {

    .content-style {
        padding: 10px;
        height: calc(100vh - 70px);
        overflow: auto;
        .van-pull-refresh{
            min-height: calc(100vh - 70px);
        }

        .item-style {
            padding: 10px 0;
            margin-bottom: 10px;
            background: #FFFFFF;
            border-radius: 6px;
            &:last-child{
                margin-bottom: 100px;
            }

            .item-top {
                text-align: right;
                padding-right: 5px;
            }

            .item-content {
                padding: 0 10px;
                display: flex;
                flex-flow: wrap;

                div {
                    width: 50%;
                    margin: 5px 0;
                    text-align: left;
                }
            }
        }
    }

    .bottom-btn {
        position: fixed;
        bottom: 20px;
        width: 100%;
        text-align: center;

        button {
            width: 70%;
        }
    }
}

.empty {
    padding: 30px;
    font-weight: 400;
    color: #AAAAAA;
}
</style>
