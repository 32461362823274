<template>
	<!-- //自定义组件绑定v-model -->
	<div>
		<van-action-sheet v-model:show="msg" :actions="actions" @select="onSelect" @click-overlay="close" :close-on-click-overlay="false"/>
	</div>
</template>

<script>
	import {
		Toast
	} from 'vant';
	export default {
		data() {
			return {
				show: false,
				actions: [{
					name: '选项一'
				}, {
					name: '选项二'
				}, {
					name: '选项三'
				}],
			}
		},
		props: {
			msg: {
				type: Boolean,
				default: ''
			},
		},
		model:{
			prop:'msg',
			event:'cc'
		},
		methods: {
			onSelect(item) {
				// 默认情况下点击选项时不会自动收起
				// 可以通过 close-on-click-action 属性开启自动收起
				// this.show = false;
				this.$emit('cc',false)
				Toast(item.name);
			},
			close(){
				console.log(123)
				this.$emit('cc',false)
			}
		},
		mounted() {

		},
	}
</script>

<style scoped lang="scss">

</style>
