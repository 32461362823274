let taskOptions = [
  { transferProductionValue: "转单产值(万)", taskProductionValue: '任务产值(万)', value: 'transfer_output' },
  { transferProductionValue: "补齐产值(万)", taskProductionValue: '任务产值(万)', value: 'supply_output' },
  { transferProductionValue: "转单量", taskProductionValue: '任务量', value: 'transfer_count' },
  { transferProductionValue: "补齐量", taskProductionValue: '任务量', value: 'supply_count' },
  { transferProductionValue: "签单量", taskProductionValue: '任务量', value: 'signed_count' },
  { transferProductionValue: "到店量", taskProductionValue: '任务量', value: 'shop_count' },
  { transferProductionValue: "线索量", taskProductionValue: '任务量', value: 'clue_count' },
]
// 判断表头展示内容
export default function estimateOutput (value) {
  let data = {}
  taskOptions.filter(item => {
    if (item.value == value) {
      data = item
    }
  })
  return data
}