// 财务看板
import * as API from "../index.js";
import { buildParam } from '@/utils/index'
const BaseApi = process.env.VUE_APP_BASE_API
// const BaseApi = 'http://192.168.0.219:30780/mock/42'
const SysApi = process.env.VUE_APP_SYS_API

// ===============任务看板====================
// 查询看板详情
const lastMonthDetail = '/task/taskMainBudget/lastMonthDetail'

/* ======================任务中心================== */
// 本月/历史任务查询接口
const getTaskList = '/task/taskMainBudget/getTaskList'
// 查询指标列表项
const getTermSlug = '/task/taskMainBudget/getTermSlug'
// 编辑任务
const saveIndex = '/task/pretrial/saveIndex'
// 子任务列表详情
const subtaskDetail = '/task/taskMainItem/budgetList?taskId='
// 子任务编辑
const updateSubtaskDetail = '/task/taskMainItem/updateById'
// 子任务新增
const saveSubtaskDetail = '/task/taskMainItem/saveBatch'
// 子任务删除
const deleteSubtaskDetail = '/task/taskMainItem/remove?id='
// 查询执行者
const getExecutorList = '/task/hr/getExecutorList'
// 修改任务指标
const updateTaskMainZb = '/task/taskMainBudget/updateTaskMainZb'

// 查询审批列表
const getWaitBeDone = '/task/pretrial/waitBeDone'
// 审批任务
const updatecheckTask = '/task/pretrial/updatecheckTask'
// 审批流程查询
const getApprovalList = '/task/taskMainBudget/getApprovalList'
// 审批结果
const checkFlowResult = '/task/approveAccount/checkFlowResult'
// 任务成果上传
const taskJzResultSubmit = '/task/taskMainBudget/taskJzResultSubmit'
// 获取市场列表
const getMarketList  = '/task/taskMainBudget/getMarketList'
// 校验月份选择是否重复
const checkDate = '/task/hr/ysCheckDate'
// 下发工地罚金任务
const issueSitePenalty = '/task/taskMainBudget/issueSitePenalty'
// 下载文件
const downOSSFile = '/tools/oss/ali/downOSSFile/public'
// 查询是否展示子任务
const getShowPersonalTask = '/task/taskMainBudget/getShowPersonalTask?termSlug='
// 查询子任务数量
const getTaskMainItemNumber  = '/task/taskMainBudget/getTaskMainItemNumber?taskId='


export default {
  //  查询上月详情
  lastMonthDetail: (data) => {
    return API.POST(`${BaseApi}${lastMonthDetail}`, data)
  },
  // 本月/历史任务查询接口
  getTaskListApi: (data) => {
    return API.POST(`${BaseApi}${getTaskList}`, data)
  },
  // 查询指标列表项
  getTermSlugApi: () => {
    return API.GET(`${BaseApi}${getTermSlug}`)
  },
  // 编辑任务
  saveIndex: (params) => {
    return API.POST(`${BaseApi}${saveIndex}`, params)
  },
  // 子任务列表详情
  subtaskDetail: (id) => {
    return API.GET(`${BaseApi}${subtaskDetail}` + id)
  },
  // 子任务编辑
  updateSubtaskDetail: (params) => {
    return API.POST(`${BaseApi}${updateSubtaskDetail}`, params)
  },
  // 子任务新增
  saveSubtaskDetail: (params) => {
    return API.POST(`${BaseApi}${saveSubtaskDetail}`, params)
  },
  // 子任务删除
  deleteSubtaskDetail: (id) => {
    return API.GET(`${BaseApi}${deleteSubtaskDetail}` + id)
  },
  // 查询执行者
  getExecutorListApi: () => {
    return API.GET(`${BaseApi}${getExecutorList}`)
  },
  // 修改任务指标--预审总监
  updateTaskMainZb: (params) => {
    return API.POST(`${BaseApi}${updateTaskMainZb}`, params)
  },

  // 查询审批列表
  getWaitBeDoneApi: (params) => {
    buildParam(params)
    return API.POST(`${BaseApi}${getWaitBeDone}`, params)
  },
  // 审批任务
  updatecheckTask: (params) => {
    return API.POST(`${BaseApi}${updatecheckTask}`, params)
  },
  // 审批流程查询
  getApprovalList: (params) => {
    return API.POST(`${BaseApi}${getApprovalList}`, params)
  },
  // 审批结果
  checkFlowResult: (params) => {
    buildParam(params)
    return API.POST(`${BaseApi}${checkFlowResult}`, params)
  },
  // 任务成果上传接口
  taskJzResultSubmit: (params) => {
    return API.POST(`${BaseApi}${taskJzResultSubmit}`, params)
  },
  // 获取市场列表
  getMarketListApi: (params) => {
    return API.GET(`${BaseApi}${getMarketList}`)
  },
  // 校验月份是否重复
  checkDateApi: (params) => {
    return API.GET(`${BaseApi}${checkDate}`,params)
  },
  // 下发工地罚金任务
  issueSitePenalty: (params) => {
    return API.POST(`${BaseApi}${issueSitePenalty}`, params)
  },
  // 下载文件
  downOSSFile: (params) => {
    return API.downloadFileGET(`${SysApi}${downOSSFile}`,params)
  },
  // 查询是否分配子任务
  getShowPersonalTask:(params) => {
    return API.GET(`${BaseApi}${getShowPersonalTask}`+params)
  },
  // 获取子任务数量
  getTaskMainItemNumber:(params) => {
    return API.GET(`${BaseApi}${getTaskMainItemNumber}`+params)
  },
}
