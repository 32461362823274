<template>
    <div class="directorOperations">
        <headerNav title="签单手排行榜" :leftIcon="true" @leftClick="back">
            <template>
                <span @click="openChooseDate" class="headNav-righ-text">
                    {{ getParams().date }}
                    <van-icon name="arrow-down" />
                </span>
            </template>
        </headerNav>
        <div class="page-contnet">
            <el-table :data="signListData" header-row-class-name="table-head-class" @sort-change="sortChange">
                <el-table-column type="index" label="排名" align="center" width="65">
                    <template #default="scope">
                        <span class="cell-img-box" v-if="scope.$index == 0"><img
                                :src="require('@/assets/hw-s7.svg')"></span>
                        <span class="cell-img-box" v-else-if="scope.$index == 1"><img
                                :src="require('@/assets/hw-s8.svg')"></span>
                        <span class="cell-img-box" v-else-if="scope.$index == 2"><img
                                :src="require('@/assets/hw-s10.svg')"></span>
                        <span v-else>{{ scope.$index + 1 }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="signName" label="签单手" width="120" />
                <el-table-column prop="marketName" label="市场" width="80">
                    <template #default="scope">
                        {{ scope.row.marketName.replace('市场', '') }}
                    </template>
                </el-table-column>
                <el-table-column prop="orderReceivingNum" sortable="custom" width="100" label="接单数" align="right" />
                <el-table-column prop="signNum" sortable="custom" width="100" label="签单数" align="right" />
                <el-table-column prop="signRate" sortable="custom" width="100" label="签单率" align="right">
                    <template #default="scope">
                        {{ scope.row.signRate }}%
                    </template>
                </el-table-column>
                <div slot="empty" style="text-align: left;">
                    <div class="table-empty">
                        <img :src="require('@/assets/m-empty.png')">
                        <span>暂无数据</span>
                    </div>
                </div>
            </el-table>
        </div>


        <van-popup v-model="popupShow" class="my-popup" overlay-class="my-popup-cover" position="bottom"
            :safe-area-inset-bottom="true" :style="{ height: '370px' }">
            <div class="my-popup-box">
                <div class="my-popup-head">
                    日期筛选
                    <van-icon name="cross" size="16" @click="popupShow = false" />
                </div>
                <div class="my-popup-content">
                    <van-picker show-toolbar title="" :columns="columns" toolbar-position="bottom" ref="myPicker"
                        style="height: 100%;">
                        <template #default>
                            <div class="my-popup-footer">
                                <van-button type="default" @click="restSearch">重置</van-button>
                                <van-button type="info" @click="sureSearch">确认</van-button>
                            </div>
                        </template>
                    </van-picker>
                </div>

            </div>
        </van-popup>
    </div>
</template>

<script>
import api from '@/api/api.js'
import moment from 'moment';

export default {
    data () {
        return {
            popupShow: false,
            columns: [{ values: [] }, { values: [] }],
            year: moment().year(),
            month: '全部',
            signListData: []
        }
    },
    methods: {
        //打开日期选择
        openChooseDate () {
            this.$refs.myPicker && this.$refs.myPicker.setValues([this.year, this.month])
            this.popupShow = true
        },
        //获取参数
        getParams () {
            let m = Number(this.month.replace('月', ''))
            //日期
            let date = m ? this.year + '-' + (m < 10 ? '0' + m : m) : this.year + ''
            return { date }
        },
        //获取列表
        async getSignList ({ prop, order }) {
            console.log(prop, order)
            let { date } = this.getParams()
            let sortField = ''
            if (prop == 'orderReceivingNum') sortField = 'order_receiving_num'
            if (prop == 'signNum') sortField = 'sign_num'
            if (prop == 'signRate') sortField = 'sign_rate'
            let obj = {
                queryStr: JSON.stringify({
                    Q_reportData_LK: date
                }),
                order: sortField + (order != '' ? (order.indexOf('desc') > -1 ? ' desc' : ' asc') : ''),
            }
            let res = await api.signDetailsList(obj)
            this.signListData = res.data
        },
        //排序
        sortChange ({ prop, order }) {
            this.getSignList({ prop: prop, order: order })
        },
        back () {
            this.$router.go(-1)
        },
        restSearch () {
            this.year = moment().year()
            this.month = '全部'
            //重置
            this.popupShow = false
            this.getSignList({ prop: 'signNum', order: 'desc' })
        },
        sureSearch () {
            this.year = this.$refs.myPicker.getValues()[0]
            this.month = this.$refs.myPicker.getValues()[1]
            //确认搜索
            this.popupShow = false
            this.getSignList({ prop: 'signNum', order: 'desc' })
        },
    },
    created () {
        let y = []
        let nowYear = moment().year()
        for (let i = nowYear; i > nowYear - 21; i--) {
            y.push(i)
        }
        let m = ['全部']
        for (let i = 1; i < 13; i++) {
            m.push(i + '月')
        }
        this.columns[0].values = y
        this.columns[1].values = m
        this.year = this.$route.query.year
        this.month = this.$route.query.month
        this.getSignList({ prop: 'signNum', order: 'desc' })
    },
    mounted () {

    }
}
</script>

<style lang="scss" scoped>
.page-contnet {
    height: calc(100vh - 50px);
    overflow: auto;
}

.headNav-righ-text {
    font-size: 16px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
}

.my-popup-cover {
    background: rgba(0, 0, 0, 0.5);

}

.my-popup {
    background: #FFFFFF;
    border-radius: 16px 16px 0px 0px;

    .my-popup-box {
        .my-popup-head {
            line-height: 48px;
            border-bottom: 1px solid #DFDFDF;
            text-align: center;
            font-weight: bold;
            color: #424242;
            position: relative;
            font-size: 16px;

            i {
                position: absolute;
                right: 19px;
                top: calc(50% - 8px);
            }
        }

        .my-popup-content {
            height: calc(100% - 10px);
            overflow: hidden;
        }

        .my-popup-footer {
            height: 75px;
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;
            gap: 12px;

            button {
                width: 164px;
                height: 44px;
                border-radius: 8px;
            }
        }
    }
}

.table-empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
        width: 160px;
        margin-bottom: 10px
    }

    span {
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #5E5E5E;
    }
}
::v-deep .el-table__empty-block {
	width: 100%;
	min-width: 100%;
	max-width: 100%;
}
::v-deep {
    .van-picker-column__item div {
        transition: all 0.5s;
    }

    .van-picker-column__item--selected div {
        font-size: 19px;
    }

    .table-head-class th {
        background: #F5F5F5;
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #1D2129;
    }

    .el-table__body-wrapper {
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #1D2129;

        tr {
            .el-table__cell {

                &:nth-child(4),
                &:nth-child(5),
                &:nth-child(6),
                &:nth-child(7) {
                    font-size: 16px;
                    font-family: Bahnschrift-Light, Bahnschrift;
                    font-weight: 300;
                    color: #1D2129;
                }

                .cell-img-box {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                img {
                    width: 20px;
                }
            }

        }
    }

    .myecharts-toop {
        line-height: 30px;
        font-size: 17px;
        font-family: Bahnschrift-SemiLight, Bahnschrift;
        font-weight: normal;
        color: #FFFFFF;
        // align-items: center;
        padding: 0 5px;
        position: relative;

        &:first-child::after {
            content: '';
            position: absolute;
            height: 1px;
            width: 100%;
            bottom: 0;
            left: 0;
            background: #2B88F9;
        }

        span {
            display: inline-block;
            width: 50%;
        }

        .m-span {
            width: 50%;
            font-size: 14px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            text-align: left;

            &:last-child {
                text-align: right;
                padding-right: 10px;
            }
        }
    }
}
</style>