<template>
  <div class="content">
    <headerNav leftNav=" " :title="navTitle" @leftClick="back"></headerNav>
    <main class="content-main">
      <!-- 步骤条 -->
      <!-- 总经理或者前台总监转单量补齐量到店量签单量 -->
      <TaskProgress
        v-if="(routerData.roleCode == 'ROLE_603933' || routerData.roleCode == 'ROLE_394260' || routerData.roleCode == 'ROLE_768022' || routerData.roleCode == 'ROLE_886285') && (routerData.termSlug == 'transfer_count' || routerData.termSlug == 'supply_count' || routerData.termSlug == 'signed_count' || routerData.termSlug == 'shop_count')"
        :perform="{ taskGoalCount: taskPlanObj.subordinateTask, percentageRate: Number(taskPlanObj.subordinateTaskAccomplishRate) || 0 }"
        :percentageMoney="taskPlanObj.practicalAccomplish"
        :unit="routerData.termSlug == 'transfer_output' || routerData.termSlug == 'supply_output' ? '万' : '个'" />
      <TaskProgress v-else
        :subAndSup="{ supTask: taskPlanObj.superiorsTask, supRate: Number(taskPlanObj.superiorsAccomplishRate), subTask: taskPlanObj.subordinateTask, subRate: Number(taskPlanObj.subordinateTaskAccomplishRate) }"
        :isProgress="false" :percentageMoney="taskPlanObj.practicalAccomplish"
        :unit="routerData.termSlug == 'transfer_output' || routerData.termSlug == 'supply_output' ? '万' : '个'" />

      <!-- 详情 -->
      <div class="content-center-title">
        <div class="left-line"></div>
        <div>详情</div>
      </div>

      <!-- table -->
      <el-table :data="transferTableData" style="width: 100%" header-cell-class-name="market-title"
        row-class-name="market-row" row-key="departmentId" :expand-row-keys="defaultExpandList">
        <!-- 自定义暂无数据 -->
        <template slot="empty">
          <div class="empty-no-data">
            <img src="../../../../assets/task-management/no-data.png" alt="">
            <div>暂无数据</div>
          </div>
        </template>
        <el-table-column type="index" label="TOP" align="center" width="40">
          <template slot-scope="props">
            <div v-if="props.$index < 3" style="line-height: 1;"><img class="index-img" :src="getImageIndex(props.$index)"
                alt=""></div>
            <div v-else> {{ props.$index + 1 }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="departmentName" label="部门" min-width="44">
          <template slot-scope="props">
            <span class="market-content-a" @click="onClickDepartmentDeatils(props.row)">{{
              props.row.departmentName ? props.row.departmentName.slice(0, -1) : ''
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="transferProductionValue" :label="outputCount.transferProductionValue" align="right"
          min-width="85">
        </el-table-column>
        <el-table-column prop="taskProductionValue" :label="outputCount.taskProductionValue" align="right" min-width="85">
        </el-table-column>
        <el-table-column prop="accomplishRate" label="完成率" align="right" min-width="67">
          <template slot-scope="scope">
            <span v-if="scope.row.accomplishRate">{{ scope.row.accomplishRate && scope.row.accomplishRate != '-' ?
              scope.row.accomplishRate + '%' : scope.row.accomplishRate
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="handle" label="操作" min-width="38">
          <template slot-scope="scope">
            <span class="market-details" v-if="scope.row.departmentName != '客服部'" @click="expandControlRow(scope.row)">详情</span>
          </template>
        </el-table-column>
        <!-- 展开行详情 -->
        <el-table-column type="expand" width="1">
          <template slot-scope="scope">
            <div class="market-content-expand">
              <div class="market-content-expand-left">
                <img src="@/assets/task-management/task-details-table-expand.png" alt="">
                <div>上月任务：{{ scope.row.lastMonthProductionValue }}{{ routerData.termSlug == 'transfer_output' ||
                  routerData.termSlug == 'supply_output' ? '万元' : '个' }}</div>
              </div>
              <div class="market-content-expand-right">
                <span class="color-green" v-if="scope.row.isSuccess == '1'">已完成</span>
                <span class="color-red" v-else>未完成</span>
              </div>
            </div>
            <!-- <div class="market-content-expand-row" v-if="props.row.taskProductionValue == '-'">
              <div class="market-content-expand-seting" @click="setTask(props.row)">
                <img src="@/assets/task-management/task-details-table-seting.png" alt="">
                <div>任务设置</div>
              </div>
            </div> -->
          </template>
        </el-table-column>
      </el-table>
    </main>

    <!-- 任务设置 -->
    <van-dialog v-model="setTaskDialogVisible" :showConfirmButton="false" @close="setTaskDialogClose">
      <div class="set-task-dialog">
        <!-- 导航 -->
        <div class="set-task-dialog-nav">
          任务设置
          <div class="set-task-dialog-nav-close" @click="closeSetTaskDialog">×</div>
        </div>
        <!--内容  -->
        <div class="set-task-dialog-mian">
          <van-form @submit="onSubmitTask">
            <van-field v-model="taskDialogFrom.value" name="任务产值" label-width="80px"
              :label="outputCount.taskProductionValue.slice(0, 4) + ':'"
              :placeholder="'请输入' + outputCount.taskProductionValue.slice(0, 4)" :rules="[{ required: true }]">
              <i slot="left-icon" style="color:red">*</i>
              <template #button>万</template>
            </van-field>
            <div class="set-task-dialog-foot">
              <van-button class="btn" round block type="info" native-type="submit">确认</van-button>
            </div>
          </van-form>

        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import TaskProgress from "@/components/task-management/task-progress.vue"
import estimateOutput from "../../component/data.js"
import api from "@/api/task-management/board.js";

export default {
  components: {
    TaskProgress
  },
  data () {
    return {
      // 路由接参
      routerData: {},
      // 转单表格数据
      transferTableData: [],
      // 展开行id--转单
      defaultExpandList: [],
      // 任务设置弹窗
      setTaskDialogVisible: false,
      taskDialogFrom: {
        value: ''
      },
      // 表头动态展示内容
      outputCount: {},
      // 顶部任务产值目标
      taskPlanObj: {}
    }
  },
  created () {
    let localData = JSON.parse(localStorage.getItem('TABLE_HEADER_CONTENT'))
    let marketData = JSON.parse(localStorage.getItem('MARKET_DATA'))
    this.routerData = { ...localData, ...marketData }
    this.getTableData()
    this.outputCount = estimateOutput(localData.termSlug)
    this.navTitle = this.outputCount.transferProductionValue.slice(0, 4) + '详情'
  },
  methods: {
    back () {
      this.$router.go(-1)
    },
    // 查询表格数据
    async getTableData () {
      const { data } = await api.taskDetailApi({ ext: this.routerData.termSlug, Q_reportData_EQ: this.routerData.Q_reportData_EQ, marketCode: this.routerData.marketCode, type: '2' })
      this.taskPlanObj = data.accomplishCondition ? data.accomplishCondition : {}
      this.transferTableData = data.data01
    },
    // 控制展开行--转单
    expandControlRow (item) {
      const position = this.defaultExpandList.indexOf(item.departmentId)
      if (position !== -1) {
        this.defaultExpandList.splice(position, 1)
      } else {
        this.defaultExpandList.push(item.departmentId)
      }
    },
    // 获取图片
    getImageIndex (index) {
      return require(`@/assets/task-management/task-details-table-${index + 1}.png`)
    },
    // 任务设置
    setTask (row) {
      this.setTaskDialogVisible = true
    },
    // 确定
    onSubmitTask () {
      this.setTaskDialogVisible = false
    },
    // 关闭
    closeSetTaskDialog () {
      this.setTaskDialogVisible = false
    },
    setTaskDialogClose () {
      this.taskDialogFrom = {
        value: ''
      }
    },
    // 点击部门查看人员
    onClickDepartmentDeatils (row) {
      let obj = { ...row, ...this.routerData }
      localStorage.setItem('USER_DATA', JSON.stringify(obj))
      if(row.departmentName.indexOf("客服部") != -1){
        this.$router.push({ name: "TaskBoardNaturalInStoreCustomerList"})
      }else{
        this.$router.push({ path: '/task-management/board/employee-list' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content ::v-deep {
  .el-table__expand-icon>.el-icon {
    display: none;
  }

  .empty-no-data {
    img {
      width: 160px;
      height: 150px;
    }

    div {
      color: #5E5E5E;
      font-size: 14px;
      margin-top: -40px;
    }
  }

  .content-main {
    background: #F5F5F5;
    height: calc(100vh - 50px);
    overflow: auto;
    margin-top: -1px;

    .content-center-title {
      height: 47px;
      line-height: 47px;
      display: flex;
      align-items: center;
      background: #fff;
      padding: 0px 10px;
      margin-top: 16px;

      .left-line {
        width: 4px;
        height: 18px;
        background: #0E8BFF;
        margin-right: 8px;
      }

      div {
        color: #333333;
        font-size: 18px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: 550;
      }
    }

    .market-title {
      background-color: #F5F5F5;
      color: #1D2129;
      font-size: 14px;
      font-family: PingFang SC-Bold, PingFang SC;
      padding: 0px 0px;

      .cell {
        padding: 10px 3px;
        font-size: 13px;
        font-weight: 500;
      }
    }

    .market-row {
      font-size: 16px;
      color: #1D2129;

      .el-table__cell {
        padding: 3px 0px;
        height: 30px;
      }

      .cell {
        padding: 5px 3px;
        font-weight: 400;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .el-table__expand-colum {
      visibility: hidden;
    }

    .el-table__body-wrapper {
      height: calc(100vh - 150px);
      overflow: auto;
    }

    .market-details {
      color: #0E8BFF;
      font-size: 13px;
    }

    .market-content-a {
      color: #0E8BFF;
      font-size: 13px;
      font-weight: 400;
      white-space: nowrap;
      // /* 不换行 */
      overflow: hidden;
      // /* 隐藏超出部分 */
      text-overflow: ellipsis;
      display: block;
    }

    .index-img {
      width: 20px;
      height: 20px;
    }

    .market-content-expand {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 11px 16px;

      .market-content-expand-left {
        display: flex;
        align-items: center;

        img {
          width: 20px;
          height: 20px;
        }

        div {
          color: #545454;
          font-size: 15px;
          margin-left: 10px;
        }
      }

      .market-content-expand-right {
        width: 46px;
        height: 22px;
        line-height: 22px;
        font-size: 13px;
        text-align: center;
        font-weight: bold;
        font-family: PingFang SC-Bold, PingFang SC;

        span {
          padding: 2px 3px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: visible;
          font-weight: 500;
          border-radius: 4px;
        }

        .color-red {
          color: #FF6157;
          background: #FAEBEA;
        }

        .color-green {
          color: #17CA5E;
          background: #E3F5EA;
        }
      }
    }

    .market-content-expand-row {
      display: flex;
      justify-content: end;
      margin: 0px 11px 4px;
      padding-top: 12px;
      border-top: 1px solid #F1F1F1;

      .market-content-expand-seting {
        display: flex;
        align-items: center;
        width: 91px;
        height: 28px;
        line-height: 28px;
        border: 1px solid #3DA2FF;
        color: #0E8BFF;
        font-size: 14px;
        border-radius: 4px;
        padding-left: 6px;
        box-sizing: border-box;
        cursor: pointer;
        user-select: none;

        img {
          width: 18px;
          height: 18px;
          margin: 1px 2px 0px 0;
        }
      }
    }


  }

  .set-task-dialog {
    .set-task-dialog-nav {
      text-align: center;
      margin: 12px 0px;
      padding: 0 17px 12px 17px;
      font-weight: bold;
      color: #424242;
      font-size: 16px;
      border-bottom: 1px solid #DFDFDF;

      .set-task-dialog-nav-close {
        float: right;
        font-size: 15px;
      }
    }

    .set-task-dialog-mian {
      padding: 16px 0 0;

      .van-field__body {
        border-bottom: 1px solid #BEBEBE;
      }

      .van-field__label {
        margin-right: 0;
        color: #252523;
        font-size: 16px;
      }

      .van-cell::after {
        border: 0;
      }

      .set-task-dialog-foot {
        // width: 100%;
        text-align: center;
        padding: 0px 14px 18px 15px;
        margin-top: 16px;

        .btn {
          border-radius: 4px;
          // width: 290px;
          // height: 44px;
          background: #3975C6;
        }
      }

    }

  }

}
</style>