<template>
	<div class="addPatrolList-style">
		<headerNav title="新增" leftNav="取消" @leftClick="cancel" rightNav="提交" @rightClick="submit"></headerNav>
		<div class="content-style">
			<van-form @submit="onSubmit" ref="vantForm">
				<div class="card-style">
					<div class="input-box">
						<div class="input-text">巡检时间 <span class="xing-style">*</span> </div>
						<div class="input-style">
							<van-field :rules="[{ required: true}]" readonly  @click="chooseTime" v-model="addInfo.time" class="field-style" name="巡检时间"
								clickable right-icon="warning-o" placeholder="请选择">
								<template v-slot:right-icon>
									<div class="field-right-icon">
										<img src="@/assets/xzjt.png" class="choose-icon" alt="">
									</div>
								</template>
							</van-field>
							<van-action-sheet v-model="timeShow">
								<div>
									<van-datetime-picker @confirm="timeSubmit" @cancel="timeCancel" v-model="timeDate"
										type="datetime" title="选择时间" />
								</div>
							</van-action-sheet>
						</div>
					</div>
					<div class="cut-off-rule-style"></div>
					<div class="input-box">
						<div class="input-text">巡检阶段 <span class="xing-style">*</span> </div>
						<div class="input-style">
							<van-field :rules="[{ required: true}]" readonly  @click="chooseShowClick('stage')" v-model="addInfo.nodeName"
								class="field-style" name="巡检阶段" clickable right-icon="warning-o" placeholder="请选择">
								<template v-slot:right-icon>
									<div class="field-right-icon">
										<img src="@/assets/xzjt.png" class="choose-icon" alt="">
									</div>
								</template>
							</van-field>
						</div>
					</div>
					<div class="cut-off-rule-style"></div>
					<div class="input-box">
						<div class="input-text">客户跟随 <span class="xing-style">*</span> </div>
						<div class="input-style">
							<van-field :rules="[{ required: true}]" readonly  @click="chooseShowClick('clientFollow')" v-model="addInfo.clientFollowName"
								class="field-style" name="客户跟随" clickable right-icon="warning-o" placeholder="请输入">
								<template v-slot:right-icon>
									<div class="field-right-icon">
										<img src="@/assets/xzjt.png" class="choose-icon" alt="">
									</div>
								</template>
							</van-field>
						</div>
					</div>
					<div class="cut-off-rule-style"></div>
					<div class="input-textarea-box">
						<div class="input-text">巡检说明 </div>
						<div class="input-style">
							<van-field class="input-style" v-model="addInfo.inspectionRemark" rows="3" autosize type="textarea"
								name="巡检说明" placeholder="请输入" />
						</div>
					</div>
					<van-action-sheet v-model="chooseShow" :actions="actions" @select="onSelect" cancel-text="取消"
						close-on-click-action />
				</div>
				<div class="card-style" v-if="addInfo.nodeCode!=null">
					<div class="input-box" @click="addProject">
						<div class="add-project-style">
							<img src="@/assets/add.png" alt="" class="add-icon">
							<div class="text">移入巡检项</div>
						</div>
					</div>
					<div class="cut-off-rule-style"></div>
				</div>
				<div class="card-style" style="margin-top: 0;">
					<div class="project-item" v-for="(item,index) in addInfo.items">
						<div class="input-box">
							<div class="add-project-style">
								<img @click="delProject(index)" src="@/assets/del.png" alt="" class="add-icon">
								<div class="">
									<div class="general-input">{{item.itemName}}</div>
								</div>
							</div>
						</div>
						<div class="cut-off-rule-style"></div>
						<div class="input-box">
							<div class="input-text">是否合格 <span class="xing-style">*</span> </div>
							<div class="input-style">
								<van-field :rules="[{ required: true}]" v-model="item.standardFlagName" readonly  @click="chooseShowClick('standard',index)"
									class="field-style" name="是否合格" clickable right-icon="warning-o" placeholder="请输入">
									<template v-slot:right-icon>
										<div class="field-right-icon">
											<img src="@/assets/xzjt.png" class="choose-icon" alt="">
										</div>
									</template>
								</van-field>
							</div>
						</div>
						<div class="input-box upPhoto-style">
							<div class="input-text">现场照片
							 <span class="xing-style">*</span> </div>
							<div class="input-style">
								<van-uploader multiple
									:after-read="(val)=>afterRead(val,item)">
									<van-button class="button-style" type="primary"></van-button>
								</van-uploader>
								<img src="@/assets/upPhoto.png" class="choose-icon" alt="">
							</div>
						</div>
						<div class="photo-box" v-show="item.imgs.length">
							<div class="photo-style" v-for="(item2,index2) in item.imgs" style="position: relative;">
								<div class="del-icon" @click="delPhoto(item.imgs,index2)">×</div>
								<img @click="photoPreview(item.imgs,index2)" style="width: 100%;height: 100%;" :src="item2.webUrl" alt="" />
								<div class="img-overlay" v-if="item2.status === 'uploading'">
									<van-loading size="20" color="#f5f5f5" />
								</div>
								<div class="img-overlay" v-if="item2.status === 'failed'">
									<van-icon name="fail" size="20" color="#f5f5f5" />
								</div>
							</div>
						</div>
						<div class="cut-off-rule-style"></div>
						<div class="input-textarea-box">
							<div class="input-text">备注信息 </div>
							<div class="input-style">
								<van-field class="input-style" v-model="item.remark" rows="3" autosize type="textarea"
									name="备注信息" placeholder="请输入" />
							</div>
						</div>
						<div class="cut-off-rule-style"></div>
					</div>
				</div>
			</van-form>
		</div>
		<hintModel :show="overShow" @cancel="overShow = false" @submit="submitSure" submitText="确定提交" cancelText="我再想想" hintText="提交之后不可再次编辑，确定提交吗？">
		</hintModel>
	</div>
</template>

<script>
	import {
		ImagePreview
	} from 'vant';
	import {
		timeFormat
	} from '@/utils/timeCompute'
	import api from '@/api/api.js'
	import {
		Toast
	} from 'vant';
	export default {
		data() {
			return {
				overShow: false,
				chooseShow: false,
				actions: [],
				timeShow: false,
				info: {},
				timeDate: new Date(),
				addInfo: {
					projectId:null,
					time:'',
					nodeName:'',
					nodeCode:'',
					isFollow:'1',
					inspectionRemark:'',
					items:[],
					clientFollowName:'是',
					isDel:'0'
					
					// id:null,
					// checkTime: '',
					// itemId: null,
					// itemName: '',
					// companionFlag: 1,
					// clientFollowName:'是',
					// remark: '',
					// depthCheckContent: []
				},
				projectIndex: '',
				select: '',
				itemIdList: [],
				clientFollowList: [{
						value: '1',
						name: '是'
					},
					{
						value: '0',
						name: '否'
					}
				],
				clientIsStandardList: [{
						value: '2',
						name: '是'
					},
					{
						value: '0',
						name: '否'
					}
				],
			}
		},
		methods: {
			onSelect(item) {
				if (this.select == 'stage') {
					if(this.addInfo.nodeCode != item.value) this.addInfo.items = []
					this.addInfo.nodeName = item.name
					this.addInfo.nodeCode = item.value
				} else if (this.select == 'clientFollow') {
					this.addInfo.clientFollowName = item.name
					this.addInfo.isFollow = item.value
				} else if (this.select == 'standard') {
					this.addInfo.items[this.projectIndex].standardFlagName = item.name
					this.addInfo.items[this.projectIndex].qualifiedStatus = item.value
				} else if(this.select  == 'addStage'){
					this.addInfo.items.unshift({
						contentId:item.value,
						qualifiedStatus:'2',
						checkStatus:'0',
						isDel:'0',
						imgs:[],
						standardFlagName: '是',
						itemName: item.name,
						
						
						// itemName: item.name,
						// itemId:item.value,
						// standardFlag: 1,//合格标志(1:合格;0:不合格)
						// standardFlagName: '是',
						// parentItemId:this.addInfo.itemId,//
						// imgUrlList: [],
						// remark: '',
						// levelNo: "1"
					})
				}
			},
			chooseShowClick(type, index,list) {
				this.select = type
				this.projectIndex = index
				if (type == 'stage') {
					this.actions = this.itemIdList
				} else if (type == 'clientFollow') {
					this.actions = this.clientFollowList
				} else if (type == 'standard') {
					this.actions = this.clientIsStandardList
				} else if(type == 'addStage'){
					this.actions = list
				}

				this.chooseShow = true
			},
			addProject() {
				if(this.addInfo.nodeCode==null){
					return
				}else{
					this.itemIdList.forEach((e,i)=>{
						if(this.addInfo.nodeCode==e.value){
							let temp = []
							e.children?.forEach(e2=>{
								temp.push({
									value:e2.id,
									name:e2.contentName
								})
							})
                            if(!temp.length){
                                 Toast.fail('该阶段未维护巡检内容！')
                                 return;
                            }
							this.chooseShowClick('addStage',i,temp)
						}
					})
				}
			},
			delProject(index) {
				this.addInfo.items.splice(index, 1)
			},
			chooseTime() {
				this.timeShow = true
			},
			timeSubmit(val) {
				this.addInfo.time = timeFormat(val, '-','yy-mm-dd HH:mm:ss')
				this.timeShow = false
			},
			timeCancel(val) {
				this.timeShow = false
			},
			cancel() {
				this.$router.push({
					name: "deepenPatrolListlName",
					query: this.info
				})
			},
			submit() {
                if(!this.addInfo.items?.length){
                    Toast.fail('请移入巡检项')
                    return
                }
				this.overShow = true
				// this.$router.push({
				// 	name: "deepenPatrolListlName",
				// 	query: this.info
				// })
			},
			async submitSure() {
				let required = true
				let tip = ''
				const items = this.addInfo.items
				for(let i=0;i<items.length;i++){
					if(!items[i].imgs.length){
						required = false
						tip = '请上传现场照片！'
						break
					}else if(items[i].imgs.some(item => item.status === 'uploading')){
						required = false
						tip = '图片上传未完成，请等待...'
						break
					}else if(items[i].imgs.some(item => item.status === 'failed')){
						required = false
						tip = '图片上传失败\n请重新上传'
						break
					}
				}
				if(required){
					this.$refs.vantForm.submit()
				}else{
					Toast.fail(tip)
				}
			},
			async onSubmit() {
				let obj = this.addInfo
				let res = await api.addPatrolRecord(obj)
				if(res.code==200){
					this.$router.push({
						name: "deepenPatrolListlName",
					})
				}
				this.overShow = false
			},
			async afterRead(file, item) {
                let fileList = Array.isArray(file)?file:[file]
                if (fileList.length>9){
                    Toast.fail('最多一次上传九张图片！')
                    fileList = fileList.splice(0,9)
                }
				const confirmFileList = fileList.map(item1 => ({ webUrl: item1.content, status: 'uploading' }))
				item.imgs = item.imgs.concat(confirmFileList)
				const indexR = item.imgs.length - fileList.length
                fileList.forEach((e, indexF)=>{
                    // 此时可以自行将文件上传至服务器
                    let formData = new FormData();
                    formData.append('files', e.file);
                    formData.append('dir', 'oss');
					new Promise((reslove) => {
						const res = api.upFile(formData)
						reslove(res)
					}).then((res) => {
						if (res.code == 200) {
							item.imgs[indexR + indexF].webUrl = res.url
							item.imgs[indexR + indexF].status = 'done'
						} else {
							item.imgs[indexR + indexF].status = 'failed'
						}
					})
                })
			},
			beforeRead(file, item) {
				if (item.length > 2) {
					return false;
				} else {
					return true
				}
			},
			delPhoto(item, index) {
				item.splice(index, 1)
			},
			//巡检阶段
			async getProjectInspectList() {
				let obj = {
					Q_isDel_EQ:'0',
				}
				let res = await api.getProjectNodeTree(obj)
				if (res.code == 200) {
					let temp = []
					res.data.forEach(e => {
						temp.push({
							value: e.id,
							name: e.contentName,
							children:e.children
						})
					})
					this.itemIdList.push(...temp)
				}
			},
			photoPreview(list,index) {
                let imgList = list.map(e => e.webUrl)
				let o = {
                    images: imgList,
					startPosition: index
				}
				ImagePreview(o);
			},
		},

		mounted() {
			//获取巡检阶段列表
			this.getProjectInspectList()
			this.info = JSON.parse(sessionStorage.getItem('clientInfo'))
			this.addInfo.projectId = this.info.id
		},
	}
</script>

<style scoped lang="scss">
	.addPatrolList-style {
		.content-style {
			height: calc(100vh - 50px);
			overflow: auto;

			.card-style {
				margin-top: 10px;
				padding-left: 18px;
				background: #FFFFFF;

				.field-style .van-field__control {
					color: #4F7AFD !important;
				}

				.add-project-style {
					height: 45px;
					display: flex;
					align-items: center;

					.text {
						font-size: 15px;
						// font-family: Source Han Sans SC;
						font-weight: 400;
						color: #333333;
					}

					.input {
						display: flex;
						align-items: center;

						.input-style {
							padding: 0;
							margin-left: 0;
						}
					}

					.input1 {
						width: 75px;
						border-right: 1px solid #E6E6E6;
					}

					.input2 {
						margin-left: 18px;
					}

					.add-icon {
						width: 22px;
						height: 22px;
						margin-right: 17px;
					}

					.general-input {
						padding: 0;
						font-size: 15px;
						// font-family: Source Han Sans SC;
						font-weight: 400;
						color: #333333;
					}
				}

				// .project-item {
				// 	// .cut-off-rule-style:last-child {
				// 	// 	// display: none;
				// 	// }
				// }

				.photo-box {
					background: #FFFFFF;
					display: flex;
					padding-bottom: 18px;
					flex-wrap: wrap;
					.photo-style {
						width: 100px;
						height: 100px;
						margin-right: 10px;
						margin-top: 10px;
						position: relative;
						.img-overlay {
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							background-color: rgba(0, 0, 0, 0.65);
							display: flex;
							align-items: center;
							justify-content: center;
						}
					}

					.center-style {
						margin: 0 20px;
					}

					.del-icon {
						width: 16px;
						height: 16px;
						background: #000000;
						color: #FFFFFF;
						opacity: 0.8;
						border-radius: 50%;
						font-size: 9px;
						line-height: 16px;
						position: absolute;
						right: -6px;
						top: -6px;
						text-align: center;
						border: none;
						border-radius: 50%;
						z-index: 1;
					}
				}

			}
		}
	}
</style>
<style>
	.addPatrolList-style .field-style .van-field__control {
		color: #4F7AFD !important;
	}
</style>
