// 财务看板
import * as API from "../index.js";
import { buildParam } from '@/utils/index'
const BaseApi = process.env.VUE_APP_BASE_API
// const BaseApi = 'http://192.168.0.219:30780/mock/42'
const SysApi = process.env.VUE_APP_SYS_API

// ===============任务看板====================
// 详情数据列表
const detailList = '/task/taskBasicsTarget/select/taskDetail'
// 列表上月详情查询
const lastMonthDetail = '/task/taskBasicsTarget/select/lastMonthDetail'
/* 任务审批结果上传 */
const getApproveList = '/task/approveAccount/getApproveList'
/* 任务审批结果流程进度 */
const getListByTaskId  = '/task/approveAccount/getById?id='
/* 审批 */
const checkFlowResult  = '/task/approveAccount/checkFlowResult'
/* 文件上传oss */
const upLoad = '/tools/oss/ali/upload'
/* 任务成果上传 */
const taskJzResultSubmit = '/task/taskMainBudget/taskJzResultSubmit'

/* ======================任务中心================== */
// 本月/历史任务查询接口
const getTaskList = '/task/marketSummarize/getTaskList?monthType='
// 查询审批任务数量
const checkTaskNumber = '/task/approveAccount/pendingApprovalCount?taxonomy='
// 查询审批列表
const getApproveCheck = '/task/marketSummarize/getApproveList'

export default {

  // 任务看板-管理看板
  detailListApi: (params) => {
    buildParam(params)
    return API.POST(`${BaseApi}${detailList}`, params)
  },
  // 查询表格详情
  lastMonthDetail: (params) => {
    buildParam(params)
    return API.POST(`${BaseApi}${lastMonthDetail}`, params)
  },
  // 审批结果上传记录
  getApproveList: (params) => {
    buildParam(params)
    return API.POST(`${BaseApi}${getApproveList}`, params)
  },
  // 审批流程进度
  getListByTaskId: (id) => {
    return API.GET(`${BaseApi}${getListByTaskId}` + id)
  },
  // 审批
  checkFlowResult: (params) => {
    buildParam(params)
    return API.POST(`${BaseApi}${checkFlowResult}`, params)
  },
  // 上传oss
  upLoad: (data) => {
    return API.POST(`${SysApi}${upLoad}`, data)
  },
  // 任务成果上传
  taskJzResultSubmit: (params) => {
    return API.POST(`${BaseApi}${taskJzResultSubmit}`, params)
  },
  // 本月/历史任务查询接口
  getTaskListApi: (data) => {
    return API.GET(`${BaseApi}${getTaskList}`+ data)
  },
  // 查询审批任务数量
  checkTaskNumberApi: (data) => {
    return API.GET(`${BaseApi}${checkTaskNumber}${data}`)
  },
  // 查询审批列表
  getApproveCheck: (params) => {
    buildParam(params)
    return API.POST(`${BaseApi}${getApproveCheck}`, params)
  },
}
