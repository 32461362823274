<template>
  <div class="main">
    <van-sticky class="topMenu">
      <headerNav leftNav=" " :title="'子任务'" @leftClick="back"></headerNav>
    </van-sticky>

    <main>
      <div class="content" :class="upActiveTab != '3'?'':'content-height'" v-if="list.length > 0">
        <!-- 每一项任务 -->
        <div class="content-element" v-for="(item, index) in list" :key="index"
          @click="onClickTaskDetails(item,index)">
          <div class="content-element-review">
            <div class="content-element-review-text">{{item.targetDepartmentName}}-{{ item.targetRoleName }}</div>
            <div class="content-element-review-time">{{ item.userName }} {{ item.isException == '1'?'(异常)':'' }}
              <span style="margin-left: 24px;">
                <span style="color: #0E8BFF;">{{ item.finishValue || 0 }}/</span>{{ item.targetValue }}
              </span>
            </div>
          </div>
          <div class="content-element-final">
            <div class="content-element-final-count">{{ item.finishRate?(parseFloat(item.finishRate)).toFixed(2)+'%':'-' }}</div>
            <div class="content-element-final-text">完成率</div>
          </div>
          <div class="content-element-arrows">
            <img src="@/assets/task-management/task-setup-finance-arrows.png" alt="">
          </div>
        </div>
      </div>
      <!-- 暂无任务 -->
      <div class="no-data" v-if="isData && list.length <= 0">
        <img src="@/assets/task-management/task-setup-nodata.png" alt="">
        <div>暂无任务</div>
      </div>
    </main>

    <footer v-if="upActiveTab != '3'">
      <div @click="createTask">添加</div>
    </footer>
  </div>
</template>

<script>
import api from "@/api/task-management/human-resources.js";
import { Toast,ImagePreview } from "vant";
import moment from "moment";

export default {
  data () {
    return {
      upActiveTab:'',
      list:[],
      isData:false,
    }
  },
  mounted() {
    this.upActiveTab = this.$route.query.upActiveTab
    if(this.$route.query.taskId == ''){
      let data = localStorage.getItem("SUB_TASK_LIST")
      if (data) {
        this.list = JSON.parse(data)
        this.isData = this.list.length > 0?false:true
      }
    }else{
      this.getData()
    }
  },
  methods: {
    back () {
      this.$router.go(-1)
    },
    // 查询表格数据
    async getData () {
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      let params = {
        "taskId": this.$route.query.taskId,
      }
      api.getSubTaskList(params).then((res)=>{
        if (res.code == 200) {
          Toast.clear()
          this.list = res.data
        }else{
          Toast.fail(res.msg)
        }
      }).finally(()=>{
        this.isData = this.list.length > 0?false:true
      })
    },
    // 点击详情
    onClickTaskDetails (item,index) {
      this.$router.push({ 
        name: 'TaskSetupHumanResourcesSubTaskDetails', 
        query: {
          upActiveTab:this.upActiveTab,
          id:item.id,
          index:index
        }
      })
    },
    // 创建子任务
    createTask(){
      this.$router.push({ 
        name: 'TaskSetupHumanResourcesCreateSubTask', 
        query:{
          taskId:this.$route.query.taskId
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.main::v-deep {
  height: 100vh;
  overflow: hidden;

  .van-tab {
    font-size: 17px;
    flex: none;
    width: 25%;
  }

  .van-tab--active {
    font-size: 18px;
  }

  .no-data {
    height: calc(100vh - 136px);
    padding-top: 150px;
    box-sizing: border-box;


    img {
      width: 210px;
      height: 137px;
    }

    div {
      font-size: 16px;
      font-family: Bahnschrift, Bahnschrift;
      font-weight: 400;
      color: rgba(51, 51, 51, 0.5);
    }
  }

  .content {
    height: calc(100vh - 136px);
    background: #F5F5F5;
    overflow: auto;

    .content-element {
      background: #ffffff;
      display: flex;
      // justify-content: space-between;
      align-items: center;
      padding: 16px 16px 17px;
      margin-bottom: 8px;
      width: 100%;
      box-sizing: border-box;
      // position: relative;
      border-radius: 8px 8px 8px 8px;

      .content-element-city {
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        border-radius: 50%;
        background-color: #F7F8FA;
        color: #3975C6;
        font-size: 16px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
      }


      .content-element-review {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        width: 57%;
        height: 60px;
        margin-right: 20px;
        
        .content-element-review-text {
          color: #333333;
          font-size: 18px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          width: 100%;
          white-space: nowrap; /* 不换行 */
          overflow: hidden; /* 超出部分隐藏 */
          text-overflow: ellipsis; /* 显示省略号 */
          text-align: left;
        }

        .content-element-review-time {
          color: #999999;
          font-size: 14px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          span{
            font-size: 18px;
            font-family: Bahnschrift, Bahnschrift;
            font-weight: 400;
            color: #333333;
          }
        }
      }

      .content-element-final {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 44px;
        width: 70px;
        padding: 8px 16px;
        background-color: #F7F8FA;
        border-radius: 4px;

        .content-element-final-count {
          font-size: 18px;
          font-family: Bahnschrift, Bahnschrift;
          font-weight: 400;
          color: #0E8BFF;
        }

        .content-element-final-text {
          font-size: 14px;
          font-family: Bahnschrift, Bahnschrift;
          font-weight: 400;
          color: #999999;
        }
      }

      .content-element-arrows {
        // position: absolute;
        // right: 16px;
        margin-left: 8px;
        img {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
  
  .content-height{
    height: calc(100vh - 50px);
  }

  footer{
    div{
      width: 95%;
      height: 44px;
      background: #3975C6;
      border-radius: 4px 4px 4px 4px;
      margin: 12px auto 30px;
      font-size: 18px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 44px;
      
    }
  }

}
</style>