<template>
    <div class="finishUpProject-style">
        <headerNav title="分包单详情" leftNav="返回" @leftClick="cancel"></headerNav>
        <div class="data-null" v-if="showNull">
            <img :src="require('@/assets/subNull.png')" alt="" width="50%">
        </div>
        <div v-else>
            <div :class="routerName == '深化'?'content-style2':'content-style'" >
                <div class="content-header">
                    <div class="content-header-top">
                        <div class="left">{{ detailInfo.consumerName }} </div>
                        <div class="right" @click="callPhone(detailInfo.phoneNo)">
                            <img :src="require('@/assets/phoneNo.png')" alt="" width="16" height="16">
                            <span style="margin-left: 8px;"> {{ detailInfo.phoneNo }} </span>
                        </div>
                    </div>
                    <div class="content-header-bottom">
                        <van-icon name="location" color="#F7B500" />
                        <span style="margin-left: 16px;"> {{ detailInfo.address }} </span>
                    </div>
                </div>
                <div class="content-center">
                    <div class="content-center-base">
                        <van-collapse v-model="activeName" accordion>
                            <van-collapse-item title="基本信息" name="1">
                                <van-field :label="item.title" :value="item.value" input-align="right" readonly
                                    v-for="item in baseList" :key="item.title" :border="false" />
                            </van-collapse-item>
                        </van-collapse>
                    </div>
                    <DetailCollapse1 :list="projectList1" :activeNames.sync="activeNames1" v-if="projectList1.length"
                        @changeActive="onChangeActiveNames" @changeList="onChangeList" />
                    <van-divider dashed :style="{ borderColor: '#979797' }" />
                    <DetailCollapse :list="projectList2" :activeNames.sync="activeNames2" v-if="projectList2.length" />
                </div>
            </div>
            <div class="bottom_up" v-if="routerName == '深化' && (detailInfo.divideStatus == '待上传' || detailInfo.divideStatus == '待验收')">
                <van-button type="info" class="btn" @click="handleChecked(detailInfo)">{{ detailInfo.divideStatus }}</van-button>
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/api/api.js'
import DetailCollapse from './detailCollapse'
import DetailCollapse1 from './detailCollapse1'
export default {
    components: {
        DetailCollapse, DetailCollapse1
    },
    data() {
        return {
            detailInfo: {}, // 用户详情
            baseList: [
                { title: '分包单编号:', value: '' },
                { title: '合同号:', value: '' },
                { title: '创建日期:', value: '' },
                { title: '深化:', value: '' },
                { title: '项目经理:', value: '' },
            ], // 筛选
            activeName: '', // 展开
            projectList1: [], // 未读
            projectList2: [], // 已读
            activeNames1: [], // 展开
            activeNames2: [], // 展开
            showNull: true,
            // 区分是深化还是工程
            routerName:''
        }
    },
    computed: {
        id() {
            const clientInfo = JSON.parse(sessionStorage.getItem('clientInfo1'))
            return clientInfo.id
        }
    },
    methods: {
        // 调用拨号功能
        callPhone(phoneNo) {
            if (!phoneNo) return;
            window.location.href = "tel://" + phoneNo;
        },
        cancel() {
            this.$router.back()
            // this.$router.push({ name: "engineeringConstructionName" })
        },
        async onGetDetail() {
            let res = await api.getSubpackageDetail(this.id)
            if (res.code == 200) {
                this.baseList[0].value = res.data.documentNo
                this.baseList[1].value = res.data.contractNo
                this.baseList[2].value = res.data.belowData
                this.baseList[3].value = res.data.deepenName
                this.baseList[4].value = res.data.projectName
                this.projectList1 = res.data.sellOrderItemTDTOList0 || []
                this.projectList2 = res.data.sellOrderItemTDTOList1 || []
                this.detailInfo = res.data
                this.showNull = false
            } else {
                this.showNull = true
            }
        },
        onChangeActiveNames(value) {
            this.activeNames1 = value
        },
        async onChangeList(Index) {
            const item = this.projectList1.splice(Index, 1)[0]
            this.projectList2.unshift(item)
            await api.editSubpackageDetailIsLock(item.id)
            await this.onGetDetail()
        },
        // 待上传或者待验收跳转页面
        handleChecked(row){
            // sessionStorage.setItem("clientInfo1", JSON.stringify(row));
            // sessionStorage.setItem("fromRouterNameEngineering", "engineeringConstructionName");
            console.log(row)
            if(row.divideStatus == '待上传'){
                this.$router.push({ name: "ToBeUploaded" });
            }else{
                this.$router.push({ name: "engineeringSubpackageDetailView",query:{routerName:'深化'} });
            }
        }
    },
    created() {
        this.routerName = this.$route.query.routerName
        this.onGetDetail()
        console.log(this.$route.query)
    },
}
</script>

<style scoped lang="scss">
.finishUpProject-style {
    .content-style {
        height: calc(100vh - 70px);
        overflow: auto;
        padding-bottom: 20px;
    }
    .content-style2 {
        height: calc(100vh - 170px);
        overflow: auto;
        padding-bottom: 20px;
    }
    .bottom_up{
        height: 100px;
        background: #fff;
        .btn{
            background: #4871C0;
            color: #FFFFFF;
            font-weight: bold;
            font-size: 16px;
            border-radius: 10px;
            width: 311px;
            height: 58px;
            line-height: 58px;
            margin: 10px 0px;
        }
    }
    .data-null {
        height: calc(100vh - 70px);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .content-header {
        padding: 16px 24px;

        .content-header-top {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left {
                font-size: 20px;
            }

            .right {
                height: 100%;
                display: flex;
                align-items: center;
                color: #1677FF;
            }
        }

        .content-header-bottom {
            margin-top: 16px;
            display: flex;
            align-items: center;
            font-size: 16px;
            text-align: left;
        }
    }

    .content-center {
        padding: 0 8px;
        text-align: left;

        .content-center-base {
            border-radius: 10px;
            overflow: hidden;

            ::v-deep .van-field__label {
                color: #999999;
                font-size: 14px;
            }

            ::v-deep .van-cell__value {
                font-size: 15px;
            }

            ::v-deep .van-collapse-item__content {
                padding: 0 !important;
            }
        }

        .content-center-list {
            margin-top: 16px;
        }
    }


}
</style>
