<template>
  <div class="main">
    <headerNav leftNav=" " :title="'审批'" @leftClick="back" />
    <main>
      <div class="content" ref="scrollContent" v-if="listData.length > 0">
        <div class="content-element" v-for="(item,index) in listData" :key="index" >
          <!-- 日期 -->
          <div class="content-element-date">{{ item.createDate2 }}</div>
          <!-- 审批内容 -->
          <div class="content-element-main" @click="onClickDetails(item)">
            <div class="content-element-main-top">
              <div class="content-element-main-top-left-array" v-for="(item1,index1) in typeList" :key="index1" v-if="item.termId == item1.type">
                <div class="content-element-main-top-left" :style="`color:${item1.color};background:${item1.color}20`">{{ item.termName }}</div>
              </div>
              <div class="content-element-main-top-center">任务成果审核</div>
              <div v-if="item.finalStatus == '0'" class="content-element-main-top-right content-element-main-top-right-orange">待审核</div>
              <div v-if="item.finalStatus == '1'" class="content-element-main-top-right content-element-main-top-right-green">已通过</div>
              <div v-if="item.finalStatus == '2'" class="content-element-main-top-right content-element-main-top-right-red">已驳回</div>
              <div v-if="item.finalStatus == '3'" class="content-element-main-top-right content-element-main-top-right-gay">已失效</div>
            </div>
            <div class="content-element-main-center">
              提交人：<span>{{ item.userName }}</span>
            </div>
            <div class="content-element-main-bottom">
              <div>查看详情</div>
              <img src="@/assets/task-management/task-setup-finance-arrows.png" alt="">
            </div>
          </div>
        </div>
      </div>
      <!-- 暂无任务 -->
      <div class="no-data" v-if="isData && listData.length <= 0">
        <img src="@/assets/task-management/task-setup-nodata2.png" alt="">
        <div>暂无任务</div>
      </div>
    </main>
  </div>
</template>

<script>
import api from "@/api/task-management/human-resources.js";
import { Toast,ImagePreview } from "vant";
import moment from "moment";

export default {
  data () {
    return {
      typeList:[
        { name:'招聘试岗',type:'invite_job', color:'#FF8C21',},
        { name:'薪资提交',type:'pay_submit', color:'#17CA5E',},
        { name:'新员工培训',type:'employee_training', color:'#0E8BFF',},
        { name:'费用报销',type:'cost_payment', color:'#FF6157',},
        { name:'行政大巡检',type:'admin_inspection', color:'#CC6AFB',},
        { name:'宿舍巡检',type:'dorm_inspection', color:'#CC6AFB',},
      ],
      listData:[],
      isData:false,
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    back () {
      this.$router.go(-1)
    },
    // 查询表格数据
    async getData () {
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      let params = {
        "type": "2", //1=看板；2=任务中心
        "Q_reportData_EQ": moment().format("YYYY-MM"),
        "taxonomy":"4" //4=人事，7=产品
      }
      api.getApproveList(params).then((res)=>{
        if (res.code == 200) {
          Toast.clear()
          this.listData = res.data.approveList
          this.$nextTick(()=>{
            let element = this.$refs.scrollContent
            let scrollTop = sessionStorage.getItem("EXAMINE_LIST_SCROLL_TOP")
            element.scrollTop = scrollTop || element.scrollHeight
          })
        }else{
          Toast.fail(res.msg)
        }
      }).finally(()=>{
        this.isData = this.listData.length > 0?false:true
      })
    },
    // 查看审批详情
    onClickDetails(item){
      let scrollTop = this.$refs.scrollContent.scrollTop
      sessionStorage.setItem("EXAMINE_LIST_SCROLL_TOP",scrollTop)
      this.$router.push({ 
        name: 'TaskBoardHumanResourcesExamineDetail',
        query: {
          item:JSON.stringify(item),
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.main::v-deep {
  background: #F5F5F5;
  .content {
    height: calc(100vh - 61px);
    overflow: auto;
    padding-top: 10px;

    .content-element {
      .content-element-date {
        font-size: 13px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #8C8C8C;
        margin: 7px 0px;
      }

      .content-element-main {
        background: #FFFFFF;
        border-radius: 8px;
        padding: 18px 12px 8px;
        margin-bottom: 16px;

        .content-element-main-top {
          display: flex;
          align-items: center;
          width: 100%;
          .content-element-main-top-left-array{
            max-width: 50%;
            margin-right: 4px;
          }
          .content-element-main-top-left {
            height: 24px;
            line-height: 24px;
            background: rgba(14, 139, 255, 0.1);
            border-radius: 4px;
            color: #0E8BFF;
            font-size: 16px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            padding: 0 4px;
            width: 100%;
            box-sizing: border-box;
            white-space: nowrap; /* 不换行 */
            overflow: hidden; /* 超出部分隐藏 */
            text-overflow: ellipsis; /* 显示省略号 */
          }

          .content-element-main-top-center {
            font-size: 18px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            color: #1D2129;
          }

          .content-element-main-top-right {
            width: 60px;
            height: 24px;
            line-height: 25px;
            border-radius: 4px;
            margin-left: auto;
            font-size: 15px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            color: #FFF;
          }

          .content-element-main-top-right-green {
            background: #17CA5E;
          }

          .content-element-main-top-right-red {
            background: #FF6157;
          }

          .content-element-main-top-right-orange {
            background: #FF8C21;
          }

          .content-element-main-top-right-gay {
            background: #999999;
          }
        }
        .content-element-main-center {
            font-size: 16px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #545454;
            text-align: left;
            margin: 14px 0px;
            span{
              color: #1D2129;
            }
          }
          .content-element-main-bottom{
            border-top: 1px solid #EBEDF0;
            display: flex;
            justify-content: space-between;
            font-size: 16px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #545454;
            padding-top: 10px;
            img{
              width: 16px;
              height: 16px;
            }
          }
      }

    }
  }

  .no-data {
    height: calc(100vh - 51px);
    padding-top: 140px;
    box-sizing: border-box;

    img {
      width: 160px;
      height: 150px;
    }

    div {
      font-size: 16px;
      font-family: Bahnschrift, Bahnschrift;
      font-weight: 400;
      color: rgba(51, 51, 51, 0.5);
    }
  }
}</style>