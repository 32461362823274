<template>
    <div class="main">
        <van-sticky>
            <headerNav title="业务统计分类" class="nav nav_b" />
        </van-sticky>
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
            <div class="cont_list">
                <van-cell is-link v-for="(item, index) in dataType" :icon="item.icon" :title="item.title"
                    @click="handleClick(item)" :key="index" />
            </div>
        </van-pull-refresh>

    </div>
</template>

<script>
import { Toast } from 'vant';
export default {

    data () {
        return {
            isLoading: false,
            dataType: [
                { id: 1, title: '到店统计', icon: 'star' },
                { id: 2, title: '跟单统计', icon: 'todo-list' },
                { id: 3, title: '到访统计', icon: 'balance-list' },
                { id: 4, title: '签单统计', icon: 'gold-coin' },
                { id: 5, title: '汇总', icon: 'invitation' },
                { id: 6, title: '公司员工结构数据', icon: 'friends' },
                { id: 7, title: '销售统计图', icon: 'balance-list' },
            ],
            show: false,
            actions: [{ id: 1, name: '话务' }, { id: 2, name: '签单' }, { id: 3, name: '客服' }],
        }
    },
    methods: {
        // 下拉刷新
        onRefresh () {
            setTimeout(() => {
                Toast('刷新成功');
                this.isLoading = false;
            }, 1000);
        },
        handleClick (row) {
            if (row.id == 6) {
                this.$router.push({
                    name: 'PersonLineChart',
                    query: {
                        row: JSON.stringify(row)
                    }
                })
            } else if (row.id == 7) {
                this.$router.push({
                    name: 'MarketSales',
                    query: {
                        row: JSON.stringify(row)
                    }
                })
            }
            else {
                this.$router.push({
                    name: 'CompentStatisticsIndex',
                    query: {
                        row: JSON.stringify(row)
                    }
                })
            }
        },
        onCancel () {
            this.show = false
        },
    }
}
</script>

<style lang="scss" scoped>
.nav_b {
    background: #0B0B67;
}
.main {
    box-sizing: border-box;
    background-color: #ffffff;
    height: 100%;

    .cont_list {
        // margin-top: 10px;
        height: 100vh
    }

}

.van-cell::v-deep {
    justify-content: space-between;

    .van-cell__title {
        flex: none;
    }

    .van-cell__right-icon {
        margin-left: auto;
    }
}
</style>