<template>
	<div class="projectplan-style">
		<headerNav title="工程进度" leftNav="返回" @leftClick="back"></headerNav>
		<div class="content-style">
			<pullRefresh ref="pullRefresh" :isLoading="isLoading" @refresh="onRefresh">
				<van-list class="vant-list-style" v-model="loading" :finished="finished"
					:finished-text="total?'·已加载全部数据·':'·暂无数据·'" @load="onLoad">
					<div class="item-style" v-for="(item,index) in list" @click="checkProjectPlan(item)">
						<van-tag v-if="item.processStatus=='1'" class="label-style-dark-color" color="#40D673 "
							type="primary">已完成</van-tag>
						<van-tag v-else class="label-style-dark-color" color="#AAAAAA " type="primary">未完成</van-tag>
						<div class="item-title">{{item.nodeName}}</div>
						<img src="@/assets/xzjt.png" alt="" class="item-img-style" v-if="item.processStatus=='1'">
						<div src="@/assets/xzjt.png" alt="" class="item-up-style" v-else>上传</div>
					</div>
				</van-list>
			</pullRefresh>
		</div>
	</div>
</template>

<script>
	import api from '@/api/api.js'
	export default {
		data() {
			return {
				list: [],
				isLoading: false,
				loading: false,
				finished: false,
				info:{},
				error: false, // 是否加载失败
				pageNum: 1, // 分页
				pageSize: 10, // 每页条数
				total: 0 // 数据总条数
			}
		},
		methods: {
			back() {
                sessionStorage.setItem('clientInfo', JSON.stringify(this.info))
				if (sessionStorage.getItem('fromRouterNameEngineering')) {
					this.$router.push({
						name: sessionStorage.getItem('fromRouterNameEngineering'),
					})
				} else {
					this.$router.push({
						name: "engineeringClicntDetailName",
					})
				}
				// if (this.$route.query.isBack) {
				// 	this.$router.push({
				// 		name: "engineeringConstructionName",
				// 		query: this.info
				// 	})
				// } else {
				// 	this.$router.push({
				// 		name: "engineeringClicntDetailName",
				// 		query: this.info
				// 	})
				// }
			},
			//获取列表
			async getList() {
				let obj = {
					Q_projectId_EQ: this.info.id
				}
				let res = await api.getProjectPlanList(obj)
				if (res.code == 200) {
					this.total = res.data.length;
					if (res.data.length === 0) { // 判断获取数据条数若等于0
						this.list = []; // 清空数组
						this.finished = true; // 停止加载
					} else {
						// 若数据条数不等于0
						this.list.push(...res.data) // 将数据放入list中
						// this.loading = false; // 加载状态结束 
						// 如果list长度大于等于总数据条数,数据全部加载完成
						if (this.list.length >= res.data.length) {
							this.finished = true; // 结束加载状态
						}
					}
					if(this.$refs.pullRefresh){
						this.$refs.pullRefresh.refreshSuccess()
					}
				}
			},
			// 被 @load调用的方法
			onLoad() { // 若加载条到了底部
				let timer = setTimeout(() => { // 定时器仅针对本地数据渲染动画效果,项目中axios请求不需要定时器
					// this.getList(); // 调用上面方法,请求数据
					this.getList()
					this.pageNum++; // 分页数加一
					this.finished && clearTimeout(timer); //清除计时器
				}, 100);
			},
			// 加载失败调用方法
			onRefresh() {
				this.finished = false; // 清空列表数据
				this.loading = true; // 将 loading 设置为 true，表示处于加载状态
				this.pageNum = 1; // 分页数赋值为1
				this.list = []; // 清空数组
				this.onLoad(); // 重新加载数据
			},
			//查看工程进度
			checkProjectPlan(item) {
				sessionStorage.setItem('clientInfo',JSON.stringify(this.info))
				sessionStorage.setItem('projectPlanInfo',JSON.stringify(item))
				if (item.processStatus=='1') {
					//查看工程进度详情
					this.$router.push({
						name: "engineeringCheckProjectPlanName",
                        query: {
                            typeCode: item.nodeCode
                        }
					})
				} else {
					//上传工程进度完成图片
					this.$router.push({
						name: "engineeringFinishUpProjectPlanName",
                        query:{
                            typeCode:item.nodeCode
                        }
					})
				}
			},

		},
		mounted() {
			this.info = JSON.parse(sessionStorage.getItem('clientInfo'))
		},
	}
</script>

<style scoped lang="scss">
	.projectplan-style {
		.content-style {
			height: calc(100vh - 60px);
			overflow: auto;
			margin-top: 10px;
			background: #FFFFFF;

			.item-style {
				display: flex;
				margin-left: 18px;
				height: 44px;
				align-items: center;
				border-bottom: 1px solid #E6E6E6;
				position: relative;

				.label-style-dark-color {
					transform: scale(.9);
				}

				.item-title {
					margin-left: 12px;
					font-size: 15px;
					font-weight: 400;
					color: #333333;
				}

				.item-img-style {
					position: absolute;
					right: 11px;
					width: 22px;
					height: 22px;
				}

				.item-up-style {
					position: absolute;
					right: 18px;
					font-size: 15px;
					font-weight: 400;
					color: #4F7AFD;
				}
			}
		}
	}
</style>
