<template>
  <div class="main">
    <van-sticky class="topMenu">
      <headerNav leftNav=" " 
      :title="'任务管理中心'" 
      @leftClick="back" 
      @rightClick="onClickExamine" 
      :rightNav="'审批'" 
      :rightIcon="'task-setup-finance-check'" 
      :hintData="tasksNum"
      :showRightContent="roleInfo.roleCode != 'ROLE_271913'"></headerNav>
    </van-sticky>

    <main>
      <van-tabs v-model="active" color="#3DA2FF" title-active-color="#3DA2FF" title-inactive-color="#333333" @change="onClickNavTab">
        <van-tab title="本月任务" name="1">
          <div class="tab-nav" v-if="taskTarget.length">
            <div class="tab-nav-main">
              <div :class="activeTarget.monthActive == item.value?'active':''" v-for="(item, index) in taskTarget" :key="index" @click="onClickTaskTarget(item,'monthActive')">{{ item.label }}</div>
            </div>
          </div>
          <div class="content" :class="{ 'content-btn': params.taskType === 'site_penalty','padding-top':!taskTarget.length }" v-if="monthTask.length" ref="scrollArea1" @scroll="handleScroll">
            <!-- 预审总监 -->
            <div v-if="roleInfo.roleCode == 'ROLE_097074'">
              <MajordomoItem v-for="(item, index) in monthTask" :key="index" :item="item" :targetType="activeTarget.monthActive" @onClick="onClickOverViewDetails(item)" />
            </div>
            <!-- 预审经理 -->
            <div v-else>
              <ManagerItem v-for="(item, index) in monthTask" :key="index" :item="item" :targetType="activeTarget.monthActive" @onClick="onClickOverViewDetails(item)" />
            </div>
          </div>
          <!-- 暂无数据 -->
          <div class="no-data" v-if="!isData && !monthTask.length">
            <img src="@/assets/task-management/task-setup-nodata.png" alt />
            <div>暂无任务</div>
          </div>
        </van-tab>
        <van-tab title="计划任务" name="2">
          <div class="tab-nav" v-if="taskTarget.length">
            <div class="tab-nav-main">
              <div :class="activeTarget.planAcitve == item.value?'active':''" v-for="(item, index) in taskTarget" :key="index" @click="onClickTaskTarget(item,'planAcitve')">{{ item.label }}</div>
            </div>
          </div>
          <div class="content" :class="{ 'content-btn': params.taskType === 'site_penalty','padding-top':!taskTarget.length }" v-if="monthTask.length" ref="scrollArea2" @scroll="handleScroll">
            <!-- 预审总监 -->
            <div v-if="roleInfo.roleCode == 'ROLE_097074'">
              <MajordomoItem v-for="(item, index) in monthTask" :key="index" :item="item" :targetType="activeTarget.planAcitve" @onClick="onClickOverViewDetails(item)" />
            </div>
            <!-- 预审经理 -->
            <div v-else>
              <ManagerItem v-for="(item, index) in monthTask" :key="index" :item="item" :targetType="activeTarget.planAcitve" @onClick="onClickOverViewDetails(item)" />
            </div>
          </div>
          <!-- 暂无任务 -->
          <div class="no-data"  v-if="!isData && !monthTask.length">
            <img src="@/assets/task-management/task-setup-nodata.png" alt />
            <div>暂无任务</div>
          </div>
        </van-tab>
        <van-tab title="历史任务" name="3">
          <div class="tab-nav" v-if="taskTarget.length">
            <div class="tab-nav-main">
              <div :class="activeTarget.historyActive == item.value?'active':''" v-for="(item, index) in taskTarget" :key="index" @click="onClickTaskTarget(item,'historyActive')">{{ item.label }}
              </div>
            </div>
          </div>
          <div class="content" v-if="monthTask.length" :class="{'padding-top':!taskTarget.length}" ref="scrollArea3" @scroll="handleScroll">
            <!-- 预审总监 -->
            <div v-if="roleInfo.roleCode == 'ROLE_097074'">
              <MajordomoItem v-for="(item, index) in monthTask" :key="index" :item="item" :targetType="activeTarget.historyActive" @onClick="onClickOverViewDetails(item)" />
            </div>
            <!-- 预审经理 -->
            <div v-else>
              <ManagerItem v-for="(item, index) in monthTask" :key="index" :item="item" :targetType="activeTarget.historyActive" @onClick="onClickOverViewDetails(item)" />
            </div>
          </div>
          <!-- 暂无任务 -->
          <div class="no-data"  v-if="!isData && !monthTask.length">
            <img src="@/assets/task-management/task-setup-nodata.png" alt />
            <div>暂无任务</div>
          </div>
        </van-tab>
      </van-tabs>

        <!-- 添加 -->
        <div class="footer-add" v-if="params.taskType == 'site_penalty' && params.monthType != '3'">
          <van-button class="btn" type="info" @click="addTask">添加</van-button>
        </div>
    </main>
  </div>
</template>

<script>
import MajordomoItem from "./component/majordomo-item.vue";
import ManagerItem from "./component/manager-item.vue";
import api from "@/api/task-management/inquiry.js";
import api2 from "@/api/task-management/finance.js";
import { Toast } from "vant";

export default {
  components: {
    MajordomoItem,
    ManagerItem
  },
  data() {
    return {
      active: 1,
      // 指标项筛选默认值
      activeTarget: {
        monthActive: "", // 总监
        planAcitve: "",
        historyActive: ""
      },
      // 待审批任务数量
      tasksNum: 0,
      // 列表数据
      monthTask: [],
      // 顶部筛选
      taskTarget: [],

      // 角色权限
      roleInfo: {},
      // 列表查询接口
      params: {
        monthType: null,
        taskType: '',
        page: 1,
        limit: 30
      },
      datalist:[],
      isLoading: false, // 是否正在加载数据
      isData:true
    };
  },
  created() {
    // this.monthTask = []
    localStorage.removeItem('INQUIRY_TASK_DETAILS');
    // 获取角色权限
    let role = JSON.parse(localStorage.getItem("USER_ROLE"));
    this.getTermSlug();
    this.searchCheckNum()
    this.roleInfo = role;
    this.params.monthType = this.active;
  },
  mounted() {
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    // 查询指标列表项
    async getTermSlug() {
      const { data } = await api.getTermSlugApi();
      const arr = [];
      for (let key in data) {
        arr.push({ label: data[key], value: key });
      }
      // 获取筛选条件
      let fliter = JSON.parse(localStorage.getItem('TAB_ACTIVE_OBJ'))
      // 判断等于预审总监
      if(fliter && fliter.monthType == 2 && this.roleInfo.roleCode == 'ROLE_097074'){
        let record = arr.find(item => item.value == 'site_penalty')
        this.taskTarget= [record]
      }else{
        this.taskTarget = arr;
      }
      if (fliter) {
        this.updateFliterData(fliter)
      } else{
        // 判断预审专员默认查个人
        if(this.roleInfo.roleCode == 'ROLE_271913') {
          console.log('进行了');
           this.params.taskType = 1
        }else{
            this.activeTarget.monthActive = this.taskTarget[0].value;
            this.activeTarget.planAcitve = this.taskTarget[0].value;
            this.activeTarget.historyActive = this.taskTarget[0].value;
            this.params.taskType = this.taskTarget[0].value;
        }
      }

      await this.getTaskListData();
    },
    // 查询本月历史任务列表
    async getTaskListData() {
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      try {
        const { data } = await api.getTaskListApi(this.params);
        this.datalist = data
        if(this.params.monthType == '3'){
          this.monthTask.push(...data)
        }else{
        this.monthTask = data
        }
        this.monthTask.map(item =>{
          if(item.termId == 'site_routing_inspection'){
            item.reality = item.reality && item.reality =='-'?'-': Number(item.reality).toFixed(0)
            item.task = item.task && item.task =='-'?'-':Number(item.task).toFixed(0)
          }
        })
      } finally {
        Toast.clear()
        this.isData = this.monthTask.length
      }
    },
    // 查询审批数量
    async searchCheckNum() {
      const { data } = await api2.checkTaskNumberApi(6);
      this.tasksNum = data;
    },
    // 更新筛选条件
    updateFliterData(data) {
      this.activeTarget = {
        monthActive: data.monthActive, // 总监
        planAcitve: data.planAcitve,
        historyActive: data.historyActive
      }
      let obj = {
        '1':'monthActive',
        '2':'planAcitve',
        '3':'historyActive'
      }
      this.active = data.monthType
      this.params.taskType = data[obj[data.monthType]] || 1
      // 专员默认1
      if(this.roleInfo.roleCode == 'ROLE_271913'){
        this.params.taskType = 1
      }
      this.params.monthType = data.monthType

    },
    // 下拉 加载更多
    async handleScroll(e) {
      const el = e.target;
      if (el.scrollTop + el.clientHeight + 5 >= el.scrollHeight &&!this.isLoading && this.params.monthType == '3' && this.params.limit == this.datalist.length) {
        this.params.page++; // 加载下一页数据
        this.loading = true;
        await this.getTaskListData();
        this.loading = false;
      }
    },
    // 切换本月历史
    onClickNavTab(e) {
      this.active = e
      this.params.monthType = e;
      // 判断是计划任务只显示工地罚金  预审总监
      if(this.roleInfo.roleCode == 'ROLE_097074'){
        if(e == 2){
           let record = this.taskTarget.find(item => item.value == 'site_penalty')
            this.taskTarget= [record]
            this.params.taskType = this.taskTarget[0].value;
            this.activeTarget.planAcitve = this.taskTarget[0].value;
            this.getTaskListData()
          }else{
          this.getTermSlug()
        }
      }else{
        this.getTermSlug()
      }
      this.resetParams(e)
      this.setFliter()
    },
    // 点击顶部指标项
    onClickTaskTarget(item, type) {
      this.activeTarget[type] = item.value;
      this.params.taskType = this.activeTarget[type]
      this.setFliter()
      this.resetParams()
      this.getTaskListData()
    },
    // 存放筛选条件
    setFliter(){
      // 存放筛选数据
      localStorage.setItem('TAB_ACTIVE_OBJ', JSON.stringify({ ...this.params, ...this.activeTarget }))
    },
    // 重置查询条件
    resetParams(e){
      this.params.page = 1
      this.monthTask = []
    },
    // 点击综述详情
    onClickOverViewDetails(item) {
      this.$forceUpdate();
      item.city = this.params.taskType
      item.monthType = this.active
      localStorage.setItem('INQUIRY_TASK_DETAILS', JSON.stringify(item))
      this.$router.push({
        path: "/task-management/setup/inquiry/details/task-designing",
      });
    },
    // 点击审批
    onClickExamine() {
      this.$router.push({
        path: "/task-management/setup/inquiry/details/examine-list"
      });
    },
    // 添加工地罚金任务
    addTask(){
      this.$router.push({path:'/task-management/setup/inquiry/details/add-sitePenalty'})
    }
  }
};
</script>

<style lang="scss" scoped>
.main::v-deep {
  height: 100vh;
  overflow: hidden;
  .footer-add {
    height: 66px;
    line-height: 50px;
    width: 100%;
    background: #fff;
    box-shadow: 0px -1px 8px 0px rgba(0, 0, 0, 0.04);
    text-align: center;
    position: fixed;
    bottom: 0px;
    left: 0px;
    padding-bottom: 10px;

    .btn {
      background: #3975c6;
      color: #ffffff;
      border-radius: 4px;
      font-size: 16px;
      font-weight: 500;
      width: 340px;
    }
  }
  .van-tab {
    font-size: 17px;
    flex: none;
    width: 25%;
  }

  .van-tab--active {
    font-size: 18px;
  }

  .no-data {
    margin: 150px auto;
    height: 100%;
    width: 200px;
    img {
      width: 210px;
      height: 137px;
    }

    div {
      font-size: 16px;
      font-family: Bahnschrift, Bahnschrift;
      font-weight: 400;
      color: rgba(51, 51, 51, 0.5);
    }
  }

  .tab-nav {
    padding: 9px 0px;
    background: #f5f5f5;
    overflow: auto;
    /* 普通浏览器 */
    &::-webkit-scrollbar {
      height: 0px;
    }
    /* 苹果浏览器 */
    &::-ms-scrollbar {
      height: 0px;
    }
    .tab-nav-main {
      width: 500px;
      overflow: auto;
      display: flex;
      .active {
        color: #3da2ff;
      }
      div {
        width: 84px;
        height: 32px;
        line-height: 32px;
        background-color: #ffffff;
        border-radius: 4px;
        color: #545454;
        font-size: 15px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        margin: 0px 6px;
      }
    }
  }

  .content {
    height: calc(100vh - 140px);
    background: #f5f5f5;
    overflow: auto;
  }
  .padding-top{
    padding-top: 16px;
  }
  .content-btn{
    height: calc(100vh - 200px);
  }
}
</style>