<template>
    <div>
        <van-sticky>
            <headerNav class="nav_b" leftNav=" " @leftClick="back" :title="routerRow.title+'--订单数据'" />
        </van-sticky>
        <div class="cont">
            <div class="top">
                <div>
                    <van-circle v-model="currentRate" size="120px" :rate="64" :speed="100" color="#894FC7"
                        :stroke-width="100" :text="text" layer-color="#F2F2F2" />
                </div>
                <div>
                    <p>本月累计销售总额（元）</p>
                    <animate-number from="0.00" :to="moneyNum" duration="2000" from-color="#44CC00" to-color="#ec4949"
                        style="font-size:30px;text-align:center;display:block;"></animate-number>
                </div>
            </div>
            <!-- 底部滚动 -->
            <div class="roll">
                <div class="div_c" v-for="item in moneyData" :key="item.id">
                    <p>{{ item.name }}</p>
                    <div class="sum_div">
                        <animate-number from="0.00" :to="item.money" duration="2000"
                            style="font-size:25px;text-align:center;display:block;"></animate-number>
                        <div class="span">
                            <span>{{ '+' + item.percentage }}</span>
                            <img src="../../../assets/u1389.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <!-- 月销售额折线图 -->
            <div class="echartMain"></div>
            <!-- 材料销售排行 -->
            <div class="echartMaterials eachartsSize"></div>
            <!-- 销售商品排行 -->
            <div class="shopRanking"></div>
        </div>
    </div>
</template>

<script>
import * as echarts from 'echarts';
export default {

    data () {
        return {
            routerRow: {},
            dataSource: [
                ['product', '2018', '2019', '2020', '2021', '2022'],
            ],
            currentRate: 0,
            moneyNum: 16378.50,
            moneyData: [
                {
                    id: 1,
                    name: '总销售额(元)',
                    money: 3456923,
                    percentage: '2.40%',
                },
                {
                    id: 2,
                    name: '本年销售额(元)',
                    money: 3456923,
                    percentage: '2.40%',
                },
                {
                    id: 3,
                    name: '本季销售额(元)',
                    money: 3456923,
                    percentage: '2.40%',
                },
                {
                    id: 4,
                    name: '本月销售额(元)',
                    money: 3456923,
                    percentage: '2.40%',
                },

            ]
        }
    },
    created () {
        this.routerRow = JSON.parse(this.$route.query.row)
    },
    computed: {
        text () {
            return this.currentRate.toFixed(0) + '%';
        },
    },
    mounted () {
        this.$nextTick(() => {
            this.handleMonth()
            this.handleMaterials()
            this.handleShopRanking()
        })
    },
    methods: {
        // 月度销售额
        handleMonth () {
            let option = {
                title: {
                    text: '2022年月度销售额趋势',
                    textStyle: {
                        color: '#fff'
                    }
                },
                tooltip: {
                    trigger: 'axis'
                },
                xAxis: {
                    type: 'category',
                    data: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
                    axisLine: {
                        lineStyle: {
                            color: '#fff'
                        }
                    },
                    axisLabel: {
                        color: '#fff'
                    }
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        color: '#fff'
                    },
                    lineStyle: {
                        normal: {
                            color: '#ffffff',
                            width: 4,
                        }
                    }
                },
                series: [
                    {
                        data: [150, 230, 224, 218, 135, 147, 260, 230, 224, 218, 135, 147],
                        type: 'line',
                        label: {
                            show: true,
                            position: 'bottom',
                            textStyle: {
                                fontSize: 12,
                                color: '#fff'
                            }
                        },
                    }
                ]
            };

            this.handleEcharts('.echartMain', option)
        },
        // 材料各类占比
        handleMaterials () {
            let option = {
                title: {
                    text: '材料销售各类占比',
                    textStyle: {
                        color: '#fff'
                    }
                },
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    top: '10%',
                    bottom: '5%',
                    left: 'center',
                    textStyle: {
                        color: '#fff'
                    }
                },
                series: [
                    {
                        name: '材料种类',
                        type: 'pie',
                        radius: ['40%', '70%'],
                        avoidLabelOverlap: false,
                        itemStyle: {
                            borderRadius: 10,
                            borderColor: '#fff',
                            borderWidth: 1
                        },
                        label: {
                            show: false,
                            position: 'center',
                            color: '#fff'
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: '40',
                                fontWeight: 'bold'
                            }
                        },
                        labelLine: {
                            show: true
                        },
                        data: [
                            { value: 1048, name: '地板' },
                            { value: 735, name: '门窗' },
                            { value: 580, name: '空调' },
                            { value: 484, name: '席梦思' },
                            { value: 300, name: '油烟机' }
                        ]
                    }
                ]
            };
            this.handleEcharts('.echartMaterials', option)
        },
        // 商品销售排行
        handleShopRanking () {
            let option = {
                title: {
                    text: '产品销售排行 TOP.6',
                    textStyle: {
                        color: '#fff'
                    },
                    itemGap: 20
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend: {
                    top: '5%',
                    bottom: '5%',
                    left: 'center',
                    textStyle: {
                        color: '#fff'
                    }
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'value',
                    boundaryGap: [0, 0.01],
                    axisLabel: {
                        color: '#fff'
                    },
                },
                yAxis: {
                    type: 'category',
                    data: ['产品1', '产品2', '产品3', '产品4', '产品5', '产品6'],
                    axisLabel: {
                        color: '#fff'
                    },
                },
                series: [
                    {
                        name: '2021',
                        type: 'bar',
                        data: [1803, 2489, 2934, 10490, 1744, 6330],
                    },
                    {
                        name: '2022',
                        type: 'bar',
                        data: [1925, 2338, 310, 1594, 1141, 6807],
                        itemStyle: {
                            color: "#FD9A31"
                        }
                    }
                ]
            };
            this.handleEcharts('.shopRanking', option)
        },
        // 封装
        handleEcharts (name, option) {
            var chartDom = document.querySelector(name);
            // var chartDom = document.getElementById('main');
            var myChart = echarts.init(chartDom, 'night');
            option && myChart.setOption(option);
        },
        //回退
        back () {
            this.$router.push({
                name: "statistics",
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.nav_b {
    background: #0B0B67;
}

.cont {
    background: #0B0B67;
    height: 100%;
    padding: 10px;

    .top {
        width: 353px;
        height: 146px;
        background-color: rgba(255, 255, 255, 1);
        border-radius: 5px;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    .roll {
        margin-top: 20px;
        width: 100%;
        overflow-x: auto;
        display: flex;
        // justify-content: space-around;
        // padding-left: 10px;
        -webkit-scrollbar: none;
        padding: 10px 0px 7px 0px;

        .div_c {
            background: #EDF3FF;
            width: 182px;
            height: 70px;
            border-radius: 5px;
            padding: 10px 8px;
            margin: 0px 10px;

            p {
                font-size: 14px;
                text-align: left;
                margin-top: 5px;
            }
        }


        .sum_div {
            display: flex;

            .span {
                margin-left: 20px;
                display: flex;

                span {
                    margin-right: 5px;
                    font-size: 13px;
                    color: #0EA76E;
                }

                img {
                    width: 20px;
                    height: 15px;
                }

            }

            // justify-content: space-around;
        }

    }

    .eachartsSize {
        width: 100%;
        height: 400px;
        margin-top: 20px;
    }

    // 图表
    .echartMain {
        width: 100%;
        height: 400px;
        margin-top: 20px;
    }
    // 排行统计
    .shopRanking{
        width: 100%;
        height: 400px;
        margin-top: 20px;
    }
}
</style>