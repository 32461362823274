let taskOptions = [
  // =============================预审===============================
  {
    navTitle:'预估利润详情',
    collect1: '达标市场',
    collect2: '未达标市场',
    collect3: '平均利润率',
    unit: '个',
    tooltip: "此为利润率最低标准",
    expandName: '上月利润率',
    expandIcon: 'task-board-finance-table-expant-icon4',
    imgUrl:'task-board-inquiry1',
    leftIconName:'task-board-inquiry-dabiao1',
    rightIconName:'task-board-inquiry-dabiao2',
    value: 'estimate_profit',
    table:[
      { label:'实际利润率',props:'reality',align:'right',unit:'%'},
      { label:'任务利润率',props:'task',align:'right',isShowTooltip:true,tooltip: "此为利润率最低标准",unit:'%' },
    ]
  },
  {
    navTitle:'结算利润详情',
    collect1: '达标市场',
    collect2: '未达标市场',
    collect3: '平均利润率',
    unit: '个',
    tooltip: "此为利润率最低标准",
    expandName: '上月利润率',
    expandIcon: 'task-board-finance-table-expant-icon4',
    imgUrl:'task-board-inquiry1',
    leftIconName:'task-board-inquiry-dabiao1',
    rightIconName:'task-board-inquiry-dabiao2',
    value: 'settle_accounts_profit',
    table:[
      { label:'实际利润率',props:'reality',align:'right',unit:'%' },
      { label:'任务利润率',props:'task',align:'right',isShowTooltip:true,tooltip: "此为利润率最低标准",unit:'%' },
    ]
  },
  {
    navTitle:'结转利润详情',
    collect1: '达标市场',
    collect2: '未达标市场',
    collect3: '平均利润率',
    unit: '个',
    tooltip: "此为利润率最低标准",
    expandName: '上月利润率',
    expandIcon: 'task-board-finance-table-expant-icon4',
    imgUrl:'task-board-inquiry1',
    leftIconName:'task-board-inquiry-dabiao1',
    rightIconName:'task-board-inquiry-dabiao2',
    value: 'balance_profit',
    table:[
      { label:'实际利润率',props:'reality',align:'right',unit:'%' },
      { label:'任务利润率',props:'task',align:'right',isShowTooltip:true,tooltip: "此为利润率最低标准",unit:'%' },
    ]
  },
  {
    navTitle:'工地巡检详情',
    collect1: '实际巡检',
    collect2: '应巡检',
    collect3: '完成率',
    unit: '次',
    expandName: '上月完成率',
    expandIcon: 'task-board-finance-table-expant-icon3',
    imgUrl:'task-board-inquiry2',
    leftIconName:'task-board-inquiry-xunjian1',
    rightIconName:'task-board-inquiry-xunjian2',
    value: 'site_routing_inspection',
    table:[
      { label:'实际巡检(次)',props:'reality',align:'right',unit:''},
      { label:'应巡检(次)',props:'task',align:'right',unit:'' },
      { label:'完成率',props:'accomplish',align:'right',unit:'%'},
    ]
  },
  {
    navTitle:'工地罚金详情',
    collect1: '实际完成',
    collect2: '任务罚金',
    collect3: '完成率',
    unit: '元',
    expandName: '上月完成率',
    expandIcon: 'task-board-finance-table-expant-icon3',
    imgUrl:'task-board-inquiry2',
    leftIconName:'task-board-inquiry-fajin1',
    rightIconName:'task-board-inquiry-fajin2',
    value: 'site_penalty',
    table:[
      { label:'实际完成(元)',props:'reality',align:'right',unit:'' },
      { label:'任务罚金(元)',props:'task',align:'right',unit:'' },
      { label:'完成率',props:'accomplish',align:'right',unit:'%' },
    ]
  },
  {
    navTitle:'综述报告详情',
    collect1: '满分完成',
    collect2: '非满分完成',
    collect3: '平均得分',
    collect4: '最终得分',
    unit: '个',
    expandName: '上月最终得分',
    expandIcon: 'task-board-finance-table-expant-icon2',
    imgUrl:'task-board-inquiry3',
    leftIconName:'task-board-inquiry-zongsu1',
    rightIconName:'task-board-inquiry-zongsu2',
    value: 'overview_report',
    table:[
      { label:'错漏处',props:'reality',align:'right',unit:'' },
      { label:'延期提交(次)',props:'task',align:'right',unit:''},
      { label:'最终得分',props:'accomplish',align:'right',isShowTooltip:true,unit:'' },
    ]
  },
]
// 判断指标展示内容
export function estimateOutput (value) {
  let data = {}
  taskOptions.filter(item => {
    if (item.value == value) {
      data = item
    }
  })
  return data
}


// 任务成果展示
let taskResults = [
  { 
    label:'预估利润率',
    value:'estimate_profit',
  },
  { 
    label:'结算利润率',
    value:'settle_accounts_profit',
  },
  { 
    label:'结转利润率',
    value:'balance_profit',
    type:'file', // 任务上传显示的图片还是文件
    typeName1:'实际利润率', // 指标项
    unit:'%', // 指标单位
    isShowTarget:1, // 显示指标
    taskUploadTitle:'任务利润率', // 任务成果上传顶部展示
    formList:[
      { label:'实际利润率', props:'realityProfitRate',pattern:/^[0-9]+(\.[0-9]{1,2})?$/,message:'只能输入正数，最多保留两位小数' }
    ]
  },
  { 
    label:'工地巡检',
    value:'site_routing_inspection',
    type:'image',
    typeName1:'应巡检',
    typeName2:'实际巡检',
    unit:'次',
    isShowTarget:2,
    formList:[
      { label:'应巡检', props:'taskFine',pattern:/^(0|[1-9]\d*)$/,message:'只能输入整数' },
      { label:'实际巡检', props:'realityAccomplish',pattern:/^(0|[1-9]\d*)$/,message:'只能输入整数' },
    ]
  },
  { 
    label:'工地罚金',
    value:'site_penalty',
    type:'image',
    typeName1:'任务罚金',
    typeName2:'实际完成',
    unit:'元',
    isShowTarget:2,
    taskUploadTitle:'任务罚金',
    formList:[
      { label:'实际完成', props:'realityAccomplish',pattern:/^[0-9]+(\.[0-9]{1,2})?$/,message:'只能输入正数，最多保留两位小数' },
    ]
  },
  { 
    label:'综述报告',
    value:'overview_report',
    type:'image',
    isShowToopits:true
  },
]
export function estimateOutputTarget (value) {
  let data = {}
  taskResults.filter(item => {
    if (item.value == value) {
      data = item
    }
  })
  return data
}