<template>
    <div class="main_box">
        <headerNav title="消息详情" @leftClick="back"></headerNav>
        <div class="height_style1">
            <div class="info_main">
                <div class="info_nav">
                    <div class="info_left">{{ customerDetails.consumerName.length > 2 ?
                        customerDetails.consumerName.substring(0, 2) : customerDetails.consumerName }}
                    </div>
                    <div class="info_right">
                        <div class="right_flex">
                            <div class="right_a">{{ customerDetails.consumerName }}</div>
                            <div class="right_b_img">
                                <img src="../../assets/comfime.svg" alt="" class="img" v-if="customerDetails.orderStatusName == '已通过'">
                                <img src="../../assets/comfimeNo.svg" alt="" class="img" v-if="customerDetails.orderStatusName == '未通过'">
                            </div>
                        </div>
                        <div class="right_bottom">
                            <span class="span_back">编号</span>
                            <span class="span_code">{{ customerDetails.orderNo }}</span>
                        </div>
                    </div>
                </div>
                <div class="info_content">
                    <div class="cont_nav">
                        <div class="cont_mian fenge">
                            <div class="cont_main_top">{{ customerDetails.maintainContract }}</div>
                            <div class="cont_main_bottom">合同号</div>
                        </div>
                        <div class="cont_mian">
                            <div class="cont_main_top">{{ customerDetails.typeCodeName }}</div>
                            <div class="cont_main_bottom">单据类型</div>
                        </div>
                    </div>
                    <div class="cont_main" v-if="isSub">
                        <div class="cont_flex">
                            <div class="flex_a">项目经理:</div>
                            <div class="flex_b">{{ customerDetails.projectName }}</div>
                        </div>
                        <div class="cont_flex">
                            <div class="flex_a">深化:</div>
                            <div class="flex_b">{{ customerDetails.deepenName }}</div>
                        </div>
                        <div class="cont_flex">
                            <div class="flex_a">装修地址:</div>
                            <div class="flex_b">{{ customerDetails.detailAddress }}</div>
                        </div>
                        <div class="cont_flex">
                            <div class="flex_a">品牌:</div>
                            <div class="flex_b">{{ customerDetails.brandName }}</div>
                        </div>
                        <div class="cont_flex">
                            <div class="flex_a">供应商:</div>
                            <div class="flex_b">{{ customerDetails.itemName }}</div>
                        </div>
                        <div class="cont_flex">
                            <div class="flex_a">单据备注:</div>
                            <div class="flex_b">{{ customerDetails.remark }}</div>
                        </div>
                        <div class="cont_flex cont_flex_img">
                            <div class="flex_a flex_text">附件信息:</div>
                            <div class="flex_b flex_img">
                                <div v-for="item in customerDetails.itemf">
                                    <van-image class="img" @click="onPreview(item.fileAdd)" :src="item.fileAdd" :width="72" :height="72" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="content_bottom" @click="handleChangeSub">
                        <div class="flex">
                            {{ isSub ? '收起更多' : '查看更多' }}
                            <img :class="isSub ? '' : 'jiantou'" :src="require('@/assets/sqgdpng.png')" alt=""
                                style="width:12px;height:12px;margin-left:4px">
                        </div>
                    </div>
                </div>
            </div>
            <div class="center_count">
                <div class="left">
                    <div class="left_a">合计</div>
                    <div class="left_b">数量/价格</div>
                </div>
                <div class="right">
                    <div class="right_a">{{ quantity }}</div>
                    <div class="right_b">{{ '￥'+ total }}</div>
                </div>
            </div>
            <div class="bottom_table">
                <div class="table_mian">
                    <table>
                        <thead>
                            <tr class="tr_th">
                                <th class="fixed_column" style="width:150px;text-align:left;padding-left:15px">商品名称</th>
                                <th style="width:160px;text-align:left;padding-left:15px">材料编号</th>
                                <th style="width:80px;text-align:center;padding-left:15px">品牌</th>
                                <!-- <th style="width:80px;text-align:center;padding-left:15px">套餐类型</th> -->
                                <th style="width:80px;text-align:right;padding-left:15px">样式图</th>
                                <th style="width:145px;text-align:left;padding-left:15px">规格</th>
                                <th style="width:60px;text-align:left;padding-left:15px">尺寸</th>
                                <th style="width:60px;text-align:left;padding-left:15px">数量</th>
                                <th style="width:60px;text-align:left;padding-left:15px">单位</th>
                                <th style="width:60px;text-align:left;padding-left:15px">单价</th>
                                <th style="width:60px;text-align:left;padding-left:15px">合计价</th>
                                <th style="width:145px;text-align:left;padding-left:15px">备注</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in customerDetails.item" class="main_tr" :key="'rol1-' + index">
                                <!-- 商品名称 -->
                                <td class="fixed_column_td" style="text-align:left;padding-left:15px;font-weight:mediu">{{ item.itemName }}
                                </td>
                                <!-- 材料编号 -->
                                <td style="text-align:left;padding-left:15px">{{ item.itemNo }}</td>
                                <!-- 品牌 -->
                                <td style="text-align:center;padding-left:15px">{{ item.brandName }}</td>
                                <!-- 套餐类型 -->
                                <!-- <td style="text-align:center;padding-left:15px;font-weight:mediu">{{ item.allianceName }}</td> -->
                                <!-- 样式图 -->
                                <td style="text-align:right;padding-left:15px;font-weight:mediu">
                                    <div @click="onPreview(item.imgUrl)">
                                        <img :src="item.imgUrl" :width="56" :height="22" alt="">
                                    </div>
                                </td>
                                <!-- 规格 -->
                                <td style="text-align:left;padding-left:15px">{{ item.modelSpec }}</td>
                                <!-- 尺寸 -->
                                <td style="text-align:left;padding-left:15px">{{ item.itemSize }}</td>
                                <!-- 数量 -->
                                <td style="text-align:left;padding-left:15px">{{ item.amount }}</td>
                                <!-- 单位 -->
                                <td style="text-align:left;padding-left:15px">{{ item.unitName }}</td>
                                <!-- 单价 -->
                                <td style="text-align:left;padding-left:15px">{{ item.sellingPrice }}</td>
                                <!-- 合计价 -->
                                <td style="text-align:left;padding-left:15px">{{ item.sellingSubtotal }}</td>
                                <!-- 备注 -->
                                <td style="text-align:left;padding-left:15px">{{ item.remark }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Dialog,ImagePreview } from 'vant'
import api from '@/api/api.js'
import api2 from '@/api/dataBoard.js'

export default {
    components: {
    },
    data () {
        return {
            id: '',
            logId: '',
            isSub: false,
            dataTable: [],
            // 详情传值
            customerDetails: {},
            // 显示提交或者下单分包
            isShowPlace: '',
            // 销售单id
            customerId: '',
            // 消息内容
            msgConent: {},
            // 合计
            total: 0,
            // 增减量
            quantity: 0
        }
    },
    props: {

    },
    methods: {
        back () {
            this.$router.go(-1)
        },

        // 切换收起展开
        handleChangeSub () {
            this.isSub = !this.isSub
        },
        // 查询详情
        async handleDetails () {
            const data = await api.orderDetailList(this.id)
            this.customerDetails = data.data
            data.data.item.forEach(item => {
                this.total += item.amount * item.sellingPrice
                this.quantity += item.amount
            })
            this.total = Math.round(this.total * 100) / 100
            this.quantity = Math.round(this.quantity * 100) / 100
            let res = await api2.messageApi(this.logId)
            this.msgConent = res.data
        },
        onPreview (url) {
            ImagePreview({
                images: [url],
                closeable: true,
                showIndex: false
            });
        },

    },
    mounted () {

    },
    created () {
        console.log(this.$route.params, "l;iuyop")
        this.id = this.$route.params.id
        this.logId = this.$route.params.logId
        this.handleDetails()
    }
}
</script>

<style lang="scss" scoped>
.main_box {
    background: #F5F5F5;

    .color_blue {
        color: #3975C6;
    }

    .color_red {
        color: #EB5757;
    }

    .info_main {
        background: #3975C6;
        padding: 12px;

        .info_nav {
            display: flex;
            align-items: center;

            .info_left {
                width: 72px;
                height: 72px;
                border-radius: 100%;
                border: 1.5px solid #9AC5FF;
                background: #FFFFFF;
                color: #3975C6;
                font-weight: bold;
                line-height: 72px;
                text-align: center;
                font-size: 22px;
                font-family: PingFang SC-Heavy, PingFang SC;
                // flex: 3;
            }

            .info_right {
                margin-left: 12px;
                width: 265px;

                .right_flex {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .right_a {
                        font-size: 22px;
                        color: #FFFFFF;
                        font-family: PingFang SC-Bold, PingFang SC;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    .right_b_img {
                        display: flex;
                        align-items: center;
                        padding: 0px 8px;
                        margin-bottom:-10px ;

                        .img {
                            width: 48px;
                            height: 48px;
                            margin-right: 1px;
                            // vertical-align: middle;
                        }
                    }
                }

                .right_bottom {
                    text-align: left;
                    margin-top: 8px;
                    display: flex;
                    align-items: center;

                    .span_back {
                        background: #FFFFFF;
                        border-radius: 1px;
                        margin-right: 6px;
                        color: #3975C6;
                        font-size: 11px;
                        padding: 1px 4px;
                        font-family: PingFang SC-Regular, PingFang SC;
                    }

                    .span_code {
                        color: #FFFFFF;
                        font-size: 15px;
                        font-family: PingFang SC-Medium, PingFang SC;
                    }
                }
            }
        }

        .info_content {
            margin-top: 16px;

            .cont_nav {
                background: #2569C3;
                border-radius: 4px;
                display: flex;
                justify-content: space-around;
                color: #FFFFFF;

                .cont_mian {
                    padding: 12px 5px;
                    position: relative;
                    // flex: 1;

                    .cont_main_top {
                        font-size: 15px;
                        font-weight: medium;
                        margin-bottom: 2px;
                        font-family: PingFang SC-Medium, PingFang SC;
                        flex-wrap: nowrap;
                    }

                    .cont_main_bottom {
                        font-size: 13px;
                        color: #FBFBFB;
                        font-family: PingFang SC-Regular, PingFang SC;
                    }
                }

                .fenge::after {
                    position: absolute;
                    content: '';
                    width: 1px;
                    height: 18px;
                    opacity: 1;
                    right: -50px;
                    top: 26px;
                    // border: 1px solid #3F77C0;
                    background: #3F77C0;
                }
            }

            .cont_main {
                border-radius: 4px;
                background: #2569C3;
                margin-top: 1px;
                padding: 9px 8px;

                .cont_flex {
                    display: flex;
                    justify-content: space-between;
                    margin: 9px 0px;

                    .flex_a {
                        color: #FFFFFF;
                        font-size: 13px;
                        font-family: PingFang SC-Regular, PingFang SC;

                    }

                    .flex_b {
                        font-size: 15px;
                        color: #FFFFFF;
                        font-weight: medium;
                        font-family: PingFang SC-Medium, PingFang SC;
                    }
                }
                .cont_flex_img{
                    display: flex;
                    justify-content: space-between;
                    .flex_text{
                        white-space: nowrap;
                    }
                    .flex_img{
                        display: flex;
                        flex-wrap: wrap;
                        width: 232px;
                        .img{
                            // display: inline-block;
                            margin: 0px 6px 6px 6px;
                        }
                    }
                }
            }

            .content_bottom {
                width: 100%;
                color: #ECF4FF;
                font-size: 12px;
                margin: 12px 0px 0px 0px;

                .flex {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-family: PingFang SC-Regular, PingFang SC;
                }

                .jiantou {
                    transform: rotate(180deg);
                    transition: all 0.3s;
                }
            }
        }

    }

    .height_style {
        height: calc(100vh - 150px);
        overflow: auto;
    }

    .height_style1 {
        height: calc(100vh - 51px);
        overflow: auto;
    }

    .center_count {
        height: 44px;
        background: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 16px;
        padding: 0px 12px;

        .left {
            display: flex;
            align-items: flex-end;

            .left_a {
                color: #333333;
                font-size: 16px;
                font-family: PingFang SC-Bold, PingFang SC;
                font-weight: bold;
                margin-right: 2px;

            }

            .left_b {
                color: #868686;
                font-size: 12px;
                font-family: PingFang SC-Medium, PingFang SC;
            }
        }

        .right {
            display: flex;
            align-items: flex-end;
            font-family: PingFang SC-Bold, PingFang SC;

            .right_a {
                color: #333333;
                font-size: 20px;
                margin-right: 16px;

            }

            .right_b {
                color: #EB5757;
                font-size: 20px;
                font-weight: bold;
            }
        }
    }

    .bottom_table {
        width: 100%;
        overflow-x: auto;

        .table_mian {
            width: 751px;
        }

        .sticky-col {
            position: sticky;
            left: 0;
            z-index: 1;
            background-color: #f5f5f5;

        }

        .fixed_column {
            position: sticky;
            left: 0;
            z-index: 1;
            /* 将固定列设置为最上层 */
            background: #EEEEEE;
        }

        .fixed_column_td {
            position: sticky;
            left: 0;
            z-index: 1;
            background: #fff;
            /* 将固定列设置为最上层 */
        }

        table {
            border-collapse: collapse; //将边框合并
            // display: block;
            width: 100%;
            white-space: nowrap;
            table-layout: fixed;
            background: #FFFFFF;
        }

        th {
            padding: 0;
            font-family: PingFang SC-Medium, PingFang SC;
            font-size: 13px;
        }

        td {
            font-family: PingFang SC-Regular, PingFang SC;
        }

        td,
        th {
            /* 设置 td 元素的文本超出部分以省略号形式展示 */
            text-overflow: ellipsis;
            /* 设置 td 元素的文本不换行 */
            white-space: nowrap;
            overflow: hidden;
        }

        .fixed_column,
        .fixed_column+td {
            width: 100px;
            /* 设置第一列单元格宽度 */
            text-align: center;
        }

        .fixed_column.dragging {
            box-shadow: 2px 0 6px rgba(0, 0, 0, 0.2);
        }

        .tr_th {
            background: #EEEEEE;
            color: #1D2129;
            width: 751px;
            height: 49px;
        }

        .main_tr {
            border-bottom: 1px solid #E5E6EB;
            height: 49px;
            color: #1D2129;
            font-size: 14px;
        }
    }

}
</style>