<template>
    <div>
        <van-list class="vant-list-style" v-model="loading" :finished="finished"
            :finished-text="total ? '·已加载全部数据·' : '·暂无数据·'" @load="onLoad">
            <pullRefresh ref="pullRefresh" @refresh="onRefresh">
                <div v-if="!followList.length">暂无数据</div>
                <div class="list-item" v-for="(item, index) in followList">
                    <div class="item-top">
                        <div>
                            <span class="name">{{ item.userName }}</span>
                            <span class="depart">{{ item.departName }}</span>
                        </div>
                        <div class="time">{{ item.createDate }}</div>
                    </div>
                    <div class="item-remark">
                        {{ item.advanceRemark }}
                    </div>
                </div>
            </pullRefresh>
        </van-list>
    </div>
</template>

<script>
import api from '../../../api/api';
export default {
    data () {
        return {
            followList: [],
            isLoading: false,
            loading: false,
            finished: false,
            error: false, // 是否加载失败
            pageNum: 1, // 分页
            pageSize: 10, // 每页条数
            total: 0, // 数据总条数
        }
    },
    methods: {
        async getFollowList () {
            let obj = {
                page: this.pageNum,
                limit: this.pageSize,
                Q_consumerId_EQ: this.$route.params.id,
            }
            let res = await api.getFollowList(obj)
            if (res.code == 200) {
                this.total = res.count;
                if (res.data.length === 0) { // 判断获取数据条数若等于0
                    this.followList = []; // 清空数组
                    this.finished = true; // 停止加载
                } else {
                    // 若数据条数不等于0
                    this.followList.push(...res.data) // 将数据放入list中
                    this.loading = false; // 加载状态结束 
                    // 如果list长度大于等于总数据条数,数据全部加载完成
                    if (this.followList.length >= res.count) {
                        this.finished = true; // 结束加载状态
                    }
                }
                this.isLoading = false
                if (this.$refs.pullRefresh) {
                    this.$refs.pullRefresh.refreshSuccess()
                }
            }
        },
        // 被 @load调用的方法
        onLoad (e) { // 若加载条到了底部
            let timer = setTimeout(() => { // 定时器仅针对本地数据渲染动画效果,项目中axios请求不需要定时器
                this.getFollowList(); // 调用上面方法,请求数据
                this.pageNum++; // 分页数加一
                this.finished && clearTimeout(timer); //清除计时器
            }, 100);
        },
        // 加载失败调用方法
        onRefresh (e) {
            this.finished = false; // 清空列表数据
            this.loading = true; // 将 loading 设置为 true，表示处于加载状态
            this.pageNum = 1; // 分页数赋值为1
            this.followList = []; // 清空数组
            this.onLoad(e); // 重新加载数据
        },
    }
}
</script>

<style lang="scss" scoped>

.list-item {
    background-color: #FFFFFF;
    margin: 7px;
    margin-bottom: 0;
    border-radius: 8px;
    box-shadow: 1px 1px 6px #E6E6E6;

    .item-top {
        display: flex;
        line-height: 50px;
        border-bottom: 1px solid #E6E6E6;
        padding: 0 10px;
        text-align: left;

        div {
            flex: 1;
        }

        .name {
            font-size: 17px;
            margin-right: 10px;
        }

        .depart {
            color: #AAAAAA;
            text-align: right;
        }

        .time {
            text-align: right;
            color: #AAAAAA;
        }
    }

    .item-remark {
        padding: 10px;
        text-align: left;
        min-height: 40px;
    }
}
</style>