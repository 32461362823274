// php
import Vue from "vue";
import * as API from "../index.js";
import { buildParam } from "@/utils/index";
const BaseApi = process.env.VUE_APP_BASE_API;
const SysApi = process.env.VUE_APP_SYS_API;

// ------------------------------看板------------------------------------
// 任务筛选
const getTasksDetail = "/task/taskBasicsTarget/select/taskDetail";
// 上月数据详情
const getLastDetail = "/task/hr/lastDetail"
// 任务审批结果列表查询
const getApproveList = "/task/approveAccount/getApproveList";
// 图片上传到服务器
const uploadFile = "/tools/oss/ali/upload";
// 任务结果上传
const saveTaskResult= "/task/approveAccount/save";
// 任务审批单个审批流数据
const getTaskStep = "/task/approveAccount/getById";
// 执行审批
const approval = "/task/approveAccount/approval";
// ------------------------------设置-------------------------------------
// 本月/计划/历史的数据查询列表
const getTaskList = "/task/hr/getTaskList";
// 待审批数量查询
const getPendingApprovalCount= "/task/approveAccount/pendingApprovalCount";
// 任务详情 数据查询
const getTaskDetail = "/task/product/getById"
// 任务项列表
const getTermList = "/task/term/getTermList"
// 执行者数据列表
const getExecutorList = "/task/hr/getExecutorList"
// 校验-月份不可选择
const checkDate = "/task/hr/checkDate"
// 任务设置保存
const saveTask = "/task/hr/saveTask"
// 任务设置更新
const updateTask = "/task/hr/updateTask"
// 子任务数据查询列表
const getSubTaskList = "/task/taskMainItem/list"
// 子任务数据批量新增
const saveSubTask = "/task/taskMainItem/saveBatch"
// 子任务数据删除
const removeSubTask = "/task/taskMainItem/remove"
// 子任务数据详情
const getSubTaskDetail = "/task/taskMainItem/getById"
// 子任务数据修改
const updateSubTask = "/task/taskMainItem/updateById"
// 招聘岗位的数据列表
const getPostList = "/backend/post/r01"
// 招聘部门的数据列表
const getDeptList = "/backend/depart/getDeptTreeById"

export default {
  // 看板-------------------------------
  getTasksDetail: (params) => {
    buildParam(params);
    return API.POST(`${BaseApi}${getTasksDetail}`, params);
  },
  getLastDetail: (params) => {
    return API.GET(`${BaseApi}${getLastDetail}`, params);
  },
  getApproveList: (params) => {
    buildParam(params);
    return API.POST(`${BaseApi}${getApproveList}`, params);
  },
  uploadFile: (params) => {
    buildParam(params);
    return API.POST(`${SysApi}${uploadFile}`, params);
  },
  saveTaskResult: (params) => {
    buildParam(params);
    return API.POST(`${BaseApi}${saveTaskResult}`, params);
  },
  getTaskStep: (params) => {
    buildParam(params);
    return API.GET(`${BaseApi}${getTaskStep}`, params);
  },
  approval: (params) => {
    buildParam(params);
    return API.POST(`${BaseApi}${approval}`, params);
  },

  // 设置------------------------------------
  getTaskList: (params) => {
    buildParam(params);
    return API.GET(`${BaseApi}${getTaskList}`, params);
  },
  getPendingApprovalCount: (params) => {
    buildParam(params);
    return API.GET(`${BaseApi}${getPendingApprovalCount}`, params);
  },
  getTaskDetail: (params) => {
    buildParam(params);
    return API.GET(`${BaseApi}${getTaskDetail}`, params);
  },
  getTermList: (params) => {
    buildParam(params);
    return API.GET(`${BaseApi}${getTermList}`, params);
  },
  getExecutorList: (params) => {
    buildParam(params);
    return API.GET(`${BaseApi}${getExecutorList}`, params);
  },
  checkDate: (params) => {
    buildParam(params);
    return API.GET(`${BaseApi}${checkDate}`, params);
  },
  saveTask: (params) => {
    buildParam(params);
    return API.POST(`${BaseApi}${saveTask}`, params);
  },
  updateTask: (params) => {
    buildParam(params);
    return API.POST(`${BaseApi}${updateTask}`, params);
  },
  getSubTaskList: (params) => {
    buildParam(params);
    return API.GET(`${BaseApi}${getSubTaskList}`, params);
  },
  saveSubTask: (params) => {
    buildParam(params);
    return API.POST(`${BaseApi}${saveSubTask}`, params);
  },
  removeSubTask: (params) => {
    buildParam(params);
    return API.GET(`${BaseApi}${removeSubTask}`, params);
  },
  getSubTaskDetail: (params) => {
    buildParam(params);
    return API.GET(`${BaseApi}${getSubTaskDetail}`, params);
  },
  updateSubTask: (params) => {
    buildParam(params);
    return API.POST(`${BaseApi}${updateSubTask}`, params);
  },
  getPostList: (params) => {
    buildParam(params);
    return API.POST(`${SysApi}${getPostList}`, params);
  },
  getDeptList: (params) => {
    buildParam(params);
    return API.GET(`${SysApi}${getDeptList}`, params);
  },
};

