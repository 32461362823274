// 人事经理  任务详情
let manageTaskDetailModel = [
  { name:'延期发生率',type:'delay_happen',
    table:[
      {label:'任务时间',prop:'taskDate',},
      {label:'任务项',prop:'termName',},
      {label:'执行者',prop:'userName',},
      {label:'标准发生率',prop:'standardRate',class:'interval',},
      {label:'实际发生率',prop:'finishRate',unit:'%',finishStatus:true},
      {label:'工地延期数',prop:'finishValue',unit:'个'},
      {label:'工地总数',prop:'targetValue',unit:'个'},
      {label:'子任务',prop:'childTaskTotalCount',class:'interval',click:'subTask'},
    ],
  },
  { name:'延期解决率',type:'delay_solve',
    table:[
      {label:'任务时间',prop:'taskDate',},
      {label:'任务项',prop:'termName',},
      {label:'执行者',prop:'userName',},
      {label:'标准解决率',prop:'standardRate',class:'interval',},
      {label:'实际解决率',prop:'finishRate',unit:'%',finishStatus:true},
      {label:'解决工地数',prop:'finishValue',unit:'个'},
      {label:'需解决总数',prop:'targetValue',unit:'个'},
      {label:'子任务',prop:'childTaskTotalCount',class:'interval',click:'subTask'},
    ],
  },
  { name:'在建售后发生率',type:'after_sale_building',
    table:[
      {label:'任务时间',prop:'taskDate',},
      {label:'任务项',prop:'termName',},
      {label:'执行者',prop:'userName',},
      {label:'标准发生率',prop:'standardRate',class:'interval',},
      {label:'实际发生率',prop:'finishRate',unit:'%',finishStatus:true},
      {label:'工地售后数',prop:'finishValue',unit:'个'},
      {label:'工地总数',prop:'targetValue',unit:'个'},
      {label:'子任务',prop:'childTaskTotalCount',class:'interval',click:'subTask'},
    ],
  },
  { name:'完工售后发生率',type:'after_sale_finish',
    table:[
      {label:'任务时间',prop:'taskDate',},
      {label:'任务项',prop:'termName',},
      {label:'执行者',prop:'userName',},
      {label:'标准发生率',prop:'standardRate',class:'interval',},
      {label:'实际发生率',prop:'finishRate',unit:'%',finishStatus:true},
      {label:'工地售后数',prop:'finishValue',unit:'个'},
      {label:'工地总数',prop:'targetValue',unit:'个'},
      {label:'子任务',prop:'childTaskTotalCount',class:'interval',click:'subTask'},
    ],
  },
  { name:'售后解决率',type:'after_sale_solve',
    table:[
      {label:'任务时间',prop:'taskDate',},
      {label:'任务项',prop:'termName',},
      {label:'执行者',prop:'userName',},
      {label:'标准解决率',prop:'standardRate',class:'interval',},
      {label:'实际解决率',prop:'finishRate',unit:'%',finishStatus:true},
      {label:'解决工地数',prop:'finishValue',unit:'个'},
      {label:'需解决总数',prop:'targetValue',unit:'个'},
      {label:'子任务',prop:'childTaskTotalCount',class:'interval',click:'subTask'},
    ],
  },
  { name:'投诉发生率',type:'complaint_happen',
    table:[
      {label:'任务时间',prop:'taskDate',},
      {label:'任务项',prop:'termName',},
      {label:'执行者',prop:'userName',},
      {label:'标准发生率',prop:'standardRate',class:'interval',},
      {label:'实际发生率',prop:'finishRate',unit:'%',finishStatus:true},
      {label:'工地投诉数',prop:'finishValue',unit:'个'},
      {label:'工地总数',prop:'targetValue',unit:'个'},
      {label:'子任务',prop:'childTaskTotalCount',class:'interval',click:'subTask'},
    ],
  },
  { name:'投诉解决率',type:'complaint_solve',
    table:[
      {label:'任务时间',prop:'taskDate',},
      {label:'任务项',prop:'termName',},
      {label:'执行者',prop:'userName',},
      {label:'标准解决率',prop:'standardRate',class:'interval',},
      {label:'实际解决率',prop:'finishRate',unit:'%',finishStatus:true},
      {label:'解决工地数',prop:'finishValue',unit:'个'},
      {label:'需解决总数',prop:'targetValue',unit:'个'},
      {label:'子任务',prop:'childTaskTotalCount',class:'interval',click:'subTask'},
    ],
  },
]

let commissionerTaskDetailModel = [
  { name:'延期发生率',type:'delay_happen',
    table:[
      {label:'任务时间',prop:'taskDate',},
      {label:'任务项',prop:'termName',},
      {label:'标准发生率',prop:'standardRate',class:'interval',},
      {label:'实际发生率',prop:'finishRate',unit:'%',finishStatus:true},
      {label:'工地延期数',prop:'finishValue',unit:'个'},
      {label:'工地总数',prop:'targetValue',unit:'个'},
      {label:'附件',prop:'fileList',},
    ],
  },
  { name:'延期解决率',type:'delay_solve',
    table:[
      {label:'任务时间',prop:'taskDate',},
      {label:'任务项',prop:'termName',},
      {label:'标准解决率',prop:'standardRate',class:'interval',},
      {label:'实际解决率',prop:'finishRate',unit:'%',finishStatus:true},
      {label:'解决工地数',prop:'finishValue',unit:'个'},
      {label:'需解决总数',prop:'targetValue',unit:'个'},
      {label:'附件',prop:'fileList',},
    ],
  },
  { name:'在建售后发生率',type:'after_sale_building',
    table:[
      {label:'任务时间',prop:'taskDate',},
      {label:'任务项',prop:'termName',},
      {label:'标准发生率',prop:'standardRate',class:'interval',},
      {label:'实际发生率',prop:'finishRate',unit:'%',finishStatus:true},
      {label:'工地售后数',prop:'finishValue',unit:'个'},
      {label:'工地总数',prop:'targetValue',unit:'个'},
      {label:'附件',prop:'fileList',},
    ],
  },
  { name:'完工售后发生率',type:'after_sale_finish',
    table:[
      {label:'任务时间',prop:'taskDate',},
      {label:'任务项',prop:'termName',},
      {label:'标准发生率',prop:'standardRate',class:'interval',},
      {label:'实际发生率',prop:'finishRate',unit:'%',finishStatus:true},
      {label:'工地售后数',prop:'finishValue',unit:'个'},
      {label:'工地总数',prop:'targetValue',unit:'个'},
      {label:'附件',prop:'fileList',},
    ],
  },
  { name:'售后解决率',type:'after_sale_solve',
    table:[
      {label:'任务时间',prop:'taskDate',},
      {label:'任务项',prop:'termName',},
      {label:'标准解决率',prop:'standardRate',class:'interval',},
      {label:'实际解决率',prop:'finishRate',unit:'%',finishStatus:true},
      {label:'解决工地数',prop:'finishValue',unit:'个'},
      {label:'需解决总数',prop:'targetValue',unit:'个'},
      {label:'附件',prop:'fileList',},
    ],
  },
  { name:'投诉发生率',type:'complaint_happen',
    table:[
      {label:'任务时间',prop:'taskDate',},
      {label:'任务项',prop:'termName',},
      {label:'标准发生率',prop:'standardRate',class:'interval',},
      {label:'实际发生率',prop:'finishRate',unit:'%',finishStatus:true},
      {label:'工地投诉数',prop:'finishValue',unit:'个'},
      {label:'工地总数',prop:'targetValue',unit:'个'},
      {label:'附件',prop:'fileList',},
    ],
  },
  { name:'投诉解决率',type:'complaint_solve',
    table:[
      {label:'任务时间',prop:'taskDate',},
      {label:'任务项',prop:'termName',},
      {label:'标准解决率',prop:'standardRate',class:'interval',},
      {label:'实际解决率',prop:'finishRate',unit:'%',finishStatus:true},
      {label:'解决工地数',prop:'finishValue',unit:'个'},
      {label:'需解决总数',prop:'targetValue',unit:'个'},
      {label:'附件',prop:'fileList',},
    ],
  },
]

export const taskDetailModelFn = (code,params) => {
  let data = {}
  if(code == "ROLE_560934"){
    manageTaskDetailModel.filter(item => {
      if (item.type == params) {
        data = item
      }
    })
  }else{
    commissionerTaskDetailModel.filter(item => {
      if (item.type == params) {
        data = item
      }
    })
  }
  return data
};

// 子任务详情
let subTaskDetailModel = [
  { name:'延期发生率',type:'delay_happen',
    table:[
      {label:'执行者',prop:'userName',},
      {label:'标准发生率',prop:'standardRate',class:'interval',},
      {label:'实际发生率',prop:'finishRate',unit:'%',finishStatus:true},
      {label:'工地延期数',prop:'finishValue',unit:'个'},
      {label:'工地总数',prop:'targetValue',unit:'个'},
      {label:'附件',prop:'fileList',},
    ],
  },
  { name:'延期解决率',type:'delay_solve',
    table:[
      {label:'执行者',prop:'userName',},
      {label:'标准解决率',prop:'standardRate',class:'interval',},
      {label:'实际解决率',prop:'finishRate',unit:'%',finishStatus:true},
      {label:'解决工地数',prop:'finishValue',unit:'个'},
      {label:'需解决总数',prop:'targetValue',unit:'个'},
      {label:'附件',prop:'fileList',},
    ],
  },
  { name:'在建售后发生率',type:'after_sale_building',
    table:[
      {label:'执行者',prop:'userName',},
      {label:'标准发生率',prop:'standardRate',class:'interval',},
      {label:'实际发生率',prop:'finishRate',unit:'%',finishStatus:true},
      {label:'工地售后数',prop:'finishValue',unit:'个'},
      {label:'工地总数',prop:'targetValue',unit:'个'},
      {label:'附件',prop:'fileList',},
    ],
  },
  { name:'完工售后发生率',type:'after_sale_finish',
    table:[
      {label:'执行者',prop:'userName',},
      {label:'标准发生率',prop:'standardRate',class:'interval',},
      {label:'实际发生率',prop:'finishRate',unit:'%',finishStatus:true},
      {label:'工地售后数',prop:'finishValue',unit:'个'},
      {label:'工地总数',prop:'targetValue',unit:'个'},
      {label:'附件',prop:'fileList',},
    ],
  },
  { name:'售后解决率',type:'after_sale_solve',
    table:[
      {label:'执行者',prop:'userName',},
      {label:'标准解决率',prop:'standardRate',class:'interval',},
      {label:'实际解决率',prop:'finishRate',unit:'%',finishStatus:true},
      {label:'解决工地数',prop:'finishValue',unit:'个'},
      {label:'需解决总数',prop:'targetValue',unit:'个'},
      {label:'附件',prop:'fileList',},
    ],
  },
  { name:'投诉发生率',type:'complaint_happen',
    table:[
      {label:'执行者',prop:'userName',},
      {label:'标准发生率',prop:'standardRate',class:'interval',},
      {label:'实际发生率',prop:'finishRate',unit:'%',finishStatus:true},
      {label:'工地投诉数',prop:'finishValue',unit:'个'},
      {label:'工地总数',prop:'targetValue',unit:'个'},
      {label:'附件',prop:'fileList',},
    ],
  },
  { name:'投诉解决率',type:'complaint_solve',
    table:[
      {label:'执行者',prop:'userName',},
      {label:'标准解决率',prop:'standardRate',class:'interval',},
      {label:'实际解决率',prop:'finishRate',unit:'%',finishStatus:true},
      {label:'解决工地数',prop:'finishValue',unit:'个'},
      {label:'需解决总数',prop:'targetValue',unit:'个'},
      {label:'附件',prop:'fileList',},
    ],
  },
]
export const subTaskDetailModelFn = (params) => {
  let data = {}
  subTaskDetailModel.filter(item => {
    if (item.type == params) {
      data = item
    }
  })
  return data
};