<template>
  <div class="content market-content">
    <headerNav leftNav=" " :title="targetObj.navTitle" @leftClick="back"></headerNav>
    <main class="content-main">
      <!-- 汇总 -->
      <BoardNavSummarize 
      :imgBackUrlName="targetObj.imgUrl" 
      :ratio="taskPlanObj.average" 
      :completionsCount="taskPlanObj.reachStandard"
      :sumTotal="taskPlanObj.noReachStandard"
      :leftIconName="targetObj.leftIconName"
      :leftText="targetObj.collect1"
      :rightIconName="targetObj.rightIconName"
      :rightText="targetObj.collect2"
      :unit="targetObj.unit"
      :targetName="targetObj.collect3"
      :navTitleUnit="targetObj.value == 'overview_report'? ' ':'%'"/>
      <div class="content-center-title">
        <img src="@/assets/task-management/task-details-center-title.png" alt="">
        <div>市场详情</div>
      </div>

      <!-- table -->
      <el-table :data="marketTableData" style="width: 100%"
        header-cell-class-name="market-title" row-class-name="market-row" row-key="marketCode"
        :expand-row-keys="defaultExpandList">
        <!-- 自定义暂无数据 -->
        <template slot="empty">
          <div class="empty-no-data">
            <img src="../../../../assets/task-management/no-data.png" alt="">
            <div>暂无数据</div>
          </div>
        </template>
        <el-table-column type="index" label="TOP" align="center" width="43">
          <template slot-scope="props">
            <div v-if="props.$index < 3" style="line-height: 1;"><img class="index-img" :src="getImageIndex(props.$index)"
                alt=""></div>
            <div v-else> {{ props.$index + 1 }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="marketName" label="市场" min-width="44">
          <template slot-scope="props">
            <!-- 判断是市场综述还是其他 -->
            <span v-if="targetObj.value == 'overview_report'" class="market-content-text">{{ props.row.marketName }}</span>
            <span v-else class="market-content-a" @click="onClickMarketDetails(props.row)">{{ props.row.marketName
            }}</span>
          </template>
        </el-table-column>
        <el-table-column v-for="(item,index) in targetObj.table" :key="index" :prop="item.props" :label="item.label" align="right"
          min-width="85">
          <template slot="header" slot-scope="scope">
           <div class="table-custom">
            <span>{{ item.label }}</span>
            <!-- 综述展示得分规则 -->
            <el-tooltip class="tooltip-content" effect="dark" placement="top-end" :offset="-10" v-if="item.isShowTooltip">
              <div slot="content" v-if="item.tooltip">{{ targetObj.tooltip }}</div>
              <div slot="content" v-else>满分100分；以延期提交(1天2分)、<br/>错漏处(1处1分)进行减分</div>
              <img class="table-img" src="../../../../assets/task-management/table-hint.png" alt="">
            </el-tooltip>
           </div>
          </template>
          <template slot-scope="scope">
            <span class="complete">
               {{ (targetObj.value == 'site_routing_inspection' && scope.row[item.props] != '-' ?Number(scope.row[item.props]).toFixed(0):scope.row[item.props] )
              + (scope.row[item.props] != '-'?item.unit :'') }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="handle" label="操作" min-width="38">
          <template slot-scope="scope">
            <span class="market-details" @click="expandControlRow(scope.row)">详情</span>
          </template>
        </el-table-column>
        <!-- 展开行详情 -->
        <el-table-column type="expand" width="1">
          <template slot-scope="scope">
            <div class="market-content-expand">
              <div class="market-content-expand-left">
                <!-- 判断是综述就显示最终得分 -->
                <img :src="require(`../../../../assets/task-management/${targetObj.expandIcon}.png`)" alt="">
                <div>
                  {{ targetObj.expandName }}：
                  {{ scope.row.lastMonthData}}
                  {{ targetObj.value == 'overview_report' ? '分' : '%' }}
                </div>
              </div>
              <div class="market-content-expand-right">
                <span class="color-green" v-if="scope.row.isSuccess == '1'">已完成</span>
                <span class="color-red" v-if="scope.row.isSuccess == '0'">未完成</span>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </main>
  </div>
</template>

<script>
import BoardNavSummarize from "@/views/task-management/component/public/module/BoardNavSummarize.vue"
import {estimateOutput} from "./data.js"
import api from "@/api/task-management/finance.js";
import api2 from "@/api/task-management/inquiry.js";

import { Toast } from "vant";

export default {
  components: {
    BoardNavSummarize
  },
  data () {
    return {
      // 任务看板路由接参
      routerData: {},
      // 市场表格数据
      marketTableData:[],
      // 展开行id
      defaultExpandList: [],
      // 当前指标
      targetObj:{},
      // 展开行展示图标
      expandIcon:'',
      // 当前指标信息
      taskPlanObj: {}
    }
  },
  created () {
    let localData = JSON.parse(localStorage.getItem("TABLE_HEADER_CONTENT"));
    this.routerData = localData;
    this.targetObj = estimateOutput(localData.termSlug);
    console.log(this.targetObj);
    this.getTableData()
  },
  methods: {
    back () {
      this.$router.go(-1)
    },
    // 查询表格数据
    async getTableData () {
      try {
        Toast.loading({
          message: '加载中...',
          forbidClick: true,
        });
        const { data } = await api.detailListApi({
          ext: this.routerData.termSlug,
          Q_reportData_EQ: this.routerData.Q_reportData_EQ,
          type: "1"
        });
        this.taskPlanObj = data;
        this.marketTableData = data.data01 || [];
      } finally {
        Toast.clear()
      }
    },
    // 控制展开行
    expandControlRow (item) {
      const position = this.defaultExpandList.indexOf(item.marketCode)
      if (position !== -1) {
        this.defaultExpandList.splice(position, 1)
      } else {
        this.defaultExpandList.push(item.marketCode)
        this.getExpandRowDetails(item)
      }
    },
    /* 查询展开行详情 */
    async getExpandRowDetails(row) {
      const { data } = await api2.lastMonthDetail({
        termId: row.termId,
        selectId:row.selectId,
        type:1
      });
      this.marketTableData = this.marketTableData.map(item => {
        if (item.marketCode == row.marketCode) {
          item = { ...item, ...data };
        }
        return item;
      });
    },
    // 判断表头是否显示提示信息
    isShowTooltip(){
      // 预估、结算、结转、综述报告
      if(this.targetObj.value == 'estimate_profit' || this.targetObj.value == 'settle_accounts_profit' || this.targetObj.value == 'balance_profit' || this.targetObj.value == 'overview_report'){
        return true
      }else{
        return false
      }
    },
    // 获取图片
    getImageIndex (index) {
      return require(`@/assets/task-management/task-details-table-${index + 1}.png`)
    },
    // 点击市场查看部门
    onClickMarketDetails (row) {
      localStorage.setItem("MARKET_DATA", JSON.stringify(row));
      this.$router.push({ path: '/task-management/board/inquiry/employee-list' })
    }
  }
}
</script>

<style lang="scss" scoped src="../../component/public/css/table.scss"></style>
<style lang="scss" scoped>
.market-content::v-deep {
  .content-main {
    .el-table__body-wrapper {
      height: calc(100vh - 332px) !important;
      overflow: auto;
    }
    .table-custom{
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .table-img{
        width: 14px;
        height: 14px;
        margin-left: 3px;
      }
    }
  }
}
</style>