<template>
  <div class="content">
    <headerNav
      leftNav=" "
      title="新建子任务"
      @leftClick="back"
      :rightNav="'添加任务'"
      :rightIcon="'sub-task-add-icon'"
      @rightClick="addTask"
    ></headerNav>
    <main class="content-main">
      <van-form @submit="onSubmitTaskSet" :key="fromRestFields">
        <div v-for="(item,index) in fromArr" :key="index">
          <div class="sub-task">
            <div class="sub-task-title">子任务{{ index+1 }}</div>
            <div class="sub-task-icon" @click="deleteSubTask(index)" v-if="fromArr.length>1"><img src="../../../../assets/task-management/delete-icon.png"></div>
          </div>
          <van-field
            readonly
            clickable
            name="targetDepartmentName"
            :value="item.targetDepartmentName"
            label="招聘部门"
            placeholder="请选择"
            :rules="[{ required: true, message: '' }]"
            @click="showDeptPickerFn(item,index)"
            @focus="forbid"
          >
            <i slot="left-icon" style="color: red">*</i>
            <template #button><van-icon name="arrow" /></template>
          </van-field>
          <van-field
            readonly
            clickable
            name="targetRoleName"
            :value="item.targetRoleName"
            label="招聘职位"
            placeholder="请选择"
            :rules="[{ required: true, message: '' }]"
            @click="showPostPickerFn(item,index)"
            @focus="forbid"
          >
            <i slot="left-icon" style="color: red">*</i>
            <template #button><van-icon name="arrow" /></template>
          </van-field>
          <van-field
            v-model="item.targetValue"
            name="targetValue"
            type="digit"
            maxlength="3"
            label="招聘人数"
            placeholder="请输入"
            :rules="[{ required: true, message: '' }]"
            @input="formatter($event,item,index)"
          >
            <i slot="left-icon" style="color: red">*</i>
            <template #button>人</template>
          </van-field>
          <van-field
            clickable
            readonly
            name="userName"
            :value="item.userName"
            label="执行者"
            placeholder="请选择"
            :rules="[{ required: true, message: '' }]"
            @click="showExecutorPickerFn(item,index)"
            @focus="forbid"
          >
            <i slot="left-icon" style="color: red">*</i>
            <template #button><van-icon name="arrow" /></template>
          </van-field>
        </div>

        <div class="my-task-modification-popup-button">
          <van-button native-type="submit">确认</van-button>
        </div>
      </van-form>
    </main>

    <van-popup v-model="showDeptPicker" position="bottom">
      <van-picker
        show-toolbar
        title="招聘部门"
        :columns="deptList"
        @confirm="onConfirmDept"
        @cancel="showDeptPicker = false"
      />
    </van-popup>
    <van-popup v-model="showPostPicker" position="bottom">
      <van-picker
        show-toolbar
        title="招聘职位"
        :columns="postList"
        @confirm="onConfirmPost"
        @cancel="showPostPicker = false"
      />
    </van-popup>
    <van-popup v-model="showExecutorPicker" position="bottom">
      <van-picker
        show-toolbar
        title="执行者"
        :columns="executorList"
        @confirm="onConfirmExecutor"
        @cancel="showExecutorPicker = false"
      />
    </van-popup>

  </div>
</template>

<script>
import moment from "moment";
import { Toast } from "vant";
import api from "@/api/task-management/human-resources.js";

export default {
  components: {},
  data() {
    return {
      deptList:[], //招聘部门列表
      showDeptPicker: false, //部门弹窗
      showDeptIndex:0, //第几个部门弹窗
      postList: [], //招聘岗位列表
      showPostPicker: false, //岗位弹窗
      showPostIndex:0, //第几个岗位弹窗
      executorList:[], //执行者数据
      showExecutorPicker: false, //执行者弹窗
      showExecutorIndex:0, //第几个执行者弹窗

      fromRestFields: 0,
      fromValue: {
        targetDepartmentId:"",
        targetDepartmentName:"",
        targetRoleCode:"",
        targetRoleName:"",
        userId:"",
        userName:"",
        targetValue:"",
      },
      fromArr:[
        {
          targetDepartmentId:"",
          targetDepartmentName:"",
          targetRoleCode:"",
          targetRoleName:"",
          userId:"",
          userName:"",
          targetValue:"",
        },
      ],
      // 子任务数据
      subTaskList:[],
    };
  },
  created() {
    if(localStorage.getItem("SUB_TASK_LIST")){
      this.subTaskList = JSON.parse(localStorage.getItem("SUB_TASK_LIST"))
    }
  },
  mounted() {
    let _this = this;
    this.getDeptList()
    this.getPostList()
    this.getExecutorList()
  },
  methods: {
    // 路由返回
    back() {
      this.$router.go(-1);
    },
    // 添加任务
    addTask(){
      this.fromArr.push({
        targetDepartmentId:"",
        targetDepartmentName:"",
        targetRoleCode:"",
        targetRoleName:"",
        userId:"",
        userName:"",
        targetValue:"",
      })
    },
    // 删除子任务
    deleteSubTask(index){
      this.fromArr.splice(index,1)
    },
    // 深拷贝
    deepClone(data) {
      return JSON.parse(JSON.stringify(data));
    },
    // 递归替换节点属性名
    modifyProperties(treeData) {
      treeData.forEach(node => {
        node.text = node.name;
        node.value = node.id;
        delete node.name;
        delete node.id;
        if (node.children && node.children.length > 0 && !node.leaf) {
          this.modifyProperties(node.children);
        } else {
          // delete node.children;
        }
      });
      return treeData;
    },
    // 获取招聘部门列表
    getDeptList(){
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      let params = {}
      api.getDeptList(params).then((res)=>{
        if (res.code == 200) {
          Toast.clear()
          let clonedData = this.deepClone(res.data[0].children);
          clonedData.forEach((item,index)=>{
            if(!item.children){
              item['children'] = [{...item}]
            }
          })
          this.deptList = this.modifyProperties(clonedData);
        }else{
          Toast.fail(res.msg)
        }
      })
    },
    // 获取招聘岗位列表
    getPostList(){
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      let params = {
        "limit": "1000",
        "page":"1",
        "Q_status_EQ":"y",
      }
      api.getPostList(params).then((res)=>{
        if (res.code == 200) {
          Toast.clear()
          let arr = []
          res.data.forEach(item => {
            arr.push({
              text:item.name,
              value:item.id,
            })
          });
          this.postList = arr
        }else{
          Toast.fail(res.msg)
        }
      })
    },
    // 获取执行者数据
    getExecutorList(){
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      let params = {}
      api.getExecutorList(params).then((res)=>{
        if (res.code == 200) {
          Toast.clear()
          let arr = []
          res.data.forEach(item => {
            arr.push({
              text:item.name,
              value:item.code,
            })
          });
          this.executorList = arr
        }else{
          Toast.fail(res.msg)
        }
      })
    },
    // 打开招聘部门弹窗
    showDeptPickerFn(item,index){
      this.showDeptPicker = true
      this.showDeptIndex = index
    },
    // 打开招聘岗位弹窗
    showPostPickerFn(item,index){
      this.showPostPicker = true
      this.showPostIndex = index
    },
    // 打开执行者弹窗
    showExecutorPickerFn(item,index){
      this.showExecutorPicker = true
      this.showExecutorIndex = index
    },
    // 通过多维下标获取对应属性
    getNodeInfo(data, index) {
      if (index.length === 1) { // 最后一级，直接返回对应节点的信息
        const node = data[index[0]];
        return {
          value: node.value,
          text: node.text
        };
      } else {
        const subData = data[index[0]].children; // 获取子数组
        const subIndex = index.slice(1); // 获取子下标
        return this.getNodeInfo(subData, subIndex); // 递归调用
      }
    },
    // 判断是否重复
    isRepeat(arr,key,key1){//arr是数组，key是需要比对的属性名
      let data = []
      for(let i=0;i<arr.length;i++){
        for(let j=i+1;j<arr.length;j++){
          if(arr[i][key]&&arr[j][key]&&arr[i][key1]&&arr[j][key1]&&arr[i][key] === arr[j][key] && arr[i][key1] === arr[j][key1]){
            data.push({i,j})
          }
        }
      }
      return data
    },
    // 判断职位是否重复
    repeatPicker(name,id,index){
      let nArr = this.subTaskList.concat(this.fromArr)
      let res = this.isRepeat(nArr,"targetDepartmentName","targetRoleName")
      if(res.length>0){
        this.fromArr[index][name] = ""
        this.fromArr[index][id] = ""
        Toast.fail('该职位已有子任务，请重新创建')
      }
    },
    // 招聘部门弹窗 确认
    onConfirmDept(value,index) {
      if( Array.isArray(index) && index.length>1 ){
        let nodeInfo = this.getNodeInfo(this.deptList, index);
        this.fromArr[this.showDeptIndex].targetDepartmentName = nodeInfo.text;
        this.fromArr[this.showDeptIndex].targetDepartmentId = nodeInfo.value;
      }else{
        this.fromArr[this.showDeptIndex].targetDepartmentName = value.text;
        this.fromArr[this.showDeptIndex].targetDepartmentId = value.value;
      }
      this.showDeptPicker = false;
      // this.repeatPicker("targetDepartmentName","targetDepartmentId",this.showDeptIndex)
    },
    // 招聘岗位弹窗 确认
    onConfirmPost(value) {
      this.fromArr[this.showPostIndex].targetRoleName = value.text;
      this.fromArr[this.showPostIndex].targetRoleCode = value.value;
      this.showPostPicker = false;
      // this.repeatPicker("targetRoleName","targetRoleCode",this.showPostIndex)
    },
    // 执行者弹窗 确认
    onConfirmExecutor(value){
      this.fromArr[this.showExecutorIndex].userName = value.text;
      this.fromArr[this.showExecutorIndex].userId = value.value;
      this.showExecutorPicker = false;
    },
    // 禁止弹出软键盘
    forbid(){
      document.activeElement.blur()
    },
    formatter(value,item,index){
      if( Number(value) <= 0 ){
        Toast.fail('输入的数量不能为空或零')
        this.fromArr[index].targetValue = ""
      }
    },
    // form表单 提交
    onSubmitTaskSet(values) {
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      if(this.$route.query.taskId == ''){
        let nArr = this.subTaskList.concat(this.fromArr)
        if(nArr.length > 50){
          Toast.fail('创建子任务数量不能超过50个！')
          return false
        }
        localStorage.setItem("SUB_TASK_LIST",JSON.stringify(nArr))
        Toast.success('操作成功');
        let _this = this;
        setTimeout(()=>{
          _this.back()
        },1000)
      }else{
        let params={
          "taskId": this.$route.query.taskId,
          "itemList":this.fromArr
        }
        api.saveSubTask(params).then((res)=>{
          Toast.clear()
          if (res.code == 200) {
            Toast.success('操作成功');
            let _this = this;
            setTimeout(()=>{
              _this.back()
            },1000)
          }else{
            Toast.fail(res.msg)
          }
        })
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content ::v-deep {
  height: 100vh;
  overflow: auto;
  background: #f3f5f7;
  .van-form {
    height: calc(100vh - 140px);
    overflow: auto;
    .interval {
      border-top: 8px solid #f3f5f7;
    }
  }
  
  .content-main{
    .sub-task{
      height: 24px;
      padding: 7px 13px 7px 11px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .sub-task-title{
        font-size: 16px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #333333;
        position: relative;
        padding-left: 6px;
        &::after{
          content: ""; 


          width: 4px;
          height: 16px;
          background: #0E8BFF;
          border-radius: 2px 2px 2px 2px;
          position: absolute;
          left: 0;
          top: 50%;
          margin-top: -7px;
        }
      }
      .sub-task-icon{
        width: 20px;
        height: 20px;
        img{
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .my-task-modification-popup-button{
    width: 100%;
    padding: 10px 18px 30px;
    position: fixed;
    bottom: 0;
    box-shadow: 0px -1px 8px 0px rgba(0,0,0,0.04);
    box-sizing: border-box;
    background: #ffffff;
    .van-button{
      width: 100%;
      height: 50px;
      font-size: 18px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      background: #4871C0;
      border-radius: 4px 4px 4px 4px;
    }
  }

  .van-field__label {
    font-size: 16px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    color: #1d2129;
  }
  .van-field__control{
    text-align: right;
    font-size: 16px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #000000;
  }
}
</style>
