// php
import Vue from 'vue'
import * as API from "./index.js";
import {
    buildParam
} from '@/utils/index'
const BaseApi = process.env.VUE_APP_BASE_API
const SysApi = process.env.VUE_APP_SYS_API

// 线索数量
const cluesNum = '/report/board001/r01'
// 渠道
const canalRatio = '/report/board001/r02'
// 期房现房占比
const houseRatio = '/report/Board003/r01/0'
// 库存单
const inventory = '/report/Board003/r02'
// 签单
const signing = '/report/Board003/r03'
// 转单
const transferOrder = '/report/Board003/r04'
// 话务
const traffic ='/report/Board003/r05'
// 督导
const supervision = '/report/Board003/r06'
// 产值完成情况
const output ='/report/Board002/r03'
// 趋势
const trend = '/report/Board002/r04'
// 年度产值
const annualOutput = '/report/Board002/r05'
// 产值设置
const outputSetAdd = '/report/Board002/add/productionValue'
const outputRemind = '/report/Board002/setConversionOutput'

// 查询消息内容
const message = '/decorate/messageLog/queryById/'


export default {
    
    // 根据当前登录人群组信息查看模块信息
    moduleApi: (params) => {
        // buildParam(params)
        return API.GET(`${BaseApi}/report/baseGroupModule/getModuleByGroupId`, params)
    },
    // 选择角色 获取数据看板资源列表
    roleModuleApi: (params) => {
        buildParam(params)
        return API.POST(`${BaseApi}/report/baseModule/list`, params)
    },
    marketApi: (params) => {
        // buildParam(params)
        return API.GET(`${BaseApi}/report/baseUserMarket/getMarketByUserIdList`, params)
    },

    cluesNumApi: (params) => {
        buildParam(params)
        return API.POST(`${BaseApi}${cluesNum}`, params)
    },
    canalRatioApi: (params) => {
        buildParam(params)
        return API.POST(`${BaseApi}${canalRatio}`, params)
    },
    houseRatioApi: (params) => {
        buildParam(params)
        return API.POST(`${BaseApi}${houseRatio}`, params)
    },
    inventoryApi: (params) => {
        buildParam(params)
        return API.POST(`${BaseApi}${inventory}`, params)
    },
    signingApi: (params) => {
        buildParam(params)
        return API.POST(`${BaseApi}${signing}`, params)
    },
    transferOrderApi: (params) => {
        buildParam(params)
        return API.POST(`${BaseApi}${transferOrder}`, params)
    },
    trafficApi: (params) => {
        buildParam(params)
        return API.POST(`${BaseApi}${traffic}`, params)
    },
    supervisionApi: (params) => {
        buildParam(params)
        return API.POST(`${BaseApi}${supervision}`, params)
    },
    outputApi: (params) => {
        buildParam(params)
        return API.POST(`${BaseApi}${output}`, params)
    },
    trendApi: (params) => {
        buildParam(params)
        return API.POST(`${BaseApi}${trend}`, params)
    },
    annualOutputApi: (params) => {
        buildParam(params)
        return API.POST(`${BaseApi}${annualOutput}`, params)
    },

    outputSetAddApi: (params) => {
        buildParam(params)
        return API.POST(`${BaseApi}${outputSetAdd}`, params)
    },


    // 子页面-----------------------------------------------
    cluesEchartApi: (params) => {
        buildParam(params)
        return API.POST(`${BaseApi}/report/board001/r03`, params)
    },
    cluesTableApi: (params) => {
        buildParam(params)
        return API.POST(`${BaseApi}/report/board001/r04`, params)
    },
    toStoreEchartApi: (params) => {
        buildParam(params)
        return API.POST(`${BaseApi}/report/board001/r05`, params)
    },
    toStoreTableApi: (params) => {
        buildParam(params)
        return API.POST(`${BaseApi}/report/board001/r06`, params)
    },
    signEchartApi: (params) => {
        buildParam(params)
        return API.POST(`${BaseApi}/report/board001/r07`, params)
    },
    signTableApi: (params) => {
        buildParam(params)
        return API.POST(`${BaseApi}/report/board001/r08`, params)
    },
    transferOrderEchartApi: (params) => {
        buildParam(params)
        return API.POST(`${BaseApi}/report/board001/r09`, params)
    },
    transferOrderTableApi: (params) => {
        buildParam(params)
        return API.POST(`${BaseApi}/report/board001/r10`, params)
    },
    completionTableApi: (params) => {
        buildParam(params)
        return API.POST(`${BaseApi}/report/board001/r11`, params)
    },

    // report/gongsi004/depart    查部门的接口
    departApi: (params) => {
        buildParam(params)
        return API.POST(`${BaseApi}/report/gongsi004/depart`, params)
    },

    outputRemindApi: (params) => {
        // buildParam(params)
        return API.GET(`${BaseApi}${outputRemind}`, params)
    },

    messageApi: (params) => {
        return API.GET(`${BaseApi}${message}${params}`)
    },
}
