<template>
	<div class="box">
		<router-view />
	</div>
</template>

<script>
	export default {
		created() {
			// let uid1 = this.$route.query.uid1
			// let uid2 = this.$route.query.uid2
			// let uid3 = this.$route.query.uid3
			// let uid4 = this.$route.query.uid4
			// sessionStorage.setItem('uid1','69a641c3-dacb-422a-ba90-caebdee4cd9f')
			// sessionStorage.setItem('uid2','854744')
			// sessionStorage.setItem('uid3','1657525397114')
			// sessionStorage.setItem('uid4','c047ab3414796d596b899b28a3a686f52e6e5aa2d662186343d099eb82b269be')
		},
		data() {
			return {}
		},
		methods: {
			

		},
		mounted() {
			
		},
	}
</script>

<style scoped lang="scss">
	.box{
		height: 100vh;
		// width: 100%;
		background-color: #F5F6F8 ;
	}
</style>
<style lang="scss">
	.vant-list-style {
		.van-list__finished-text {
			font-size: 11px;
			// font-family: Source Han Sans SC;
			font-weight: 400;
			color: #AAAAAA;
			// height: 40px;
			// line-height: 20px;
		}
		.van-loading__text{
			font-size: 11px;
			// font-family: Source Han Sans SC;
			font-weight: 400;
			color: #AAAAAA;
			// display: none;
		}
	}
</style>
