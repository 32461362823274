<template>
    <div class="detail-collapse-box">
        <van-list :loading="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
            <div v-for="item in list" :key="item.id" class="detail-collapse-item">
                <div class="detail-collapse-header" @click="onShowCollapse(item.id)">
                    <div style="font-weight: 300;font-size: 14px;"> {{ item.belowDate }} </div>
                    <div style="padding-left: 8px;">
                        <van-icon name="arrow-down" color="#969799" size="18" v-show="!activeNames.includes(item.id)" />
                        <van-icon name="arrow-up" color="#969799" size="18" v-show="activeNames.includes(item.id)" />
                    </div>
                </div>
                <div class="detail-collapse-center">
                    <div class="dcc-top van-ellipsis"> {{ item.itemName }}</div>
                    <div class="dcc-bottom">
                        <div style="color: #999;font-size: 14px;">增减量/单价</div>
                        <div>
                            <span :style="{ color: item.amount > 0 ? '#E8202A' : '#039139' }">{{
                                item.amount > 0 ? `+${item.amount}` : item.amount }}</span>
                            &emsp;
                            <span style="font-size: 14px;">￥{{ item.sellingPrice }}/{{ item.itemUnit }}</span>
                        </div>
                    </div>
                </div>
                <div class="detail-collapse-footer" v-show="activeNames.includes(item.id)">
                    <van-divider dashed :style="{ borderColor: '#979797', marginBottom: 0 }" />
                    <div class="dcf-item">
                        <div class="label">合计价:</div>
                        <div class="value" style="color: #E8202A;font-size: 16px;">{{ item.sellingTotal }}</div>
                    </div>
                    <div class="dcf-item">
                        <div class="label">材料/定额编号:</div>
                        <div class="value">{{ item.itemNo }}</div>
                    </div>
                    <div class="dcf-item">
                        <div class="label" style="width: 60px;">说明:</div>
                        <div class="value">{{ item.remark }}</div>
                    </div>
                </div>
            </div>
        </van-list>
    </div>
</template>

<script>
export default {
    props: {
        list: {
            type: [Array, null, undefined],
            default: () => []
        },
        activeNames: {
            type: [Array, null, undefined],
            default: () => []
        }
    },
    data() {
        return {
            finished: false,
            loading: false,
        }
    },
    methods: {
        onShowCollapse(id) {
            const flag = this.activeNames.includes(id)
            if (flag) {
                const arr = this.activeNames.filter(item => item !== id)
                this.$emit('update:activeNames', arr)
            } else {
                const arr = [...this.activeNames, id]
                this.$emit('update:activeNames', arr)
            }
        },
        onLoad() { }
    }
}
</script>

<style scoped lang="scss">
.detail-collapse-box {
    font-size: 14px;
    margin-top: 16px;

    .detail-collapse-item {
        background-color: #fff;
        padding: 10px 16px;
        border-radius: 10px;

        &:not(:last-child) {
            margin-bottom: 8px;
        }

        .detail-collapse-header {
            height: 32px;
            line-height: 32px;
            display: flex;
            justify-content: space-between;
            color: #666;
        }

        .detail-collapse-center {
            .dcc-top {
                font-size: 16px;
                color: #333;
            }

            .dcc-bottom {
                margin-top: 16px;
                display: flex;
                justify-content: space-between;
            }
        }

        .detail-collapse-footer {
            font-size: 14px;

            .dcf-item {
                display: flex;
                justify-content: space-between;
                padding: 8px 0;

                .label {
                    width: 100px;
                    color: #999999;
                }

                .value {
                    text-align: right;
                    flex: 1;
                    word-break: break-all;
                }
            }
        }
    }
}
</style>