<template>
  <div style="background: #f5f5f5">
    <headerNav leftNav=" " @leftClick="back" title="调整记录">
      <template>
        <span class="headNav-righ-text" @click="switchTask" v-if="list.isBtn">
          {{ switchText == '0'?'我的任务':'下级任务' }}<van-icon name="exchange" />
        </span>
      </template>
    </headerNav>
    <div class="main">
      <div class="content">
        <van-form @submit="searchUser" class="search" v-if="list.isSearch&&switchText == '1'">
          <van-field v-model="userName" label="" placeholder="输入业务人员名称" maxlength="20">
            <template #button>
              <img class="search-icon" src="../../../assets/task-management/search-icon.png" alt="" @click="searchUser">
            </template>
          </van-field>
        </van-form>
        <div class="screen">
          <div class="screen-item" v-for="(node) in list.tabList" @click="screenFn(node)">
            <div v-if="node.lable == '自定义'" class="screen-item-date">{{ node.value.split("~")[0] }}<br/>{{ node.value.split("~")[1] }}</div>
            <span v-else>{{ node.lable || node.name  }}</span><img src="../../../assets/task-management/task-screen-icon.png" alt="">
          </div>
        </div>
        <div class="info-list" :class="list.isSearch&&switchText == '1'?'info-list1':''" ref="scrollArea1" @scroll="handleScroll">
          <div class="info-list-item" v-for="(item1,index1) in dataList" :key="index1">
            <div class="info-list-item-title">
              <div class="title-name">
                <span v-if="userRole.roleCode == 'ROLE_603933'">{{ item1.markerName }}</span>
                {{ item1.updateStatus == '1'||item1.updateStatus == '2'?'任务设置':'任务修改' }}
              </div>
              <div class="item-time">{{ item1.setTime }}</div>
            </div>
            <div class="info-list-item-content">
              <div class="info-list-cell info-list-target">
                <div class="info-list-lable">指标：</div>
                <div class="info-list-value info-list-target-content">
                  <div v-for="(item5,index5) in item1.termName" :key="index5">{{ item5 }}</div>
                </div>
              </div>
              <div class="info-list-cell">
                <div class="info-list-lable">月份：</div>
                <div class="info-list-value">{{ item1.taskDate}}</div>
              </div>
              <div v-if="item1.isRank == '1'">
                <div v-if="item1.updateStatus == '3'">
                  <div class="info-list-item-modify">
                    <div class="item-left">
                      <div class="item-modify-name">原任务</div>
                      <div class="item-modify-item" v-for="(item2,index2) in item1.rawTask" :key="index2">
                        <div class="item-modify-item-parent">
                          <div class="item-modify-num">
                            {{ item2.productionValue }}<span>{{ item2.unit }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="item-right">
                      <div class="item-modify-name">修改后</div>
                      <div class="item-modify-item" v-for="(item3,index3) in item1.presentTask" :key="index3">
                        <div class="item-modify-item-parent">
                          <div class="item-modify-num">
                            {{ item3.productionValue }}<span>{{ item3.unit }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="info-list-cell">
                    <div class="info-list-lable">人员：</div>
                    <div class="info-list-value">{{ item1.presentTask[0].departmentName }}</div>
                  </div>
                </div>
                <div v-if="item1.updateStatus == '1'||item1.updateStatus == '2'">
                  <div v-for="(item4,index4) in item1.presentTask" :key="index4">
                    <div v-if="index4 == 0">
                      <div class="info-list-cell">
                        <div class="info-list-lable">任务:</div>
                        <div class="info-list-value">{{ item4.productionValue }}<span>{{ item4.unit }}</span></div>
                      </div>
                      <div class="info-list-cell">
                        <div class="info-list-lable">人员：</div>
                        <div class='info-list-value' :class="item4.expandBtn?'info-list-person-height':''">
                          <div class="info-list-person-content" v-for="(item5,index5) in item4.detailList" :key="index5">
                            <div>{{ item5.departmentName }}</div>
                            <div>{{ item5.productionValue }}<span>{{ item5.unit }}</span></div>
                          </div>
                        </div>
                      </div>
                      <div class="expand-person" v-if="item4.detailList&&item4.detailList.length > 5" @click="item4.expandBtn=!item4.expandBtn">
                        <div v-if="item4.expandBtn">更多 <van-icon name="arrow-down" /></div>
                        <div v-else>收起 <van-icon name="arrow-up" /></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="info-list-item-modify" v-if="item1.updateStatus == '3'">
                  <div class="item-left">
                    <div class="item-modify-name">原任务</div>
                    <div class="item-modify-item" v-for="(item2,index2) in item1.rawTask" :key="index2">
                      <div class="item-modify-item-parent">
                        <div class="item-modify-target">{{ item2.departmentName }}：</div>
                        <div class="item-modify-num">
                          {{ item2.productionValue }}<span>{{ item2.unit }}</span>
                        </div>
                      </div>
                      <div class="item-modify-item-child">
                        <div class="item-modify-item-child-item" v-for="(item3,index3) in item2.detailList" :key="index3" >
                          <div class="item-modify-target">{{ item3.departmentName }}：</div>
                          <div class="item-modify-num">
                            {{ item3.productionValue }}<span>{{ item3.unit }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="item-right">
                    <div class="item-modify-name">修改后</div>
                    <div class="item-modify-item" v-for="(item3,index3) in item1.presentTask" :key="index3">
                      <div class="item-modify-item-parent">
                        <div class="item-modify-target">{{ item3.departmentName }}：</div>
                        <div class="item-modify-num">
                          {{ item3.productionValue }}<span>{{ item3.unit }}</span>
                        </div>
                      </div>
                      <div class="item-modify-item-child">
                        <div class="item-modify-item-child-item" v-for="(item7,index7) in item3.detailList" :key="index7">
                          <div class="item-modify-target">{{ item7.departmentName }}：</div>
                          <div class="item-modify-num">
                            {{ item7.productionValue }}<span>{{ item7.unit }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="info-list-item-setup" v-if="item1.updateStatus == '1'||item1.updateStatus == '2'">
                  <div class="item-setup-name">任务:</div>
                  <div class="item-setup-item" v-for="(item4,index4) in item1.presentTask" :key="index4">
                    <div class="item-setup-item-parent">
                      <div class="item-setup-target">{{ item4.departmentName }}：</div>
                      <div class="item-setup-num">
                        {{ item4.productionValue }}<span>{{ item4.unit }}</span>
                        <div class="item-expand-btn" v-if="item4.detailList&&item4.detailList.length > 0" @click="item4.expandBtn=!item4.expandBtn">
                          <van-icon name="arrow-up" v-if="item4.expandBtn"/>
                          <van-icon name="arrow-down" v-else/>
                        </div>
                      </div>
                    </div>
                    <div class="item-setup-item-child" v-for="(item5,index5) in item4.detailList" :key="index5" v-if="item4.expandBtn">
                      <div class="item-setup-target">{{ item5.departmentName }}：</div>
                      <div class="item-setup-num">{{ item5.productionValue }}<span>{{ item5.unit }}</span></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 暂无数据 -->
          <div class="no-data" v-if="isList && dataList.length <= 0">
            <img src="@/assets/task-management/task-setup-nodata.png" alt="">
            <div>暂无数据</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 指标 筛选 -->
    <van-popup v-model="showTargetPicker" :lazy-render="false" position="bottom">
      <van-picker
        show-toolbar
        title="选择指标"
        ref="target"
        :columns="targetList"
        @confirm="onConfirmTarget"
        @cancel="showTargetPicker = false"
      />
    </van-popup>
    <!-- 月份 筛选 -->
    <van-popup v-model="showMonthPicker" :lazy-render="false" position="bottom">
      <van-picker
        show-toolbar
        title="选择月份"
        ref="month"
        :columns="monthList"
        @confirm="onConfirmMonth"
        @cancel="showMonthPicker = false"
      />
    </van-popup>
    <!-- 月份自定义 弹窗 -->
    <van-popup v-model="showCustomMonth" :lazy-render="false" position="bottom">
      <div class="custom">
        <p>按月份筛选</p>
        <div class="custom-date">
          <div
            :class="startMonth == '' ? '' : 'custom-date-content'"
            @click="openChooseMonth('1')"
          >
            {{ startMonth == "" ? "开始时间" : startMonth }}
          </div>
          <div class="custom-date-interval">-</div>
          <div
            :class="endMonth == '' ? '' : 'custom-date-content'"
            @click="openChooseMonth('2')"
          >
            {{ endMonth == "" ? "结束时间" : endMonth }}
          </div>
        </div>
        <div class="custom-footer">
          <van-button class="btn" type="default" @click="customMonthRestSearch">取消</van-button>
          <van-button class="btn" color="#4871C0" @click="customMonthSureSearch" type="info">确认</van-button>
        </div>
      </div>
    </van-popup>
    <!-- 月份自定义 筛选 -->
    <van-popup v-model="showCustomMonthPicker" :lazy-render="false" position="bottom">
      <van-datetime-picker
        v-model="customMonth"
        ref="customMonth"
        type="year-month"
        title="选择年月"
        :min-date="minDate"
        :max-date="maxDate"
        :formatter="dateFormatter"
        @confirm="onConfirmCustomMonth"
      />
    </van-popup>

  </div>
</template>
<script>
import api from "@/api/task-management/setup.js";
import moment from "moment";
import { Toast } from 'vant';
import { timeFormat } from "@/utils/timeCompute";
import { adjustFn } from './task-adjust-data'

export default {
  data() {
    return {
      // 用户信息
      userRole:{},
      // 通过用户编码获取对应角色的显示限制
      list:{},
      // 我的任务、下级任务 类型切换
      switchText:'0',
      // 指标筛选
      showTargetPicker:false,
      // 指标筛选 数据
      targetList:[],
      // 月份筛选
      showMonthPicker:false,
      // 月份筛选 数据
      monthList:[
        { text: "全部", value: "" },
        { text: "上月", value: "1" },
        { text: "本月", value: "2" },
        { text: "自定义", value: "3" },
      ],
      // 月份自定义弹窗
      showCustomMonth:false,
      // 月份自定义 开始时间
      startMonth:'',
      // 月份自定义 结束时间
      endMonth:'',
      // 自定义月份筛选
      showCustomMonthPicker:false,
      // 自定义月份筛选的最小时间
      minDate: new Date(2010, 0, 1),
      // 自定义月份筛选的最大时间
      maxDate: new Date(2025, 10, 1),
      // 自定义月份 默认时间
      customMonth: new Date(),
      // 自定义月份类型（区分开始、结束）
      customMonthType:'',
      // 分页 当前页数
      page:1,
      // 分页 当前条数
      limit:30,
      // 数据结果
      dataList:[],
      isLoading: false, // 是否正在加载数据
      // 是否有无数据
      isList:false,
      // 搜索栏搜索内容
      userName:''
    };
  },
  created() {
    this.userRole = JSON.parse(localStorage.getItem("USER_ROLE"));
    this.list = adjustFn(this.userRole.roleCode)
  },
  mounted() {
    this.getList()
  },
  methods: {
    // 路由返回
    back() {
      this.$router.go(-1);
    },
    //  我的任务、下级任务 类型切换
    switchTask(){
      this.switchText = this.switchText == '0'?'1':'0'
      this.userName = ''
      this.list.tabList.forEach((item,index)=>{
        if(item.code == 'target'){
          item.lable = ''
          item.value = ''
        }
        if(item.code == "month"){
          item.lable = ''
          item.value = ''
        }
      })
      this.dataList = []
      this.getList()
    },
    // 打开弹出
    screenFn(node){
      if(node.code == "target"){
        this.targetList = this.switchText == '0'?node.list:node.list1
        this.showTargetPicker = true
        if(node.value != ""){
          setTimeout(()=>{
            this.$refs.target&&this.$refs.target.setValues([node.value]);
          },0)
        }else{
          this.$refs.target&&this.$refs.target.setIndexes([0])
        }
      }else if(node.code == "month"){
        this.showMonthPicker = true
        if(node.value != ""){
          setTimeout(()=>{
            if( node.text != '自定义'){
              this.$refs.month&&this.$refs.month.setValues([node.lable]);
              this.startMonth = ""
              this.endMonth = ""
            }else{
              this.$refs.month&&this.$refs.month.setValues(['自定义']);
              this.startMonth = node.value.split("~")[0]
              this.endMonth = node.value.split("~")[1]
            }
          },0)
        }else{
          this.$refs.month&&this.$refs.month.setIndexes([0])
          this.startMonth = ""
          this.endMonth = ""
        }
      }
    },
    // 指标 确认
    onConfirmTarget(value){
      this.list.tabList.forEach((item,index)=>{
        if(item.code == 'target'){
          item.lable = value.text
          item.value = value.text
        }
      })
      this.page = 1
      this.dataList = []
      this.getList()
      this.$forceUpdate()
      this.showTargetPicker = false
    },
    // 月份 确认
    onConfirmMonth(value){
      if(value.value == '3'){
        this.showCustomMonth = true
      }else{
        this.list.tabList.forEach((item,index)=>{
          if(item.code == 'month'){
            item.lable = value.text
            if(value.value == "1"){
              item.value = moment().subtract(1, 'months').format('YYYY-MM')+"~"+moment().subtract(1, 'months').format('YYYY-MM');
            }else if(value.value == "2"){
              item.value = moment().format('YYYY-MM')+"~"+moment().format('YYYY-MM');
            }else{
              item.value = value.value
            }
          }
        })
        this.page = 1
        this.dataList = []
        this.getList()
        this.$forceUpdate()
        this.showMonthPicker = false
      }
    },
    // 打开 月份自定义赋值 (判断开始时间/结束时间)
    openChooseMonth(type){
      this.customMonthType = type
      let date
      if(type == "1"){
        date = this.startMonth ? moment(this.startMonth).format('YYYY-MM') : moment().format('YYYY-MM')
      }else if(type == "2"){
        date = this.endMonth ? moment(this.endMonth).format('YYYY-MM') : moment().format('YYYY-MM')
      }
      this.customMonth = new Date(date)
      this.showCustomMonthPicker = true
    },
    //月份自定义弹窗关闭
    customMonthRestSearch() {
      this.showCustomMonth = false
    },
    //确认搜索
    customMonthSureSearch() {
      if(this.startMonth == "" || this.endMonth == ""){
        Toast('开始时间和结束时间不能为空');
        return false
      }
      if((moment(this.startMonth).year() - moment(this.endMonth).year()) * 12 + moment(this.startMonth).month() - moment(this.endMonth).month() < -24){
        Toast('月份跨度最多为2年(24个月)');
        this.startMonth = ''
        this.endMonth = ''
        return false
      }
      if((moment(this.startMonth).year() - moment(this.endMonth).year()) * 12 + moment(this.startMonth).month() - moment(this.endMonth).month() > 0){
        Toast('结束时间不能超过开始时间');
        this.startMonth = ''
        this.endMonth = ''
        return false
      }
      this.list.tabList.forEach((item,index)=>{
        if(item.code == 'month'){
          item.lable = '自定义'
          item.value = this.startMonth+"~"+this.endMonth
        }
      })
      this.page = 1
      this.dataList = []
      this.getList()
      this.showMonthPicker = false
      this.showCustomMonth = false
      this.showCustomMonthPicker = false
    },
    // 时间筛选弹窗格式调整
    dateFormatter(type, val) {
      if (type === 'year') {
        return `${val}年`;
      } else if (type === 'month') {
        return `${val}月`;
      }else if (type === 'day') {
        return `${val}日`;
      }
      return val;
    },
    // 月份自定义赋值 (判断开始时间/结束时间)
    onConfirmCustomMonth(value){
      let month = timeFormat(value).slice(0,-3)
      if(this.customMonthType == "1"){
        this.startMonth = month
      }else if(this.customMonthType == "2"){
        this.endMonth = month
      }
      this.showCustomMonthPicker = false
    },
    // 下拉 加载更多
    async handleScroll(e) {
      const el = e.target
      if ( (el.scrollTop + el.clientHeight)+1 >= el.scrollHeight && !this.isLoading && this.dataList.length >= this.limit) {
        this.page++; // 加载下一页数据
        this.loading = true
        await this.getList();
        this.loading = false
      }
    },
    // 搜索栏 搜索
    searchUser(){
      this.dataList = []
      this.getList()
    },
    // 获取数据
    async getList(){
      // ------参数--------
      let headline = '' //指标类型
      let taskDate = '' //月份
      this.list.tabList.forEach((item,index)=>{
        if(item.code == 'target'){
          headline = item.lable == "全部"?'':item.lable
        }
        if(item.code == "month"){
          taskDate = item.value
        }
      })
      let params = {
        page:this.page,
        limit:this.limit,
        taskType:this.switchText,
        headline,
        taskDate,
        afterData:this.userName,
      }
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      await api.getAdjustRecordList(params).then((res)=>{
        if(res.code == 200){
          Toast.clear()
          res.data.forEach((item,index)=>{
            item.presentTask&&item.presentTask.forEach((item1,index1)=>{
              if(item1.detailList&&item1.detailList.length > 0){
                item1.expandBtn = true
              }
            })
            item.rawTask&&item.rawTask.forEach((item2,index2)=>{
              if(item2.detailList&&item2.detailList.length > 0){
                item2.expandBtn = true
              }
            })
          })
          this.dataList.push(...res.data)
        }else{
          Toast.fail(res.msg)
        }
      }).finally(()=>{
        this.isList = this.dataList.length > 0?false:true
      })
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep {
  .headNav-righ-text {
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    i{
      padding-top:2px;
    }
  }

  .content{
    height: 100%;

    .search{
      width:100%;
      padding:8px;
      background: #FFFFFF;
      box-sizing: border-box;
      .van-field{
        background: #F9F9FA;
        border-radius: 4px 4px 4px 4px;
      }
      .search-icon{
        display: flex;
        align-items: center;
        width: 14px;
        height: 14px;
      }
    }

    .screen{
      height: 46px;
      padding: 0 16px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 15px;
      color: #333333;
      background: #FFFFFF;
      margin-bottom: 8px;
      .screen-item{
        width: 25%;
        display: flex;
        align-items: center;
        justify-content: center;
        .screen-item-date{
          display: inline-block;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 12px;
          color: #333333;
          vertical-align: middle;
        }
        img{
          width: 14px;
          height: 14px;
          margin-left: 2px;
        }
      }
    }
    .info-list{
      height: calc(100vh - 106px);
      overflow: auto;

      .info-list-item{
        margin-bottom: 8px;
        background: #FFFFFF;
        padding: 8px 12px;
        border-radius: 8px 8px;
        .info-list-item-title{
          height: 32px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 8px;
          .title-name{
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 18px;
            color: #1D2129;
            line-height: 32px;
            .market{
              background: rgba(242,109,12,0.1);
              padding: 0 4px;
              font-family: PingFang SC, PingFang SC;
              font-weight: 500;
              font-size: 15px;
              color: #F26D0C;
              line-height: 32px;
              margin-right: 4px;
            }
          }
          .item-time{
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #333333;
          }
        }
        .info-list-item-content{
          .info-list-cell{
            min-height: 32px;
            display: flex;
            margin-bottom: 8px;
            .info-list-lable{
              width: 70px;
              min-width: 70px;
              line-height: 32px;
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              font-size: 16px;
              color: #545454;
              text-align: left;
              margin-right: 8px;
            }
            .info-list-value{
              width: calc(100% - 70px);
              line-height: 32px;
              font-family: PingFang SC, PingFang SC;
              font-weight: 500;
              font-size: 16px;
              color: #1D2129;
              text-align: left;
              span{
                font-size: 12px;
              }
            }
            .info-list-target-content{
              width: 100%;
              height: 100%;
              display: flex;
              flex-wrap: wrap;
              div{
                font-family: PingFang SC, PingFang SC;
                font-weight: 500;
                font-size: 18px;
                color: #8C8C8C;
                padding: 0 4px;
                background: #F9F9F9;
                margin: 0 8px 8px 0;
              }
            }
            .info-list-person-height{
              max-height: 167px;
              overflow: hidden;
            }
            .info-list-person-content{
              display: flex;
              justify-content: space-between;
            }
          }
          .info-list-target{
            margin:0;
          }
          .expand-person{
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 16px;
            color: #333333;
            line-height: 19px;
          }

          .info-list-item-modify{
            display: flex;
            justify-content: space-between;
            .item-left{
              width: 49.5%;
              background: rgba(242,242,242,0.3);
              border-radius: 4px 4px 4px 4px;
              padding: 12px 8px;
              box-sizing: border-box;
              .item-modify-name{
                font-family: PingFang SC, PingFang SC;
                font-weight: 500;
                font-size: 16px;
                color: #393D44;
                text-align: left;
              }
              .item-modify-item{
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                .item-modify-target{
                  font-family: PingFang SC, PingFang SC;
                  font-weight: 400;
                  font-size: 14px;
                  color: #545454;
                }
                .item-modify-num{
                  font-family: PingFang SC, PingFang SC;
                  font-weight: 500;
                  font-size: 16px;
                  color: #333333;
                  max-width: 50%;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  span{
                    font-size: 12px;
                  }
                }
              }
            }
            .item-right{
              width: 49.5%;
              background: rgba(242,242,242,0.3);
              border-radius: 4px 4px 4px 4px;
              padding: 12px 8px;
              box-sizing: border-box;
              .item-modify-name{
                font-family: PingFang SC, PingFang SC;
                font-weight: 500;
                font-size: 16px;
                color: #393D44;
                text-align: left;
              }
              .item-modify-item{
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                .item-modify-target{
                  font-family: PingFang SC, PingFang SC;
                  font-weight: 400;
                  font-size: 14px;
                  color: #545454;
                }
                .item-modify-num{
                  font-family: PingFang SC, PingFang SC;
                  font-weight: 500;
                  font-size: 16px;
                  max-width: 50%;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  span{
                    font-size: 12px;
                  }
                }
              }
            }
            .item-modify-item-parent,.item-modify-item-child{
              display: flex;
              justify-content: space-between;
              width: 100%;
            }
            .item-modify-item-parent{
              margin-top: 12px;
            }
            .item-modify-item-child-item{
              margin-top: 12px;
              width: 100%;
              display: flex;
              justify-content: space-between;
            }
            .item-modify-target{
              text-align: left;
              width: 45%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
          .info-list-item-setup{
            width: 100%;
            
            .item-setup-name{
              font-family: PingFang SC, PingFang SC;
              font-weight: 500;
              font-size: 16px;
              color: #393D44;
              text-align: left;
              line-height: 32px;
            }
            .item-setup-item{
              display: flex;
              justify-content: space-between;
              flex-wrap: wrap;
              padding-left: 8px;
              .item-setup-target{
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                font-size: 14px;
                color: #545454;
                line-height: 32px;
              }
              .item-setup-num{
                font-family: PingFang SC, PingFang SC;
                font-weight: 500;
                font-size: 16px;
                color: #333333;
                line-height: 32px;
                span{
                  font-size: 12px;
                }
              }
              .item-setup-item-parent,.item-setup-item-child{
                display: flex;
                justify-content: space-between;
                width: 100%;
              }
              .item-setup-item-child{
                padding-left: 8px;
              }
            }
          }

          .item-expand-btn{
            display: inline-block;
            margin-left: 4px;
          }
        }
      }

      .no-data {
        height: 100%;
        img {
          margin: 50% auto 0;
          width: 210px;
          height: 137px;
        }

        div {
          font-size: 16px;
          font-family: Bahnschrift, Bahnschrift;
          font-weight: 400;
          color: rgba(51, 51, 51, 0.5);
        }
      }
    }
    .info-list1{
      height: calc(100vh - 165px);
    }
  }

  .custom{
    p{
      color: #2c3e50;
      font-family: Noto Sans SC;
      font-size: 16px;
      font-weight: 500;
      margin: 0;
      padding-left: 20px;
      line-height: 46px;

    }
    .custom-date {
      height: 40px;
      display: flex;
      justify-content: center;
      border: 1px solid #696969;
      border-radius:10px 10px;
      margin: 10px 20px 20px;
      & > div {
        width: 40%;
        line-height: 40px;
        text-align: center;
        font-size: 16px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #999999;
      }
      .custom-date-interval {
        width: 10%;
        color: #000000;
      }
      .custom-date-content {
        color: #000000;
      }
    }
    .custom-footer {
      width: 100%;
      background: #FFFFFF;
      box-shadow: 0px -1px 8px 0px rgba(0, 0, 0, 0.04);
      display: flex;
      justify-content: space-around;
      align-items: center;
      .btn {
        width: 164px;
        height: 46px;
        border-radius: 4px;
        font-size: 18px;
        margin: 10px 0 20px;
      }
    }
  }
}
</style>