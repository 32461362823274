<template>
    <div class="main_box">
        <headerNav title="消息详情" @leftClick="back"></headerNav>
        <div class="height_style">
            <div class="info_main">
                <div class="info_nav">
                    <div class="info_leftS">
                        <div class="info_leftS_tit1">{{ msgConent.messageSuggest }}</div>
                        <div class="info_leftS_tit2">
                            <div class="info_leftS_tit2L">合同号</div>
                            <div class="info_leftS_tit2R">{{ customerDetails.contractNo }}</div>
                        </div>
                    </div>
                </div>
                <div class="info_content">
                    <div class="cont_main">
                        <div class="cont_flex">
                            <div class="flex_a">客户姓名:</div>
                            <div class="flex_b">{{ customerDetails.consumerName }}</div>
                        </div>
                        <div class="cont_flex">
                            <div class="flex_a">地址:</div>
                            <div class="flex_b">{{ customerDetails.detailAddress }}</div>
                        </div>
                        <div class="cont_flex">
                            <div class="flex_a">计价面积:</div>
                            <div class="flex_b">{{ customerDetails.priceArea }}</div>
                        </div>
                        <div class="cont_flex">
                            <div class="flex_a">提交人:</div>
                            <div class="flex_b">{{ customerDetails.transferName }}</div>
                        </div>
                        <div class="cont_flex">
                            <div class="flex_a">选材单状态:</div>
                            <div class="flex_b">{{ customerDetails.chooseStatusName }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bottom">
                <img src="@/assets/selectlistdetails.png" alt="">
                <p>选材单详情请到WEB端查看</p>
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/api/api.js'
import api2 from '@/api/dataBoard.js'

export default {
    components: {
    },
    data () {
        return {
            id: '',
            logId: '',
            messageId: '',
            orderNoticeType: '',
            isSub: false,
            dataTable: [],
            // 详情传值
            customerDetails: {},
            // 显示提交或者下单分包
            isShowPlace: '',
            // 销售单id
            customerId: '',
            // 消息内容
            msgConent: {},

        }
    },
    props: {

    },
    methods: {
        back () {
            this.$router.go(-1)
        },

        // 切换收起展开
        handleChangeSub () {
            this.isSub = !this.isSub
        },
        // 查询详情
        async handleDetails () {
            const {data} = await api.querySelectDetails(this.$route.params.budgetId)
            this.customerDetails = data
            
            let res = await api2.messageApi(this.logId)
            this.msgConent = res.data
        },
        // 产品选择弹出层
        handleSelectProduct () {
            this.showSelectproduct = true
        },
        // 选择产品
        handleSelectChange (item) {
            this.action.activeValue = item.id
            this.action.label = item.idName
            console.log(this.action.activeValue,item.id,this.action.activeValue == item.id)
        },
        // 取消选择
        handleCancel () {
            this.showSelectproduct = false
        },
        // 确定
        handleConfirm () {

        }
    },
    mounted () {

    },
    created () {
        this.id = this.$route.params.id
        this.logId = this.$route.params.logId
        this.messageId = this.$route.params.messageId
        this.handleDetails()
    }
}
</script>

<style lang="scss" scoped>
.main_box {
    background: #F5F5F5;
    height: calc(100vh - 90px);

    .color_blue {
        color: #3975C6;
    }

    .color_red {
        color: #EB5757;
    }

    .info_main {
        text-align: left;
        background: #3975C6;
        color: #fff;
        padding: 12px;
        font-family: PingFang SC-Heavy, PingFang SC;

        .info_nav {
            display: flex;
            align-items: center;

            .info_leftS {
                flex: 1;

                .info_leftS_tit1 {
                    font-size: 18px;
                    line-height: 21px;
                    font-weight: bold;
                }

                .info_leftS_tit2 {
                    margin-top: 10px;
                    height: 22px;
                    display: flex;
                    align-items: center;

                    .info_leftS_tit2L {
                        width: 41px;
                        height: 17px;
                        line-height: 17px;
                        font-size: 11px;
                        color: #3975C6;
                        background: #FFFFFF;
                        border-radius: 1px;
                        text-align: center;
                    }

                    .info_leftS_tit2R {
                        margin-left: 8px;
                        height: 21px;
                        font-size: 15px;
                    }
                }
            }

            .info_right {
                margin-left: 10px;
                width: 48px;
                height: 48px;
            }
        }

        .info_content {
            margin-top: 16px;

            .cont_main {
                border-radius: 4px;
                background: #2569C3;

                .cont_flex {
                    display: flex;
                    justify-content: space-between;
                    color: #FFFFFF;
                    padding: 8px;
                    font-family: PingFang SC-Regular, PingFang SC;

                    .flex_a {
                        width: 80px;
                        font-size: 14px;
                    }

                    .flex_b {
                        max-width: calc(100% - 80px);
                        font-size: 16px;
                        text-align: right;
                    }
                }

                .cont_flex_img {
                    display: flex;
                    justify-content: space-between;

                    .flex_text {
                        white-space: nowrap;
                    }

                    .flex_img {
                        display: flex;
                        flex-wrap: wrap;
                        width: 232px;

                        .img {
                            // display: inline-block;
                            margin: 0px 6px 6px 6px;
                        }
                    }
                }
            }

            .content_bottom {
                width: 100%;
                color: #ECF4FF;
                font-size: 12px;
                margin: 12px 0px 0px 0px;

                .flex {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-family: PingFang SC-Regular, PingFang SC;
                }

                .jiantou {
                    transform: rotate(180deg);
                    transition: all 0.3s;
                }
            }
        }

    }

    .height_style {
        height: calc(100vh - 50px);
        overflow: auto;
    }

    .bottom{
        height: calc(100vh - 400px);
        margin-top: 16px;
        background: #fff;
        text-align: center;
        img{
            width: 220px;
            height: 124px;
            margin-top: 50px;
        }
        p{
            font-size: 16px;
            color: #333333;
            font-family: PingFang SC-Regular, PingFang SC;
        }
    }

}</style>