<template>
    <div class="myClientDetails">
        <headerNav title="客户详情" />
        <div class="content">
            <div class="name">{{ clientInfo.consumerName }}<span>{{ clientInfo.gradeCode ? clientInfo.gradeCode + '级' : ''
            }}</span></div>
            <!-- <div class="phone">{{ clientInfo.phoneNo }}</div> -->
            <div class="info-item" v-if="clientInfo.phoneNo">
                <span class="item-title">手机号</span>
                <span class="item-value">{{ clientInfo.phoneNo }}</span>
            </div>
            <div class="info-item" v-if="clientInfo.wechatNo">
                <span class="item-title">微信号</span>
                <span class="item-value">{{ clientInfo.wechatNo }}</span>
            </div>
            <div class="info-item" v-if="clientInfo.landlineNo">
                <span class="item-title">固话</span>
                <span class="item-value">{{ clientInfo.landlineNo }}</span>
            </div>
            <div class="info-item">
                <span class="item-title">邀约码</span>
                <span class="item-value">{{ clientInfo.inviteCode }}</span>
            </div>
            <div class="info-item">
                <span class="item-title">渠道</span>
                <span class="item-value">{{ clientInfo.channelName }}</span>
            </div>
            <div class="info-item">
                <span class="item-title">到店时间</span>
                <span class="item-value">
                    {{ clientInfo.shopTime.slice(0, 10) }}
                    &nbsp;&nbsp;
                    {{ clientInfo.shopTime.slice(10) }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import api from '../../../api/api.js'
export default {
    created () {
        this.id = this.$route.params.id
        this.getClientInfoC()
    },
    data () {
        return {
            id: '',//客户id
            clientInfo: {},
        }
    },
    methods: {
        //获取客户信息
        async getClientInfoC () {
            let res = await api.getClientInfoC(this.id)
            if (res.code == 200) {
                this.clientInfo = res.data
            }
        },
    },
}
</script>

<style scoped lang="scss">
.content {
    text-align: left;
    padding: 20px 20px;

    .name {
        text-align: left;
        font-size: 24px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
        line-height: 33px;
        position: relative;
        margin-bottom: 40px;

        span {
            position: absolute;
            right: 0;
            font-size: 24px;
            color: #4871C0;
            line-height: 33px;
        }
    }

    .phone {
        margin-top: 8px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 20px;
    }

    .info-item {
        display: flex;
        width: 100%;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        line-height: 20px;
        padding: 15px 0;

        span {
            flex: 1;
            display: flex;
        }

        .item-title {
            justify-content: flex-start;
            color: #999999;
        }

        .item-value {
            justify-content: flex-end;
            color: #000000;
        }
    }
}
</style>

