<template>
  <div>
    <!-- 经理--专员详情组件 -->
    <div class="content-element">

      <!-- 标题 -->
      <div class="content-element-top" @click="onClick">
        <div class="content-element-top-left">
          <div class="img"><img :src="require(`@/assets/task-management/${item.termId}.png`)" alt=""></div>
          <div class="text">{{ item.termName }}</div>
          <div v-if="targetType == '1'" class="city-style">市场</div>
          <div class="date">{{ item.taskTime }}</div>
        </div>
        <div class="content-element-arrows">
          <img src="@/assets/task-management/task-setup-finance-arrows.png" alt="">
        </div>
      </div>

      <!-- 指标内容 -->
      <div class="content-element-bottom" v-if="!taskObj.isSingleIndicator">
        <div class="content-element-bottom-left content-element-bottom-el">
          <div class="count">{{ item[taskObj.field1] || '-' }}<span>{{ taskObj.leftUnit }}</span></div>
          <div class="text">{{ taskObj.taskTarget1 }}</div>
        </div>
        <div class="content-element-bottom-right content-element-bottom-el" v-if="taskObj.taskTarget2">
          <div class="content-element-bottom-right-el" v-if="taskObj.taskTarget2">
            <div class="count">{{ item[taskObj.field2] || '-' }}<span>{{ taskObj.rightUnit }}</span></div>
            <div class="text">{{ taskObj.taskTarget2 }}</div>
          </div>
          <div class="content-element-bottom-right-el" v-if="taskObj.taskTarget3">
            <div class="count">{{ item[taskObj.field3] || '-' }}<span>{{ taskObj.rightUnit }}</span></div>
            <div class="text">{{ taskObj.taskTarget3 }}</div>
          </div>
        </div>
      </div>

      <!-- 适配一个指标展示的内容 -->
      <div class="content-element-bottom-one" v-else>
        <div class="text">最终得分：</div>
        <div class="count">{{ item.reality || '-' }}<span>分</span></div>
      </div>
    </div>
  </div>
</template>

<script>
import { estimateOutputTarget } from '../data.js'
export default {
  name: 'MajordomoItem',
  props: {
    item: {
      type: Object,
      default: {}
    },
    // 判断是哪个指标
    targetType: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      taskObj: {}
    }
  },
  watch: {
    targetType (newValue, oldValue) {
      this.updatedTargetType()
    }
  },
  created () {
    this.updatedTargetType()
  },
  methods: {
    updatedTargetType () {
      this.taskObj = estimateOutputTarget(this.item.termId)
    },
    onClick () {
      this.$emit('onClick')
    }
  }
}
</script>

<style lang="scss" scoped src="./index.scss"></style>
<style lang="scss" scoped>
.city-style {
  width: 44px;
  height: 22px;
  line-height: 22px;
  background: rgba(242, 109, 12, 0.1);
  color: #F26D0C;
  font-size: 18px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  margin: 0px 12px 0px 8px;
  border-radius: 3px;
}
</style>