<template>
    <div class="finishUpProject-style">
        <headerNav title="待上传" leftNav="返回" @leftClick="cancel"></headerNav>
        <div class="content-style">
            <div class="upPhoto-style">
                <div class="input-text">
                    <span style="color:red">* </span>
                    <span>现场照片：</span>
                    <span style="font-size: 12px;color: #999;">最多上传30张,.PNG/.JPG</span>
                </div>
                <div class="up-style">
                    <van-uploader :after-read="afterRead" multiple :max-count="30" :max-size="10 * 1024 * 1024"
                        v-model="fileList">
                        <div class="up-style-img">
                            <img :src="require('@/assets/camera.png')" alt="" width="20px" />
                            <span>上传图片</span>
                        </div>
                    </van-uploader>
                </div>
            </div>

            <div class="textarea-style">
                <div class="input-text">备注信息：</div>
                <div style="flex:1">
                    <van-field class="input-style" v-model="remark" rows="3" autosize maxlength="200" show-word-limit
                        type="textarea" name="备注信息" placeholder="请输入" />
                </div>
            </div>

            <div class="submit-box">
                <van-button type="primary" block color="#4871C0" @click="onSubmit">上传</van-button>
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/api/api.js'
import { Toast } from 'vant';
export default {
    data() {
        return {
            clientInfo: {},
            fileList: [],
            remark: '',
        }
    },
    methods: {
        async afterRead(file) {
            const fileList = Array.isArray(file) ? file : [file]
            fileList.forEach(async (e, index) => {
                const indexR = this.fileList.length - fileList.length + index
                this.fileList[indexR].status = 'uploading'
                // 此时可以自行将文件上传至服务器
                const formData = new FormData();
                formData.append('files', e.file);
                formData.append('dir', 'oss');
                const res = await api.upFile(formData)
                if (res.code == 200) {
                    this.fileList[indexR].url = res.url
                    this.fileList[indexR].status = 'done'
                } else {
                    this.fileList[indexR].status = 'failed'
                }
            })
        },
        async onSubmit() {
            if (this.fileList.length > 0) {
                const isUplod = this.fileList.some(item => item.status === 'uploading')
                if (!isUplod) {
                    const obj = {
                        sellDivideId: this.clientInfo.id,
                        imgUrlList: this.fileList.filter(item => item.status === 'done').map(item => item.url),
                        remark: this.remark,
                    }
                    const res = await api.addSubpackageImgDetail(obj)
                    if (res.code == 200) {
                        Toast.success('提交成功！')
                        this.cancel()
                    }
                } else {
                    Toast.fail('图片上传未完成，请等待...')
                }
            } else {
                Toast.fail('请上传图片！')
            }
        },
        async onGetDetail() {
            const res = await api.getSubpackageImgDetail(this.clientInfo.id)
            if (res.code == 200) {
                const { imgUrlList = [], remark } = res.data
                this.fileList = imgUrlList.map(item => ({ url: item, status: 'done' }))
                this.remark = remark
            }
        },
        cancel() {
            this.$router.push({ name: 'engineeringConstructionName' })
        },
    },
    created() {
        this.clientInfo = JSON.parse(sessionStorage.getItem('clientInfo1'))
        this.onGetDetail()
    },
    mounted() {
        const width1 = window.screen.width - (16 * 2 + 24)
        document.getElementsByClassName('up-style-img')[0].style.width = width1 / 3 - 8 + 'px'
        document.getElementsByClassName('up-style-img')[0].style.height = width1 / 3 - 8 + 'px'
    },
    watch: {
        fileList(newVal) {
            if (newVal.length) {
                newVal.forEach((_, index) => {
                    this.$nextTick(() => {
                        const width1 = window.screen.width - (16 * 2 + 24)
                        document.getElementsByClassName('van-uploader__preview-image')[index].style.width = width1 / 3 - 8 + 'px'
                        document.getElementsByClassName('van-uploader__preview-image')[index].style.height = width1 / 3 - 8 + 'px'
                    })
                })
            }
        }
    }
}
</script>

<style scoped lang="scss">
.finishUpProject-style {

    .content-style {
        height: calc(100vh - 120px);
        padding-bottom: 70px;
        overflow: auto;
        background-color: #fff;
        text-align: left;
        font-size: 16px;
    }

    .upPhoto-style {
        margin-top: 8px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .input-text {
            height: 32px;
            line-height: 32px;
            padding: 0 16px;
        }

        .up-style {
            padding-left: 28px;

            .up-style-img {
                border: 1px dashed #eee;
                background-color: #F8FAFF;
                border-radius: 5px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                margin: 0 8px 8px 0;

                span {
                    margin-top: 10px;
                }
            }

            ::v-deep .van-uploader__preview-image {
                border-radius: 5px;
                overflow: hidden;
            }

            ::v-deep .van-uploader__preview-delete {
                width: 20px;
                height: 20px;
                top: 8px;
                right: 8px;
                border-radius: 100%;

                .van-uploader__preview-delete-icon,
                .van-icon {
                    font-size: 24px;
                }
            }
        }
    }


    .textarea-style {
        padding: 0 16px 0 28px;

        .input-style {
            margin-top: 8px;
            padding: 8px;
            border-radius: 5px;
            background-color: #F8FAFF;
        }
    }

    .submit-box {
        width: calc(100% - 80px);
        padding: 0 40px;
        position: absolute;
        bottom: 18px;

        :deep(.van-button) {
            border-radius: 10px;
        }
    }
}
</style>
