// 人事总监 看板（市场）
let marketModel = [
  { name:'招聘试岗',type:'invite_job', leftText:'试岗人员', rightText:'任务人数', unit:'人', operate:'完成率',
    bgIcon:'human-resources-bg-icon',
    leftIcon:'human-resources-invite-job-left-icon',
    rightIcon:'human-resources-invite-job-right-icon',
    table:[
      {label:'试岗人数',prop:'finishValue', minWidth:"85"},
      {label:'任务人数',prop:'targetValue', minWidth:"85"},
      {label:'完成率',prop:'finishRate', minWidth:"70"},
    ],
    detailIcon:'last-month-completion-rate',
  },
  { name:'薪资提交',type:'pay_submit', leftText:'及时提交',rightText:'未及时提交', unit:'个', operate:'及时率',
    bgIcon:'human-resources-bg-icon',
    leftIcon:'human-resources-pay-submit-left-icon',
    rightIcon:'human-resources-pay-submit-right-icon',
    table:[
      {label:'提交时间',prop:'createDate', minWidth:"120",align:'left'},
      {label:'及时率',prop:'finishRate',prompt:'以每月6日18:00为截止时间', minWidth:"70"},
    ],
    detailIcon:'task-board-finance-table-expant-icon',
  },
  { name:'新员工培训',type:'employee_training',leftText:'已培训',rightText:'应培训', unit:'人', operate:'完成率',
    bgIcon:'human-resources-bg-icon',
    leftIcon:'human-resources-employee-training-left-icon',
    rightIcon:'human-resources-employee-training-right-icon',
    table:[
      {label:'已培训',prop:'finishValue', minWidth:"85"},
      {label:'应培训',prop:'targetValue', minWidth:"85"},
      {label:'完成率',prop:'finishRate', minWidth:"70"},
    ],
    detailIcon:'last-month-completion-rate',
  },
  { name:'费用报销',type:'cost_payment', leftText:'已及时报销',rightText:'应及时报销', unit:'笔', operate:'及时率',
    bgIcon:'human-resources-bg-icon',
    leftIcon:'human-resources-cost-payment-left-icon',
    rightIcon:'human-resources-cost-payment-right-icon',
    table:[
      {label:'已及时报销',prop:'finishValue', minWidth:"85"},
      {label:'应及时报销',prop:'targetValue', minWidth:"85"},
      {label:'及时率',prop:'finishRate', minWidth:"70"},
    ],
    detailIcon:'task-board-finance-table-expant-icon',
  },
  { name:'行政大巡检',type:'admin_inspection',leftText:'已巡检次数',rightText:'应巡检次数', unit:'次', operate:'完成率',
    bgIcon:'human-resources-bg-icon',
    leftIcon:'human-resources-admin-inspection-left-icon',
    rightIcon:'human-resources-admin-inspection-right-icon',
    table:[
      {label:'已巡检(次)',prop:'finishValue', minWidth:"85"},
      {label:'应巡检(次)',prop:'targetValue', minWidth:"85"},
      {label:'完成率',prop:'finishRate', minWidth:"70"},
    ],
    detailIcon:'last-month-completion-rate',
  },
  { name:'宿舍巡检',type:'dorm_inspection', leftText:'已巡检次数',rightText:'应巡检次数', unit:'次', operate:'完成率',
    bgIcon:'human-resources-bg-icon',
    leftIcon:'human-resources-dorm-inspection-left-icon',
    rightIcon:'human-resources-dorm-inspection-right-icon',
    table:[
      {label:'已巡检(次)',prop:'finishValue', minWidth:"85"},
      {label:'应巡检(次)',prop:'targetValue', minWidth:"85"},
      {label:'完成率',prop:'finishRate', minWidth:"70"},
    ],
    detailIcon:'last-month-completion-rate',
  },
]

export const marketModelFn = (params) => {
  let data = {}
  marketModel.filter(item => {
    if (item.type == params) {
      data = item
    }
  })
  return data
};

// 人事经理 看板（人员）
let employeeModel = [
  { name:'招聘试岗',type:'invite_job', leftText:'试岗人员', rightText:'任务人数', unit:'人', operate:'完成率',
    bgIcon:'human-resources-bg-icon',
    leftIcon:'human-resources-invite-job-left-icon',
    rightIcon:'human-resources-invite-job-right-icon',
    table:[
      {label:'试岗人数',prop:'finishValue', minWidth:"85"},
      {label:'任务人数',prop:'targetValue', minWidth:"85"},
      {label:'完成率',prop:'finishRate', minWidth:"70"},
    ],
    detailIcon:'last-month-completion-rate',
  },
  { name:'薪资提交',type:'pay_submit', leftText:'及时提交',rightText:'未及时提交', unit:'个', operate:'及时率',
    bgIcon:'human-resources-bg-icon',
    leftIcon:'human-resources-pay-submit-left-icon',
    rightIcon:'human-resources-pay-submit-right-icon',
    table:[
      {label:'提交时间',prop:'createDate', minWidth:"120",align:'left'},
      {label:'及时率',prop:'finishRate',prompt:'以每月6日18:00为截止时间', minWidth:"70",},
    ],
    detailIcon:'task-board-finance-table-expant-icon',
    showFoot:true,
  },
  { name:'新员工培训',type:'employee_training',leftText:'已培训',rightText:'应培训', unit:'人', operate:'完成率',
    bgIcon:'human-resources-bg-icon',
    leftIcon:'human-resources-employee-training-left-icon',
    rightIcon:'human-resources-employee-training-right-icon',
    table:[
      {label:'已培训',prop:'finishValue', minWidth:"85"},
      {label:'应培训',prop:'targetValue', minWidth:"85"},
      {label:'完成率',prop:'finishRate', minWidth:"70"},
    ],
    detailIcon:'last-month-completion-rate',
  },
  { name:'费用报销',type:'cost_payment', leftText:'已及时报销',rightText:'应及时报销', unit:'笔', operate:'及时率',
    bgIcon:'human-resources-bg-icon',
    leftIcon:'human-resources-cost-payment-left-icon',
    rightIcon:'human-resources-cost-payment-right-icon',
    table:[
      {label:'已及时报销',prop:'finishValue', minWidth:"85"},
      {label:'应及时报销',prop:'targetValue', minWidth:"85"},
      {label:'及时率',prop:'finishRate', minWidth:"70"},
    ],
    detailIcon:'task-board-finance-table-expant-icon',
  },
  { name:'行政大巡检',type:'admin_inspection',leftText:'已巡检次数',rightText:'应巡检次数', unit:'次', operate:'完成率',
    bgIcon:'human-resources-bg-icon',
    leftIcon:'human-resources-admin-inspection-left-icon',
    rightIcon:'human-resources-admin-inspection-right-icon',
    table:[
      {label:'已巡检(次)',prop:'finishValue', minWidth:"85"},
      {label:'应巡检(次)',prop:'targetValue', minWidth:"85"},
      {label:'完成率',prop:'finishRate', minWidth:"70"},
    ],
    detailIcon:'last-month-completion-rate',
  },
  { name:'宿舍巡检',type:'dorm_inspection', leftText:'已巡检次数',rightText:'应巡检次数', unit:'次', operate:'完成率',
    bgIcon:'human-resources-bg-icon',
    leftIcon:'human-resources-dorm-inspection-left-icon',
    rightIcon:'human-resources-dorm-inspection-right-icon',
    table:[
      {label:'已巡检(次)',prop:'finishValue', minWidth:"85"},
      {label:'应巡检(次)',prop:'targetValue', minWidth:"85"},
      {label:'完成率',prop:'finishRate', minWidth:"70"},
    ],
    detailIcon:'last-month-completion-rate',
  },
]

export const employeeModelFn = (params) => {
  let data = {}
  employeeModel.filter(item => {
    if (item.type == params) {
      data = item
    }
  })
  return data
};

// 人事专员 看板（详情）
let employeeSingleModel = [
  { name:'招聘试岗',type:'invite_job', leftText:'试岗人员', rightText:'任务人数', unit:'人', operate:'完成率',
    bgIcon:'human-resources-bg-icon',
    leftIcon:'human-resources-invite-job-left-icon',
    rightIcon:'human-resources-invite-job-right-icon',
    detailIcon:'human-resources-invite-job-detail-icon',
    showType:"1",
  },
  { name:'薪资提交',type:'pay_submit', leftText:'及时提交',rightText:'未及时提交', unit:'个', operate:'及时率',
    bgIcon:'human-resources-bg-icon',
    leftIcon:'human-resources-pay-submit-left-icon',
    rightIcon:'human-resources-pay-submit-right-icon',
    detailIcon:'human-resources-pay-submit-detail-icon',
    showFoot:true,
    showType:"3",
  },
  { name:'新员工培训',type:'employee_training',leftText:'已培训',rightText:'应培训', unit:'人', operate:'完成率',
    bgIcon:'human-resources-bg-icon',
    leftIcon:'human-resources-employee-training-left-icon',
    rightIcon:'human-resources-employee-training-right-icon',
    detailIcon:'human-resources-employee-training-detail-icon',
    showType:"2",
  },
  { name:'费用报销',type:'cost_payment', leftText:'已及时报销',rightText:'应及时报销', unit:'笔', operate:'及时率',
    bgIcon:'human-resources-bg-icon',
    leftIcon:'human-resources-cost-payment-left-icon',
    rightIcon:'human-resources-cost-payment-right-icon',
    detailIcon:'human-resources-cost-payment-detail-icon',
    showType:"2",
  },
  { name:'行政大巡检',type:'admin_inspection',leftText:'已巡检次数',rightText:'应巡检次数', unit:'次', operate:'完成率',
    bgIcon:'human-resources-bg-icon',
    leftIcon:'human-resources-admin-inspection-left-icon',
    rightIcon:'human-resources-admin-inspection-right-icon',
    detailIcon:'human-resources-admin-inspection-detail-icon',
    showType:"1",
  },
  { name:'宿舍巡检',type:'dorm_inspection', leftText:'已巡检次数',rightText:'应巡检次数', unit:'次', operate:'完成率',
    bgIcon:'human-resources-bg-icon',
    leftIcon:'human-resources-dorm-inspection-left-icon',
    rightIcon:'human-resources-dorm-inspection-right-icon',
    detailIcon:'human-resources-dorm-inspection-detail-icon',
    showType:"1",
  },
]

export const employeeSingleModelFn = (params) => {
  let data = {}
  employeeSingleModel.filter(item => {
    if (item.type == params) {
      data = item
    }
  })
  return data
};

// 提交上传
let uploadTaskModel = [
  { name:'招聘试岗',type:'invite_job', unit:'人',
    formList:[
      {label:'招聘任务',prop:'indexName',type:'text'},
      {label:'招聘人数',prop:'targetValue',type:'digit',unit:true},
      {label:'试岗人数',prop:'finishValue',type:'digit',unit:true,rules:true,maxlength:'3'},
    ]
  },
  { name:'薪资提交',type:'pay_submit', unit:'个',
    formList:[
      {label:'提交截止时间',prop:'prompt',},
    ]
  },
  { name:'新员工培训',type:'employee_training', unit:'人',
    formList:[
      {label:'应培训人数',prop:'targetValue',type:'digit',unit:true,rules:true,maxlength:'3'},
      {label:'已培训人数',prop:'finishValue',type:'digit',unit:true,rules:true,maxlength:'3'},
    ]
  },
  { name:'费用报销',type:'cost_payment', unit:'笔',
    formList:[
      {label:'应及时报销',prop:'targetValue',type:'digit',unit:true,rules:true,maxlength:'5'},
      {label:'已及时报销',prop:'finishValue',type:'digit',unit:true,rules:true,maxlength:'5'},
    ],maxlength:'3'
  },
  { name:'行政大巡检',type:'admin_inspection', unit:'次',
    formList:[
      {label:'应巡检次数',prop:'targetValue',type:'digit',unit:true,maxlength:'3'},
      {label:'已巡检次数',prop:'finishValue',type:'digit',unit:true,rules:true,maxlength:'3'},
    ]
  },
  { name:'宿舍巡检',type:'dorm_inspection', unit:'次',
    formList:[
      {label:'应巡检次数',prop:'targetValue',type:'digit',unit:true,maxlength:'3'},
      {label:'已巡检次数',prop:'finishValue',type:'digit',unit:true,rules:true,maxlength:'3'},
    ]
  },
]
export const uploadTaskModelFn = (params) => {
  let data = {}
  uploadTaskModel.filter(item => {
    if (item.type == params) {
      data = item
    }
  })
  return data
};

// 审批详情
let examineDetailModel = [
  { name:'招聘试岗',type:'invite_job', operate:'完成率',
    table:[
      {label:'任务项',prop:'termName',},
      {label:'招聘人数',prop:'targetValue',unit:'人',},
      {label:'试岗人数',prop:'finishValue',unit:'人',},
    ],
  },
  { name:'薪资提交',type:'pay_submit', operate:'及时率',
    table:[
      {label:'任务项',prop:'termName',},
    ],
  },
  { name:'新员工培训',type:'employee_training', operate:'完成率',
    table:[
      {label:'任务项',prop:'termName',},
      {label:'应培训人数',prop:'targetValue',unit:'人',},
      {label:'已培训人数',prop:'finishValue',unit:'人',},
    ],
  },
  { name:'费用报销',type:'cost_payment', operate:'及时率',
    table:[
      {label:'任务项',prop:'termName',},
      {label:'应及时报销',prop:'targetValue',unit:'笔',},
      {label:'已及时报销',prop:'finishValue',unit:'笔',},
    ],
  },
  { name:'行政大巡检',type:'admin_inspection', operate:'完成率',
    table:[
      {label:'任务项',prop:'termName',},
      {label:'已巡检次数',prop:'finishValue',unit:'次',},
      {label:'应巡检次数',prop:'targetValue',unit:'次',},
    ],
  },
  { name:'宿舍巡检',type:'dorm_inspection', operate:'完成率',
    table:[
      {label:'任务项',prop:'termName',},
      {label:'应巡检次数',prop:'targetValue',unit:'次',},
      {label:'已巡检次数',prop:'finishValue',unit:'次',},
    ],
  },
]
export const examineDetailModelFn = (params) => {
  let data = {}
  examineDetailModel.filter(item => {
    if (item.type == params) {
      data = item
    }
  })
  return data
};