<template>
  <div class="content">
    <van-sticky class="topMenu">
      <headerNav leftNav=" " :title="navTitle" @leftClick="back"></headerNav>
      
    </van-sticky>

    <main class="content-main">
      <!-- 步骤条 -->
      <TaskProgress
        :percentageMoney="taskPlanObj.practicalAccomplish"
        :showFoot="false"
      />

      <!-- 详情 -->
      <div class="content-center-title">
        <div class="left-line"></div>
        <div>详情</div>
      </div>

      <!-- table -->
      <el-table :data="userTableData" header-cell-class-name="market-title" row-class-name="market-row" row-key="userId">
        <!-- 自定义暂无数据 -->
        <template slot="empty">
          <div class="empty-no-data">
            <img src="../../../../assets/task-management/no-data.png" alt="">
            <div>暂无数据</div>
          </div>
        </template>
        <el-table-column type="index" label="TOP" align="center" width="40">
          <template slot-scope="props">
            <div>{{ props.$index + 1 }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="consumerName" label="客户名称" min-width="85" show-overflow-tooltip></el-table-column>
        <el-table-column prop="inviteTime" label="到店时间" align="left" min-width="140"></el-table-column>
        <el-table-column prop="personQty" label="到店人数" align="right" min-width="55"></el-table-column>
      </el-table>
    </main>

  </div>
</template>

<script>
import TaskProgress from "@/components/task-management/task-progress.vue"
import BoardNavSummarize from "@/views/task-management/component/public/module/BoardNavSummarize.vue"
import estimateOutput from "../../component/data.js"
import api from "@/api/task-management/board.js";
import { Toast } from "vant";

export default {
  components: {
    TaskProgress,
    BoardNavSummarize
  },
  data () {
    return {
      // 任务看板路由接参
      routerData: {},
      // 转单表格数据
      userTableData: [],
      // 表头动态展示内容
      outputCount: {},
      // 顶部任务产值目标
      taskPlanObj: {}
    }
  },
  created () {
    let localData = JSON.parse(localStorage.getItem('TABLE_HEADER_CONTENT'))
    let userData = JSON.parse(localStorage.getItem('USER_DATA'))
    this.routerData = { ...localData, ...userData }
    this.getTableData()
    this.outputCount = estimateOutput(localData.termSlug)
    this.navTitle = this.outputCount.transferProductionValue.slice(0, 4) + '详情'
  },
  methods: {
    back () {
      this.$router.go(-1)
    },
    // 查询表格数据
    async getTableData () {
      try {
        Toast.loading({
          message: '加载中...',
          forbidClick: true,
        });
        const { data } = await api.validShopCustomerDetailApi({Q_marketCode_EQ: this.routerData.marketCode})
        this.taskPlanObj = data.accomplishCondition ? data.accomplishCondition : {}
        this.userTableData = data.customerDetailVoList
      } finally {
        Toast.clear()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.content ::v-deep {
  .el-table__expand-icon>.el-icon {
    display: none;
  }

  .empty-no-data {
    img {
      width: 160px;
      height: 150px;
    }

    div {
      color: #5E5E5E;
      font-size: 14px;
      margin-top: -40px;
    }
  }

  .content-main {
    background: #F5F5F5;
    height: calc(100vh - 50px);
    overflow: auto;
    margin-top: -1px;

    .table-content {
      height: calc(100vh - 315px);
      overflow: auto;
    }

    .content-center-title {
      height: 47px;
      line-height: 47px;
      display: flex;
      align-items: center;
      background: #fff;
      padding: 0px 10px;
      margin-top: 16px;

      .left-line {
        width: 4px;
        height: 18px;
        background: #0E8BFF;
        margin-right: 8px;
      }

      div {
        color: #333333;
        font-size: 18px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: 550;
      }
    }

    .market-title {
      background-color: #F5F5F5;
      color: #1D2129;
      font-size: 14px;
      font-family: PingFang SC-Bold, PingFang SC;
      padding: 0px 0px;

      .cell {
        padding: 10px 3px;
        font-size: 13px;
        font-weight: 500;

      }
    }

    .el-table__body-wrapper {
      height: calc(100vh - 150px);
      overflow: auto;
    }


    .market-row {
      font-size: 16px;
      color: #1D2129;
      overflow: visible;

      .el-table__cell {
        padding: 3px 0px;
        height: 30px;
        overflow: visible;
      }

      .cell {
        padding: 5px 3px;
        font-weight: 400;
        overflow: visible;
        white-space: nowrap;

        .anomaly {
          .market-content-a {
            white-space: nowrap;
            // /* 不换行 */
            overflow: hidden;
            // /* 隐藏超出部分 */
            text-overflow: ellipsis;
            display: block;
          }

        }

      }
    }

    .el-table__expand-colum {
      visibility: hidden;
    }

    .market-details {
      color: #0E8BFF;
      font-size: 13px;
    }

    .market-content-a {
      color: #0E8BFF;
      font-size: 13px;
      font-weight: 400;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
    }

    .anomaly {
      position: relative;

      .anomaly-bg {
        height: 19px;
        width: 39px;
        text-align: center;
        color: #fff;
        position: absolute;
        font-size: 12px;
        top: -8px;
        right: -25px;
        line-height: 19px;
        background: url('../../../../assets/task-management/employee-list-anomaly.png') no-repeat;
        background-position: 100%;
        background-size: cover;
        z-index: 10;
      }
    }

    .index-img {
      width: 20px;
      height: 20px;
    }

    .market-content-expand {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 11px 16px;
      // border-bottom: 1px solid #F1F1F1;

      .market-content-expand-left {
        display: flex;
        align-items: center;

        img {
          width: 20px;
          height: 20px;
        }

        div {
          color: #545454;
          font-size: 15px;
          margin-left: 10px;
        }
      }

      .market-content-expand-right {
        width: 46px;
        height: 22px;
        line-height: 22px;
        font-size: 13px;
        text-align: center;
        font-weight: bold;
        font-family: PingFang SC-Bold, PingFang SC;

        span {
          padding: 2px 3px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: visible;
          font-weight: 500;
          border-radius: 4px;
        }

        .color-red {
          color: #FF6157;
          background: #FAEBEA;
        }

        .color-green {
          color: #17CA5E;
          background: #E3F5EA;
        }
      }
    }

    .market-content-expand-row {
      display: flex;
      justify-content: end;
      margin: 0px 11px 4px;
      padding-top: 12px;
      border-top: 1px solid #F1F1F1;

      .market-content-expand-seting {
        display: flex;
        align-items: center;
        width: 91px;
        height: 28px;
        line-height: 28px;
        border: 1px solid #3DA2FF;
        color: #0E8BFF;
        font-size: 14px;
        border-radius: 4px;
        padding-left: 6px;
        box-sizing: border-box;
        cursor: pointer;
        user-select: none;

        img {
          width: 18px;
          height: 18px;
          margin: 1px 2px 0px 0;
        }
      }
    }


  }

  .set-task-dialog {
    .set-task-dialog-nav {
      text-align: center;
      margin: 12px 0px;
      padding: 0 17px 12px 17px;
      font-weight: bold;
      color: #424242;
      font-size: 16px;
      border-bottom: 1px solid #DFDFDF;

      .set-task-dialog-nav-close {
        float: right;
        font-size: 15px;
      }
    }

    .set-task-dialog-mian {
      padding: 16px 0 0;

      .van-field__body {
        border-bottom: 1px solid #BEBEBE;
      }

      .van-field__label {
        margin-right: 0;
        color: #252523;
        font-size: 16px;
      }

      .van-cell::after {
        border: 0;
      }

      .set-task-dialog-foot {
        // width: 100%;
        text-align: center;
        padding: 0px 14px 18px 15px;
        margin-top: 16px;

        .btn {
          border-radius: 4px;
          // width: 290px;
          // height: 44px;
          background: #3975C6;
        }
      }

    }

  }

}
</style>