<template>
    <div class="main">
        <div class="main_cont">
            <p class="p_1"><span class="span_p">合同号</span>{{ msgInfo.contractNo }}</p>
            <p class="p_2">
                <span class="span_left">{{ msgConent.messageSuggest }}</span>
                <van-tag plain color="#3CC593" size="medium" v-if="msgInfo.divideStatus == '验收已通过'">{{ msgInfo.divideStatus }}</van-tag>
                <van-tag plain color="#FF2D2D" size="medium" v-if="msgInfo.divideStatus == '验收未通过'">{{ msgInfo.divideStatus }}</van-tag>
                <van-tag plain color="orange" size="medium" v-if="msgInfo.divideStatus == '待验收'">{{ msgInfo.divideStatus }}</van-tag>
                <van-tag plain color="orange" size="medium" v-if="msgInfo.divideStatus == '待上传'">{{ msgInfo.divideStatus }}</van-tag>
            </p>
        </div>
        <div class="content_info">
            <van-collapse v-model="activeNames">
                <van-collapse-item title="基本信息" name="1">
                    <div class="cont_div">
                        <div class="cont_label">分包单编号</div>
                        <div class="cont_value">{{ msgInfo.documentNo }}</div>
                    </div>
                    <div class="cont_div">
                        <div class="cont_label">客户姓名</div>
                        <div class="cont_value">{{ msgInfo.consumerName }}</div>
                    </div>
                    <div class="cont_div">
                        <div class="cont_label">手机号</div>
                        <div class="cont_value">{{ msgInfo.phoneNo }}</div>
                    </div>
                    <div class="cont_div">
                        <div class="cont_label">创建日期</div>
                        <div class="cont_value">{{ msgInfo.belowData }}</div>
                    </div>
                    <div class="cont_div">
                        <div class="cont_label">深化</div>
                        <div class="cont_value">{{ msgInfo.deepenName }}</div>
                    </div>
                    <div class="cont_div">
                        <div class="cont_label">装修地址</div>
                        <div class="cont_value">{{ msgInfo.address }}</div>
                    </div>
                </van-collapse-item>
                <van-collapse-item title="分包单详情" name="2" class="cont_bottom">
                    <van-collapse-item :name="index2+1" class="cont_fenbao" v-for="(item,index2) in showList" :key="index2+'1'">
                        <template #title>
                            <div class="slot_fenbao">
                                <p class="slot_fenbao_p">{{ item.belowDate }}</p>
                                <p class="slot_fenbao_p2">{{ item.itemName }}</p>
                                <div class="slot_fenbao_flex">
                                    <div class="left">增减量/单价</div>
                                    <div class="right">
                                        <span class="right_a red">{{ item.amount }}</span>
                                        <span class="right_b">¥{{ item.sellingPrice?item.sellingPrice:0 }}/㎡</span>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <div class="slot_cont">
                            <div class="left">合计价:</div>
                            <div class="right red">¥{{ item.sellingTotal | 0 }}</div>
                        </div>
                        <div class="slot_cont">
                            <div class="left">材料/定额编号:</div>
                            <div class="right right1">{{ item.itemNo }}</div>
                        </div>
                        <div class="slot_cont">
                            <div class="left_nowarp">说明:</div>
                            <div class="right1">{{ item.remark }}</div>
                        </div>
                    </van-collapse-item>
                    <div class="zhankai" v-if="list.length > showList.length || showBtnMore" @click="isShowBtnMore">{{showBtnMore?'收起更多': '展开更多'}}
                        <img :class="showBtnMore?'jiantou':'jiantou1'" :src="require('@/assets/icon2.png')" alt=""
                                style="width:12px;height:12px;margin-left:4px">
                    </div>
                </van-collapse-item>
            </van-collapse>
        </div>
    </div>
</template>
<script>
import api from '@/api/api.js'
import api2 from '@/api/dataBoard.js'
export default {
    props: {
        infoMain: Object,
    },
    data () {
        return {
            checkRemark: '',
            activeNames: [''],
            // 分包单详情
            msgInfo:{},
            msgConent:{},
            // 列表
            list:[],
            // 是否展示显示更多按钮
            showBtnMore:false,
            // 显示的数据
            showList:[],
            // 剩余的数据
            remainingList:[]
        }
    },
    methods: {
        handleBlur () {
            this.$emit('handleRemark', this.checkRemark)
        },
        // 查询详情
        async handleDetails () {
            const { sellOrderItemTDTOList0 = [], sellOrderItemTDTOList1 = [], ...otherParams } = await api.pageDetail(this.id)
            let res = await api2.messageApi(this.logId)
            this.msgConent = res.data
            this.msgInfo = otherParams.data
            this.list = [...otherParams.data.sellOrderItemTDTOList0]
            this.remainingList = this.list.slice(3)
            this.showList = this.list.slice(0, 3)
        },
        isShowBtnMore(){
            this.showBtnMore = !this.showBtnMore
            if(this.showBtnMore){
                this.showList = [...this.showList, ...this.remainingList];
            }else{
                this.showList = this.list.slice(0, 3);
            }
        }

    },
    created () {
        this.id = this.$route.params.id
        this.logId = this.$route.params.logId
        this.handleDetails()
    },

}
</script>
<style lang="scss"  src="./index.scss"></style>
<style lang="scss" scoped>
.jiantou{
    transform: rotate(90deg);
    transition: all 0.3s;
}
.jiantou1{
    transform: rotate(-90deg);
    transition: all 0.3s;
}
</style>