<template>
  <div class="main">
    <headerNav leftNav=" " :title="'审批详情'" @leftClick="back"></headerNav>
    <main>
      <div class="main-style">
        <div class="content">
          <!-- 顶部 -->
          <div class="content-top">
            <div class="content-top-left">
              <div class="content-top-left-img">
                <img src="@/assets/task-management/task-setup-finance-check-details.png" alt="">
              </div>
              <div class="content-top-left-text">
                <div class="title">
                  <span v-if="roleData.roleCode=='ROLE_603933'">{{ detailList.markerName }}</span>{{detailList.headline}}</div>
                <div class="submit-name">
                  <div>
                    提交人 <span>{{detailList.userName}}</span> 
                  </div>
                  <div class="content-top-right">{{ detailList.setTime }}</div>
                </div>
              </div>
            </div>
          </div>
          <!-- 任务项 -->
          <div class="content-center" v-if="isExpanded">
            <!-- 详情 -->
            <div class="content-item">
              <div class="content-center-details-term">
                <div class="left">指标</div>
                <div class="right">
                  <div v-for="(item1,index1) in detailList.termName" :key="index1">{{ item1 }}</div>
                </div>
              </div>
              <div class="content-center-details-month">
                <div class="left">任务月份</div>
                <div class="right">{{ detailList.taskDate }}</div>
              </div>
            </div>
            <div v-if="detailList.updateStatus == '1'||detailList.updateStatus == '2'">
              <div class="content-item">
                <div class="content-item-title">指派任务</div>
                <div v-for="(item2,index2) in detailList.rawTask" :key="index2" class="content-center-details">
                  <div  class="content-center-details-item">
                    <div class="left">{{ item2.termName }} :</div>
                    <div class="right">{{ item2.productionValue }}<span>{{ item2.unit }}</span></div>
                  </div>
                  <div class="content-center-details-item content-center-details-item-child" v-for="(item9,index9) in item2.detailList" :key="index9">
                    <div class="left">{{ item9.termName }}：</div>
                    <div class="right">{{ item9.productionValue }}<span>{{ item9.unit }}</span></div>
                  </div>
                </div>
              </div>
              <div class="content-item">
                <div class="content-item-title">设置任务</div>
                <div v-for="(item3,index3) in detailList.presentTask" :key="index3" class="content-center-details">
                  <div class="content-center-details-item">
                    <div class="left">{{ item3.departmentName }} :</div>
                    <div class="right">{{ item3.productionValue }}<span>{{ item3.unit }}</span></div>
                  </div >
                  <div class="content-center-details-item content-center-details-item-child" v-for="(item4,index4) in item3.detailList" :key="index4">
                    <div class="left">{{ item4.departmentName }} :</div>
                    <div class="right">{{ item4.productionValue }}<span>{{ item4.unit }}</span></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="content-item-update" v-if="detailList.updateStatus == '3'">
              <div class="content-item-update-item">
                <div class="content-item-title">原任务</div>
                <div v-for="(item5,index5) in detailList.rawTask" :key="index5" class="content-center-details">
                  <div class="content-center-details-item">
                    <div class="left">{{ item5.departmentName }} :</div>
                    <div class="right">{{ item5.productionValue }}<span>{{ item5.unit }}</span></div>
                  </div>
                  <div class="content-center-details-item content-center-details-item-child" v-for="(item6,index6) in item5.detailList" :key="index6">
                    <div class="left">{{ item6.departmentName }} :</div>
                    <div class="right">{{ item6.productionValue }}<span>{{ item6.unit }}</span></div>
                  </div>
                </div>
              </div>
              <div class="content-item-update-item">
                <div class="content-item-title">修改后</div>
                <div v-for="(item7,index7) in detailList.presentTask" :key="index7" class="content-center-details">
                  <div class="content-center-details-item">
                    <div class="left">{{ item7.departmentName }} :</div>
                    <div class="right">{{ item7.productionValue }}<span>{{ item7.unit }}</span></div>
                  </div>
                  <div class="content-center-details-item content-center-details-item-child" v-for="(item8,index8) in item7.detailList" :key="index8">
                    <div class="left">{{ item8.departmentName }} :</div>
                    <div class="right">{{ item8.productionValue }}<span>{{ item8.unit }}</span></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- 收起展开 -->
          <div class="content-center-bottom" @click="onClickExpand">
            <img :src="require(`@/assets/task-management/${!isExpanded ? 'task-zhankai' : 'task-shouqi'}.png`)" alt="">
          </div>
        </div>

        <!-- 审批流程 -->
        <div class="bottom-check">
          <div class="bottom-check-title">
            <div class="left-line"></div>
            <div class="text">审批流程</div> 
          </div>

          <div class="steps-content">
            <van-steps direction="vertical" :active="(stepData.length-1)" active-color="#0E8BFF">
              <van-step v-for="(item, index) in stepData" :key="index">
                <template #active-icon>
                  <div class="steps-active-icon">
                    <img v-if="item.manageCheckStatus == '0'" src="@/assets/task-management/task-setup-steps-review.png" alt="">
                    <img v-if="item.manageCheckStatus == '1'" src="@/assets/task-management/task-setup-steps-confrim.png" alt="">
                    <img v-if="item.manageCheckStatus == '2'" src="@/assets/task-management/task-setup-steps-reject.png" alt="">
                    <img v-if="item.manageCheckStatus == '3'" src="@/assets/task-management/task-setup-steps-lose.png" alt="">
                    <img v-if="item.manageCheckStatus == '4'" src="@/assets/task-management/task-setup-steps-lose.png" alt="">
                    <img v-if="item.manageCheckStatus == '5'" src="@/assets/task-management/task-setup-steps-copy.png" alt="">
                    <img v-if="item.manageCheckStatus == '6'" src="@/assets/task-management/task-setup-steps-copy.png" alt="">
                  </div>
                </template>
                <div>
                  <div class="step-main">
                    <div class="step-main-left">
                      <div class="step-main-left-text">
                        {{ item.manageRoleUserName&&item.manageRoleUserName.join('、') }} <span>{{ item.manageRoleName }}</span>
                      </div>
                      <div class="step-main-left-date">{{ item.sponsorTime }}</div>
                      
                    </div>
                    <div class="step-main-right">
                      <span v-if="item.manageCheckStatus == '0'" class="orange">待审核</span>
                      <span v-if="item.manageCheckStatus == '1'" class="green">已通过</span>
                      <span v-if="item.manageCheckStatus == '2'" class="red">已驳回</span>
                      <span v-if="item.manageCheckStatus == '3'" class="gay">已失效</span>
                      <span v-if="item.manageCheckStatus == '4'" class="gay">已撤回</span>
                      <span v-if="item.manageCheckStatus == '5'" class="gay">待抄送</span>
                      <span v-if="item.manageCheckStatus == '6'" class="gay">已抄送</span>
                      <span v-if="item.manageCheckStatus == '7'" class="gay">已发起</span>
                    </div>
                  </div>
                  <div class="step-main-reject" v-if="item.manageCheckStatus == '2'">
                    驳回原因：{{ item.manageReason }}
                  </div>
                  <div class="step-main-reject" v-if="item.manageCheckStatus == '4'">
                    撤回原因：{{ item.manageReason }}
                  </div>
                </div>
              </van-step>
            </van-steps>
          </div>
        </div>

        <!-- 撤销 -->
        <div class="footer-handle" v-if="isShowRevokeBtn">
          <van-button class="btn1" color="#4871C0" @click="onHandleExamine('撤销')" type="info">撤销</van-button>
        </div>
        <!-- 审批 -->
        <div class="footer-handle" v-if="!isShowRevokeBtn && isShowExamineBtn">
          <van-button class="btn" type="default" @click="onHandleExamine('驳回')">驳回</van-button>
          <van-button class="btn" color="#4871C0" @click="onHandleExamine('通过')" type="info">通过</van-button>
        </div>
      </div>
    </main>

    <!-- 审批确认弹窗 -->
    <van-dialog v-model="examineDialog" :closeOnClickOverlay="true" confirmButtonColor="#3975C6" show-cancel-button
      @cancel="examineDialogClose" :beforeClose='examineDialogBeforeClose'  @confirm="examineDialogConfirm">
      <div class="dialog-main">
        <!-- 驳回 -->
        <div class="dialog-main-reject" v-if="!examineDialogModel">
          <!-- 您确认“ <span>驳回</span> ” {{detailList.userName}}的审批请求吗？ -->
          <div class="dialog-main-reject-title">{{ dialogTitle }}</div>
          <van-field
            v-model="rejectMessage"
            rows="3"
            autosize
            :label="dialogTitle"
            type="textarea"
            maxlength="50"
            :placeholder="'请输入'+dialogTitle"
            show-word-limit
            required
          />
        </div>
        <!-- 通过 -->
        <div class="dialog-main-pass" v-else>
          确认“ <span>通过</span> ” {{detailList.headline}}？
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import api from "@/api/task-management/setup.js";
import { Toast } from "vant";
import moment from "moment";

export default {
  data () {
    return {
      // 详情数据
      detailList:{},
      // 任务项显示隐藏
      isExpanded: true,
      // 流程数据
      stepData: [],
      // 审批弹窗
      examineDialog: false,
      // 弹窗标题
      dialogTitle:'',
      //弹窗模块
      examineDialogModel: false,
      // 显示撤销按钮
      isShowRevokeBtn:false,
      // 显示审批按钮
      isShowExamineBtn:true,
      // 驳回原因
      rejectMessage:'',
      // 当前登录人信息
      roleData:null
    }
  },
  created () {
    this.roleData = JSON.parse(localStorage.getItem("USER_ROLE"));
    this.isShowRevokeBtn = this.$route.query.isRevoke == '1'?true:false
  },
  mounted() {
    this.getDetail()
    this.getdata()
  },
  methods: {
    // 回退页面
    back () {
      this.$router.go(-1)
    },
    // 控制展开收起
    onClickExpand () {
      this.isExpanded = !this.isExpanded
    },
    // 点击审批按钮
    onHandleExamine (type) {
      this.examineDialog = true
      if (type == '驳回') {
        this.examineDialogModel = false
        this.dialogTitle = '驳回原因'
      } else if(type == '撤销'){
        this.examineDialogModel = false
        this.dialogTitle = '撤销原因'
      } else if(type == '通过'){
        this.examineDialogModel = true
      }
    },
    // 点击撤回
    onHandleRevoke(){
      this.examineDialogModel = false
      this.dialogTitle = '撤销原因'
    },
    // 弹窗关闭
    examineDialogClose () {},
    // 弹窗关闭之前
    examineDialogBeforeClose(action, done){
      if(action == "confirm" && !this.examineDialogModel && this.rejectMessage ==''){
        Toast('请填写'+this.dialogTitle)
        done(false)
      }else{
        done()
      }
    },
    // 弹窗确认
    examineDialogConfirm(){
      if(!this.examineDialogModel && this.rejectMessage ==''){
        return false
      }
      if(this.isShowRevokeBtn){
        Toast.loading({
          message: '加载中...',
          forbidClick: true,
        });
        let params = {
          "id":this.detailList.id,
          "manageReason": this.rejectMessage,
        }
        api.getApprovalRevoke(params).then((res)=>{
          if (res.code == 200) {
            Toast.success("操作成功")
            this.isShowRevokeBtn = false
            this.getdata()
          }else{
            Toast.fail(res.msg)
          }
        })
      }else{
        Toast.loading({
          message: '加载中...',
          forbidClick: true,
        });
        let params = {
          "id":this.detailList.id,
          "result": this.examineDialogModel?'1':'2',
          "remark": this.rejectMessage,
        }
        api.getApprovalCheckResult(params).then((res)=>{
          if (res.code == 200) {
            Toast.success("操作成功")
            this.getdata()
          }else{
            Toast.fail(res.msg)
          }
        })
      }
    },
    // 获取详情的数据
    getDetail(){
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      let params = {
        "id": this.$route.query.id,
        "etx":this.$route.query.tabActive
      }
      api.getCheckListDetail(params).then((res)=>{
        if (res.code == 200) {
          Toast.clear()
          this.detailList = res.data[0]
        }else{
          Toast.fail(res.msg)
        }
      })
    },
    // 获取流程信息
    getdata(){
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      let params = {
        "id": this.$route.query.id,
      }
      api.getApprovalProcess(params).then((res)=>{
        if (res.code == 200) {
          Toast.clear()
          this.stepData = res.data
          const result = res.data.find(item => item.manageRoleCode == this.roleData.roleCode)
          this.isShowExamineBtn = result && result.manageCheckStatus == '0' ? true : false
        }else{
          Toast.fail(res.msg)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.main::v-deep {
  background: #F5F5F5;
  height: 100vh;

  .main-style {
    height: calc(100vh - 50px);
    overflow: auto;
    margin-top: -1px;
  }

  .content {
    background: #3975C6;
    border-radius: 0px 0px 5px 5px;
    padding: 8px 12px;

    .content-top {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;

      .content-top-left {
        width: 100%;
        display: flex;
        align-items: flex-start;
        text-align: left;
        .content-top-left-img {
          img {
            width: 18px;
            height: 18px;
            margin: 6px 4px 0 0;
          }
        }

        .content-top-left-text {
          width: calc(100% - 22px);
          .title {
            font-size: 20px;
            font-family: PingFang TC, PingFang TC;
            font-weight: 500;
            color: #FFFFFF;
            margin-bottom: 9px;
            white-space: nowrap; 
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .submit-name {
            font-size: 16px;
            font-family: PingFang TC, PingFang TC;
            font-weight: 400;
            color: #D7E3F4;
            display: flex;
            justify-content: space-between;
            align-items: center;

            span {
              font-weight: 500;
              margin-left: 16px;
            }
          }
        }
      }

      .content-top-right {
        font-size: 14px;
        font-family: PingFang TC, PingFang TC;
        font-weight: 400;
        color: #FFFFFF;
      }
    }

    .content-center {
      border-radius: 4px;
      margin-bottom: 8px;
      .content-item{
        background: #336CC2;
        border-radius: 8px 8px 8px 8px;
        padding: 9px 8px;
        margin-bottom: 8px;

        .content-item-title{
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 16px;
          color: #FFFFFF;
          text-align: left;
          line-height: 38px;
        }

        .content-center-details-term{
          display: flex;
          justify-content: space-between;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          .left {
            font-size: 14px;
            line-height: 38px;
          }
  
          .right {
            display: flex;
            justify-content: flex-end;
            flex-wrap: wrap;
            font-size: 15px;
            max-width: 70%;
            div{
              background: rgba(255,255,255,0.1);
              padding: 0 4px;
              margin:0 0 8px 8px;
              border-radius: 3px 3px;
              line-height: 30px;
            }
          }
        }
        .content-center-details-month{
          display: flex;
          justify-content: space-between;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 16px;
          color: #FFFFFF;
          line-height: 38px;
          .left {
            font-size: 14px;
          }
        }

        .content-center-details {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;

          .content-center-details-item{
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            line-height: 38px;
          }

          .content-center-details-item-child{
            padding: 0 8px;
            background: rgba(0,0,0,0.05);
            line-height: 38px;
            margin-bottom: 1px;
          }
  
          .left {
            font-size: 14px;
          }
  
          .right {
            font-size: 16px;
            font-weight: 500;
            span{
              font-size: 12px;
            }
          }
        }
      }

      .content-item-update{
        margin-bottom: 8px;
        display: flex;
        justify-content: space-between;
        .content-item-update-item{
          border-radius: 8px 8px 8px 8px;
          padding: 9px 8px;
          background: #336CC2;
          width: calc(49.5% - 16px);
          .content-item-title{
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 16px;
            color: #FFFFFF;
            text-align: left;
            line-height: 38px;
          }
          .content-center-details {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            flex-wrap: wrap;
    
            .left {
              font-size: 14px;
              text-align: left;
              width: 45%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
    
            .right {
              font-size: 16px;
              font-weight: 500;
              text-align: right;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              span{
                font-size: 12px;
              }
            }
            .content-center-details-item{
              width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: center;
              line-height: 38px;
            }
            .content-center-details-item-child{
              padding: 0 8px;
              background: rgba(0,0,0,0.05);
              line-height: 38px;
            }
          }
        }
      }
    }

    .content-center-bottom {
      img {
        width: 16px;
        height: 16px;
      }
    }
  }

  .bottom-check {
    background: #FFFFFF;
    border-radius: 8px;
    margin-top: 8px;
    margin-bottom: 90px;

    .bottom-check-title {
      display: flex;
      padding: 14px;
      align-items: center;
      border: 1px solid #EEEEEE;

      .left-line {
        width: 4px;
        height: 16px;
        background: #4871C0;
        border-radius: 20px
      }

      .text {
        font-size: 16px;
        font-family: PingFang TC, PingFang TC;
        font-weight: 500;
        color: #333333;
        margin-left: 3px;
      }
    }
  }

  .steps-content {
    .van-step__line {
      left: -4.5%;
      top: 20px
    }

    .steps-active-icon {
      img {
        width: 12px;
        height: 12px;
      }
    }

    .step-main {
      display: flex;
      justify-content: space-between;

      .step-main-left {
        text-align: left;
        width: 80%;

        .step-main-left-text {
          font-size: 16px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #323233;
          margin-bottom: 8px;

          span {
            width: 64px;
            height: 20px;
            background: rgba(217, 217, 217, 0.2);
            border-radius: 4px;
            font-size: 14px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 300;
            color: #8C8C8C;
            padding: 0px 4px;
          }
        }

        .step-main-left-date {
          font-size: 12px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #969799;
        }
        
      }

      .step-main-right {
        span {
          width: 58px;
          height: 24px;
          font-size: 14px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          padding: 3px 8px;
          border-radius: 4px;
        }

        .green {
          background: #ECF8F0;
          color: #38B865;
        }

        .orange {
          background: #FFF4E9;
          color: #FF9C3F;
        }

        .red {
          background: rgba(255, 97, 87, 0.15);
          color: #FF6157;
        }

        .gay {
          background: #F5F5F5;
          color: #999999;
        }
      }
    }
    .step-main-reject{
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #545454;
      margin-top: 10px;
      text-align: left;
    }
  }

  .footer-handle {
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px -1px 8px 0px rgba(0, 0, 0, 0.04);
    position: fixed;
    bottom: 1px;
    left: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    z-index: 100;
    padding: 10px 0 30px;

    .btn {
      width: 45%;
      height: 46px;
      border-radius: 4px;
      font-size: 18px;
    }
    .btn1{
      width: 90%;
      height: 46px;
      border-radius: 4px;
      font-size: 18px;
    }
  }

  .dialog-main {
    padding: 16px;
    border-bottom: 1px solid #EEEEEE;

    .dialog-main-pass {
      font-size: 16px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      color: #000000;
      margin: 20px 0px;

      span {
        color: #ED4040;
      }
    }
    .dialog-main-reject{
      font-size: 16px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      color: #323233;
      .dialog-main-reject-title{
        margin-bottom: 16px;
      }
      .van-cell{
        padding: 8px;
        background: #F5F5F5;

        .van-cell__title{
          display: none;
        }
      }
    }

  }

  .van-dialog__cancel {
    font-size: 18px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    color: #333333;
  }

  .van-dialog__confirm {
    font-size: 18px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    color: #3975C6;
  }
}
</style>