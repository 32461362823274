<template>
    <div class="messageInfo-box">
        <headerNav title="消息详情" />
        <div :class="msgInfo.accountStatusName == '未到账' || !msgInfo.accountStatusName ? 'main_content' : 'show_main_content'">
            <!-- 预订款缴费审核 -->
            <div class="main_cont">
                <p class="p_1"><span class="span_p">合同号</span>{{ msgInfo.contractNo }}</p>
                <p class="p_2">
                    <span class="span_left">{{ msgConent.messageSuggest }}</span>
                </p>
            </div>
            <div class="content_info">
                <van-collapse v-model="activeNames">
                    <van-collapse-item title="基本信息" name="1">
                        <div class="cont_div">
                            <div class="cont_label">单据编号</div>
                            <div class="cont_value">{{ msgInfo.paperNo }}</div>
                        </div>
                        <div class="cont_div">
                            <div class="cont_label">客户姓名</div>
                            <div class="cont_value">{{ msgInfo.consumerName }}</div>
                        </div>
                        <div class="cont_div">
                            <div class="cont_label">预定合同号</div>
                            <div class="cont_value">{{ msgInfo.reserveContract }}</div>
                        </div>
                        <div class="cont_div">
                            <div class="cont_label">施工合同号</div>
                            <div class="cont_value">{{ msgInfo.contractNo }}</div>
                        </div>
                        <div class="cont_div">
                            <div class="cont_label">收款类型</div>
                            <div class="cont_value">{{ msgInfo.feeTypeName }}</div>
                        </div>
                        <div class="cont_div">
                            <div class="cont_label">发起人</div>
                            <div class="cont_value">{{ msgInfo.createId }}</div>
                        </div>
                        <div class="cont_div">
                            <div class="cont_label">收款金额(元)</div>
                            <div class="cont_value">{{ msgInfo.planAmount }}</div>
                        </div>
                    </van-collapse-item>
                </van-collapse>
            </div>
            <div class="bottom_main content_info" v-if="msgInfo.accountStatusName == '未到账' || !msgInfo.accountStatusName">
                <div class="cont_div">
                    <div class="cont_label">缴费用户:</div>
                    <div class="cont_value">{{ msgInfo.consumerName }}</div>
                </div>
                <div class="payway">
                    <div class="cont_label">缴费方式:</div>
                    <van-checkbox-group v-model="resultCheckBox" direction="horizontal" @change="handleSelectWay($event)">
                        <van-checkbox shape="square" checked-color="#4871C0" v-for="item in payFreeList" :key="item.id"
                            :name="item.code" @click="handleSelect(item.code)">{{ item.name }}</van-checkbox>
                    </van-checkbox-group>
                </div>
                <div class="way_list cont_div" v-if="checkedList.includes('ITEM819003')">
                    <div class="left_nav">刷卡</div>
                    <div class="content">
                        <van-cell-group>
                            <van-field v-model="showPayfreelsit.realityAmount" label-width="80px" label-class="label_left"
                                label="缴费金额" placeholder="请输入" />
                            <van-field label="上传凭证" label-width="80px" label-class="label_left">
                                <template #input>
                                    <div>
                                        <van-uploader :after-read="afterRead" multiple :max-count="30"
                                            :max-size="10 * 1024 * 1024" v-model="showPayfreelsit.paperUrl">
                                            <div class="up-style-img">
                                                <img :src="require('@/assets/camera1.png')" alt="" width="20px" />
                                                <span>上传图片</span>
                                            </div>
                                        </van-uploader>
                                    </div>
                                </template>
                            </van-field>
                        </van-cell-group>
                    </div>
                </div>
                <div class="way_list cont_div" v-if="checkedList.includes('ITEM250367')">
                    <div class="left_nav">转账</div>
                    <div class="content">
                        <van-cell-group>
                            <van-field v-model="showPayfreelsit.realityAmount1" label-width="80px" label-class="label_left"
                                label="缴费金额" placeholder="请输入" />
                            <van-field label="上传凭证" label-width="80px" label-class="label_left">
                                <template #input>
                                    <div>
                                        <van-uploader :after-read="afterRead1" multiple :max-count="30"
                                            :max-size="10 * 1024 * 1024" v-model="showPayfreelsit.paperUrl1">
                                            <div class="up-style-img">
                                                <img :src="require('@/assets/camera1.png')" alt="" width="20px" />
                                                <span>上传图片</span>
                                            </div>
                                        </van-uploader>
                                    </div>
                                </template>
                            </van-field>
                        </van-cell-group>
                    </div>
                </div>
                <div class="way_list cont_div" v-if="checkedList.includes('ITEM516907')">
                    <div class="left_nav">扫码</div>
                    <div class="content">
                        <van-cell-group>
                            <van-field v-model="showPayfreelsit.realityAmount2" label-width="80px" label-class="label_left"
                                label="缴费金额" placeholder="请输入" />
                            <van-field label="上传凭证" label-width="80px" label-class="label_left">
                                <template #input>
                                    <div>
                                        <van-uploader :after-read="afterRead2" multiple :max-count="30"
                                            :max-size="10 * 1024 * 1024" v-model="showPayfreelsit.paperUrl2">
                                            <div class="up-style-img">
                                                <img :src="require('@/assets/camera1.png')" alt="" width="20px" />
                                                <span>上传图片</span>
                                            </div>
                                        </van-uploader>
                                    </div>
                                </template>
                            </van-field>
                        </van-cell-group>
                    </div>
                </div>
                <div class="way_list cont_div" v-if="checkedList.includes('ITEM588103')">
                    <div class="left_nav">现金</div>
                    <div class="content">
                        <van-cell-group>
                            <van-field v-model="showPayfreelsit.realityAmount3" label-width="80px" label-class="label_left"
                                label="缴费金额" placeholder="请输入" />
                            <van-field label="上传凭证" label-width="80px" label-class="label_left">
                                <template #input>
                                    <div>
                                        <van-uploader :after-read="afterRead3" multiple :max-count="30"
                                            :max-size="10 * 1024 * 1024" v-model="showPayfreelsit.paperUrl3">
                                            <div class="up-style-img">
                                                <img :src="require('@/assets/camera1.png')" alt="" width="20px" />
                                                <span>上传图片</span>
                                            </div>
                                        </van-uploader>
                                    </div>
                                </template>
                            </van-field>
                        </van-cell-group>
                    </div>
                </div>
                <div>
                    <div>记账时间:</div>
                    <van-field v-model="tallyTime" :rules="[{ required: true }]" readonly @click="chooseTime"
                        class="field-style" name="记账时间" clickable placeholder="请选择">
                    </van-field>
                    <van-action-sheet v-model="timeShow">
                        <van-datetime-picker @confirm="timeSubmit" @cancel="timeCancel" type="datetime" :value="maxDate"
                            :min-date="minDate" :max-date="maxDate" title="选择时间" />
                    </van-action-sheet>
                </div>
                <!-- 备注信息 -->
                <div>
                    <div>备注:</div>
                    <div v-if="msgInfo.accountStatusName == '未到账' || !msgInfo.accountStatusName">
                        <van-field v-model="bottomReamrk" rows="2" autosize type="textarea" maxlength="200"
                            placeholder="请输入" show-word-limit />
                    </div>
                    <div v-else>
                        {{ bottomReamrk }}
                    </div>
                </div>
            </div>
            <div class="bottom_main content_info" v-else>
                <div class="payway">
                    <div class="cont_label cont_label1">缴费方式:</div>
                    <div class="list_style" v-for="item in listRows">
                        <div class="left">{{ item.collectStyleName }}</div>
                        <div class="right">
                            <div class="top">缴费金额：{{ item.realityAmount }}</div>
                            <div class="img_list">
                                <div class="top">上传凭证：</div>
                                <div class="img_box">
                                    <div v-for="items in item.itemp" @click="onPreview(items.fileAdd)">
                                        <img :src="items.fileAdd" alt="" width="64px" height="64px">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 备注信息 -->
                <div>
                    <div class="remark_bottom">备注:</div>
                    <div class="remark_bottom1">
                        {{ listRows && listRows.length > 0 && listRows[0].remark || '' }}
                    </div>
                </div>
            </div>
        </div>
        <div v-show="msgInfo.accountStatusName == '未到账' || !msgInfo.accountStatusName" class="bottom">
            <van-button type="info" class="bottom_btn" @click="onSubmit" :loading="submitLoading">确认</van-button>
        </div>
    </div>
</template>
<script>
import moment from 'moment'
import api from '@/api/api.js'
import api2 from '@/api/dataBoard.js'
import { ImagePreview, Toast } from 'vant';
import AcceptanceSubcontract from './Component/AcceptanceSubcontract.vue'
export default {
    components: {
        AcceptanceSubcontract
    },
    data () {
        return {
            id: '',
            logId: '',
            // 消息详情
            msgInfo: {},
            // 消息标题
            msgConent: {},
            activeNames: ['1'],
            radio: '',
            fileList: [],
            remark: '',
            // 控制显示更多图片
            showMoreImg: false,
            // 审核意见
            checkRemark: '',
            // 控制是否点击确认
            isClickOk: false,
            // 下拨付款金额
            paymentMoney: '',
            // 缴费方式
            resultCheckBox: [],
            // 缴费方式数组
            payFreeList: [],
            // 缴费方式遍历显示
            showPayfreelsit: {
                money: '',
                planId: '',
                orderId: '',
                realityAmount: '', //刷卡
                realityAmount1: '', //转账
                realityAmount2: '', //扫码
                realityAmount3: '', //现金
                paperUrl: [],
                paperUrl1: [],
                paperUrl2: [],
                paperUrl3: [],
            },
            // 勾选后的方式
            checkedList: [],
            // 底部的备注信息
            bottomReamrk: '',
            // 当前点击的缴费方式
            payMoney: '',
            submitLoading: false,
            // 缴费方式数据
            listRows: [],
            tallyTime: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            timeShow: false,
            minDate: new Date(Date.now() - 5 * 24 * 60 * 60 * 1000),
            maxDate: new Date(),
        }
    },
    methods: {
        // 调用字典查询缴费方式
        async searchPayFree () {
            const { data } = await api.getDictionariesList({ ext: "dist_info", Q_typeCode_IN: 'DICT747462' })
            this.payFreeList = data
        },
        // 查询详情
        async handleDetails () {
            let params = {
                'Q_a.id_EQ': this.id
            }
            var { data } = await api.Cashiernotice(params)
            if (data.list[0].accountStatusName != '未到账') {
                this.handleOnseach()
            }
            this.msgInfo = data.list[0]
            let res = await api2.messageApi(this.logId)
            this.msgConent = res.data
        },
        async onSubmit () {
            let paramsArr = []
            this.checkedList.map(item => {
                let obj = {
                    collectStyle: item,
                    consumerName: this.msgInfo.consumerName,
                    feeType: this.msgInfo.feeType,
                    orderId: this.msgInfo.orderId,
                    itemp: [],
                    planId: this.msgInfo.id,
                    realityAmount: '',
                    remark: this.bottomReamrk,
                    tallyTime: this.tallyTime
                }
                if (item == 'ITEM819003') {
                    obj.itemp = this.showPayfreelsit.paperUrl
                    obj.realityAmount = this.showPayfreelsit.realityAmount
                } else if (item == 'ITEM250367') {
                    obj.itemp = this.showPayfreelsit.paperUrl1
                    obj.realityAmount = this.showPayfreelsit.realityAmount1
                } else if (item == 'ITEM516907') {
                    obj.itemp = this.showPayfreelsit.paperUrl2
                    obj.realityAmount = this.showPayfreelsit.realityAmount2
                } else if (item == 'ITEM588103') {
                    obj.itemp = this.showPayfreelsit.paperUrl3
                    obj.realityAmount = this.showPayfreelsit.realityAmount3
                }
                paramsArr.push(obj)
            })
            try {
                this.submitLoading = true
                await api.cashierPay(paramsArr)
                Toast.success('申请成功')
                this.handleDetails()
            } finally {
                this.submitLoading = false
            }

        },
        //查询缴费方式
        async handleOnseach () {
            const { data } = await api.detailList({ Q_planId_EQ: this.id })
            this.listRows = data
        },
        // 点击缴费方式
        handleSelect (e) {
            this.payMoney = e
        },
        // 选择缴费方式
        handleSelectWay (e, code) {
            this.checkedList = e
            if (e.length == 1) {
                // 刷卡
                if (e[0] == 'ITEM819003') {
                    this.showPayfreelsit.realityAmount = this.msgInfo.planAmount
                    this.showPayfreelsit.realityAmount1 = this.showPayfreelsit.realityAmount1 == this.msgInfo.planAmount ? '' : this.showPayfreelsit.realityAmount1
                    this.showPayfreelsit.realityAmount2 = this.showPayfreelsit.realityAmount2 == this.msgInfo.planAmount ? '' : this.showPayfreelsit.realityAmount2
                    this.showPayfreelsit.realityAmount3 = this.showPayfreelsit.realityAmount3 == this.msgInfo.planAmount ? '' : this.showPayfreelsit.realityAmount3
                }
                // 转账
                if (e[0] == 'ITEM250367') {
                    this.showPayfreelsit.realityAmount1 = this.msgInfo.planAmount
                    this.showPayfreelsit.realityAmount = this.showPayfreelsit.realityAmount == this.msgInfo.planAmount ? '' : this.showPayfreelsit.realityAmount
                    this.showPayfreelsit.realityAmount2 = this.showPayfreelsit.realityAmount2 == this.msgInfo.planAmount ? '' : this.showPayfreelsit.realityAmount2
                    this.showPayfreelsit.realityAmount3 = this.showPayfreelsit.realityAmount3 == this.msgInfo.planAmount ? '' : this.showPayfreelsit.realityAmount3
                }
                // 扫码
                if (e[0] == 'ITEM516907') {
                    this.showPayfreelsit.realityAmount2 = this.msgInfo.planAmount
                    this.showPayfreelsit.realityAmount = this.showPayfreelsit.realityAmount == this.msgInfo.planAmount ? '' : this.showPayfreelsit.realityAmount
                    this.showPayfreelsit.realityAmount1 = this.showPayfreelsit.realityAmount1 == this.msgInfo.planAmount ? '' : this.showPayfreelsit.realityAmount1
                    this.showPayfreelsit.realityAmount3 = this.showPayfreelsit.realityAmount3 == this.msgInfo.planAmount ? '' : this.showPayfreelsit.realityAmount3
                }
                // 现金
                if (e[0] == 'ITEM588103') {
                    this.showPayfreelsit.realityAmount3 = this.msgInfo.planAmount
                    this.showPayfreelsit.realityAmount = this.showPayfreelsit.realityAmount == this.msgInfo.planAmount ? '' : this.showPayfreelsit.realityAmount
                    this.showPayfreelsit.realityAmount1 = this.showPayfreelsit.realityAmount1 == this.msgInfo.planAmount ? '' : this.showPayfreelsit.realityAmount1
                    this.showPayfreelsit.realityAmount2 = this.showPayfreelsit.realityAmount2 == this.msgInfo.planAmount ? '' : this.showPayfreelsit.realityAmount2
                }
            }
            // 查找缴费方式里面是否有勾选的缴费方式，若没有则是取消勾选，就将上一个勾选的缴费方式赋值
            if (e.indexOf(this.payMoney) == -1) {
                let code = this.payMoney
                /*取消的哪个就把那个置空 */
                // 刷卡
                if (code == 'ITEM819003') {
                    this.showPayfreelsit.realityAmount = ''
                }
                // 转账
                if (code == 'ITEM250367') {
                    this.showPayfreelsit.realityAmount1 = ''
                }
                // 扫码
                if (code == 'ITEM516907') {
                    this.showPayfreelsit.realityAmount2 = ''
                }
                // 现金
                if (code == 'ITEM588103') {
                    this.showPayfreelsit.realityAmount3 = ''
                }
                // 刷卡
                if (e[0] == 'ITEM819003') {
                    this.showPayfreelsit.realityAmount = this.showPayfreelsit.realityAmount ? this.showPayfreelsit.realityAmount : this.msgInfo.planAmount
                    this.showPayfreelsit.realityAmount1 = this.showPayfreelsit.realityAmount1 == this.msgInfo.planAmount ? '' : this.showPayfreelsit.realityAmount1
                    this.showPayfreelsit.realityAmount2 = this.showPayfreelsit.realityAmount2 == this.msgInfo.planAmount ? '' : this.showPayfreelsit.realityAmount2
                    this.showPayfreelsit.realityAmount3 = this.showPayfreelsit.realityAmount3 == this.msgInfo.planAmount ? '' : this.showPayfreelsit.realityAmount3
                    // 转账
                } else if (e[0] == 'ITEM250367') {
                    this.showPayfreelsit.realityAmount1 = this.showPayfreelsit.realityAmount1 ? this.showPayfreelsit.realityAmount1 : this.msgInfo.planAmount
                    this.showPayfreelsit.realityAmount = this.showPayfreelsit.realityAmount == this.msgInfo.planAmount ? '' : this.showPayfreelsit.realityAmount
                    this.showPayfreelsit.realityAmount2 = this.showPayfreelsit.realityAmount2 == this.msgInfo.planAmount ? '' : this.showPayfreelsit.realityAmount2
                    this.showPayfreelsit.realityAmount3 = this.showPayfreelsit.realityAmount3 == this.msgInfo.planAmount ? '' : this.showPayfreelsit.realityAmount3
                    // 扫码
                } else if (e[0] == 'ITEM516907') {
                    this.showPayfreelsit.realityAmount2 = this.showPayfreelsit.realityAmount2 ? this.showPayfreelsit.realityAmount2 : this.msgInfo.planAmount
                    this.showPayfreelsit.realityAmount = this.showPayfreelsit.realityAmount == this.msgInfo.planAmount ? '' : this.showPayfreelsit.realityAmount
                    this.showPayfreelsit.realityAmount1 = this.showPayfreelsit.realityAmount1 == this.msgInfo.planAmount ? '' : this.showPayfreelsit.realityAmount1
                    this.showPayfreelsit.realityAmount3 = this.showPayfreelsit.realityAmount3 == this.msgInfo.planAmount ? '' : this.showPayfreelsit.realityAmount3
                } else {
                    // 现金
                    this.showPayfreelsit.realityAmount3 = this.showPayfreelsit.realityAmount3 ? this.showPayfreelsit.realityAmount3 : this.msgInfo.planAmount
                    this.showPayfreelsit.realityAmount = this.showPayfreelsit.realityAmount == this.msgInfo.planAmount ? '' : this.showPayfreelsit.realityAmount
                    this.showPayfreelsit.realityAmount1 = this.showPayfreelsit.realityAmount1 == this.msgInfo.planAmount ? '' : this.showPayfreelsit.realityAmount1
                    this.showPayfreelsit.realityAmount2 = this.showPayfreelsit.realityAmount2 == this.msgInfo.planAmount ? '' : this.showPayfreelsit.realityAmount2
                }
            }
        },
        // 设置显示方式
        showPayfreelsithandle () {
            this.showPayfreelsit.filter(item => this.checkedList.some(aItem => {
                if (aItem === item.code) {
                    item.show = true
                } else {
                    item.show = false
                }
            }));
        },
        async afterRead (file) {
            const fileList = Array.isArray(file) ? file : [file]
            fileList.forEach(async (e, index) => {
                // 此时可以自行将文件上传至服务器
                const formData = new FormData();
                formData.append('files', e.file);
                formData.append('dir', 'oss');
                const res = await api.upFile(formData)
                if (res.code == 200) {
                    const indexR = this.showPayfreelsit.paperUrl.length - fileList.length + index
                    this.showPayfreelsit.paperUrl[indexR].fileAdd = res.url
                }
            })
        },
        async afterRead1 (file) {
            const fileList = Array.isArray(file) ? file : [file]
            fileList.forEach(async (e, index) => {
                // 此时可以自行将文件上传至服务器
                const formData = new FormData();
                formData.append('files', e.file);
                formData.append('dir', 'oss');
                const res = await api.upFile(formData)
                if (res.code == 200) {
                    const indexR = this.showPayfreelsit.paperUrl1.length - fileList.length + index
                    this.showPayfreelsit.paperUrl1[indexR].fileAdd = res.url
                }
            })
        },
        async afterRead2 (file) {
            const fileList = Array.isArray(file) ? file : [file]
            fileList.forEach(async (e, index) => {
                // 此时可以自行将文件上传至服务器
                const formData = new FormData();
                formData.append('files', e.file);
                formData.append('dir', 'oss');
                const res = await api.upFile(formData)
                if (res.code == 200) {
                    const indexR = this.showPayfreelsit.paperUrl2.length - fileList.length + index
                    this.showPayfreelsit.paperUrl2[indexR].fileAdd = res.url
                }
            })
        },
        async afterRead3 (file) {
            const fileList = Array.isArray(file) ? file : [file]
            fileList.forEach(async (e, index) => {
                // 此时可以自行将文件上传至服务器
                const formData = new FormData();
                formData.append('files', e.file);
                formData.append('dir', 'oss');
                const res = await api.upFile(formData)
                if (res.code == 200) {
                    const indexR = this.showPayfreelsit.paperUrl3.length - fileList.length + index
                    this.showPayfreelsit.paperUrl3[indexR].fileAdd = res.url
                }
            })
        },
        chooseTime () {
            this.timeShow = true

        },
        timeSubmit (val) {
            this.tallyTime = moment(val).format('YYYY-MM-DD HH:mm:ss')
            this.timeShow = false
        },
        timeCancel (val) {
            this.timeShow = false
        },
        onPreview (url) {
            ImagePreview({
                images: [url],
                closeable: true,
                showIndex: false
            });
        },
    },
    mounted () {

    },
    created () {
        this.id = this.$route.params.id
        this.logId = this.$route.params.logId
        this.handleDetails()
        this.searchPayFree()
    }
}
</script>
<style lang="scss" src="./Component/index.scss"></style>
<style scoped lang="scss">
.messageInfo-box {
    width: 100%;
    overflow: hidden;

    .main_content {
        width: 100%;
        overflow-x: auto;
        height: 81vh;
        background: #F5F5F5;

    }

    .show_main_content {
        height: calc(100vh - 50px);
        width: 100%;
        overflow-x: auto;
        background: #F5F5F5;
    }
}

.span_p {
    width: 44px;
    text-align: center;
    height: 18px;
    background: #ECF2FA;
    color: #3975C6;
    font-size: 12px;
    margin-right: 12px;
    line-height: 18px;
    padding: 2px;
}

.main_cont {
    background: #fff;
    // height: 20px;
    padding: 16px;
    text-align: left;
    margin-bottom: 8px;

    .p_1 {
        margin: 0px 0px 8px 0px;
        color: #999999;
        font-size: 14px;
    }

    .p_2 {
        display: flex;
        justify-content: space-between;
        margin: 0px;

        .span_left {
            color: #000000;
            font-size: 16px;
            font-weight: 600;
        }
    }
}


.money_style {
    margin-top: 8px;
    font-size: 16px;
    font-weight: 400;
    color: #333333;

    .van-cell {
        &::before {
            position: absolute;
            left: 8px;
            color: #ee0a24;
            font-size: 14px;
            content: '*';
        }
    }
}

.money_style1 {
    margin-top: 8px;
    font-size: 16px;
    font-weight: 400;
    color: #333333;
}

.bottom {
    // position: fixed;
    // bottom: 0;
    padding-top: 24px;
    background: #FFFFFF;
    width: 100%;
    z-index: 2;
    color: #999999;
    font-family: PingFangSC-Regular, PingFang SC;
    box-shadow: 0px -1px 1px 0px #EEEEEE;

    .bottom_btn {
        background: #3975C6;
        color: #ffffff;
        width: 250px;
        height: 60px;
        line-height: 60px;
        border-radius: 5px;
        margin-bottom: 20px;
    }
}

.color_orange {
    color: #F7B500 !important;
}

.color_blue {
    color: #4871C0 !important;
}

.color_red {
    color: #FF2D2D !important;
}

.bottom_main {
    margin-top: 8px;
    background: #fff;
    padding: 22px 16px;
    color: #333333;
    font-size: 16px;

    .cont_div {
        .cont_value {
            text-align: right;
        }
    }

    .payway {
        margin-bottom: 16px;

        .cont_label {
            margin-bottom: 10px;
        }

        .cont_label1 {
            color: #999999;
            font-size: 14px;
        }

        .list_style {
            display: flex;
            justify-content: space-between;
            margin: 0px 6px 8px 6px;
            .left {
                color: #999999;
                font-size: 14px;
            }

            .right {
                font-family: PingFangSC-Regular, PingFang SC;
                font-size: 16px;
                color: #333333;
                width: 220px;
                padding: 17px;
                background: #F8FAFF;

                .img_list {
                    display: flex;
                    margin-top: 24px;
                    justify-content: space-between;

                    .top {
                        white-space: nowrap;
                    }

                    .img_box {
                        display: flex;
                        flex-wrap: wrap;

                        img {
                            margin: 0px 4px 4px 4px;
                        }
                    }
                }
            }
        }
    }

    .remark_bottom {
        color: #999999;
        font-size: 14px;
    }

    .remark_bottom1 {
        color: #333333;
        font-size: 16px;
    }

    .way_list {
        padding-left: 10px;

        .left_nav {
            white-space: nowrap;
            position: relative;
            margin-right: 25px;

            &::before {
                position: absolute;
                left: -10px;
                top: 2px;
                color: #ee0a24;
                font-size: 14px;
                content: '*';
            }
        }

        .content {
            .up-style-img {
                border: 1px dashed #eee;
                background-color: #F8FAFF;
                border-radius: 5px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                margin: 0 5px 5px 0;
                width: 64px;
                height: 64px;

                span {
                    margin-top: 10px;
                }
            }

            ::v-deep .label_left {
                color: #333333;
                font-size: 16px;
                font-weight: 400;
            }

            ::v-deep .van-cell-group {
                background: #F8FAFF;
                border-radius: 5px;
                overflow: hidden;

                .van-cell {
                    background: #F8FAFF;
                }
            }

            ::v-deep .van-uploader__preview-image {
                border-radius: 5px;
                overflow: hidden;
                width: 64px;
                height: 64px;
            }

            ::v-deep .van-uploader__preview-delete {
                width: 20px;
                height: 20px;
                top: 5px;
                right: 5px;
                border-radius: 100%;

                .van-uploader__preview-delete-icon,
                .van-icon {
                    font-size: 24px;
                }
            }
        }
    }

    ::v-deep .van-cell__value--alone {
        background: #F8FAFF;
        padding: 8px;
        font-weight: 400;
        font-family: PingFangSC-Regular, PingFang SC;
    }
}</style>

