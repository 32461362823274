<template>
    <div>
        <headerNav title="话务进线排行榜详情" :leftIcon="true" @leftClick="back">
            <template>
                <span @click="openSearch" class="headNav-righ-text">
                    筛选
                    <van-icon name="filter-o" />
                </span>
            </template>
        </headerNav>
        <div class="page-box">
            <div class="moudule2-box">
                <el-table :data="inboundLeaderboardData" header-row-class-name="table-head-class" @sort-change="sortChange">
                    <el-table-column type="index" label="TOP" align="center" width="60">
                        <template #default="scope">
                            <span class="cell-img-box" v-if="scope.$index == 0"><img
                                    :src="require('@/assets/hw-s7.svg')"></span>
                            <span class="cell-img-box" v-else-if="scope.$index == 1"><img
                                    :src="require('@/assets/hw-s8.svg')"></span>
                            <span class="cell-img-box" v-else-if="scope.$index == 2"><img
                                    :src="require('@/assets/hw-s10.svg')"></span>
                            <span v-else>{{ scope.$index + 1 }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="telephonistName" label="话务手" width="100" />
                    <el-table-column prop="marketName" label="市场" width="80">
                        <template #default="scope">
                            {{ scope.row.marketName.replace('市场', '') }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="clueAllocation" sortable="custom" label="线索量" align="right" width="100" />
                    <el-table-column prop="clueValid" sortable="custom" label="有效量" align="right" width="100" />
                    <el-table-column prop="validShopNum" sortable="custom" label="有效到店量" align="right" width="130" />
                    <el-table-column prop="shopRate" sortable="custom" label="到店率" align="right" width="100">
                        <template #default="scope">
                            {{ scope.row.shopRate }}%
                        </template>
                    </el-table-column>
                    <div slot="empty" style="text-align: left;">
                        <div class="table-empty">
                            <img :src="require('@/assets/m-empty.png')">
                            <span>暂无数据</span>
                        </div>
                    </div>
                </el-table>
            </div>
            <van-popup v-model="popupShow" class="my-popup" overlay-class="my-popup-cover" position="bottom" :safe-area-inset-bottom="true"
                :style="{ height: '374px' }">
                <div class="my-popup-box">
                    <div class="my-popup-head">
                        筛选
                        <van-icon name="cross" size="16" @click="popupShow = false" />
                    </div>
                    <div class="my-popup-content">
                        <p>选择时间</p>
                        <ul>
                            <li v-for="item in dateTypeList" :class="item.type == dateType ? 'active-li' : ''"
                                @click="changeDateType(item)">{{ item.name }}</li>
                        </ul>
                        <p>选择市场</p>
                        <ul>
                            <li v-for="item in marketList" :class="item.value == marketCode ? 'active-li' : ''"
                                @click="changeMarket(item)">{{ item.text }}</li>
                        </ul>
                    </div>
                    <div class="my-popup-footer">
                        <van-button type="default" @click="restSearch">重置</van-button>
                        <van-button type="info" @click="sureSearch">确认</van-button>
                    </div>
                </div>
            </van-popup>
        </div>
    </div>
</template>

<script>
import api from '@/api/api.js'
import moment from 'moment';

export default {
    data () {
        return {
            popupShow: false,
            dateType: '1',
            chooseSureDateType: '1',
            dateTypeList: [
                {
                    type: '1',
                    name: '今日',
                },
                {
                    type: '2',
                    name: '本周',
                },
                {
                    type: '3',
                    name: '本月',
                },
                {
                    type: '10',
                    name: '本年',
                }
            ],
            marketCode: 'all',
            chooseMarketCode: 'all',
            marketList: [
                { text: '全部', value: 'all' },
                { text: '济南', value: '370100' },
                { text: '青岛', value: '370200' },
                { text: '淄博', value: '370300' },
                { text: '烟台', value: '370600' },
                { text: '潍坊', value: '370700' },
                { text: '威海', value: '371000' },
                { text: '临沂', value: '371300' },
            ],
            inboundLeaderboardData: [],
        }
    },
    methods: {
        //打开筛选
        openSearch () {
            this.popupShow = true
            this.dateType = this.chooseSureDateType
            this.marketCode = this.chooseMarketCode
        },
        changeDateType (item) {
            //时间类型改变
            this.dateType = item.type
        },
        changeMarket (item) {
            //市场改变
            this.marketCode = item.value
        },
        restSearch () {
            //重置
            this.dateType = '1'
            this.marketCode = 'all'
            this.chooseMarketCode = this.marketCode
            this.chooseSureDateType = this.dateType
            this.popupShow = false
            this.heavyLoad()
        },
        sureSearch () {
            //确认搜索
            this.chooseMarketCode = this.marketCode
            this.chooseSureDateType = this.dateType
            this.popupShow = false
            this.heavyLoad()
        },
        //话务进线排行榜
        async getInboundLeaderboard ({ field, order }) {
            let { type, marketCodeValue, reportData1, reportData2, contrastData1, contrastData2  } = this.getParams()
            let sortField = 'shop_rate1,valid_shop_num,clue_valid'
            if (field == 'clueAllocation') sortField = 'clue_allocation'
            if (field == 'clueValid') sortField = 'clue_valid'
            if (field == 'validShopNum') sortField = 'valid_shop_num'
            let obj = {
                ext: [1, 2, 5, 6].find(e => e == type) ? 'day' : 'month',
                queryStr: JSON.stringify(
                    {
                        ext: [1, 2, 5, 6].find(e => e == type) ? 'day' : 'month',
                        marketCode: marketCodeValue,
                        reportData1,
                        reportData2,
                        oldReportData1: contrastData1,
                        oldReportData2: contrastData2,
                        type,
                        sortOrder: order ? order.replace('ing', '') : 'descend',
                        sortField: sortField
                    }
                )
            }
            let res = await api.inboundLeaderboard(obj)
            this.inboundLeaderboardData = res.data
        },
        //排序
        sortChange ({ prop, order }) {
            this.getInboundLeaderboard({ field: prop, order: order })
        },
        //请求参数
        getParams (timeType) {
            let type = this.chooseSureDateType
            let reportData1 = ''
            let reportData2 = ''
            let oldReportData1 = ''
            let oldReportData2 = ''
            let contrastData1 = ''
            let contrastData2 = ''
            if (this.chooseSureDateType == 1) {
                //本日
                reportData1 = moment().format('YYYY-MM-DD');
                reportData2 = moment().format('YYYY-MM-DD');
                oldReportData1 = moment(moment().year(moment().year() - 1)).format('YYYY-MM-DD');
                oldReportData2 = moment(moment().year(moment().year() - 1)).format('YYYY-MM-DD');
                contrastData1 = moment().subtract(1, 'days').format('YYYY-MM-DD');
                contrastData2 = moment().subtract(1, 'days').format('YYYY-MM-DD');
            } else if (this.chooseSureDateType == 2) {
                type = 1
                //本周
                reportData1 = moment().startOf('week').format('YYYY-MM-DD') //本周一
                reportData2 = moment().format('YYYY-MM-DD'); //当天
                oldReportData1 = moment(reportData1).subtract(1, 'years').format('YYYY-MM-DD');
                oldReportData2 = moment(reportData2).subtract(1, 'years').format('YYYY-MM-DD');
                contrastData1 = moment(reportData1).subtract(7, 'days').format('YYYY-MM-DD');
                contrastData2 = moment(reportData2).subtract(7, 'days').format('YYYY-MM-DD')
            } else if (this.chooseSureDateType == 3) {
                type = 1
                //本月
                reportData1 = moment().startOf('month').format('YYYY-MM-DD')
                reportData2 = moment().format('YYYY-MM-DD'); //当天
                oldReportData1 = moment(reportData1).subtract(1, 'years').format('YYYY-MM-DD');
                oldReportData2 = moment(reportData2).subtract(1, 'years').format('YYYY-MM-DD');
                contrastData1 = moment(reportData1).subtract(1, 'month').format('YYYY-MM-DD');
                contrastData2 = moment(reportData2).subtract(1, 'month').format('YYYY-MM-DD')
            } else if (this.chooseSureDateType == 6) {
                type = 1
                //昨日
                reportData1 = moment().subtract(1, 'day').format('YYYY-MM-DD');
                reportData2 = moment().subtract(1, 'day').format('YYYY-MM-DD');
                oldReportData1 = moment(reportData1).subtract(1, 'years').format('YYYY-MM-DD');
                oldReportData2 = moment(reportData2).subtract(1, 'years').format('YYYY-MM-DD');
                contrastData1 = moment().subtract(2, 'days').format('YYYY-MM-DD');
                contrastData2 = moment().subtract(2, 'days').format('YYYY-MM-DD');
            } else if (this.chooseSureDateType == 10) {
                //本年
                if (moment().month() > 1 && timeType != 'day') {
                    //当前本年大于2月 
                    type = '3'
                    reportData1 = moment().startOf('year').format('YYYY-MM')
                    reportData2 = moment().format('YYYY-MM'); //当月
                    oldReportData1 = moment(reportData1).subtract(1, 'years').format('YYYY-MM');
                    oldReportData2 = moment(reportData2).subtract(1, 'years').format('YYYY-MM');
                    contrastData1 = moment(reportData1).subtract(1, 'years').format('YYYY-MM');
                    contrastData2 = moment(reportData2).subtract(1, 'years').format('YYYY-MM');
                } else {
                    //当前本年不大于2月
                    type = '1'
                    reportData1 = moment().startOf('year').format('YYYY-MM-DD')
                    reportData2 = moment().format('YYYY-MM-DD'); //当天
                    oldReportData1 = moment(reportData1).subtract(1, 'years').format('YYYY-MM-DD');
                    oldReportData2 = moment(reportData2).subtract(1, 'years').format('YYYY-MM-DD');
                    contrastData1 = moment(reportData1).subtract(1, 'years').format('YYYY-MM');
                    contrastData2 = moment(reportData2).subtract(1, 'years').format('YYYY-MM');
                }
            }
            return {
                type, reportData1, reportData2,
                oldReportData1, oldReportData2,
                marketCodeValue: this.chooseMarketCode ? this.chooseMarketCode : 'all',
                channelType: this.mediaType ? this.mediaType : 'all',
                contrastData1,
                contrastData2

            }
        },

        //数据重载
        heavyLoad () {
            this.getInboundLeaderboard({ field: 'shopRate', order: 'descend' })
        },
        back () {
            this.$router.go(-1)
        }
    },
    created () {
        this.dateType = this.$route.query.type
        this.marketCode = this.$route.query.marketCodeValue
        this.chooseMarketCode = this.marketCode
        this.chooseSureDateType = this.dateType
        this.heavyLoad()
    }
}
</script>

<style lang="scss" scoped>
.headNav-righ-text {
    font-size: 14px;
}

.my-popup-cover {
    background: rgba(0, 0, 0, 0.5);
}

.my-popup {
    background: #FFFFFF;
    border-radius: 16px 16px 0px 0px;

    .my-popup-box {
        .my-popup-head {
            line-height: 48px;
            border-bottom: 1px solid #DFDFDF;
            text-align: center;
            font-size: 16px;
            font-family: PingFang SC-Bold, PingFang SC;
            font-weight: bold;
            color: #424242;
            position: relative;

            i {
                position: absolute;
                right: 19px;
                top: calc(50% - 8px);
            }
        }

        .my-popup-content {
            padding: 16px;
            padding-bottom: 10px;

            &>p {
                text-align: left;
                font-size: 14px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #424242;
            }

            ul {
                display: flex;
                flex-wrap: wrap;
                gap: 14px;
                margin-bottom: 21px;
            }

            li {
                width: 75px;
                line-height: 32px;
                background: #F9F9F9;
                border-radius: 4px 4px 4px 4px;
                font-size: 14px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
            }

            .active-li {
                background: #3DA2FF;
                color: #FFFFFF;
            }
        }

        .my-popup-footer {
            button {
                width: 164px;
                height: 44px;
                border-radius: 8px;

                &:first-child {
                    margin-right: 12px;
                }
            }
        }
    }


}

.page-box {
    height: calc(100vh - 50px);
    overflow: auto;
}

.table-empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
        width: 160px;
        margin-bottom: 10px
    }

    span {
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #5E5E5E;
    }
}
::v-deep .el-table__empty-block {
	width: 100%;
	min-width: 100%;
	max-width: 100%;
}
::v-deep {
    .table-head-class th {
        background: #F5F5F5;
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #1D2129;
    }

    .el-table__body-wrapper {
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #1D2129;

        tr {
            .el-table__cell {

                &:nth-child(4),
                &:nth-child(5),
                &:nth-child(6),
                &:nth-child(7) {
                    font-size: 16px;
                    font-family: Bahnschrift-Light, Bahnschrift;
                    font-weight: 300;
                    color: #1D2129;
                }

                .cell-img-box {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                img {
                    width:20px;
                }
            }

        }
    }

    .pie-tooltip {
        display: flex;
        color: red;

        p {
            flex: 1;
            padding: 0 11px;
            margin: 0;
            font-size: 14px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 44px;

            &:nth-child(2) {
                font-size: 16px;
                justify-content: flex-end;
            }
        }
    }

}</style>