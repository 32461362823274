<template>
  <div class="content market-content">
    <headerNav leftNav=" " :title="navTitle" @leftClick="back"></headerNav>
    <main class="content-main">
      <!-- 汇总 -->
      <BoardNavSummarize 
      :imgBackUrlName="typeList.bgIcon" 
      :ratio="indicatorsData.finishRate" 
      :completionsCount="indicatorsData.finishValue"
      :sumTotal="indicatorsData.targetValue"
      :leftIconName="typeList.leftIcon"
      :leftText="typeList.leftText"
      :rightIconName="typeList.rightIcon"
      :rightText="typeList.rightText"
      :unit="typeList.unit"
      :targetName="typeList.operate"
      :customerName="indicatorsData.userName"
      :isAbnormity="indicatorsData.isException == '1'?true:false"
      />
      <div class="content-center-title">
        <div>详情</div>
      </div>

      <!-- table -->
      <el-table :data="tableData" style="width: 100%"
        header-cell-class-name="market-title" row-class-name="market-row" row-key="indexCode" :expand-row-keys="defaultExpandList">
        <!-- 自定义暂无数据 -->
        <template slot="empty">
          <div class="empty-no-data">
            <img src="../../../../assets/task-management/no-data.png" alt="">
            <div>暂无数据</div>
          </div>
        </template>
        <el-table-column type="index" label="TOP" align="center" width="43">
          <template slot-scope="props">
            <div v-if="props.$index < 3" style="line-height: 1;"><img class="index-img" :src="getImageIndex(props.$index)" alt=""></div>
            <div v-else> {{ props.$index + 1 }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="indexName" label="招聘任务" min-width="110">
          <template slot-scope="props">
            <span v-if="roleData.roleCode == 'ROLE_477142'" class="market-content-a" @click="onClickDetails(props.row)">{{ props.row.indexName }}</span>
            <span v-else class="market-content-text" >{{ props.row.indexName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="finishValue" label="试岗人数" align="right" min-width="80">
        </el-table-column>
        <el-table-column prop="targetValue" label="任务人数" align="right" min-width="80">
        </el-table-column>
        <el-table-column prop="finishRate" label="完成率" align="right" min-width="72">
          <template slot-scope="scope">
            <span class="complete">{{ scope.row.finishRate }}%</span>
          </template>
        </el-table-column>
      </el-table>
    </main>
  </div>
</template>

<script>
import BoardNavSummarize from "@/views/task-management/component/public/module/BoardNavSummarize.vue"
import { employeeModelFn } from "./data.js"
import api from "@/api/task-management/human-resources.js";
import { Toast } from "vant";
import moment from "moment";

export default {
  components: {
    BoardNavSummarize
  },
  data () {
    return {
      // 当前登录人信息
      roleData:null,
      // 任务看板路由接参
      routerData: {},
      // 指标
      indicatorsData:{},
      // 市场表格数据
      tableData:[],
      // 展开行id
      defaultExpandList: [],
      // 模块类型数据 
      typeList:{},
    }
  },
  created () {
    this.roleData = JSON.parse(localStorage.getItem("USER_ROLE"));
    let localData = JSON.parse(localStorage.getItem("TABLE_HEADER_CONTENT"));
    this.routerData = localData;
    this.typeList = employeeModelFn(localData.termSlug)
    this.navTitle = this.typeList.name + '详情'
  },
  mounted() {
    this.getTableData()
  },
  methods: {
    back () {
      this.$router.go(-1)
    },
    // 查询表格数据
    async getTableData () {
        Toast.loading({
          message: '加载中...',
          forbidClick: true,
        });
        let params = {
          "type": "3", //1=市场；2=人员；3=任务
          "ext": this.typeList.type,
          "Q_reportData_EQ": moment().format("YYYY-MM"),
          "indexCode":this.$route.query.indexCode,
          "marketCode":this.$route.query.marketCode,
        }
        api.getTasksDetail(params).then((res)=>{
          if (res.code == 200) {
            Toast.clear()
            this.indicatorsData = res.data.statisticsObject
            if(res.data.detailList){
              res.data.detailList.forEach((item,index)=>{
                item.finishRate = parseFloat(item.finishRate).toFixed(2)
              })
            }
            this.tableData = res.data.detailList || []
          }else{
            Toast.fail(res.msg)
          }
        })
    },
    // 获取图片
    getImageIndex (index) {
      return require(`@/assets/task-management/task-details-table-${index + 1}.png`)
    },
    // 点击查看详情
    onClickDetails (row) {
      this.$router.push({
        name:"TaskBoardHumanResourcesEmployeeSingle",
        query:{
          type:this.typeList.type,
          item:JSON.stringify(row)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped src="../../component/public/css/table.scss"></style>
<style lang="scss" scoped>
  .table-prompt-icon{
    width: 14px;
    height: 14px;
    vertical-align: middle;
  }
  .content-center-title{
    div:nth-child(1){
      position: relative;
      padding-left: 12px;
    }
    div:nth-child(1)::after{
      content: '';
      width: 4px;
      height: 15px;
      background: #0E8BFF;
      border-radius: 1px 1px 1px 1px;
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -7.5px;
    }
  }
  ::v-deep{
    .market-row {

      .cell{
        overflow: hidden !important;
        white-space: nowrap !important;
        text-overflow: ellipsis !important;
      }
    }
  }
</style>