<template>
    <div>
        <headerNav leftNav="返回" class="nav_b" @leftClick="back" :title="routerRow.title" />
        <!-- 图表 -->
        <div class="echartMain"></div>
    </div>
</template>

<script>
import * as echarts from 'echarts';
export default {

    data () {
        return {
            routerRow: {},
            dataSource: [
                ['product', '2018', '2019', '2020', '2021', '2022'],
            ]
        }
    },
    created () {
        this.routerRow = JSON.parse(this.$route.query.row)
    },
    mounted () {
        this.$nextTick(() => {
            this.handleEcharts()
            this.handleSetData()
        })
    },
    methods: {
        handleEcharts () {
            var chartDom = document.querySelector('.echartMain');
            var myChart = echarts.init(chartDom);
            var option;
            let that = this
            setTimeout(function () {
                option = {
                    legend: {}, 
                    tooltip: {
                        trigger: 'axis',
                        showContent: true
                    },
                    dataset: {
                        source: that.dataSource
                    },
                    xAxis: { type: 'category' },
                    yAxis: { gridIndex: 0 },
                    grid: { top: '55%' },
                    series: [
                        {
                            type: 'line',
                            smooth: true,
                            seriesLayoutBy: 'row',
                            emphasis: { focus: 'series' },
                            areaStyle: {
                                color: '#5470C6',
                                opacity: 0.5
                            }
                        },
                        {
                            type: 'line',
                            smooth: true,
                            seriesLayoutBy: 'row',
                            emphasis: { focus: 'series' },
                            areaStyle: {
                                color: '#91CC75',
                                opacity: 0.5
                            }
                        },
                        {
                            type: 'line',
                            smooth: true,
                            seriesLayoutBy: 'row',
                            emphasis: { focus: 'series' },
                            areaStyle: {
                                color: '#FAC858',
                                opacity: 0.5
                            }
                        },
                        {
                            type: 'line',
                            smooth: true,
                            seriesLayoutBy: 'row',
                            emphasis: { focus: 'series' },
                            areaStyle: {
                                color: '#EE6666',
                                opacity: 0.5
                            }
                        },
                        {
                            type: 'pie',
                            id: 'pie',
                            radius: '30%',
                            center: ['50%', '25%'],
                            emphasis: {
                                focus: 'self'
                            },
                            label: {
                                formatter: '{b}: {@2018} ({d}%)'
                            },
                            encode: {
                                itemName: 'product',
                                value: '2018',
                                tooltip: '2018'
                            },
                        }
                    ],
                    roseType: 'area'
                };
                myChart.on('updateAxisPointer', function (event) {
                    const xAxisInfo = event.axesInfo[0];
                    if (xAxisInfo) {
                        const dimension = xAxisInfo.value + 1;
                        myChart.setOption({
                            series: {
                                id: 'pie',
                                label: {
                                    formatter: '{b}: {@[' + dimension + ']} ({d}%)'
                                },
                                encode: {
                                    value: dimension,
                                    tooltip: dimension
                                }
                            }
                        });
                    }
                });
                myChart.setOption(option);
            });


            option && myChart.setOption(option);
        },
        //回退
        back () {
            this.$router.push({
                name: "statistics",
            })
        },
        // 设置数据
        handleSetData () {
            if (this.routerRow.id == 1) {
                this.dataSource.push(['A类', 56.5, 82.1, 88.7, 70.1, 53.4])
            }
            if (this.routerRow.id == 2) {
                this.dataSource.push(['B类', 51.1, 51.4, 55.1, 53.3, 73.8])
            }
            if (this.routerRow.id == 3) {
                this.dataSource.push(['C类', 40.1, 62.2, 69.5, 36.4, 45.2])
            }
            if (this.routerRow.id == 4) {
                this.dataSource.push(['D类', 25.2, 37.1, 41.2, 18, 33.9])
            }
            if (this.routerRow.id == 5) {
                this.dataSource.push(
                    ['A类', 56.5, 82.1, 88.7, 70.1, 53.4],
                    ['B类', 51.1, 51.4, 55.1, 53.3, 73.8],
                    ['C类', 40.1, 62.2, 69.5, 36.4, 45.2],
                    ['D类', 25.2, 37.1, 41.2, 18, 33.9]
                )
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.nav_b {
    background: #0B0B67;
}
.echartMain {
    width: 100%;
    margin-top: 20px;
    height: 600px;
}
</style>