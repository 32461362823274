<template>
	<div class="box">
		<router-view />
	</div>
</template>

<script>
	export default {
		created() {
			// let uid1 = this.$route.query.uid1
			// let uid2 = this.$route.query.uid2
			// let uid3 = this.$route.query.uid3
			// let uid4 = this.$route.query.uid4
			// sessionStorage.setItem('uid1','bc13730f-5e68-488a-8f70-f67174fc9941')
			// sessionStorage.setItem('uid2','436663')
			// sessionStorage.setItem('uid3','1656300657632')
			// sessionStorage.setItem('uid4','a09cb0c9f064b7870efd5f92821997f8cce1544e2c3e806717fc30c78197b209')
		},
		data() {
			return {}
		},
		methods: {


		},
		mounted() {

		},
	}
</script>

<style scoped lang="scss">
	.box {
		height: 100vh;
		// width: 100%;
		background-color: #F5F6F8;
	}
</style>
<style lang="scss">
	.vant-list-style {
		.van-list__finished-text {
			font-size: 11px;
			// font-family: Source Han Sans SC;
			font-weight: 400;
			color: #AAAAAA;
			// height: 40px;
			// line-height: 20px;
		}

		.van-loading__text {
			font-size: 11px;
			// font-family: Source Han Sans SC;
			font-weight: 400;
			color: #AAAAAA;
		}
	}
</style>
