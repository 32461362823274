<template>
  <div class="content employees-content">
    <van-sticky class="topMenu">
      <headerNav leftNav=" " 
      :title="targetObj.navTitle" 
      @leftClick="back" 
      :rightNav="topNavHandel.text" 
      :rightIcon="topNavHandel.icon" 
      @rightClick="onClickNavRight"
      :showRightContent="showRightContent"></headerNav>
    </van-sticky>

    <main class="content-main">
      <!-- 汇总 -->
      <BoardNavSummarize 
      :imgBackUrlName="targetObj.imgUrl" 
      :ratio="taskPlanObj.average" 
      :completionsCount="taskPlanObj.reachStandard"
      :sumTotal="taskPlanObj.noReachStandard"
      :leftIconName="targetObj.leftIconName" 
      :leftText="targetObj.collect1" 
      :rightIconName="targetObj.rightIconName"
      :rightText="targetObj.collect2" 
      :unit="targetObj.unit" 
      :targetName="targetObj.collect3" 
      :navTitleUnit="targetObj.value == 'overview_report'? ' ':'%'"/>

      <!-- 人员详情 -->
      <div class="content-center-title">
        <div class="left-line"></div>
        <div>人员详情</div>
      </div>

      <!-- table -->
      <el-table :data="userTableData" header-cell-class-name="market-title" row-class-name="market-row" row-key="userId"
        :expand-row-keys="defaultExpandList" :highlight-current-row="false">
        <!-- 自定义暂无数据 -->
        <template slot="empty">
          <div class="empty-no-data">
            <img src="../../../../assets/task-management/no-data.png" alt="">
            <div>暂无数据</div>
          </div>
        </template>
        <el-table-column type="index" label="TOP" align="center" width="40">
          <template slot-scope="props">
            <div v-if="props.$index < 3" style="line-height: 1;"><img class="index-img" :src="getImageIndex(props.$index)"
                alt=""></div>
            <div v-else> {{ props.$index + 1 }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="userName" label="人员" min-width="60" show-overflow-tooltip>
          <template slot-scope="props">
            <div class="anomaly">
              <!-- 预算预估跳转 -->
              <span v-if="targetObj.value == 'estimate_profit' || targetObj.value == 'settle_accounts_profit'" class="market-content-a" @click="onClickPersonnelDetails(props.row)">
                {{ props.row.userName }}
              </span>
              <span v-else class="market-content-text">{{ props.row.userName }}</span>
              <div class="anomaly-bg" v-if="props.row.isSpecial == '1'">异常</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column v-for="(item,index) in targetObj.table" :key="index" :prop="item.props" :label="item.label" align="right"
          min-width="85">
          <template slot="header" slot-scope="scope">
           <div class="table-custom">
            <span>{{ item.label }}</span>
            <!-- 综述展示得分规则 -->
            <el-tooltip class="tooltip-content" effect="dark" placement="top-end" :offset="-10" v-if="item.isShowTooltip">
              <div slot="content" v-if="item.tooltip">{{ targetObj.tooltip }}</div>
              <div slot="content" v-else>满分100分；以延期提交(1天2分)、<br/>错漏处(1处1分)进行减分</div>
              <img class="table-img" src="../../../../assets/task-management/table-hint.png" alt="">
            </el-tooltip>
           </div>
          </template>
          <template slot-scope="scope">
            <span class="complete">
              {{ (targetObj.value == 'site_routing_inspection' && scope.row[item.props] != '-' ?Number(scope.row[item.props]).toFixed(0):scope.row[item.props] )
              + (scope.row[item.props] != '-'?item.unit :'') }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="handle" label="操作" min-width="38">
          <template slot-scope="scope">
            <span class="market-details" @click="expandControlRow(scope.row)">详情</span>
          </template>
        </el-table-column>
        <!-- 展开行详情 -->
        <el-table-column type="expand" width="1">
          <template slot-scope="scope">
            <div class="market-content-expand">
              <div class="market-content-expand-left">
                <!-- 判断是综述就显示最终得分 -->
                <img :src="require(`../../../../assets/task-management/${targetObj.expandIcon}.png`)" alt="">
                <div>{{targetObj.expandName}}
                  {{ scope.row.lastMonthData}}
                  {{ targetObj.value == 'overview_report' ? '分' : '%' }}
                </div>
              </div>
              <div class="market-content-expand-right">
                <span class="color-green" v-if="scope.row.isSuccess == '1'">已完成</span>
                <span class="color-red"  v-if="scope.row.isSuccess == '0'">未完成</span>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </main>
  </div>
</template>

<script>
import BoardNavSummarize from "@/views/task-management/component/public/module/BoardNavSummarize.vue"
import {estimateOutput} from "./data.js"
import api from "@/api/task-management/finance.js";
import api2 from "@/api/task-management/inquiry.js";
import { Toast } from "vant";

export default {
  components: {
    BoardNavSummarize
  },
  data () {
    return {
      routerData: {},// 任务看板路由接参
      userTableData: [],// 表格数据
      defaultExpandList: [], // 展开行id-
      setTaskDialogVisible: false, // 任务设置弹窗
      taskDialogFrom: {
        value: ''
      },
      fromRestFields: 0,// 清空form校验
      outputCount: {},// 表头动态展示内容
      setTaskRole: false,// 判读任务设置是否有权限
      taskPlanObj: {},// 顶部任务产值目标
      setRowsData: {}, // 任务设置当前行数据
      targetObj:{},// 当前指标
      topNavHandel:{ // 顶部导航右侧操作显示
        icon:"task-board-nav-right-city",
        text:"市场综述报告"
      },
      showRightContent:false, // 控制导航顶部是否展示
      // 是否显示子任务
      isAllocation:false
    }
  },
  created () {
    let localData = JSON.parse(localStorage.getItem("TABLE_HEADER_CONTENT"));
    let userData = JSON.parse(localStorage.getItem("MARKET_DATA"));
    this.routerData = { ...localData, ...userData };
    this.targetObj = estimateOutput(localData.termSlug);
    this.getShowTask()
    this.getTableData()
    // 预估利润、结算利润、结转利润
    if(this.targetObj.value == 'estimate_profit' || this.targetObj.value == 'settle_accounts_profit' || this.targetObj.value == 'balance_profit'){
      this.targetObj.collect1 = '达标人数'
      this.targetObj.collect2 = '未达标人数'
    }
    // 预估利润、结算利润 并且是经理和专员
    if(this.targetObj.value != 'estimate_profit' && this.targetObj.value  != 'settle_accounts_profit' && this.routerData.roleCode == 'ROLE_922446'){
      this.showRightContent = true
      if(this.targetObj.value != 'overview_report' ){
        this.topNavHandel = {
          icon:"task-board-nav-right-person",
          text:"个人任务"
        }
      }
    }
   
  },
  methods: {
    back () {
      this.$router.go(-1)
    },
    // 查询表格数据
    async getTableData () {
      try {
        Toast.loading({
          message: '加载中...',
          forbidClick: true,
        });
        const { data } = await api.detailListApi({
          ext: this.routerData.termSlug,
          Q_reportData_EQ: this.routerData.Q_reportData_EQ,
          type: "2",
          marketCode: this.routerData.marketCode,
        });
        this.taskPlanObj = data
        this.userTableData = data.data01
      } finally {
        Toast.clear()
      }
    },
    // 查询是否分配子任务
    async getShowTask(){
      const {data} = await api2.getShowPersonalTask(this.routerData.termSlug)
      this.isAllocation = data
    },
    // 控制展开行
    expandControlRow (item) {
      const position = this.defaultExpandList.indexOf(item.userId)
      if (position !== -1) {
        this.defaultExpandList.splice(position, 1)
      } else {
        this.defaultExpandList.push(item.userId)
        this.getExpandRowDetails(item)
      }
    },
     /* 查询展开行详情 */
     async getExpandRowDetails(row) {
      const { data } = await api2.lastMonthDetail({
        termId: row.termId,
        selectId:row.selectId,
        type:2
      });
      this.userTableData = this.userTableData.map((item) => {
        if (item.userId == row.userId) {
          item = { ...item, ...data };
        }
        return item;
      });
    },
    // 判断表头是否显示提示信息
    isShowTooltip(){
      // 预估、结算、结转、综述报告
      if(this.targetObj.value == 'estimate_profit' || this.targetObj.value == 'settle_accounts_profit' || this.targetObj.value == 'balance_profit' || this.targetObj.value == 'overview_report'){
        return true
      }else{
        return false
      }
    },
    // 获取图片
    getImageIndex (index) {
      return require(`@/assets/task-management/task-details-table-${index + 1}.png`)
    },
    // 点击个人任务或者市场综述
    onClickNavRight(){
      if(!this.isAllocation){
        Toast.fail('暂无个人任务')
        return
      }
      this.$router.push({ path: '/task-management/board/inquiry/employee-details'})
    },
    // 点击人员查看人员详情
    onClickPersonnelDetails (row) {
      this.$router.push({ path: '/task-management/board/inquiry/employee-single',query:{row:JSON.stringify(row)}})
    }
  },
}
</script>

<style lang="scss" scoped src="../../component/public/css/table.scss"></style>
<style lang="scss" scoped>
.employees-content::v-deep {
  .content-main {
    .el-table__body-wrapper {
      // height: calc(100vh - 332px) !important;
      overflow: auto;
    }
    .table-custom{
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .table-img{
        width: 14px;
        height: 14px;
        margin-left: 3px;
      }
    }
  }
  .anomaly {
    position: relative;

    .anomaly-bg {
      height: 19px;
      width: 39px;
      text-align: center;
      color: #fff;
      position: absolute;
      font-size: 12px;
      top: -8px;
      right: -25px;
      line-height: 19px;
      background: url("../../../../assets/task-management/employee-list-anomaly.png")
        no-repeat;
      background-position: 100%;
      background-size: cover;
      z-index: 10;
    }
  }
  .content-main .market-row .cell {
    overflow: visible;
  }
  .el-table__cell {
    padding: 3px 0px;
    height: 30px;
    overflow: visible;
    .cell {
      padding: 5px 3px;
      font-weight: 400;
      overflow: visible;
      white-space: nowrap;

      .anomaly {
        .market-content-a {
          white-space: nowrap;
          // /* 不换行 */
          overflow: hidden;
          // /* 隐藏超出部分 */
          text-overflow: ellipsis;
          display: block;
        }
      }
    }
  }
}
</style>