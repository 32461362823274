<template>
    <div class="clientdata">
        <van-cell title="客户姓名" is-link :value="dataInfo.consumerName" value-class="valueclass"
            @click="openEdit('consumerName')" maxlength="10" :class="disabledStatus != '2'?'none-arrow':''"/>
        <van-cell title=" 真实姓名" is-link :value="dataInfo.realityName" value-class="valueclass"
            @click="openEdit('realityName')" maxlength="10" :class="disabledStatus != '2'?'none-arrow':''"/>
        <van-cell title="微信昵称" is-link :value="dataInfo.wechatName" value-class="valueclass"
            @click="openEdit('wechatName')" maxlength="10" :class="disabledStatus != '2'?'none-arrow':''"/>
        <van-cell title="微信号" is-link :value="dataInfo.wechatNo" value-class="valueclass" @click="openEdit('wechatNo')"
            maxlength="15"  :class="disabledStatus != '2'?'none-arrow':''"/>
        <van-cell title="关注点" is-link :value="dataInfo.tagName" value-class="valueclass"
            @click="openConfirm('tagCode', 'tagName')" :class="disabledStatus != '2'?'none-arrow':''"/>

        <!-- <van-cell title="客户意向" is-link :value="dataInfo.tagName" value-class="valueclass" /> -->
        <van-cell title="客户等级" is-link :value="dataInfo.gradeCode" value-class="valueclass" class="none-arrow"/>
        <van-cell title="区域" is-link :value="dataInfo.area" value-class="valueclass" @click="chooseArea()" :class="disabledStatus != '2'?'none-arrow':''"/>
        <van-cell title="详细地址" is-link :value="dataInfo.detailAddress" value-class="valueclass"
            @click="openEdit('detailAddress')" :class="disabledStatus != '2'?'none-arrow':''" />
        <van-cell title="房屋类型" is-link :value="dataInfo.houseStyleName" value-class="valueclass"
            @click="openConfirm('houseStyle')" :class="disabledStatus != '2'?'none-arrow':''"/>
        <van-cell title="装修面积" is-link :value="dataInfo.areaQty" value-class="valueclass"
            @click="openEdit('areaQty')" :class="disabledStatus != '2'?'none-arrow':''"/>
        <van-cell title="装修风格" is-link :value="dataInfo.styleName" value-class="valueclass"
            @click="openConfirm('styleCode', 'styleName')" :class="disabledStatus != '2'?'none-arrow':''"/>
        <van-cell title="户型" is-link :value="dataInfo.houseTypeName" value-class="valueclass"
            @click="openConfirm('houseType')" :class="disabledStatus != '2'?'none-arrow':''"/>
        <van-popup v-model="showPicker" position="bottom">
            <van-picker v-if="showPicker" show-toolbar :columns="columns" @confirm="onConfirm"
                :default-index="showPickerIndex" @cancel="showPicker = false" />
        </van-popup>
        <van-popup v-model="editModel" get-container="body" duration="0" class="editmodel">
            <div style="height: 100vh;width: 100vw;">
                <headerNav :title="getTitle()">
                    <template>
                        <van-icon name="cross" @click="closeEdit" />
                    </template>
                </headerNav>
                <div class="editmodel-content">
                    <van-cell-group>
                        <van-field v-if="popupKey == 'detailAddress'" v-model="editValue" type="textarea" maxlength="50"
                            :placeholder="'请输入' + getTitle()" show-word-limit />
                        <van-field v-else-if="popupKey == 'areaQty'" v-model="editValue" type="number" maxlength="6"
                            @input="xiaoshu" :placeholder="'请输入' + getTitle()" />
                        <van-field v-else v-model="editValue" :placeholder="'请输入' + getTitle()" />
                    </van-cell-group>
                </div>
                <div class="bottom-btn">
                    <div @click="closeEdit" style="border-right: 1px solid #E6E6E6">取消</div>
                    <div @click="submitEdit" style="background: #4F7AFD;color: #ffffff;">确定</div>
                </div>
            </div>
        </van-popup>
        <van-popup v-model="chooseAreaShow" round position="bottom" get-container="body">
            <van-cascader v-model="cascaderValue" title="请选择所在地区" :options="optionsArea" @close="chooseAreaShow = false"
                @change="onChange" @finish="onFinish" />
        </van-popup>
    </div>
</template>

<script>
import api from '../../../api/api.js'
import {
    Toast
} from 'vant';
export default {
    data () {
        return {
            dataInfo: {},//客户资料
            showPicker: false,//选择器是否显示
            showPickerIndex: 0,//选择器所选下标
            chooseAreaShow: false,//选择区域是否显示
            chooseAreaValue: [],//选中的区域
            cascaderValue: '',//选择区域绑定值
            optionsArea: [],//选择区域配置列表
            columns: [],//选择器配置
            gradeCode: [],//客户等级
            tagCode: [],//关注点
            houseStyle: [],//房屋类型
            houseType: [],//户型
            province: [],//省
            city: [],//市
            district: [],//区
            popupKey: '',//编辑的字段key
            editModel: false,//文本编辑是否显示
            editValue: '',//文本编辑内容
            confirmLoading: false,
        }
    },
    props:{
        disabledStatus:{
            type:String | Number,
            default:'2'
        },
    },
    methods: {
        //获取客户信息
        async getClientData () {
            let res = await api.getClientData(this.$route.params.id)
            if (res.code == 200) {
                this.dataInfo = res.data
                //将省市区拼接在一起
                let area = []
                if (this.dataInfo.provinceName) area.push(this.dataInfo.provinceName)
                if (this.dataInfo.cityName) area.push(this.dataInfo.cityName)
                if (this.dataInfo.regionName) area.push(this.dataInfo.regionName)
                this.dataInfo.area = area.join('/')
            }
        },
        //打开选择器
        openConfirm (key, value) {
            // 判断是否领取客户
            if(this.disabledStatus != '2') return
            this.showPicker = true;
            this.popupKey = key
            //根据所选编辑的下拉框进行选择器colums赋值
            this.columns = this[this.popupKey].map((e, i) => {
                if (e.code == this.dataInfo[key]) {
                    this.showPickerIndex = i
                }
                return e.name
                // return {
                //     text:e.name,
                //     deptCode:e.code
                // }
            })
        },
        //选择器确认
        onConfirm (value, index) {
            this.showPicker = false;
            this.submitEdit(index)
        },
        //字典（排序）
        async dictionary (code) {
            //客户等级
            let res = await api.dictionary({ ['Q_dict.code_IN']: code })
            return res.data
        },
        //字典
        async getDictionariesList (code) {
            let res = await api.getDictionariesList({ Q_typeCode_IN: code })
            return res.data
        },
        //获取编辑title
        getTitle () {
            let title = ''
            if (this.popupKey == 'consumerName') title = '客户姓名'
            if (this.popupKey == 'realityName') title = '真实姓名'
            if (this.popupKey == 'wechatNo') title = '微信号'
            if (this.popupKey == 'wechatName') title = '微信昵称'
            if (this.popupKey == 'detailAddress') title = '详细地址'
            return title
        },
        //打开文本编辑
        openEdit (key) {
            console.log(this.disabledStatus)
            // 判断是否领取客户
            if(this.disabledStatus != '2') return
            this.popupKey = key
            if (this.popupKey == 'consumerName') this.editValue = this.dataInfo.consumerName
            if (this.popupKey == 'realityName') this.editValue = this.dataInfo.realityName
            if (this.popupKey == 'wechatNo') this.editValue = this.dataInfo.wechatNo
            if (this.popupKey == 'wechatName') this.editValue = this.dataInfo.wechatName
            if (this.popupKey == 'detailAddress') this.editValue = this.dataInfo.detailAddress
            if (this.popupKey == 'areaQty') this.editValue = this.dataInfo.areaQty
            this.editModel = true
        },
        //关闭文本编辑
        closeEdit () {
            this.editModel = false
        },
        //编辑提交
        async submitEdit (index) {
            if (this.confirmLoading) return
            let obj = {
                id: this.$route.params.id,
                gradeCode: this.dataInfo.gradeCode
            }
            if (this.popupKey == 'consumerName') obj.consumerName = this.editValue//客户姓名
            if (this.popupKey == 'realityName') obj.realityName = this.editValue//真实姓名
            if (this.popupKey == 'wechatName') obj.wechatName = this.editValue//微信昵称
            if (this.popupKey == 'detailAddress') obj.detailAddress = this.editValue//详细地址
            if (this.popupKey == 'areaQty') obj.areaQty = this.editValue

            //微信号
            if (this.popupKey == 'wechatNo') {
                obj.wechatNo = this.editValue
                //微信号验证 不能输入中文
                let regs = /[\u4E00-\u9FA5\uFE30-\uFFA0]/;
                if (obj.wechatNo && regs.test(obj.wechatNo)) {
                    /* eslint-disable-next-line */
                    Toast('请输入正确的微信号!')
                    return
                }
            }
            //关注点
            if (this.popupKey == 'tagCode') {
                obj.tagCode = this.tagCode[index].code
                obj.tagName = this.tagCode[index].name
            }
            
            if (this.popupKey == 'gradeCode') obj.gradeCode = this.gradeCode[index].code//客户等级
            if (this.popupKey == 'houseStyle') obj.houseStyle = this.houseStyle[index].code//房屋类型
            //装修风格
            if (this.popupKey == 'styleCode') {
                obj.styleCode = this.styleCode[index].code
                obj.styleName = this.styleCode[index].name
            }
            //户型
            if (this.popupKey == 'houseType') {
                obj.houseType = this.houseType[index].code
                obj.houseTypeName = this.houseType[index].name
            }

            //区域
            if (this.popupKey == 'area'){
                obj.provinceCode = this.chooseAreaValue[0].value
                obj.provinceName = this.chooseAreaValue[0].text

                obj.cityCode = this.chooseAreaValue[1].value
                obj.cityName = this.chooseAreaValue[1].text

                obj.regionCode = this.chooseAreaValue[2].value
                obj.regionName = this.chooseAreaValue[2].text
            }
            if(this.popupKey != 'gradeCode'){
                obj.gradeCode = this.dataInfo.gradeCode
            }
            this.confirmLoading = true
            let res = await api.editClientData(obj)
            if (res.code == 200) {
                this.editModel = false
                this.showPicker = false
                Toast('编辑成功!');
                this.getClientData()
            }
            this.confirmLoading = false
        },
        //选择省市区
        chooseArea () {
            // 判断是否领取客户
            if(this.disabledStatus != '2') return
            this.popupKey = 'area'
            this.chooseAreaShow = true
        },
        //省市区选择改变触发
        async onChange (e) {
            if (e.tabIndex == 0) e.selectedOptions[0].children = await this.getArea({ Q_parentCode_EQ: e.value });
            if (e.tabIndex == 1) e.selectedOptions[1].children = await this.getArea({ Q_parentCode_EQ: e.value },true);
        },
        //省市区选择结束触发
        onFinish ({ selectedOptions }) {
            //所选中的区域
            this.chooseAreaValue = selectedOptions
            this.submitEdit()
            this.chooseAreaShow = false;
            this.cascaderValue = ''
        },
        //获取省市区
        async getArea (params, isLast) {
            let res = await api.getArea(params)
            let arr = res.data.map(e => {
                let item = {
                    text: e.name,
                    value: e.code,
                    children: []
                }
                //如果是最后一级删除children
                if (isLast) delete item.children
                return item
            })
            return arr
        },
        xiaoshu (value) {
            value = value.replace(/[^\d.]/g, "");  //清除“数字”和“.”以外的字符
            value = value.replace(/^\./g, "");  //必须保证第一个为数字而不是.
            value = value.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
            value = value.replace(".", "$#$").replace(/\./g, "").replace("$#$", "."); //不能重复出现点
            value = value.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');//只能输入两个小数
            if (value.indexOf(".") < 0 && value != "") {//以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
                value = parseFloat(value);
            }
            this.editValue = value
        }
    },
    async created () {
        this.getClientData()
        this.gradeCode = await this.dictionary('DICT284607')//获取客户等级列表
        this.tagCode = await this.getDictionariesList('DICT851901')//获取关注点
        this.houseStyle = await this.getDictionariesList('DICT949787')//获取房屋类型
        this.styleCode = await this.getDictionariesList('DICT997340')//获取装修风格
        this.houseType = await this.dictionary('DICT254116')//获取户型
        this.optionsArea = await this.getArea({ Q_type_EQ: '1' })//获取省

    }
}
</script>

<style lang="scss" scoped>
.clientdata {
    text-align: left;
    padding: 1px 0;
}

.valueclass {
    color: #34495e;
    flex: 2;
}

.bottom-btn {
    height: 40px;
    position: fixed;
    bottom: 0;
    display: flex;
    width: 100%;
    background-color: #FFFFFF;
    text-align: center;
    border-top: 1px solid #E6E6E6;

    div {
        flex: 1;
        font-size: 13px;
        height: 100%;
        line-height: 40px;
    }
}

.editmodel-content {
    padding: 5px;
}
.none-arrow {
    .van-cell__right-icon {
        width: 20px;

        &::before {
            content: '' !important;
        }
    }
}
</style>
<style lang="scss">
.van-cell {
    line-height: normal;
}

.editmodel {
    .van-cell {
        border: 1px solid #ebedf0;
        border-radius: 5px;
    }
}
</style>