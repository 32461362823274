let list = [
  {
    userCode:'ROLE_603933',//运营总成
    isTab:true,
    tabList:[
      {
        name:'待处理',
        value:'1',
        isNum:true,
        num:0,
        screenList:[
          { 
            name:'指标',
            code:'target',
            lable:'',
            value:'',
            list:[
              { text: "全部", value: "9" },
              { text: "转单产值", value: "1" },
              { text: "补齐产值", value: "2" },
            ],
          },
          { 
            name:'月份',
            code:'month',
            lable:'',
            value:'',
          },
          { 
            name:'到达优先',
            code:'priority',
            lable:'',
            value:'',
          },
        ],
      },
      {
        name:'已处理',
        value:'2',
        isNum:false,
        screenList:[
          { 
            name:'审批状态',
            code:'state',
            lable:'',
            value:'',
            list:[
              { text: "全部", value: "9" },
              { text: "已通过", value: "1" },
              { text: "已驳回", value: "2" },
              { text: "已失效", value: "3" },
            ],
          },
          { 
            name:'指标',
            code:'target',
            lable:'',
            value:'',
            list:[
              { text: "全部", value: "9" },
              { text: "转单产值", value: "1" },
              { text: "补齐产值", value: "2" },
            ],
          },  
          { 
            name:'月份',
            code:'month',
            lable:'',
            value:'',
          },
          { 
            name:'处理日期',
            code:'handleDate',
            lable:'',
            value:'',
          },
        ],
      },
      {
        name:'抄送我',
        value:'3',
        isNum:true,
        num:0,
        screenList:[
          { 
            name:'指标',
            code:'target',
            lable:'',
            value:'',
            list:[
              { text: "全部", value: "9" },
              { text: "转单产值", value: "1" },
              { text: "补齐产值", value: "2" },
              { text: "转单量", value: "3" },
              { text: "补齐量", value: "4" },
              { text: "签单量", value: "5" },
              { text: "到店量", value: "6" },
              { text: "线索量", value: "7" },
            ],
          },
          { 
            name:'月份',
            code:'month',
            lable:'',
            value:'',
          },
          { 
            name:'到达日期',
            code:'reachDate',
            lable:'',
            value:'',
          },
        ],
      },
    ],
  },
  {
    userCode:'ROLE_768022',//总经理
    isTab:true,
    tabList:[
      {
        name:'待处理',
        value:'1',
        isNum:true,
        num:0,
        screenList:[
          { 
            name:'指标',
            code:'target',
            lable:'',
            value:'',
            list:[
              { text: "全部", value: "9" },
              { text: "转单产值", value: "1" },
              { text: "补齐产值", value: "2" },
              { text: "转单量", value: "3" },
              { text: "补齐量", value: "4" },
              { text: "签单量", value: "5" },
              { text: "到店量", value: "6" },
              { text: "线索量", value: "7" },
            ],
          },
          { 
            name:'月份',
            code:'month',
            lable:'',
            value:'',
          },
          { 
            name:'到达优先',
            code:'priority',
            lable:'',
            value:'',
          },
        ],
      },
      {
        name:'已处理',
        value:'2',
        isNum:false,
        screenList:[
          { 
            name:'审批状态',
            code:'state',
            lable:'',
            value:'',
            list:[
              { text: "全部", value: "9" },
              { text: "已通过", value: "1" },
              { text: "已驳回", value: "2" },
              { text: "已失效", value: "3" },
            ],
          },
          { 
            name:'指标',
            code:'target',
            lable:'',
            value:'',
            list:[
              { text: "全部", value: "9" },
              { text: "转单产值", value: "1" },
              { text: "补齐产值", value: "2" },
              { text: "转单量", value: "3" },
              { text: "补齐量", value: "4" },
              { text: "签单量", value: "5" },
              { text: "到店量", value: "6" },
              { text: "线索量", value: "7" },
            ],
          },
          { 
            name:'月份',
            code:'month',
            lable:'',
            value:'',
          },
          { 
            name:'处理日期',
            code:'handleDate',
            lable:'',
            value:'',
          },
        ],
      },
      {
        name:'抄送我',
        value:'3',
        isNum:true,
        num:0,
        screenList:[
          { 
            name:'指标',
            code:'target',
            lable:'',
            value:'',
            list:[
              { text: "全部", value: "9" },
              { text: "转单产值", value: "1" },
              { text: "补齐产值", value: "2" },
              { text: "转单量", value: "3" },
              { text: "补齐量", value: "4" },
              { text: "签单量", value: "5" },
              { text: "到店量", value: "6" },
              { text: "线索量", value: "7" },
            ],
          },
          { 
            name:'月份',
            code:'month',
            lable:'',
            value:'',
          },
          { 
            name:'到达日期',
            code:'reachDate',
            lable:'',
            value:'',
          },
        ],
      },
      {
        name:'已发起',
        value:'4',
        isNum:true,
        num:0,
        screenList:[
          { 
            name:'审批状态',
            code:'state',
            lable:'',
            value:'',
            list:[
              { text: "全部", value: "9" },
              { text: "待审批", value: "0" },
              { text: "已通过", value: "1" },
              { text: "已驳回", value: "2" },
              { text: "已撤回", value: "4" },
              { text: "已失效", value: "3" },
            ],
          },
          { 
            name:'指标',
            code:'target',
            lable:'',
            value:'',
            list:[
              { text: "全部", value: "9" },
              { text: "转单产值", value: "1" },
              { text: "补齐产值", value: "2" },
            ],
          },
          { 
            name:'月份',
            code:'month',
            lable:'',
            value:'',
          },
          { 
            name:'提交日期',
            code:'renderDate',
            lable:'',
            value:'',
          },
        ],
      },
    ],
  },
  {
    userCode:'ROLE_886285',//前台总监
    isTab:true,
    tabList:[
      {
        name:'待处理',
        value:'1',
        isNum:true,
        num:0,
        screenList:[
          { 
            name:'指标',
            code:'target',
            lable:'',
            value:'',
            list:[
              { text: "全部", value: "9" },
              { text: "转单产值", value: "1" },
              { text: "补齐产值", value: "2" },
              { text: "转单量", value: "3" },
              { text: "补齐量", value: "4" },
              { text: "签单量", value: "5" },
              { text: "到店量", value: "6" },
              { text: "线索量", value: "7" },
            ],
          },
          { 
            name:'月份',
            code:'month',
            lable:'',
            value:'',
          },
          { 
            name:'到达优先',
            code:'priority',
            lable:'',
            value:'',
          },
        ],
      },
      {
        name:'已处理',
        value:'2',
        isNum:false,
        screenList:[
          { 
            name:'审批状态',
            code:'state',
            lable:'',
            value:'',
            list:[
              { text: "全部", value: "9" },
              { text: "已通过", value: "1" },
              { text: "已驳回", value: "2" },
              { text: "已失效", value: "3" },
            ],
          },
          { 
            name:'指标',
            code:'target',
            lable:'',
            value:'',
            list:[
              { text: "全部", value: "9" },
              { text: "转单产值", value: "1" },
              { text: "补齐产值", value: "2" },
              { text: "转单量", value: "3" },
              { text: "补齐量", value: "4" },
              { text: "签单量", value: "5" },
              { text: "到店量", value: "6" },
              { text: "线索量", value: "7" },
            ],
          },
          { 
            name:'月份',
            code:'month',
            lable:'',
            value:'',
          },
          { 
            name:'处理日期',
            code:'handleDate',
            lable:'',
            value:'',
          },
        ],
      },
      {
        name:'已发起',
        value:'4',
        isNum:true,
        num:0,
        screenList:[
          { 
            name:'审批状态',
            code:'state',
            lable:'',
            value:'',
            list:[
              { text: "全部", value: "9" },
              { text: "待审批", value: "0" },
              { text: "已通过", value: "1" },
              { text: "已驳回", value: "2" },
              { text: "已撤回", value: "4" },
              { text: "已失效", value: "3" },
            ],
          },
          { 
            name:'月份',
            code:'month',
            lable:'',
            value:'',
          },
          { 
            name:'提交日期',
            code:'renderDate',
            lable:'',
            value:'',
          },
        ],
      },
    ],
  },
  {
    userCode:'ROLE_113490',//督导经理
    isTab:false,
    tabList:[
      {
        name:'督导经理',
        value:'4',
        screenList:[
          { 
            name:'审批状态',
            code:'state',
            lable:'',
            value:'',
            list:[
              { text: "全部", value: "9" },
              { text: "待审批", value: "0" },
              { text: "已通过", value: "1" },
              { text: "已驳回", value: "2" },
              { text: "已撤回", value: "4" },
              { text: "已失效", value: "3" },
            ],
          },
          { 
            name:'月份',
            code:'month',
            lable:'',
            value:'',
          },
          { 
            name:'提交日期',
            code:'renderDate',
            lable:'',
            value:'',
          },
        ],
      }
    ]
  },
  {
    userCode:'ROLE_327530',//转单经理
    isTab:false,
    tabList:[
      {
        name:'转单经理',
        value:'4',
        screenList:[
          { 
            name:'审批状态',
            code:'state',
            lable:'',
            value:'',
            list:[
              { text: "全部", value: "9" },
              { text: "待审批", value: "0" },
              { text: "已通过", value: "1" },
              { text: "已驳回", value: "2" },
              { text: "已撤回", value: "4" },
              { text: "已失效", value: "3" },
            ],
          },
          { 
            name:'月份',
            code:'month',
            lable:'',
            value:'',
          },
          { 
            name:'提交日期',
            code:'renderDate',
            lable:'',
            value:'',
          },
        ],
      }
    ]
  },
  {
    userCode:'ROLE_553504',//签单经理
    isTab:false,
    tabList:[
      {
        name:'签单经理',
        value:'4',
        screenList:[
          { 
            name:'审批状态',
            code:'state',
            lable:'',
            value:'',
            list:[
              { text: "全部", value: "9" },
              { text: "待审批", value: "0" },
              { text: "已通过", value: "1" },
              { text: "已驳回", value: "2" },
              { text: "已撤回", value: "4" },
              { text: "已失效", value: "3" },
            ],
          },
          { 
            name:'月份',
            code:'month',
            lable:'',
            value:'',
          },
          { 
            name:'提交日期',
            code:'renderDate',
            lable:'',
            value:'',
          },
        ],
      }
    ]
  },
  {
    userCode:'ROLE_670920',//话务经理
    isTab:false,
    tabList:[
      {
        name:'话务经理',
        value:'4',
        screenList:[
          { 
            name:'审批状态',
            code:'state',
            lable:'',
            value:'',
            list:[
              { text: "全部", value: "9" },
              { text: "待审批", value: "0" },
              { text: "已通过", value: "1" },
              { text: "已驳回", value: "2" },
              { text: "已撤回", value: "4" },
              { text: "已失效", value: "3" },
            ],
          },
          { 
            name:'月份',
            code:'month',
            lable:'',
            value:'',
          },
          { 
            name:'提交日期',
            code:'renderDate',
            lable:'',
            value:'',
          },
        ],
      }
    ]
  },
]

export const approveFn = (code) => {
  let data = {}
  list.filter(item => {
    if (item.userCode == code) {
      data = item
    }
  })
  return data
};