import { render, staticRenderFns } from "./PlaceOrder.vue?vue&type=template&id=7826d27a&scoped=true&"
import script from "./PlaceOrder.vue?vue&type=script&lang=js&"
export * from "./PlaceOrder.vue?vue&type=script&lang=js&"
import style0 from "./Component/component.scss?vue&type=style&index=0&prod&lang=scss&"
import style1 from "./PlaceOrder.vue?vue&type=style&index=1&id=7826d27a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7826d27a",
  null
  
)

export default component.exports