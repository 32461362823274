<template>
  <div class="page">
    <!-- 数据看板 -->
    <div class="data-content" v-if="active == 0">
      <!--数据看板  -->
      <DataBoard v-if="routerRoleName == 'dataBoard'" />
      <!-- 科技看板 -->
      <PresidentBoard v-if="routerRoleName == 'president'" />
      <!-- 话务总监看板 -->
      <TdirectorBoard v-if="routerRoleName == 'TdirectorName'" />
      <!-- 运营总裁看板 -->
      <DirectorOperationsBoard v-if="routerRoleName == 'directorOperationsName'" />
    </div>
    <!-- 任务看板 -->
    <TaskBoard v-if="active == 1" />
    <!--我的 -->
    <My v-if="active == 2" />
    <!-- 底部菜单 -->
    <ul class="mune" :class="clientPlatform == 'iOS' ? 'padding-bottom' : ''">
      <li @click="onClickMune(index)" v-for="(item, index) in muneList" :key="index">
        <img :src="active == index ? item.iconActive : item.icon" alt="">
        <div :class="active == index ? 'active' : ''">{{ item.name }}</div>
      </li>
    </ul>
  </div>
</template>

<script>
// 任务看板
import TaskBoard from "./board/index.vue"
// 数据看板
import DataBoard from "@/views/dataBoard/index.vue"
// 科技看板
import PresidentBoard from "@/views/president/index.vue"
// 话务总监看板
import TdirectorBoard from "@/views/Tdirector/index.vue"
// 运营总裁看板
import DirectorOperationsBoard from "@/views/directorOperations/index.vue"
// 我的
import My from "@/views/task-management/my/index.vue"

import api from "@/api/task-management/board.js";
import api2 from "@/api/api.js"
import api3 from "@/api/task-management/my.js";
import { judgeClient } from "@/utils/judgeClient.js"
import { Toast } from "vant";

export default {
  components: {
    TaskBoard,
    DataBoard,
    PresidentBoard,
    TdirectorBoard,
    DirectorOperationsBoard,
    My,
  },
  data () {
    return {
      muneList: [
        {
          name: "数据",
          icon: require("@/assets/task-management/index-footer-icon1.png"),
          iconActive: require("@/assets/task-management/index-footer-icon1-active.png")
        },
        {
          name: "任务",
          icon: require("@/assets/task-management/index-footer-icon2.png"),
          iconActive: require("@/assets/task-management/index-footer-icon2-active.png")
        },
        {
          name: "我的",
          icon: require("@/assets/task-management/index-footer-icon3.png"),
          iconActive: require("@/assets/task-management/index-footer-icon3-active.png")
        },
      ],
      active: 1,
      // 数据看板权限
      routerRoleName: "",
      // 判断是什么平台
      clientPlatform: ""
    }
  },
  created () {
    // 首次登录清空浏览器存储
    localStorage.removeItem("TABLE_HEADER_CONTENT");
    localStorage.removeItem("USER_DATA");
    localStorage.removeItem("MARKET_DATA");
    localStorage.removeItem("TAB_ACTIVE_OBJ");
    localStorage.removeItem("INQUIRY_TASK_DETAILS");
    // 判断当前登录平台是安卓还是iOS
    this.clientPlatform = judgeClient()

    // 判断初次登录 是否缓存
    let role = JSON.parse(localStorage.getItem("USER_ROLE"))
    if(!role){
      this.getUserRole()
    }
    // 判断选择的tab 是否缓存
    let tabActive = localStorage.getItem("INDEX_TAB_ACTIVE")
    console.log(tabActive);
    if(tabActive){
      this.active = tabActive
      if(this.active == 0){
        this.getDataMenu()
      }
    }

  },
  methods: {
    onClickMune (index) {
      this.active = index
      // 获取数据看板权限
      if (index == 0) {
        this.getDataMenu()
      }
      localStorage.setItem("INDEX_TAB_ACTIVE",this.active)
    },
    async getDataMenu () {
      let userInfo = JSON.parse(localStorage.getItem("USER_ROLE"))
      let list = await api.getRolePermissionApi({"roleCode":userInfo.roleCode})
      this.routerRoleName = list.data
      // 我的  前台总监，运营总裁 话务经理
      if (this.active == 0 && (this.routerRoleName != "dataBoard" && this.routerRoleName != "president" && this.routerRoleName != "directorOperationsName" && this.routerRoleName != "TdirectorName")) {
        Toast("当前功能暂未开启")
      }
    },
    // 查询登录人权限等级
    async getUserRole() {
      const { data } = await api.roleApi()
      if (data) {
        // 遍历权限，如果多个角色权限则取level等级最大的一个
        data.sort((before, after) => before.level - after.level);
        this.userRole = data[0]
        data.forEach((item,index) => {
          if(item.select){
            this.userRole = data[index]
          }
        });
        localStorage.setItem("USER_ROLE_LIST",JSON.stringify(data))
        await api3.changeRoleInfoApi({"roleCode":this.userRole.roleCode})
        await api.getButtonResourceApi({"roleCode":this.userRole.roleCode}).then((res)=>{
          if(res.code == 200){
            this.$store.commit('setUserRole', {...this.userRole,btn:res.data});
          }
        })
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.data-content{
  height: calc(100vh - 74px);
  overflow: auto;
}
.mune {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 50px;
  background: #FFFFFF;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  font-size: 12px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #908F8F;
  z-index: 100;


  &>li {
    width: 20%;
    margin-top: 6px;

    div {
      margin-top: -4px;
    }

    &>img {
      width: 24px;
      height: 24px;
    }

    .active {
      color: #0E8BFF;
    }
  }
}

.padding-bottom {
  padding-bottom: 10px;
  height: 60px;
}
</style>