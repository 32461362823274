<template>
    <div class="messageInfo-box">
        <headerNav title="消息详情" />
        <div :class="msgInfo.divideStatus != '待验收' ? 'show_main_content' : 'main_content'">
            <!-- 信息 -->
            <AcceptanceSubcontract ref="compontDetails"></AcceptanceSubcontract>
            <!-- 审批内容---自理项目分包单验收 -->
            <div class="check_main">
                <!-- 自理项目分包单验收 -->
                <p class="title_p">审批内容</p>
                <hr style="border: 0.5px solid #eee;">
                <div class="check_main_cont">
                    <div class="div_flex">
                        <div>上传时间:</div>
                        <div>{{ msgInfo.imgDate }}</div>
                    </div>
                    <div class="div_flex">
                        <div class="beizhu">备&emsp;&emsp;注:</div>
                        <div class="remark">{{ msgInfo.remark }}</div>
                    </div>
                    <div class="div_img">
                        <div class="title_img">现场照片:</div>
                        <div class="cont_img">
                            <van-image v-if="msgInfo.imgUrlList" @click="onPreview(item)" v-for="(item, index) in msgInfo.imgUrlList.slice(0, 8)" width="104" height="104" :src="item"
                                class="img" :key="index" />
                            <div class="see_more" v-if="msgInfo.imgUrlList && msgInfo.imgUrlList.length > 8 && !showMoreImg" @click="showMoreImg = true">
                                查看更多</div>
                            <van-image  @click="onPreview(item)" v-for="(item, index2) in msgInfo.imgUrlList.slice(8)" v-if="msgInfo.imgUrlList && showMoreImg" width="104"
                                height="104" :src="item" class="img" :key="index2" />
                        </div>
                    </div>
                    <div class="check_opinion">
                        <div>审核意见:</div>
                        <div v-if="msgInfo.divideStatus == '待验收'">
                            <van-field v-model="checkRemark" class="input_style" rows="2" autosize type="textarea"
                                maxlength="200" placeholder="请输入" show-word-limit />
                        </div>
                        <div v-else class="remark_bottom">
                            {{ remark }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-show="msgInfo.divideStatus == '待验收'">
            <div class="main_bottom">
                <van-radio-group v-model="radio" direction="horizontal">
                    <div class="div_border">
                        <van-radio name="2" icon-size="20px">拒绝</van-radio>
                    </div>
                    <div class="div_border">
                        <van-radio name="3" icon-size="20px">通过</van-radio>
                    </div>
                </van-radio-group>
            </div>
            <van-button type="info" class="bottom_btn" :disabled="!radio" @click="onSubmit">确认</van-button>
        </div>
    </div>
</template>
<script>
import api from '@/api/api.js'
import { ImagePreview,Toast } from 'vant';
import AcceptanceSubcontract from './Component/AcceptanceSubcontract.vue'
export default {
    components: {
        AcceptanceSubcontract
    },
    data () {
        return {
            id: '',
            logId:'',
            msgInfo: {},
            activeNames: [''],
            radio: '',
            fileList: [],
            remark: '',
            imageList: [],
            // 控制显示更多图片
            showMoreImg: false,
            // 审核意见
            checkRemark: '',
            // 控制是否点击确认
            isClickOk: false
        }
    },
    methods: {
        async onSearch () {
           const {data}  = await api.imgDetail(this.id)
           this.msgInfo = data
           this.imageList = data.imgUrlList
           this.remark = data.carefulRemark
        },
        async onSubmit () {
            const data = await api.careful({ carefulRemark: this.checkRemark, carefulStatus: this.radio, sellDivideId: this.id })
            if(data.code == 200){
                Toast.success('操作成功！')
                this.onSearch()
                this.$refs.compontDetails.handleDetails()
            }
        },
        onPreview(url) {
            ImagePreview({
                images: [url],
                closeable: true,
                showIndex: false
            });
        },
    },
    mounted () {

    },
    created () {
        this.id = this.$route.params.id
        this.logId = this.$route.params.logId
        this.onSearch()
    }
}
</script>

<style scoped lang="scss">
.messageInfo-box {
    width: 100%;
    overflow: hidden;
    .main_content {
        width: 100%;
        overflow-x: auto;
        height: 74vh;
        background: #F5F5F5;

    }

    .show_main_content {
        height: calc(100vh - 50px);
        width: 100%;
        overflow-x: auto;
        background: #F5F5F5;
    }
  
}

.main_bottom {
    padding: 24px 16px;
    display: flex;
    justify-content: space-around;

    .div_border {
        border: 1px solid #D4D6D9;
        padding: 10px 30px;
        border-radius: 4px;
        margin: 0px 15px;
        font-size: 18px;
    }
}

.bottom_btn {
    background: #3A72C6;
    margin-bottom: 24px;
    width: 295px;
    border-radius: 10px
}

.check_main {
    background: #fff;
    margin-top: 8px;

    .title_p {
        color: #333333;
        font-size: 16px;
        font-weight: 500;
        text-align: left;
        padding: 16px 16px 10px 16px;
        margin: 0;
    }

    .check_main_cont {
        color: #333333;
        font-size: 16px;
        padding: 10px 16px;

        .div_flex {
            display: flex;
            justify-content: space-between;
            padding-bottom: 16px;
        }

        .beizhu {
            text-align: justify;
            flex: 3;
        }

        .remark {
            flex: 7;
            text-align: left;
        }

        .div_img {
            text-align: left;

            .cont_img {
                margin-top: 8px;
                display: flex;
                flex-wrap: wrap;
            }

            .img {
                margin: 6px 6px;
                border-radius: 5px;
            }

            .see_more {
                width: 100px;
                height: 100px;
                border: 1px solid #EEEEEE;
                border-radius: 5px;
                line-height: 100px;
                text-align: center;
                margin: 5px 5px;
            }
        }

        .check_opinion {
            text-align: left;
            margin-top: 26px;

            .input_style {
                margin-top: 8px;
                padding: 8px;
                border-radius: 5px;
                background-color: #F8FAFF;
                font-weight: 400;
            }

            .remark_bottom {
                margin-top: 8px;
            }
        }
    }

}
</style>

<style  lang="scss">
.cont_bottom {
    margin-top: 8px;

    .cont_fenbao {
        background: #F5F5F5;
        overflow: hidden;
        border-radius: 10px;
        margin-bottom: 8px;

        .van-cell {
            padding: 0px 10px 10px 10px !important;
            background: #F5F5F5 !important;
        }

        .van-collapse-item__content {
            background: #F5F5F5;
        }
    }

    .zhankai {
        text-align: center !important;
        color: #4871C0 !important;
        width: 100% !important;
    }

    .van-collapse-item__wrapper {
        border-radius: 10px;
        background: #F5F5F5;
    }

    .van-collapse-item__content {
        padding-left: 8px;
        padding-right: 8px;
    }
}

.van-cell {
    color: #333333;
    font-size: 16px;
    font-weight: 500;
    padding: 16px;
}
</style>