<template>
  <div class="content employees-content">
    <van-sticky class="topMenu">
      <headerNav
        leftNav=" "
        :title="targetObj.navTitle"
        @leftClick="back"
      ></headerNav>
    </van-sticky>

    <main class="content-main">
      <!-- 汇总 -->
      <BoardNavSummarize
        :imgBackUrlName="targetObj.imgUrl"
        :ratio="taskPlanObj.timelySumRate"
        :completionsCount="taskPlanObj.timelyCompletionSum"
        :sumTotal="taskPlanObj.cashierTotalSum"
        :leftIconName="targetObj.leftIconName"
        :leftText="targetObj.collect1"
        :rightIconName="targetObj.rightIconName"
        :rightText="targetObj.collect2"
        :unit="targetObj.unit"
        :targetName="targetObj.collect3"
        :customerName="routerData.userName"
        :isAbnormity="taskPlanObj.isSpecial == '1'"
      />

      <!-- 人员详情 -->
      <div class="content-center-title">
        <div class="left-line"></div>
        <div>详情</div>
      </div>

      <!-- table -->
      <el-table
        :data="userTableData"
        header-cell-class-name="market-title"
        row-class-name="market-row"
        row-key="userId"
        :expand-row-keys="defaultExpandList"
        :highlight-current-row="false"
      >
        <!-- 自定义暂无数据 -->
        <template slot="empty">
          <div class="empty-no-data">
            <img src="../../../../assets/task-management/no-data.png" alt="" />
            <div>暂无数据</div>
          </div>
        </template>
        <el-table-column prop="day" label="日期" min-width="55" align="center">
        </el-table-column>
        <el-table-column
          prop="timelyCompletion"
          :label="targetObj.tableColumn1"
          align="right"
          min-width="85"
        >
        </el-table-column>
        <el-table-column
          prop="cashierTotal"
          :label="targetObj.tableColumn2"
          align="right"
          min-width="85"
        >
        </el-table-column>
        <el-table-column
          prop="timelyRate"
          :label="targetObj.tableColumn3"
          align="right"
          min-width="85"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.timelyRate">{{
              scope.row.timelyRate && scope.row.timelyRate != "-"
                ? scope.row.timelyRate + "%"
                : scope.row.timelyRate
            }}</span>
          </template>
        </el-table-column>
      </el-table>
    </main>
  </div>
</template>

<script>
import BoardNavSummarize from "@/views/task-management/component/public/module/BoardNavSummarize.vue";
import { estimateOutput } from "./data.js";
import api from "@/api/task-management/finance.js";
import { Toast } from "vant";

export default {
  components: {
    BoardNavSummarize,
  },
  data() {
    return {
      // 任务看板路由接参
      routerData: {},
      // 表格数据
      userTableData: [],
      // 展开行id-
      defaultExpandList: [],
      // 任务设置弹窗
      setTaskDialogVisible: false,
      taskDialogFrom: {
        value: "",
      },
      // 清空form校验
      fromRestFields: 0,
      // 表头动态展示内容
      outputCount: {},
      // 判读任务设置是否有权限
      setTaskRole: false,
      // 顶部任务产值目标
      taskPlanObj: {},
      // 任务设置当前行数据
      setRowsData: {},
      // 当前指标
      targetObj: {},
    };
  },
  created() {
    let localData = localStorage.getItem("TABLE_HEADER_CONTENT")
      ? JSON.parse(localStorage.getItem("TABLE_HEADER_CONTENT"))
      : {};
    let userData = localStorage.getItem("MARKET_DATA")
      ? JSON.parse(localStorage.getItem("MARKET_DATA"))
      : {};
    let route = this.$route.query.item
      ? JSON.parse(this.$route.query.item)
      : {};
    this.mergeObject(this.routerData, route);
    this.mergeObject(this.routerData, localData);
    this.mergeObject(this.routerData, userData);
    this.targetObj = estimateOutput(localData.termSlug);
    this.getTableData();
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    /* 合并对象函数，避免空值不会被覆盖 */
    mergeObject(target, obj) {
      for (let key in obj) {
        if (obj[key] !== null && obj[key] !== undefined) {
          target[key] = obj[key];
        }
      }
    },
    // 查询表格数据
    async getTableData() {
      try {
        Toast.loading({
          message: "加载中...",
          forbidClick: true,
        });
        const { data } = await api.detailListApi({
          ext: this.routerData.termSlug,
          Q_reportData_EQ: this.routerData.Q_reportData_EQ,
          type: "3",
          marketCode: this.routerData.marketCode,
          userId: this.routerData.userId,
        });
        this.taskPlanObj = data;
        this.userTableData = data.data01;
      } finally {
        Toast.clear();
      }
    },
    // 控制展开行
    expandControlRow(item) {
      const position = this.defaultExpandList.indexOf(item.userId);
      if (position !== -1) {
        this.defaultExpandList.splice(position, 1);
      } else {
        this.defaultExpandList.push(item.userId);
      }
    },
    // 获取图片
    getImageIndex(index) {
      return require(`@/assets/task-management/task-details-table-${
        index + 1
      }.png`);
    },
  },
};
</script>

<style lang="scss" scoped src="../../component/public/css/table.scss"></style>
<style lang="scss" scoped>
.employees-content::v-deep {
  .content-main {

    .market-title {
      .cell {
        font-size: 13px;
        font-weight: 500;
      }
    }

    .el-table_1_column_4 {
      padding: 0px 10px;
    }

    .market-row {
      td {
        &:last-child {
          .cell {
            padding: 5px 10px 5px 3px;
          }
        }
      }
    }
  }
}
</style>
