<template>
	<div class="selectTime-style">
		<van-action-sheet v-model="show" @click-overlay="timeCancel" :close-on-click-overlay="false">
			<div>
				<div class="van-picker__toolbar"><button type="button" class="van-picker__cancel"
						@click="timeCancel">取消</button>
					<div class="van-ellipsis van-picker__title">选择时间</div><button type="button"
						class="van-picker__confirm" @click="timeSubmit">确认</button>
				</div>
				<!-- 单个时间选择 -->
				<div v-if="type=='date'">
					<van-datetime-picker  ref="singleTime" :show-toolbar="false" @confirm="timeSubmit" @cancel="timeCancel" v-model="startTime" type="date"
						title="选择时间" />
				</div>
				<!-- 时间段选择 -->
				<div v-if="type=='date-quantum'">
					<van-tabs v-model="active" :lazy-render="false">
						<van-tab title="开始时间" name="startTime">
							<van-datetime-picker :show-toolbar="false" ref="startTime" class="datetime-picker-quantum" 
								 v-model="startTime" type="date" title="选择时间" />
						</van-tab>
						<van-tab title="结束时间" name="endTime">
							<van-datetime-picker :show-toolbar="false" ref="endTime" :minDate="startTime" class="datetime-picker-quantum"
								 v-model="endTime" type="date" title="选择时间" />
						</van-tab>
					</van-tabs>
				</div>
				
			</div>
		</van-action-sheet>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				startTime: '',
				endTime: '',
				active: 'satrTime',
			}
		},
		props: {
			//选择时间类型
			type: {
				type: String,
				default: "date", //date单个时间 date-quantum时间段
			},
			show: {
				type: Boolean,
				default: false
			},
		},
		model: {
			prop: 'show',
			event: 'change'
		},
		created() {
			this.startTime = this.endTime = new Date()
		},
		methods: {
			timeCancel() {
				this.$emit('change', false)
			},
			timeSubmit(val) {
				let start
				let end
				let a = []
				if(this.type=='date'){
					start= this.$refs.singleTime.getPicker().getValues().join('-')
					a.push(start)
				}
					
				if(this.type=='date-quantum'){
					start= this.$refs.startTime.getPicker().getValues().join('-')
					end= this.$refs.endTime.getPicker().getValues().join('-')
					a.push(start)
					a.push(end)
				}
				this.$emit('change', false)
				this.$emit('confirm',a)
			}

		},
		mounted() {

		},
	}
</script>

<style scoped lang="scss">
	.selectTime-style {}
</style>
