<template>
  <div class="main">
    <headerNav leftNav=" " :title="'审批详情'" @leftClick="back"></headerNav>
    <main>
      <div class="main-style">
        <div class="content">
          <!-- 顶部 -->
          <div class="content-top">
            <div class="content-top-left">
              <div class="content-top-left-img">
                <img src="@/assets/task-management/task-setup-finance-check-details.png" alt="">
              </div>
              <div class="content-top-left-text">
                <div class="title">{{routeQueryInfo.userName}}的任务结果</div>
                <div class="submit-name">提交人 <span>{{routeQueryInfo.userName}}</span> </div>
              </div>
            </div>
            <div class="content-top-right">{{ routeQueryInfo.createDate }}</div>
          </div>
          <!-- 任务项 -->
          <div class="content-center" v-if="isExpanded">
            <!-- 综述报告 -->
            <div>
              <div v-for="(item,index) in typeList.table" :key="index" class="content-center-details">
                <div class="left">{{ item.label }}</div>
                <div class="right">{{ item.unit=="%"?(routeQueryInfo[item.prop]).toFixed(2):routeQueryInfo[item.prop] }}{{ item.unit }}</div>
              </div>
              <div class="content-center-details content-center-details-img">
                <div class="left">附件 ({{ routeQueryInfo.fileInfoList.length }})</div>
                <div class="right-img" :class="{'right-img-4':routeQueryInfo.fileInfoList && routeQueryInfo.fileInfoList.length == 4} ">
                  <img v-for="(item,index) in routeQueryInfo.fileInfoList" :key="index" @click="onPreview(item.fileUrl)" width="100" height="100" :src="item.fileUrl" />
                </div>
              </div>
            </div>
          </div>

          <!-- 收起展开 -->
          <div class="content-center-bottom" @click="onClickExpand">
            <img :src="require(`@/assets/task-management/${!isExpanded ? 'task-zhankai' : 'task-shouqi'}.png`)" alt="">
          </div>
        </div>

        <!-- 审批流程 -->
        <div class="bottom-check">
          <div class="bottom-check-title">
            <div class="left-line"></div>
            <div class="text">审批流程</div> 
          </div>

          <div class="steps-content">
            <van-steps direction="vertical" :active="stepData.length" active-color="#0E8BFF">
              <van-step v-for="(item, index) in stepData" :key="index">
                <template #active-icon>
                  <div class="steps-active-icon">
                    <img v-if="item.manageCheckStatus == '0'" src="@/assets/task-management/task-setup-steps-review.png" alt="">
                    <img v-if="item.manageCheckStatus == '1'" src="@/assets/task-management/task-setup-steps-confrim.png" alt="">
                    <img v-if="item.manageCheckStatus == '2'" src="@/assets/task-management/task-setup-steps-reject.png" alt="">
                    <img v-if="item.manageCheckStatus == '3'" src="@/assets/task-management/task-setup-steps-lose.png" alt="">
                  </div>
                </template>
                <div class="step-main">
                  <div class="step-main-left">
                    <div class="step-main-left-text">
                      {{ item.manageName }} <span>{{ item.manageRoleName }}</span>
                    </div>
                    <div class="step-main-left-date">{{ item.manageTime }}</div>
                  </div>
                  <div class="step-main-right">
                    <span v-if="item.manageCheckStatus == '0'" class="orange">待审核</span>
                    <span v-if="item.manageCheckStatus == '1'" class="green">已通过</span>
                    <span v-if="item.manageCheckStatus == '2'" class="red">已驳回</span>
                    <span v-if="item.manageCheckStatus == '3'" class="gay">已失效</span>
                  </div>
                </div>
              </van-step>
            </van-steps>
          </div>
        </div>

        <!-- 审批 -->
        <div class="footer-handle" v-if="isShowExamineBtn">
          <van-button class="btn" type="default" @click="onHandleExamine('驳回')">驳回</van-button>
          <van-button class="btn" color="#4871C0" @click="onHandleExamine('通过')" type="info">通过</van-button>
        </div>
      </div>
    </main>

    <!-- 审批确认弹窗 -->
    <van-dialog v-model="examineDialog" :closeOnClickOverlay="true" confirmButtonColor="#3975C6" show-cancel-button
      @cancel="examineDialogClose" @confirm="examineDialogConfirm">
      <div class="dialog-main">
        <!-- 驳回 -->
        <div class="dialog-main-reject" v-if="!examineDialogModel">
          您确认“ <span>驳回</span> ” {{routeQueryInfo.userName}}的审批请求吗？
        </div>
        <!-- 通过 -->
        <div class="dialog-main-reject" v-else>
          您确认“ <span>通过</span> ” {{routeQueryInfo.userName}}的审批请求吗？
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { examineDetailModelFn } from "./data.js"
import api from "@/api/task-management/human-resources.js";
import { Toast,ImagePreview } from "vant";
import moment from "moment";

export default {
  data () {
    return {
      // 路由携带信息
      routeQueryInfo:{},
      // 模块类型数据 
      typeList:{},
      // 任务项显示隐藏
      isExpanded: false,
      // 流程数据
      stepData: [],
      // 审批弹窗
      examineDialog: false,
      //弹窗模块
      examineDialogModel: false,
      // 显示审批按钮
      isShowExamineBtn:true,
      // 当前登录人信息
      roleData:null
    }
  },
  created () {
    this.roleData = JSON.parse(localStorage.getItem("USER_ROLE"));
    this.routeQueryInfo = JSON.parse(this.$route.query.item)
    this.typeList = examineDetailModelFn(this.routeQueryInfo.termId)
  },
  mounted() {
    this.getdata()
  },
  methods: {
    back () {
      this.$router.go(-1)
    },
    // 控制展开收起
    onClickExpand () {
      this.isExpanded = !this.isExpanded
    },
    // 点击审批按钮
    onHandleExamine (type) {
      this.examineDialog = true
      if (type == '驳回') {
        this.examineDialogModel = false
      } else {
        this.examineDialogModel = true
      }
    },
    // 弹窗关闭
    examineDialogClose () {},
    // 弹窗确认
    examineDialogConfirm(){
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      let params = {
        "id":this.routeQueryInfo.id,
        "manageCheckStatus": this.examineDialogModel?'1':'2',
        "termId":this.routeQueryInfo.termId,
      }
      api.approval(params).then((res)=>{
        if (res.code == 200) {
          Toast.success("操作成功")
          this.getdata()
        }else{
          Toast.fail(res.msg)
        }
      })
    },
    // 查看大图
    onPreview (url) {
      ImagePreview({
        images: [url],
        closeable: true,
        showIndex: false
      });
    },
    // 获取流程信息
    getdata(){
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      let params = {
        "id": this.routeQueryInfo.id,
      }
      api.getTaskStep(params).then((res)=>{
        if (res.code == 200) {
          Toast.clear()
          this.stepData = res.data
          const result = res.data.find(item => item.manageRoleCode == this.roleData.roleCode)
          this.isShowExamineBtn = result && result.manageCheckStatus == '0' ? true : false
        }else{
          Toast.fail(res.msg)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.main::v-deep {
  background: #F5F5F5;
  height: 100vh;

  .main-style {
    height: calc(100vh - 50px);
    overflow: auto;
    margin-top: -1px;
  }

  .content {
    background: #3975C6;
    border-radius: 0px 0px 5px 5px;
    padding: 8px 12px;

    .content-top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;

      .content-top-left {
        display: flex;
        align-items: flex-start;
        text-align: left;
        max-width: 60%;
        .content-top-left-img {
          img {
            width: 18px;
            height: 18px;
            margin: 6px 4px 0 0;
          }
        }

        .content-top-left-text {
          width: 100%;
          .title {
            font-size: 20px;
            font-family: PingFang TC, PingFang TC;
            font-weight: 500;
            color: #FFFFFF;
            margin-bottom: 9px;
            white-space: nowrap; 
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .submit-name {
            font-size: 16px;
            font-family: PingFang TC, PingFang TC;
            font-weight: 400;
            color: #D7E3F4;

            span {
              font-weight: 500;
              margin-left: 16px;
            }
          }
        }
      }

      .content-top-right {
        font-size: 14px;
        font-family: PingFang TC, PingFang TC;
        font-weight: 400;
        color: #FFFFFF;
        padding-top: 4px;
      }
    }

    .content-center {
      padding: 9px 8px;
      background: #336CC2;
      border-radius: 4px;
      margin-bottom: 8px;
      .content-center-details {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        padding: 8px 0px;
        // height: 38px;

        .left {
          font-size: 14px;
        }

        .right {
          font-size: 16px;
          font-weight: 500;
        }
        .right-img{
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          max-width: 250px;
          border-radius: 4px;
          overflow: hidden;
          img{
            width: 71px;
            height: 71px;
            margin:0 0 10px 10px;
            border-radius: 4px;
            border: 1px solid rgba(217,217,217,0.4);
          }
        }
        .right-img-4{
          max-width: 166px;
        }

        .accessory {
          display: flex;
          align-items: center;

          img {
            width: 14px;
            height: 16px;
            margin-right: 3px;
          }
        }
      }

      .content-center-details-img{
        align-items: flex-start;
      }
    }

    .content-center-bottom {
      img {
        width: 16px;
        height: 16px;
      }
    }
  }

  .bottom-check {
    background: #FFFFFF;
    border-radius: 8px;
    margin-top: 8px;

    .bottom-check-title {
      display: flex;
      padding: 14px;
      align-items: center;
      border: 1px solid #EEEEEE;

      .left-line {
        width: 4px;
        height: 16px;
        background: #4871C0;
        border-radius: 20px
      }

      .text {
        font-size: 16px;
        font-family: PingFang TC, PingFang TC;
        font-weight: 500;
        color: #333333;
        margin-left: 3px;
      }
    }
  }

  .steps-content {
    .van-step__line {
      left: -4.5%;
      top: 20px
    }

    .steps-active-icon {
      img {
        width: 12px;
        height: 12px;
      }
    }

    .step-main {
      display: flex;
      justify-content: space-between;

      .step-main-left {
        text-align: left;

        .step-main-left-text {
          font-size: 16px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #323233;
          margin-bottom: 8px;

          span {
            width: 64px;
            height: 20px;
            background: rgba(217, 217, 217, 0.2);
            border-radius: 4px;
            font-size: 14px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 300;
            color: #8C8C8C;
            padding: 0px 4px;
          }
        }

        .step-main-left-date {
          font-size: 12px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #969799;
        }
      }

      .step-main-right {
        span {
          width: 58px;
          height: 24px;
          font-size: 14px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          padding: 3px 8px;
          border-radius: 4px;
        }

        .green {
          background: #ECF8F0;
          color: #38B865;
        }

        .orange {
          background: #FFF4E9;
          color: #FF9C3F;
        }

        .red {
          background: rgba(255, 97, 87, 0.15);
          color: #FF6157;
        }

        .gay {
          background: #F5F5F5;
          color: #999999;
        }
      }
    }
  }

  .footer-handle {
    width: 100%;
    height: 66px;
    background: #FFFFFF;
    box-shadow: 0px -1px 8px 0px rgba(0, 0, 0, 0.04);
    position: fixed;
    bottom: 1px;
    left: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    z-index: 100;

    .btn {
      width: 164px;
      height: 46px;
      border-radius: 4px;
      font-size: 18px;
    }
  }

  .dialog-main {
    padding: 16px;
    border-bottom: 1px solid #EEEEEE;

    .dialog-main-reject {
      font-size: 16px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      color: #000000;
      margin: 20px 0px;

      span {
        color: #ED4040;
      }
    }

    .dialog-main-agree {
      .dialog-main-agree-title {
        font-size: 16px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        color: #323233;
      }

      .dialog-from {
        margin-top: 20px;

        .van-cell::after {
          border: 0;
        }
      }
    }

    .faults {
      font-size: 16px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      color: #333333;

      span {
        color: #999999;
      }
    }

    .from-custom {
      display: flex;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      color: #999999;
      align-items: center;
      justify-content: space-between;
      padding: 11px 18px;

      .from-custom-left {
        font-size: 16px;
      }

      .from-custom-right {
        font-size: 14px;
        color: #333333;
        text-align: left;
        width: 110px;
        // flex: 4;

        span {
          color: #999999;
        }
      }
    }
  }

  .van-dialog__cancel {
    font-size: 18px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    color: #333333;
  }

  .van-dialog__confirm {
    font-size: 18px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    color: #3975C6;
  }
}
</style>