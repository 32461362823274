<template>
  <div class="content employees-content">
    <van-sticky class="topMenu">
      <headerNav leftNav=" " :title="targetObj.navTitle" @leftClick="back"></headerNav>
    </van-sticky>

    <main class="contents">
      <!-- 汇总 -->
      <BoardNavSummarize 
      :imgBackUrlName="targetObj.imgUrl" 
      :ratio="taskPlanObj.average" 
      :completionsCount="taskPlanObj.reachStandard"
      :sumTotal="taskPlanObj.noReachStandard"
      :leftIconName="targetObj.leftIconName" 
      :leftText="targetObj.collect1" 
      :rightIconName="targetObj.rightIconName"
      :rightText="targetObj.collect2" 
      :unit="targetObj.unit" 
      :targetName="targetObj.collect3"/>
      <div class="empty-no-data1">
        <img src="../../../../assets/task-management/no-data.png" alt="">
        <div class="text">暂无数据</div>
      </div>
    </main>
  </div>
</template>

<script>
import BoardNavSummarize from "@/views/task-management/component/public/module/BoardNavSummarize.vue"
import {estimateOutput} from "./data.js"
import api from "@/api/task-management/finance.js";
import { Toast } from "vant";

export default {
  components: {
    BoardNavSummarize
  },
  data () {
    return {
      // 任务看板路由接参
      routerData: {},
      // 当前指标
      targetObj:{},
      taskPlanObj:{}
    }
  },
  created () {
    let localData = JSON.parse(localStorage.getItem("TABLE_HEADER_CONTENT"));
    let userData = localStorage.getItem("MARKET_DATA")? JSON.parse(localStorage.getItem("MARKET_DATA")): {};
    let router = JSON.parse(this.$route.query.row)
    this.taskPlanObj = router
    this.mergeObject(this.routerData, router);
    this.mergeObject(this.routerData, localData);
    this.mergeObject(this.routerData, userData);
    this.targetObj = estimateOutput(localData.termSlug);
    if(this.targetObj.value == 'estimate_profit' || this.targetObj.value == 'settle_accounts_profit' || this.targetObj.value == 'balance_profit'){
      this.targetObj.collect1 = '达标工地'
      this.targetObj.collect2 = '未达标工地'
    }
    this.getTableData()
  },
  methods: {
    back () {
      this.$router.go(-1)
    },
     /* 合并对象函数，避免空值不会被覆盖 */
     mergeObject(target, obj) {
      for (let key in obj) {
        if (obj[key] !== null && obj[key] !== undefined) {
          target[key] = obj[key];
        }
      }
    },
    // 查询表格数据
    async getTableData() {
      try {
        Toast.loading({
          message: "加载中...",
          forbidClick: true,
        });
        const { data } = await api.detailListApi({
          ext: this.routerData.termSlug,
          Q_reportData_EQ: this.routerData.Q_reportData_EQ,
          type: "3",
          marketCode: this.routerData.marketCode,
          userId: this.routerData.userId,
        });
        this.taskPlanObj = data;
        this.userTableData = data.data01;
      } finally {
        Toast.clear();
      }
    },
  },
}
</script>

<style lang="scss" scoped src="../../component/public/css/table.scss"></style>
<style lang="scss" scoped>
.employees-content::v-deep {
  .contents{
    margin-top: -1px;
  }
  .empty-no-data1 {
    margin-top: 40px;
    img {
      width: 160px;
      height: 150px;
    }

    div {
      color: #5E5E5E;
      font-size: 14px;
    }
  }
}
</style>