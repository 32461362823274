<template>
    <div class="main technology_kanban">
        <van-sticky>
            <headerNav class="nav_b" title="数据看板"></headerNav>
        
            <div class="tabs flex">
                <div :class=" active == '1' ? 'active':'' " @click="department('1')">市场部</div>
                <div :class=" active == '2' ? 'active':'' " @click="department('2')">广告部</div>
            </div>
        </van-sticky>
        <div class="tabsContent" v-show="active == '1'"> 
            <div class="monthData">
                <div class="nav">
                    <div class="flex">
                        <div class="title">当月数据</div>
                        <div class="tip">近7天数据展示</div>
                    </div>
                    <div class="more" @click="monthDetails">查看更多 <van-icon name="arrow" /></div>
                </div>
                <div class="content">
                    <div class="tab center flex">
                        <div :class=" active1 == '1' ? 'active':'' " @click="monthData('1')">线索量</div>
                        <div :class=" active1 == '2' ? 'active':'' " @click="monthData('2')">有效量</div>
                        <div :class=" active1 == '3' ? 'active':'' " @click="monthData('3')">到店量</div>
                        <div :class=" active1 == '4' ? 'active':'' " @click="monthData('4')">签单量</div>
                    </div>
                    <div class="table monthTable">
                        <el-table :data="monthTableData" border style="width: 100%">
                            <el-table-column prop="reportData" align="center" min-width="100" fixed="left" label="日期"></el-table-column>
                            <el-table-column prop="week" min-width="60" align="center" fixed="left" label="星期"></el-table-column>
                            <el-table-column prop="qdNumber" min-width="80" align="right" label="青岛"></el-table-column>
                            <el-table-column prop="jnNumber" min-width="80" align="right" label="济南"></el-table-column>
                            <el-table-column prop="zbNumber" min-width="80" align="right" label="淄博"></el-table-column>
                            <el-table-column prop="lyNumber" min-width="80" align="right" label="临沂"></el-table-column>
                            <el-table-column prop="wfNumber" min-width="80" align="right" label="潍坊"></el-table-column>
                            <el-table-column prop="whNumber" min-width="80" align="right" label="威海"></el-table-column>
                            <el-table-column prop="ytNumber" min-width="80" align="right" label="烟台"></el-table-column>
                            <el-table-column prop="jniNumber" min-width="80" align="right" label="济宁"></el-table-column>
                            <el-table-column prop="ccNumber" min-width="80" align="right" label="长春"></el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>
            <div class="costEffectivenessRatio">
                <div class="nav">
                    <div class="title">市场费效比</div>
                </div>
                <div class="content">
                    <div class="tab center flex">
                        <div :class=" active2 == '1' ? 'active':'' " @click="costEffectivenessRatio('1')">本月</div>
                        <div :class=" active2 == '2' ? 'active':'' " @click="costEffectivenessRatio('2')">上月</div>
                    </div>
                    <div class="echart">
                        <div class="echart1"></div>
                        <div class="tip">
                            <div>保底</div>
                            <div>任务</div>
                            <div>目标</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="output">
                <div class="nav">
                    <div class="title">产值详细数据</div>
                    <div class="tip">{{scheduling}}</div>
                </div>
                <div class="content">
                    <div class="tab center flex">
                        <div :class=" active3 == '1' ? 'active':'' " @click="output('1')">本月</div>
                        <div :class=" active3 == '2' ? 'active':'' " @click="output('2')">上月</div>
                    </div>
                    <div class="table">
                        <el-table
                            :data="outputTableData"
                            border
                            style="width: 100%">
                            <el-table-column prop="marketName" align="center" min-width="80" fixed="left" label="市场"></el-table-column>
                            <el-table-column prop="practicalExpenditure" min-width="140" align="right" label="实际花费"></el-table-column>
                            <el-table-column prop="practicalProductionValue" min-width="140" align="right" label="实际产值"></el-table-column>
                            <el-table-column prop="costBenefitRatio" min-width="100" align="right" label="费效比"></el-table-column>
                            <el-table-column prop="targetProductionValue" min-width="140" align="right" label="目标产值"></el-table-column>
                            <el-table-column prop="achievingRate" min-width="100" align="right" label="达成率"></el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>
            <div class="clue">
                <div class="nav">
                    <div class="title">线索数据</div>
                    <!-- <van-dropdown-menu class="select" >
                        <van-dropdown-item v-model="value1" :options="option1" @change="clueChange"/>
                    </van-dropdown-menu> -->
                    
                    <van-popover v-model="show1" :placement="placement" :actions="option1" @select="clueChange" :get-container="getContainer">
                        <template #reference>
                            <div class="select" ref="target1" @click="showPopover1">{{ value1.text }} <van-icon name="arrow-down"/></div>
                        </template>
                    </van-popover>

                </div>
                <div class="content">
                    <div class="tab center flex">
                        <div :class=" active4 == '1' ? 'active':'' " @click="clue('1')">本周</div>
                        <div :class=" active4 == '2' ? 'active':'' " @click="clue('2')">本月</div>
                        <div :class=" active4 == '3' ? 'active':'' " @click="clue('3')">今年</div>
                    </div>
                    <div class="echart2"></div>
                </div>
            </div>
            <div class="market">
                <div class="nav">
                    <div class="title">市场数据</div>
                    <!-- <van-dropdown-menu class="select" style="min-width: 95px;">
                        <van-dropdown-item v-model="value2" :options="option2" @change="marketChange"/>
                    </van-dropdown-menu> -->

                    <van-popover v-model="show2" :placement="placement" :actions="option2" @select="marketChange" :get-container="getContainer">
                        <template #reference>
                            <div class="select" ref="target2" @click="showPopover2">{{ value2.text }} <van-icon name="arrow-down"/></div>
                        </template>
                    </van-popover>
                </div>
                <div class="content">
                    <div class="tab center flex">
                        <div :class=" active5 == '1' ? 'active':'' " @click="marketData('1')">本周</div>
                        <div :class=" active5 == '2' ? 'active':'' " @click="marketData('2')">本月</div>
                        <div :class=" active5 == '3' ? 'active':'' " @click="marketData('3')">今年</div>
                    </div>
                    <div class="echart3"></div>
                </div>
            </div>
            <div class="channel">
                <div class="nav">
                    <div class="title">渠道占比</div>
                        <!-- <van-dropdown-menu class="select1" style="min-width: 75px;">
                            <van-dropdown-item v-model="value3" :options="option1" @change="channelChange1"/>
                        </van-dropdown-menu>
                        <van-dropdown-menu class="select1" style="min-width: 95px;">
                            <van-dropdown-item v-model="value4" :options="option2" @change="channelChange2"/>
                        </van-dropdown-menu> -->
                        <div>
                            <van-popover v-model="show3" :placement="placement" :actions="option1" @select="channelChange1" style="margin-right: 15px;" :get-container="getContainer">
                                <template #reference>
                                    <div class="select" ref="target3" @click="showPopover3">{{ value3.text }} <van-icon name="arrow-down"/></div>
                                </template>
                            </van-popover>
                            <van-popover v-model="show4" :placement="placement" :actions="option2" @select="channelChange2" :get-container="getContainer">
                                <template #reference>
                                    <div class="select" ref="target4" @click="showPopover4">{{ value4.text }} <van-icon name="arrow-down"/></div>
                                </template>
                            </van-popover>
                        </div>
                </div>
                <div class="content">
                    <div class="tab center flex">
                        <div :class=" active6 == '1' ? 'active':'' " @click="channel('1')">今日</div>
                        <div :class=" active6 == '2' ? 'active':'' " @click="channel('2')">本月</div>
                    </div>
                    <div v-if="showChannelEchart" style="width: 100%;">
                        <div class="echart4"></div>
                    </div>
                    <div v-if="!showChannelEchart" class="empty">
                        <img src="../../assets/icon19.png" alt="">
                        <div>暂无数据</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="tabsContent1" v-show="active == '2'">
            <div class="today">
                <div class="nav">
                    <div class="title">今日数据</div>
                </div>
                <div class="content">
                    <div>
                        <span class="num">{{ consumeSumDate }}</span>
                        <span>总消耗</span>
                    </div>
                    <div>
                        <span class="num">{{ clueSumDate }}</span>
                        <span>总线索量</span>
                    </div>
                    <div>
                        <span class="num">{{ xianBenDate }}</span>
                        <span>线本</span>
                    </div>
                </div>
            </div>
            <div class="month">
                <div class="nav">
                    <div class="title">本月数据</div>
                </div>
                <div class="content">
                    <div>
                        <span class="num">{{ consumeSumMonth }}</span>
                        <span>总消耗</span>
                    </div>
                    <div>
                        <span class="num">{{ clueSumMonth }}</span>
                        <span>总线索量</span>
                    </div>
                    <div>
                        <span class="num">{{ xianBenMonth }}</span>
                        <span>线本</span>
                    </div>
                </div>
            </div>
            <div class="channel">
                <div class="nav">
                    <div class="title">渠道数据</div>
                    <div class="more" @click="channel1Details">查看更多 <van-icon name="arrow" /></div>
                </div>
                <div class="content">
                    <div class="tab center flex">
                        <div :class=" active7 == '1' ? 'active':'' " @click="channel1('1')">本周</div>
                        <div :class=" active7 == '2' ? 'active':'' " @click="channel1('2')">本月</div>
                        <div :class=" active7 == '3' ? 'active':'' " @click="channel1('3')">半年</div>
                    </div>
                    <div class="table">
                        <div class="table">
                        <el-table :data="channelTableData" border style="width: 100%" :row-class-name="tableRowClassName" cell-class-name="table_cell">
                            <el-table-column type="index" width="80" align="center" fixed="left" label="TOP"></el-table-column>
                            <el-table-column prop="channelName" align="left" fixed="left" label="渠道" :width="flexwidth('channelName',channelTableData,'渠道','noSort')"></el-table-column>
                            <el-table-column prop="consume" align="right" label="消耗" sortable :width="flexwidth('consume',channelTableData,'消耗')">
                                <template slot-scope="scope">
                                    <span>{{ (scope.row.consume).toFixed(2) }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="practicalExpenditure" min-width="140" align="right" label="实际花费" sortable :width="flexwidth('practicalExpenditure',channelTableData,'实际花费')">
                                <template slot-scope="scope">
                                    <span>{{ (scope.row.practicalExpenditure).toFixed(2) }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="threadNumber" min-width="100" align="left" label="线索量" sortable :width="flexwidth('threadNumber',channelTableData,'线索量')"></el-table-column>
                            <el-table-column prop="practicalThreadCost" align="right" label="实际线索成本" sortable :width="flexwidth('practicalThreadCost',channelTableData,'实际线索成本')">
                                <template slot-scope="scope">
                                    <span>{{ (scope.row.practicalThreadCost).toFixed(2) }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="practicalValidCost" min-width="160" align="right" label="实际有效成本" sortable :width="flexwidth('practicalValidCost',channelTableData,'实际有效成本')">
                                <template slot-scope="scope">
                                    <span>{{ (scope.row.practicalValidCost).toFixed(2) }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="practicalShopCost" min-width="160" align="right" label="实际到店成本" sortable :width="flexwidth('practicalShopCost',channelTableData,'实际到店成本')">
                                <template slot-scope="scope">
                                    <span>{{ (scope.row.practicalShopCost).toFixed(2) }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="practicalSignCost" min-width="160" align="right" label="实际签单成本" sortable :width="flexwidth('practicalSignCost',channelTableData,'实际签单成本')">
                                <template slot-scope="scope">
                                    <span>{{ (scope.row.practicalSignCost).toFixed(2) }}</span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    </div>
                </div>
            </div>
            <div class="department">
                <div class="nav">
                    <div class="title">部门数据</div>
                </div>
                <div class="content">
                    <div class="tab center flex">
                        <div :class=" active8 == '1' ? 'active':'' " @click="department1('1')">本周</div>
                        <div :class=" active8 == '2' ? 'active':'' " @click="department1('2')">本月</div>
                        <div :class=" active8 == '3' ? 'active':'' " @click="department1('3')">半年</div>
                    </div>
                    <div class="table">
                        <div class="table">
                        <el-table :data="departmentData" border style="width: 100%" :row-class-name="tableRowClassName" cell-class-name="table_cell">
                            <el-table-column type="index" width="80" align="center" fixed="left" label="TOP"></el-table-column>
                            <el-table-column prop="departmentName" min-width="100" align="left" fixed="left" label="广告部"></el-table-column>
                            <el-table-column prop="consume" min-width="140" align="right" label="消耗" sortable>
                                <template slot-scope="scope">
                                    <span>{{ (scope.row.consume).toFixed(2) }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="practicalExpenditure" min-width="140" align="right" label="实际花费" sortable>
                                <template slot-scope="scope">
                                    <span>{{ (scope.row.practicalExpenditure).toFixed(2) }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="threadNumber" min-width="100" align="left" label="线索量" sortable></el-table-column>
                            <el-table-column prop="practicalThreadCost" min-width="160" align="right" label="实际线索成本" sortable >
                                <template slot-scope="scope">
                                    <span>{{ (scope.row.practicalThreadCost).toFixed(2) }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="practicalValidCost" min-width="160" align="right" label="实际有效成本" sortable >
                                <template slot-scope="scope">
                                    <span>{{ (scope.row.practicalValidCost).toFixed(2) }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="practicalShopCost" min-width="160" align="right" label="实际到店成本" sortable >
                                <template slot-scope="scope">
                                    <span>{{ (scope.row.practicalShopCost).toFixed(2) }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="practicalSignCost" min-width="160" align="right" label="实际签单成本" sortable >
                                <template slot-scope="scope">
                                    <span>{{ (scope.row.practicalSignCost).toFixed(2) }}</span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    </div>
                </div>
            </div>
            <div class="operate">
                <div class="nav">
                    <div class="title">运营人数据</div>
                </div>
                <div class="content">
                    <div class="tab center flex">
                        <div :class=" active9 == '1' ? 'active':'' " @click="operate('1')">本周</div>
                        <div :class=" active9 == '2' ? 'active':'' " @click="operate('2')">本月</div>
                        <div :class=" active9 == '3' ? 'active':'' " @click="operate('3')">半年</div>
                    </div>
                    <div class="table">
                        <div class="table">
                        <el-table :data="operateData" border style="width: 100%" cell-class-name="table_cell">
                            <el-table-column type="index" width="80" align="center" fixed="left" label="TOP"></el-table-column>
                            <el-table-column prop="operateName" min-width="100" align="left" fixed="left" label="运营人"></el-table-column>
                            <el-table-column prop="consume" min-width="140" align="right" label="消耗" sortable>
                                <template slot-scope="scope">
                                    <span>{{ (scope.row.consume).toFixed(2) }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="practicalExpenditure" min-width="140" align="right" label="实际花费" sortable>
                                <template slot-scope="scope">
                                    <span>{{ (scope.row.practicalExpenditure).toFixed(2) }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="threadNumber" min-width="100" align="left" label="线索量" sortable></el-table-column>
                            <el-table-column prop="practicalThreadCost" min-width="160" align="right" label="实际线索成本" sortable >
                                <template slot-scope="scope">
                                    <span>{{ (scope.row.practicalThreadCost).toFixed(2) }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="practicalValidCost" min-width="160" align="right" label="实际有效成本" sortable >
                                <template slot-scope="scope">
                                    <span>{{ (scope.row.practicalValidCost).toFixed(2) }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="practicalShopCost" min-width="160" align="right" label="实际到店成本" sortable >
                                <template slot-scope="scope">
                                    <span>{{ (scope.row.practicalShopCost).toFixed(2) }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="practicalSignCost" min-width="160" align="right" label="实际签单成本" sortable >
                                <template slot-scope="scope">
                                    <span>{{ (scope.row.practicalSignCost).toFixed(2) }}</span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
	import api from '@/api/president.js'
    import moment from 'moment';
    import { Toast } from 'vant';
    import * as echarts from 'echarts';
	import { timeFormat } from '@/utils/timeCompute'
	export default {
		data() {
			return {
                active:'1',  //部门
                active1:'1', //当月数据
                monthTableData:[],
                tableData: [],
                
                active2:'1', //费效率
                active3:'1', //产值
                scheduling:'', //时间进度
                outputTableData:[],

                show1: false,
                placement: 'bottom',
                value1:{ text: '全部', value: '' },
                option1: [
                    { text: '全部', value: '' },
                    // { text: '济南', value: '370100' },
                    // { text: '青岛', value: '370200' },
                    // { text: '淄博', value: '370300' },
                    // { text: '烟台', value: '370600' },
                    // { text: '潍坊', value: '370700' },
                    // { text: '威海', value: '371000' },
                    // { text: '济宁', value: '370800' },
                    // { text: '临沂', value: '371300' },
                ],
                active4:'1', //线索
                
                show2: false,
                value2:{ text: '线索量', value: '1',unit:"单位/个" },
                option2: [
                    { text: '线索量', value: '1', unit:"单位/个"},
                    { text: '有效量', value: '2', unit:"单位/个"},
                    { text: '到店量', value: '3', unit:"单位/个"},
                    { text: '签单量', value: '4', unit:"单位/个"},
                    { text: '有效率', value: '5', unit:"单位/%"},
                    { text: '到店率', value: '6', unit:"单位/%"},
                    { text: '签单率', value: '7', unit:"单位/%"},
                    { text: '实际线索成本', value: '8', unit:"单位/元"},
                    { text: '实际有效成本', value: '9', unit:"单位/元"},
                    { text: '实际到店成本', value: '10', unit:"单位/元"},
                    { text: '实际签单成本', value: '11', unit:"单位/元"},
                ],
                active5:'1', //市场
                
                value3:{ text: '全部', value: '' },
                value4:{ text: '线索量', value: '1' },
                show3: false,
                show4: false,
                active6:'1',
                channelEchartData:[],
                showChannelEchart:false,

                // ------------------------------
                consumeSumDate: 0,
                clueSumDate: 0,
                xianBenDate: 0,
                consumeSumMonth: 0,
                clueSumMonth: 0,
                xianBenMonth: 0,

                active7:'1',
                channelTableData:[],
                active8:'1',
                departmentData:[],
                active9:'1',
                operateData:[],
                // ---时间--------
                thisWeek:'',
                thisMonth:'',
                lastMonth:'',
                thisYear:'',
                halfYear:'',
            }
		},
		components: {
		},
		created() {
		},
        mounted() {
            this.thisWeek = moment().startOf('week').add(1,'days').format('YYYY-MM-DD')+"~"+moment().endOf('week').add(1,'days').format('YYYY-MM-DD')
            this.thisMonth = moment().format('YYYY-MM')
            this.lastMonth = moment(moment().month(moment().month() - 1)).format('YYYY-MM')
            this.thisYear = moment().format('YYYY')
            if(Number(moment().format('MM')) < 7){
                this.halfYear = moment().format('YYYY')+'-'+'01'+'~'+moment().format('YYYY')+'-'+'06'
            }else{
                this.halfYear = moment().format('YYYY')+'-'+'07'+'~'+moment().format('YYYY')+'-'+'12'
            }
            this.scheduling ='本月时间进度：'+((Number(moment().format('DD'))/Number(moment().endOf('month').format('DD')))*100).toFixed(0)+'%'

            this.getMarketCode()
            this.getMonthData()
            this.getCostEffectivenessRatio(this.active2)
            this.getOutputData(this.thisMonth)
            this.getClueData(this.active4)
            this.getMarketEchart(this.active5)
            this.getChanneltEchart(this.active6)
            this.getIndicators()
            this.getChannelData(this.active7,this.thisWeek)
            this.getDepartmentData(this.active8,this.thisWeek)
            this.getOperateData(this.active9,this.thisWeek)
            window.addEventListener('scroll', this.handleScroll)

		},
		methods: {
            //回退
            back () {
                // this.$router.push({
                //     name:"TaskManagement"
                // })
            },
            formatDate(date) {
                let month,day
                if((date.getMonth() + 1)<10){
                    month = '0'+ (date.getMonth() + 1)
                }else{
                    month = (date.getMonth() + 1)
                }
                if(date.getDate()<10){
                    day = '0'+date.getDate()
                }else{
                    day = date.getDate()
                }
                return `${date.getFullYear()}-${month}-${day}`;
            },
            // 封装
            handleEcharts (name, option) {
                var chartDom = document.querySelector(name);
                // var chartDom = document.getElementById('main');
                var myChart = echarts.init(chartDom, 'night');
                option && myChart.setOption(option);
            },
            handleScroll(){
                this.show1 = false
                this.show2 = false
                this.show3 = false
                this.show4 = false
            },
            adjustPlacement(name) {
                const targetRect = name.getBoundingClientRect();
                const windowHeight = window.innerHeight;
                console.log(targetRect.bottom + 175 , windowHeight);
                if (targetRect.bottom + 175 > windowHeight) {
                    this.placement = 'top-end';
                } else{
                    this.placement = 'bottom-end';
                }
            },
            getContainer() {
                return document.querySelector('.technology_kanban');
            },
            getMarketCode(){
                let params = {
                    'Q_dict.code_IN': 'DICT771486',
                }
                api.dictItem(params).then((res)=>{
                    console.log(res,'1111111111111111');
                    res.data.forEach((item)=>{
                        this.option1.push({ text: item.name, value: item.code })
                    })
                })
            },
            department(type){
                this.active = type
                document.body.scrollTop = document.documentElement.scrollTop = 0;
            },
            monthData(type){
                this.active1 = type
                this.getMonthData()
            },
            getMonthData(date){
                let params = {
                    'ext':this.active1,
                    'order':'7',
                }
                api.monthTableApi(params).then((res)=>{
                    console.log(res);
                    this.monthTableData = res.data
                })
            },
            monthDetails(){
                this.$router.push({
                    name: 'monthDetails',
                    query: {
                        type:this.active1,
                    }
                })
            },
            getSummaries(param) {
                const { columns, data } = param;
                const sums = [];
                columns.forEach((column, index) => {
                    console.log(column);
                    if (index === 0) {
                        sums[index] = <div>
                            <div class='amount'>合计</div>
                            <div class='average'>平均</div>
                        </div> ;
                        return;
                    }
                
                });
                return sums
            },
            costEffectivenessRatio(type){
                this.active2 = type
                this.getCostEffectivenessRatio(this.active2)
            },
            getCostEffectivenessRatio(type){
                let date = ''
                if( type == '1'){
                    date = this.thisMonth
                }else{
                    date = this.lastMonth
                }
                let params = {
                    'marketCode':'99',
                    'Q_reportData_EQ':date
                }
                api.costEffectivenessRatioApi(params).then((res)=>{
                    if(JSON.stringify(res.data) == '{}'){
                        res.data = {
                            data:[],
                            market:[]
                        }
                    }
                    this.$nextTick(()=>{
                        this.getEcharts1(res.data)
                    })
                })
            },
            getEcharts1(data){
                let line = [
                    { name:'保底', yAxis: 15 , lineStyle:{ color:"#FDAF18" ,} },
                    { name:'任务', yAxis: 12.5 , lineStyle:{ color:"#1CC976" ,} },
                    { name:'目标', yAxis: 10 , lineStyle:{ color:"#FF453A" ,} }
                ]

                let arr1max = Number(Math.max(...data?.data))
                arr1max = arr1max <= 15 ? 20 : arr1max

                let option = {
                    grid: {
                        top:"6%",
                        bottom:"10%",
                        left:'13%',
                        right:'5%'
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow' ,
                        },
                        // formatter:'{b}：{c}%'
                        formatter:function(params){
                            console.log(params);
                            return `
                                    <div style='margin-bottom:2px;display:flex;align-items: center;'><div style='margin-right:5px;width:10px;height:10px;background:${line[0].lineStyle.color}'></div>${line[0].name}<span style='width:60px;font-weight: bold;text-align:right'> ${line[0].yAxis}%</span></div>
                                    <div style='margin-bottom:2px;display:flex;align-items: center;'><div style='margin-right:5px;width:10px;height:10px;background:${line[1].lineStyle.color}'></div>${line[1].name}<span style='width:60px;font-weight: bold;text-align:right'> ${line[1].yAxis}%</span></div>
                                    <div style='margin-bottom:2px;display:flex;align-items: center;'><div style='margin-right:5px;width:10px;height:10px;background:${line[2].lineStyle.color}'></div>${line[2].name}<span style='width:60px;font-weight: bold;text-align:right'> ${line[2].yAxis}%</span></div>
                                    <div style='margin-bottom:2px;display:flex;align-items: center;'><div style='margin-right:5px;width:10px;height:10px;background:transparent'></div>${params[0].name}<span style='width:60px;font-weight: bold;text-align:right'> ${params[0].value}%</span></div>
                            `
                        },
                        rich: {
                            name:{
                                padding:[10,0,10,0] 
                            },
                            rate: {
                                fontSize: 10,
                                color: '#999'
                            }
                        }
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: data?.market || []
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            axisLabel: {
                                formatter: '{value}%'
                            },
                            max:arr1max
                        }
                    ],
                    series: [
                        {
                            name: 'Rainfall',
                            type: 'bar',
                            color:'#31A6FF',
                            data: data?.data || [],
                            barWidth:'30%',
                            // label: {
                            //     show: true,
                            //     position: 'top',
                            //     valueAnimation: true
                            // },
                            markLine: {
                                label: {
                                    formatter: "",
                                },
                                data: line
                            }
                        }
                    ]
                };

                this.handleEcharts('.echart1', option)
            },
            output(type){
                this.active3 = type
                if(type == 1){
                    this.getOutputData(this.thisMonth)
                    this.scheduling ='本月时间进度：'+((Number(moment().format('DD'))/Number(moment().endOf('month').format('DD')))*100).toFixed(0)+'%'
                }else{
                    this.getOutputData(this.lastMonth)
                    this.scheduling ='上月时间进度：100%'
                }
            },
            getOutputData(date){
                let params = {
                    'marketCode':'99',
                    'Q_reportData_EQ':date
                }
                api.outputDataApi(params).then((res)=>{
                    console.log(res);
                    this.outputTableData = res.data
                })
            },
            clueChange(e){
                console.log(e);
                this.value1 = e
                this.getClueData(this.active4)
            },
            showPopover1(e) {
                console.log(e);
                this.show1 = !this.show1;
                this.adjustPlacement(this.$refs.target1);
            },
            clue(type){
                this.active4 = type
                this.getClueData(this.active4)
            },
            getClueData(type){
                let date = ''
                if( type == '1'){
                    date = this.thisWeek
                }else if(type == '2'){
                    date = this.thisMonth
                }else{
                    date = this.thisYear
                }

                let params = {
                    'ext':type,
                    'Q_marketCode_EQ':this.value1.value,
                    'Q_reportData_EQ':date
                }
                api.clueEchartApi(params).then((res)=>{
                    console.log(res);
                    this.getEcharts2(res.data)
                })
            },
            getEcharts2(data){
                let option = {
                    grid: {
                        top:"15%",
                        bottom:"23%",
                        left:'15%',
                        right:'5%'
                    },
                    legend: {
                        bottom:'2%',
                        data: ['线索量', '有效量']
                    },
                    tooltip: {
                        trigger: 'axis',
                        textStyle:{
                            align:'left'
                        }
                    },
                    xAxis: {
                        type: 'category',
                        data: data?.date || [],
                    },
                    yAxis: {
                        type: 'value',
                        name:'单位:个',
                        nameTextStyle:{
                            color:'#999999',
                        }
                    },
                    series: [
                        {
                            name:'线索量',
                            type: 'line',
                            color:'#FDAF18',
                            data: data?.mediaSum || [],
                        },
                        {
                            name:'有效量',
                            type: 'line',
                            color:'#1CC976',
                            data: data?.validSum || [],
                        }
                    ]
                };
                this.handleEcharts('.echart2', option)
            },
            marketChange(e){
                console.log(e);
                this.value2 = e
                this.getMarketEchart(this.active5)
            },
            marketData(type){
                this.active5 = type
                this.getMarketEchart(this.active5)
            },
            showPopover2(e) {
                console.log(e);
                this.show2 = !this.show2;
                this.adjustPlacement(this.$refs.target2);
            },
            getMarketEchart(type){
                let date = ''
                if( type == '1'){
                    date = this.thisWeek
                }else if(type == '2'){
                    date = this.thisMonth
                }else{
                    date = this.thisYear
                }

                let params = {
                    'ext':type,
                    'order':this.value2.value,
                    'Q_reportData_EQ':date
                }
                api.marketEchartApi(params).then((res)=>{
                    console.log(res);
                    this.getEcharts3(res.data,this.value2.unit)
                })
            },
            getEcharts3(data,unit){
                let option = {
                    grid: {
                        top:"12%",
                        bottom:"13%",
                        left:'12%',
                        right:'5%'
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow' ,
                        },
                        formatter:'{b}：{c}'
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: data?.marketName||[],
                            axisLabel: {
                                interval: '0'
                            }
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            name: unit
                        }
                    ],
                    series: [
                        {
                            name: 'Rainfall',
                            type: 'bar',
                            color:'#31A6FF',
                            data: data?.value||[],
                            barWidth:'30%',
                        }
                    ]
                };
                this.handleEcharts('.echart3', option)
            },
            channelChange1(e){
                console.log(e);
                this.value3 = e
                this.getChanneltEchart(this.active6)
            },
            channelChange2(e){
                console.log(e);
                this.value4 = e
                this.getChanneltEchart(this.active6)
            },
            channel(type){
                this.active6 = type
                this.getChanneltEchart(this.active6)
            },
            showPopover3(e) {
                console.log(e);
                this.show3 = !this.show3;
                this.adjustPlacement(this.$refs.target3);
            },
            showPopover4(e) {
                console.log(e);
                this.show4 = !this.show4;
                this.adjustPlacement(this.$refs.target4);
            },
            getChanneltEchart(type){
                let date = ''
                if( type == '1'){
                    date = moment().format('YYYY-MM-DD')
                }else{
                    date = this.thisMonth
                }
                let params = {
                    'ext':this.active6,
                    'Q_reportData_EQ':date,
                    'Q_marketCode_EQ':this.value3.value,
                    'order':this.value4.value,
                }
                api.channelEchartApi(params).then((res)=>{
                    console.log(res,'channelEchartApi');
                    this.channelEchartData = []
                    res.data.forEach((item)=>{
                        if(item.value  != 0){
                            if(item.name.length>4){
                                item.name = item.name.slice(0,4)+'...'
                            }
                            this.channelEchartData.push(item)
                        }
                    })
                    this.showChannelEchart = this.channelEchartData.length > 0 ?true:false
                    if(this.showChannelEchart){
                        this.$nextTick(()=>{
                            this.getEcharts4(this.channelEchartData)
                        })
                    }
                    console.log(this.showChannelEchart , this.channelEchartData.length,'this.showChannelEchart = this.channelEchartData.length');
                })
            },
            getEcharts4(data){
                for(var singlep in data){
                    console.log("singlepsinglepsinglepsinglep",singlep,data[singlep].value)
                    let _v = data[singlep].value;
                    if(_v < 0){
                        data[singlep].value = Math.abs(_v)
                    }
                    data[singlep].value2 = _v
                }
                let option = {
                    series: [{
                        name: '渠道占比',
                        type: 'pie',
                        radius: ['30%', '40%'],
                        avoidLabelOverlap: true,
                        itemStyle: {
                            borderColor: '#fff',
                            borderWidth: 2
                        },
                        label: {
                            // position:'outer',
                            alignTo: 'edge',
                            // alignTo:'lableLine',
                            // formatter:'NO.{@sort} {name|{b}}\n{num|{c}个}\n{rate|{d}%}',
                            formatter:(params)=>{
                                return `{name|NO.${params.data.sort}  ${params.name}}\n{num|${params.data.value2} ${params.data.unit}}  {rate|${params.data.proportion}}`
                            },    
                            rich: {
                                name:{
                                    fontSize: 13,
                                    padding:[0,0,2,0]
                                },
                                num:{
                                    fontSize: 12,
                                    padding:[2,0,0,0] 
                                },
                                rate: {
                                    fontSize: 12,
                                    color: '#999',
                                    padding:[2,0,0,0]
                                }
                            },
                            minMargin: 5,
                            edgeDistance: 30,
                            lineHeight: 20,
                        },
                        labelLine: {
                            show: true,
                            length: 20,
                            length2: 0,
                            maxSurfaceAngle: 80
                        },
                        labelLayout: function (params) {
                            const isLeft = params.labelRect.x < document.querySelector('.echart4').getBoundingClientRect().width / 2;
                            const points = params.labelLinePoints;
                            // Update the end point.
                            points[2][0] = isLeft
                            ? params.labelRect.x
                            : params.labelRect.x + params.labelRect.width;
                            return {
                                labelLinePoints: points
                            };
                        },
                        data: data
                    }]
                };
                console.log('aaaadataoptionoptionoptionoption------------------',option)
                this.handleEcharts('.echart4', option)
            },
            // --------------------------------------
            getIndicators(){
                api.indicatorsApi().then((res)=>{
                    console.log(res);
                    this.consumeSumDate = res.data.consumeSumDate
                    this.clueSumDate = res.data.clueSumDate
                    this.xianBenDate = res.data.xianBenDate
                    this.consumeSumMonth = res.data.consumeSumMonth
                    this.clueSumMonth = res.data.clueSumMonth
                    this.xianBenMonth = res.data.xianBenMonth
                })
            },
            tableRowClassName({row,rowIndex}){
                if(rowIndex>9){
                    return 'hidden_box'
                }   
                return ''
            },
            channel1(type){ // 展示10条
                this.active7 = type
                if( type == '1'){
                    this.getChannelData(this.active7,this.thisWeek)
                }else if(type == '2'){
                    this.getChannelData(this.active7,this.thisMonth+'~'+this.thisMonth)
                }else{
                    this.getChannelData('2',this.halfYear)
                }
            },
            getChannelData(type,date){
                let params = {
                    'type':type,
                    'Q_reportData_BW':date
                }
                api.channelDataApi(params).then((res)=>{
                    console.log(res);
                    res.data.forEach((item)=>{
                        if( item.channelName && item.channelName.length > 4 ) item.channelName = item.channelName.slice(0,4)+'...'
                        item.consume = Number(item.consume)
                        item.practicalExpenditure = Number(item.practicalExpenditure)
                        item.practicalShopCost = Number(item.practicalShopCost)
                        item.practicalSignCost = Number(item.practicalSignCost)
                        item.practicalThreadCost = Number(item.practicalThreadCost)
                        item.practicalValidCost = Number(item.practicalValidCost)
                    })
                    this.channelTableData = res.data
                })
            },
            flexwidth(prop, tableData, title,flag) {
                if (tableData.length === 0 ) return; //表格没数据不做处理
                let flexwidth = 0;//初始化表格列宽
                let columnContent = '';//占位最宽的内容
                let canvas = document.createElement("canvas");
                let context = canvas.getContext("2d");

                var tableDom = document.querySelector('.el-table  .cell');
                
                var style = window.getComputedStyle(tableDom);
                var paddingLeft = parseFloat(style.paddingLeft)
                var paddingRight = parseFloat(style.paddingRight)
                context.font = style.fontSize+" Microsoft YaHei";
                
                if ((prop === '') && title) {//标题长内容少的，取标题的值
                    columnContent = title
                } else {// 获取该列中占位最宽的内容
                    let index = 0;
                    for (let i = 0; i < tableData.length; i++) {
                        const now_temp = tableData[i][prop] + "";
                        const max_temp = tableData[index][prop] + "";
                        const now_temp_w = context.measureText(now_temp).width
                        const max_temp_w = context.measureText(max_temp).width
                        if (now_temp_w > max_temp_w) {
                            index = i;
                        }
                    }
                    columnContent = tableData[index][prop]
                    //比较占位最宽的值跟标题、标题为空的留出四个位置
                    const column_w = context.measureText(columnContent).width
                    let title_w = context.measureText(title).width
                    if(flag != 'noSort'){
                        title_w = context.measureText(title+'排序').width
                    }
                    if (column_w < title_w) {
                        columnContent = title || '留四个字'
                        if(flag != 'noSort'){
                            columnContent = title + '排序'
                        }
                    }
                }
                
                // 计算最宽内容的列宽
                let width = context.measureText(columnContent);
                flexwidth = width.width + paddingLeft + (paddingRight*2) 
                console.log(columnContent,flexwidth);
                // debugger
                return Math.ceil(flexwidth) + "px"
            },
            channel1Details(){
                this.$router.push({
                    name: 'channelDetails',
                    query: {
                        type:this.active7,
                    }
                })
            },
            department1(type){
                this.active8 = type
                if( type == '1'){
                    this.getDepartmentData(this.active8,this.thisWeek)
                }else if(type == '2'){
                    this.getDepartmentData(this.active8,this.thisMonth+'~'+this.thisMonth)
                }else{
                    this.getDepartmentData('2',this.halfYear)
                }
            },
            getDepartmentData(type,date){
                let params = {
                    'type':type,
                    'Q_reportData_BW':date
                }
                api.departmentDataApi(params).then((res)=>{
                    console.log(res);
                    res.data.forEach((item)=>{
                        item.consume = Number(item.consume)
                        item.practicalExpenditure = Number(item.practicalExpenditure)
                        item.practicalShopCost = Number(item.practicalShopCost)
                        item.practicalSignCost = Number(item.practicalSignCost)
                        item.practicalThreadCost = Number(item.practicalThreadCost)
                        item.practicalValidCost = Number(item.practicalValidCost)
                    })
                    this.departmentData = res.data
                })
            },
            operate(type){
                this.active9 = type
                if( type == '1'){
                    this.getOperateData(this.active9,this.thisWeek)
                }else if(type == '2'){
                    this.getOperateData(this.active9,this.thisMonth+'~'+this.thisMonth)
                }else{
                    this.getOperateData('2',this.halfYear)
                }
            },
            getOperateData(type,date){
                let params = {
                    'type':type,
                    'Q_reportData_BW':date
                }
                api.operateDataApi(params).then((res)=>{
                    console.log(res);
                    res.data.forEach((item)=>{
                        item.consume = Number(item.consume)
                        item.practicalExpenditure = Number(item.practicalExpenditure)
                        item.practicalShopCost = Number(item.practicalShopCost)
                        item.practicalSignCost = Number(item.practicalSignCost)
                        item.practicalThreadCost = Number(item.practicalThreadCost)
                        item.practicalValidCost = Number(item.practicalValidCost)
                    })
                    this.operateData = res.data
                })
            },
        },
		
	}
</script>

<style scoped lang="scss">
.flex{
    display: flex;
}
.nav_b {
    background: #3975C6;
}

.center{
    display: flex;
    justify-content: center;
    align-items: center;
}


.main{
    background: #F5F5F5;

    .tabs{
        width: 100%;
        height: 48px;
        font-size: 18px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        background: #4871C0;

        div{
            width: 50%;
            line-height: 48px;
            border-radius: 8px 8px 0px 0px;
            background: rgba(72,113,192,0.1);
            background-blend-mode: multiply
        }
        .active{
            font-size: 20px;
            font-family: PingFang SC-Bold, PingFang SC;
            color: #3A72C6;
            background: #FFFFFF;
            box-shadow: 0px 4px 20px 0px rgba(214,214,214,0.2);
        }
    }

    .tabsContent{
        width: 100%;
        height: 100%;

        .monthData{
            width: 100%;
            height: 100%;
            background: #FFFFFF;
            padding-top: 13px;
            margin-bottom: 15px;
            .nav{
                height: 35px;
                display: flex;
                justify-content: space-between;
                .title{
                    width: 90px;
                    line-height: 30px;
                    background: url('../../assets/indicatorsTBg.png') no-repeat;
                    background-size: 100% 100%;
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    text-align: left;
                    padding-left: 10px;
                }
                .tip{
                    font-size: 16px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #4871C0;
                    line-height: 46px;
                    margin-left: 5px;
                }
                .more{
                    font-size: 14px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #4F4F4F;
                    line-height: 46px;
                    margin-right: 8px;
                }
            }
            .content{
                .tab{
                    margin: 24px 12px 16px;
                    justify-content: space-between;
                    background: #F8F8F8;
                    border-radius: 4px 4px 4px 4px;

                    div{
                        font-size: 14px;
                        font-family: PingFang SC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #545454;
                        width: 25%;
                        line-height: 36px;
                    }
                    .active{
                        font-size: 14px;
                        font-family: PingFang SC-Bold, PingFang SC;
                        color: #4871C0;
                        background: #FFFFFF;
                        border-radius: 4px 4px;
                        border: 1px solid #4871C0;
                    }
                }

                .table{
                    overflow:auto;
                }

                .amount{
                    padding-bottom: 8px;
                    color: #FF2B2B;
                }
                .average{
                    padding-top: 8px;
                }
            }
        }

        .costEffectivenessRatio{
            width: 100%;
            height: 100%;
            background: #FFFFFF;
            padding-top: 13px;
            margin-bottom: 15px;
            .nav{
                height: 35px;
                display: flex;
                justify-content: space-between;
                .title{
                    width: 100px;
                    line-height: 30px;
                    background: url('../../assets/trendTBg.png') no-repeat;
                    background-size: 100% 100%;
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    text-align: left;
                    padding-left: 10px;
                }
            }

            .content{
                .tab{
                    margin: 20px 12px 18px;
                    justify-content: space-between;
                    background: #F8F8F8;
                    border-radius: 4px 4px 4px 4px;

                    div{
                        font-size: 14px;
                        font-family: PingFang SC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #545454;
                        width: 50%;
                        line-height: 36px;
                    }
                    .active{
                        font-size: 14px;
                        font-family: PingFang SC-Bold, PingFang SC;
                        color: #4871C0;
                        background: #FFFFFF;
                        border-radius: 4px 4px;
                        border: 1px solid #4871C0;
                    }
                }

                .echart{
                    width: 100%;
                    height: 240px;
                    .echart1{
                        width: 100%;
                        height: 200px;
                        overflow: hidden;
                    }
                    .tip{
                        font-size: 14px;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #999999;
                        margin-top: 5px;
                        display: flex;
                        justify-content: center;
                        div{
                            width: 25%;
                            height: 30px;
                            position: relative;
                            line-height: 30px;
                        }
                    }
                    .tip div:nth-child(1)::after{
                        content: '';
                        width: 8px;
                        height: 8px;
                        background: #FDAF18;
                        position: absolute;
                        left: 20%;
                        top: 50%;
                        margin-top: -4px;
                    }
                    .tip div:nth-child(2)::after{
                        content: '';
                        width: 8px;
                        height: 8px;
                        background: #1CC976;
                        position: absolute;
                        left: 20%;
                        top: 50%;
                        margin-top: -4px;
                    }
                    .tip div:nth-child(3)::after{
                        content: '';
                        width: 8px;
                        height: 8px;
                        background: #FF453A;
                        position: absolute;
                        left: 20%;
                        top: 50%;
                        margin-top: -4px;
                    }
                }
            }
        }

        .output{
            width: 100%;
            height: 100%;
            background: #FFFFFF;
            padding-top: 13px;
            margin-bottom: 15px;
            .nav{
                height: 35px;
                display: flex;
                .title{
                    width: 120px;
                    line-height: 30px;
                    background: url('../../assets/indicatorsTBg.png') no-repeat;
                    background-size: 100% 100%;
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    text-align: left;
                    padding-left: 10px;
                }
                .tip{
                    font-size: 16px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #4871C0;
                    line-height: 46px;
                    margin-left: 5px;
                }
            }
            .content{
                .tab{
                    margin: 20px 12px 18px;
                    justify-content: space-between;
                    background: #F8F8F8;
                    border-radius: 4px 4px 4px 4px;

                    div{
                        font-size: 14px;
                        font-family: PingFang SC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #545454;
                        width: 50%;
                        line-height: 36px;
                    }
                    .active{
                        font-size: 14px;
                        font-family: PingFang SC-Bold, PingFang SC;
                        color: #4871C0;
                        background: #FFFFFF;
                        border-radius: 4px 4px;
                        border: 1px solid #4871C0;
                    }
                }

            }
        }

        .clue{
            width: 100%;
            height: 100%;
            background: #FFFFFF;
            padding-top: 13px;
            margin-bottom: 15px;
            .nav{
                height: 35px;
                display: flex;
                justify-content: space-between;
                padding-right: 10px;
                .title{
                    width: 90px;
                    line-height: 30px;
                    background: url('../../assets/trendTBg.png') no-repeat;
                    background-size: 100% 100%;
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    text-align: left;
                    padding-left: 10px;
                }
                
            }

            .content{
                .tab{
                    margin: 24px 12px 16px;
                    justify-content: space-between;
                    background: #F8F8F8;
                    border-radius: 4px 4px 4px 4px;

                    div{
                        font-size: 14px;
                        font-family: PingFang SC-Medium, PingFang SC;
                        color: #545454;
                        width: 33%;
                        line-height: 36px;
                    }
                    .active{
                        font-size: 14px;
                        font-family: PingFang SC-Bold, PingFang SC;
                        color: #4871C0;
                        background: #FFFFFF;
                        border-radius: 4px 4px;
                        border: 1px solid #4871C0;
                    }
                }
                .echart2{
                    width: 100%;
                    height: 240px;
                    overflow: hidden;
                }
            }
        }

        .market{
            width: 100%;
            height: 100%;
            background: #FFFFFF;
            padding-top: 13px;
            margin-bottom: 15px;
            .nav{
                height: 35px;
                display: flex;
                justify-content: space-between;
                padding-right: 10px;
                .title{
                    width: 90px;
                    line-height: 30px;
                    background: url('../../assets/indicatorsTBg.png') no-repeat;
                    background-size: 100% 100%;
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    text-align: left;
                    padding-left: 10px;
                }
            }

            .content{
                .tab{
                    margin: 24px 12px 16px;
                    justify-content: space-between;
                    background: #F8F8F8;
                    border-radius: 4px 4px 4px 4px;

                    div{
                        font-size: 14px;
                        font-family: PingFang SC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #545454;
                        width: 33%;
                        line-height: 36px;
                    }
                    .active{
                        font-size: 14px;
                        font-family: PingFang SC-Bold, PingFang SC;
                        color: #4871C0;
                        background: #FFFFFF;
                        border-radius: 4px 4px;
                        border: 1px solid #4871C0;
                    }
                }
                .echart3{
                    width: 100%;
                    height: 240px;
                    overflow: hidden;
                }
            }
        }

        .channel{
            width: 100%;
            height: 100%;
            background: #FFFFFF;
            padding-top: 13px;
            margin-bottom: 15px;
            .nav{
                height: 35px;
                display: flex;
                justify-content: space-between;
                padding-right: 10px;
                .title{
                    width: 90px;
                    line-height: 30px;
                    background: url('../../assets/trendTBg.png') no-repeat;
                    background-size: 100% 100%;
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    text-align: left;
                    padding-left: 10px;
                }
            }
            .content{
                .tab{
                    margin: 24px 12px 16px;
                    justify-content: space-between;
                    background: #F8F8F8;
                    border-radius: 4px 4px 4px 4px;

                    div{
                        font-size: 14px;
                        font-family: PingFang SC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #545454;
                        width: 50%;
                        line-height: 36px;
                    }
                    .active{
                        font-size: 14px;
                        font-family: PingFang SC-Bold, PingFang SC;
                        color: #4871C0;
                        background: #FFFFFF;
                        border-radius: 4px 4px;
                        border: 1px solid #4871C0;
                    }
                }
                .echart4{
                    width: 100%;
                    height: 240px;
                    overflow: hidden;
                }
            }
        }
    }

    .tabsContent1{
        .today{
            width: 100%;
            height: 100%;
            background: #FFFFFF;
            padding-top: 13px;
            margin-bottom: 15px;
            .nav{
                height: 35px;
                display: flex;
                justify-content: space-between;
                .title{
                    width: 90px;
                    line-height: 30px;
                    background: url('../../assets/indicatorsTBg.png') no-repeat;
                    background-size: 100% 100%;
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    text-align: left;
                    padding-left: 10px;
                }
            }
            .content{
                display: flex;
                justify-content: center;
                padding: 20px 12px 10px;
                div{
                    width: 33%;
                    height: 65px;
                    background: #F7F8F9;
                    display: flex;
                    justify-content: center;
                    align-content: center;
                    align-items: center;
                    flex-wrap: wrap;
                    span{
                        width: 100%;
                        font-size: 13px;
                        font-family: PingFang SC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #464646;
                    }
                    .num{
                        font-size: 19px;
                        font-family: PingFang SC-Bold, PingFang SC;
                        color: #333333;
                    }
                    
                }
            }
        }

        .month{
            width: 100%;
            height: 100%;
            background: #FFFFFF;
            padding-top: 13px;
            margin-bottom: 15px;
            .nav{
                height: 35px;
                display: flex;
                justify-content: space-between;
                .title{
                    width: 90px;
                    line-height: 30px;
                    background: url('../../assets/trendTBg.png') no-repeat;
                    background-size: 100% 100%;
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    text-align: left;
                    padding-left: 10px;
                }
            }
            .content{
                display: flex;
                justify-content: center;
                padding: 20px 12px 10px;
                div{
                    width: 33%;
                    height: 65px;
                    background: #F7F8F9;
                    display: flex;
                    justify-content: center;
                    align-content: center;
                    align-items: center;
                    flex-wrap: wrap;
                    span{
                        width: 100%;
                        font-size: 13px;
                        font-family: PingFang SC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #464646;
                    }
                    .num{
                        font-size: 19px;
                        font-family: PingFang SC-Bold, PingFang SC;
                        color: #333333;
                    }
                    
                }
            }
        }

        .channel{
            width: 100%;
            height: 100%;
            background: #FFFFFF;
            padding-top: 13px;
            margin-bottom: 15px;
            .nav{
                height: 35px;
                display: flex;
                justify-content: space-between;
                .title{
                    width: 90px;
                    line-height: 30px;
                    background: url('../../assets/indicatorsTBg.png') no-repeat;
                    background-size: 100% 100%;
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    text-align: left;
                    padding-left: 10px;
                }
                .more{
                    font-size: 14px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #4F4F4F;
                    line-height: 46px;
                    margin-right: 8px;
                }
            }

            .content{
                .tab{
                    margin: 24px 12px 16px;
                    justify-content: space-between;
                    background: #F8F8F8;
                    border-radius: 4px 4px 4px 4px;

                    div{
                        font-size: 14px;
                        font-family: PingFang SC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #545454;
                        width: 33%;
                        line-height: 36px;
                    }
                    .active{
                        font-size: 14px;
                        font-family: PingFang SC-Bold, PingFang SC;
                        color: #4871C0;
                        background: #FFFFFF;
                        border-radius: 4px 4px;
                        border: 1px solid #4871C0;
                    }
                }
            }
        }

        .department{
            width: 100%;
            height: 100%;
            background: #FFFFFF;
            padding-top: 13px;
            margin-bottom: 15px;
            .nav{
                height: 35px;
                display: flex;
                justify-content: space-between;
                .title{
                    width: 90px;
                    line-height: 30px;
                    background: url('../../assets/trendTBg.png') no-repeat;
                    background-size: 100% 100%;
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    text-align: left;
                    padding-left: 10px;
                }
                .more{
                    font-size: 14px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #4F4F4F;
                    line-height: 46px;
                    margin-right: 8px;
                }
            }

            .content{
                .tab{
                    margin: 24px 12px 16px;
                    justify-content: space-between;
                    background: #F8F8F8;
                    border-radius: 4px 4px 4px 4px;

                    div{
                        font-size: 14px;
                        font-family: PingFang SC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #545454;
                        width: 33%;
                        line-height: 36px;
                    }
                    .active{
                        font-size: 14px;
                        font-family: PingFang SC-Bold, PingFang SC;
                        color: #4871C0;
                        background: #FFFFFF;
                        border-radius: 4px 4px;
                        border: 1px solid #4871C0;
                    }
                }
            }
        }

        .operate{
            width: 100%;
            height: 100%;
            background: #FFFFFF;
            padding-top: 13px;
            margin-bottom: 15px;
            .nav{
                height: 35px;
                display: flex;
                justify-content: space-between;
                .title{
                    width: 100px;
                    line-height: 30px;
                    background: url('../../assets/indicatorsTBg.png') no-repeat;
                    background-size: 100% 100%;
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    text-align: left;
                    padding-left: 10px;
                }
                .more{
                    font-size: 14px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #4F4F4F;
                    line-height: 46px;
                    margin-right: 8px;
                }
            }

            .content{
                .tab{
                    margin: 24px 12px 16px;
                    justify-content: space-between;
                    background: #F8F8F8;
                    border-radius: 4px 4px 4px 4px;

                    div{
                        font-size: 14px;
                        font-family: PingFang SC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #545454;
                        width: 33%;
                        line-height: 36px;
                    }
                    .active{
                        font-size: 14px;
                        font-family: PingFang SC-Bold, PingFang SC;
                        color: #4871C0;
                        background: #FFFFFF;
                        border-radius: 4px 4px;
                        border: 1px solid #4871C0;
                    }
                }
            }
        }
    }
}

.select{
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #4F4F4F;
    text-align: left;
    line-height: 36px;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #DDDDDD;
    min-width: 50px;
    padding: 0 10px;
    position: relative;
}

.empty{
    width: 100%;
    height: 240px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;
    img{
        width: 200px;
        height: 140px;
    }
    div{
        width: 100%;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        margin-top: 5px;
    }
    }

::v-deep{
    .el-table--border .el-table__cell, .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed{
        border-right: none;

    }
    .el-table th.el-table__cell{
        background: #F5F5F5;
    }

    .van-dropdown-menu__bar{
        justify-content: space-between;
        background: transparent;
        padding: 0 10px;
    }
    .van-dropdown-menu__title{
        color: #000000;
    }
    .van-dropdown-menu__item{
        flex: none;
    }
    .van-dropdown-menu__bar{
        height: 100%;
        border-radius: 4px;
        padding-left: 5px;
        display: flex;
        justify-content: left;
        box-shadow:none;
        border: 1px solid #e2e2e2; 
    }
    .van-dropdown-item__option--active,.van-dropdown-item__option--active .van-dropdown-item__icon{
        color: #00aeff;
    }

    .el-table .cell{
        font-weight: normal;
        font-size: 14px;
    }
    .el-table .hidden_box{
        display: none;
    }

    .el-table .el-table__header-wrapper .el-table__cell,.el-table .el-table__fixed-header-wrapper .el-table__cell{
        height: 53px !important;
    }

    .el-table__fixed-right{
        height: 52px !important;
    }
    // .el-table__fixed-right .el-table__fixed-header-wrapper .el-table__cell{
    //     background: linear-gradient(270deg,#ffffff00 0%, #F5F5F5 100%);
    // }

    .monthTable .el-table__row:nth-last-child(1),.monthTable .el-table__row:nth-last-child(2){
        background: #fdfdfd;
    }
    .monthTable .el-table__row:nth-last-child(2) td:nth-child(1){
        color: #FF2B2B;
    }
    .tabsContent1 .el-table__fixed .el-table__fixed-header-wrapper .el-table__cell .cell,.tabsContent1 .el-table .el-table__header-wrapper .cell{
        height: 35px !important;
        line-height: 35px;
    }
    .tabsContent1 .el-table .el-table__row .cell{
        padding-right: 18px;
    }

    .department .table_cell .cell,.operate .table_cell .cell{
        white-space: nowrap;
        text-overflow:ellipsis;
        overflow: hidden;
    }

    body .el-table th.gutter{
        display:table-cell !important;
    }
    body .el-table colgroup.gutter{
        display:table-cell !important;
    }
    table{
        width:100%  !important;
    }

    .van-popover__content{
        max-height: 175px;
        overflow: auto;
    }
    .van-popover__action{
        min-width: 85px;
    }
    .van-sticky--fixed{
        z-index: 99999999;
    }
}

</style>
