<template>
    <div class="directorOperations">
        <headerNav title="运营总裁数据看板">
            <template>
                <span @click="openChooseDate" class="headNav-righ-text">
                    {{ getParams().date }}
                    <van-icon name="arrow-down" />
                </span>
            </template>
        </headerNav>
        <div class="page-content">
            <div class="product-moudule-box" style="background: #3975C6;">
                <div class="product-moudule">
                    <div class="tab-box">
                        <div @click="tabIndex = 1">
                            <span>转单产值</span>
                            <img v-if="tabIndex == 1" :src="require('@/assets/yy-b1.png')">
                        </div>
                        <div @click="tabIndex = 2">
                            <span>补齐产值</span>
                            <img v-if="tabIndex == 2" :src="require('@/assets/yy-b1-2.png')">
                        </div>
                        <img :src="require('@/assets/yy-b0.png')" alt="">
                    </div>
                    <div class="tab-content">
                        <p v-if="tabIndex == 1">{{ productDataValue.maintainActualValue }}<span>万</span></p>
                        <p v-if="tabIndex == 1"
                            :style="`color:${productDataValue.maintainRate > 0 ? '#FF6157' : productDataValue.maintainRate < 0 ? '#17CA5E;' : ''}`">
                            {{ productDataValue.maintainRate > 0 ? '+' +
                                productDataValue.maintainRate :
                                productDataValue.maintainRate < 0 ? '-' + productDataValue.maintainRate :
                                    productDataValue.maintainRate }}% <img class="icon-img"
                                v-if="productDataValue.maintainRate > 0" :src="require('@/assets/hw-s2.svg')">
                                <img class="icon-img" v-if="productDataValue.maintainRate < 0"
                                    :src="require('@/assets/hw-s3.svg')">
                        </p>
                        <p v-if="tabIndex == 2">{{ productDataValue.finaleActualValue }}<span>万</span></p>
                        <p v-if="tabIndex == 2"
                            :style="`color:${productDataValue.finaleRate > 0 ? '#FF6157' : productDataValue.finaleRate < 0 ? '#17CA5E' : ''}`">
                            {{ productDataValue.finaleRate > 0 ? '+' + productDataValue.finaleRate
                                : productDataValue.finaleRate < 0 ? '-' + productDataValue.finaleRate :
                                    productDataValue.finaleRate }}% <img class="icon-img" v-if="productDataValue.finaleRate > 0"
                                :src="require('@/assets/hw-s2.svg')">
                                <img class="icon-img" v-if="productDataValue.finaleRate < 0"
                                    :src="require('@/assets/hw-s3.svg')">
                        </p>
                        <img v-if="tabIndex == 1 && productDataValue.maintainRate >= 0" class="right-img"
                            :src="require('@/assets/yy-m1-2.png')">
                        <img v-if="tabIndex == 1 && productDataValue.maintainRate < 0" class="right-img"
                            :src="require('@/assets/yy-m1.png')">
                        <img v-if="tabIndex == 2 && productDataValue.finaleRate >= 0" class="right-img"
                            :src="require('@/assets/yy-m1-2.png')">
                        <img v-if="tabIndex == 2 && productDataValue.finaleRate < 0" class="right-img"
                            :src="require('@/assets/yy-m1.png')">
                    </div>
                    <img :src="require('@/assets/yy-b2.png')">

                </div>
            </div>
            <div class="clue-moudule">
                <ul>
                    <li v-for="item in cluesLiList" :style="`background:${item.color}`">
                        <p>{{ item.name }}</p>
                        <p>{{ item.value }}</p>
                        <p :style="item.rate > 0 ? 'color: #FB3640;' : item.rate < 0 ? 'color: #17CA5E;' : ''">
                            {{ item.rate }}%
                            <img v-if="item.rate > 0" :src="require(`@/assets/hw-s2.svg`)" alt="">
                            <img v-if="item.rate < 0" :src="require(`@/assets/hw-s3.svg`)" alt="">
                        </p>
                        <div :style="`background:${item.iconBg};box-shadow:${item.iconShadow}`">
                            <img :src="require(`@/assets/${item.icon}.svg`)" alt="">
                        </div>
                    </li>
                </ul>
            </div>
            <div class="product-market">
                <h3>
                    <div>
                        <img :src="require(`@/assets/yy-s7.svg`)" alt="">
                        市场产值分析
                    </div>
                    <div>
                        <ul>
                            <li :class="item.value == productionValueType ? 'avtive-li' : ''"
                                v-for="item in [{ value: '2', name: '对比图' }, { value: '1', name: '走势图' }]"
                                @click="productionValueTypeChange(item.value)">{{ item.name }}</li>
                        </ul>
                    </div>
                </h3>
                <div class="product-market-content">
                    <div class="productionValueEcharts"></div>
                </div>
            </div>
            <div class="sign-market">
                <h3>
                    <div>
                        <img :src="require(`@/assets/yy-s8.svg`)" alt="">
                        市场签单分析
                    </div>
                    <div>
                        <ul>
                            <li :class="item.value == signValueType ? 'avtive-li' : ''"
                                v-for="item in [{ value: '2', name: '对比图' }, { value: '1', name: '走势图' }]"
                                @click="psignValueTypeChange(item.value)">{{ item.name }}</li>
                        </ul>
                    </div>
                </h3>
                <div class="sign-market-content">
                    <div class="signValueEcharts"></div>
                </div>
            </div>
            <div class="clue-rate">
                <h3>
                    <div>
                        <img :src="require(`@/assets/yy-s9.svg`)" alt="">
                        集团有效线索占比
                    </div>
                </h3>
                <div class="clue-rate-content">
                    <div class="clue-echarts-box">
                        <div class="clueEcharts"></div>
                    </div>
                    <div class="clue-num">
                        <div>
                            <p>线索量</p>
                            <p>{{ clueRateData.threadNum }}<span>个</span></p>
                        </div>
                        <div>
                            <p>有效量</p>
                            <p>{{ clueRateData.validThreadNum }}<span>个</span></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="list-moudule">
                <h3>
                    <div>
                        <img :src="require(`@/assets/hw-s6.svg`)" alt="">
                        排行榜
                    </div>
                    <div class="h3-right">
                        <span @click="checkDetails">查看更多<van-icon name="arrow" :size="10"
                                style="margin-left: 8px;" /></span>
                    </div>
                </h3>
                <div class="list-moudule-content">
                    <div class="tab-box">
                        <ul>
                            <li v-for="item in [{ value: '1', name: '签单手' }, { value: '2', name: '设计师资源' }, { value: '3', name: '设计师产值' }]"
                                :class="item.value == listType ? 'active-li' : ''" @click="changeListType(item.value)">{{
                                    item.name }}</li>
                        </ul>
                    </div>
                    <div class="list-moudule-table">
                        <el-table v-show="listType == '1'" :data="signListData" header-row-class-name="table-head-class">
                            <el-table-column type="index" label="排名" align="center" width="65">
                                <template #default="scope">
                                    <span class="cell-img-box" v-if="scope.$index == 0"><img
                                            :src="require('@/assets/hw-s7.svg')"></span>
                                    <span class="cell-img-box" v-else-if="scope.$index == 1"><img
                                            :src="require('@/assets/hw-s8.svg')"></span>
                                    <span class="cell-img-box" v-else-if="scope.$index == 2"><img
                                            :src="require('@/assets/hw-s10.svg')"></span>
                                    <span v-else>{{ scope.$index + 1 }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="signName" label="签单手" width="120" />
                            <el-table-column prop="marketName" label="市场" width="80">
                                <template #default="scope">
                                    {{ scope.row.marketName.replace('市场', '') }}
                                </template>
                            </el-table-column>
                            <el-table-column prop="orderReceivingNum" label="接单数" align="right" />
                            <el-table-column prop="signNum" label="签单数" align="right" />
                            <el-table-column prop="signRate" label="签单率" align="right">
                                <template #default="scope">
                                    {{ scope.row.signRate }}%
                                </template>
                            </el-table-column>
                            <div slot="empty" style="text-align: left;">
                                <div class="table-empty">
                                    <img :src="require('@/assets/m-empty.png')">
                                    <span>暂无数据</span>
                                </div>
                            </div>
                        </el-table>
                        <el-table v-show="listType == '2'" :data="designListData" header-row-class-name="table-head-class">
                            <el-table-column type="index" label="排名" align="center" width="65">
                                <template #default="scope">
                                    <span class="cell-img-box" v-if="scope.$index == 0"><img
                                            :src="require('@/assets/hw-s7.svg')"></span>
                                    <span class="cell-img-box" v-else-if="scope.$index == 1"><img
                                            :src="require('@/assets/hw-s8.svg')"></span>
                                    <span class="cell-img-box" v-else-if="scope.$index == 2"><img
                                            :src="require('@/assets/hw-s10.svg')"></span>
                                    <span v-else>{{ scope.$index + 1 }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="transferName" label="设计师" width="120" />
                            <el-table-column prop="marketName" label="市场" width="80">
                                <template #default="scope">
                                    {{ scope.row.marketName.replace('市场', '') }}
                                </template>
                            </el-table-column>
                            <el-table-column prop="sendAll" label="接单数" align="right" />
                            <el-table-column prop="transferSum" label="转单数" align="right" />
                            <el-table-column prop="transferRate" label="转单率" align="right">
                                <template #default="scope">
                                    {{ scope.row.transferRate }}%
                                </template>
                            </el-table-column>
                            <div slot="empty" style="text-align: left;">
                                <div class="table-empty">
                                    <img :src="require('@/assets/m-empty.png')">
                                    <span>暂无数据</span>
                                </div>
                            </div>
                        </el-table>
                        <el-table v-show="listType == '3'" :data="designProductListData"
                            header-row-class-name="table-head-class">
                            <el-table-column type="index" label="排名" align="center" width="65">
                                <template #default="scope">
                                    <span class="cell-img-box" v-if="scope.$index == 0"><img
                                            :src="require('@/assets/hw-s7.svg')"></span>
                                    <span class="cell-img-box" v-else-if="scope.$index == 1"><img
                                            :src="require('@/assets/hw-s8.svg')"></span>
                                    <span class="cell-img-box" v-else-if="scope.$index == 2"><img
                                            :src="require('@/assets/hw-s10.svg')"></span>
                                    <span v-else>{{ scope.$index + 1 }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="transferName" label="设计师" width="120" />
                            <el-table-column prop="marketName" label="市场" width="80">
                                <template #default="scope">
                                    {{ scope.row.marketName.replace('市场', '') }}
                                </template>
                            </el-table-column>
                            <el-table-column prop="realProFront" label="转单产值" width="130" align="right" />
                            <el-table-column prop="finishRate" label="任务产值完成占比" width="170" align="right">
                                <template #default="scope">
                                    {{ scope.row.finishRate }}%
                                </template>
                            </el-table-column>
                            <div slot="empty" style="text-align: left;">
                                <div class="table-empty">
                                    <img :src="require('@/assets/m-empty.png')">
                                    <span>暂无数据</span>
                                </div>
                            </div>
                        </el-table>
                    </div>
                </div>
            </div>
        </div>


        <van-popup v-model="popupShow" class="my-popup" overlay-class="my-popup-cover" position="bottom"
            :safe-area-inset-bottom="true" :style="{ height: '370px' }">
            <div class="my-popup-box">
                <div class="my-popup-head">
                    日期筛选
                    <van-icon name="cross" size="16" @click="popupShow = false" />
                </div>
                <div class="my-popup-content">
                    <van-picker show-toolbar title="" :columns="columns" toolbar-position="bottom" ref="myPicker"
                        style="height: 100%;">
                        <template #default>
                            <div class="my-popup-footer">
                                <van-button type="default" @click="restSearch">重置</van-button>
                                <van-button type="info" @click="sureSearch">确认</van-button>
                            </div>
                        </template>
                    </van-picker>
                </div>

            </div>
        </van-popup>
    </div>
</template>

<script>
import api from '@/api/api.js'
import moment from 'moment';
import * as echarts from 'echarts';

export default {
    data () {
        return {
            popupShow: false,
            columns: [{ values: [] }, { values: [], defaultIndex: moment().month() + 1 }],
            year: moment().year(),
            month: (moment().month() + 1) + '月',
            tabIndex: 1,
            cluesLiList: [
                //线索信息
                {
                    name: '线索总分配数',
                    color: '#E3F4FF',
                    iconBg: '#DCF0FF',
                    iconShadow: '0px 4px 15px 0px rgba(0,145,255,0.2)',
                    icon: 'yy-s1',
                    value: 0,
                    rate: 0
                },
                {
                    name: '有效到店数',
                    color: '#D2FBEB',
                    iconBg: '#CAF4EC',
                    iconShadow: '0px 9px 29px 0px rgba(44,204,174,0.42)',
                    icon: 'yy-s2',
                    value: 0,
                    rate: 0
                },
                {
                    name: '有效客户数',
                    color: '#FFE5E4',
                    iconBg: '#FFE4E2',
                    iconShadow: '0px 9px 29px 0px #FFACA6',
                    icon: 'yy-s3',
                    value: 0,
                    rate: 0
                },
                {
                    name: '签单数',
                    color: '#EBEAFF',
                    iconBg: '#E9E5FF',
                    iconShadow: '0px 9px 29px 0px rgba(204,106,251,0.38)',
                    icon: 'yy-s4',
                    value: 0,
                    rate: 0
                },
                {
                    name: '转单数',
                    color: '#C9EDF2',
                    iconBg: '#D3F1FF',
                    iconShadow: '0px 5px 16px 0px rgba(128,205,232,0.4)',
                    icon: 'yy-s5',
                    value: 0,
                    rate: 0
                },
                {
                    name: '补齐数',
                    color: '#FFEBBD',
                    iconBg: '#FFE7BF',
                    iconShadow: '0px 5px 16px 0px rgba(252,149,54,0.4)',
                    icon: 'yy-s6',
                    value: 0,
                    rate: 0
                }
            ],
            productionYearValueData: {},//年度产值、市场产值数据、门店年度排名
            productDataValue: {},//产值数据以及产值图表数据
            productionValueType: '2',
            signValueType: '2',
            listType: '1',
            signListData: [],//签单手列表数据
            designListData: [],//设计师资源列表数据
            designProductListData: [],//设计师产值列表数据
            clueRateData: {
                threadNum: 0,//线索数
                validThreadNum: 0,//有效数
                validThreadNumRate: 0//有效率
            },
        }
    },
    methods: {
        //打开日期选择
        openChooseDate () {
            this.$refs.myPicker && this.$refs.myPicker.setValues([this.year, this.month])
            this.popupShow = true
        },
        //获取参数
        getParams () {
            let m = Number(this.month.replace('月', ''))
            //日期
            let date = m ? this.year + '-' + (m < 10 ? '0' + m : m) : this.year + ''
            return { date }
        },
        //获取年度产值、市场产值数据、年度市场完成率数据
        async getProductionYearValue () {
            let { date } = this.getParams()
            let res = await api.productionYearValue(date)
            this.productionYearValueData = res.data
        },
        //获取产值数据以及产值图表数据
        async getProductData () {
            let { date } = this.getParams()
            let res = await api.productData(date)
            this.productDataValue = res.data
            //绘制市场分析产值图表
            this.getProcuctionData()
            this.getSignData()
        },
        //获取线索数据
        async getClueData () {
            let { date } = this.getParams()
            let res = await api.productClueData(date)
            //线索数据填充
            this.cluesLiList[0].value = res.data?.topDataDTO.clueAllocation
            this.cluesLiList[0].rate = res.data?.topDataDTO.clueAllocationRate
            this.cluesLiList[1].value = res.data?.topDataDTO.shopNumber
            this.cluesLiList[1].rate = res.data?.topDataDTO.shopNumberRate
            this.cluesLiList[2].value = res.data?.topDataDTO.validClientNumber
            this.cluesLiList[2].rate = res.data?.topDataDTO.validClientNumberRate
            this.cluesLiList[3].value = res.data?.topDataDTO.signNumber
            this.cluesLiList[3].rate = res.data?.topDataDTO.signNumberRate
            this.cluesLiList[4].value = res.data?.topDataDTO.transferSum
            this.cluesLiList[4].rate = res.data?.topDataDTO.transferSumRate
            this.cluesLiList[5].value = res.data?.topDataDTO.polishingNum
            this.cluesLiList[5].rate = res.data?.topDataDTO.polishingNumRate

            this.clueRateData.threadNum = res.data?.saleFunnelDTO?.threadNum
            this.clueRateData.validThreadNum = res.data?.saleFunnelDTO?.validThreadNum
            this.clueRateData.validThreadNumRate = res.data?.saleFunnelDTO?.validThreadNumRate.toFixed(2)

            //绘制集团有效线索占比图表
            this.getClueRate()
        },
        //市场产值对比图趋势图切换
        productionValueTypeChange (e) {
            this.productionValueType = e
            this.getProcuctionData()
        },
        //市场产值分析图表数据
        getProcuctionData () {
            if (this.productionValueType == '1') {
                let clueTrendLineData = {
                    xAxis: this.productDataValue.productionTrendData?.xcoordinateList,
                    "转单产值": this.productDataValue.productionTrendData?.maintainAmountList,
                    "补齐产值": this.productDataValue.productionTrendData?.finaleAmountList
                }
                this.echarteInit('.productionValueEcharts', {
                    tooltip: {
                        trigger: 'axis',
                        backgroundColor: '#3DA2FF',
                        borderColor: '#3DA2FF',
                        textStyle: {
                            color: '#FFFFFF'
                        },
                        padding: 5,
                        position: 'top',
                        formatter: (params) => {
                            // formatter: '{name|{b}}\n{time|{c} 小时}',
                            return `
                            <div class="myecharts-toop-box">  
                                <div class="myecharts-toop"><span class="m-span">${params[0].marker} ${params[0].seriesName}：</span> <span class="m-span">${params[0].data} 万元</span></div>    
                                <div class="myecharts-toop"><span class="m-span">${params[1].marker} ${params[1].seriesName}：</span> <span class="m-span">${params[1].data} 万元</span></div>
                            </div>   
                        `
                        },
                    },
                    legend: {
                        selectedMode: false,
                        icon: 'roundRect',
                        right: '4%',
                        data: ['转单产值', '补齐产值'],
                        top: 22,
                        right: 15,
                        itemWidth: 8,
                        itemHeight: 8,
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true,
                    },
                    xAxis: [
                        {
                            type: 'category',
                            boundaryGap: false,
                            data: clueTrendLineData.xAxis,
                            axisLabel: {
                                interval: '0'
                            }
                        }
                    ],
                    yAxis: {
                        type: 'value',
                        name: '(万元)',
                    },
                    series: [
                        {
                            name: '转单产值',
                            type: 'line',
                            data: clueTrendLineData['转单产值'],
                            color: '#4AC0EA',
                            lineStyle: {
                                shadowColor: 'rgba(74,192,234,0.1)',
                                // opacity:0.1,
                                shadowBlur: 1,
                                shadowOffsetY: 10,
                                // shadowOffsetX:5
                            },
                        },
                        {
                            name: '补齐产值',
                            type: 'line',
                            data: clueTrendLineData['补齐产值'],
                            color: '#FEA741',
                            lineStyle: {
                                shadowColor: 'rgba(254,167,65,0.1)',
                                shadowBlur: 1,
                                shadowOffsetY: 10,
                                // shadowOffsetX:5
                            },
                        }
                    ]
                })
            } else {
                let _this = this
                function max () {
                    let maxNum = Math.max(..._this.productDataValue.productionCompareData?.maintainAmountList, ..._this.productDataValue.productionCompareData?.finaleAmountList)
                    let num = Math.ceil(maxNum / 4);
                    let len = (num + '').length - 1;
                    let num2 = Math.pow(10, len);
                    let num3 = Math.round(num / num2) * num2;
                    return num3 * 5;
                }
                this.echarteInit('.productionValueEcharts', {
                    tooltip: {
                        padding: 5,
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        },
                        backgroundColor: '#3DA2FF',
                        borderColor: '#3DA2FF',
                        textStyle: {
                            color: '#FFFFFF'
                        },
                        formatter: (params) => {
                            return `
                    <div class="myecharts-toop-box">  
                                <div class="myecharts-toop"><span class="m-span">${params[0].marker} ${params[0].seriesName}：</span> <span class="m-span">${params[0].data} 万元</span></div>    
                                <div class="myecharts-toop"><span class="m-span">${params[1].marker} ${params[1].seriesName}：</span> <span class="m-span">${params[1].data} 万元</span></div>
                            </div>  
                `
                        },
                    },
                    legend: {
                        selectedMode: false,
                        right: '4%',
                        data: ['转单产值', '补齐产值'],
                        top: 22,
                        right: 15,
                        itemWidth: 8,
                        itemHeight: 8,

                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: this.productDataValue.productionCompareData?.xcoordinateList?.map(e => e.replace('市场', '')),
                            axisTick: {
                                show: true,
                                alignWithLabel: true
                            },
                            axisLabel: {
                                interval: '0'
                            }
                        },
                    ],
                    yAxis: [
                        {
                            name: '(万元)',
                            type: 'value',
                        }
                    ],
                    series: [
                        {
                            name: '转单产值',
                            type: 'bar',
                            data: this.productDataValue.productionCompareData?.maintainAmountList,
                            itemStyle: {
                                // 柱状图颜色
                                color: '#4AC0EA',
                                borderRadius: 2,
                            },
                            barWidth: 10,
                        },
                        {
                            name: '补齐产值',
                            type: 'bar',
                            data: this.productDataValue.productionCompareData?.finaleAmountList,
                            itemStyle: {
                                // 柱状图颜色
                                color: '#FFA73F',
                                borderRadius: 2
                            },
                            barWidth: 10,
                        },
                    ]
                })

            }
        },
        //市场签单对比图趋势图切换
        psignValueTypeChange (e) {
            this.signValueType = e
            this.getSignData()
        },
        //市场签单分析
        getSignData () {
            if (this.signValueType == '1') {
                let clueTrendLineData = {
                    xAxis: this.productDataValue.signTrendData?.xcoordinateList,
                    "有效到店数": this.productDataValue.signTrendData?.validShopNumList,
                    "签单数": this.productDataValue.signTrendData?.signNumList
                }
                this.echarteInit('.signValueEcharts', {
                    tooltip: {
                        trigger: 'axis',
                        backgroundColor: '#3DA2FF',
                        borderColor: '#3DA2FF',
                        textStyle: {
                            color: '#FFFFFF'
                        },
                        padding: 5,
                        position: 'top',
                        formatter: (params) => {
                            // formatter: '{name|{b}}\n{time|{c} 小时}',
                            return `
                            <div class="myecharts-toop-box">  
                                <div class="myecharts-toop"><span class="m-span">${params[0].marker} ${params[0].seriesName}：</span> <span class="m-span">${params[0].data}个</span></div>    
                                <div class="myecharts-toop"><span class="m-span">${params[1].marker} ${params[1].seriesName}：</span> <span class="m-span">${params[1].data}个</span></div>
                            </div>   
                        `
                        },
                    },
                    legend: {
                        selectedMode: false,
                        icon: 'roundRect',
                        right: '4%',
                        data: ['有效到店数', '签单数'],
                        top: 22,
                        right: 15,
                        itemWidth: 8,
                        itemHeight: 8,
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true,
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: clueTrendLineData.xAxis,
                        axisLabel: {
                            interval: '0'
                        }
                    },
                    yAxis: {
                        type: 'value',
                        name: '(个)'
                    },
                    series: [
                        {
                            name: '有效到店数',
                            type: 'line',
                            data: clueTrendLineData['有效到店数'],
                            color: '#2CCCAE',
                            lineStyle: {
                                shadowColor: 'rgba(44,204,174,0.1)',
                                // opacity:0.1,
                                shadowBlur: 1,
                                shadowOffsetY: 10,
                                // shadowOffsetX:5
                            },
                        },
                        {
                            name: '签单数',
                            type: 'line',
                            data: clueTrendLineData['签单数'],
                            color: '#CC6AFB',
                            lineStyle: {
                                shadowColor: 'rgba(204,106,251,0.1)',
                                shadowBlur: 1,
                                shadowOffsetY: 10,
                                // shadowOffsetX:5
                            },
                        }
                    ]
                })
            } else {
                let _this = this
                function max () {
                    let maxNum = Math.max(..._this.productDataValue.signCompareData?.validShopNumList, ..._this.productDataValue.signCompareData?.signNumList)
                    let num = Math.ceil(maxNum / 4);
                    let len = (num + '').length - 1;
                    let num2 = Math.pow(10, len);
                    let num3 = Math.round(num / num2) * num2;
                    return num3 * 5;
                }
                this.echarteInit('.signValueEcharts', {
                    tooltip: {
                        padding: 5,
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        },
                        backgroundColor: '#3DA2FF',
                        borderColor: '#3DA2FF',
                        textStyle: {
                            color: '#FFFFFF'
                        },
                        formatter: (params) => {
                            return `
                    <div class="myecharts-toop-box">  
                                <div class="myecharts-toop"><span class="m-span">${params[0].marker} ${params[0].seriesName}：</span> <span class="m-span">${params[0].data} 个</span></div>    
                                <div class="myecharts-toop"><span class="m-span">${params[1].marker} ${params[1].seriesName}：</span> <span class="m-span">${params[1].data} 个</span></div>
                            </div>  
                `
                        },
                    },
                    legend: {
                        selectedMode: false,
                        right: '4%',
                        data: ['有效到店数', '签单数'],
                        top: 22,
                        right: 15,
                        itemWidth: 8,
                        itemHeight: 8,

                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: this.productDataValue.signCompareData?.xcoordinateList?.map(e => e.replace('市场', '')),
                            axisTick: {
                                show: true,
                                alignWithLabel: true
                            },
                            axisLabel: {
                                interval: '0'
                            }
                        },
                    ],
                    yAxis: [
                        {
                            name: '(个)',
                            type: 'value',
                        }
                    ],
                    series: [
                        {
                            name: '有效到店数',
                            type: 'bar',
                            data: this.productDataValue.signCompareData?.validShopNumList,
                            itemStyle: {
                                // 柱状图颜色
                                color: '#4AC0EA',
                                borderRadius: 2,
                            },
                            barWidth: 10,
                        },
                        {
                            name: '签单数',
                            type: 'bar',
                            data: this.productDataValue.signCompareData?.signNumList,
                            itemStyle: {
                                // 柱状图颜色
                                color: '#FFA73F',
                                borderRadius: 2
                            },
                            barWidth: 10,
                        },
                    ]
                })

            }
        },
        //集团有效线索占比
        getClueRate () {
            this.echarteInit('.clueEcharts', {
                series: [
                    {
                        name: 'pie1',
                        type: 'pie',
                        width: '150%',
                        left: -50,
                        animation: false, //去掉动画效果
                        silent: true, //不响应和触发鼠标事件，默认为 false，即响应和触发鼠标事
                        selectedMode: 'single',
                        radius: [0, '40%'],
                        label: {
                            position: 'center',
                            fontSize: 14,
                            formatter: () => {
                                return `{text|${this.clueRateData.validThreadNumRate + '%'}}\n{num|有效率}`
                            },
                            rich: {
                                text: {
                                    fontSize: 18,
                                    padding: [0, 0, 0, 0],
                                    color: '#3DA2FF',
                                },
                                num: {
                                    fontSize: 14,
                                    color: '#8C8C8C',
                                    fontWeight: 500,
                                    padding: [10, 0, 0, 0]
                                }
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: [
                            { value: 100, name: 'Search Engine', itemStyle: { color: '#FFFFFF' } }
                        ]
                    },
                    {
                        name: 'pie2',
                        type: 'pie',
                        width: '150%',
                        left: -50,
                        animation: false, //去掉动画效果
                        silent: true, //不响应和触发鼠标事件，默认为 false，即响应和触发鼠标事
                        selectedMode: 'single',
                        radius: ['40%', '45%'],
                        label: {
                            position: 'inner',
                            fontSize: 14
                        },
                        labelLine: {
                            show: false
                        },
                        data: [{ value: 100, itemStyle: { color: '#EEF2F4' } }]
                    },
                    {
                        name: 'pie3',
                        type: 'pie',
                        width: '150%',
                        left: -50,
                        animation: false, //去掉动画效果
                        silent: true, //不响应和触发鼠标事件，默认为 false，即响应和触发鼠标事
                        selectedMode: 'single',
                        radius: ['48%', '53%'],
                        label: {
                            position: 'inner',
                            fontSize: 14
                        },
                        labelLine: {
                            show: false
                        },
                        data: [{ value: 100, itemStyle: { color: 'rgba(61, 162, 255, 0.5)' } }]
                    },
                    {
                        name: 'pie4',
                        type: 'pie',
                        width: '150%',
                        left: -50,
                        radius: ['45%', '55%'],
                        silent: true, //不响应和触发鼠标事件，默认为 false，即响应和触发鼠标事
                        label: {
                            alignTo: 'edge',
                            minMargin: 30,
                            edgeDistance: 10,
                            lineHeight: 15,
                            show: false
                        },
                        labelLine: {
                            length: 15,
                            length2: 0,
                            maxSurfaceAngle: 80
                        },
                        data: [
                            {
                                value: this.clueRateData.validThreadNumRate || 0,
                                name: '线索量',
                                itemRate: '0%',
                                itemStyle: {
                                    color: '#3DA2FF',
                                    borderRadius: '50%',
                                    shadowColor: '#ABD6FF',
                                    shadowBlur: 10
                                }
                            },
                            {
                                value: 100 - (this.clueRateData.validThreadNumRate || 0),
                                name: '有效量',
                                itemRate: '0%',
                                itemStyle: {
                                    color: 'rgba(61, 162, 255, 0)'
                                }
                            },
                        ]
                    }
                ]
            })
        },
        //排行榜数据切换
        changeListType (e) {
            this.listType = e
        },
        //获取签单列表数据
        async getSignList () {
            let { date } = this.getParams()
            let res = await api.signList(date)
            this.signListData = res.data
        },
        //获取设计师列表数据
        async getDesignList () {
            let { date } = this.getParams()
            let res = await api.designList(date)
            this.designListData = res.data?.transferOrderList
            this.designProductListData = res.data?.transferValueList
        },
        //查看详情
        checkDetails () {
            if (this.listType == '1') this.$router.push({ name: 'TdirectorSignDetailsName', query: { year: this.year, month: this.month } })
            if (this.listType == '2') this.$router.push({ name: 'TdirectorDesignDetailsName', query: { year: this.year, month: this.month } })
            if (this.listType == '3') this.$router.push({ name: 'TdirectorDesignProductDetailsName', query: { year: this.year, month: this.month } })
        },
        //加载echarts
        echarteInit (calssName, option) {
            let chartElement = document.querySelector(calssName);
            // 有的话就获取已有echarts实例的DOM节点
            let echart = echarts.getInstanceByDom(chartElement)
            // 如果不存在，就进行初始化
            if (echart == null) {
                echart = echarts.init(chartElement);
            }
            echart.setOption(option, true);
            if (calssName == '.channelClueEcharts') {
                var o = echart.getOption();
                this.lendColors = o.color
            }
        },
        restSearch () {
            this.year = moment().year()
            this.month = '全部'
            //重置
            this.popupShow = false
            this.heavyLoad()
        },
        sureSearch () {
            this.year = this.$refs.myPicker.getValues()[0]
            this.month = this.$refs.myPicker.getValues()[1]
            //确认搜索
            this.popupShow = false
            this.heavyLoad()
        },
        //数据重载
        heavyLoad () {
            this.getProductionYearValue()
            this.getClueData()
            this.getProductData()
            this.getSignList()
            this.getDesignList()
        },
    },
    created () {
        let y = []
        let nowYear = moment().year()
        for (let i = nowYear; i > nowYear - 21; i--) {
            y.push(i)
        }
        let m = ['全部']
        for (let i = 1; i < 13; i++) {
            m.push(i + '月')
        }
        this.columns[0].values = y
        this.columns[1].values = m
    },
    mounted () {
        let _this = this
        this.$nextTick(() => {
            _this.heavyLoad()
        })
    }
}
</script>

<style lang="scss" scoped>
p {
    margin: 0;
}

.directorOperations {
    background: #F5F5F5;

    .headNav-righ-text {
        font-size: 16px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
    }

    .page-content {
        height: calc(100vh - 50px);
        overflow: auto;

        .product-moudule-box {
            background: #3975C6;
            // padding-top: 19px;
            padding-bottom: 10px;
        }

        .product-moudule {
            height: 178px;
            margin: 0 12px 0 12px;
            position: relative;

            img {
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
            }

            .tab-box {
                display: flex;
                position: relative;
                z-index: 1;

                &>img {
                    left: -1px;
                }

                &>div {
                    z-index: 1;
                    flex: 1;
                    height: 52px;
                    position: relative;
                    font-size: 18px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: bold;
                    color: #FFFFFF;
                    line-height: 52px;
                    text-align: center;

                    span {
                        position: relative;
                        z-index: 1;
                    }

                    &:nth-child(2) {
                        img {
                            left: -1px;
                        }
                    }
                }

            }

            .tab-content {
                position: relative;
                z-index: 1;
                height: 125px;
                display: flex;
                flex-direction: column;
                justify-content: center;

                &>p {
                    margin: 0;
                    text-align: left;
                    padding-left: 31px;
                    color: #FFFFFF;

                    &:first-child {
                        font-size: 28px;
                        font-family: Bahnschrift-SemiLight, Bahnschrift;
                        font-weight: normal;
                        color: #FFFFFF;
                        margin-bottom: 15px;

                        span {
                            font-size: 15px;
                        }
                    }

                    &:nth-child(2) {
                        font-size: 21px;
                        font-family: Bahnschrift-Light, Bahnschrift;
                        font-weight: 300;
                    }

                    .icon-img {
                        width: 20px;
                        height: 20px;
                        position: unset;
                    }
                }

                .right-img {
                    position: absolute;
                    width: 117px;
                    height: 75px;
                    left: unset;
                    right: 21px;
                    top: 26px;
                }
            }
        }

        .clue-moudule {
            margin-bottom: 16px;
            background-color: #FFFFFF;
            padding: 12px 0;
            border-radius: 0 0 8px 8px;

            ul {
                display: flex;
                flex-wrap: wrap;
                gap: 12px;
                justify-content: center;
                width: 100%;

                li {
                    width: 45%;
                    height: 100px;
                    background: #FF6259;
                    border-radius: 4px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    position: relative;
                    text-align: left;

                    &>p {
                        margin: 0;
                        padding-left: 14px;

                        &:nth-child(1) {
                            font-size: 16px;
                            font-family: PingFang SC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #333333;
                        }

                        &:nth-child(2) {
                            font-size: 24px;
                            font-family: Bahnschrift-SemiLight, Bahnschrift;
                            font-weight: normal;
                            color: #333333;
                            margin-top: 6px;
                            margin-bottom: 1px;

                            span {
                                font-size: 13px;
                            }
                        }

                        &:nth-child(3) {
                            font-size: 16px;
                            font-family: Bahnschrift-SemiLight, Bahnschrift;
                            font-weight: normal;
                        }
                    }

                    &>div {
                        position: absolute;
                        width: 36px;
                        height: 36px;
                        border-radius: 11px 11px 11px 11px;
                        right: 8px;
                        bottom: 9px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }

        .product-market,
        .sign-market,
        .clue-rate,
        .list-moudule {
            margin-bottom: 16px;
            background-color: #FFFFFF;
            border-radius: 8px;

            h3 {
                height: 54px;
                font-size: 19px;
                font-family: PingFang SC-Bold, PingFang SC;
                font-weight: bold;
                color: #333333;
                padding: 0 12px;
                display: flex;
                align-items: center;
                border-bottom: 1px solid #F5F5F5;
                margin: 0;

                &>div {
                    flex: 1;
                    display: flex;
                    align-items: center;

                    &:nth-child(2) {
                        justify-content: flex-end;
                    }
                }

                ul {
                    justify-content: flex-end;
                    border-radius: 4px;
                    border: 1px solid #D9D9D9;
                    display: flex;

                    li {
                        width: 66px;
                        height: 26px;
                        font-size: 14px;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                        line-height: 26px;

                        &:nth-child(1) {
                            border-right: 1px solid #D9D9D9;
                        }
                    }

                    .avtive-li {
                        color: #0E8BFF;
                    }
                }

                img {
                    width: 23px;
                    height: 23px;
                    margin-right: 6px;
                }
            }

            .product-market-content,
            .sign-market-content {

                .productionValueEcharts,
                .signValueEcharts {
                    width: 100%;
                    height: 300px;
                }
            }
        }
    }

    .sign-market h3 img {
        margin-top: 2px;
    }

    .clue-rate {
        .clue-rate-content {
            display: flex;
            height: 250px;

            .clueEcharts {
                width: 100%;
                height: 250px;
            }

            &>div {
                flex: 1;
                width: 0;

                &:nth-child(2) {
                    flex: 1.1
                }
            }

            .clue-num {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 16px;

                div {
                    background: rgba(245, 245, 245, 0.4);
                    border-radius: 8px;
                    padding: 10px 34px;

                    p {
                        &::after {
                            content: '';
                            position: absolute;
                            left: -12px;
                            width: 8px;
                            height: 8px;
                            background: rgba(61, 162, 255, 0.5);
                            border-radius: 1px;
                        }
                    }

                    &:nth-child(2) p:nth-child(1)::after {
                        background: rgba(61, 162, 255, 1) !important;
                    }

                    p:nth-child(1) {
                        font-size: 14px;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                        position: relative;
                        display: flex;
                        align-items: center;
                        margin-bottom: 4px;
                    }

                    p:nth-child(2) {
                        font-size: 22px;
                        font-family: Bahnschrift-SemiLight, Bahnschrift;
                        font-weight: normal;
                        color: #333333;

                        span {
                            font-size: 14px;
                            font-family: Bahnschrift-SemiLight, Bahnschrift;
                            font-weight: normal;
                            color: #333333;
                        }
                    }
                }
            }
        }
    }

    .list-moudule {
        .h3-right {
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #4F4F4F;

            &>span {
                display: flex;
                align-items: center;

                &:active {
                    opacity: 0.8;
                }
            }
        }

        .list-moudule-content {
            .tab-box {
                background-color: #FFFFFF;
                padding: 16px 12px;
            }

            ul {
                display: flex;
                height: 40px;
                background: #F8F8F8;
                border-radius: 4px;
                align-items: center;
                padding: 2px;
                gap: 1px;

                li {
                    flex: 1;
                    height: 36px;
                    border: 1px solid #F8F8F8;
                    font-size: 14px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #545454;
                    left: 36px;
                    line-height: 36px;

                    &.active-li:nth-child(1) {
                        border-radius: 4px 0 0 4px;
                    }

                    &.active-li:nth-child(2) {
                        border-radius: 4px;
                    }

                    &.active-li:nth-child(3) {
                        border-radius: 0 4px 4px 0;
                    }
                }

                .active-li {
                    border-color: #3DA2FF;
                    color: #3DA2FF;
                    background: #FFFFFF;
                }
            }

            .list-moudule-table {
                min-height: 260px;
                padding-bottom: 10px;
            }
        }
    }

    .my-popup {
        background: #FFFFFF;
        border-radius: 16px 16px 0px 0px;

        .my-popup-box {
            .my-popup-head {
                line-height: 48px;
                border-bottom: 1px solid #DFDFDF;
                text-align: center;
                font-weight: bold;
                color: #424242;
                position: relative;
                font-size: 16px;

                i {
                    position: absolute;
                    right: 19px;
                    top: calc(50% - 8px);
                }
            }

            .my-popup-content {
                height: calc(100% - 10px);
                overflow: hidden;
            }

            .my-popup-footer {
                height: 75px;
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: center;
                gap: 12px;

                button {
                    width: 164px;
                    height: 44px;
                    border-radius: 8px;
                }
            }
        }
    }
}

.my-popup-cover {
    background: rgba(0, 0, 0, 0.5);

}

.table-empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
        width: 160px;
        margin-bottom: 10px
    }

    span {
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #5E5E5E;
    }
}

::v-deep {
    .van-picker-column__item div {
        transition: all 0.5s;
    }

    .van-picker-column__item--selected div {
        font-size: 19px;
    }

    .table-head-class th {
        background: #F5F5F5;
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #1D2129;
    }

    .el-table__body-wrapper {
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #1D2129;

        tr {
            .el-table__cell {

                &:nth-child(4),
                &:nth-child(5),
                &:nth-child(6),
                &:nth-child(7) {
                    font-size: 16px;
                    font-family: Bahnschrift-Light, Bahnschrift;
                    font-weight: 300;
                    color: #1D2129;
                }

                .cell-img-box {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                img {
                    width: 20px;
                }
            }

        }
    }

    .myecharts-toop {
        line-height: 30px;
        font-size: 17px;
        font-family: Bahnschrift-SemiLight, Bahnschrift;
        font-weight: normal;
        color: #FFFFFF;
        // align-items: center;
        padding: 0 5px;
        position: relative;

        &:first-child::after {
            content: '';
            position: absolute;
            height: 1px;
            width: 100%;
            bottom: 0;
            left: 0;
            background: #2B88F9;
        }

        span {
            display: inline-block;
            width: 50%;
        }

        .m-span {
            width: 50%;
            font-size: 14px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            text-align: left;

            span {
                border: 1px solid #FFFFFF;
            }

            &:last-child {
                text-align: right;
                padding-right: 10px;
            }
        }
    }


}
</style>