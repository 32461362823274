<template>
	<div class="solveDanger-style">
<!-- 		<div class="search-style">
			<van-field class="choosetime-input" v-model="searchValue" @blur="onRefresh()" clearable left-icon="smile-o"
				placeholder="搜索关键字">
				<template v-slot:left-icon>
					<div style="height: 100%;display: flex;align-items: center;">
						<img src="@/assets/search.png" class="search-icon" alt="">
					</div>
				</template>
			</van-field>
		</div> -->
		<div class="audit-content">
			<pullRefresh  ref="pullRefresh" @refresh="onRefresh">
				<van-list class="vant-list-style" v-model="loading" :finished="finished"
					:finished-text="total?'·已加载全部数据·':'·暂无数据·'" @load="onLoad">
					<div class="list-item" v-for="item in list">
						<div class="amedt-title">
							<van-tag class="label-style-dark-color" color="#40D673  " type="primary">已解决</van-tag>
							{{item.itemName}}
						</div>
						<div class="item-style">
							<div class="amedt-item-title">巡检人：</div>
							<div class="amedt-item-text">{{item.createId}}</div>
						</div>
						<div class="item-style">
							<div class="amedt-item-title">是否合格：</div>
							<div class="amedt-item-text">{{item.qualifiedStatus=='1' || item.qualifiedStatus=='2'?'是':'否'}}</div>
						</div>
						<div class="item-style">
							<div class="amedt-item-title">巡检阶段：</div>
							<div class="amedt-item-text">{{item.contentName}}</div>
						</div>
						<div class="item-style">
							<div class="amedt-item-title">提交时间：</div>
							<div class="amedt-item-text">{{item.createDate}}</div>
						</div>
						<div class="item-style item-style-img">
							<div class="amedt-item-title">现场照片：</div>
							<div class="amedt-item-text amedt-item-img">
								<div style="display: flex;">
									<img @click="photoPreview(item.imgs,itemIngIndex)"
										v-for="(itemIng,itemIngIndex) in item.imgs" :src="itemIng.webUrl" alt="">
								</div>
							</div>
							<div class="boxshow-style"></div>
						</div>
						<div class="item-style item-style-img" style="border: none;">
							<div class="amedt-item-title">备注信息：</div>
							<div class="amedt-item-text amedt-item-remark">{{item.remark}}</div>
						</div>
						<div class="item-bottom-style">
							<span class="zgjl-style" style="border: none;" @click="toAmendRecord(item)">
								<img src="@/assets/zgjl.png" alt="" class="shty-img">
								整改记录
							</span>
						</div>
					</div>
				</van-list>
			</pullRefresh>
		</div>
	</div>
</template>

<script>
	import {
		ImagePreview
	} from 'vant';
	import api from '@/api/api.js'
	export default {
		data() {
			return {
				searchValue: '',
				info: {},
				pageNum: 1,
				pageSize: 10,
				total: 0,
				list: [],
				isLoading: false,
				loading: false,
				finished: false,
				error: false, // 是否加载失败
			}
		},
		methods: {
			// 被 @load调用的方法
			onLoad() { // 若加载条到了底部
				let timer = setTimeout(() => { // 定时器仅针对本地数据渲染动画效果,项目中axios请求不需要定时器
					this.getResolvedList()
					this.pageNum++; // 分页数加一
					this.finished && clearTimeout(timer); //清除计时器
				}, 100);
			},
			// 加载失败调用方法
			onRefresh() {
				this.finished = false; // 清空列表数据
				this.loading = true; // 将 loading 设置为 true，表示处于加载状态
				this.pageNum = 1; // 分页数赋值为1
				this.list = []; // 清空数组
				this.onLoad(); // 重新加载数据
			},
			//符合标准列表
			async getResolvedList(e) {
				let obj = {
					flag: '3',//解决隐患
					time:'',
					projectId: this.info.id,
					nodeCode:''
					
				}
				let res = await api.getProjectStatesList(obj)
				if (res.code == 200) {
					this.total = res.count
					if (res.data.length === 0) { // 判断获取数据条数若等于0
						this.list = []; // 清空数组
						this.finished = true; // 停止加载
					} else {
						// 若数据条数不等于0
						this.list.push(...res.data) // 将数据放入list中
						this.loading = false; // 加载状态结束 
						// 如果list长度大于等于总数据条数,数据全部加载完成
						if (this.list.length >= res.count) {
							this.finished = true; // 结束加载状态
						}
					}
					if(this.$refs.pullRefresh){
						if(this.$refs.pullRefresh){
							this.$refs.pullRefresh.refreshSuccess()
						}
					}
				}
			},
			//图片预览
			photoPreview(list, index) {
				let imgList = list.map((e)=>e.webUrl)
				let o = {
					images: imgList,
					startPosition: index
				}
				ImagePreview(o);
			},
			//前往整改记录
			toAmendRecord(item) {
				sessionStorage.setItem('abarbeitungInfo',JSON.stringify(item))
				this.$router.push({
					name: 'engineeringPatrolAmendRecordName',
					query: {
						fromRouterName: 'engineeringSolveDangerName'
					}
				})
			}
		},
		mounted() {
			this.info = JSON.parse(sessionStorage.getItem('clientInfo'))
		},
	}
</script>

<style scoped lang="scss">
	.solveDanger-style {
		.solveDanger-title {
			font-size: 13px;
			// font-family: Source Han Sans SC;
			font-weight: 500;
			color: #333333;
			display: inline-block;
			width: 99%;
		}

		.solveDanger-title2 {
			border-right: 1px solid #E6E6E6;
		}

		.audit-content,
		.abarbeitung-content {
			height: calc(100vh - 150px);
			overflow: auto;
			padding: 0 10px;
		}

		.audit-content,
		.abarbeitung-content {
			.list-item {
				text-align: left;
				background: #FFFFFF;
				border-radius: 6px;
				margin-top: 10px;

				.amedt-title {
					display: flex;
					align-items: center;
					height: 58px;
					border-bottom: 1px solid #E6E6E6;
					font-size: 16px;
					font-weight: 500;
					color: #333333;

					.label-style-dark-color {
						margin: 0 12px 0 10px;
					}
				}

				.zgh-img {
					width: 112px;
					height: 12px;
					margin: 19px 0 6px 10px;
				}

				.item-style {
					padding: 13px 0;
					margin: 0 10px;
					border-bottom: 1px solid #E6E6E6;
					display: flex;
					// min-height: 38px;
					align-items: center;

					.amedt-item-title {
						font-size: 14px;
						font-weight: 400;
						color: #777777;
						width: 80px;
					}

					.amedt-item-text {
						font-size: 14px;
						font-weight: 400;
						color: #333333;
					}

					.amedt-item-remark {
						width: calc(100% - 80px);
					}

					.amedt-item-img {
						display: flex;
						/* 设置超出滚动 */
						overflow-x: auto;
						width: calc(100% - 80px);

						// position: relative;
						img {
							width: 70px;
							height: 70px;
							margin-right: 4px;
						}

					}

					.amedt-item-img::-webkit-scrollbar {
						display: none;
					}
				}

				.item-style-img {
					align-items: flex-start;
					position: relative;

					.boxshow-style {
						position: absolute;
						width: 24px;
						height: 70px;
						background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
						right: 0;
					}

				}

				.amend-after-style {
					border-top: 1px solid #E6E6E6;
				}

				.item-bottom-style {
					display: flex;
					font-size: 13px;
					font-family: Source Han Sans SC;
					font-weight: 400;
					height: 40px;
					align-items: center;
					border-top: 1px solid #E6E6E6;

					span {
						display: flex;
						align-items: center;
						width: 100%;
						justify-content: center;
						height: 22px;
						border-right: 1px solid #E6E6E6;
					}

					img {
						width: 22px;
						height: 22px;
					}

					.ty-style {
						color: #40D673;
					}

					.jj-style {
						color: #FC426B;
						border: none;
					}

					.zgjl-style {
						color: #4F7AFD;
					}
				}
			}

		}
	}
</style>
