<template>
    <div class="finishUpProject-style">
        <headerNav title="上传" leftNav="取消" @leftClick="cancel" rightNav="提交" @rightClick="submit"></headerNav>
        <div class="content-style">
            <div class="input-box upPhoto-style">
                <div class="input-text">现场照片
                    <span class="xing-style">*</span>
                </div>
                <div class="input-style">
                    <van-uploader :after-read="afterRead" multiple>
                        <van-button class="button-style" type="primary"></van-button>
                    </van-uploader>
                    <img src="@/assets/upPhoto.png" class="choose-icon" alt="">
                </div>
            </div>
            <div class="photo-box" v-show="fileList.length">
                <div class="photo-style" v-for="(item, index) in fileList" style="position: relative;">
                    <div class="del-icon" @click="delPhoto(index)">×</div>
                    <img style="width: 100%;height: 100%;" :src="item.url" alt="" @click="photoPreview(index)">
                    <div class="img-overlay" v-if="item.status === 'uploading'">
                        <van-loading size="20" color="#f5f5f5" />
                    </div>
                    <div class="img-overlay" v-if="item.status === 'failed'">
                        <van-icon name="fail" size="20" color="#f5f5f5" />
                    </div>
                </div>
            </div>
            <div class="input-box" v-if="typeCode == 'ITEM982859' && !backPage">
                <div class="input-text">开工日期
                    <span class="xing-style">*</span>
                </div>
                <div class="input-style">
                    <van-field :rules="[{ required: true }]" readonly @click="chooseTime" v-model="startDate"
                        class="field-style" name="开工时间" clickable right-icon="warning-o" placeholder="请选择">
                        <template v-slot:right-icon>
                            <div class="field-right-icon">
                                <img src="@/assets/xzjt.png" class="choose-icon" alt="">
                            </div>
                        </template>
                    </van-field>
                    <PickerTime v-bind:timeShow.sync="timeShow" @confirm="timeSubmit" />
                </div>
            </div>
            <div class="input-textarea-box">
                <div class="input-text">备注信息 </div>
                <div class="input-style textarea-style">
                    <van-field class="input-style" v-model="remark" rows="3" autosize type="textarea" name="备注信息"
                        placeholder="请输入" />
                </div>
            </div>
            <hintModel :show="overShow" @cancel="overShow = false" @submit="submitSure" submitText="确定提交" cancelText="我再想想"
                hintText="提交之后不可再次编辑，确定提交吗？">
            </hintModel>
        </div>
    </div>
</template>

<script>
import api from '@/api/api.js'
import { timeFormat } from '@/utils/timeCompute'
import { Toast, ImagePreview } from 'vant';
import PickerTime from '@/components/pickerTime'
export default {
    components: {
        PickerTime
    },
    data() {
        return {
            info: {},
            projectPlanInfo: {},
            fileList: [],
            remark: '',
            overShow: false,
            timeShow: false,
            typeCode: null,
            backPage: null,
            startDate: '',
        }
    },
    methods: {
        cancel() {
            if(this.backPage){
                this.$router.push({
                    name: "deepenCheckProjectPlanName",
                })
            }else{
                this.$router.push({
                    name: "deepenProjectPlanName",
                })
            }
        },
        async afterRead(file) {
            let fileList = Array.isArray(file) ? file : [file]
            if (fileList.length > 9) {
                Toast.fail('最多一次上传九张图片！')
                fileList = fileList.splice(0, 9)
            }
            const confirmFileList = fileList.map(item => ({ url: item.content, status: 'uploading' }))
            this.fileList = this.fileList.concat(confirmFileList)
            const indexR = this.fileList.length - fileList.length
            fileList.forEach((e, index) => {
                // 此时可以自行将文件上传至服务器
                let formData = new FormData();
                formData.append('files', e.file);
                formData.append('dir', 'oss');
                new Promise((reslove) => {
                    const res = api.upFile(formData)
                    reslove(res)
                }).then((res) => {
                    if (res.code == 200) {
                        this.fileList[indexR + index].url = res.url
                        this.fileList[indexR + index].status = 'done'
                    } else {
                        this.fileList[indexR + index].status = 'failed'
                    }
                })
            })
        },
        delPhoto(index) {
            this.fileList.splice(index, 1)
        },
        submit() {
            this.overShow = true
        },
        async submitSure() {
            if (this.fileList.length > 0) {
                const isUplod = this.fileList.some(item => item.status === 'uploading')
                const isFail = this.fileList.some(item => item.status === 'failed')
                if (!isUplod && !isFail) {
                    if(this.typeCode == 'ITEM982859' && !this.backPage && !this.startDate?.length){
                        Toast.fail('请选择开工日期！')
                    }else{
                        const list = this.fileList.map(e => ({ webUrl: e.url, nodeId: this.projectPlanInfo.id }))
                        let obj = {
                            nodeId: this.projectPlanInfo.id,
                            list,
                            remark: this.remark,
                            projectId: this.info.id
                        }
                        if (this.typeCode == 'ITEM982859') obj.startDate = this.startDate
                        let res = await api.projectPlanFinish(obj)
                        if (res.code == 200) {
                            Toast.success('提交成功！')
                            this.cancel()
                        }
                    }
                } else if (isUplod) {
                    Toast.fail('图片上传未完成，请等待...')
                } else if (isFail) {
                    Toast.fail('图片上传失败\n请重新上传')
                }
            } else {
                Toast.fail('请上传完成照片！')
            }
        },
        //选择时间弹窗
        chooseTime() {
            this.timeShow = true
        },
        timeSubmit(val) {
            // this.startDate = timeFormat(val, '-', 'yy-mm-dd HH:mm:ss')
            this.startDate = val
            this.timeShow = false
        },
        timeCancel(val) {
            this.timeShow = false
        },
        photoPreview(index) {
            let o = {
                images: this.fileList.map(item => item.url),
                startPosition: index
            }
            ImagePreview(o);
        },
    },
    created() {
        this.typeCode = this.$route.query.typeCode
        this.backPage = this.$route.query.backPage
    },
    mounted() {
        this.projectPlanInfo = JSON.parse(sessionStorage.getItem('projectPlanInfo'))
        this.info = JSON.parse(sessionStorage.getItem('clientInfo'))
    },
}
</script>

<style scoped lang="scss">
.finishUpProject-style {
    .content-style {
        height: calc(100vh - 50px);
        overflow: auto;
    }

    .input-box {
        margin-top: 10px;
        padding: 0 12px;
        display: flex;
        justify-content: space-between;
    }

    .input-text {
        font-size: 14px;
    }

    .input-textarea-box {
        padding: 0 12px 16px;
        margin-top: 10px;
        background: #FFFFFF;

        .textarea-style {
            border: 1px solid #eee;
            border-radius: 1px;
            padding: 8px;
        }
    }

    .field-style {
        ::v-deep .van-field__control {
            text-align: right;
        }
    }

    .photo-box {
        background: #FFFFFF;
        display: flex;
        padding: 0 18px;
        padding-bottom: 18px;
        flex-wrap: wrap;

        .photo-style {
            width: 48px;
            height: 48px;
            margin-right: 19px;
            margin-top: 10px;
            position: relative;

            .img-overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.65);
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .center-style {
            margin: 0 20px;
        }

        .del-icon {
            width: 16px;
            height: 16px;
            background: #000000;
            color: #FFFFFF;
            opacity: 0.8;
            border-radius: 50%;
            font-size: 9px;
            line-height: 16px;
            position: absolute;
            right: -6px;
            top: -6px;
            text-align: center;
            border: none;
            border-radius: 50%;
            z-index: 1;
        }
    }
}
</style>
<style scoped lang="scss">
.finishUpProject-style {
    .up-style {
        .van-button--primary {
            background-color: #FFFFFF;
            border: none
        }
    }
}
</style>
