<template>
  <div class="content">
    <headerNav
      leftNav=" "
      :title="navTitle"
      @leftClick="back"
    ></headerNav>
    <main class="content-main">
      <van-form @submit="onSubmitTask">
        <van-field
          :disabled ="!item.rules"
          v-for="(item,index) in typeList.formList" :key="index"
          v-model="formObj[item.prop]"
          :name="item.prop"
          :type="item.type"
          :maxlength="item.maxlength?item.maxlength:20"
          :label="item.label"
          placeholder="请输入"
          :rules="[{ required: true, message: '' }]"
          class="from-item"
          @input="formatter($event,item)"
        >
          <i v-if="item.rules" slot="left-icon" style="color: red">*</i>
          <template #button v-if="item.unit">{{ typeList.unit }}</template>
        </van-field>
        <van-field name="uploader" label="附件" v-model="uploaderNum" show-word-limit maxlength="9">
          <i slot="left-icon" style="color: red">*</i>
          <template #input>
            <van-uploader v-model="uploader" :max-count="9" accept=".jpg, .jpeg, .png, .gif" :after-read="afterRead" @delete="deleteFile"/>
          </template>
        </van-field>

        <div class="my-task-modification-popup-button">
          <van-button native-type="submit">提交</van-button>
        </div>
      </van-form>
    </main>

  </div>
</template>

<script>
import moment from "moment";
import { Toast } from 'vant';
import api from "@/api/task-management/human-resources.js";
import { uploadTaskModelFn } from "./data.js"

export default {
  components: {},
  data() {
    return {
      navTitle:"任务成果上传",
      // 任务看板路由接参
      routerData: {},
      // 路由参数
      previousRouterData: {},
      currentRouterData:{},
      // 查询数据
      data:{},
      formObj:{
        targetValue:"",
        finishValue:"",
        fileList:[]
      },
      // 上传
      uploaderNum:'',
      uploader:[],
      // 防止重复提交
      limit:false,
    };
  },
  created () {
    let localData = JSON.parse(localStorage.getItem("TABLE_HEADER_CONTENT"));
    this.routerData = localData;
    this.typeList = uploadTaskModelFn(localData.termSlug)
    this.previousRouterData = JSON.parse(this.$route.query.previousItem)
    this.currentRouterData = JSON.parse(this.$route.query.currentItem)
    if(this.typeList.type == "invite_job"){
      this.formObj = {
        ...this.formObj,
        indexName:this.previousRouterData.indexName,
        targetValue:this.currentRouterData.targetValue,
      }
    }else if(this.typeList.type == "pay_submit"){
      this.formObj = {
        prompt:'需本月6日前上传',
        fileList:[]
      }
    }else if(this.typeList.type == "admin_inspection" || this.typeList.type == "dorm_inspection"){
      this.formObj = {
        ...this.formObj,
        targetValue:this.currentRouterData.targetValue,
      }
    }
  },
  mounted() { },
  methods: {
    // 路由返回
    back() {
      this.$router.go(-1);
    },
    // 禁止弹出软键盘
    forbid(){
      document.activeElement.blur()
    },
    formatter(value,item){
      if(item.type == "digit" && Number(value) <= 0){
        Toast.fail('输入的数量不能为空或零')
        this.formObj[item.prop] = ""
      }
    },
    // 文件读取完成后的回调函数
    afterRead(file){
      this.uploaderNum=''
      console.log(this.uploader,'1111111');
      for(var i=1;i<=this.uploader.length;i++){
        this.uploaderNum +='-' 
      }
    },
    // 文件删除
    deleteFile(file){
      this.uploaderNum=''
      console.log(this.uploader,'222222');
      for(var i=1;i<=this.uploader.length;i++){
        this.uploaderNum +='-' 
      }
    },
    // 上传到oss服务器
    async upLoadFile(file) {
      let formData = new FormData();
      formData.append("files", file);
      formData.append("name", "files");
      formData.append("dir", "oss");
      try {
        const res = await api.uploadFile(formData)
        if (res.code == 200) {
          this.formObj.fileList.push({ fileName: file.name, fileUrl: res.url });
        }
      } catch (error) {
        Toast.fail('上传失败');
      }
    },
    // 表单参数 提交接口
    async saveTaskResult(){
      let params = {
        ...this.formObj,
        itemId:this.previousRouterData.indexCode||null,
        taskId:this.currentRouterData.taskId,
      }
      await api.saveTaskResult(params).then((res)=>{
        Toast.clear()
        if (res.code == 200) {
          Toast.success('操作成功');
          let _this = this;
          setTimeout(()=>{
            _this.back()
          },1000)
        }else{
          Toast.fail(res.msg)
        }
      }).finally(()=>{
        this.formObj.fileList = []
        this.limit = false
      })
    },
    // ---- form表单 提交---------
    async onSubmitTask(values) {
      if(this.limit){
        return false
      }
      if(this.uploader.length<=0){
        Toast.fail('请上传附件')
        return false
      }
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      const promises = [];
      this.uploader.forEach((item) => {
        promises.push(this.upLoadFile(item.file))
      })
      this.limit = true
      await Promise.all(promises)
      await this.saveTaskResult()
    },
  },
};
</script>
<style>
  .van-image-preview .van-image-preview__index{
    top: 95% !important;
  }
</style>
<style lang="scss" scoped>
.content ::v-deep {
  height: 100vh;
  overflow: auto;
  background: #f3f5f7;
  .van-form {
    height: calc(100vh - 140px);
    overflow: auto;
    .from-item {
      border-bottom: 8px solid #f3f5f7;
      .van-field__label{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .van-field__control{
        text-align: right;
        color: #323233 !important;
        -webkit-text-fill-color:unset;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
      }
    }
  }

  .my-task-modification-popup-button{
    width: 100%;
    padding: 10px 18px 30px;
    position: fixed;
    bottom: 0;
    background: #FFFFFF;
    box-shadow: 0px -1px 8px 0px rgba(0,0,0,0.04);
    box-sizing: border-box;
    .van-button{
      width: 100%;
      height: 50px;
      font-size: 18px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      color: #FFFFFF;
      background: #4871C0;
      border-radius: 4px 4px 4px 4px;
    }
  }

  .van-uploader__preview-image,.van-uploader__upload,.van-uploader__file{
    width: 69px;
    height: 69px;
  }

}
</style>
