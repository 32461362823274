<template>
  <div class="content">
    <headerNav
      leftNav=" "
      :title="navTitle"
      @leftClick="back"
    ></headerNav>
    <main class="content-main">
      <van-form @submit="onSubmitTaskSet" :key="fromRestFields">
        <van-field
          readonly
          disabled
          name="taskDate"
          :value="taskSetList.taskDate"
          label="时间"
          placeholder="请选择"
          :rules="[{ required: true, message: '' }]"
          class="from-item"
          @focus="forbid"
        >
          <i slot="left-icon" style="visibility: hidden;">*</i>
        </van-field>
        <van-field
          v-if="this.queryInfo.termId == 'transfer_output'"
          v-model="multiTerm.transfer_output"
          name="transfer_output"
          type="digit"
          maxlength="6"
          label="转单产值"
          placeholder="请输入"
          :rules="[{ required: true, message: '' }]"
          class="from-item"
        >
          <i slot="left-icon" style="color: red">*</i>
          <template #button>{{ unit }}</template>
        </van-field>
        <van-field
          v-if="this.queryInfo.termId == 'supply_output'"
          v-model="multiTerm.supply_output"
          name="supply_output"
          type="digit"
          maxlength="6"
          label="补齐产值"
          placeholder="请输入"
          :rules="[{ required: true, message: '' }]"
          class="from-item"
        >
          <i slot="left-icon" style="color: red">*</i>
          <template #button>{{ unit }}</template>
        </van-field>
        <div class="task-button">
          <span class="task-button-tip">*提示：如果设置任务大于上级指派任务，提交后需上级进行审批，审批通过任务生效；否则不用审批</span>
          <div class="my-task-modification-popup-button">
            <van-button native-type="button" @click="back">取消</van-button>
            <van-button native-type="submit">确认</van-button>
          </div>
        </div>
      </van-form>
    </main>

    <!-- 日期弹窗 -->
    <van-popup
      v-model="datePopupShow"
      class="my-date-popup"
      overlay-class="my-popup-cover"
      position="bottom"
      :safe-area-inset-bottom="true"
      :lazy-render="false"
    >
      <div class="my-date-popup-box">
        <div class="my-date-popup-content">
          <van-picker
            show-toolbar
            title="选择日期"
            :columns="columns"
            toolbar-position="top"
            ref="myPicker"
            style="height: 100%"
            @confirm="dateSureSearch"
            @cancel="dateRestSearch"
          >
          </van-picker>
        </div>
      </div>
    </van-popup>

  </div>
</template>

<script>
import moment from "moment";
import { Toast } from 'vant';
import api from "@/api/task-management/setup.js";
export default {
  components: {},
  data() {
    return {
      navTitle:'下级任务设置',
      datePopupShow: false,
      year: moment().year(),
      month: moment().month() + 1,
      columns: [
        { values: [], defaultIndex: moment().year() },
        { values: [], defaultIndex: moment().month() + 1 },
      ],
      queryInfo:{},
      unit:'万元',
      fromRestFields: 0,
      taskSetList: {
        id:"",
        roleCode:"",
        taskDate: "",
      },
      multiTerm:{
        transfer_output:'',
        supply_output:'',
      },
    };
  },
  created() {
    this.setDateColumns()
  },
  mounted() {
    let _this = this;
    this.queryInfo = JSON.parse(this.$route.query.item)
    let {taskDate,productionJobValue,id,roleCode} = this.queryInfo
    this.taskSetList = {
      ...this.taskSetList,
      id,
      roleCode,
      taskDate,
    }
    if(this.queryInfo.termId == 'transfer_output') {
      this.multiTerm.transfer_output = productionJobValue
    }else{
      this.multiTerm.supply_output = productionJobValue
    }
  },
  methods: {
    // 路由返回
    back() {
      this.$router.go(-1);
    },
    //初始化日期选择范围
    setDateColumns() {
      let y = [];
      let nowYear = moment().year() + 2;
      for (let i = nowYear; i > nowYear - 21; i--) {
        y.push(i);
      }
      let m = [];
      for (let i = 1; i < 13; i++) {
        i = i < 10 ? "0" + i : i.toString()
        m.push(i);
      }
      this.columns[0].values = y;
      this.columns[1].values = m;
    },
    //打开日期选择
    openChooseDate(type) {
      this.year = this.taskSetList.taskDate ? this.taskSetList.taskDate.split('-')[0] : moment().year()
      this.month = this.taskSetList.taskDate ? this.taskSetList.taskDate.split('-')[1] : (moment().month() + 1 ).toString()
      this.$refs.myPicker && this.$refs.myPicker.setValues([Number(this.year), this.month]);
      this.datePopupShow = true;
    },
    // 日期弹窗 取消
    dateRestSearch() {
      this.year = moment().year();
      this.month = moment().month() + 1 ;
      this.datePopupShow = false;
    },
    // 日期弹窗 确认
    dateSureSearch() {
      this.year = this.$refs.myPicker.getValues()[0];
      this.month = this.$refs.myPicker.getValues()[1];
      let date = this.year + "-" + this.month;
      this.taskSetList.taskDate = date
      //确认
      this.datePopupShow = false;
    },
    // 禁止弹出软键盘
    forbid(){
      document.activeElement.blur()
    },
    // ---- form表单 提交---------
    onSubmitTaskSet(values) {
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      this.taskSetList = {
        ...this.taskSetList,
        multiTerm:JSON.stringify(this.multiTerm)
      }
      api.setTasks(this.taskSetList).then((res)=>{
        Toast.clear()
        if (res.code == 200) {
          Toast.success('操作成功');
          let _this = this;
          setTimeout(()=>{
            _this.back()
          },1000)
        }else{
          Toast.fail(res.msg)
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.content ::v-deep {
  height: 100vh;
  overflow: auto;
  background: #f3f5f7;
  .van-form {
    height: calc(100vh - 185px);
    overflow: auto;
    .from-item {
      border-bottom: 8px solid #f3f5f7;
      .van-field__label{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .task-button{
    width: 100%;
    padding: 10px 18px 30px;
    position: fixed;
    bottom: 0;
    background: #FFFFFF;
    box-shadow: 0px -1px 8px 0px rgba(0,0,0,0.04);
    box-sizing: border-box;
    .task-button-tip{
      font-size: 12px;
      color: #999999;
      font-family: PingFang SC-Medium, PingFang SC;
      text-align: center;
    }
  }

  .my-task-modification-popup-button{
    margin-top: 10px;
    display: flex;
    gap: 12px;
    .van-button{
      width: 50%;
      height: 50px;
      font-size: 18px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      
      &:nth-child(2){
        color: #FFFFFF;
        background: #4871C0;
        border-radius: 4px 4px 4px 4px;
      }
    }
  }

  .task—allocation{
    margin:8px 0 8px 14px;
    .task—allocation-title{
      height: 24px;
      font-size: 16px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
      text-align: left;
      display: flex;
      align-items: center;
      & > div{
        width: 4px;
        height: 17px;
        background: #0E8BFF;
        border-radius: 18px 18px 18px 18px;
        margin-right: 6px;
      }

    }
    .task—allocation-describe{
      height: 24px;
      font-size: 12px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #999999;
      text-align: left;
      margin-left: 10px;
      & > span{
        color: #4871C0;
      }
    }
  }

}
</style>
