let taskOptions = [
  // ===========================财务===========================
  {
    navTitle:'收银详情',
    tableColumn1: "及时完成",
    tableColumn2: "收银总数",
    tableColumn3: "及时率",
    collect1: '及时完成',
    collect2: '收银总数',
    collect3: '及时率',
    value: 'cashier_timely',
    imgUrl:'task-board-finance',
    leftIconName:'task-board-finance-completions1',
    rightIconName:'task-board-finance-alltotal1',
    unit: '笔'
  },
  {
    navTitle:'记账详情',
    tableColumn1: "及时完成",
    tableColumn2: "记账总数",
    tableColumn3: "及时率",
    collect1: '及时完成',
    collect2: '记账总数',
    collect3: '及时率',
    unit: '笔',
    imgUrl:'task-board-finance',
    leftIconName:'task-board-finance-completions2',
    rightIconName:'task-board-finance-alltotal2',
    value: 'keep_accounts_timely'
  },
  {
    navTitle:'审核详情',
    tableColumn1: "及时完成",
    tableColumn2: "审核总数",
    tableColumn3: "及时率",
    collect1: '及时完成',
    collect2: '审核总数',
    collect3: '及时率',
    unit: '单',
    imgUrl:'task-board-finance',
    leftIconName:'task-board-finance-completions',
    rightIconName:'task-board-finance-alltotal',
    value: 'check_timely'
  },
  {
    navTitle:'市场综述',
    tableColumn1: "错漏处",
    tableColumn2: "延期提交(天)",
    tableColumn3: "最终得分",
    unit: '个',
    collect1: '满分完成',
    collect2: '非满分完成',
    collect3: '最终得分',
    collect4: '平均得分',
    imgUrl:'task-board-inquiry3',
    leftIconName:'task-board-finance-completions3',
    rightIconName:'task-board-finance-alltotal3',
    value: 'market_summarize'
  },
]
// 判断指标展示内容
export function estimateOutput (value) {
  let data = {}
  taskOptions.filter(item => {
    if (item.value == value) {
      data = item
    }
  })
  return data
}

// 任务成果展示
let taskResults = [
  { 
    label:'收银详情',
    value:'cashier_timely',
  },
  { 
    label:'记账详情',
    value:'keep_accounts_timely',
  },
  { 
    label:'审核详情',
    value:'check_timely',
    type:'file', // 任务上传显示的图片还是文件
    typeName1:'实际利润率', // 指标项
    unit:'%', // 指标单位
    isShowTarget:1, // 显示指标
  },
  { 
    label:'市场综述',
    value:'market_summarize',
  },
]
export function estimateOutputTarget (value) {
  let data = {}
  taskResults.filter(item => {
    if (item.value == value) {
      data = item
    }
  })
  return data
}

// 审批列表对应详情展示
let taskCheckList = [
  { 
    label:'工资提交',
    value:'pay_submit',
  },
  { 
    label:'费用报销',
    value:'cost_payment',
    label2:'应报销笔数',
    label3:'及时销笔数',
    unit:'笔'
  },
  { 
    label:'工地罚金',
    value:'site_penalty',
    label2:'任务罚金',
    label3:'实际完成',
    unit:'元'
  },
  { 
    label:'市场综述',
    value:'market_summarize',
  },
]
export function taskCheckListDetails (value) {
  let data = {}
  taskCheckList.filter(item => {
    if (item.value == value) {
      data = item
    }
  })
  return data
}