<template>
  <div class="progress-main" :style="{paddingBottom:showFoot?'12px':'0px'}">

    <main>
      <div class="progress-main-nav" :style="{ backgroundImage: `url(${imageUrl})` }">
        <div class="progress-main-nav-left">{{ day }}</div>
        <div class="progress-main-nav-right">
          <div class="progress-main-nav-right-money">{{(navTitleUnit == '%'?parseFloat(ratio).toFixed(2):ratio) + navTitleUnit }}
          </div>
          <div class="progress-main-nav-right-text">{{ month }}月份{{ targetName }}</div>
        </div>
        <div class="progress-main-nav-customerName" v-if="customerName">
            <div class="personal">{{ customerName }}</div>
            <div v-if="isAbnormity" class="personal-error">异常</div>
        </div>
      </div>
    </main>

    <footer v-if="showFoot">
      <div class="progress-foot-indicator">
        <!-- 左侧 -->
        <div class="progress-foot-indicator-content">
          <div class="progress-foot-indicator-top">
            <div class="progress-foot-indicator-top-img">
              <img :src="require(`@/assets/task-management/${leftIconName}.png`)" alt="">
            </div>
            <div class="progress-foot-indicator-top-right">
              <div>{{ completionsCount || 0 }} <span>{{ unit }}</span></div>
              <span>{{ leftText }}</span>
            </div>
          </div>
        </div>

        <!-- 右侧 -->
        <div class="progress-foot-indicator-content">
          <div class="progress-foot-indicator-top">
            <div class="progress-foot-indicator-top-img">
              <img :src="require(`@/assets/task-management/${rightIconName}.png`)" alt="">
            </div>
            <div class="progress-foot-indicator-top-right">
              <div>{{ sumTotal || 0 }} <span>{{ unit }}</span></div>
              <span>{{ rightText }}</span>
            </div>
          </div>
        </div>
      </div>

    </footer>

  </div>
</template>

<script>
import { timeFormat } from "@/utils/timeCompute";

export default {
  props: {
    // 背景图片名称
    imgBackUrlName: {
      type: String,
      default: ''
    },
    // 比率
    ratio: {
      type: String | Number,
      default: 0.00
    },
    // 左侧完成数
    completionsCount: {
      type: String | Number,
      default: 0
    },
    // 总数
    sumTotal: {
      type: String | Number,
      default: 0
    },
    // 左侧图标
    leftIconName: {
      type: String,
      default: ''
    },
    // 左侧文字
    leftText: {
      type: String,
      default: ''
    },
    // 右侧图标
    rightIconName: {
      type: String,
      default: ''
    },
    // 右侧文字
    rightText: {
      type: String,
      default: ''
    },
    // 单位
    unit: {
      type: String,
      default: '单'
    },
    // 指标名称
    targetName: {
      type: String,
      default: ''
    },
    // 是否显示指标汇总
    showFoot: {
      type: Boolean,
      default: true
    },
    // 客户名称
    customerName:{
      type: String,
      default:null
    },
    // 是否异常
    isAbnormity:{
      type: Boolean,
      default:false
    },
    // 顶部单位
    navTitleUnit:{
      type: String,
      default:'%'
    }

  },
  data () {
    return {
      month: 1,
      day: 1
    }
  },
  computed: {
    imageUrl () {
      // 使用相对路径或绝对URL来设置图片URL
      return require(`@/assets/task-management/${this.imgBackUrlName}.png`);
    }
  },
  created () {
    let data = timeFormat(new Date())
    this.month = data.split('-')[1]
    this.day = data.split('-')[2]
  }
}
</script>

<style lang="scss" scoped>
.progress-main::v-deep {
  padding: 12px 12px 0px 12px;
  border-radius: 0px 0px 8px 8px;
  background-color: #3975C6;

  .progress-main-nav {
    display: flex;
    align-items: flex-start;
    color: #fff;
    font-weight: 400;
    padding-bottom: 18px;
    background-size: 120px 89px;
    background-repeat: no-repeat;
    background-position: 210px 30% !important;

    .progress-main-nav-left {
      width: 28px;
      height: 22px;
      text-align: center;
      font-size: 12px;
      margin: 8.8px 6px 0px 0px;
      padding-top: 7px;
      background: url('../../../../../assets/task-management/progress-task-date.png') no-repeat;
      background-size: contain;
      background-position: 0px -1px;
    }

    .progress-main-nav-right {
      font-family: PingFang SC-Medium, PingFang SC;
      text-align: left;

      .progress-main-nav-right-money {
        font-size: 32px;
      }

      .progress-main-nav-right-text {
        font-size: 13px;
      }
    }

    .progress-main-nav-customerName {
      font-size: 15px;
      font-weight: bold;
      margin: 3% 6% 0 auto;
      position: relative;
      .personal-error{
        width: 36px;
        height: 20px;
        line-height: 16px;
        background: url('../../../../../assets/task-management/task-board-component-error.png') no-repeat;
        background-size: contain;
        font-size: 12px;
        position: absolute;
        top: -13px;
        left: 50%;
        margin-left: -14px;
        font-weight: 400;
      }
      div {
        width: 100%;
        text-align: center;
      }
      .van-badge {
        width: 34px;
        height: 18px;
        // line-height: 1px;
        font-size: 13px;
        background: #ED4040;
        padding: 3px 3px 1px;
        border: 0;
      }

      .van-badge--fixed {
        top: 1px;
        right: 4px;
      }
      .personal {
        min-width: 54px;
        max-width: 120px;
        height: 32px;
        line-height: 32px;
        border-radius: 4px;
        background: url('../../../../../assets/task-management/task-board-component-namebg.png') no-repeat;
        background-size: cover;
        font-size: 15px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        padding: 0 3px;
        white-space: nowrap; /* 不换行 */
        overflow: hidden; /* 超出部分隐藏 */
        text-overflow: ellipsis; /* 使用省略号表示被裁切的内容 */
      }
    }
  }

  .progress-foot-indicator {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2px;

    .progress-foot-indicator-content {
      width: 140px;
      // height: 70px;
      padding: 14px 15px;
      border-radius: 6px;
      background: url('../../../../../assets/task-management/progress-indicator-background.png') no-repeat;
      background-size: cover;

      .progress-foot-indicator-top {
        display: flex;

        .progress-foot-indicator-top-img {
          width: 29px;
          height: 29px;
          padding: 6px;
          background: #D7EFFF;
          border-radius: 2.73px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 28px;
            height: 28px;
          }
        }

        .progress-foot-indicator-top-right {
          margin-left: 4px;
          font-family: Bahnschrift-Regular, Bahnschrift;
          text-align: left;

          div {
            color: #545454;
            font-size: 20px;

            span {
              font-size: 14px;
            }
          }

          span {
            font-size: 13px;
            color: #8B8F92;
            font-weight: bold;
          }
        }
      }

      .progress-foot-indicator-foot {
        width: 100%;
        text-align: left;
        margin-top: 9px;
        font-family: PingFang SC-Heavy, PingFang SC;

        div {
          min-width: 100px;
          max-width: 123px;
          height: 24px;
          line-height: 24px;
          background: #E1F1FD;
          color: #0E8BFF;
          font-weight: 800;
          font-size: 12px;
        }
      }
    }
  }
}
</style>