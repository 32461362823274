<template>
	<div class="audit-resulse-style">
		<headerNav title="拒绝" leftNav="返回" @leftClick="cancel" rightNav="提交" @rightClick="submit"></headerNav>
		<div class="input-textarea-box">
			<div class="input-text">拒绝理由 <span class="xing-style">*</span></div>
			<div class="input-style">
				<van-form ref="vantForm" @submit="onSubmit">
					<van-field :rules="[{ required: true}]" class="input-style"
						v-model="refulseRemark" rows="3" autosize type="textarea" maxlength="200" name="refulseRemark"
						placeholder="请输入" />
				</van-form>

			</div>
		</div>
	</div>
</template>

<script>
	import api from '@/api/api.js'
	export default {
		data() {
			return {
				info:{},
				refulseRemark: '',
				itemId:'',
				reformId:''
			}
		},
		methods: {
			submit() {
				this.$refs.vantForm.submit()
			},
			cancel() {
				this.$router.go(-1)
			},
			async onSubmit(val){
				let obj = {
					itemId:this.itemId,
					reformId: this.reformId,
					check: false,
					resolveRemark: this.refulseRemark
				}
				let res = await api.dangerAudit(obj)
				if(res.code==200){
					this.$router.go(-1)
				}
			},
		},
		mounted() {
			this.itemId=this.$route.query.itemId
			this.reformId=this.$route.query.reformId
		},
	}
</script>

<style scoped lang="scss">
	.audit-resulse-style {
		.input-textarea-box {
			background: #FFFFFF;
			margin-top: 10px;
			padding: 0 18px;
		}
	}
</style>
