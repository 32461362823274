<template>
	<div class="construction-style">
		<headerNav title="在建工程"></headerNav>
		<van-tabs v-model="active" @change="onChange" color='#4871C0'>
			<van-tab title="客户订单" name='1'>
				<IndexTab />
			</van-tab>
			<van-tab title="分包单" name='2'>
				<IndexTab2 />
			</van-tab>
		</van-tabs>

	</div>
</template>

<script>
import IndexTab2 from './IndexTab2.vue'
import IndexTab from './IndexTab.vue'
import api from '../../../api/api.js'

export default {
	data() {
		return {
			active: '1'
		}
	},
	components: {
		IndexTab2,
		IndexTab
	},
	methods: {
		onChange(active) {
			sessionStorage.setItem('ecActive', active)
		},
        async getWeiXinInfo () {
            let obj = {
                type: 0,
                agentid: sessionStorage.getItem('APPID'),
                url: location.href.split('#')[0]
            }
            let res = await api.getWxConfig(obj)
            // if (res.code == 200) {
                wx.config({
                    beta: true,// 必须这么写，否则wx.invoke调用形式的jsapi会有问题
                    // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    debug: false,
                    // 必填，公众号的唯一标识
                    appId: res.appId,
                    // 必填，生成签名的时间戳
                    timestamp: res.timestamp,
                    // 必填，生成签名的随机串
                    nonceStr: res.noncestr,
                    // 必填，签名
                    signature: res.signature,
                    // 必填，需要使用的JS接口列表，所有JS接口列表
                    jsApiList: ['checkJsApi', 'onHistoryBack']
                });
                wx.ready(function () {
                    wx.onHistoryBack(function () {
                        wx.closeWindow();
                        return false
                    });
                });
                wx.error(function (res) {
                    alert("错误信息：" + res.errMsg);//这个地方的好处就是wx.config配置错误，会弹出窗口哪里错误，然后根据微信文档查询即可。
                });
            // }
        },
	},
	mounted() {
		const ecActive = sessionStorage.getItem('ecActive')
		if (ecActive) this.active = ecActive
        // console.log(wx)
        // this.getWeiXinInfo()
	}
}

</script>
<style scoped lang="scss">
::v-deep .van-tabs__nav {
	background-color: #3975C6;
}

::v-deep .van-tab {
	color: #fff;
	background-color: #5488CE;
	border-radius: 10px 10px 0px 0px;
}

::v-deep .van-tab--active {
	color: #181A1C;
	background-color: #fff;
}

::v-deep .van-tabs__line {
	display: none;
}
</style>