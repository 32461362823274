<template>
  <div class="main">
    <van-sticky class="topMenu">
      <headerNav leftNav=" " :title="'添加子任务'" @leftClick="back" @rightClick="onClickAddSubtask" :rightNav="'添加任务'" :rightIcon="'sub-task-add-icon'">
      </headerNav>
    </van-sticky>

    <van-form class="cell-group-center" @submit="confirmAdd">
      <main class="content">
        <div class="content-top">
          <van-cell-group class="cell-group-top">
            <van-cell title="任务时间" :value="taskData.taskTime" title-class="cell-group-title" value-class="cell-group-right" />
            <van-cell title="任务项" :value="taskData.termName" title-class="cell-group-title" value-class="cell-group-right" />
          </van-cell-group>
        </div>

        <div class="content-center">
          <div class="content-center-tips">
            已成功添加{{ taskList.length }}项子任务
          </div>
          <div class="content-center-list" v-for="(item, index) in taskList" :key="index">
            <div class="content-center-list-top">
              <div class="content-center-list-top-left">
                <div class="line"></div>
                <div class="text">子任务{{ index + 1 }}</div>
              </div>
              <div class="del">
                <img src="@/assets/task-management/delete-icon.png" alt="" @click="onClickDelete(item, index)" />
              </div>
            </div>

            <div class="content-center-list-cont">
              <van-field v-model="item.taskUserValue" :label="item.taskUser" label-width="140px" :rules="[{ required: true }]" placeholder="请选择" input-align="right" readonly clickable
                @click.stop="onClickSelect(item,index)">
                <i slot="left-icon" style="color: red">*</i>
                <template #button>
                  <van-icon name="arrow" />
                </template>
              </van-field>

              <van-field v-if="item.taskRoate" v-model="item.taskRoateValue" :label="item.taskRoate" label-width="140px" :rules="[{ required: true }]" placeholder="请输入" input-align="right"
                type="digit">
                <i slot="left-icon" style="color: red">*</i>
                <template #button>
                  <span style="color: #1d2129">{{
                    item.unit
                  }}</span>
                </template>
              </van-field>
            </div>

            <van-popup v-model="item.showPicker" position="bottom">
              <van-picker show-toolbar :columns="executorList" @confirm="onConfirm(item, $event)" @cancel="item.showPicker = false" />
            </van-popup>
          </div>
        </div>
      </main>
      <!-- 确定 -->
      <div class="footer-add">
        <van-button class="btn" type="info" native-type="submit" :loading="submitLoading">确定</van-button>
      </div>
    </van-form>

    <!-- 删除确认弹窗 -->
    <van-dialog v-model="deleteDialog" :closeOnClickOverlay="true" confirmButtonColor="#3975C6" show-cancel-button @close="deleteDialogClose" @confirm="onConfirmDelete">
      <div class="dialog-main">
        <!-- 驳回 -->
        <div class="dialog-main-reject">
          您确认“<span>删除</span>”子任务{{ deleteSubTaskIndex + 1 }}的相关内容吗？
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { estimateSubTask } from "../data.js";
import { Toast } from 'vant';
import api from "@/api/task-management/inquiry.js";

export default {
  data() {
    return {
      executorList: [],
      taskObj: {},
      // 子任务列表
      taskList: [],
      // 当前点击的子任务执行者索引
      subTaskUserIndex: 0,
      // 删除子任务弹窗
      deleteDialog: false,
      // 删除子任务索引
      deleteSubTaskIndex: 0,
      // 上传loading
      submitLoading:false
    };
  },
  created() {
    let taskData = JSON.parse(localStorage.getItem('INQUIRY_TASK_DETAILS'))
    this.taskData = taskData
    this.taskObj = estimateSubTask(taskData.termId);
    this.onClickAddSubtask();
    this.getExecutorList()
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    // 添加子任务
    onClickAddSubtask() {
      let newTask = {
        showPicker: false,
        ...this.taskObj
      };
      this.taskList.push(newTask);
    },
    // 查询执行者
    async getExecutorList() {
      const { data } = await api.getExecutorListApi()
      this.executorList = data.map(item => {
        return { text: item.name, value: item.code }
      })
    },
    // 点击打开选择框
    onClickSelect(item, index) {
      this.subTaskUserIndex = index
      item.showPicker = true;
    },
    // 删除子任务
    onClickDelete(item, index) {
      if (this.taskList.length == 1) {
        Toast.fail('只有一个子任务时不可删除')
        return
      }
      this.deleteDialog = true
      this.deleteSubTaskIndex = index

    },
    // 点击确定添加子任务
    onConfirm(item, value) {

      this.taskList[this.subTaskUserIndex].taskUserValue = value.text
      this.taskList[this.subTaskUserIndex].taskUserID = value.value
      item.showPicker = false;
      if (this.hasDuplicateTaskUser(this.taskList)) {
        this.taskList[this.subTaskUserIndex].taskUserValue = ''
        this.taskList[this.subTaskUserIndex].taskUserID = ''
        Toast.fail('执行者不可重复')
      }
    },
    // 删除弹窗二次确认
    onConfirmDelete() {
      this.taskList.map((items, indexs) => {
        if (this.deleteSubTaskIndex == indexs) {
          this.taskList.splice(indexs, 1);
        }
      });
    },
    // 弹窗关闭‘
    deleteDialogClose() {
    },
    // 确定添加
    async confirmAdd() {
      let obj = {
        taskId: this.taskData.taskId,
        itemList: this.taskList.map(item => ({ userId: item.taskUserID, userName: item.taskUserValue, [item.fileName]: item.taskRoateValue }))
      }
      this.submitLoading = true
      try {
        const data = await api.saveSubtaskDetail(obj)
        if (data.code == 200) {
          Toast.success('添加成功')
          setTimeout(() => {
            this.back()
          }, 1000)
        }
      } finally { 
        this.submitLoading = false
      }
    },
    // 判断执行者重复
    hasDuplicateTaskUser(arr) {
      return new Set(arr.map((item) => JSON.stringify(item.taskUserID))).size !== arr.length
    }
  },
};
</script>

<style lang="scss" scoped>
.main::v-deep {
  .content {
    background: #f5f5f5;
    height: calc(100vh - 130px);
    padding-bottom: 80px;
    overflow: auto;

    .content-top {
    }

    .content-center {
      text-align: left;

      .content-center-tips {
        font-size: 14px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #ff8c21;
        margin: 16px 12px 8px 12px;
      }

      .content-center-list {
        .content-center-list-top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0px 12px;

          .content-center-list-top-left {
            display: flex;
            align-items: center;

            .line {
              width: 4px;
              height: 15px;
              background: #0e8bff;
              border-radius: 1px 1px 1px 1px;
              margin-right: 8px;
            }

            .text {
              font-size: 18px;
              font-family: PingFang SC, PingFang SC;
              font-weight: 500;
              color: #333333;
            }
          }

          .del {
            img {
              width: 20px;
              height: 20px;
              vertical-align: middle;
            }
          }
        }

        .content-center-list-cont {
          margin: 10px 0px 16px 0px;

          .van-field__label {
            font-size: 16px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            color: #1d2129;
          }
          .van-field__value {
            font-size: 16px;
          }
        }
      }
    }
  }

  .cell-group-title {
    font-size: 16px;
    font-family: PingFang TC, PingFang TC;
    font-weight: 400;
    color: #999999;
    text-align: left;
  }

  .cell-group-right {
    font-size: 16px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #333;
  }
  .footer-add {
    height: 66px;
    line-height: 50px;
    width: 100%;
    background: #fff;
    box-shadow: 0px -1px 8px 0px rgba(0, 0, 0, 0.04);
    text-align: center;
    position: fixed;
    bottom: 0px;
    left: 0px;
    padding-bottom: 10px;


    .btn {
      background: #3975c6;
      color: #ffffff;
      border-radius: 4px;
      font-size: 16px;
      font-weight: 500;
      width: 340px;
    }
  }
  .dialog-main {
    padding: 16px;
    border-bottom: 1px solid #eeeeee;

    .dialog-main-reject {
      font-size: 16px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      color: #000000;
      margin: 20px 0px;

      span {
        color: #ed4040;
      }
    }
  }
}
</style>
