<template>
  <div class="content">
    <headerNav
      leftNav=" "
      title="任务设置"
      @leftClick="back"
    ></headerNav>
    <main class="content-main">
      <van-form @submit="onSubmitTaskSet" :key="fromRestFields">
        <van-field
          clickable
          name="marketName"
          :value="taskSetList.marketName"
          label="市场"
          placeholder="请选择"
          :rules="[{ required: true, message: '' }]"
          @click="showMarketPicker = true"
          class="from-item"
          @focus="forbid"
        >
          <i slot="left-icon" style="color: red">*</i>
          <template #button><van-icon name="arrow" /></template>
        </van-field>
        <van-field
          clickable
          name="taskDate"
          :value="taskSetList.taskDate"
          label="时间"
          placeholder="请选择"
          :rules="[{ required: true, message: '' }]"
          @click="openChooseDate"
          class="from-item"
          @focus="forbid"
        >
          <i slot="left-icon" style="color: red">*</i>
          <template #button><van-icon name="arrow" /></template>
        </van-field>
        <van-field
          clickable
          name="taskName"
          :value="taskSetList.termName"
          label="任务类型"
          placeholder="请选择"
          :rules="[{ required: true, message: '' }]"
          @click="showTaskTypePicker = true"
          class="from-item"
          @focus="forbid"
        >
          <i slot="left-icon" style="color: red">*</i>
          <template #button><van-icon name="arrow" /></template>
        </van-field>
        <van-field
          v-model="taskSetList.productionMinValue"
          name="productionMinValue"
          type="digit"
          maxlength="6"
          label="保底产值"
          placeholder="请输入"
          :rules="[{ required: true, message: '' }]"
          class="from-item"
        >
          <i slot="left-icon" style="color: red">*</i>
          <template #button>万元</template>
        </van-field>
        <van-field
          v-model="taskSetList.productionJobValue"
          name="productionJobValue"
          type="digit"
          maxlength="6"
          label="任务产值"
          placeholder="请输入"
          :rules="[{ required: true, message: '' }]"
          class="from-item"
        >
          <i slot="left-icon" style="color: red">*</i>
          <template #button>万元</template>
        </van-field>
        <van-field
          v-model="taskSetList.productionGoalValue"
          name="productionGoalValue"
          type="digit"
          maxlength="6"
          label="目标产值"
          placeholder="请输入"
          :rules="[{ required: true, message: '' }]"
          class="from-item"
        >
          <i slot="left-icon" style="color: red">*</i>
          <template #button>万元</template>
        </van-field>
        <div class="my-task-modification-popup-button">
          <van-button native-type="botton" @click="back">取消</van-button>
          <van-button native-type="submit">确认</van-button>
        </div>
      </van-form>
    </main>

    <van-popup v-model="showMarketPicker" position="bottom">
      <van-picker
        show-toolbar
        title="市场"
        :columns="marketList"
        @confirm="onConfirmMarket"
        @cancel="showMarketPicker = false"
      />
    </van-popup>

    <van-popup
      v-model="datePopupShow"
      class="my-date-popup"
      overlay-class="my-popup-cover"
      position="bottom"
      :safe-area-inset-bottom="true"
      :lazy-render="false"
    >
      <div class="my-date-popup-box">
        <div class="my-date-popup-content">
          <van-picker
            show-toolbar
            title="选择日期"
            :columns="columns"
            toolbar-position="top"
            ref="myPicker"
            style="height: 100%"
            @confirm="dateSureSearch"
            @cancel="dateRestSearch"
          >
          </van-picker>
        </div>
      </div>
    </van-popup>

    <van-popup v-model="showTaskTypePicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="taskTypeList"
        @confirm="onConfirmTaskType"
        @cancel="showTaskTypePicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
import moment from "moment";
import { Toast } from 'vant';
import api from "@/api/task-management/setup.js";

export default {
  components: {},
  data() {
    return {
      fromRestFields: 0,
      taskSetList: {
        operateType:"1",  //1 新增  ; 2 修改
        taskType: "D",    //任务分类（D=组织, P=员工)
        marketName:"",
        marketCode: "",
        taskDate: "",
        termName:"",
        termId:"",
        productionMinValue: "",
        productionJobValue: "",
        productionGoalValue: "",
      },
      marketList: [
        { text: "济南", value: "370100" },
        { text: "青岛", value: "370200" },
        { text: "淄博", value: "370300" },
        { text: "烟台", value: "370600" },
        { text: "潍坊", value: "370700" },
        { text: "威海", value: "371000" },
        { text: "临沂", value: "371300" },
        { text: "济宁", value: "370800" },
        { text: "长春", value: "220100" },
      ],
      showMarketPicker: false,

      datePopupShow: false,
      year: moment().year(),
      month: moment().month() + 1,
      columns: [
        { values: [], defaultIndex: moment().year() },
        { values: [], defaultIndex: moment().month() + 1 },
      ],

      showTaskTypePicker: false,
      taskTypeList:[
        { text: "转单产值", value: "transfer_output" },
        { text: "补齐产值", value: "supply_output" },
      ]
    };
  },
  created() {
    this.setDateColumns()
  },
  mounted() {
    let _this = this;
  },
  methods: {
    // 路由返回
    back() {
      this.$router.go(-1);
    },
    // 市场弹窗 确认
    onConfirmMarket(value) {
      this.taskSetList.marketCode = value.value;
      this.taskSetList.marketName = value.text;
      this.showMarketPicker = false;
      this.validTasks()
    },
    //初始化日期选择范围
    setDateColumns() {
      let y = [];
      let nowYear = moment().year() + 2;
      for (let i = nowYear; i > nowYear - 21; i--) {
        y.push(i);
      }
      let m = [];
      for (let i = 1; i < 13; i++) {
        i = i < 10 ? "0" + i : i.toString()
        m.push(i);
      }
      this.columns[0].values = y;
      this.columns[1].values = m;
    },
    //打开日期选择
    openChooseDate(type) {
      this.year = this.taskSetList.taskDate ? this.taskSetList.taskDate.split('-')[0] : moment().year()
      this.month = this.taskSetList.taskDate ? this.taskSetList.taskDate.split('-')[1] : (moment().month() + 1 ).toString()
      this.$refs.myPicker && this.$refs.myPicker.setValues([Number(this.year), this.month]);
      this.datePopupShow = true;
    },
    // 日期弹窗 取消
    dateRestSearch() {
      this.year = moment().year();
      this.month = moment().month() + 1 ;
      this.datePopupShow = false;
    },
    // 日期弹窗 确认
    dateSureSearch() {
      this.year = this.$refs.myPicker.getValues()[0];
      this.month = this.$refs.myPicker.getValues()[1];
      let date = this.year + "-" + this.month;
      this.taskSetList.taskDate = date
      if((moment(date).year() - moment().year()) * 12 + moment(date).month() - moment().month() < 0){
        this.taskSetList.taskDate = ''
        Toast.fail('不能选择历史时间')
      }
      
      //确认
      this.datePopupShow = false;
      this.validTasks()
    },
    // 任务类型弹窗 确认
    onConfirmTaskType(value){
      this.taskSetList.termId = value.value;
      this.taskSetList.termName = value.text;
      this.showTaskTypePicker = false;
      this.validTasks()
    },
    // 校验任务设置数据
    validTasks(){
      if(this.taskSetList.marketCode && this.taskSetList.taskDate && this.taskSetList.termId){
        let params = {
          "Q_taskDate_EQ":this.taskSetList.taskDate, 
          "Q_termId_EQ":this.taskSetList.termId, 
          "Q_marketCode_EQ":this.taskSetList.marketCode
        }
        api.validTask(params).then((res)=>{
          if(res.code != 200){
            Toast.fail(res.msg)
            this.taskSetList.marketCode = ""
            this.taskSetList.marketName = ""
            this.taskSetList.taskDate = ""
            this.taskSetList.termId = ""
            this.taskSetList.termName = ""
          }
        })
      }
    },
    // 禁止弹出软键盘
    forbid(){
      document.activeElement.blur()
    },
    // form表单 提交
    onSubmitTaskSet(values) {
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      api.setTaskPresident(this.taskSetList).then((res)=>{
        Toast.clear()
        if (res.code == 200) {
          Toast.success('操作成功');
          let _this = this;
          setTimeout(()=>{
            _this.back()
          },1000)
        }else{
          Toast.fail(res.msg)
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.content ::v-deep {
  height: 100vh;
  overflow: auto;
  background: #f3f5f7;
  .van-form {
    height: calc(100vh - 140px);
    overflow: auto;
    .from-item {
      border-bottom: 8px solid #f3f5f7;
    }
  }

  .my-task-modification-popup-button{
    width: 100%;
    padding: 10px 18px 30px;
    position: fixed;
    bottom: 0;
    background: #FFFFFF;
    box-shadow: 0px -1px 8px 0px rgba(0,0,0,0.04);
    box-sizing: border-box;
    display: flex;
    gap: 12px;
    .van-button{
      width: 50%;
      height: 50px;
      font-size: 18px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      
      &:nth-child(2){
        color: #FFFFFF;
        background: #4871C0;
        border-radius: 4px 4px 4px 4px;
      }
    }
  }

  

}
</style>
