<template>
  <div class="page">
    <headerNav leftNav=" " :title="'个人信息'" @leftClick="back" />
    <main>
      <ul>
        <li class="cell interval">
          <div class="lable">客户姓名</div>
          <div class="value">{{ info.idName }}</div>
        </li>
        <li class="cell">
          <div class="lable">性别</div>
          <div class="value">{{ info.gender == "1"?"男":"女" }}</div>
        </li>
        <li class="cell">
          <div class="lable">手机号</div>
          <div class="value">{{ info.mobile }}</div>
        </li>
        <li class="cell interval">
          <div class="lable">市场</div>
          <div class="value">{{ info.marketName }}</div>
        </li>
        <li class="cell">
          <div class="lable">部门</div>
          <div class="value">{{ info.department }}</div>
        </li>
        <li class="cell">
          <div class="lable">角色信息</div>
          <div class="value role">
            <span v-for="(item,index) in roleList" :key="index">{{ item.text }}</span>
          </div>
        </li>
        <li class="cell">
          <div class="lable">员工状态</div>
          <div class="value status">{{ info.status=="1"?"离职":"在职" }}</div>
        </li>
      </ul>
    </main>
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  components: {
  },
  data () {
    return {
      info:{},
      roleList:[]
    }
  },
  created () {
    this.info = JSON.parse(this.$route.query.info)
    console.log(this.info);
    let list = JSON.parse(localStorage.getItem('USER_ROLE_LIST'))
    this.roleList = list.map(item => {
      return { text: item.roleName}
    })
  },
  methods: {
    back () {
      this.$router.go(-1)
    },
  }
}
</script>

<style lang="scss" scoped>
.page{
  width: 100%;
  height: 100vh;
  background: #F2F2F2;
  .cell{
    background: #FFFFFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;
    position: relative;

    .lable{
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 17px;
      color: #333333;
      line-height: 56px;
    }
    .value{
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 17px;
      color: #333333;
      width: 70%;
      text-align: right;
    }

    .role{
      padding: 8px 0 0;
      span{
        line-height: 26px;
        background: rgba(57,117,198,0.1);
        border-radius: 4px 4px 4px 4px;
        display: inline-block;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 16px;
        color: #3975C6;
        padding: 2px 6px;
        margin-left: 8px;
        margin-bottom: 10px;
      }
    }

    .status{
      color: #3CC774;
    }
  }
  .cell::before{
    content: "";
    position: absolute;
    bottom: 0;
    height: 1px;
    width: calc(100% - 24px);
    background: rgba(51,51,51,0.08);

  }
  .interval{
    margin-top: 16px;
  }
}
</style>