<template>
    <div class="detail-collapse-box">
        <van-divider dashed :style="{ borderColor: '#979797' }" />
        <!-- <div class="detail-collapse" v-if="!showList">
            <van-swipe-cell :before-close="beforeClose" :name="ItemData.id">
                <div class="detail-collapse-item">
                    <div class="detail-collapse-header" @click="onShowCollapse(ItemData.id)">
                        <div style="font-weight: 300;font-size: 14px;"> {{ ItemData.belowDate || '' }} </div>
                        <div style="padding-left: 8px;">
                            <van-icon name="arrow-down" color="#969799" size="18"
                                v-show="!activeNames.includes(ItemData.id)" />
                            <van-icon name="arrow-up" color="#969799" size="18"
                                v-show="activeNames.includes(ItemData.id)" />
                        </div>
                    </div>
                    <div class="detail-collapse-center">
                        <div class="dcc-top van-ellipsis"> {{ ItemData.itemName }}</div>
                        <div class="dcc-bottom">
                            <div style="color: #999;font-size: 14px;">增减量/单价</div>
                            <div>
                                <span :style="{ color: ItemData.amount > 0 ? '#E8202A' : '#039139' }">{{
                                    ItemData.amount > 0 ? `+${ItemData.amount}` : ItemData.amount }}</span>
                                &emsp;
                                <span style="font-size: 14px;">￥{{ ItemData.primePrice }}/{{ ItemData.itemUnit }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="detail-collapse-footer" v-show="activeNames.includes(ItemData.id)">
                        <van-divider dashed :style="{ borderColor: '#979797', marginBottom: 0 }" />
                        <div class="dcf-item">
                            <div class="label">合计价:</div>
                            <div class="value" style="color: #E8202A;font-size: 16px;">{{ ItemData.sellingPrice }}</div>
                        </div>
                        <div class="dcf-item">
                            <div class="label">材料/定额编号:</div>
                            <div class="value">{{ ItemData.itemNo }}</div>
                        </div>
                        <div class="dcf-item">
                            <div class="label" style="width: 60px;">说明:</div>
                            <div class="value">{{ ItemData.remark }}</div>
                        </div>
                    </div>
                </div>
                <template #right>
                    <van-button square text="已读" class="delete-button" />
                </template>
            </van-swipe-cell>
        </div> -->
        <div>
            <div class="detail-collapse" v-for="item in list" :key="item.id" v-if="item.id === activeCellId || showList">
                <van-swipe-cell :before-close="beforeClose" :name="item.id">
                    <div class="detail-collapse-item">
                        <div class="detail-collapse-header" @click="onShowCollapse(item.id)">
                            <div style="font-weight: 300;font-size: 14px;"> {{ item.belowDate || '' }} </div>
                            <div style="padding-left: 8px;">
                                <van-icon name="arrow-down" color="#969799" size="18"
                                    v-show="!activeNames.includes(item.id)" />
                                <van-icon name="arrow-up" color="#969799" size="18"
                                    v-show="activeNames.includes(item.id)" />
                            </div>
                        </div>
                        <div class="detail-collapse-center">
                            <div class="dcc-top van-ellipsis"> {{ item.itemName }}</div>
                            <div class="dcc-bottom">
                                <div style="color: #999;font-size: 14px;">增减量/单价</div>
                                <div>
                                    <span :style="{ color: item.amount > 0 ? '#E8202A' : '#039139' }">{{
                                        item.amount > 0 ? `+${item.amount}` : item.amount }}</span>
                                    &emsp;
                                    <span style="font-size: 14px;">￥{{ item.sellingPrice }}/{{ item.itemUnit }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="detail-collapse-footer" v-show="activeNames.includes(item.id)">
                            <van-divider dashed :style="{ borderColor: '#979797', marginBottom: 0 }" />
                            <div class="dcf-item">
                                <div class="label">合计价:</div>
                                <div class="value" style="color: #E8202A;font-size: 16px;">{{ item.sellingTotal }}</div>
                            </div>
                            <div class="dcf-item">
                                <div class="label">材料/定额编号:</div>
                                <div class="value">{{ item.itemNo }}</div>
                            </div>
                            <div class="dcf-item">
                                <div class="label" style="width: 60px;">说明:</div>
                                <div class="value">{{ item.remark }}</div>
                            </div>
                        </div>
                    </div>
                    <template #right>
                        <div class="delete-button">已读</div>
                    </template>
                </van-swipe-cell>
            </div>
        </div>
        <div style="text-align: center;marginTop: 8px;" @click="onShowList" v-if="list.length > 1">
            ( <span style="color:#1677FF">{{ list.length }}</span>条 ) 未查看
            <van-icon name="arrow-down" v-show="!showList" />
            <van-icon name="arrow-up" v-show="showList" />
        </div>
    </div>
</template>

<script>
import api from '@/api/api.js'
export default {
    props: {
        list: {
            type: [Array, null, undefined],
            default: () => []
        },
        activeNames: {
            type: [Array, null, undefined],
            default: () => []
        },
    },
    data() {
        return {
            showList: false,
        }
    },
    computed: {
        ItemData() {
            return this.list.length ? this.list[0] : {}
        },
        activeCellId() {
            return this.list.length ? this.list[0].id : ''
        }
    },
    methods: {
        onShowCollapse(id) {
            const flag = this.activeNames.includes(id)
            if (flag) {
                const list = this.activeNames.filter(item => item !== id)
                this.$emit('changeActive', list)
            } else {
                const list = [...this.activeNames, id]
                this.$emit('changeActive', list)
            }
        },
        onShowList() {
            if (this.list.length > 1) {
                this.showList = !this.showList

            }
        },
        beforeClose({ name: id, instance, position }) {
            if (position === 'right') {
                const findIndex = this.list.findIndex(item => item.id === id)
                this.$emit('changeList', findIndex)
                // api.editSubpackageDetailIsLock(id)
            }
            instance.close();
        },
    },
}
</script>

<style scoped lang="scss">
.detail-collapse-box {
    font-size: 14px;

    .van-swipe-cell {
        border-radius: 8px;
        background-color: #fff;
    }

    .delete-button {
        width: 60px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #48C086;
        border-color: #48C086;
        color: #fff;
    }

    .detail-collapse {
        margin-bottom: 10px;
    }

    .detail-collapse-item {
        padding: 10px 16px;
        border-radius: 10px;

        .detail-collapse-header {
            height: 32px;
            line-height: 32px;
            display: flex;
            justify-content: space-between;
            color: #666;
        }

        .detail-collapse-center {
            .dcc-top {
                font-size: 16px;
                color: #333;
            }

            .dcc-bottom {
                margin-top: 8px;
                display: flex;
                justify-content: space-between;
            }
        }

        .detail-collapse-footer {
            font-size: 14px;

            .dcf-item {
                display: flex;
                justify-content: space-between;
                padding: 8px 0;

                .label {
                    width: 100px;
                    color: #999999;
                }

                .value {
                    text-align: right;
                    flex: 1;
                    word-break: break-all;
                }
            }
        }
    }
}

.swiper {
    width: calc(100% - 48px);
}

.swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background-color: #fff;
}
</style>