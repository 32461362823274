<template>
	<div class="patrol-style">
		<headerNav title="巡检整改" leftNav="返回" @leftClick="back" :rightNav="active==1?'新增':'' "
			@rightClick="add"></headerNav>
		<div class="header-tabs">
			<van-tabs v-model="active" class="my-tabs">
				<van-tab v-for="(item,index) in tabs" :title="item.title" :key="index"
					:to="{name:item.routerName}" :name='item.index'>
					<template #title>
						<v-touch @swipeleft="swiperleft" @swiperight="swiperright">
							<div class="diy-title">{{item.title}}</div>
						</v-touch>
						<van-badge v-if="item.newMesNum>0" class="badge-style" :content="item.newMesNum" max="99" />
					</template>
					<div class="contennt-style">
						<router-view />
					</div>
				</van-tab>
			</van-tabs>
		</div>

	</div>
</template>

<script>
	import api from '@/api/api.js'
	export default {
		data() {
			return {
				info: {},
				active: 1,
				tabs: [{
						title: '巡检记录',
						routerName: 'deepenPatrolListlName',
						index: 1
					},
					{
						title: '现存隐患',
						routerName: 'deepenExtantDangerName',
						newMesNum: 0,
						index: 2
					},
					{
						title: '解决隐患',
						routerName: 'deepenSolveDangerName',
						index: 3

					},
					{
						title: '符合标准',
						routerName: 'deepenStandardName',
						index: 4
					},
				],
				active: 1,
			}
		},
		computed: {
			// 计算属性的 getter
			getStoreItem() {
				return this.$store.state.extantDangerNum
			}
		},
		watch: {
			getStoreItem: function(val) {
				this.tabs[1].newMesNum = this.$store.state.extantDangerNum
			},
		},
		methods: {
			swiperleft() {
				// console.log(this.active)
				// console.log("左滑")
				if (this.active > 1) {
					this.active -= 1
					this.$router.push({
						name: this.tabs[this.active - 1].routerName,
						query: this.info
					})
				}
			},
			swiperright() {
				// console.log("右滑")
				if (this.active < 4) {
					this.active += 1
					this.$router.push({
						name: this.tabs[this.active - 1].routerName,
						query: this.info
					})
				}
			},
			back() {
				sessionStorage.setItem('clientInfo',JSON.stringify(this.info))
				if (sessionStorage.getItem('fromRouterNameDeepen')) {
					this.$router.push({
						name: sessionStorage.getItem('fromRouterNameDeepen'),
					})
				} else {
					this.$router.push({
						name: "deepenClicntDetailName",
					})
				}
				// if(this.$route.query.isBack){
				// 	this.$router.push({
				// 		name: "deepenConstructionName",
				// 		query: this.info
				// 	})
				// }else{
				// 	this.$router.push({
				// 		name: "deepenClicntDetailName",
				// 		query: this.info
				// 	})
				// }
			},
			add() {
				sessionStorage.setItem('clientInfo',JSON.stringify(this.info))
				if (this.active == 1) {
					this.$router.push({
						name: "deepenAddPatroName",
					})
				}
			},
			async getExtantDangerAuditList() {
				let obj = {
					flag: '1',//解决隐患
					time:'',
					projectId: this.info.id,
					nodeCode:''
				}
				let res = await api.getProjectStatesList(obj)
				if (res.code == 200) {
					this.tabs[1].newMesNum = res.count
					// let that = this
					// localStorage.setItem('extantDangerNum', this.tabs[1].newMesNum)
					// window.addEventListener('storage', event => {
					// 	if (event.key == 'extantDangerNum') {
					// 		that.tabs[1].newMesNum = localStorage.getItem('extantDangerNum')
					// 	}
					// });
				}
			}
		},
		mounted() {
			this.tabs.forEach((e, i) => {
				if (e.routerName == this.$route.name) {
					this.active = e.index
				}
			})
			// this.active = this.$route.name
			this.info = JSON.parse(sessionStorage.getItem('clientInfo'))
			this.getExtantDangerAuditList()
		},
	}
</script>

<style scoped lang="scss">
	.patrol-style {
		.header-tabs {
			height: 47px;
		}

		.contennt-style {
			height: calc(100vh - 98px);
			overflow: auto;
		}

		.diy-title {
			display: flex;
			align-items: center;

			.mes-style {}
		}

		.badge-style {
			transform: scale(.9);
			display: block;
			font-size: 12px;
			line-height: 14px;
			margin-left: 2px;
			position: absolute;
			right: 0;
		}
	}
</style>
<style lang="scss">
	.patrol-style .my-tabs {
		.van-tabs__wrap {
			height: 47px;
			border-bottom: 1px solid #E6E6E6;
		}

		.van-tab {
			font-size: 13px;
			// font-family: Source Han Sans SC;
			font-weight: 400;
			color: #AAAAAA;
		}

		.van-tab--active {
			font-size: 13px;
			// font-family: Noto Sans SC;
			font-weight: 500;
			color: #333333;
		}

		.van-tabs__line {
			width: 25px;
			height: 2px;
			background: #4F7AFD;
			border-radius: 2px 2px 0px 0px;
		}
		.van-tab__text {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
			width: 100%;
		}
	}
</style>
