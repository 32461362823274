<template>
  <div class="main">
    <van-sticky class="topMenu">
      <headerNav leftNav=" " :title="'任务管理中心'" @leftClick="back" @rightClick="onClickExamine" :rightNav="'审批'"
        :rightIcon="'task-setup-finance-check'" :hintData="approvalCount" :showRightContent="examineBtn">
      </headerNav>
    </van-sticky>

    <main>
      <van-tabs v-model="tabActive" @click="onClickTab" color="#3DA2FF" title-active-color="#3DA2FF" title-inactive-color="#333333">
        <van-tab title="本月任务" name="1">
          <div class="content" :class="userRole.roleCode=='ROLE_201262'?'':'content-height'" ref="scrollArea1" @scroll="handleScroll">
            <!-- 每一项任务 -->
            <div class="content-element" v-for="(item, index) in list" :key="index" @click="onClickTaskDetails(item)">
              <div class="content-element-icon">
                <img :src="require(`@/assets/task-management/human-resources-${item.termId.replaceAll('_','-')}-detail-icon.png`)" alt="">
              </div>
              <div class="content-element-review">
                <div class="content-element-review-text">{{item.termName}}</div>
                <div class="content-element-review-date">{{ item.taskDate }}</div>
                <div class="content-element-review-name">
                  <i>{{ item.userName }}</i>
                  <span style="margin-left: 20px;" v-if="item.termId == 'invite_job'">
                  <span style="color: #0E8BFF;">{{ item.childTaskFinishCount }}/</span>{{ item.childTaskTotalCount }}</span>
                  <span style="margin-left: 20px;" v-if="item.termId == 'admin_inspection'||item.termId == 'dorm_inspection'">
                  <span style="color: #0E8BFF;">{{ item.finishValue }}/</span>{{ item.targetValue }}</span>
                </div>
              </div>
              <div class="content-element-final">
                <div class="content-element-final-count">{{ (item.finishRate).toFixed(2) }}%</div>
                <div class="content-element-final-text">{{ item.rateName}}</div>
              </div>
              <div class="content-element-arrows">
                <img src="@/assets/task-management/task-setup-finance-arrows.png" alt="">
              </div>
            </div>
            <!-- 暂无任务 -->
            <div class="no-data" v-if="isData && list.length <= 0">
              <img src="@/assets/task-management/task-setup-nodata.png" alt="">
              <div>暂无任务</div>
            </div>
          </div>
        </van-tab>
        <van-tab title="计划任务" name="2">
          <div class="content" :class="userRole.roleCode=='ROLE_201262'?'':'content-height'" ref="scrollArea2" @scroll="handleScroll">
            <!-- 每一项任务 -->
            <div class="content-element" v-for="(item, index) in list" :key="index" @click="onClickTaskDetails(item)">
              <div class="content-element-icon">
                <img :src="require(`@/assets/task-management/human-resources-${item.termId.replaceAll('_','-')}-detail-icon.png`)" alt="">
              </div>
              <div class="content-element-review">
                <div class="content-element-review-text">{{item.termName}}</div>
                <div class="content-element-review-date">{{ item.taskDate }}</div>
                <div class="content-element-review-name">
                  <i>{{ item.userName }}</i>
                  <span style="margin-left: 20px;" v-if="item.termId == 'invite_job' ">
                  <span style="color: #0E8BFF;">{{ item.childTaskFinishCount }}/</span>{{ item.childTaskTotalCount }}</span>
                </div>
              </div>
              <div class="content-element-final" v-if="item.termId == 'invite_job'||item.termId == 'admin_inspection'||item.termId == 'dorm_inspection'">
                <div class="content-element-final-count">{{ (item.targetValue) }}</div>
                <div class="content-element-final-text">任务({{ item.termId == 'invite_job'?'人':'次' }})</div>
              </div>
              <div class="content-element-arrows">
                <img src="@/assets/task-management/task-setup-finance-arrows.png" alt="">
              </div>
            </div>
            <!-- 暂无任务 -->
            <div class="no-data" v-if="isData && list.length <= 0">
              <img src="@/assets/task-management/task-setup-nodata.png" alt="">
              <div>暂无任务</div>
            </div>
          </div>
        </van-tab>
        <van-tab title="历史任务" name="3">
          <div class="content" :class="userRole.roleCode=='ROLE_201262'?'':'content-height'" ref="scrollArea3" @scroll="handleScroll">
            <!-- 每一项任务 -->
            <div class="content-element" v-for="(item, index) in list" :key="index" @click="onClickTaskDetails(item)">
              <div class="content-element-icon">
                <img :src="require(`@/assets/task-management/human-resources-${item.termId.replaceAll('_','-')}-detail-icon.png`)" alt="">
              </div>
              <div class="content-element-review">
                <div class="content-element-review-text">{{item.termName}}</div>
                <div class="content-element-review-date">{{ item.taskDate }}</div>
                <div class="content-element-review-name">
                  <i>{{ item.userName }}</i>
                  <span style="margin-left: 20px;" v-if="item.termId == 'invite_job' ">
                  <span style="color: #0E8BFF;">{{ item.childTaskFinishCount }}/</span>{{ item.childTaskTotalCount }}</span>
                  <span style="margin-left: 20px;" v-if="item.termId == 'admin_inspection'||item.termId == 'dorm_inspection'">
                  <span style="color: #0E8BFF;">{{ item.finishValue }}/</span>{{ item.targetValue }}</span>
                </div>
              </div>
              <div class="content-element-final">
                <div class="content-element-final-count">{{ (item.finishRate).toFixed(2) }}%</div>
                <div class="content-element-final-text">{{ item.rateName}}</div>
              </div>
              <div class="content-element-arrows">
                <img src="@/assets/task-management/task-setup-finance-arrows.png" alt="">
              </div>
            </div>
            <!-- 暂无任务 -->
            <div class="no-data" v-if="isData && list.length <= 0">
              <img src="@/assets/task-management/task-setup-nodata.png" alt="">
              <div>暂无任务</div>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </main>

    <footer v-if="userRole.roleCode == 'ROLE_201262'">
      <div @click="createTask">添加</div>
    </footer>
  </div>
</template>

<script>
import api from "@/api/task-management/human-resources.js";
import { Toast,ImagePreview } from "vant";
import moment from "moment";

export default {
  data () {
    return {
      examineBtn:true,
      tabActive: 1,
      list:[],
      approvalCount:0,
      // 当前登录人角色
      userRole: {},
      page: 1, // 当前页码
      limit: 30, //条数
      isLoading: false, // 是否正在加载数据
      isData:false,
      dataLength:0,
    }
  },
  mounted() {
    localStorage.removeItem("SUB_TASK_OBJ")
    localStorage.removeItem("SUB_TASK_LIST")
    sessionStorage.removeItem("EXAMINE_LIST_SCROLL_TOP")
    let tabActiveObj = JSON.parse(localStorage.getItem('TAB_ACTIVE_OBJ'))||""
    if( tabActiveObj ){
      this.tabActive = tabActiveObj.tabActive
    }else{
      this.tabActive = 1
    }
    this.userRole = JSON.parse(localStorage.getItem("USER_ROLE"));
    if(this.userRole.roleCode == "ROLE_477142"){
      this.examineBtn = false
    }
    this.getData()
    this.getApprovalCount()
  },
  methods: {
    back () {
      this.$router.go(-1)
    },
    // 查询表格数据
    async getData () {
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      let params = {
        "monthType": this.tabActive,
        "page":this.page,
      }
      api.getTaskList(params).then((res)=>{
        if (res.code == 200) {
          Toast.clear()
          this.dataLength = res.data.length
          this.list.push(...res.data)
        }else{
          Toast.fail(res.msg)
        }
      }).finally(()=>{
        this.isData = this.list.length > 0?false:true
      })
    },
    // 审批数量查询
    getApprovalCount(){
      let params = {
        "taxonomy": "4",//4=人事；5=财务；6=预审；7=产品
      }
      api.getPendingApprovalCount(params).then((res)=>{
        if (res.code == 200) {
          this.approvalCount = res.data
        }else{
          Toast.fail(res.msg)
        }
      })
    },
    // 切换Tab
    onClickTab(name, title){
      localStorage.setItem("TAB_ACTIVE_OBJ",JSON.stringify({tabActive:name}))
      this.page = 1
      this.list = []
      this.$nextTick(() => {
        this.$refs['scrollArea'+name].scrollTop = 0 // 将滚动位置设置为 0
      })
      this.getData()
    },
    // 下拉 加载更多
    async handleScroll(e) {
      const el = e.target
      if ( (el.scrollTop + el.clientHeight)+1 >= el.scrollHeight && !this.isLoading && this.dataLength >= this.limit) {
        this.page++; // 加载下一页数据
        this.loading = true
        await this.getData();
        this.loading = false
      }
    },
    // 点击详情
    onClickTaskDetails (item) {
      this.$router.push({ 
        name: 'TaskSetupHumanResourcesTaskDetails', 
        query: {
          active: this.userRole.roleCode=='ROLE_477142'?'3':this.tabActive,
          item:JSON.stringify(item),
        }
      })
    },
    // 点击审批
    onClickExamine () {
      this.$router.push({ name: 'TaskSetupHumanResourcesExamineList' })
    },
    // 创建任务
    createTask(){
      this.$router.push({ name: 'TaskSetupHumanResourcesCreateTask' })
    },
  }
}
</script>

<style lang="scss" scoped>
.main::v-deep {
  height: 100vh;
  overflow: hidden;

  .van-tab {
    font-size: 17px;
    flex: none;
    width: 25%;
  }

  .van-tab--active {
    font-size: 18px;
  }

  .no-data {
    height: 100%;
    img {
      margin: 50% auto 0;
      width: 210px;
      height: 137px;
    }

    div {
      font-size: 16px;
      font-family: Bahnschrift, Bahnschrift;
      font-weight: 400;
      color: rgba(51, 51, 51, 0.5);
    }
  }

  .content {
    height: calc(100vh - 190px);
    background: #F5F5F5;
    padding-top: 8px;
    overflow: auto;

    .content-element {
      background: #ffffff;
      display: flex;
      // justify-content: space-between;
      align-items: center;
      padding: 16px 14px 17px;
      margin-bottom: 8px;
      width: 100%;
      box-sizing: border-box;
      // position: relative;

      .content-element-icon {
        margin-top: -48px;
        img {
          width: 24px;
          height: 24px;
          margin-right: 4px;
        }
      }

      .content-element-review {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        width: 50%;
        // height: 70px;
        margin-right: 20px;

        .content-element-review-text {
          color: #333333;
          font-size: 18px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          width: 100%;
          white-space: nowrap; /* 不换行 */
          overflow: hidden; /* 超出部分隐藏 */
          text-overflow: ellipsis; /* 显示省略号 */
          text-align: left;
        }
        .content-element-review-date{
          font-size: 14px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          color: #999999;
          margin: 5px 0;
        }

        .content-element-review-name {
          color: #999999;
          font-size: 14px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          text-align: left;
          width: 100%;
          display: flex;
          align-items: center;
          i{
            display: inline-block;
            font-style:normal;
            width: 40%;
            white-space: nowrap; /* 不换行 */
            overflow: hidden; /* 超出部分隐藏 */
            text-overflow: ellipsis; /* 显示省略号 */
          }
          span{
            font-size: 18px;
            font-family: Bahnschrift, Bahnschrift;
            font-weight: 400;
            color: #333333;
          }
        }
      }

      .content-element-final {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 44px;
        width: 70px;
        padding: 8px 16px;
        background-color: #F7F8FA;
        border-radius: 4px;

        .content-element-final-count {
          font-size: 18px;
          font-family: Bahnschrift, Bahnschrift;
          font-weight: 400;
          color: #0E8BFF;
        }

        .content-element-final-text {
          font-size: 14px;
          font-family: Bahnschrift, Bahnschrift;
          font-weight: 400;
          color: #999999;
        }
      }

      .content-element-arrows {
        // position: absolute;
        // right: 14px;
        margin-left: 8px;
        img {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
  
  .content-height{
    height: calc(100vh - 103px);
  }

  footer{
    div{
      width: 95%;
      height: 44px;
      background: #3975C6;
      border-radius: 4px 4px 4px 4px;
      margin: 12px auto 30px;
      font-size: 18px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 44px;
    }
  }

}
</style>