<template>
    <div class="projectplan-style">
        <headerNav title="查看" leftNav="返回" rightNav="操作记录" @leftClick="back" @rightClick="onToRecord"></headerNav>
        <div class="content-style">
            <pullRefresh ref="pullRefresh" @refresh="onRefresh">
                <van-list class="vant-list-style" v-model="loading" :finished="finished"
                    :finished-text="total ? '·已加载全部数据·' : '·暂无数据·'" @load="onLoad">
                    <van-swipe-cell v-for="(item, index) in list" :key="index" @click="e => onDeleteImg(e, item)">
                        <div class="item-style">
                            <div class="img-style">
                                <img :src="item.webUrl" alt="" style="width: 100%;height: 100%;"
                                    @click="photoPreview(item)">
                            </div>
                            <div class="item-center">
                                <div class="item-center-row">
                                    <div class="label">备注：</div>
                                    <div class="value">{{ item.remark }}</div>
                                </div>
                                <div class="item-center-row">
                                    <div class="label">上传人：</div>
                                    <div class="value">{{ item.createName }}</div>
                                </div>
                                <div class="item-center-row">
                                    <div class="label">上传时间：</div>
                                    <div class="value">{{ item.createDate }}</div>
                                </div>
                            </div>
                        </div>
                        <template #right>
                            <van-button square type="danger" text="删除" style="width: 100%;height: 100%;" />
                        </template>
                    </van-swipe-cell>
                </van-list>
            </pullRefresh>
        </div>
        <div class="buttons">
            <van-button type="info" text="上传" style="height:52px;border-radius: 4px;" block @click="onToFinishUp" />
            <!-- <van-uploader :after-read="afterRead" multiple></van-uploader> -->
        </div>
    </div>
</template>

<script>
import api from '@/api/api.js'
import { Dialog, Toast, ImagePreview, SwipeCell } from 'vant'
export default {
    data() {
        return {
            list: [],
            info: {},
            projectPlanInfo: {},
            isLoading: false,
            loading: false,
            finished: false,
            error: false, // 是否加载失败
            pageNum: 1, // 分页
            pageSize: 10, // 每页条数
            total: 0 // 数据总条数
        }
    },
    methods: {
        back() {
            sessionStorage.setItem('clientInfo', JSON.stringify(this.info))
            this.$router.push({
                name: "engineeringProjectPlanName",
            })
        },
        //获取列表
        async getList() {
            let obj = {
                Q_nodeId_EQ: this.projectPlanInfo.id,
                page: this.pageNum,
                limit: this.pageSize
            }
            let res = await api.checkProjectPlan(obj)
            if (res.code == 200) {
                const arr = res.data.list || [];
                this.total = arr.length;
                if (res.data.length === 0) { // 判断获取数据条数若等于0
                    this.list = []; // 清空数组
                    this.finished = true; // 停止加载
                } else {
                    // 若数据条数不等于0
                    this.list.push(...arr) // 将数据放入list中
                    // this.loading = false; // 加载状态结束 
                    // 如果list长度大于等于总数据条数,数据全部加载完成
                    if (this.list.length >= arr.length) {
                        this.finished = true; // 结束加载状态
                    }
                }
                if (this.$refs.pullRefresh) {
                    this.$refs.pullRefresh.refreshSuccess()
                }
            }
        },
        // 被 @load调用的方法
        onLoad() { // 若加载条到了底部
            let timer = setTimeout(() => { // 定时器仅针对本地数据渲染动画效果,项目中axios请求不需要定时器
                // this.getList(); // 调用上面方法,请求数据
                this.getList()
                this.pageNum++; // 分页数加一
                this.finished && clearTimeout(timer); //清除计时器
            }, 100);
        },
        // 加载失败调用方法
        onRefresh() {
            this.finished = false; // 清空列表数据
            this.loading = true; // 将 loading 设置为 true，表示处于加载状态
            this.pageNum = 1; // 分页数赋值为1
            this.list = []; // 清空数组
            this.onLoad(); // 重新加载数据
        },
        // 删除
        onDeleteImg(position, item) {
            if (position === 'right') {
                Dialog.confirm({
                    title: '删除',
                    message: '确认删除该图片吗？'
                }).then(async () => {
                    await api.checkProjectPlanDelte(item.id)
                    Toast.success('删除成功!');
                    this.onReset()
                    this.getList()
                })
            }
        },
        // 重置
        onReset() {
            this.pageNum = 1
            this.finished = false;
            this.loading = true;
            this.list = []
        },
        //图片预览
        photoPreview(item) {
            let o = {
                images: [item.webUrl],
                showIndex: false
            }
            ImagePreview(o);
        },
        onToRecord() {
            this.$router.push({
                name: "engineeringRecordProjectPlanName",
            })
        },
        onToFinishUp() {
            this.$router.push({
                name: "engineeringFinishUpProjectPlanName",
                query: {
                    typeCode: this.projectPlanInfo.nodeCode,
                    backPage: 'engineeringCheckProjectPlanName'
                }
            })
        },
        async afterRead(file) {
            let fileList = Array.isArray(file) ? file : [file]
            if (fileList.length > 9) {
                Toast.fail('最多一次上传九张图片！')
                fileList = fileList.splice(0, 9)
            }
            fileList.forEach((e, index) => {
                // 此时可以自行将文件上传至服务器
                let formData = new FormData();
                formData.append('files', e.file);
                formData.append('dir', 'oss');
                new Promise((reslove) => {
                    const res = api.upFile(formData)
                    reslove(res)
                }).then((res) => {
                    let obj = {
                        nodeId: this.projectPlanInfo.id,
                        list: [{ webUrl: res.url, nodeId: this.projectPlanInfo.id }],
                        remark: this.remark,
                        projectId: this.info.id
                    }
                    const res1 = api.projectPlanFinish(obj)
                    return res1
                }).then((res1) => {
                    if (res1.code == 200) {
                        Toast.success('上传成功')
                        this.onReset()
                        this.getList()
                    }
                })
            })
        },
    },
    mounted() {
        this.projectPlanInfo = JSON.parse(sessionStorage.getItem('projectPlanInfo'))
        this.info = JSON.parse(sessionStorage.getItem('clientInfo'))
    },
}
</script>

<style scoped lang="scss">
.projectplan-style {
    .content-style {
        overflow: auto;
        margin-top: 10px;

        .item-style {
            padding: 12px;
            display: flex;
            margin-bottom: 16px;
            background: #FFFFFF;

            .img-style {
                width: 72px;
                height: 72px;
                border-radius: 4px;
                overflow: hidden;
            }

            .item-center {
                font-size: 14px;
                flex: 1;
                margin-left: 12px;
                text-align: left;

                .item-center-row {
                    display: flex;

                    &:not(:first-child) {
                        margin-top: 4px;
                    }

                    .label {
                        color: #888;
                    }

                    .value {
                        flex: 1;
                    }
                }
            }

            .item-right {
                width: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: red;
            }
        }
    }

    ::v-deep .van-pull-refresh {
        min-height: calc(100vh - 122px);
    }

    .buttons {
        position: fixed;
        bottom: 0;
        width: calc(100% - 32px);
        padding: 5px 16px;
        display: flex;
        background-color: #fff;
        border-top: 1px solid #eee;

        &>div {
            flex: 1;
        }

        &>div:nth-child(2) {
            border-left: 1px solid #eee;
        }

        .van-uploader {
            width: 100%;
        }

        ::v-deep .van-uploader__input-wrapper {
            width: 100%;
        }
    }
}
</style>
