<template>
  <div class="content">
    <headerNav leftNav=" " :title="title" @leftClick="back"></headerNav>
    <main class="content-main">
      <!-- 步骤条 -->
      <TaskProgress :perform="{ taskGoalCount: (outputValue.productionJobValue||0).toFixed(2), percentageRate: (outputValue.finishRate||0) }" 
        :percentageMoney="(outputValue.finishValue||0).toFixed(2)" :unit="unit" :isShowDay="isShowDay == '1'?true:false" :monthProps="month"/>

        <div class="content-center-title">
          <div class="left-line"></div>
          <div>详情</div>
        </div>

        <el-table :data="tableData" style="width: 100%" header-cell-class-name="market-title"
          row-class-name="market-row" row-key="id">
          <template slot="empty">
            <div class="empty-no-data">
              <img src="../../../assets/task-management/no-data.png" alt="">
              <div>暂无数据</div>
            </div>
          </template>
          <el-table-column type="index" label="TOP" align="center" width="40">
            <template slot-scope="props">
              <div style="line-height: 1;" v-if="props.$index < 3">
                <img class="index-img"  :src="getImageIndex(props.$index)" alt="">
              </div>
              <div v-else> {{ props.$index + 1 }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="userName" label="人员" min-width="44">
            <template slot-scope="props">
              <div class="anomaly">
                <span class="market-content-a">{{ props.row.userName }}</span>
                <div class="anomaly-bg" v-if="props.row.isSpecial && props.row.isSpecial == '0'">异常</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="transferProductionValue" :label="outputCount.transferProductionValue" align="right" min-width="85">
          </el-table-column>
          <el-table-column prop="taskProductionValue" :label="outputCount.taskProductionValue" align="right" min-width="85">
          </el-table-column>
          <el-table-column prop="accomplishRate" label="完成率" align="right" min-width="67">
            <template slot-scope="scope">
              <span v-if="scope.row.accomplishRate">
                {{ scope.row.accomplishRate && scope.row.accomplishRate != '-' ? parseFloat(scope.row.accomplishRate).toFixed(2) + '%' : scope.row.accomplishRate }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="handle" label="操作" min-width="38" v-if="isShowDay!='3'">
            <template slot-scope="scope">
              <span :class="scope.row.updateStatus && scope.row.updateStatus == '0' && scope.row.isSpecial != '0'?'market-details':'market-details1'"  @click="setTask(scope.row)">修改</span>
            </template>
          </el-table-column>
        </el-table>

    </main>

    <!-- 任务设置 -->
    <van-dialog v-model="setTaskDialogVisible" :showConfirmButton="false" @close="setTaskDialogClose">
      <div class="set-task-dialog">
        <!-- 导航 -->
        <div class="set-task-dialog-nav">
          任务修改
          <div class="set-task-dialog-nav-close" @click="closeSetTaskDialog">×</div>
        </div>
        <!--内容  -->
        <div class="set-task-dialog-mian">
          <van-form @submit="onSubmitTask">
            <van-field v-model="taskDialogFrom.value" name="任务产值" label-width="80px" type="digit" :maxlength="maxlength"
              :label="outputCount.taskProductionValue?outputCount.taskProductionValue.slice(0, 4) + ':':''"
              :placeholder="outputCount.taskProductionValue?'请输入' + outputCount.taskProductionValue.slice(0, 4):''" :rules="[{ required: true }]">
              <i slot="left-icon" style="color:red">*</i>
              <template #button>{{ unit }}</template>
            </van-field>
            <div class="set-task-dialog-foot">
              <van-button class="btn" round block type="info" native-type="submit">确认</van-button>
            </div>
          </van-form>

        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { Toast } from 'vant';
import api from "@/api/task-management/setup.js";
import TaskProgress from "@/components/task-management/task-progress.vue";
import estimateOutput from "../component/data.js"
export default {
  components: {
    TaskProgress
  },
  data () {
    return {
      title:'产值详情',
      queryInfo:{},
      taskTypeList:[
        { text: "转单产值", value: "transfer_output", unit:'万',max:5 },
        { text: "补齐产值", value: "supply_output", unit:'万',max:5 },
        { text: "转单量", value: 'transfer_count', unit:'个',max:4 },
        { text: "补齐量", value: 'supply_count', unit:'个',max:4 },
        { text: "签单量", value: 'signed_count', unit:'个',max:4 },
        { text: "到店量", value: 'shop_count', unit:'个',max:4 },
        { text: "线索量", value: 'clue_count', unit:'个',max:4 },
      ],
      isShowDay:'',
      month:'',
      unit:"万",
      maxlength:4,
      // 产值详情 (进度条)
      outputValue:{},
      // 表格数据
      tableData: [],
      // 表头动态展示内容
      outputCount: {},
      // 任务设置当前行数据
      setRowsData: {},
      // 任务设置弹窗
      setTaskDialogVisible: false,
      taskDialogFrom: {
        value: ''
      },
      submitLoading: false
    }
  },
  created () {},
  mounted() {
    let _this = this;
    this.queryInfo = JSON.parse(this.$route.query.item)
    let {id,roleCode,termId,departmentName,marketCode,taskDate,productionJobValue,finishRate,finishValue} = this.queryInfo
    this.month = taskDate.split('-')[1]
    this.taskTypeList.forEach((item) => {
      if(item.value == termId){
        this.unit = item.unit
        this.maxlength = item.max
      }
    });
    this.isShowDay = this.$route.query.type 
    this.outputCount = estimateOutput(termId)
    this.title = this.$route.query.title
    this.getTableList()
  },
  methods: {
    back () {
      this.$router.go(-1)
    },
    // 获取图片
    getImageIndex (index) {
      return require(`@/assets/task-management/task-details-table-${index + 1}.png`)
    },
    // 任务设置
    setTask (row) {
      if(row.isSpecial && row.isSpecial== '0'){
        Toast("该人员已离开当前部门，不能修改任务")
        return false
      }
      if(!row.updateStatus || row.updateStatus != '0'){
        Toast("当前任务上级正在审批，不可修改")
        return false
      }
      this.setRowsData = row
      this.setTaskDialogVisible = true
      this.taskDialogFrom.value = Number(row.accomplishRatePx) || ''
      console.log(row); 
    },
    // 确定
    async onSubmitTask () {
      try {
        let obj = {
          id: this.queryInfo.id,
          taskDate: this.queryInfo.taskDate,
          roleCode: this.queryInfo.roleCode,
          termId: this.queryInfo.termId,
          multiTerm: JSON.stringify({ [this.setRowsData.userId]: this.taskDialogFrom.value })
        }
        const data = await api.setUserTasks(obj)
        if (data.code == 200) {
          this.getTableList()
          Toast('设置成功')
        }else{
          Toast.fail(data.msg)
        }
      } finally {
        this.setTaskDialogVisible = false
      }
    },
    // 关闭
    closeSetTaskDialog () {
      this.setTaskDialogVisible = false
    },
    setTaskDialogClose () {
      this.taskDialogFrom = {
        value: ''
      }
    },
    // 获取表格数据
    getTableList(){
      let params = {
        ext:this.queryInfo.termId,
        "Q_reportData_EQ":this.queryInfo.taskDate,
        type:"3",
        marketCode:this.queryInfo.marketCode,
        departmentName:this.queryInfo.departmentName
      }
      api.taskDetail(params).then((res)=>{
        Toast.loading({
          message: '加载中...',
          forbidClick: true,
        });
        if (res.code == 200) {
          Toast.clear()
          this.outputValue = {
            finishValue: Number(res.data.accomplishCondition.practicalAccomplish || 0),
            finishRate: Number(res.data.accomplishCondition.subordinateTaskAccomplishRate || 0),
            productionJobValue: Number(res.data.accomplishCondition.subordinateTask || 0),
            unit:this.unit
          }
          this.tableData = res.data.data01 || []
        }else{
          Toast.fail(res.msg)
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.content ::v-deep {

  .empty-no-data {
    img {
      width: 160px;
      height: 150px;
    }

    div {
      color: #5E5E5E;
      font-size: 14px;
      margin-top: -40px;
    }
  }

  .content-main {
    background: #F5F5F5;
    height: calc(100vh - 50px);
    overflow: auto;

    .content-center-title {
      height: 47px;
      line-height: 47px;
      display: flex;
      align-items: center;
      background: #fff;
      padding: 0px 10px;
      margin-top: 16px;
      position: relative;

      .left-line {
        width: 4px;
        height: 18px;
        background: #0E8BFF;
        margin-right: 8px;
      }

      div {
        color: #333333;
        font-size: 18px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: 550;
      }

      .right-button{
        width: 78px;
        line-height: 28px;
        background: #0E8BFF;
        border-radius: 4px 4px 4px 4px;
        font-size: 14px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #FFFFFF;
        position: absolute;
        right: 10px;
      }
    }

    .market-title {
      background-color: #F5F5F5;
      color: #1D2129;
      font-size: 14px;
      font-family: PingFang SC-Bold, PingFang SC;
      padding: 0px 0px;

      .cell {
        font-size: 13px;
        font-weight: 500;
        padding: 10px 3px;
      }
    }
    .el-table__body-wrapper {
      overflow: auto;
      height: calc(100vh - 150px);
    }
    .market-row {
      font-size: 16px;
      color: #1D2129;
      overflow: visible;

      .el-table__cell {
        padding: 3px 0px;
        height: 30px;
        overflow: visible;
      }

      .cell {
        font-weight: 400;
        padding: 5px 3px;
        white-space: nowrap;
        overflow: visible;
        text-overflow: ellipsis;
      }
    }

    .el-table__expand-colum {
      visibility: hidden;
    }

    .market-details {
      color: #0E8BFF;
      font-size: 13px;
    }
    .market-details1 {
      color: #999999;
      font-size: 13px;
    }

    .anomaly {
      position: relative;

      .market-content-a {
        font-size: 13px;
        font-weight: 400;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
      }
      .anomaly-bg {
        height: 19px;
        width: 39px;
        text-align: center;
        color: #fff;
        position: absolute;
        font-size: 12px;
        top: -8px;
        right: -25px;
        line-height: 19px;
        background: url('../../../assets/task-management/employee-list-anomaly.png') no-repeat;
        background-position: 100%;
        background-size: cover;
        z-index: 100;
      }
    }
    
    .index-img {
      width: 20px;
      height: 20px;
    }

    .market-content-expand {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 11px 20px;
      border-bottom: 1px solid #F1F1F1;

      .market-content-expand-left {
        display: flex;
        align-items: center;

        img {
          width: 20px;
          height: 20px;
        }

        div {
          color: #545454;
          font-size: 15px;
          margin-left: 10px;
        }
      }

      .market-content-expand-right {
        width: 46px;
        height: 22px;
        line-height: 22px;
        font-size: 13px;
        text-align: center;
        font-weight: bold;
        font-family: PingFang SC-Bold, PingFang SC;

        span {
          padding: 2px 3px;
        }

        .color-red {
          color: #FF6157;
          background: #FAEBEA;
        }

        .color-green {
          color: #17CA5E;
          background: #E3F5EA;
        }
      }
    }

    .market-content-expand-row {
      display: flex;
      justify-content: end;
      margin: 14px 11px 4px;

      .market-content-expand-seting {
        display: flex;
        align-items: center;
        width: 91px;
        height: 28px;
        line-height: 28px;
        border: 1px solid #3DA2FF;
        color: #0E8BFF;
        font-size: 14px;
        border-radius: 4px;
        padding-left: 6px;
        box-sizing: border-box;
        cursor: pointer;
        user-select: none;

        img {
          width: 18px;
          height: 18px;
          margin: 1px 2px 0px 0;
        }
      }
    }
  }

  .set-task-dialog {
    .set-task-dialog-nav {
      text-align: center;
      margin: 12px 0px;
      padding: 0 17px 12px 17px;
      font-weight: bold;
      color: #424242;
      font-size: 16px;
      border-bottom: 1px solid #DFDFDF;

      .set-task-dialog-nav-close {
        float: right;
        font-size: 15px;
      }
    }

    .set-task-dialog-mian {
      padding: 16px 0 0;

      .van-field__body {
        border-bottom: 1px solid #BEBEBE;
      }

      .van-field__label {
        margin-right: 0;
        color: #252523;
        font-size: 16px;
      }

      .van-cell::after {
        border: 0;
      }

      .set-task-dialog-foot {
        // width: 100%;
        text-align: center;
        padding: 0px 14px 18px 15px;
        margin-top: 16px;

        .btn {
          border-radius: 4px;
          // width: 290px;
          // height: 44px;
          background: #3975C6;
        }
      }

    }

  }

  .bottom-button{
    width: 100%;
    background: #FFFFFF;
    padding:12px 18px;
    box-sizing: border-box;
    & > div{
      line-height: 44px;
      background: #4871C0;
      border-radius: 8px 8px 8px 8px;
      font-size: 18px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      text-align: center;
    }
  }
}
</style>