<template>
  <div class="content market-content">
    <van-sticky class="topMenu">
      <headerNav
      leftNav=" "
      :title="targetObj.navTitle"
      @leftClick="back"
    ></headerNav>
    </van-sticky>
    <main class="content-main">
      <!-- 汇总 -->
      <BoardNavSummarize
        :imgBackUrlName="targetObj.imgUrl"
        :ratio="targetObj.value == 'market_summarize'?taskPlanObj.ysFinishRate:taskPlanObj.timelySumRate"
        :completionsCount="targetObj.value == 'market_summarize'?taskPlanObj.taskFine:taskPlanObj.timelyCompletionSum"
        :sumTotal="targetObj.value == 'market_summarize'?taskPlanObj.targetValue:taskPlanObj.cashierTotalSum"
       :showFoot="false"
        :targetName="targetObj.collect3 "
        :customerName="taskPlanObj.userName"
        :navTitleUnit="targetObj.value == 'market_summarize'? ' ':'%'"
      />
      <div class="content-center-title">
        <div class="left-line"></div>
        <div>任务结果上传记录</div>
      </div>

      <div class="content-element">
        <div class="content-element-main" v-for="(item,index) in upLoadList" :key="index">
          <div class="content-element-main-top">
            <div class="content-element-main-top-img">
              <img src="@/assets/task-management/task-board-city-upload.png" alt="" />
            </div>
            <div class="content-element-main-top-center">{{ item.createDate.replace(/\//g, ".").split(' ')[0] }}</div>
            <div class="content-element-main-top-left">{{ item.createDate.replace(/\//g, ".").split(' ')[1] }}</div>
            <div v-if="item.finalStatus == '0'" class="content-element-main-top-right content-element-main-top-right-orange">待审核</div>
            <div v-if="item.finalStatus == '1'" class="content-element-main-top-right content-element-main-top-right-green">已通过</div>
            <div v-if="item.finalStatus == '2'" class="content-element-main-top-right content-element-main-top-right-red">已驳回</div>
            <div v-if="item.finalStatus == '3'" class="content-element-main-top-right content-element-main-top-right-gay">已失效</div>
          </div>
          <div class="content-element-main-center">
            <div>附件(<span class="span_count">{{ (item.fileInfoList && item.fileInfoList.length) || 0 }}</span>)</div>
            <div class="accessory" v-for="(fileItem,fileIndex) in item.fileInfoList" :key="fileIndex">
              <img
                class="accessory-img"
                src="@/assets/task-management/task-setup-file-xlsx.png"
                alt=""
              />
              <div class="accessory-text" @click="onClickDownFile(fileItem)">{{ fileItem.fileName }}</div>
            </div>
          </div>
          <div class="content-element-main-bottom" @click="onClickRecord(item)">
            <div>查看详情</div>
            <img
              src="@/assets/task-management/task-setup-finance-arrows.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </main>
    <!-- 任务成果上传 -->
    <div class="footer-upload">
      <van-button class="btn" type="info" @click="taskUpload"
        >任务成果上传</van-button
      >
    </div>
  </div>
</template>

<script>
import BoardNavSummarize from "@/views/task-management/component/public/module/BoardNavSummarize.vue";
import { estimateOutput } from "./data.js";
import api from "@/api/task-management/finance.js";
import { Toast } from "vant";

export default {
  components: {
    BoardNavSummarize,
  },
  data() {
    return {
      // 任务看板路由接参
      routerData: {},
      // 当前指标
      targetObj: {},
      // 上传文件列表
      fileList: [],
      // 汇总信息
      taskPlanObj:{},
      // 上传记录
      upLoadList:[]
    };
  },
  created() {
    let localData = JSON.parse(localStorage.getItem("TABLE_HEADER_CONTENT"));
    let userData = JSON.parse(localStorage.getItem("MARKET_DATA"));
    this.routerData = { ...localData, ...userData };
    this.targetObj = estimateOutput(localData.termSlug);
    this.getTableData();
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    // 查询表格数据
    async getTableData() {
      try {
        Toast.loading({
          message: "加载中...",
          forbidClick: true,
        });
        const { data } = await api.getApproveList({
          ext: this.routerData.termSlug,
          Q_reportData_EQ: this.routerData.Q_reportData_EQ,
          type: "1",
          taxonomy: "5",
        });
        this.taskPlanObj = data.statisticsObject
        this.upLoadList = data.approveList
      } finally {
        Toast.clear();
      }
    },
    // 详情
    onClickRecord(item){
      localStorage.setItem('TASK_UPLOAD_RECORD', JSON.stringify(item))
      this.$router.push('/task-management/board/finance/task-upload-list')
    },
    // 上传
    taskUpload() {
      this.$router.push("/task-management/board/finance/task-upload");
    },
    downloadFile(fileUrl) {
      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = '';
      link.click();
    },
    // 下载文件
    onClickDownFile(file){
      let pre_prod_host = window.location.host == "m.hejiahejiazhuang.com" ?process.env.VUE_APP_BASE_RUL_PROD : process.env.VUE_APP_BASE_RUL_PRE
      let url = process.env.NODE_ENV === "production" ?pre_prod_host+process.env.VUE_APP_SYS_API: process.env.VUE_APP_SYS_API
      this.downloadFile(url+`/tools/oss/ali/downOSSFile/public?fileName=${file.fileUrl.split('/').pop()}&dir=oss`)
    } 
  },
};
</script>

<style lang="scss" scoped>
.market-content::v-deep {
  .content-main {
    background: #f5f5f5;
    height: calc(100vh - 70px);
    overflow: auto;
    margin-top: -1px;
    padding-bottom: 65px;

  }

  .content-center-title {
    height: 47px;
    line-height: 47px;
    display: flex;
    align-items: center;
    padding: 0px 10px;
    margin-top: 16px;

    .left-line {
      width: 4px;
      height: 18px;
      background: #0e8bff;
      margin-right: 8px;
    }

    div {
      color: #333333;
      font-size: 18px;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: 550;
    }
  }

  .content-element {
    height:calc(100vh - 160px);
    overflow: auto;
    .content-element-date {
      font-size: 13px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      color: #8c8c8c;
      margin: 7px 0px;
    }

    .content-element-main {
      background: #ffffff;
      border-radius: 8px;
      padding: 18px 12px 8px;
      margin-bottom: 16px;

      .content-element-main-top {
        display: flex;
        align-items: center;

        .content-element-main-top-left {
          width: 40px;
          height: 22px;
          font-size: 15px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #8c8c8c;
          line-height: 22px;
          background: #f9f9f9;
          border-radius: 3px;
        }
        .content-element-main-top-img {
          img {
            width: 24px;
            height: 24px;
            vertical-align: middle;
          }
        }
        .content-element-main-top-center {
          font-size: 21px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          color: #1d2129;
          margin: 0px 12px 0px 4px;
        }

        .content-element-main-top-right {
          width: 60px;
          height: 24px;
          line-height: 24px;
          border-radius: 4px;
          margin-left: auto;
          font-size: 13px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          color: #fff;
        }

        .content-element-main-top-right-green {
          background: #17ca5e;
        }

        .content-element-main-top-right-red {
          background: #ff6157;
        }

        .content-element-main-top-right-orange {
          background: #ff8c21;
        }

        .content-element-main-top-right-gay {
          background: #999999;
        }
      }

      .content-element-main-center {
        font-size: 14px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #545454;
        text-align: left;
        margin: 14px 0px;
        display: flex;
        justify-content: space-between;
        .span_count {
          color: #1d2129;
        }
        .accessory {
          display: flex;
          align-items: center;
          justify-content: flex-end;

          .accessory-img {
            width: 13px;
            height: 16px;
            margin-right: 4px;
          }

          .accessory-text {
            font-size: 12px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            color: #0084f4;
            max-width: 200px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }

      .content-element-main-bottom {
        border-top: 1px solid #ebedf0;
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #545454;
        padding-top: 10px;

        img {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
  .footer-upload {
    height: 66px;
    line-height: 50px;
    width: 100%;
    background: #fff;
    box-shadow: 0px -1px 8px 0px rgba(0, 0, 0, 0.04);
    text-align: center;
    position: fixed;
    bottom: 0px;
    left: 0px;
    .btn {
      background: #3975c6;
      color: #ffffff;
      border-radius: 4px;
      font-size: 16px;
      font-weight: 500;
      width: 340px;
    }
  }
}
</style>
