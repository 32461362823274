// php
import Vue from 'vue'
import * as API from "./index.js";
import {
    buildParam
} from '@/utils/index'
const BaseApi = process.env.VUE_APP_BASE_API
const SysApi = process.env.VUE_APP_SYS_API

// 字典
const dictItem='/backend/dictItem/r02'

// 当月数据
const monthTable = '/report/advertisingMarket/list'
// 线索图标
const clueEchart = '/report/advertisingMarket/chartList'

// 市场费效比
const costEffectivenessRatio = '/report/president/list01'
// 产值详细数据
const outputData = '/report/president/list02'
// 市场图表数据
const marketEchart = '/report/advertisingMarket/mediaChartList'

const channelEchart = '/report/advertisingMarket/channelChartList'

// ---广告部-------
// 数据指标 
const indicators = '/report/adBoard/list01'
// 渠道
const channelData  = '/report/adBoard/list02'
// 部门
const departmentData  = '/report/adBoard/list03'
// 运营人
const operateData  = '/report/adBoard/list04'

export default {

    // 字典查询
    dictItem:(params) => {
        buildParam(params)
        return API.POST(`${SysApi}${dictItem}`, params)
    },
    // 当月数据
    monthTableApi:(params)=>{
        buildParam(params)
        return API.POST(`${BaseApi}${monthTable}`, params)
    },
    // 线索图标
    clueEchartApi:(params)=>{
        buildParam(params)
        return API.POST(`${BaseApi}${clueEchart}`, params)
    },

    // 市场费效比
    costEffectivenessRatioApi: (params) => {
        buildParam(params)
        return API.POST(`${BaseApi}${costEffectivenessRatio}`, params)
    },
    // 产值详细数据
    outputDataApi: (params) => {
        buildParam(params)
        return API.POST(`${BaseApi}${outputData}`, params)
    },
    // 市场图表数据
    marketEchartApi: (params) => {
        buildParam(params)
        return API.POST(`${BaseApi}${marketEchart}`, params)
    },
    // 渠道图表数据
    channelEchartApi: (params) => {
        buildParam(params)
        return API.POST(`${BaseApi}${channelEchart}`, params)
    },



    // ---广告部-------
    indicatorsApi: (params) => {
        return API.POST(`${BaseApi}${indicators}`, params)
    },
    channelDataApi: (params) => {
        buildParam(params)
        return API.POST(`${BaseApi}${channelData}`, params)
    },
    departmentDataApi: (params) => {
        buildParam(params)
        return API.POST(`${BaseApi}${departmentData}`, params)
    },
    operateDataApi: (params) => {
        buildParam(params)
        return API.POST(`${BaseApi}${operateData}`, params)
    },
}
