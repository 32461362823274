<template>
    <div class="main_box">
        <headerNav title="消息详情" @leftClick="back"></headerNav>
        <div class="height_style">
            <div class="info_main">
                <div class="info_nav">
                    <div class="info_left">{{ customerDetails.consumerName && customerDetails.consumerName.length > 2 ?
                        customerDetails.consumerName.substring(0, 2) : customerDetails.consumerName }}
                    </div>
                    <div class="info_right">
                        <div class="right_flex">
                            <div class="right_a">{{ customerDetails.consumerName }}</div>
                        </div>
                        <div class="right_bottom">
                            <span class="span_back">编号</span>
                            <span class="span_code">{{ customerDetails.contract }}</span>
                        </div>
                    </div>
                </div>
                <div class="info_content">
                    <div class="cont_nav">
                        <div class="cont_mian fenge">
                            <div class="cont_main_top">{{ customerDetails.orderTypeName }}</div>
                            <div class="cont_main_bottom">订单类型</div>
                        </div>
                        <div class="cont_mian fenge">
                            <div class="cont_main_top">{{ customerDetails.amount }}</div>
                            <div class="cont_main_bottom">总金额</div>
                        </div>
                        <div class="cont_mian fenge">
                            <div class="cont_main_top">{{ customerDetails.orderStatusName }}</div>
                            <div class="cont_main_bottom">状态</div>
                        </div>
                        <div class="cont_mian">
                            <div class="cont_main_top">{{ customerDetails.receiptStatus == '1'?'已完成':'未完成' }}</div>
                            <div class="cont_main_bottom">收款状态</div>
                        </div>
                    </div>
                    <div class="cont_main" v-if="isSub">
                        <div class="cont_flex">
                            <div class="flex_a">提交日期:</div>
                            <div class="flex_b">{{ customerDetails.submitDate }}</div>
                        </div>
                        <div class="cont_flex">
                            <div class="flex_a">创建日期:</div>
                            <div class="flex_b">{{ customerDetails.createDate }}</div>
                        </div>
                        <div class="cont_flex">
                            <div class="flex_a">收款进度:</div>
                            <div class="flex_b">{{ customerDetails.receiptProcess }}</div>
                        </div>
                        <div class="cont_flex">
                            <div class="flex_a">订单编号:</div>
                            <div class="flex_b">{{ customerDetails.orderCode }}</div>
                        </div>
                        <div class="cont_flex">
                            <div class="flex_a">是否下单/分包单:</div>
                            <div class="flex_b">{{ customerDetails.isAllot == '1'?"是":'否' }}</div>
                        </div>
                        <div class="cont_flex">
                            <div class="flex_a">制单人:</div>
                            <div class="flex_b">{{ customerDetails.createName }}</div>
                        </div>
                    </div>
                    <div class="content_bottom" @click="handleChangeSub">
                        <div class="flex">
                            {{ isSub ? '收起更多' : '查看更多' }}
                            <img :class="isSub?'':'jiantou'" :src="require('@/assets/sqgdpng.png')" alt=""
                                style="width:12px;height:12px;margin-left:4px">
                        </div>
                    </div>
                </div>
            </div>
            <div class="bottom_table">
                <div class="table_mian">
                    <table>
                        <thead>
                            <tr class="tr_th">
                                <th class="fixed_column" style="width:150px;text-align:left;padding-left:15px">项目名称</th>
                                <th style="width:160px;text-align:left;padding-left:15px">材料/定额编号</th>
                                <th style="width:145px;text-align:left;padding-left:15px">供应商</th>
                                <th style="width:60px;text-align:left;padding-left:15px">单位</th>
                                <th style="width:60px;text-align:right;padding-left:15px">增减量</th>
                                <th style="width:60px;text-align:left;padding-left:15px">单价</th>
                                <th style="width:70px;text-align:left;padding-left:15px">合计价格</th>
                                <th style="width:145px;text-align:left;padding-left:15px">说明</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in dataTable" class="main_tr" :key="'rol1-' + index">
                                <!-- 项目名称 -->
                                <td class="fixed_column_td" style="text-align:left;padding-left:15px">{{ item.itemName }}
                                </td>
                                <!-- 材料编号 -->
                                <td style="text-align:left;padding-left:15px">{{ item.itemNo }}</td>
                                <!-- 供应商 -->
                                <td style="text-align:left;padding-left:15px">{{ item.supplierName }}</td>
                                <!-- 单位 -->
                                <td style="text-align:left;padding-left:15px">{{ item.itemUnit }}</td>
                                <!-- 增减量 -->
                                <td style="text-align:right;padding-left:15px" :class="Number(item.amount) < 0?'isred':''">{{ item.amount }}</td>
                                <!-- 单价 -->
                                <td style="text-align:left;padding-left:15px">{{ item.sellingPrice }}</td>
                                <!-- 合计价 -->
                                <td style="text-align:left;padding-left:15px">{{ item.sellingTotal }}</td>
                                <!-- 备注 -->
                                <td style="text-align:left;padding-left:15px">{{ item.remark }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="bottom">
                <div class="bottom_span">
                    <p>确认下单后,</p>
                    <p>将直接对项目经理或供应商下对应<span>【分包单】</span>及<span>【确认单】</span></p>
                </div>
                <van-button type="info" class="bottom_btn" :loading="placeLoading" :disabled="isHandlePlace.isOperated != '0'" @click="handlePlaceOrder">{{isHandlePlace.isOperated == '0'?'确认下单':'已确认'}}</van-button>
            </div>
        </div>
    </div>
</template>

<script>
import { Toast } from 'vant'
import api from '@/api/api.js'
import api2 from '@/api/dataBoard.js'

export default {
    components: {
    },
    data () {
        return {
            id: '',
            logId: '',
            isSub: false,
            dataTable: [],
            // 详情传值
            customerDetails: {},
            // 控制确认下单按钮
            isHandlePlace:{},
            // 确认下单按钮loading
            placeLoading:false
        }
    },
    methods: {
        back () {
            this.$router.go(-1)
        },
        // 切换收起展开
        handleChangeSub () {
            this.isSub = !this.isSub
        },
        // 查询详情
        async handleDetails () {
            const data = await api.queryDetail(this.id)
            this.customerDetails = data.data
        },
        // 查询表格数据
        async handleTableData () {
            const tableData = await api.queryTable(this.id)
            this.dataTable = tableData.data
        },
        // 查询是否可以下单
        async IsHandlePlaceOrder(){
            const isplace = await api.isPlaceOrder(this.logId)
            this.isHandlePlace = isplace.data
        },
        // 确认下单
        async handlePlaceOrder (name) {
            try{
                this.placeLoading = true
                await api.subContact([this.id])
                Toast.success('操作成功！')
                this.IsHandlePlaceOrder()
            }finally{
                this.placeLoading = false
            }
        },
    },
    mounted () {

    },
    created () {
        this.id = this.$route.params.id
        this.logId = this.$route.params.logId
        this.handleDetails()
        this.IsHandlePlaceOrder()
        this.handleTableData()
    }
}
</script>

<style lang="scss" src="./Component/component.scss"></style>
<style lang="scss" scoped>
.height_style{
    height: 77vh;
    overflow: auto;
}
.bottom_span{
    span{
        color: #333333;
        font-family: PingFangSC-Regular, PingFang SC;
    }
}
</style>