<template>
    <div class="messageInfo-box">
        <headerNav title="消息详情" />
        <div :class="msgInfo.allocateStatusName != '待审核' ? 'show_main_content' : 'main_content'">
            <!-- 申请财务放款 -->
            <div class="main_cont">
                <p class="p_1"><span class="span_p">合同号</span>{{ msgInfo.finaleContract }}-273076</p>
                <p class="p_2">
                    <span class="span_left">{{ msgConent.messageSuggest }}</span>
                    <!-- <van-tag plain color="#3CC593" size="medium">审核通过</van-tag>
                    <van-tag plain color="#FF2D2D" v-show="false" size="medium">审核未通过</van-tag> -->
                </p>
            </div>
            <div class="content_info">
                <van-collapse v-model="activeNames">
                    <van-collapse-item title="基本信息" name="1">
                        <div class="cont_div">
                            <div class="cont_label">申请日期</div>
                            <div class="cont_value">{{ msgInfo.allocateTime }}</div>
                        </div>
                        <div class="cont_div">
                            <div class="cont_label">单据编号</div>
                            <div class="cont_value">{{ msgInfo.allocateNo }}</div>
                        </div>
                        <div class="cont_div">
                            <div class="cont_label">下拨阶段</div>
                            <div class="cont_value">{{ msgInfo.nodeName }}</div>
                        </div>
                        <div class="cont_div">
                            <div class="cont_label">申请金额(元)</div>
                            <div class="cont_value">{{ msgInfo.allocateFee }}</div>
                        </div>
                        <div class="cont_div">
                            <div class="cont_label">放款金额(元)</div>
                            <div class="cont_value">{{ msgInfo.grantFee }}</div>
                        </div>
                        <div class="cont_div">
                            <div class="cont_label">客户</div>
                            <div class="cont_value">{{ msgInfo.consumerName }}</div>
                        </div>
                        <div class="cont_div">
                            <div class="cont_label">申请人</div>
                            <div class="cont_value">{{ msgInfo.appName }}</div>
                        </div>
                        <div class="cont_div">
                            <div class="cont_label">项目经理</div>
                            <div class="cont_value">{{ msgInfo.managerName }}</div>
                        </div>
                        <div class="cont_div">
                            <div class="cont_label">下拨状态</div>
                            <div class="cont_value" :class="msgInfo.allocateStatusName == '申请中' || msgInfo.allocateStatusName == '待审核'?'color_status_orange': msgInfo.allocateStatusName == '已放款'?'color_status_blue':'color_status_red'">{{ msgInfo.allocateStatusName }}</div>
                        </div>
                        <div class="cont_div">
                            <div class="cont_label">放款备注</div>
                            <div class="cont_value">{{ msgInfo.allocateRemark }}</div>
                        </div>
                    </van-collapse-item>
                </van-collapse>
            </div>
            <van-cell-group :class="msgInfo.allocateStatusName == '待审核' ? 'money_style' : 'money_style1'">
                <van-field v-model="paymentMoney" label="下拨付款金额" clearable input-align="right" @blur="handleChange"/>
            </van-cell-group>
            <div class="check_opinion">
                <div>备注:</div>
                <div v-if="msgInfo.allocateStatusName == '待审核'">
                    <van-field v-model="checkRemark" class="input_style" rows="2" autosize type="textarea" maxlength="200"
                        placeholder="请输入" show-word-limit/>
                </div>
                <div v-else class="remark_bottom">
                    {{ msgInfo.remark }}
                </div>
            </div>
        </div>
        <div v-show="msgInfo.allocateStatusName == '待审核'">
            <van-button type="info" class="bottom_btn" @click="onSubmit">确认</van-button>
        </div>
    </div>
</template>
<script>
import api from '@/api/api.js'
import api2 from '@/api/dataBoard.js'
import { Toast,Notify } from 'vant';
import AcceptanceSubcontract from './Component/AcceptanceSubcontract.vue'
export default {
    components: {
        AcceptanceSubcontract
    },
    data () {
        return {
            id: '',
            logId: '',
            msgInfo: {},
            // 消息标题
            msgConent: {},
            activeNames: [''],
            radio: '',
            fileList: [],
            remark: '',
            // 控制显示更多图片
            showMoreImg: false,
            // 审核意见
            checkRemark: '',
            // 控制是否点击确认
            isClickOk: false,
            // 下拨付款金额
            paymentMoney: ''
        }
    },
    methods: {
        async onSearch () {
            const { data } = await api.searchmsg(this.id)
            let res = await api2.messageApi(this.logId)
            this.msgConent = res.data
            this.msgInfo = data
            this.paymentMoney = this.msgInfo.allocateFee
            
            console.log(data,this.paymentMoney )
        },
        async onSubmit () {
            let obj ={
                id:this.msgInfo.id,
                orderId:this.msgInfo.orderId,
                nodeCode:this.msgInfo.nodeCode,
                grantFee:this.paymentMoney,
                remark:this.checkRemark
            }
            const data = await api.financeLoan(obj)
            if(data.code == 200){
                Toast.success('放款成功！')
                this.onSearch()
            }
        },
        handleChange(e){
            let {value} = e.target
            const regex = /^[0-9]+(\.[0-9]+)?$/;
            if(!regex.test(value)){
                Notify('只能输入数字');
                this.paymentMoney = ''
            }
            
        },
    },
    mounted () {

    },
    created () {
        this.id = this.$route.params.id
        this.logId = this.$route.params.logId
        this.onSearch()
    }
}
</script>

<style scoped lang="scss">
.messageInfo-box {
    width: 100%;
    overflow: hidden;

    .main_content {
        width: 100%;
        overflow-x: auto;
        height: 83vh;
        background: #F5F5F5;

    }

    .show_main_content {
        height: calc(100vh - 50px);
        width: 100%;
        overflow-x: auto;
        background: #F5F5F5;
    }
}

.main_cont {
    background: #fff;
    height: 50px;
    padding: 16px;
    text-align: left;
    margin-bottom: 8px;

    .p_1 {
        margin: 0px 0px 8px 0px;
        color: #999999;
        font-size: 14px;
        display: flex;
        align-items: center;

        .span_p {
            width: 44px;
            text-align: center;
            height: 18px;
            background: #ECF2FA;
            color: #3975C6;
            font-size: 12px;
            margin-right: 12px;
            line-height: 18px;
            padding: 2px;
        }
    }

    .p_2 {
        display: flex;
        justify-content: space-between;
        margin: 0px;

        .span_left {
            color: #000000;
            font-size: 16px;
            font-weight: 600;
        }
    }
}

.content_info {
    text-align: left;



    .cont_div {
        display: flex;
        margin-bottom: 24px;

        .cont_label {
            flex: 3;
        }

        .cont_value {
            flex: 7;
            color: #000000;
        }
    }

    .cont_lastdiv {
        margin-bottom: 12px;
    }

}

.bottom_btn {
    background: #3A72C6;
    margin: 20px 0px;
    width: 295px;
    border-radius: 10px
}

.check_opinion {
    text-align: left;
    margin-top: 8px;
    background: #fff;
    padding: 10px 16px;
    font-size: 16px;
    color: #333333;

    .input_style {
        margin-top: 8px;
        padding: 8px;
        border-radius: 5px;
        background-color: #F8FAFF;
        font-weight: 400;
        margin-bottom: 8px;
    }

    .remark_bottom {
        margin-top: 8px;
    }
}

.money_style {
    margin-top: 8px;
    font-size: 16px;
    font-weight: 400;
    color: #333333;

    .van-cell {
        &::before {
            position: absolute;
            left: 8px;
            color: #ee0a24;
            font-size: 14px;
            content: '*';
        }
    }
}

.money_style1 {
    margin-top: 8px;
    font-size: 16px;
    font-weight: 400;
    color: #333333;
}
.color_status_orange{
    color: #F7B500 !important;
}
.color_status_blue{
    color: #2569C3 !important;
}
.color_status_red{
    color: #FF2D2D !important;
}
</style>

