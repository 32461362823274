<template>
  <div class="content">
    <headerNav leftNav=" " :title="navTitle + '详情'" @leftClick="back"></headerNav>
    <main class="content-main">
      <!-- 步骤条 -->
      <TaskProgress
        :perform="{ taskGoalCount: taskPlanObj.superiorsTask, percentageRate: Number(taskPlanObj.superiorsAccomplishRate || 0) }"
        :percentageMoney="taskPlanObj.practicalAccomplish" :customerName="routerData.userName"
        :isSuccess="routerData.isSpecial && routerData.isSpecial == '0'"
        :unit="routerData.termSlug == 'transfer_output' || routerData.termSlug == 'supply_output' ? '万' : '个'" />

      <!-- 个人详情 -->
      <div class="content-center-title">
        <div class="content-center-nav">
          <div class="content-center-title-left">
            <div class="left-line-style"></div>
            <div>{{ routerData.userName ? routerData.userName : '' }}详情</div>
          </div>
          <div class="content-center-title-right">
            <div class="content-center-title-right-day" :class="fliterType == 'day' ? 'active' : ''"
              @click="onClickChangeFliter('day')">近7天</div>
            <div class="content-center-title-right-month" :class="fliterType == 'month' ? 'active' : ''"
              @click="onClickChangeFliter('month')">本月</div>
          </div>
        </div>

        <!-- 图表 -->
        <div class="personage-charts" v-if="echartsData"></div>
      </div>

    </main>


  </div>
</template>

<script>
import TaskProgress from "@/components/task-management/task-progress.vue"
import estimateOutput from "../../component/data.js"
import * as echarts from 'echarts';
import api from "@/api/task-management/board.js";
import { Toast } from "vant";

export default {
  components: {
    TaskProgress
  },
  data () {
    return {
      fliterType: "day",
      // 路由接参
      routerData: {},
      // 顶部进度条数据
      taskPlanObj: {},
      // 7天的当月的图表数据
      echartsData: {},
    }
  },
  created () {
    let localData = localStorage.getItem('TABLE_HEADER_CONTENT') ? JSON.parse(localStorage.getItem('TABLE_HEADER_CONTENT')) : {}
    let userData = localStorage.getItem('USER_DATA') ? JSON.parse(localStorage.getItem('USER_DATA')) : {}
    let route = this.$route.query.item ? JSON.parse(this.$route.query.item) : {}
    this.routerData = { ...localData, ...userData, ...route }
    if (localData.termSlug) {
      this.outputCount = estimateOutput(localData.termSlug)
    }
    this.navTitle = this.outputCount ? this.outputCount.transferProductionValue.slice(0, 4) : ''
    this.getTableData()
  },
  mounted () {
    // this.setPersonageEcharts()
  },
  methods: {
    back () {
      this.$router.go(-1)
    },
    // 查询表格数据
    async getTableData () {
      try {
        Toast.loading({
          message: '加载中...',
          forbidClick: true,
        });
        const { data } = await api.taskDetailApi({ ext: this.routerData.termSlug, Q_reportData_EQ: this.routerData.Q_reportData_EQ, type: '4', marketCode: this.routerData.marketCode, departmentName: this.routerData.departmentName, userId: this.routerData.userId })
        this.taskPlanObj = data.accomplishCondition ? data.accomplishCondition : {}
        data.sevenTimeList[data.sevenTimeList.length - 2] = '昨日';
        data.sevenTimeList[data.sevenTimeList.length - 1] = '今日';
        if (data && data.timeList.length) {
          data.timeList = this.dateMonthChange(data.timeList)
        }
        this.echartsData = data
        if (data) {
          this.setPersonageEcharts(data.sevenTimeList, data.sevenDataList, 0)
        }

      } finally {
        Toast.clear()
      }
    },
    // 切换筛选
    onClickChangeFliter (name) {
      this.fliterType = name
      if (!this.echartsData.sevenTimeList) {
        return
      }
      if (name == 'day') {
        this.setPersonageEcharts(this.echartsData.sevenTimeList, this.echartsData.sevenDataList, 0)
      } else {
        this.setPersonageEcharts(this.echartsData.timeList, this.echartsData.dataList, 30)
      }
    },
    // 调整日期格式
    dateMonthChange (data) {
      let modifiedArray = data.map(item => {
        let parts = item.split("-");
        return `${parts[0]}.${parts[1]}-${parts[2]}.${parts[3]}`;
      });
      return modifiedArray
    },
    // 设置图表内容
    setPersonageEcharts (x, y, rotate = 0) {
      let _this = this
      let option = {
        title: {
          subtext: `(${_this.routerData.termSlug == 'transfer_output' || _this.routerData.termSlug == 'supply_output' ? '万元' : '个'})`,
          top: 0,
          left: -5,
        },
        tooltip: {
          confine: true,
          trigger: 'axis',
          backgroundColor: '#3DA2FF',
          borderColor: '#87C5FF',
          textStyle: {
            color: '#fff',
          },
          position: 'top',
          triggerEvent: true,
          axisPointer: {
            type: 'shadow',
          },

          formatter: function (params) {
            let cHtml = `
              <div style="font-size:14px;display:flex;align-items:center">
                <span style="width: 10px;height: 10px; border-radius: 50%;border: 1px solid #fff;background:#FFA73F;display:inline-block;"></span>  
                <span style="margin-left:5px">${estimateOutput(_this.routerData.termSlug).transferProductionValue.slice(0, 4)}:</span>  
                <span style="margin-left:20px;font-size:17px">${params[0].data} ${_this.routerData.termSlug == 'transfer_output' || _this.routerData.termSlug == 'supply_output' ? '万元' : '个'}</span>  
              </div>
            `
            var content = cHtml
            return content;
          }
        },
        grid: {
          left: '2%',
          right: '0%',
          top: '15%',
          bottom: this.fliterType == 'day' ? '15%' : '4%',
          containLabel: true,
          splitLine: {
            lineStyle: {
              color: '#999'  // 设置横线的颜色为灰色
            }
          }
        },
        legend: {
          data: [this.navTitle],
          right: 0,
          itemWidth: 8,
          itemHeight: 8,
          top: 10,
        },
        xAxis: {
          type: 'category',
          data: x,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            rotate: rotate,  // 旋转角度
            textStyle: {
              fontSize: 11,  // 设置 x 轴标签的字体大小为 14
              fontWeight: 'bold'
            }
          },
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: {
              color: '#F5F5F5'
            }
          },
          axisLabel: {
            textStyle: {
              fontSize: 11,  // 设置 y 轴标签的字体大小为 14
              fontWeight: 'bold'
            }
          }
        },
        series: [
          {
            name: this.navTitle,
            data: y,
            type: 'bar',
            barWidth: 14,
            color: '#FFA73F',
          }
        ]
      };

      this.applyEcharts('.personage-charts', option)
    },
    // 渲染echarts
    applyEcharts (name, option) {

      var chartDom = document.querySelector(name);
      var myChart = echarts.init(chartDom, 'night');
      option && myChart.setOption(option);
    },

  }
}
</script>

<style lang="scss" scoped>
.content ::v-deep {

  .content-main {
    background: #F5F5F5;
    height: calc(100vh - 50px);
    overflow: auto;
    margin-top: -1px;

    .content-center-title {
      background: #fff;
      border-radius: 4px;
      padding: 0px 12px;

      .content-center-nav {
        height: 47px;
        line-height: 47px;
        display: flex;
        align-items: center;
        margin-top: 16px;
        justify-content: space-between;

        .content-center-title-left {
          display: flex;
          align-items: center;

          .left-line-style {
            width: 4px;
            height: 18px;
            background: #0E8BFF;
            margin-right: 8px;

          }

          div {
            color: #333333;
            font-size: 18px;
            font-family: PingFang SC-Bold, PingFang SC;
            font-weight: 550;
          }
        }

        .content-center-title-right {
          display: flex;
          align-items: center;

          div {
            border: 1px solid #D9D9D9;
            font-size: 14px;
            color: #333333;
            padding: 2px 14px;
            height: 22px;
            line-height: 22px;
          }

          .active {
            color: #0E8BFF;
            font-weight: bold;
          }

          .content-center-title-right-day {
            border-radius: 4px 0 0 4px;
            border-right: 0;
          }

          .content-center-title-right-month {
            border-radius: 0 4px 4px 0px;
          }
        }

      }

      .personage-charts {
        height: 290px;
        width: 99.8%;
      }
    }


  }

}
</style>