<template>
	<div class="extantDanger-style">
		<van-tabs v-model="active" @change="tabsChange">
			<van-tab title="待审核" name="1">
				<template #title> 
					<v-touch style="width: 100%;" @swipeleft="swiperleft" @swiperight="swiperright">
						<span class="extantDanger-title extantDanger-title2">待审核 
							<span v-if="totalAudit">·{{totalAudit}}</span> 
						</span> 
					</v-touch>
				</template>
				<div class="audit-content">
					<pullRefresh ref="pullRefresh" @refresh="onRefresh">
						<van-list class="vant-list-style" v-model="loading" :finished="finished"
							:finished-text="total?'·已加载全部数据·':'·暂无数据·'" @load="onLoad">
							<div class="list-item" v-for="item in list">
								<div class="amedt-title">
									<van-tag class="label-style-dark-color" color="#FC426B " type="primary">待审核
									</van-tag>
									{{item.contentName}}
								</div>
								<div class="item-style">
									<div class="amedt-item-title">巡检人：</div>
									<div class="amedt-item-text">{{item.createId}}</div>
								</div>
								<div class="item-style">
									<div class="amedt-item-title">是否合格：</div>
									<div class="amedt-item-text">{{item.qualifiedStatus=='1'||item.qualifiedStatus=='2'?'是':'否'}}</div>
								</div>
								<div class="item-style">
									<div class="amedt-item-title">巡检阶段：</div>
									<div class="amedt-item-text">{{item.contentName}}</div>
								</div>
								<div class="item-style">
									<div class="amedt-item-title">提交时间：</div>
									<div class="amedt-item-text">{{item.createDate}}</div>
								</div>
								<div class="item-style item-style-img">
									<div class="amedt-item-title">现场照片：</div>
									<div class="amedt-item-text amedt-item-img">
										<div style="display: flex;">
											<img @click="photoPreview(item.imgs,itemIngIndex)"
												v-for="(itemIng,itemIngIndex) in item.imgs" :src="itemIng.webUrl" alt="">
										</div>
									</div>
									<div class="boxshow-style"></div>
								</div>
								<div class="item-style item-style-img" style="border: none;">
									<div class="amedt-item-title">备注信息：</div>
									<div class="amedt-item-text amedt-item-remark">{{item.remark}}</div>
								</div>
								<div class="amend-after-style" v-if="item.reformDTO">
									<img src="@/assets/zgh.png" alt="" class="zgh-img">
									<div class="item-style">
										<div class="amedt-item-title">整改人：</div>
										<div class="amedt-item-text">{{item.reformDTO.createId}}</div>
									</div>
									<div class="item-style item-style-img">
										<div class="amedt-item-title">整改照片：</div>
										<div class="amedt-item-text amedt-item-img">
											<div style="display: flex;">
												<img @click="photoPreview(item.reformDTO.imgDTOS,itemIngIndex2)"
													v-for="(itemIng2,itemIngIndex2) in item.reformDTO.imgDTOS"
													:src="itemIng2.webUrl" alt="">
											</div>
										</div>
										<div class="boxshow-style"></div>
									</div>
									<div class="item-style item-style-img" style="border: none;">
										<div class="amedt-item-title">备注信息：</div>
										<div class="amedt-item-text amedt-item-remark">
											{{item.reformDTO.remark}}
										</div>
									</div>
								</div>
								<div class="item-bottom-style">
									<span class="zgjl-style" v-if="item.reformDTO"
										@click="toAmendRecord(item)">
										<img src="@/assets/zgjl.png" alt="" class="shty-img">
										整改记录
									</span>
									<span class="ty-style" @click="auditAgree(item)">
										<img src="@/assets/shti.png" alt="" class="shty-img">
										同意
									</span>
									<span class="jj-style" @click="resulse(item)">
										<img src="@/assets/shjj.png" alt="" class="shjj-img">
										拒绝
									</span>
								</div>
							</div>
						</van-list>
					</pullRefresh>
				</div>
			</van-tab>
			<van-tab title="整改中" name="2">
				<template #title>
					<v-touch style="width: 100%;" @swipeleft="swiperleft" @swiperight="swiperright">
						<span class="extantDanger-title">整改中</span> 
					</v-touch>
				</template>
				<div class="abarbeitung-content">
					<pullRefresh ref="pullRefresh" @refresh="onRefresh">
						<van-list class="vant-list-style" v-model="loading" :finished="finished"
							:finished-text="total?'·已加载全部数据·':'·暂无数据·'" @load="onLoad">
							<div class="list-item" v-for="(item,idx) in list">
								<v-touch @swipeleft="swiperleft" @swiperight="swiperright">
									<div class="amedt-title">
										<van-tag class="label-style-dark-color" type="primary">整改中</van-tag>
										{{item.contentName}}
									</div>
								</v-touch>
								<div class="item-style">
									<div class="amedt-item-title">巡检人：</div>
									<div class="amedt-item-text">{{item.createId}}</div>
								</div>
								<div class="item-style">
									<div class="amedt-item-title">是否合格：</div>
									<div class="amedt-item-text">{{item.qualifiedStatus=='1'||item.qualifiedStatus=='2'?'是':'否'}}</div>
								</div>
								<div class="item-style">
									<div class="amedt-item-title">巡检阶段：</div>
									<div class="amedt-item-text">{{item.contentName}}</div>
								</div>
								<div class="item-style">
									<div class="amedt-item-title">提交时间：</div>
									<div class="amedt-item-text">{{item.createDate}}</div>
								</div>
								<div class="item-style item-style-img">
									<div class="amedt-item-title">现场照片：</div>
									<div class="amedt-item-text amedt-item-img">
										<div style="display: flex;">
											<img @click="photoPreview(item.imgs,itemIngIndex)"
												v-for="(itemIng,itemIngIndex) in item.imgs" :src="itemIng.webUrl" alt="">
										</div>
									</div>
									<div class="boxshow-style"></div>
								</div>
								<div class="item-style item-style-img" style="border: none;">
									<div class="amedt-item-title">备注信息：</div>
									<div class="amedt-item-text amedt-item-remark">{{item.remark}}</div>
								</div>
								<div class="amend-after-style" v-if="item.reformDTO">
									<img src="@/assets/zgh.png" alt="" class="zgh-img">
									<div class="item-style">
										<div class="amedt-item-title">整改人：</div>
										<div class="amedt-item-text">{{item.reformDTO.createId}}</div>
									</div>
									<div class="item-style item-style-img">
										<div class="amedt-item-title">整改照片：</div>
										<div class="amedt-item-text amedt-item-img">
											<div style="display: flex;">
												<img @click="photoPreview(item.reformDTO.imgDTOS,itemIngIndex2)"
													v-for="(itemIng2,itemIngIndex2) in item.reformDTO.imgDTOS"
													:src="itemIng2.webUrl" alt="">
											</div>
										</div>
										<div class="boxshow-style"></div>
									</div>
									<div class="item-style item-style-img">
										<div class="amedt-item-title">备注信息：</div>
										<div class="amedt-item-text amedt-item-remark">
											{{item.reformDTO.remark}}
										</div>
									</div>
									<div class="item-style">
										<div class="amedt-item-title">审核人：</div>
										<div class="amedt-item-text">{{item.reformDTO.checkId}}</div>
									</div>
									<div class="item-style">
										<div class="amedt-item-title">审核时间：</div>
										<div class="amedt-item-text">{{item.reformDTO.checkTime}}</div>
									</div>
									<div class="item-style">
										<div class="amedt-item-title">审核结果：</div>
										<div class="amedt-item-text">
											{{item.reformDTO.standardFlag=='1'?'同意':'拒绝'}}
										</div>
									</div>
									<div class="item-style item-style-img" style="border: none;" v-if="item.reformDTO.resolveFlag=='1' && item.reformDTO.standardFlag!='1'">
										<div class="amedt-item-title">拒绝理由：</div>
										<div class="amedt-item-text amedt-item-remark">
											{{item.reformDTO.resolveRemark}}
										</div>
									</div>
								</div>
								<div class="item-bottom-style" v-if="item.reformDTO">
									<span class="zgjl-style" style="border: none;" @click="toAmendRecord(item)">
										<img src="@/assets/zgjl.png" alt="" class="shty-img">
										整改记录
									</span>
								</div>
							</div>
						</van-list>
					</pullRefresh>
				</div>
			</van-tab>
		</van-tabs>
	</div>
</template>

<script>
	import {
		ImagePreview
	} from 'vant';
	import api from '@/api/api.js'
	export default {
		data() {
			return {
				info: {},
				active: '1',
				pageNum: 1,
				pageSize: 10,
				total: 0,
				list: [],
				isLoading: false,
				loading: false,
				finished: false,
				error: false, // 是否加载失败
				totalAudit: 0, // 待审核数量
			}
		},
		methods: {
			swiperleft() {
				// console.log("左滑")
				if(this.active!='1'){
					this.active = '1'
					this.onRefresh()
				}
			},
			swiperright() {
				// console.log("右滑")
				if(this.active!='2'){
					this.active = '2'
					this.onRefresh()
				}
			},
			tabsChange(val) {
				this.onRefresh()
			},
			// 被 @load调用的方法
			onLoad() { // 若加载条到了底部
				let timer = setTimeout(() => { // 定时器仅针对本地数据渲染动画效果,项目中axios请求不需要定时器
					if (this.active == '1') {
						this.getExtantDangerAuditList()
					} else if (this.active == '2') {
						this.getExtantDangerAmendList()
					}
					this.pageNum++; // 分页数加一
					this.finished && clearTimeout(timer); //清除计时器
				}, 100);
			},
			// 加载失败调用方法
			onRefresh() {
				this.finished = false; // 清空列表数据
				this.loading = true; // 将 loading 设置为 true，表示处于加载状态
				this.pageNum = 1; // 分页数赋值为1
				this.list = []; // 清空数组
				this.onLoad(); // 重新加载数据
			},
			//整改中列表
			async getExtantDangerAmendList() {
				let obj = {
					flag: '2',//解决隐患
					time:'',
					projectId: this.info.id,
					nodeCode:''
				}
				let res = await api.getProjectStatesList(obj)
				if (res.code == 200) {
					this.total = res.count;
					if (res.data.length === 0) { // 判断获取数据条数若等于0
						this.list = []; // 清空数组
						this.finished = true; // 停止加载
					} else {
						// 若数据条数不等于0
						this.list.push(...res.data) // 将数据放入list中
						this.loading = false; // 加载状态结束 
						// 如果list长度大于等于总数据条数,数据全部加载完成
						if (this.list.length >= res.count) {
							this.finished = true; // 结束加载状态
						}
					}
					if(this.$refs.pullRefresh){
						this.$refs.pullRefresh.refreshSuccess()
					}
				}
			},
			//待审核列表
			async getExtantDangerAuditList(e) {
				let obj = {
					flag: '1',//解决隐患
					time:'',
					projectId: this.info.id,
					nodeCode:''
				}
				let res = await api.getProjectStatesList(obj)
				if (res.code == 200) {
					if (this.active == '2' || e) {
						this.totalAudit = res.count
						// localStorage.setItem('extantDangerNum', this.totalAudit)
						this.$store.commit('changeExtantDangerNum', this.totalAudit)
						return
					}
					this.total = res.count
					this.totalAudit = res.count
					// localStorage.setItem('extantDangerNum', this.totalAudit)
					this.$store.commit('changeExtantDangerNum', this.totalAudit)
					if (res.data.length === 0) { // 判断获取数据条数若等于0
						this.list = []; // 清空数组
						this.finished = true; // 停止加载
					} else {
						// 若数据条数不等于0
						this.list.push(...res.data) // 将数据放入list中
						this.loading = false; // 加载状态结束 
						// 如果list长度大于等于总数据条数,数据全部加载完成
						if (this.list.length >= res.count) {
							this.finished = true; // 结束加载状态
						}
					}
				}
				if(this.$refs.pullRefresh){
					this.$refs.pullRefresh.refreshSuccess()
				}
			},
			//图片预览
			photoPreview(list, index) {
				let imgList = list.map(e=>e.webUrl)
				let o = {
					images: imgList,
					startPosition: index
				}
				ImagePreview(o);
			},
			//审核拒绝
			resulse(item) {
				this.$router.push({
					name: "deepenPatrolAuditRefulselName",
					query: {
						itemId:item.id,
						reformId: item.reformDTO.id,
					}
				})
			},
			//审核同意
			async auditAgree(item) {
				let obj = {
					itemId:item.id,
					reformId: item.reformDTO.id,
					check: true,
					resolveRemark: ''
				}
				let res = await api.dangerAudit(obj)
				if (res.code == 200) {
					this.onRefresh()
				}
			},
			//前往整改记录
			toAmendRecord(item) {
				sessionStorage.setItem('abarbeitungInfo',JSON.stringify(item))
				this.$router.push({
					name: 'deepenPatrolAmendRecordName',
					query: {
						id:item.id,
						activeTabs: this.active
					}
				})
			}
		},
		mounted() {
			this.info = JSON.parse(sessionStorage.getItem('clientInfo'))
			if (this.$route.params.activeTabs) {
				this.active = this.$route.params.activeTabs
			}
			this.getExtantDangerAuditList(true)
		},
	}
</script>

<style scoped lang="scss">
	.extantDanger-style {
		.extantDanger-title {
			font-size: 13px;
			// font-family: Source Han Sans SC;
			font-weight: 500;
			color: #333333;
			display: inline-block;
			width: 99%;
		}

		.extantDanger-title2 {
			border-right: 1px solid #E6E6E6;
		}

		.audit-content,
		.abarbeitung-content {
			height: calc(100vh - 150px);
			overflow: auto;
			padding: 0 10px;
		}

		.audit-content,
		.abarbeitung-content {
			.list-item {
				text-align: left;
				background: #FFFFFF;
				border-radius: 6px;
				margin-top: 10px;

				.amedt-title {
					display: flex;
					align-items: center;
					height: 58px;
					border-bottom: 1px solid #E6E6E6;
					font-size: 16px;
					font-weight: 500;
					color: #333333;

					.label-style-dark-color {
						margin: 0 12px 0 10px;
					}
				}

				.zgh-img {
					width: 112px;
					height: 12px;
					margin: 19px 0 6px 10px;
				}

				.item-style {
					padding: 13px 0;
					margin: 0 10px;
					border-bottom: 1px solid #E6E6E6;
					display: flex;
					// min-height: 38px;
					align-items: center;

					.amedt-item-title {
						font-size: 14px;
						font-weight: 400;
						color: #777777;
						width: 80px;
					}

					.amedt-item-text {
						font-size: 14px;
						font-weight: 400;
						color: #333333;
					}

					.amedt-item-remark {
						width: calc(100% - 80px);
					}

					.amedt-item-img {
						display: flex;
						/* 设置超出滚动 */
						overflow-x: auto;
						width: calc(100% - 80px);

						// position: relative;
						img {
							width: 70px;
							height: 70px;
							margin-right: 4px;
						}

					}

					.amedt-item-img::-webkit-scrollbar {
						display: none;
					}
				}

				.item-style-img {
					align-items: flex-start;
					position: relative;

					.boxshow-style {
						position: absolute;
						width: 24px;
						height: 70px;
						background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
						right: 0;
					}

				}

				.amend-after-style {
					border-top: 1px solid #E6E6E6;
				}

				.item-bottom-style {
					display: flex;
					font-size: 13px;
					font-family: Source Han Sans SC;
					font-weight: 400;
					height: 40px;
					align-items: center;
					border-top: 1px solid #E6E6E6;

					span {
						display: flex;
						align-items: center;
						width: 100%;
						justify-content: center;
						height: 22px;
						border-right: 1px solid #E6E6E6;
					}

					img {
						width: 22px;
						height: 22px;
					}

					.ty-style {
						color: #40D673;
					}

					.jj-style {
						color: #FC426B;
						border: none;
					}

					.zgjl-style {
						color: #4F7AFD;
					}
				}
			}

		}
	}
</style>
<style lang="scss">
	.extantDanger-style {
		.van-tab {
			padding: 0;
		}

		.van-tab__text {
			display: inline-block;
			width: 100%;
		}

		.van-tabs__line {
			// transform:none!important;
			width: 49% !important;
		}
	}
</style>
