import { render, staticRenderFns } from "./recruitment-trial-detail.vue?vue&type=template&id=57ce4604&scoped=true&"
import script from "./recruitment-trial-detail.vue?vue&type=script&lang=js&"
export * from "./recruitment-trial-detail.vue?vue&type=script&lang=js&"
import style0 from "../../component/public/css/table.scss?vue&type=style&index=0&id=57ce4604&prod&lang=scss&scoped=true&"
import style1 from "./recruitment-trial-detail.vue?vue&type=style&index=1&id=57ce4604&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57ce4604",
  null
  
)

export default component.exports