<template>
  <div class="content">
    <headerNav
      leftNav=" "
      title="任务设置"
      @leftClick="back"
    ></headerNav>
    <main class="content-main">
      <van-form @submit="onSubmitTaskSet" :key="fromRestFields">
        <van-field
          clickable
          readonly
          name="termName"
          :value="fromValue.termName"
          label="任务项"
          placeholder="请选择"
          :rules="[{ required: true, message: '' }]"
          @click="showTermPicker = true"
          @focus="forbid"
        >
          <i slot="left-icon" style="color: red">*</i>
          <template #button><van-icon name="arrow" /></template>
        </van-field>
        <van-field
          clickable
          readonly
          name="taskDate"
          :value="fromValue.taskDate"                                    
          label="任务时间"
          placeholder="请选择"
          :rules="[{ required: true, message: '' }]"
          @click="openChooseDate"
          @focus="forbid"
        >
          <i slot="left-icon" style="color: red">*</i>
          <template #button><van-icon name="arrow" /></template>
        </van-field>
        <van-field
          clickable
          readonly
          name="userName"
          :value="fromValue.userName"
          label="执行者"
          placeholder="请选择"
          :rules="[{ required: true, message: '' }]"
          @click="showExecutorPicker = true"
          class="interval"
          @focus="forbid"
        >
          <i slot="left-icon" style="color: red">*</i>
          <template #button><van-icon name="arrow" /></template>
        </van-field>
        <div v-if="fromValue.termId == 'invite_job'">
          <van-field
            v-model="fromValue.childTaskTotalCount"
            name="childTaskTotalCount"
            type="digit"
            maxlength="3"
            label="子任务"
            placeholder="请输入"
            :rules="[{ required: true, message: '' }]"
            readonly
            @click="addSubTask"
            class="interval"
          >
            <!-- <i slot="left-icon" style="color: red">*</i> -->
            <template #button><van-icon name="arrow" /></template>
          </van-field>
        </div>
        <div v-if="fromValue.termId == 'admin_inspection'||fromValue.termId == 'dorm_inspection'">
          <van-field
            v-model="fromValue.targetValue"
            name="targetValue"
            type="digit"
            maxlength="3"
            label="巡检次数"
            placeholder="请输入"
            :rules="[{ required: true, message: '' }]"
            @input="formatter($event)"
          >
            <i slot="left-icon" style="color: red">*</i>
            <template #button>次</template>
          </van-field>
        </div>
        <div class="my-task-modification-popup-button">
          <van-button native-type="submit">确认</van-button>
        </div>
      </van-form>
    </main>

    <van-popup v-model="showTermPicker" position="bottom">
      <van-picker
        show-toolbar
        title="任务项"
        :columns="termList"
        @confirm="onConfirmTerm"
        @cancel="showTermPicker = false"
      />
    </van-popup>

    <van-popup
      v-model="datePopupShow"
      class="my-date-popup"
      overlay-class="my-popup-cover"
      position="bottom"
      :safe-area-inset-bottom="true"
      :lazy-render="false"
    >
      <div class="my-date-popup-box">
        <div class="my-date-popup-content">
          <van-picker
            show-toolbar
            title="选择日期"
            :columns="columns"
            toolbar-position="top"
            ref="myPicker"
            style="height: 100%"
            @confirm="dateSureSearch"
            @cancel="dateRestSearch"
          >
          </van-picker>
        </div>
      </div>
    </van-popup>

    <van-popup v-model="showExecutorPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="executorList"
        @confirm="onConfirmTaskType"
        @cancel="showExecutorPicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
import moment from "moment";
import { Toast } from "vant";
import api from "@/api/task-management/human-resources.js";

export default {
  components: {},
  data() {
    return {
      termList: [], //任务项数据
      showTermPicker: false, //任务项弹窗
      datePopupShow: false, //时间弹窗
      year: moment().year(),
      month: moment().month() + 1,
      columns: [
        { values: [], defaultIndex: moment().year() },
        { values: [], defaultIndex: moment().month() + 1 },
      ],
      showExecutorPicker: false, //执行者弹窗
      executorList:[], //执行者数据

      fromRestFields: 0,
      fromValue: {
        termName:"",
        termId:"",
        taskDate: "",
        userName:"",
        userId:"",
        childTaskTotalCount:"0",
        targetValue:"",
      },
      subTaskList:[]
    };
  },
  created() {
    this.setDateColumns()
  },
  mounted() {
    let _this = this;

    let subTaskObj = localStorage.getItem("SUB_TASK_OBJ")
    if (subTaskObj) {
      this.fromValue = JSON.parse(subTaskObj)
    }
    let subTaskList = localStorage.getItem("SUB_TASK_LIST")
    if (subTaskList) {
      this.subTaskList = JSON.parse(subTaskList)
      this.fromValue.childTaskTotalCount = this.subTaskList.length
    }
    this.getTermList()
    this.getExecutorList()
  },
  methods: {
    // 路由返回
    back() {
      this.$router.go(-1);
    },
    // 获取任务项数据
    getTermList(){
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      let params = {
        "taxonomy": "4", //4=人事；5=财务；6=预审；7=产品
      }
      api.getTermList(params).then((res)=>{
        if (res.code == 200) {
          Toast.clear()
          let arr = []
          res.data.forEach(item => {
            arr.push({
              text:item.name,
              value:item.code,
            })
          });
          this.termList = arr
        }else{
          Toast.fail(res.msg)
        }
      })
    },
    // 获取执行者数据
    getExecutorList(){
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      let params = {}
      api.getExecutorList(params).then((res)=>{
        if (res.code == 200) {
          Toast.clear()
          let arr = []
          res.data.forEach(item => {
            arr.push({
              text:item.name,
              value:item.code,
            })
          });
          this.executorList = arr
        }else{
          Toast.fail(res.msg)
        }
      })
    },
    // 任务项弹窗 确认
    onConfirmTerm(value) {
      this.fromValue.termName = value.text;
      this.fromValue.termId = value.value;
      this.showTermPicker = false;

    },
    //初始化日期选择范围
    setDateColumns() {
      let y = [];
      let nowYear = moment().year() + 2;
      for (let i = nowYear; i > nowYear - 21; i--) {
        y.push(i);
      }
      let m = [];
      for (let i = 1; i < 13; i++) {
        i = i < 10 ? "0" + i : i.toString()
        m.push(i);
      }
      this.columns[0].values = y;
      this.columns[1].values = m;
    },
    //打开日期选择
    openChooseDate(type) {
      this.year = this.fromValue.taskDate ? this.fromValue.taskDate.split('-')[0] : moment().year()
      this.month = this.fromValue.taskDate ? this.fromValue.taskDate.split('-')[1] : (moment().month() + 1 ).toString()
      this.$refs.myPicker && this.$refs.myPicker.setValues([Number(this.year), this.month]);
      if(this.fromValue.termName == ''||this.fromValue.termId == ''){
        Toast.fail('请先选择任务项，才可选任务时间')
      }else{
        this.datePopupShow = true;
      }
    },
    // 日期弹窗 取消
    dateRestSearch() {
      this.year = moment().year();
      this.month = moment().month() + 1 ;
      this.datePopupShow = false;
    },
    // 日期弹窗 确认
    dateSureSearch() {
      this.year = this.$refs.myPicker.getValues()[0];
      this.month = this.$refs.myPicker.getValues()[1];
      let date = this.year + "-" + this.month;
      this.fromValue.taskDate = date
      if((moment(date).year() - moment().year()) * 12 + moment(date).month() - moment().month() < 0){
        this.fromValue.taskDate = ''
        Toast.fail('不能选择历史时间')
      }
      this.validDate(date)
      this.datePopupShow = false;
    },
    // 校验日期
    validDate(date){
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      let params = {
        taskDate:date,
        termId:this.fromValue.termId,
      }
      api.checkDate(params).then((res)=>{
        if (res.code == 200) {
          Toast.clear()
        }else{
          this.fromValue.taskDate = ''
          Toast.fail(res.msg)
        }
      })
    },
    // 执行者弹窗 确认
    onConfirmTaskType(value){
      this.fromValue.userName = value.text;
      this.fromValue.userId = value.value;
      this.showExecutorPicker = false;
    },
    // 添加子任务
    addSubTask(){
      localStorage.setItem("SUB_TASK_OBJ",JSON.stringify(this.fromValue))
      this.$router.push({
        name:'TaskSetupHumanResourcesSubTask',
        query:{
          taskId:'',
        }
      })
    },
    // 禁止弹出软键盘
    forbid(){
      document.activeElement.blur()
    },
    formatter(value){
      if(Number(value) <= 0){
        Toast.fail('输入的数量不能为空或零')
        this.fromValue.targetValue = ""
      }
    },
    // form表单 提交
    onSubmitTaskSet(values) {
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      if(this.fromValue.termId == 'invite_job'){
        this.fromValue.itemList =  this.subTaskList
      }
      api.saveTask(this.fromValue).then((res)=>{
        Toast.clear()
        if (res.code == 200) {
          Toast.success('操作成功');
          let _this = this;
          setTimeout(()=>{
            _this.back()
          },1000)
        }else{
          Toast.fail(res.msg)
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.content ::v-deep {
  height: 100vh;
  overflow: auto;
  background: #f3f5f7;
  .van-form {
    height: calc(100vh - 140px);
    overflow: auto;
    .interval {
      border-top: 8px solid #f3f5f7;
    }
  }

  .my-task-modification-popup-button{
    width: 100%;
    padding: 10px 18px 30px;
    position: fixed;
    bottom: 0;
    box-shadow: 0px -1px 8px 0px rgba(0,0,0,0.04);
    box-sizing: border-box;
    background: #ffffff;
    .van-button{
      width: 100%;
      height: 50px;
      font-size: 18px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      background: #4871C0;
      border-radius: 4px 4px 4px 4px;
    }
  }

  .van-field__label {
    font-size: 16px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    color: #1d2129;
  }
  .van-field__control{
    text-align: right;
    font-size: 16px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #000000;
  }

}
</style>
