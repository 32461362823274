<template>
  <div class="main">
    <van-sticky class="topMenu">
      <headerNav
        leftNav=" "
        :title="'任务管理中心'"
        @leftClick="back"
        @rightClick="onClickExamine"
        :rightNav="'审批'"
        :rightIcon="'task-setup-finance-check'"
        :hintData="tasksNum"
        :showRightContent="userRole.roleCode == 'ROLE_830682' || userRole.roleCode == 'ROLE_666422' || userRole.roleCode == 'ROLE_519546'"
      ></headerNav>
    </van-sticky>

    <main>
      <van-tabs
        v-model="active"
        color="#3DA2FF"
        title-active-color="#3DA2FF"
        title-inactive-color="#333333"
        @change="onClickTabs"
      >
        <van-tab title="本月任务" name="1">
          <div class="content" v-if="monthTask.length">
            <!-- 每一项任务 -->
            <div
              class="content-element"
              v-for="(item, index) in monthTask"
              :key="index"
              @click="onClickOverViewDetails(item, '1')"
            >
              <!-- 财务总监 -->
              <div class="content-element-city" v-if="userRole.roleCode == 'ROLE_830682'">
                {{ item.marketName.slice(0,2) }}
              </div>
              <div class="content-element-review" v-if="userRole.roleCode == 'ROLE_830682'">
                <div class="content-element-review-text">市场综述</div>
                <div class="content-element-review-time">
                  {{ item.taskTime }}
                </div>
              </div>

              <!-- 财务经理 -->
              <div class="content-element-date" v-if="userRole.roleCode == 'ROLE_028622'">
                <img
                  src="@/assets/task-management/task-setup-finance-date.png"
                  alt=""
                />
              </div>
              <div class="content-element-review" v-if="userRole.roleCode == 'ROLE_028622'">
                <div class="content-element-review-text">
                  {{ item.taskTime }}
                </div>
                <div class="content-element-review-time">
                  {{ item.marketName }}综述
                </div>
              </div>

              <div class="content-element-final">
                <div class="content-element-final-count">{{ item.score || '-'}}</div>
                <div class="content-element-final-text">最终得分</div>
              </div>
              <div class="content-element-arrows">
                <img
                  src="@/assets/task-management/task-setup-finance-arrows.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <!-- 暂无数据 -->
          <div class="no-data" v-if="!monthTask.length && !isData">
            <img src="@/assets/task-management/task-setup-nodata.png" alt="" />
            <div>暂无任务</div>
          </div>
        </van-tab>
        <van-tab title="历史任务" name="2">
          <!-- 财务总监 -->
          <div class="content" v-if="monthTask.length">
            <!-- 每一项任务 -->
            <div
              class="content-element"
              v-for="(item, index) in monthTask"
              :key="index"
              @click="onClickOverViewDetails(item, '2')"
            >
              <!-- 财务总监 -->
              <div class="content-element-city"  v-if="userRole.roleCode == 'ROLE_830682'">
                {{ item.marketName.slice(0,2) }}
              </div>
              <div class="content-element-review"  v-if="userRole.roleCode == 'ROLE_830682'">
                <div class="content-element-review-text">市场综述</div>
                <div class="content-element-review-time">
                  {{ item.taskTime }}
                </div>
              </div>
              <!-- 财务经理 -->
              <div class="content-element-date" v-if="userRole.roleCode == 'ROLE_028622'">
                <img
                  src="@/assets/task-management/task-setup-finance-date.png"
                  alt=""
                />
              </div>
              <div class="content-element-review" v-if="userRole.roleCode == 'ROLE_028622'">
                <div class="content-element-review-text">
                  {{ item.taskTime }}
                </div>
                <div class="content-element-review-time">
                  {{ item.marketName }}综述
                </div>
              </div>
              <div class="content-element-final">
                <div class="content-element-final-count">{{ item.score || '-' }}</div>
                <div class="content-element-final-text">最终得分</div>
              </div>
              <div class="content-element-arrows">
                <img
                  src="@/assets/task-management/task-setup-finance-arrows.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <!-- 暂无数据 -->
          <div class="no-data"  v-if="!monthTask.length && !isData">
            <img src="@/assets/task-management/task-setup-nodata.png" alt="" />
            <div>暂无任务</div>
          </div>
        </van-tab>
      </van-tabs>
    </main>
  </div>
</template>

<script>
import api from "@/api/task-management/finance.js";
import { timeFormat } from "@/utils/timeCompute";
import { Toast } from "vant";

export default {
  data() {
    return {
      active: 1,
      monthTask: [],
      // 待审批任务数量
      tasksNum: 0,
      // 当前登录人角色
      userRole: {},
      isData:true
    };
  },
  created() {
    this.userRole = JSON.parse(localStorage.getItem("USER_ROLE"));
    let cache = localStorage.getItem('TAB_ACTIVE_OBJ')
    this.active = cache ? cache : 1
    this.getTaskList();
    this.searchCheckNum();
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    // 查询本月历史数据
    async getTaskList() {
      try{
        Toast.loading({
          message: "加载中...",
          forbidClick: true,
        });
        const { data } = await api.getTaskListApi(this.active);
        this.monthTask = data || [];
      }finally{
        Toast.clear();
        this.isData = this.monthTask.length
      }
    },
    // 查询审批数量
    async searchCheckNum() {
      const { data } = await api.checkTaskNumberApi(5);
      this.tasksNum = data;
    },
    // 点击切换tabs
    onClickTabs(e) {
      this.active = e
      // 存放筛选数据
      localStorage.setItem('TAB_ACTIVE_OBJ', e)
      this.getTaskList();
    },
    // 点击综述详情
    onClickOverViewDetails(row, type) {
      row.type = type;
      this.$router.push({
        path: "/task-management/setup/finance/details/overview-details",
        query: { row: JSON.stringify(row) },
      });
    },
    // 点击审批
    onClickExamine() {
      this.$router.push({
        path: "/task-management/setup/finance/details/examine-list",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.main::v-deep {
  height: 100vh;
  overflow: hidden;

  .van-tab {
    font-size: 17px;
    flex: none;
    width: 25%;
  }

  .van-tab--active {
    font-size: 18px;
  }

  .no-data {
    margin: 150px auto;
    img {
      width: 210px;
      height: 137px;
    }

    div {
      font-size: 16px;
      font-family: Bahnschrift, Bahnschrift;
      font-weight: 400;
      color: rgba(51, 51, 51, 0.5);
    }
  }

  .content {
    height: calc(100vh - 96px);
    background: #f5f5f5;
    padding-top: 8px;
    overflow: auto;

    .content-element {
      background: #ffffff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 16px 17px;
      margin-bottom: 8px;

      .content-element-city {
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        border-radius: 50%;
        background-color: #f7f8fa;
        color: #3975c6;
        font-size: 16px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
      }

      .content-element-date {
        margin-top: -35px;

        img {
          width: 24px;
          height: 24px;
        }
      }

      .content-element-review {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        height: 60px;
        margin-right: 90px;

        .content-element-review-text {
          color: #333333;
          font-size: 18px;
          font-family: PingFang SC, PingFang SC;
          font-weight: bold;
        }

        .content-element-review-time {
          color: #999999;
          font-size: 14px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
        }
      }

      .content-element-final {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 44px;
        width: 56px;
        padding: 8px 16px;
        background-color: #f7f8fa;
        border-radius: 4px;

        .content-element-final-count {
          font-size: 18px;
          font-family: Bahnschrift, Bahnschrift;
          font-weight: 400;
          color: #0e8bff;
        }

        .content-element-final-text {
          font-size: 14px;
          font-family: Bahnschrift, Bahnschrift;
          font-weight: 400;
          color: #999999;
        }
      }

      .content-element-arrows {
        img {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}
</style>
