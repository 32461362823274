<template>
  <div class="main">
    <van-sticky class="topMenu">
      <headerNav leftNav=" " :title="'子任务'" @leftClick="back"></headerNav>
    </van-sticky>

    <main>
      <div class="main-content" v-if="subtaskList.length">
        <SubtaskItem class="main-content-item" v-for="(item, index) in subtaskList" :key="index" :item="item" :targetType="taskObj.value" @onClick="onClickOverViewDetails(item)" />
      </div>
      <!-- 暂无数据 -->
      <div class="no-data" v-if="!subtaskList.length && !isData">
        <img src="@/assets/task-management/task-setup-nodata.png" alt />
        <div>暂无任务</div>
      </div>
       <!-- 添加 -->
      <div class="footer-add" v-if="taskData.monthType != 3">
        <van-button class="btn" type="info" @click="submitUploadFile">添加</van-button>
      </div>
    </main>

   
  </div>
</template>

<script>
import SubtaskItem from '../component/subtask-item.vue'
import { estimateOutputTarget } from '../data.js'
import api from "@/api/task-management/inquiry.js";
import { Toast } from "vant";

export default {
  components: {
    SubtaskItem
  },
  data() {
    return {
      subtaskList: [],
      taskObj: {},
      // 指标详情
      taskData: {},
      isData:true
    }
  },
  created() {
    // 获取筛选条件
    let fliter = JSON.parse(localStorage.getItem('TAB_ACTIVE_OBJ'))
    let role = JSON.parse(localStorage.getItem("USER_ROLE"));
    let taskData = JSON.parse(localStorage.getItem('INQUIRY_TASK_DETAILS'))
    this.taskObj = estimateOutputTarget(taskData.termId)
    this.taskData = { ...role, ...taskData,...fliter }
    this.getSubList()
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    // 查询子任务列表
    async getSubList(){
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      try{
        const { data } = await api.subtaskDetail(this.taskData.taskMainId)
        this.subtaskList = data
        this.subtaskList.map(item =>{
          if(item.termId == 'site_routing_inspection'){
            item.realityProfitRate = item.realityProfitRate && item.realityProfitRate != '-'?Number(item.realityProfitRate).toFixed(0):'-'
            item.taskProfitRate = item.taskProfitRate && item.taskProfitRate != '-'?Number(item.taskProfitRate).toFixed(0):'-'
          }
        })
      }finally{
        Toast.clear()
        this.isData = this.subtaskList.length
      }
     
    },
    // 点击个人详情
    onClickOverViewDetails(item) {
      localStorage.setItem('INQUIRY_SUBTASK_DETAILS',JSON.stringify({...this.taskData,...item}))
      this.$router.push({ path: '/task-management/setup/inquiry/details/subtask-details' })
    },
    // 添加子任务
    submitUploadFile() {
      this.$router.push({ path: '/task-management/setup/inquiry/details/add-subtask' })
    }
  }
}
</script>

<style lang="scss" scoped>
.main-content {
  height: calc(100vh - 140px);
  padding-top: 16px;
  padding-bottom: 70px;
  background: #f5f5f5;
  overflow: auto;
  .main-content-item {
    margin-bottom: 16px;
  }
}
.no-data {
    margin: 140px auto;
    width: 200px;
    img {
      width: 210px;
      height: 137px;
    }

    div {
      font-size: 16px;
      font-family: Bahnschrift, Bahnschrift;
      font-weight: 400;
      color: rgba(51, 51, 51, 0.5);
    }
  }
.footer-add {
  height: 66px;
  line-height: 50px;
  width: 100%;
  background: #fff;
  box-shadow: 0px -1px 8px 0px rgba(0, 0, 0, 0.04);
  text-align: center;
  position: fixed;
  bottom: 0px;
  left: 0px;
  padding-bottom: 10px;

  .btn {
    background: #3975c6;
    color: #ffffff;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 500;
    width: 340px;
  }
}
</style>