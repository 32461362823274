<template>
  <div style="background: #f5f5f5">
    <headerNav leftNav=" " @leftClick="back" title="审批"></headerNav>
    <div class="main">
      <van-tabs v-model="tabActive" @click="onClickTab" color="#3DA2FF" v-if="list.isTab">
        <van-tab :title="item.name" :name="index+1" v-for="(item,index) in list.tabList" :key="index">
          <template #title>
            <van-badge :max="99" v-if="item.isNum">
              <div>{{ item.name }}</div>
              <template #content v-if="item.num > 0">{{ item.num }}</template>
            </van-badge>
          </template>
          <div class="content">
            <div class="screen">
              <div class="screen-item" v-for="(node) in item.screenList" @click="screenFn(node)">
                <div v-if="node.lable == '自定义'" class="screen-item-date">{{ node.value.split("~")[0] }}<br/>{{ node.value.split("~")[1] }}</div>
                <span v-else>{{ node.lable || node.name  }}</span><img src="../../../assets/task-management/task-screen-icon.png" alt="">
              </div>
            </div>
            <div class="info-list " ref="scrollArea" @scroll="handleScroll">
              <div class="info-list-item" v-for="(item1,index1) in dataList" :key="index1">
                <div class="info-list-item-title">
                  <div class="title-name"><span class="market" v-if="userRole.roleCode == 'ROLE_603933'">{{ item1.markerName }}</span>{{ item1.headline }}</div>
                  <div class="state" style="background: #FF8C21;" v-if="item1.manageCheckStatus == 0">待审批</div>
                  <div class="state" style="background: #17CA5E;" v-else-if="item1.manageCheckStatus == 1">已通过</div>
                  <div class="state" style="background: #FF6157;" v-else-if="item1.manageCheckStatus == 2">已驳回</div>
                  <div class="state" style="background: #999999;" v-else-if="item1.manageCheckStatus == 3">已失效</div>
                  <div class="state" style="background: #999999;" v-else-if="item1.manageCheckStatus == 4">已撤回</div>
                </div>
                <div class="info-list-item-content">
                  <div class="info-list-item-target">
                    <div class="info-list-item-target-name">指标：</div>
                    <div class="info-list-item-target-content">
                      <div v-for="(item5,index5) in item1.termName" :key="index5">{{ item5 }}</div>
                    </div>
                  </div>
                  <div class="info-list-item-month">
                    <div class="info-list-item-month-name">月份：</div>
                    <div class="info-list-item-month-content">{{ item1.taskDate}}</div>
                  </div>
                  <div class="info-list-item-modify" v-if="item1.updateStatus == '3'">
                    <div class="item-left">
                      <div class="item-modify-name">原任务</div>
                      <div class="item-modify-item" v-for="(item2,index2) in item1.rawTask" :key="index2">
                        <div class="item-modify-item-parent">
                          <div class="item-modify-target">{{ item2.departmentName }}：</div>
                          <div class="item-modify-num">
                            {{ item2.productionValue }}<span>{{ item2.unit }}</span>
                          </div>
                        </div>
                        <div class="item-modify-item-child">
                          <div class="item-modify-item-child-item" v-for="(item6,index6) in item2.detailList" :key="index6">
                            <div class="item-modify-target">{{ item6.departmentName }}：</div>
                            <div class="item-modify-num">
                              {{ item6.productionValue }}<span>{{ item6.unit }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="item-right">
                      <div class="item-modify-name">修改后</div>
                      <div class="item-modify-item" v-for="(item3,index3) in item1.presentTask" :key="index3">
                        <div class="item-modify-item-parent">
                          <div class="item-modify-target">{{ item3.departmentName }}：</div>
                          <div class="item-modify-num">{{ item3.productionValue }}<span>{{ item3.unit }}</span></div>
                        </div>
                        <div class="item-modify-item-child">
                          <div class="item-modify-item-child-item" v-for="(item7,index7) in item3.detailList" :key="index7">
                            <div class="item-modify-target">{{ item7.departmentName }}：</div>
                            <div class="item-modify-num">
                              {{ item7.productionValue }}<span>{{ item7.unit }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="info-list-item-setup" v-if="item1.updateStatus == '1'||item1.updateStatus == '2'">
                    <div class="item-setup-name">设置任务</div>
                    <div :class="item1.expandBtn?'info-list-item-setup-height':''" >
                      <div class="item-setup-item" v-for="(item4,index4) in item1.presentTask" :key="index4">
                        <div class="item-setup-item-parent">
                          <div class="item-setup-target">{{ item4.departmentName }}：</div>
                          <div class="item-setup-num">{{ item4.productionValue }}<span>{{ item4.unit }}</span></div>
                        </div>
                        <div class="item-setup-item-child" v-for="(item8,index8) in item4.detailList" :key="index8">
                          <div class="item-setup-target">{{ item8.departmentName }}：</div>
                          <div class="item-setup-num">{{ item8.productionValue }}<span>{{ item8.unit }}</span></div>
                        </div>
                      </div>
                    </div>
                    <div class="item-setup-more" v-if="item1.presentTask&&item1.presentTask.length > 5" @click="item1.expandBtn=!item1.expandBtn">
                      <div v-if="item1.expandBtn">更多 <van-icon name="arrow-down" /></div>
                      <div v-else>收起 <van-icon name="arrow-up" /></div>
                    </div>
                  </div>
                  <div class="info-list-item-detail" @click="goDetail(item1)">
                    <div class="item-time">{{ item1.setTime }}</div>
                    <div class="item-operate">查看详情 <van-icon name="arrow" /></div>
                  </div>
                </div>
                <div class="info-list-item-icon" v-if="item1.isMessage"></div>
              </div>
              <!-- 暂无数据 -->
              <div class="no-data" v-if="isList && dataList.length <= 0">
                <img src="@/assets/task-management/task-setup-nodata.png" alt="">
                <div>暂无数据</div>
              </div>
            </div>
          </div>
        </van-tab>
      </van-tabs>
      <div class="content" v-if="!list.isTab">
        <div class="screen">
          <div class="screen-item" v-for="(node) in list.tabList[0].screenList" @click="screenFn(node)">
            <div v-if="node.lable == '自定义'" class="screen-item-date">{{ node.value.split("~")[0] }}<br/>{{ node.value.split("~")[1] }}</div>
            <span v-else>{{ node.lable || node.name  }}</span> <van-icon name="arrow-down" />
          </div>
        </div>
        <div class="info-list info-list1" ref="scrollArea1" @scroll="handleScroll">
          <div class="info-list-item" v-for="(item1,index1) in dataList" :key="index1">
            <div class="info-list-item-title">
              <div class="title-name"><span class="market" v-if="userRole.roleCode == 'ROLE_603933'">{{ item1.markerName }}</span>{{ item1.headline }}</div>
              <div class="state" style="background: #FF8C21;" v-if="item1.manageCheckStatus == 0">待审批</div>
              <div class="state" style="background: #17CA5E;" v-else-if="item1.manageCheckStatus == 1">已通过</div>
              <div class="state" style="background: #FF6157;" v-else-if="item1.manageCheckStatus == 2">已驳回</div>
              <div class="state" style="background: #999999;" v-else-if="item1.manageCheckStatus == 3">已失效</div>
              <div class="state" style="background: #999999;" v-else-if="item1.manageCheckStatus == 4">已撤回</div>
            </div>
            <div class="info-list-item-content">
              <div class="info-list-item-target">
                <div class="info-list-item-target-name">指标：</div>
                <div class="info-list-item-target-content">
                  <div v-for="(item5,index5) in item1.termName" :key="index5">{{ item5 }}</div>
                </div>
              </div>
              <div class="info-list-item-month">
                <div class="info-list-item-month-name">月份：</div>
                <div class="info-list-item-month-content">{{ item1.taskDate}}</div>
              </div>
              <div class="info-list-item-modify" v-if="item1.updateStatus == '3'">
                <div class="item-left">
                  <div class="item-modify-name">原任务</div>
                  <div class="item-modify-item" v-for="(item2,index2) in item1.rawTask" :key="index2">
                    <div class="item-modify-item-parent">
                      <div class="item-modify-target">{{ item2.departmentName }}：</div>
                      <div class="item-modify-num">{{ item2.productionValue }}<span>{{ item2.unit }}</span></div>
                    </div>
                    <div class="item-modify-item-child">
                      <div class="item-modify-item-child-item" v-for="(item6,index6) in item2.detailList" :key="index6">
                        <div class="item-modify-target">{{ item6.departmentName }}：</div>
                        <div class="item-modify-num">
                          {{ item6.productionValue }}<span>{{ item6.unit }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="item-right">
                  <div class="item-modify-name">修改后</div>
                  <div class="item-modify-item" v-for="(item3,index3) in item1.presentTask" :key="index3">
                    <div class="item-modify-item-parent">
                      <div class="item-modify-target">{{ item3.departmentName }}：</div>
                      <div class="item-modify-num">{{ item3.productionValue }}<span>{{ item3.unit }}</span></div>
                    </div>
                    <div class="item-modify-item-child">
                      <div class="item-modify-item-child-item"  v-for="(item7,index7) in item3.detailList" :key="index7">
                        <div class="item-modify-target">{{ item7.departmentName }}：</div>
                        <div class="item-modify-num">
                          {{ item7.productionValue }}<span>{{ item7.unit }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="info-list-item-setup" v-if="item1.updateStatus == '1'||item1.updateStatus == '2'">
                <div class="item-setup-name">设置任务</div>
                <div :class="item1.expandBtn?'info-list-item-setup-height':''">
                  <div class="item-setup-item" v-for="(item4,index4) in item1.presentTask" :key="index4">
                    <div class="item-setup-item-parent">
                      <div class="item-setup-target">{{ item4.departmentName }}：</div>
                      <div class="item-setup-num">{{ item4.productionValue }}<span>{{ item4.unit }}</span></div>
                    </div>
                    <div class="item-setup-item-child" v-for="(item8,index8) in item4.detailList" :key="index8">
                      <div class="item-setup-target">{{ item8.departmentName }}：</div>
                      <div class="item-setup-num">{{ item8.productionValue }}<span>{{ item8.unit }}</span></div>
                    </div>
                  </div>
                </div>
                <div class="item-setup-more" v-if="item1.presentTask&&item1.presentTask.length > 5" @click="item1.expandBtn=!item1.expandBtn">
                  <div v-if="item1.expandBtn">更多 <van-icon name="arrow-down" /></div>
                  <div v-else>收起 <van-icon name="arrow-up" /></div>
                </div>
              </div>
              <div class="info-list-item-detail" @click="goDetail(item1)">
                <div class="item-time">{{ item1.setTime }}</div>
                <div class="item-operate">查看详情 <van-icon name="arrow" /></div>
              </div>
            </div>
            <div class="info-list-item-icon" v-if="item1.isMessage"></div>
          </div>
          <!-- 暂无数据 -->
          <div class="no-data" v-if="isList && dataList.length <= 0">
            <img src="@/assets/task-management/task-setup-nodata.png" alt="">
            <div>暂无数据</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 指标 筛选 -->
    <van-popup v-model="showTargetPicker" :lazy-render="false" position="bottom">
      <van-picker
        show-toolbar
        title="选择指标"
        ref="target"
        :columns="targetList"
        @confirm="onConfirmTarget"
        @cancel="showTargetPicker = false"
      />
    </van-popup>
    <!-- 月份 筛选 -->
    <van-popup v-model="showMonthPicker" :lazy-render="false" position="bottom">
      <van-picker
        show-toolbar
        title="选择月份"
        ref="month"
        :columns="monthList"
        @confirm="onConfirmMonth"
        @cancel="showMonthPicker = false"
      />
    </van-popup>
    <!-- 月份自定义 弹窗 -->
    <van-popup v-model="showCustomMonth" :lazy-render="false" position="bottom">
      <div class="custom">
        <p>按月份筛选</p>
        <div class="custom-date">
          <div
            :class="startMonth == '' ? '' : 'custom-date-content'"
            @click="openChooseMonth('1')"
          >
            {{ startMonth == "" ? "开始时间" : startMonth }}
          </div>
          <div class="custom-date-interval">-</div>
          <div
            :class="endMonth == '' ? '' : 'custom-date-content'"
            @click="openChooseMonth('2')"
          >
            {{ endMonth == "" ? "结束时间" : endMonth }}
          </div>
        </div>
        <div class="custom-footer">
          <van-button class="btn" type="default" @click="customMonthRestSearch">取消</van-button>
          <van-button class="btn" color="#4871C0" @click="customMonthSureSearch" type="info">确认</van-button>
        </div>
      </div>
    </van-popup>
    <!-- 月份自定义 筛选 -->
    <van-popup v-model="showCustomMonthPicker" :lazy-render="false" position="bottom">
      <van-datetime-picker
        v-model="customMonth"
        ref="customMonth"
        type="year-month"
        title="选择年月"
        :min-date="minDate"
        :max-date="maxDate"
        :formatter="dateFormatter"
        @confirm="onConfirmCustomMonth"
      />
    </van-popup>
    <!-- 到达优先 筛选 -->
    <van-popup v-model="showPriorityPicker" :lazy-render="false" position="bottom">
      <van-picker
        show-toolbar
        title="选择优先"
        ref="priority"
        :columns="priorityList"
        @confirm="onConfirmPriority"
        @cancel="showPriorityPicker = false"
      />
    </van-popup>
    <!-- 审批状态 筛选 -->
    <van-popup v-model="showStatePicker" :lazy-render="false" position="bottom">
      <van-picker
        show-toolbar
        title="选择审批状态"
        ref="state"
        :columns="stateList"
        @confirm="onConfirmState"
        @cancel="showStatePicker = false"
      />
    </van-popup>
    <!-- 日期 筛选 -->
    <van-popup v-model="showDatePicker" :lazy-render="false" position="bottom">
      <van-picker
        show-toolbar
        title="选择日期"
        ref="date"
        :columns="dateList"
        @confirm="onConfirmDate"
        @cancel="showDatePicker = false"
      />
    </van-popup>
    <!-- 日期自定义 弹窗 -->
    <van-popup v-model="showCustomDate" :lazy-render="false" position="bottom">
      <div class="custom">
        <p>按日期筛选</p>
        <div class="custom-date">
          <div
            :class="startDate == '' ? '' : 'custom-date-content'"
            @click="openChooseDate('1')"
          >
            {{ startDate == "" ? "开始时间" : startDate }}
          </div>
          <div class="custom-date-interval">-</div>
          <div
            :class="endDate == '' ? '' : 'custom-date-content'"
            @click="openChooseDate('2')"
          >
            {{ endDate == "" ? "结束时间" : endDate }}
          </div>
        </div>
        <div class="custom-footer">
          <van-button class="btn" type="default" @click="customDateRestSearch">取消</van-button>
          <van-button class="btn" color="#4871C0" @click="customDateSureSearch" type="info">确认</van-button>
        </div>
      </div>
    </van-popup>
    <!-- 日期自定义 筛选 -->
    <van-popup v-model="showCustomDatePicker" :lazy-render="false" position="bottom">
      <van-datetime-picker
        v-model="customDate"
        ref="customDate"
        type="date"
        title="选择年月"
        :min-date="minDate"
        :max-date="maxDate"
        :formatter="dateFormatter"
        @confirm="onConfirmCustomDate"
      />
    </van-popup>
  </div>
</template>
<script>
import api from "@/api/task-management/setup.js";
import moment from "moment";
import { Toast } from 'vant';
import { timeFormat } from "@/utils/timeCompute";
import { approveFn } from './task-approve-data'

export default {
  data() {
    return {
      // 用户信息
      userRole:{},
      // 含有tab的当前值
      tabActive:1,
      // 通过用户编码获取对应角色的显示限制
      list:{},
      // 指标筛选
      showTargetPicker:false,
      // 指标筛选 数据
      targetList:[
        { text: "全部", value: "9" },
        { text: "转单产值", value: "1" },
        { text: "补齐产值", value: "2" },
      ],
      // 月份筛选
      showMonthPicker:false,
      // 月份筛选 数据
      monthList:[
        { text: "全部", value: "" },
        { text: "上月", value: "1" },
        { text: "本月", value: "2" },
        { text: "自定义", value: "3" },
      ],
      // 月份自定义弹窗
      showCustomMonth:false,
      // 月份自定义 开始时间
      startMonth:'',
      // 月份自定义 结束时间
      endMonth:'',
      // 自定义月份筛选
      showCustomMonthPicker:false,
      // 自定义月份筛选的最小时间
      minDate: new Date(2010, 0, 1),
      // 自定义月份筛选的最大时间
      maxDate: new Date(2025, 10, 1),
      // 自定义月份 默认时间
      customMonth: new Date(),
      // 自定义月份类型（区分开始、结束）
      customMonthType:'',
      // 到达优先筛选
      showPriorityPicker:false,
      // 到达优先筛选 数据
      priorityList:[
        { text: "到达优先", value: "1" },
        { text: "等待优先", value: "2" },
      ],
      // 审批状态筛选
      showStatePicker:false,
      // 审批状态筛选 数据
      stateList:[
        { text: "全部", value: "9" },
        { text: "已通过", value: "1" },
        { text: "已驳回", value: "2" },
        { text: "已失效", value: "3" },
      ],
      // 日期筛选
      showDatePicker:false,
      // 日期筛选 数据
      dateList:[
        { text: "全部", value: "" },
        { text: "近7天", value: "1" },
        { text: "近30天", value: "2" },
        { text: "自定义", value: "3" },
      ],
      // 日期自定义弹窗显示
      showCustomDate:false,
      // 日期自定义 开始时间
      startDate:'',
      // 日期自定义 结束时间
      endDate:'',
      // 日期自定义筛选显示
      showCustomDatePicker:false,
      // 日期自定义 默认时间
      customDate: new Date(),
      // 日期自定义类型（区分开始、结束）
      customDateType:'',
      // 分页 当前页数
      page:1,
      // 分页 当前条数
      limit:30,
      // 数据结果
      dataList:[],
      isLoading: false, // 是否正在加载数据
      // 是否有无数据
      isList:false,
    };
  },
  created() {
    this.userRole = JSON.parse(localStorage.getItem("USER_ROLE"));
    let tabActiveObj = JSON.parse(sessionStorage.getItem('TASK_APPROVE_TAB_OBJ'))||""
    if( tabActiveObj ){
      this.tabActive = tabActiveObj.tabActive
    }else{
      this.tabActive = 1
    }
    this.list = approveFn(this.userRole.roleCode)
  },
  mounted() {
    this.getApproveNum()
    this.getList()
  },
  methods: {
    // 路由返回
    back() {
      this.$router.go(-1);
    },
    // 切换Tab
    onClickTab(){
      sessionStorage.setItem("TASK_APPROVE_TAB_OBJ",JSON.stringify({tabActive:this.tabActive}))
      this.page = 1
      this.dataList = []
      this.$nextTick(() => {
        if(this.list.isTab){
          this.$refs['scrollArea'].scrollTop = 0 // 将滚动位置设置为 0
        }else{
          this.$refs['scrollArea1'].scrollTop = 0 // 将滚动位置设置为 0
        }
      })
      this.getList()
    },
    // 打开弹出
    screenFn(node){
      if(node.code == "target"){
        this.targetList = node.list
        this.showTargetPicker = true
        if(node.value != ""){
          setTimeout(()=>{
            this.$refs.target&&this.$refs.target.setValues([node.value]);
          },0)
        }else{
          this.$refs.target&&this.$refs.target.setIndexes([0])
        }
      }else if(node.code == "month"){
        this.showMonthPicker = true
        if(node.value != ""){
          setTimeout(()=>{
            if( node.lable != '自定义'){
              this.$refs.month&&this.$refs.month.setValues([node.lable]);
              this.startMonth = ""
              this.endMonth = ""
            }else{
              this.$refs.month&&this.$refs.month.setValues(['自定义'])
              this.startMonth = node.value.split("~")[0]
              this.endMonth = node.value.split("~")[1]
            }
          },0)
        }else{
          this.$refs.month&&this.$refs.month.setIndexes([0])
          this.startMonth = ""
          this.endMonth = ""
        }
      }else if(node.code == "priority"){
        this.showPriorityPicker = true
        if(node.value != ""){
          setTimeout(()=>{
            this.$refs.priority&&this.$refs.priority.setValues([node.value]);
          },0)
        }else{
          this.$refs.priority&&this.$refs.priority.setIndexes([0])
        }
      }else if(node.code == "state"){
        this.stateList = node.list
        this.showStatePicker = true
        if(node.value != ""){
          setTimeout(()=>{
            this.$refs.state&&this.$refs.state.setValues([node.value]);
          },0)
        }else{
          this.$refs.state&&this.$refs.state.setIndexes([0])
        }
      }else if(node.code == 'handleDate'||node.code == 'reachDate'||node.code == 'renderDate'){
        this.showDatePicker = true
        if(node.value != ""){
          setTimeout(()=>{
            if(node.lable != '自定义'){
              this.$refs.date&&this.$refs.date.setValues([node.lable]);
              this.startDate = ""
              this.endDate = ""
            }else{
              this.$refs.date&&this.$refs.date.setValues(['自定义']);
              this.startDate = node.value.split("~")[0]
              this.endDate = node.value.split("~")[1]
            }
          },0)
        }else{
          this.$refs.date&&this.$refs.date.setIndexes([0])
          this.startDate = ""
          this.endDate = ""
        }
      }

    },
    // 指标 确认
    onConfirmTarget(value){
      this.list.tabList[this.tabActive-1].screenList.forEach((item,index)=>{
        if(item.code == 'target'){
          item.lable = value.text
          item.value = value.text
        }
      })
      this.page = 1
      this.dataList = []
      this.getList()
      this.$forceUpdate()
      this.showTargetPicker = false
    },
    // 月份 确认
    onConfirmMonth(value){
      if(value.value == '3'){
        this.showCustomMonth = true
      }else{
        this.list.tabList[this.tabActive-1].screenList.forEach((item,index)=>{
          if(item.code == 'month'){
            item.lable = value.text
            if(value.value == "1"){
              item.value = moment().subtract(1, 'months').format('YYYY-MM')+"~"+moment().subtract(1, 'months').format('YYYY-MM');
            }else if(value.value == "2"){
              item.value = moment().format('YYYY-MM')+"~"+moment().format('YYYY-MM');
            }else{
              item.value = value.value
            }
          }
        })
        this.page = 1
        this.dataList = []
        this.getList()
        this.$forceUpdate()
        this.showMonthPicker = false
      }
    },
    // 打开 月份自定义赋值 (判断开始时间/结束时间)
    openChooseMonth(type){
      this.customMonthType = type
      let date
      if(type == "1"){
        date = this.startMonth ? moment(this.startMonth).format('YYYY-MM') : moment().format('YYYY-MM')
      }else if(type == "2"){
        date = this.endMonth ? moment(this.endMonth).format('YYYY-MM') : moment().format('YYYY-MM')
      }
      this.customMonth = new Date(date)
      this.showCustomMonthPicker = true
    },
    //月份自定义弹窗关闭
    customMonthRestSearch() {
      this.showCustomMonth = false
    },
    //月份自定义确认搜索
    customMonthSureSearch() {
      if(this.startMonth == "" || this.endMonth == ""){
        Toast('开始时间和结束时间不能为空');
        return false
      }
      if((moment(this.startMonth).year() - moment(this.endMonth).year()) * 12 + moment(this.startMonth).month() - moment(this.endMonth).month() < -24){
        Toast('月份跨度最多为2年(24个月)');
        this.startMonth = ''
        this.endMonth = ''
        return false
      }
      if((moment(this.startMonth).year() - moment(this.endMonth).year()) * 12 + moment(this.startMonth).month() - moment(this.endMonth).month() > 0){
        Toast('结束时间不能超过开始时间');
        this.startMonth = ''
        this.endMonth = ''
        return false
      }
      this.list.tabList[this.tabActive-1].screenList.forEach((item,index)=>{
        if(item.code == 'month'){
          item.lable = '自定义'
          item.value = this.startMonth+"~"+this.endMonth
        }
      })
      this.page = 1
      this.dataList = []
      this.getList()
      this.showMonthPicker = false
      this.showCustomMonth = false
      this.showCustomMonthPicker = false
    },
    // 时间筛选弹窗格式调整
    dateFormatter(type, val) {
      if (type === 'year') {
        return `${val}年`;
      } else if (type === 'month') {
        return `${val}月`;
      }else if (type === 'day') {
        return `${val}日`;
      }
      return val;
    },
    // 月份自定义赋值 (判断开始时间/结束时间)
    onConfirmCustomMonth(value){
      let month = timeFormat(value).slice(0,-3)
      if(this.customMonthType == "1"){
        this.startMonth = month
      }else if(this.customMonthType == "2"){
        this.endMonth = month
      }
      this.showCustomMonthPicker = false
    },
    // 到达优先 确认
    onConfirmPriority(value){
      this.list.tabList[this.tabActive-1].screenList.forEach((item,index)=>{
        if(item.code == 'priority'){
          item.lable = value.text
          item.value = value.value
        }
      })
      this.page = 1
      this.dataList = []
      this.getList()
      this.$forceUpdate()
      this.showPriorityPicker = false
    },
    // 审批状态 确认
    onConfirmState(value){
      this.list.tabList[this.tabActive-1].screenList.forEach((item,index)=>{
        if(item.code == 'state'){
          item.lable = value.text
          item.value = value.value
        }
      })
      this.page = 1
      this.dataList = []
      this.getList()
      this.$forceUpdate()
      this.showStatePicker = false
    },
    // 日期 确认
    onConfirmDate(value){
      if(value.value == '3'){
        this.showCustomDate = true
      }else{
        this.list.tabList[this.tabActive-1].screenList.forEach((item,index)=>{
          if(item.code == 'handleDate'||item.code == 'reachDate'||item.code == 'renderDate'){
            item.lable = value.text
            if(value.value == '1'){
              item.value = moment().subtract(7, 'days').format('YYYY-MM-DD')+"~"+moment().format('YYYY-MM-DD')
            }else if(value.value == '2'){
              item.value = moment().subtract(30, 'days').format('YYYY-MM-DD')+"~"+moment().format('YYYY-MM-DD')
            }else{
              item.value = value.value
            }
          }
        })
        this.page = 1
        this.dataList = []
        this.getList()
        this.$forceUpdate()
        this.showDatePicker = false
      }
    },
    // 打开自定义日期的筛选弹窗（根据类型赋值）
    openChooseDate(type){
      this.customDateType = type
      let date
      if(type == "1"){
        date =  this.startDate ? moment(this.startDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
      }else if(type == "2"){
        date =  this.endDate ? moment(this.endDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
      }
      this.customDate = new Date(date)
      this.showCustomDatePicker = true
    },
    // 打开自定义日期的筛选弹窗（根据类型赋值）
    onConfirmCustomDate(value){
      let date = timeFormat(value)
      if(this.customDateType == "1"){
        this.startDate = date
      }else if(this.customDateType == "2"){
        this.endDate = date
      }
      this.showCustomDatePicker = false
    },
    //自定义日期弹窗关闭
    customDateRestSearch() {
      this.showCustomDate = false
    },
    //计算 两个日期 相隔天数
    getDaysBetweenDates(date1, date2) {
      let d1 = new Date(date1);
      let d2 = new Date(date2);
      // 获取时间戳（单位：毫秒）
      let timeDiff = Math.abs(d2.getTime() - d1.getTime());
      // 计算相差的天数并返回结果
      return Math.ceil(timeDiff / (1000 * 3600 * 24));
    },
    //自定义日期确认搜索
    customDateSureSearch() {
      if(this.startDate == "" || this.endDate == ""){
        Toast('开始时间和结束时间不能为空');
        return false
      }
      // 将字符串日期转换为时间戳
      let d1 = new Date(this.startDate).getTime();
      let d2 = new Date(this.endDate).getTime();
      if (d1 > d2) {
        Toast('结束时间不能超过开始时间');
        this.startDate = ''
        this.endDate = ''
        return false
      }
      let day = this.getDaysBetweenDates(this.startDate,this.endDate)
      if(day>90){
        Toast('日期相隔天数不能超过90天');
        this.startDate = ''
        this.endDate = ''
        return false
      }
      this.list.tabList[this.tabActive-1].screenList.forEach((item,index)=>{
        if(item.code == 'handleDate'||item.code == 'reachDate'||item.code == 'renderDate'){
          item.lable = '自定义'
          item.value = this.startDate+"~"+this.endDate
        }
      })
      this.page = 1
      this.dataList = []
      this.getList()
      this.showDatePicker = false
      this.showCustomDate = false
      this.showCustomDatePicker = false
    },
    // 跳转详情页面
    goDetail(item){

      let isRevoke //详情页撤销按钮是否显示 (1显示;0隐藏)
      if(!this.list.isTab){
        if(item.manageCheckStatus == '0'){
          isRevoke = '1'
        }else{
          isRevoke = '0'
        }
      }else{
        if(this.list.tabList[this.tabActive-1].value == '4' && item.manageCheckStatus == '0'){
          isRevoke = '1'
        }else{
          isRevoke = '0'
        }
      }

      if(this.list.tabList[this.tabActive-1].value != '2'){
        let params = {
          id:item.id,
          type:this.list.tabList[this.tabActive-1].value,
          roleCode:this.userRole.roleCode
        }
        api.getAlterationMessage(params)
        .finally(()=>{
          this.$router.push({
            name:'TaskManagementSetUpTaskApproveDetail',
            query: {
              id:item.id,
              tabActive:this.list.tabList[this.tabActive-1].value,
              isRevoke
            }
          })
        })
      }else{
        this.$router.push({
          name:'TaskManagementSetUpTaskApproveDetail',
          query: {
            id:item.id,
            tabActive:this.list.tabList[this.tabActive-1].value,
            isRevoke
          }
        })
      }
    },
    // 下拉 加载更多
    async handleScroll(e) {
      const el = e.target
      if ( (el.scrollTop + el.clientHeight)+1 >= el.scrollHeight && !this.isLoading && this.dataList.length >= this.limit) {
        this.page++; // 加载下一页数据
        this.loading = true
        await this.getList();
        this.loading = false
      }
    },
    // 获取审批数量
    getApproveNum(){
      api.getApprovalNumber({}).then((res)=>{
        if(res.code == 200){
          res.data.forEach((item,index)=>{
            this.list.tabList.forEach((item1,index1)=>{
              if(item.ext == item1.value){
                item1.num = item.number
              }
            })
          })
        }else{
          Toast.fail(res.msg);
        }
      })
    },
    // 获取列表数据
    async getList(){
      // ------参数--------
      let manageCheckStatus = '' //审批状态
      let headline = '' //指标类型
      let taskDate = '' //月份
      let taskOrder = '' //到达
      let clDate = '' //处理日期
      this.list.tabList[this.tabActive-1].screenList.forEach((item,index)=>{
        if(item.code == 'target'){
          headline = item.lable == "全部"?'':item.lable
        }
        if(item.code == 'state'){
          manageCheckStatus = item.value
        }
        if(item.code == "month"){
          taskDate = item.value
        }
        if(item.code == "priority"){
          taskOrder = item.value
        }
        if(item.code == 'reachDate'||item.code == 'handleDate'||item.code == 'renderDate'){
          clDate = item.value
        }
      })
      let params = {
        ext: this.list.tabList[this.tabActive-1].value, //tab
        page: this.page,
        limit: this.limit,
        manageCheckStatus,
        headline,
        taskDate,
        taskOrder,
        clDate,
      }
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      await api.getCheckList(params).then((res)=>{
        if(res.code == 200){
          Toast.clear()

          res.data.data.forEach((item,index)=>{
            if(item.presentTask&&item.presentTask.length > 0){
              item.expandBtn = true  // 设置任务展开按钮
            }

            // 消息0:未读,1:已读
            if(item.ext == "1"){
              item.isMessage = true
            }else if(item.ext == "2"){
              item.isMessage = false
            }else if(item.ext == "3"){
              item.isMessage = item.sendMessage == '0'?true:false
            }else if(item.ext == "4"){
              if(item.manageCheckStatus == "0" || item.manageCheckStatus == "4"){ //已发起的待审批和已撤回
                item.isMessage = false
              }else{
              item.isMessage = item.launchMessage == '0'?true:false
              }
            }

          })

          this.dataList.push(...res.data.data)
          if(this.list.tabList[this.tabActive-1].isNum){
            this.list.tabList[this.tabActive-1].num = res.data.number
          }
        }else{
          Toast.fail(res.msg)
        }
      }).finally(()=>{
        this.isList = this.dataList.length > 0?false:true
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.content{
  height: 100%;
  .screen{
    height: 46px;
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 15px;
    color: #333333;
    background: #FFFFFF;
    margin-bottom: 8px;
    .screen-item{
      width: 25%;
      display: flex;
      align-items: center;
      justify-content: center;
      .screen-item-date{
        display: inline-block;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 12px;
        color: #333333;
        vertical-align: middle;
      }
      img{
        width: 14px;
        height: 14px;
        margin-left: 2px;
      }
    }
  }
  .info-list{
    height: calc(100vh - 150px);
    overflow: auto;

    .info-list-item{
      margin-bottom: 8px;
      background: #FFFFFF;
      padding: 8px 12px;
      border-radius: 8px 8px;
      position: relative;
      .info-list-item-title{
        height: 32px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
        .title-name{
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 18px;
          color: #1D2129;
          line-height: 32px;
          .market{
            background: rgba(242,109,12,0.1);
            padding: 0 4px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 15px;
            color: #F26D0C;
            line-height: 32px;
            margin-right: 4px;
          }
        }
        .state{
          width: 60px;
          line-height: 24px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 15px;
          color: #FFFFFF;
          text-align: center;
          border-radius: 4px 4px;
        }
      }
      .info-list-item-content{
        .info-list-item-target{
          display: flex;
          min-height: 32px;
          .info-list-item-target-name{
            width: 70px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 16px;
            color: #545454;
            text-align: left;
            margin-right: 8px;
          }
          .info-list-item-target-content{
            display: flex;
            flex-wrap: wrap;
            div{
              font-family: PingFang SC, PingFang SC;
              font-weight: 500;
              font-size: 18px;
              color: #8C8C8C;
              padding: 0 4px;
              background: #F9F9F9;
              margin-right: 10px;
              margin-bottom: 10px;
            }
          }
        }
        .info-list-item-month{
          min-height: 32px;
          display: flex;
          align-items: center;
          margin-bottom: 8px;
          .info-list-item-month-name{
            width: 70px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 16px;
            color: #545454;
            text-align: left;
            margin-right: 8px;
          }
          .info-list-item-month-content{
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 16px;
            color: #1D2129;
          }
        }
        .info-list-item-modify{
          display: flex;
          justify-content: space-between;
          .item-left{
            width: 49.5%;
            background: rgba(242,242,242,0.3);
            padding: 12px 8px;
            box-sizing: border-box;
            .item-modify-name{
              font-family: PingFang SC, PingFang SC;
              font-weight: 500;
              font-size: 16px;
              color: #393D44;
              text-align: left;
            }
            .item-modify-item{
              display: flex;
              justify-content: space-between;
              flex-wrap: wrap;
              .item-modify-target{
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                font-size: 14px;
                color: #545454;
              }
              .item-modify-num{
                font-family: PingFang SC, PingFang SC;
                font-weight: 500;
                font-size: 16px;
                color: #333333;
                max-width: 50%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                span{
                  font-size: 12px;
                }
              }
            }
          }
          .item-right{
            width: 49.5%;
            background: rgba(255,140,33,0.05);
            padding: 12px 8px;
            box-sizing: border-box;
            .item-modify-name{
              font-family: PingFang SC, PingFang SC;
              font-weight: 500;
              font-size: 16px;
              color: #393D44;
              text-align: left;
            }
            .item-modify-item{
              display: flex;
              justify-content: space-between;
              flex-wrap: wrap;
              .item-modify-target{
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                font-size: 14px;
                color: #545454;
              }
              .item-modify-num{
                font-family: PingFang SC, PingFang SC;
                font-weight: 500;
                font-size: 16px;
                max-width: 50%;
                color: #FF8C21;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                span{
                  font-size: 12px;
                }
              }
            }
          }
          .item-modify-item-parent,.item-modify-item-child{
            display: flex;
            justify-content: space-between;
            width: 100%;
          }
          .item-modify-item-parent{
            margin-top: 12px;
          }
          .item-modify-item-child-item{
            margin-top: 12px;
            width: 100%;
            display: flex;
            justify-content: space-between;
          }
          .item-modify-target{
            text-align: left;
            width: 45%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .info-list-item-setup{
          width: 100%;
          background: rgba(14,139,255,0.05);
          padding: 12px 8px;
          box-sizing: border-box;
          .item-setup-name{
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 16px;
            color: #393D44;
            text-align: left;
          }
          .item-setup-item{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            // margin-top: 12px;
            .item-setup-target{
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              font-size: 14px;
              color: #545454;
            }
            .item-setup-num{
              font-family: PingFang SC, PingFang SC;
              font-weight: 500;
              font-size: 16px;
              color: #333333;
              span{
                font-size: 12px;
              }
            }
            .item-setup-item-parent,.item-setup-item-child{
              display: flex;
              justify-content: space-between;
              width: 100%;
              margin-top: 12px;
            }
          }
          .item-setup-more{
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 14px;
            color: #333333;
            line-height: 19px;
            margin-top: 12px;
          }
        }
        .info-list-item-setup-height{
          max-height: 165px;
          overflow: hidden;
        }
        .info-list-item-detail{
          margin-top: 8px;
          height: 32px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .item-time{
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #333333;
          }
          .item-operate{
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 16px;
            color: #545454;
          }
        }
      }
      .info-list-item-icon{
        width: 14px;
        height: 14px;
        background: #FF6259;
        border-radius: 50%;
        position: absolute;
        top: 5px;
        left: 5px;
      }
    }

    .no-data {
      height: 100%;
      img {
        margin: 50% auto 0;
        width: 210px;
        height: 137px;
      }

      div {
        font-size: 16px;
        font-family: Bahnschrift, Bahnschrift;
        font-weight: 400;
        color: rgba(51, 51, 51, 0.5);
      }
    }
  }
  .info-list1{
    height: calc(100vh - 105px);
  }
}

.custom{
  p{
    color: #2c3e50;
    font-family: Noto Sans SC;
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    padding-left: 20px;
    line-height: 46px;

  }
  .custom-date {
    height: 40px;
    display: flex;
    justify-content: center;
    border: 1px solid #696969;
    border-radius:10px 10px;
    margin: 10px 20px 20px;
    & > div {
      width: 40%;
      line-height: 40px;
      text-align: center;
      font-size: 16px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #999999;
    }
    .custom-date-interval {
      width: 10%;
      color: #000000;
    }
    .custom-date-content {
      color: #000000;
    }
  }
  .custom-footer {
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px -1px 8px 0px rgba(0, 0, 0, 0.04);
    display: flex;
    justify-content: space-around;
    align-items: center;
    .btn {
      width: 164px;
      height: 46px;
      border-radius: 4px;
      font-size: 18px;
      margin: 10px 0 20px;
    }
  }
}

::v-deep {
  .van-tabs{
    height: calc(100vh - 51px);
    // overflow-y: auto;
    .van-tabs__content{
      height: calc(100% - 46px);
      .van-tab__pane{
        height: 100%;
        .task-content{
          height: calc(100% - 62px);
          overflow: auto;
        }
      }
    }
  }
  
  .van-tab--active{
    color: #3DA2FF;
  }
  .van-tab{
    font-size: 16px;
  }
  .van-picker__confirm{
    color: #1989FA;
  }

  .van-badge {
    min-width: 13px;
    font-size: 9px;
    background: #FF6157;
    padding: 2px 3px 2px;
    border: 0;
  }

  .van-badge--fixed {
    top: 3px;
    right: -1px;
  }

  .van-tab__text--ellipsis{
    overflow: visible !important;;
  }

}
</style>