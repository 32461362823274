<template>
    <div>
        <headerNav title="单据详情" :leftNav="!applyId ? '返回' : null" @leftClick="back"></headerNav>
        <div class="content-style">
            <div class="item-style">
                <div class="item-top">
                    <!-- <van-tag round type="warning" v-if="materialsDetails.applyStatus == 0" size="medium">待审核</van-tag>
                    <van-tag round type="success" v-if="materialsDetails.budgetApplyStatus == 1" size="medium">
                        {{ materialsDetails.stockStatus == 1 ? '已备货' : '未备货' }}
                    </van-tag>
                    <van-tag round type="primary" v-if="materialsDetails.budgetApplyStatus == 2" size="medium">已领取</van-tag>
                    <van-tag round type="danger" v-if="materialsDetails.budgetApplyStatus == 3" size="medium">已拒绝</van-tag> -->
                    <van-tag round type="warning" v-if="materialsDetails.applyStatus == 0 || materialsDetails.applyStatus == 1" size="medium">待审核</van-tag>
                    <van-tag round type="success" v-if="['6','7','8','9'].includes(materialsDetails.applyStatus)" size="medium">
                        {{ materialsDetails.stockStatus == 1 ? '已备货' :  materialsDetails.stockStatus == 2 ? '已领取' : '未备货'}}
                    </van-tag>
                    <van-tag round type="danger" v-if="materialsDetails.applyStatus == 2 || materialsDetails.applyStatus == 4" size="medium">已拒绝</van-tag>
                    <van-tag round type="danger" color="#4A7DFF" v-if="materialsDetails.applyStatus == 10" size="medium">已撤销</van-tag>
                </div>
                <div class="item-content">
                    <div>单据编号:{{ materialsDetails.applyCode }}</div>
                    <div>申领种类:{{ materialsDetails.applyQty }}</div>
                    <div>工程阶段:{{ materialsDetails.nodeName }}</div>
                    <div>申领时间:{{ materialsDetails.createDate }}</div>
                    <div :style="!['6','7','8','9'].includes(materialsDetails.applyStatus) || materialsDetails.stockStatus != 2 ? 'width: 100%;' : ''">
                        订单备注:{{ materialsDetails.remark }}
                    </div>
                    <div v-if="['6','7','8','9'].includes(materialsDetails.applyStatus) && materialsDetails.stockStatus == 2">
                        领取时间:{{ materialsDetails.receiveDate }}
                    </div>
                    <div style="width: 100%;" v-if="materialsDetails.budgetApplyStatus != 0">
                        审批意见:{{ materialsDetails.budgetApplyStatus == 3 ? '拒绝' : '同意' }}
                    </div>
                </div>
            </div>
            <div class="content-list">
                <div class="list-item" v-for="(item, index) in list" :key="item.id">
                    <van-image class="left" :src="item.imgUrl" @click="photoPreview(item.imgUrl)" />
                    <div class="center">
                        <div class="name">{{ item.itemName }}</div>
                        <div>{{ item.modelSpec }}</div>
                        <div>{{ item.unitName }}</div>
                    </div>
                    <div class="right">
                        {{ item.itemQty }}
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="bottom-btn" v-if="materialsDetails.budgetApplyStatus == 1">
            <van-button type="info" round @click="getSubmit">已领取</van-button>
        </div> -->
        <div class="bottom-btn">
            <van-button type="info" @click="handleRevoke" :disabled="materialsDetails.applyStatus !== '0'"
                :color="materialsDetails.applyStatus !== '0' ? '#D1D1D1' : ''">撤销</van-button>
        </div>

    </div>
</template>

<script>
import { Toast, ImagePreview, Dialog } from 'vant';
import { timeFormat } from '@/utils/timeCompute'
import api from '@/api/api.js'
export default {
    data() {
        return {
            list: [],
            materialsDetails: {},
            applyId: null
        }
    },
    methods: {
        //撤销
        handleRevoke() {
            Dialog.confirm({
                title: '撤销确认',
                message: '确认撤销此辅材申请?',
                width: 300,
                confirmButtonColor: '#1989F7'
            }).then(async () => {
                await api.cancelMaterials({ id: this.materialsDetails.id })
                Toast({ message: '撤销成功', icon: '', });
                this.$router.replace({ name: 'engineeringMaterialsApplyEditName' })
            }).catch(() => { });
        },
        //返回
        back() {
            this.$router.push({ name: 'engineeringMaterialsName' })
        },
        //获取详情
        async getMaterialsDetails() {
            const applyId = this.$route.params.applyId
            if (applyId) {
                this.applyId = applyId
                sessionStorage.setItem('materialsDetails', JSON.stringify({ applyId }))
                const res = await api.getMaterialApplyDetails(applyId)
                this.materialsDetails = res.data
                this.list = res.data?.list ?? []
            } else {
                let obj = {
                    Q_applyId_EQ: this.materialsDetails.id
                }
                let res = await api.getMaterialsDetails(obj)
                if (res.code == 200) {
                    this.list = res.data
                }
            }
        },
        //领取提交
        async getSubmit() {
            let obj = {
                id: this.materialsDetails.id,
                receiveDate: timeFormat(new Date(), '-', 'yy-mm-dd HH:mm:ss'),
                budgetApplyStatus: "2"
            }
            let res = await api.receiveSure(obj)
            if (res.code == 200) {
                this.materialsDetails.budgetApplyStatus = '2'
                this.materialsDetails.receiveDate = obj.receiveDate
                sessionStorage.setItem('materialsDetails', JSON.stringify(this.materialsDetails))
                Toast.success('领取成功!');
            }
        },
        //辅材
        photoPreview(url) {
            ImagePreview([url]);
        },
    },
    created() {
        this.materialsDetails = JSON.parse(sessionStorage.getItem('materialsDetails'))
        this.getMaterialsDetails()
    },
}
</script>

<style lang="scss" scoped>
.content-style {
    font-size: 12px;
    padding-top: 10px;
    height: calc(100vh - 60px);
    overflow: auto;

    .item-style {
        padding-top: 10px;
        background: #FFFFFF;
        border-radius: 6px;
        box-shadow: 0px 6px 8px 2px rgba(0, 0, 0, 0.1);
        height: 130px;
        overflow: auto;

        .item-top {
            text-align: right;
            padding-right: 10px;
        }

        .item-content {
            padding: 0 10px;
            display: flex;
            flex-flow: wrap;

            div {
                width: 50%;
                margin: 5px 0;
                text-align: left;
            }
        }
    }

    .content-list {
        margin-top: 10px;
        height: calc(100vh - 210px);
        overflow: auto;
        background: #FFFFFF;

        .list-item {
            border-bottom: 1px solid #E6E6E6;
            height: 70px;
            display: flex;
            padding: 10px 10px;
            align-items: center;

            &:last-child {
                margin-bottom: 100px;
            }

            .left {
                width: 60px;
                height: 60px;
            }

            .center {
                width: calc(100% - 100px);
                padding: 0 5px;

                div {
                    text-align: left;
                }

                .name {
                    // height: 30px;
                }
            }

            .right {
                width: 40px;
            }
        }
    }
}

.bottom-btn {
    position: fixed;
    bottom: 20px;
    width: 100%;

    button {
        width: 80%;
    }
}
</style>