<template>
  <div class="main-task">
    <van-sticky class="topMenu">
      <headerNav leftNav=" " :title="'任务成果上传'" @leftClick="back"></headerNav>
    </van-sticky>
    <main>
      <div class="main-cell-list">
        <van-cell-group v-if="taskTargetObj.taskUploadTitle">
          <van-cell :title="taskTargetObj.taskUploadTitle" :value="routerData.taskFine + taskTargetObj.unit" title-class="cell-group-title" value-class="cell-group-value" />
        </van-cell-group>
        <div class="task-form">
          <van-form @submit="submitUploadFile">
            <van-field v-if="taskTargetObj.formList" v-for="(formItem,formIndex) in taskTargetObj.formList" :key="formIndex" v-model="taskUploadFrom[formItem.props]" :label="formItem.label" label-width="140px" :rules="[{ required: true,pattern:formItem.pattern,message:formItem.message }]" type="number" placeholder="请输入"
              input-align="right">
              <i slot="left-icon" style="color:red">*</i>
              <template #button> <span style="color: #1D2129;">{{ taskTargetObj.unit }}</span> </template>
            </van-field>
            <!-- 上传文件 -->
            <van-uploader v-model="taskUploadFrom.fileList" v-if="taskTargetObj.type == 'file'" accept=".xlsx,.xls,.docx" :preview-image="false" :after-read="readFile">
              <van-field v-model="taskUploadFrom.fileName" label-width="120px" class="input_value" placeholder="请选择" label="附件" input-align="right" @click="onClickSelectFile">
                <i slot="left-icon" style="color:red">*</i>
                <template #button>
                  <van-icon style="float: right;" name="arrow" /> </template>
              </van-field>
            </van-uploader>
            <div class="upload-hint flie_style" v-if="taskTargetObj.type == 'file'">
              <div class="upload-hint-icon">
                <img src="@/assets/task-management/task-board-upload-hint-icon.png" alt="" />
              </div>
              <div class="upload-hint-text">
                注：请选择.xls/.xlsx/.docx格式文件上传
              </div>
            </div>
            <!-- 上传图片 -->
            <van-field v-if="taskTargetObj.type == 'image'" name="uploader" label="附件" label-width="90px" v-model="uploaderNum" show-word-limit maxlength="9">
              <i slot="left-icon" style="color: red">*</i>
              <template #input>
                <van-uploader v-model="imgList" :max-count="9" accept=".jpg, .jpeg, .png, .gif" :multiple="true" :after-read="afterRead" @delete="deleteFile" />
              </template>
            </van-field>
            <!-- 提交 -->
            <div class="footer">
              <van-button type="info" class="btn" native-type="submit" :loading="submitLoading">提交</van-button>
            </div>
          </van-form>
        </div>
      </div>

      <!-- 提示 -->
      <div class="upload-hint" v-if="taskTargetObj.isShowToopits">
        <div class="upload-hint-icon">
          <img src="@/assets/task-management/task-board-upload-hint-icon.png" alt="">
        </div>
        <div class="upload-hint-text">
          注：任务审核将以系统提交时间以及综述错误处进行评分，满分<span class="count">100</span>，延迟提交<span>2分/次</span>，错误<span>1分/处</span>。
        </div>
      </div>
    </main>

    
  </div>
</template>

<script>
import { estimateOutputTarget } from "./data.js"
import { ImagePreview, Toast } from 'vant';
import api from "@/api/task-management/inquiry.js";
import api2 from "@/api/task-management/finance.js";

export default {
  data() {
    return {
      taskUploadFrom: {
        taskFine: '',
        realityAccomplish: '',
        fileName: '',
        fileList: [], // 文件列表
        realityProfitRate:null
      },
      imgList: [],// 图片列表
      // 任务上传信息
      taskTargetObj: {},
      uploaderNum: '',
      // 上传的文件信息
      fileInfo: {},
      // 上传后返回的文件地址
      fileAddress: [],
      // 提交loading
      submitLoading:false
    }
  },
  created() {
    let localData = JSON.parse(localStorage.getItem("TABLE_HEADER_CONTENT"));
    let route = JSON.parse(this.$route.query.row)
    this.routerData = {...localData,...route};
    // 任务成果上传
    this.taskTargetObj = estimateOutputTarget(localData.termSlug)
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    onClickSelectFile() {
    },
    // 文件读取完成后的回调函数--图片
    afterRead(file) {
      this.uploaderNum = ''
      for (var i = 1; i <= this.imgList.length; i++) {
        this.uploaderNum += '-'
      }
    },
    // 文件
    readFile(file){
      this.imgList = []
      let typeList = ['xlsx','xls','docx']
      let type = file.file.name.split('.')[1]
      if(typeList.includes(type)){
        this.taskUploadFrom.fileName =  file.file.name
        this.imgList.push(file)
      }else{
        Toast.fail("只能支持.xls/.xlsx/.docx格式的文件");
        this.imgList = []
        this.taskUploadFrom.fileName = ''
      }
      
    },
    // 文件删除
    deleteFile() {
      this.uploaderNum = ''
      for (var i = 0; i <= this.imgList.length; i++) {
        this.uploaderNum += '-'
      }
    },
    // 上传到oss服务器
    async upLoadFile(file) {
      let formData = new FormData();
      formData.append("files", file);
      formData.append("name", "files");
      formData.append("dir", "oss");
      const data = await api2.upLoad(formData)
      if (data.code == 200) {
        this.fileAddress.push({ fileName: file.name, fileUrl: data.url });
      }
    },
    // 提交
    async submitUploadFile() {
      if ((this.taskTargetObj.type == 'image' && !this.imgList.length) || (this.taskTargetObj.type == 'file' && !this.taskUploadFrom.fileName)) {
        Toast.fail("请上传文件后再提交");
        return;
      }
      this.submitLoading = true
      try{
        let urlList = []
        this.imgList.map(item => {
          urlList.push(this.upLoadFile(item.file))
        })
        await Promise.all(urlList)
        await this.saveTaskResult()
      }finally{
        this.submitLoading =false
      }
    },
    // 保存
    async saveTaskResult() {
      let obj = {
        fileList: this.fileAddress,
        termSlug: this.routerData.termSlug,
        taskFine: this.routerData.termSlug == 'site_routing_inspection'?this.taskUploadFrom.taskFine : this.routerData.taskFine,
        realityAccomplish: this.taskUploadFrom.realityAccomplish,
        realityProfitRate:this.taskUploadFrom.realityProfitRate,
        taskProfitRate:this.routerData.taskFine
      };
      try{
        const data= await api.taskJzResultSubmit(obj)
        if (data.code == 200) {
          Toast.success("上传成功");
          setTimeout(() => {
            this.back();
          }, 1000);
        }
      }catch(e){
        this.fileAddress = []
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.main-task::v-deep {
  height: 100vh;
  background: #f5f5f5;
  .van-field__error-message{
    text-align: right;
  }
  .toopit{
    margin: 10px 14px 0px;
    font-size: 14px;
    text-align: right;
    color: #ada6a6;
  }
  .main-cell-list {
    .van-cell-group {
      border-radius: 0 0 8px 8px;
      margin-bottom: 16px;

      .van-cell {
        height: 55px;
        // line-height: 36px;
        align-items: center;
      }
    }

    .cell-group-title {
      text-align: left;
      font-size: 16px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #545454;
    }

    .cell-group-value {
      font-size: 18px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
    .van-field__value {
      font-size: 16px;
    }
    .task-form {
      .van-cell {
        // height: 55px;
        // line-height: 36px;
        padding: 14px 15px;
      }

      .van-field__label {
        font-size: 16px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #333;
      }
    }
  }

  .upload-hint {
    display: flex;
    align-items: flex-start;
    padding: 16px 12px;

    .upload-hint-icon {
      margin-right: 4px;
      margin-top: -2px;

      img {
        width: 14px;
        height: 14px;
        vertical-align: middle;
      }
    }

    .upload-hint-text {
      font-size: 12px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      color: #545454;
      text-align: left;
      width: 300px;

      span {
        color: #ff6157;
        font-weight: 500;
      }

      .count {
        color: #545454;
      }
    }
  }
  .flie_style{
    text-align: right;
    width: 100%;
  }

  .footer {
    height: 66px;
    line-height: 50px;
    width: 100%;
    background: #fff;
    box-shadow: 0px -1px 8px 0px rgba(0, 0, 0, 0.04);
    text-align: center;
    position: fixed;
    bottom: 0px;
    left: 0px;

    .btn {
      background: #3975c6;
      color: #ffffff;
      border-radius: 4px;
      font-size: 16px;
      font-weight: 500;
      width: 340px;
    }
  }

  .van-uploader__preview-image,
  .van-uploader__upload {
    width: 69px;
    height: 69px;
  }
}
</style>