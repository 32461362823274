<template>
    <div class="materialsApply">
        <headerNav title="辅材申领" leftNav="返回" @leftClick="back"></headerNav>
        <van-tabs v-model="active">
            <van-tab title="辅材库" name="1">
                <template #title>
                    <span class="extantDanger-title">辅材库</span>
                </template>
                <div class="search-style">
                    <van-field class="choosetime-input" v-model="searchValue" clearable left-icon="smile-o"
                        @blur="onRefresh()" placeholder=" 输入材料名称进行搜索">
                        <template v-slot:left-icon>
                            <div style="height: 100%;display: flex;align-items: center;">
                                <img src="../../../../assets/search.png" class="search-icon" alt="">
                            </div>
                        </template>
                    </van-field>
                </div>
                <div class="tab-content">
                    <div class="left-silder">
                        <van-sidebar v-model="activeKey" @change="onChange">
                            <van-sidebar-item :title="item.className" v-for="item in materialsClassList"
                                :key="item.classCode" />
                        </van-sidebar>
                    </div>
                    <div class="list-style">
                        <div class="list-style-title">
                            {{ materialsClassInfo.className }}
                        </div>
                        <div class="list-style-content">
                            <van-list class="vant-list-style" v-model="loading" :finished="finished"
                                :finished-text="total ? '·已加载全部数据·' : '·暂无数据·'" @load="onLoad">
                                <pullRefresh ref="pullRefresh" @refresh="onRefresh">
                                    <div class="list-item" v-for="(item, index) in materialsList">
                                        <van-image class="left" :src="item.imgUrl" @click="photoPreview(item.imgUrl)" />
                                        <div class="center">
                                            <div class="name">{{ item.itemName }}</div>
                                            <div>{{ item.modelSpec }}</div>
                                            <div>{{ item.unitName }}</div>
                                        </div>
                                        <div class="right" @click="chooseChange(item)">
                                            <van-icon v-if="!isExistMaterialsList(item.id)" name="add-o" size="20" />
                                            <van-icon v-else name="close" size="20" color="#ff6c6c" />
                                        </div>
                                    </div>
                                </pullRefresh>
                            </van-list>
                        </div>
                    </div>
                </div>
            </van-tab>
            <van-tab title="已选辅材" name="2">
                <template #title>
                    <span class="extantDanger-title">已选辅材
                        <van-badge class="badge-style" :content="chooseMaterialsIds.length" max="99"
                            v-if="active == 1 && chooseMaterialsIds.length" />
                    </span>
                </template>
                <div class="tab-content">
                    <div class="list-style choose-list">
                        <div class="empty" v-if="!chooseMaterialsList.length">暂无辅材</div>
                        <div v-for="(item, index) in chooseMaterialsList">
                            <van-swipe-cell>
                                <div class="list-item">
                                    <van-image class="left" :src="item.imgUrl" @click="photoPreview(item.imgUrl)" />
                                    <div class="center-choose">
                                        <div class="name">{{ item.itemName }}</div>
                                        <div>{{ item.modelSpec }}</div>
                                        <div>{{ item.unitName }}</div>
                                    </div>
                                    <div class="right-choose">
                                        <van-stepper v-model="item.itemQty" :max="99999" step="1" input-width="40px"
                                            :decimal-length="2" button-size="20px" min="0" />
                                    </div>
                                </div>
                                <template #right>
                                    <van-button square type="danger" text="删除" style="height: 100%;"
                                        @click="chooseChange(item)" />
                                </template>
                            </van-swipe-cell>
                        </div>
                    </div>
                </div>
                <div class="bottom-box">
                    <van-goods-action>
                        <van-goods-action-icon icon="bag-o" :badge="chooseMaterialsIds.length" />
                        <div style="width: 50%;">共计{{ chooseMaterialsIds.length }}种辅材 </div>
                        <van-goods-action-button type="info" text="提交" @click="submit"
                            :disabled="!chooseMaterialsIds.length" />
                    </van-goods-action>
                </div>
                <van-dialog v-model="submitDialog" show-cancel-button :before-close="submitSure"
                    confirmButtonColor="#1989fa">
                    <template #title>
                        <div class="dialog-title">订单备注</div>
                    </template>
                    <div style="padding: 0 20px;">
                        <van-field v-model="remark" rows="2" autosize type="textarea" maxlength="120"
                            placeholder="​请输入订单备注" show-word-limit />
                    </div>
                </van-dialog>
            </van-tab>
        </van-tabs>
    </div>
</template>

<script>
import api from '@/api/api.js'
import {
    Toast, Dialog, ImagePreview
} from 'vant';
export default {
    data () {
        return {
            active: '1',
            searchValue: '',
            activeKey: 0,
            materialsClassList: [],//辅材类别列表
            materialsList: [],//辅材列表
            materialsClassInfo: {},//辅材类别信息
            chooseMaterialsList: [],//已选辅材
            chooseMaterialsIds: [],//已选辅材id
            isLoading: false,
            loading: false,
            finished: false,
            error: false, // 是否加载失败
            pageNum: 1, // 分页
            pageSize: 10, // 每页条数
            total: 0, // 数据总条数
            submitDialog: false,
            remark: '',//备注
        }
    },
    methods: {
        //返回
        back () {
            //如果没有选择辅材可直接返回
            if (!this.chooseMaterialsIds.length) {
                this.$router.push({ name: 'engineeringMaterialsName' })
                return
            }
            //选择辅材后 返回弹出确认框
            Dialog.confirm({
                title: '提示',
                message: '当前单据尚未提交，返回列表后需重新选材，是否返回？',
                messageAlign: 'left'
            })
                .then(() => {
                    this.$router.push({ name: 'engineeringMaterialsName' })
                })
                .catch(() => {

                });
        },
        onChange (e) {
            this.searchValue = ''
            this.materialsClassInfo = this.materialsClassList[e]
            this.onRefresh()
        },
        //获取辅材类别
        async getMaterialsClass () {
            let obj = {}
            let res = await api.getMaterialsClass(obj)
            if (res.code == 200) {
                res.data.forEach(e => {
                    if (e.classCode.indexOf('FC') > -1) {
                        this.materialsClassList.push(...e.children || [])
                    }
                });
                if (this.materialsClassList.length) {
                    this.materialsClassInfo = this.materialsClassList[0]
                }
            }
        },
        //获取辅材列表
        async getMaterialsList (classId) {
            let obj = {
                Q_isOn_EQ: 1,
                Q_classId_EQ: classId,
                Q_itemName_LK: this.searchValue,
                limit: this.pageSize,
                page: this.pageNum,
            }
            let res = await api.getMaterialsList(obj)
            if (res.code == 200) {
                this.total = res.count;
                if (res.data.length === 0) { // 判断获取数据条数若等于0
                    this.materialsList = []; // 清空数组
                    this.finished = true; // 停止加载
                } else {
                    // 若数据条数不等于0
                    this.materialsList.push(...res.data) // 将数据放入list中
                    this.loading = false; // 加载状态结束 
                    // 如果list长度大于等于总数据条数,数据全部加载完成
                    if (this.materialsList.length >= res.count) {
                        this.finished = true; // 结束加载状态
                    }
                }
                if (this.$refs.pullRefresh) {
                    this.$refs.pullRefresh.refreshSuccess()
                }
            }
        },
        // 被 @load调用的方法
        onLoad (e) { // 若加载条到了底部
            let timer = setTimeout(() => { // 定时器仅针对本地数据渲染动画效果,项目中axios请求不需要定时器
                // this.getList(); // 调用上面方法,请求数据
                let T = setInterval(() => {
                    if (this.materialsClassInfo.id) {
                        this.getMaterialsList(this.materialsClassInfo.id)
                        this.pageNum++; // 分页数加一
                        this.finished && clearTimeout(timer); //清除计时器
                        clearTimeout(T);
                    }
                }, 100)
            }, 100);
        },
        // 加载失败调用方法
        onRefresh (e) {
            this.finished = false; // 清空列表数据
            this.loading = true; // 将 loading 设置为 true，表示处于加载状态
            this.pageNum = 1; // 分页数赋值为1
            this.materialsList = []; // 清空数组
            this.onLoad(e); // 重新加载数据
        },
        ///选择/删除辅材
        chooseChange (item) {
            if (this.chooseMaterialsIds.includes(item.id)) {
                let index = this.chooseMaterialsIds.indexOf(item.id)
                this.chooseMaterialsIds.splice(index, 1)
                this.chooseMaterialsList.splice(index, 1)
            } else {
                this.chooseMaterialsIds.push(item.id)
                item.itemQty = 0
                let d = {
                    id: item.id,
                    productId: item.id,
                    classId: item.classId,
                    supplierId: item.supplierId,
                    brandId: item.brandId,
                    brandName: item.brandName,
                    itemNo: item.itemNo,
                    itemName: item.itemName,
                    modelSpec: item.modelSpec,
                    unitName: item.unitName,
                    imgUrl: item.imgUrl,
                    itemQty: item.itemQty,
                    marketCode: item.marketCode,
                    sellingPrice: item.sellingPrice,
                }
                this.chooseMaterialsList.push(d)
            }
        },
        //是否已加入已选辅材列表
        isExistMaterialsList (id) {
            return this.chooseMaterialsIds.includes(id)
        },
        //辅材提交
        submit () {
            let isAmount = true //是否都存在数量
            this.chooseMaterialsList.forEach(e => {
                if (e.itemQty == 0) isAmount = false
            })
            if (isAmount) {
                this.remark = ''
                this.submitDialog = true
            } else {
                Dialog.alert({
                    title: '提示',
                    message: '已选材料数量不可为0，\n请输入数量后在进行提交!',
                })

            }
        },
        //提交确认
        async submitSure (action, done) {
            if (action === 'confirm') { // 确认
                let projectInfo = JSON.parse(sessionStorage.getItem('clientInfo'))
                let obj = {
                    projectId: projectInfo.id,
                    nodeName: projectInfo.nodeName,
                    nodeCode: projectInfo.code,
                    applyQty: this.chooseMaterialsList.length,
                    remark: this.remark,
                    list: this.chooseMaterialsList.map(e => {
                        delete e.id
                        return e
                    })
                }
                try {
                    let res = await api.submitMaterials(obj)
                    if (res.code == 200) {
                        done(); // 关闭提示框
                        this.$router.push({ name: 'engineeringMaterialsName' })
                        Toast.success('提交成功!');
                    }
                } catch (error) {
                    done(false)
                }

            } else if (action === 'cancel') { // 取消
                done(); // 关闭提示框
            }

        },
        //图片预览
        photoPreview (url) {
            ImagePreview([url]);
        },
    },
    created () {
        this.getMaterialsClass()
    }
}
</script>

<style lang="scss" scoped>
.extantDanger-title {
    font-size: 13px;
    // font-family: Source Han Sans SC;
    font-weight: 500;
    color: #333333;
    display: inline-block;
    width: 99%;
    position: relative;
}

.badge-style {
    position: absolute;
    transform: scale(.9);
}

.search-style {
    height: 47px;
    background: #FFFFFF;
    display: flex;
    align-items: center;
    padding: 0 10px;

    .choosetime-input {
        height: 31px;
        line-height: 31px;
        background: #F5F6F8;
        border-radius: 6px;
        padding: 0;
        padding-right: 5px;
        font-size: 13px;
    }

    .search-icon {
        width: 22px;
        height: 22px;
        margin: auto 0;
    }
}

.tab-content {
    display: flex;
    margin-top: 10px;
    background: #FFFFFF;

    .left-silder {
        width: 100px;
        border-right: 1px solid #ebedf0;
    }

    .list-style {
        width: calc(100% - 100px);

        .list-style-title {
            line-height: 40px;
            font-size: 15px;
            font-weight: bold;
            border-bottom: 1px solid #E6E6E6;
        }

        .list-style-content {
            height: calc(100vh - 195px);
            overflow: auto;
        }

        .list-item {
            border-bottom: 1px solid #E6E6E6;
            height: 70px;
            display: flex;
            padding: 5px;
            align-items: center;

            .left {
                width: 60px;
                height: 60px;
            }

            .center {
                padding: 0 5px;
                width: calc(100% - 100px);

            }

            .center,
            .center-choose {
                div {
                    text-align: left;
                }

                .name {
                    // height: 30px;
                }
            }

            .center-choose {
                padding: 0 5px;
                width: calc(100% - 180px);
            }

            .right {
                width: 20px;
            }

            .right-choose {
                width: 100px;
            }
        }
    }

    .choose-list {
        width: 100%;
        height: calc(100vh - 155px);
        overflow: auto;
    }
}

.bottom-box {
    height: 50px;
}

.empty {
    line-height: 40px;
}

.dialog-title {
    text-align: left;
    padding-left: 20px;
}
</style>
<style lang="scss">
.toast-style {
    width: 50%;
}

.materialsApply {
    .van-tab {
        padding: 0;
    }

    .van-tab__text {
        display: inline-block;
        width: 100%;
    }

    .van-tabs__line {
        // transform:none!important;
        background: #4F7AFD;
        width: 49% !important;
        height: 2px;
        background: #4F7AFD;
        border-radius: 2px 2px 0px 0px;
    }

    .van-sidebar-item {
        background: #FFFFFF;
        padding: 10px 0;
    }

    .van-sidebar-item--select {
        color: #4F7AFD;
    }

    .van-sidebar-item--select::before {
        background-color: #4F7AFD;
        right: 0;
        left: auto;
        width: 3px;
    }

    .van-field {
        background: #f2f3f5;
    }
}
</style>