<template>
  <div class="main">
    <headerNav leftNav=" " :title="'任务详情'" @leftClick="back"></headerNav>
    <main>
      <div class="content">
        <van-cell-group class="cell-group-top cell-group-height">
          <template v-for="(item,index) in typeList.table">
            <van-cell :class="item.class"  v-if="!Array.isArray(item.value)" :title="item.label" title-class="cell-group-title" value-class="cell-group-right" @click="cellFn(item.click)">
              <template #right-icon>
                <span v-if="item.unit" class="unit">{{ item.unit }}</span>
                <span v-if="item.click=='subTask'" class="unit"><van-icon name="arrow" /></span>
              </template>
              <template #default>
                <div class="market-icon" v-if="roleData.roleCode == 'ROLE_560934'&&item.prop=='termName'">市场</div>
                <span v-if="upActiveTab == '3'&&item.finishStatus" class="finishStatus" :class="routeQueryInfo.finishStatus?'completed':'incomplete'">{{routeQueryInfo.finishStatus ? "已完成":"未完成"}}</span>
                <span>{{ (item.suffix?(item.value+item.suffix):item.value) }}</span>
              </template>
            </van-cell>
            <van-cell :class="item.class" v-if="Array.isArray(item.value)" :title="item.label+'('+item.value.length+')'" title-class="cell-group-title" value-class="cell-group-right">
              <template #default>
                <div class="file-content">
                  <img class="img" v-for="(item1,index1) in item.value" :key="index1" :src="item1.fileUrl" @click="imagePreview(item1.fileUrl)">
                </div>
              </template>
            </van-cell>
          </template>
        </van-cell-group>
      </div>
    </main>
  </div>
</template>

<script>
import api from "@/api/task-management/human-resources.js";
import { taskDetailModelFn } from "./data.js"
import { Toast,ImagePreview } from "vant";
import moment from "moment";

export default {
  data () {
    return {
      upActiveTab:"",// 上级页面的Tab active
      routeQueryInfo:{},// 路由携带信息 row
      typeList:{},// 模块类型数据
      isModify:false, //是否修改
      // 当前登录人信息
      roleData:null
    }
  },
  async created() {
    this.upActiveTab = this.$route.query.active
  },
  async mounted() {
    this.getTaskDetail()
  },
  methods: {
    back () {
      this.$router.go(-1)
    },
    // 任务详情数据查询
    getTaskDetail(){
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      let info = JSON.parse(this.$route.query.item)
      let params = {
        id:info.id
      }
      api.getTaskDetail(params).then((res)=>{
        if (res.code == 200) {
          Toast.clear()
          this.routeQueryInfo = res.data
          this.roleData = JSON.parse(localStorage.getItem("USER_ROLE"));
          this.typeList = taskDetailModelFn(this.roleData.roleCode,this.routeQueryInfo.termId)
          this.typeList.table.forEach((item,index) => {
            item.value = this.routeQueryInfo[item.prop]?this.routeQueryInfo[item.prop]:''
            if(item.prop == "childTaskTotalCount"){
              item.value = item.value ? item.value : '0'
            }else if(item.prop == "finishRate"){
              item.value = item.value ? parseFloat(item.value).toFixed(2) : '-'
            }
            else{
              item.value = item.value ? item.value : '-'
            }
            this.routeQueryInfo[item.prop] = item.value
          });
        }else{
          Toast.fail(res.msg)
        }
      })
    },
    // cell事件
    cellFn(item){
      if(item == 'subTask'){
        this.$router.push({
          name:'TaskSetupProductSubTask',
          query:{
            upActiveTab: this.upActiveTab,
            taskId: this.routeQueryInfo.id,
            taskDate: this.routeQueryInfo.taskDate,
            termName: this.routeQueryInfo.termName,
            standardRate: this.routeQueryInfo.standardRate,
          }
        })
      }
    },
    // 预览图片
    imagePreview(url){
      ImagePreview({
        images: [url],
        closeable: true,
        showIndex: false
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.main::v-deep {
  background: #F5F5F5;
  height: 100vh;

  .content {
    height: calc(100vh - 51px);
    overflow: auto;

    .cell-group-top {
      border-radius: 0px 0px 8px 8px;
      overflow: auto;
    }

    .cell-group-height{
      max-height: calc(100% - 90px);
    }

    .cell-group-center {
      border-radius: 8px;
      overflow: hidden;
      margin: 8px 0;

      .accessory {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .accessory-img {
          width: 20px;
          height: 24px;
          margin-right: 4px;
        }

        .accessory-text {
          font-size: 14px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          color: #0084F4;
        }
      }
    }

    .cell-group-bottom {
      border-radius: 8px;
      overflow: hidden;

      .mistakes {
        span {
          color: #ED4040;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
        }
      }

      .postpone {
        span {
          color: #FF8C21;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
        }
      }

      .final-score {
        span {
          color: #0084F4;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
        }
      }
    }

    .cell-group-title {
      font-size: 16px;
      font-family: PingFang TC, PingFang TC;
      font-weight: 400;
      color: #999999;
      text-align: left;
    }

    .cell-group-right,.van-field__control {
      font-size: 16px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #333;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .market-icon{
        display: inline-block !important;
        line-height: 25px;
        padding: 0 4px;
        background: rgba(242,109,12,0.1);
        border-radius: 3px 3px 3px 3px;
        font-size: 16px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #F26D0C;
        margin-right: 8px;
      }
      .finishStatus{
        display: inline-block;
        width: 52px;
        line-height: 22px;
        font-size: 12px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        text-align: center;
        margin-right: 8px;
        border-radius: 4px;
      }
      .completed{
        background: #17CA5E;
      }
      .incomplete{
        background: #FF6259;
      }
    }
    .van-field__label {
      font-size: 16px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      color: #1d2129;
    }
    .van-field__value {
      font-size: 16px;
    }

    .footer{
      width: 100%;
      background: #fff;
      padding: 11px 18px 30px;
      box-sizing: border-box;
      box-shadow: 0px -1px 8px 0px rgba(0,0,0,0.04);
      position: absolute;
      bottom: 0;
      .footer-btn{
        line-height: 44px;
        font-size: 18px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        background: #3975C6;
        border-radius: 4px 4px 4px 4px;
      }
      .untouched{
        opacity: .5;
      }
    }
  }

  .van-field__control{
    text-align: right;
  }
  
  .van-field__button{
    color: #000 !important;
  }
  .unit{
    color: #000 !important;
    padding-left: 8px !important;
  }
  .van-cell__value{
    max-width: 60%;
    overflow: visible;
    span{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .file-content{
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    width: 255px;

    .img{
      width: 72px;
      height: 72px;
      margin:0 0 11px 11px;
      border-radius: 4px;
      border: 1px solid rgba(217,217,217,0.4);
    }
  }
  .interval{
    border-top: 10px solid #F5F5F5;
  }

  
}
</style>