<template>
  <div>
    <headerNav title="合家和任务看板"></headerNav>
    <!-- 主体内容 -->
    <div class="main-content">
      <!-- 顶部导航 -->
      <nav class="navbar">
        <div class="nav-left-text">
          <img src="../../../assets/task-management/task-nav-text.png" alt="">
        </div>
        <!-- 任务中心 -->
        <div class="nav-right-setting" @click="onClickTaskCenter" v-if="userRole && userRole.show == '1'">
          <img src="../../../assets/task-management/task-nav-seting.png" alt="">
          <van-badge v-if="hintData" :content="hintData"
            :max="99">
            <div class="task-text">任务中心</div>
          </van-badge>
          <div v-else class="task-text">任务中心</div>
        </div>
      </nav>
      <div class="advcost" @click="toAdvcost" v-if="hasRolePermission('mission:center:monthly')">
        <div class="task-text"> 门店经营月报</div>
      </div>

      <!-- 任务指标 -->
      <main class="main-center">
        <!-- 任务指标 -->
        <div class="main-center-left">
          <div class="main-center-left-nav">任务指标</div>
          <div class="main-center-left-content" v-if="taskOptions">
            <div class="main-center-left-content-main">
              <div class="main-center-left-content-box" v-for="(taskItem, taskIndex) in taskOptions" :key="taskIndex"
              @click="onClickTask(taskItem)">
              <span class="m-c-l-b-text">{{ taskItem.termName }}</span>
              <span class="m-c-l-b-count" :class="{ color_green: Number(taskItem.termData) > 100 }">
                {{ taskItem.termData + (taskItem.termSlug != 'overview_report' && taskItem.termSlug != 'market_summarize'?'%':'')}}</span>
            </div>
            </div>
          </div>
          <!-- 暂无数据 -->
          <div class="no-data" v-else>
            <img src="@/assets/task-management/task-setup-nodata.png" alt="">
            <div>暂无指标</div>
          </div>
        </div>
        <!-- 导航栏 -->
        <div class="main-center-right">
          <van-sidebar v-model="navActiveKey">
            <van-sidebar-item v-for="(item, index) in navOptions" :key="index" @click="changeNavBar(item, index)">
              <template slot="title">
                <div class="main-center-right-nav">
                  <div class="img-box"><img :src="getImagePath(item, index)" alt=""></div>
                  <div>{{ item.termClassifyName }}</div>
                </div>
              </template>
            </van-sidebar-item>
          </van-sidebar>
        </div>

      </main>
    </div>
  </div>
</template>

<script>
import api from "@/api/task-management/board.js";
import moment from "moment";
import { Toast } from "vant";
import { timeFormat } from "@/utils/timeCompute";

import { hasRolePermission } from '../../../hooks/usePermission';
export default {
  components: {
  },
  props: {},
  data() {
    return {
      // 左侧任务指标
      taskOptions: [],
      // 右侧导航
      navActiveKey: 0,
      navOptions: [],
      // 任务中心数量
      hintData: 0,
      // 加载动画
      showLoading: false

    };
  },
  computed: {
    userRole() {
      return this.$store.state.userRole;
    }
  },
  watch: {},
  created() {
    // 获取当前选中的导航
    let active = localStorage.getItem('ACTIVE_RIGHT_NAV')
    console.log(active,"选择");
    if (active) {
      this.navActiveKey = active
    }
    this.getTaskIndicator()
  },
  mounted() {
  },
  methods: {
    // 跳转任务中心
    onClickTaskCenter() {
      let roleCode = this.userRole.roleCode
      // 财务部门任务管理中心
      /*
       财务总监 ROLE_830682
       财务经理 ROLE_028622
       收银员   ROLE_087091
       出纳     ROLE_748877
       成本会计   ROLE_666422
       费用会计   ROLE_519546

       预审角色
        ROLE_097074  预审总监
        ROLE_922446  预审经理
        ROLE_271913  预审专员
      */
    //  财务总监、财务经理
      if(roleCode == 'ROLE_830682' || roleCode == 'ROLE_028622' || roleCode == 'ROLE_666422' || roleCode == 'ROLE_519546'){
        this.$router.push({ path: '/task-management/setup/finance/index' })
      }else if(roleCode == 'ROLE_097074' || roleCode == 'ROLE_922446' || roleCode == 'ROLE_271913'){
        // 预审总监、经理、专员
        this.$router.push('/task-management/setup/inquiry/index')
      }else if(roleCode == 'ROLE_983041' || roleCode == 'ROLE_201262' || roleCode == 'ROLE_477142'){
        // 人事总监、经理、专员
        this.$router.push({name:"TaskSetupHumanResources"})
      }else if(roleCode == 'ROLE_637652' || roleCode == 'ROLE_560934' || roleCode == 'ROLE_360483' || roleCode == 'ROLE_764182'){
        // 产品
        this.$router.push({name:"TaskSetupProduct"})
      }else{
        // 第一版看板跳转权限--总经理，运营总裁
        this.$router.push({ path: '/task-management/setup/task-center' })
      }
    },
    // 查询任务指标
    async getTaskIndicator() {
      try {
        Toast.loading({
          message: '加载中...',
          forbidClick: true,
        });
        const { data } = await api.taskTermApi({ Q_reportData_EQ: timeFormat(new Date()).slice(0, -3) })
        console.log(data);
        // 任务中心
        this.hintData = data.hintData
        if (data && data.children.length) {
          data.children.map(item => {
            item.icon = this.setImagePath(item.termClassifyName)
          })
           // 右侧导航
          this.navOptions = data.children
           // 左侧任务指标
          this.taskOptions = data.children[this.navActiveKey].termDataList
        }
      } finally {
        Toast.clear()
      }
    },
    // 设置图标
    setImagePath(name) {
      let icon = ''
      if (name == '全部') {
        icon = 'all'
      }
      if (name == '营销') {
        icon = 'marketing'
      }
      if (name == '销售') {
        icon = 'sales'
      }
      if (name == '施工') {
        icon = 'build'
      }
      if (name == '人事') {
        icon = 'personnel'
      }
      if (name == '财务') {
        icon = 'finiance'
      }
      if (name == '预审') {
        icon = 'preliminary'
      }
      if (name == '产品') {
        icon = 'product'
      }
      return icon
    },
    // 获取图片地址
    getImagePath(item, index) {
      return require(`@/assets/task-management/task-main-nav-${item.icon}-${this.navActiveKey == index ? '1' : '2'}.png`);
    },
    // 点击右侧导航切换对应内容
    changeNavBar(item, index) {
      this.navActiveKey = index
      this.taskOptions = item.termDataList
       // 存放当前点击的分类
       localStorage.setItem('ACTIVE_RIGHT_NAV', this.navActiveKey)
    },
    // 点击任务指标
    onClickTask(item) {
      if(!item.available){
        return false
      }
      item.Q_reportData_EQ = timeFormat(new Date()).slice(0, -3)
      localStorage.setItem('TABLE_HEADER_CONTENT', JSON.stringify(item))
      // 转单-补齐-转单量-补齐量-签单量-到店量-线索量
      if (
        item.termSlug == 'transfer_output' ||
        item.termSlug == 'supply_output' || 
        item.termSlug == 'transfer_count' || 
        item.termSlug == 'supply_count' || 
        item.termSlug == 'signed_count' || 
        item.termSlug == 'shop_count' || 
        item.termSlug == 'clue_count') {
        this.taskIndicatorDetails(item)
      }
      //财务  收银--记账-审核-市场综述
      if(
        item.termSlug == 'cashier_timely' ||
        item.termSlug == 'keep_accounts_timely' ||
        item.termSlug == 'check_timely' ||
        item.termSlug == 'market_summarize'
      ){
        this.taskFinanceDetails(item)
      }
      //预审  预估--结算-结转-工地巡检--工地罚金--综述报告
      if(
        item.termSlug == 'estimate_profit' ||
        item.termSlug == 'settle_accounts_profit' ||
        item.termSlug == 'balance_profit' ||
        item.termSlug == 'site_routing_inspection' ||
        item.termSlug == 'site_penalty' ||
        item.termSlug == 'overview_report'
      ){
        this.taskInquiryDetails(item)
      }
      // 人事
      if(
        item.termSlug == 'invite_job' ||
        item.termSlug == 'pay_submit' ||
        item.termSlug == 'employee_training' ||
        item.termSlug == 'cost_payment' ||
        item.termSlug == 'admin_inspection' ||
        item.termSlug == 'dorm_inspection'
      ){
        this.taskHumanResourcesDetails(item)
      }
      // 产品
      if(
        item.termSlug == 'delay_happen' ||
        item.termSlug == 'delay_solve' ||
        item.termSlug == 'after_sale_building' ||
        item.termSlug == 'after_sale_finish' ||
        item.termSlug == 'after_sale_solve' ||
        item.termSlug == 'complaint_happen' ||
        item.termSlug == 'complaint_solve' 
      ){
        this.taskProductDetails(item)
      }
    },
    // 前台总监 点击任务指标跳转对应详情
    taskIndicatorDetails(item) {
      // 判断登录人权限跳转对应的详情列表
      let roleCode = item.roleCode
      let routerName = ''
      // 运营总裁和董事长--查看市场列表
      if (roleCode == "ROLE_603933" || roleCode == "ROLE_394260") {
        routerName = 'market-list'

      } else if (roleCode == "ROLE_768022" || roleCode == "ROLE_886285") {
        // 总经理和前台总监--查看部门列表
        // 线索量完成率要跳转市场，并且通过市场跳转到部门
        if (item.termSlug == 'clue_count') {
          routerName = 'market-list'

        } else if (item.termSlug == 'transfer_output' || item.termSlug == 'supply_output' || item.termSlug == 'transfer_count' || item.termSlug == 'supply_count') {
          // 转单产值，补齐产值、转单量、补齐量--查看督导详情和转单详情的部门列表
          routerName = 'department-list'
        } else {
          // 签单量到店量--查看单独的部门列表
          routerName = 'department-alone-list'
        }

      } else if (roleCode == "ROLE_113490" || roleCode == "ROLE_327530" || roleCode == "ROLE_553504" || roleCode == "ROLE_670920" || roleCode == "ROLE_691147") {
        // 督导经理，转单经理，签单经理，话务经理，--查看人员列表,数综经理--线索量看市场
        if (item.termSlug == 'clue_count') {
          routerName = 'market-list'

        } else {
          routerName = 'employee-list'
        }
        localStorage.removeItem('USER_DATA');
      } else {
        // 其他人（专员）--查看人员详情
        routerName = 'employee-single'
      }
      this.$router.push({ path: `/task-management/board/${routerName}` })
    },
    // 财务模块
    taskFinanceDetails(item){
      /* 角色权限 
       财务总监 ROLE_830682
       财务经理 ROLE_028622
       收银员   ROLE_087091
       出纳     ROLE_748877
       成本会计   ROLE_666422
       费用会计   ROLE_519546
      */
      let roleCode = item.roleCode
      /* 要跳转的路由地址 */
      let routerName = ''
      // 财务总监  总经理  董事长
      if(roleCode == 'ROLE_830682' || roleCode == 'ROLE_394260'){
        routerName = 'market-list'
      }else if(roleCode == 'ROLE_028622' || roleCode == 'ROLE_768022'){
        /* 财务经理  总经理 */
        /* 市场综述跳转任务上传页面 */
        if(item.termSlug == 'market_summarize'){
          routerName = 'market-overview'
        }else{
          /* 其他跳转人员列表 */
          routerName = 'employee-list'
        }
      }else if(roleCode == 'ROLE_087091' || roleCode == 'ROLE_748877' || roleCode == 'ROLE_666422'){
        /* 收银员、出纳、成本会计 */
        routerName = 'employee-single'
      }
      this.$router.push({ path: `/task-management/board/finance/${routerName}` })
    },
    // 预审模块指标项
    taskInquiryDetails(item){
      /* 角色权限 */
      let roleCode = item.roleCode
      /* 要跳转的路由地址 */
      let routerName = ''
      // 总监 董事长
      if(roleCode == 'ROLE_097074' || roleCode == 'ROLE_394260'){
        routerName = 'market-list'
      }else if(roleCode == 'ROLE_922446'|| roleCode == 'ROLE_768022'){ // 经理 总经理
        routerName = 'employee-list'
      }else if(roleCode == 'ROLE_271913'){ // 专员
        // 预估利润、结算利润
        if(item.termSlug == 'estimate_profit' || item.termSlug == 'settle_accounts_profit'){
          routerName = 'employee-single'
        }else{
          routerName = 'employee-details'
        }
      }
      this.$router.push({path:`/task-management/board/inquiry/${routerName}`,query:{row:JSON.stringify(item)}})
    },
    // 人事模块
    taskHumanResourcesDetails(item){
      // ROLE_983041        人力资源总监
      // ROLE_201262        人力资源经理
      // ROLE_477142        人力资源专员
      
      /* 角色权限 */
      let roleCode = item.roleCode
      /* 要跳转的路由地址 */
      let routerName = ''
      // 总监 董事长
      if(roleCode == 'ROLE_983041' || roleCode == 'ROLE_394260'){
        routerName = 'TaskBoardHumanResourcesMarketList'
      }else if(roleCode == 'ROLE_201262' || roleCode == 'ROLE_768022'){
        /* 经理  总经理 */
        routerName = 'TaskBoardHumanResourcesEmployeeList'
      }else if(roleCode == 'ROLE_477142'){
        /* 专员 */
        if(item.termSlug == 'invite_job'){
          routerName = 'TaskBoardHumanResourcesRecruitmentTrialDetail'
        }else{
          routerName = 'TaskBoardHumanResourcesEmployeeSingle'
        }
      }
      this.$router.push({ name:routerName })
    },
    // 产品模块
    taskProductDetails(item){
      // ROLE_637652        产品总监
      // ROLE_560934        产品经理
      // ROLE_360483        产品物料主管
      // ROLE_764182        产品家具主管
      
      /* 角色权限 */
      let roleCode = item.roleCode
      /* 要跳转的路由地址 */
      let routerName = ''
      // 总监  董事长
      if(roleCode == 'ROLE_637652' || roleCode == 'ROLE_394260'){
        routerName = 'TaskBoardProductMarketList'
      }else if(roleCode == 'ROLE_560934' || roleCode == 'ROLE_768022'){
        /* 经理 总经理*/
        routerName = 'TaskBoardProductEmployeeList'
      }else if(roleCode == 'ROLE_360483'||roleCode == 'ROLE_764182'){
        /* 专员 */
        routerName = 'TaskBoardProductEmployeeSingle'
      }
      this.$router.push({ name:routerName })
    },
    //前往门店经营月报
    toAdvcost(){
      this.$router.push({
        name:'TaskAdvcost'
      })
    }
  },
};
</script>

<style scoped lang="scss">
.main-content::v-deep {
  height: calc(100vh - 49px);
  background: url('../../../assets/task-management/mission-center.png') no-repeat;
  background-size: cover;
  background-position: 0 -15px;
  margin-top: -1px;

  // 顶部导航
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 0px 0px 8px;

    // 左侧文字
    .nav-left-text {
      width: 245.75px;
      height: 45.68px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    // 任务中心
    .nav-right-setting {
      width: 119px;
      height: 26px;
      line-height: 26px;
      font-size: 15px;
      color: #1D2129;
      background: #fff;
      border-radius: 20px 0px 0px 20px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      padding-left: 6px;
      padding-left: 12px;
      .van-badge {
        min-width: 13px;
        font-size: 9px;
        background: #FF6157;
        padding: 2px 3px 2px;
        border: 0;
      }

      .van-badge--fixed {
        top: 3px;
        right: -1px;
      }

      img {
        width: 16px;
        height: 16px;
        padding: 0px 3px 0px 0;
      }

      .task-text {
        // height: 80%;
      }
    }
  }

  //任务指标
  .main-center {
    height: calc(100% - 90px);
    display: flex;
    justify-content: space-between;
    border-radius: 14px 14px 0 0;
    overflow: hidden;
    margin: 36px 12px 0px 12px;
    background-color: #fff;
    border-right: 1px solid #fff;

    .main-center-left {
      background: url('../../../assets/task-management/task-main-left-background.png') no-repeat;
      background-size: 320px 165px;
      background-position: left 92%;

      // 任务指标标题
      .main-center-left-nav {
        width: 270px;
        height: 54.98px;
        line-height: 54.98px;
        color: #1D2129;
        font-size: 19px;
        font-weight: bold;
        text-align: left;
        padding: 0 16px;
        background: linear-gradient(175deg, #E0EDFF 0%, #FFFFFF 50%);
      }

      // 任务指标内容
      .main-center-left-content {
        overflow: auto;
        height: calc(100vh - 280px);
        .main-center-left-content-main{
          display: flex;
          flex-wrap: wrap;
          padding-left: 6px;
        }
        .main-center-left-content-box {
          width: 118px;
          height: 48px;
          padding: 12px 8px;
          margin: 6px;
          background: #F7F8FB;
          text-align: left;
          border-radius: 8px;
          display: flex;
          flex-direction: column;

          .m-c-l-b-text {
            font-size: 14px;
            color: #1D2129;
          }

          .m-c-l-b-count {
            font-size: 20px;
            color: #333;
            font-weight: 600;
          }

          .color_green {
            color: #16A44F;
          }
        }
      }

      .no-data {
        margin: 130px auto;

        img {
          width: 210px;
          height: 137px;
        }

        div {
          font-size: 16px;
          font-family: Bahnschrift, Bahnschrift;
          font-weight: 400;
          color: rgba(51, 51, 51, 0.5);
        }
      }

    }

    .main-center-right {
      font-size: 15px;
      background: linear-gradient(270deg, #E5F0FF 0%, #FFFFFF 90%);

      .main-center-right-nav {
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .img-box {
          display: flex;
          justify-content: center;
          align-content: center;
          width: 100%;
          text-align: center;

          img {
            width: 20px;
            height: 20px;
            margin-left: -2px;
          }
        }


        div {
          width: 30px;
          display: flex;
          flex-wrap: nowrap;

        }

      }

      .van-sidebar {
        width: 48px;
        background: #E5F0FF;
        box-sizing: border-box;

        .van-sidebar-item {
          width: 100%;
          height: 64px;
          padding: 8px 10px;
          // background: #E5F0FF;

          background: linear-gradient(270deg, #E5F0FF 0%, #FFFFFF 90%);
        }

        .van-sidebar-item--select {
          color: #0E8BFF;
          border-radius: 0px 14px 14px 0;

          background: linear-gradient(270deg, #ecf2f9 0%, #FFFFFF 50%);
          overflow: hidden;

          &::before {
            display: none;
          }
        }
      }

    }

    .loading-box {
      width: 100%;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.advcost {
  width: 119px;
  height: 26px;
  line-height: 26px;
  font-size: 15px;
  color: #1D2129;
  background: #fff;
  border-radius: 20px 0px 0px 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  padding-left: 12px;
}
</style>
