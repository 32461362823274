<template>
    <div>
        <van-sticky>
            <headerNav leftNav=" " class="nav_b" @leftClick="back" :title="'公司员工结构数据'" />
        </van-sticky>
        <div class="cont">
            <div class="content">
                <p>数据总览</p>
                <div class="main">
                    <div>
                        <span class="span">在职人数</span>
                        <animate-number from="0.00" :to="'309'" duration="2000" from-color="#44CC00" to-color="#0272FC"
                            style="font-size:20px;text-align:center;display:block;font-weight:bold"></animate-number>
                    </div>
                    <div>
                        <span class="span">入职人数</span>
                        <animate-number from="0.00" :to="'103'" duration="2000" from-color="#ffffff" to-color="#0272FC"
                            style="font-size:20px;text-align:center;display:block;font-weight:bold"></animate-number>
                    </div>
                </div>
                <div class="main">
                    <div>
                        <span class="span">离职人数</span>
                        <animate-number from="0.00" :to="'39'" duration="2000" from-color="#44CC00" to-color="#0272FC"
                            style="font-size:20px;text-align:center;display:block;font-weight:bold"></animate-number>
                    </div>
                    <div>
                        <span class="span">离职率</span>
                        <animate-number from="0.00" :to="14.31" duration="2000" from-color="#ffffff" to-color="#0272FC"
                            style="font-size:20px;text-align:center;display:block;font-weight:bold">%
                        </animate-number>
                    </div>
                </div>
            </div>
            <!-- 部门人数 -->
            <div class="section">
                <p class="p_div">数据总览</p>
                <div class="sectionEcharts"></div>
            </div>
            <!-- 新进人数以及离职人数 -->
            <div class="section">
                <p class="p_div">各部门新进人数以及离职人数</p>
                <div class="employees"></div>
            </div>
            <!-- 员工性别及年龄占比 -->
            <div class="section">
                <p class="p_div">员工性别及年龄占比</p>
                <div class="sectionMain">
                    <div>
                        <span>40%</span>
                        <img src="../../../assets/girl.png" alt="">
                    </div>
                    <div>
                        <span>60%</span>
                        <img src="../../../assets/boy.png" alt="">
                    </div>
                </div>
                <!-- 年龄段 -->
                <div class="age_main">
                    <div v-for="item in dataSource" :key="item.id" class="flex">
                        <div>{{ item.age }}</div>
                        <van-progress class="progress" color="#0272FC" stroke-width="8" pivot-text=" "
                            :percentage="item.percent" />
                        <div>{{ item.percent + '%' }}</div>
                    </div>
                </div>
                <!-- 学历结构 -->
                <div class="section">
                    <p class="p_div">学历结构(%)</p>
                    <div class="education"></div>
                </div>
            </div>
        </div>
        <!-- <div class="echartMain"></div> -->
    </div>
</template>

<script>
import * as echarts from 'echarts';
export default {

    data () {
        return {
            show: false,
            routerRow: {},
            dataSource: [
                {
                    id: 1,
                    age: '18-24岁',
                    percent: 5
                },
                {
                    id: 1,
                    age: '25-29岁',
                    percent: 25
                },
                {
                    id: 1,
                    age: '30-34岁',
                    percent: 30
                },
                {
                    id: 1,
                    age: '35-39岁',
                    percent: 20
                },
                {
                    id: 1,
                    age: '40-44岁',
                    percent: 15
                },
                {
                    id: 1,
                    age: '45岁以上',
                    percent: 5
                },
            ],
            labelOptions: []
        }
    },
    created () {
        this.routerRow = JSON.parse(this.$route.query.row)
    },
    mounted () {
        this.$nextTick(() => {
            this.handleSection()
            this.handleEmployees()
            this.handleEducation()
        })
    },
    methods: {
        // 部门人数
        handleSection () {
            let option = {
                xAxis: {
                    type: 'category',
                    data: ['开发部', '设计部', '人事部', '财务部', '销售部', '客服部', '运营部'],
                    axisLabel: {
                        color: '#fff'
                    },
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        color: '#fff'
                    },
                },
                series: [
                    {
                        data: [120, 200, 150, 80, 70, 110, 130],
                        type: 'bar',
                        showBackground: true,
                        itemStyle: {
                            color: "#0272FC"
                        },
                        backgroundStyle: {
                            color: 'rgba(180, 180, 180, 0.2)'
                        }
                    }
                ]
            };
            this.handleEcharts('.sectionEcharts', option)
        },
        // g各部门新进人数以及离职人数
        handleEmployees () {
            let option = {
                legend: {
                    top: '5%',
                    bottom: '5%',
                    left: 'center',
                    textStyle: {
                        color: '#fff'
                    }
                },
                title: {
                    textStyle: {
                        color: '#fff'
                    },
                },
                dataset: {
                    source: [
                        ['', '新进人数', '离职人数'],
                        ['开发部', 15, 4],
                        ['设计部', 85, 23],
                        ['人事部', 83, 37],
                        ['财务部', 86, 65],
                        ['销售部', 54, 32],
                        ['客服部', 67, 32],
                        ['运营部', 86, 65],
                    ]
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                xAxis: {
                    type: 'category',
                    axisLabel: {
                        color: '#fff'
                    },
                },
                yAxis: {
                    axisLabel: {
                        color: '#fff'
                    },
                },
                // Declare several bar series, each will be mapped
                // to a column of dataset.source by default.
                series: [{ type: 'bar' }, { type: 'bar' }]
            };
            this.handleEcharts('.employees', option)
        },
        // 学历结构
        handleEducation () {
            let option = {
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    // top: '5%',
                    left: 'center',
                    textStyle: {
                        color: '#fff'
                    },
                    x: 'left', //可设定图例在左、右、居中
                    y: 'bottom',
                },
                series: [
                    {
                        name: '学历结构',
                        type: 'pie',
                        radius: ['60%', '80%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: true,
                            position: 'center',

                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: '30',
                                fontWeight: 'bold',
                                color: '#fff'
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: [
                            { value: 29.9, name: '本科' },
                            { value: 15.8, name: '博士' },
                            { value: 22.8, name: '硕士' },
                            { value: 18.9, name: '大专' },
                            { value: 12.6, name: '博士后' }
                        ],
                        emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      }
                    }
                ]
            };
            this.handleEcharts('.education', option)
        },
        // 封装echarts
        handleEcharts (name, option) {
            var chartDom = document.querySelector(name);
            // var chartDom = document.getElementById('main');
            var myChart = echarts.init(chartDom, 'night');
            option && myChart.setOption(option);
        },
        onCancel () {
            Toast('取消');
        },
        //回退
        back () {
            this.$router.push({
                name: "statistics",
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.nav_b {
    background: #0B0B67;
}

.cont {
    background: #0B0B67;
    height: 100%;
    padding: 10px;

    .content {
        p {
            color: #fff;
            text-align: left;
            font-size: 16px;
        }
    }

    .main {
        color: #fff;
        display: flex;
        justify-content: space-around;

        .span {
            font-size: 14px;
            margin-bottom: 10px;
        }
    }

    .section {
        color: #fff;

        .p_div {
            font-size: 16px;
            text-align: left;
            margin-bottom: 0px;
        }

        .sectionEcharts {
            width: 100%;
            height: 400px;
        }

        .employees {
            width: 100%;
            height: 400px;
        }

        .employees {
            width: 100%;
            height: 400px;
        }

        .sectionMain {
            display: flex;
            margin-top: 10px;

            div {
                display: flex;
                flex-direction: column;
                margin-right: 50px;

                img {
                    width: 44px;
                    height: 76px;
                    margin-top: 10px;
                }
            }
        }
        // 学历
        .education{
            width: 100%;
            height: 400px;
        }
        .age_main {
            width: 95%;
            margin: 0px auto;
            margin-top: 20px;

            .flex {
                display: flex;
                align-items: center;
                justify-content: space-around;
                margin-bottom: 15px;
            }

            .progress {
                width: 73%;
            }
        }
    }
}
</style>