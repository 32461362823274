<template>
    <div class="my-filter1">
        <div v-for="(item, index) in filterOptions" class="filter-item" @click="handleClick(item)" :key="index">
            <span v-if="item.type == 'timeQuantum'" style="display: flex;flex-direction: column;">
                <span>{{ item.label[0] }}</span>
                <span>{{ item.label[1] }}</span>
            </span>
            <span v-else>{{ item.label }}</span>
            <van-popover v-model="item.showPopover" trigger="click" :show-arrow="false" :get-container="getContainer"
                :offset="item.type == 'timeQuantum' ? item.offset ? item.offset : [-140,11] : [60, 11]"
                :overlay="item.type == false">
                <template #reference>
                    <img :src="require('@/assets/icon1.png')" class="icon" :class="item.showPopover?'icon_rotate':''" />
                </template>
                <div v-if="item.type == 'select'" style="max-height:400px;overflow-y: scroll;width:100vw;padding-top:10px;padding-bottom:10px">
                    <div v-for="selectItem in item.actions" @click="handSelectChange(item, selectItem)"
                        :key="selectItem.value"
                        :class="selectItem.value == item.activeValue ? 'select-item select-item-active' : 'select-item'">{{
                            selectItem.text + selectItem.count }}</div>
                </div>
                <div v-if="item.type == 'timeQuantum'" class="time-style">
                    <div class="showdate">
                        <span :style="startDate ? '' : 'color: #CCCCCC;'">{{ startDate ? startDate : '开始时间' }}</span>
                        <span class="slicer"></span>
                        <span :style="endDate ? '' : 'color: #CCCCCC;'">{{ endDate ? endDate : '结束时间' }}</span>
                    </div>
                    <div class="left-icon left-1" @click="handChangeDate('deYear')">
                        <img :src="require('@/assets/icon4.png')" class="calender-icon" />
                    </div>
                    <div class="left-icon left-2" @click="handChangeDate('deMonth')">
                        <img :src="require('@/assets/icon2.png')" class="calender-icon" />
                    </div>
                    <div class="left-icon right-2" @click="handChangeDate('adMonth')">
                        <img :src="require('@/assets/icon3.png')" class="calender-icon" />
                    </div>
                    <div class="left-icon right-1" @click="handChangeDate('adYear')">
                        <img :src="require('@/assets/icon5.png')" class="calender-icon" />
                    </div>
                    <van-calendar row-height="60" :min-date="minDate" :max-date="maxDate" ref="calender" :poppable="false"
                        color="#4871C0" :show-title="false" :show-mark="false" :show-confirm="false" type="range"
                        :default-date="null" @select="handleSelect" allow-same-day :formatter="formatter"/>
                </div>
                <div class="bottom-btn" v-if="item.type == 'timeQuantum'">
                    <div class="cancel" @click="handReset(item)">重置</div>
                    <div class="sure" @click="handSure(item)">确认</div>
                </div>
            </van-popover>
        </div>
    </div>
</template>

<script>
import {
    timeFormat
} from '@/utils/timeCompute'
export default {
    data () {
        return {
            filterOptions: [],
            minDate: new Date(new Date().getFullYear() - 10, new Date().getMonth(), 1),//可选最小日期，当前日期十年前
            maxDate: new Date(new Date().getFullYear() + 10, new Date().getMonth(), 30),//可选最大日期，当前日期十年后
            monthCont: 0,//月加减次数
            yearCont: 0,//年加减次数
            year: new Date().getFullYear(),//当前年
            month: new Date().getMonth(),//当前月
            startDate: '',//开始日期
            endDate: '',//结束日期
        }
    },
    props: {
        //筛选参数
        filterList: {
            type: Array,
            default: () => []
        },
    },
    methods: {
        // 设置挂载的节点
        getContainer(){
            return document.querySelector('.my-filter1');
        },
        //筛选项点击事件
        handleClick (item) {
            //展示旗袍
            item.showPopover = true
            if (!this.startDate && !this.endDate) {
                setTimeout(() => {
                    this.$refs.calender[0].scrollToDate(new Date())
                }, 300);
            }
        },
        //下拉切换
        handSelectChange (item, selectItem) {
            item.activeValue = selectItem.value
            item.label = selectItem.text
            item.showPopover = false
            this.submit()
        },
        //日期改变
        handChangeDate (type) {
            //前一年
            if (type == 'deYear') {
                this.yearCont--;
                this.$refs.calender[0].scrollToDate(new Date(
                    this.year + this.yearCont,
                    this.month,
                    1
                ))
            }
            //下一年
            if (type == 'adYear') {
                this.yearCont++;
                this.$refs.calender[0].scrollToDate(new Date(
                    this.year + this.yearCont,
                    this.month,
                    1
                ))
            }
            //前一月
            if (type == 'deMonth') {
                this.monthCont--;
                this.$refs.calender[0].scrollToDate(new Date(
                    this.year,
                    this.month + this.monthCont,
                    1
                ))
            }
            //下一月
            if (type == 'adMonth') {
                this.monthCont++;
                this.$refs.calender[0].scrollToDate(new Date(
                    this.year,
                    this.month + this.monthCont,
                    1
                ))
            }
        },
        //日期选择改变
        handleSelect (value) {
            this.startDate = value[0] ? timeFormat(value[0], '.') : ''
            this.endDate = value[1] ? timeFormat(value[1], '.') : ''
        },
        //选择日期确认
        handSure (item) {
            if (this.startDate && this.endDate) {
                item.label = [this.startDate, this.endDate]
                item.activeValue = [timeFormat(new Date(this.startDate), '-'), timeFormat(new Date(this.endDate), '-')]
                this.submit()
            }
            item.showPopover = false

        },
        // 日期提示信息
        formatter(day) {
            if (day.type == 'start') {
                day.bottomInfo = '开始';
            } else if (day.type == 'end') {
                day.bottomInfo = '结束';
            }
            return day;
        },
        //重置
        handReset (item) {
            this.startDate = ''
            this.endDate = ''
            this.$refs.calender[0].reset([])
            item.label = item.defaultLabel
            item.activeValue = '00'
            item.showPopover = false
            this.submit()
        },
        //确认提交
        submit () {
            this.$emit('filterFunction', this.filterOptions)
        }
    },
    mounted () {

    },
    created () {
        this.filterOptions = this.filterList
    }
}
</script>

<style scoped lang="scss">
.my-filter1 {
    height: 40px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: #FFFFFF;
    border-top: 1px solid #F2F3F5;
    .filter-item {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        justify-content: space-between;
        font-size: 13px;
        width: 195px;
        margin: 0px 50px;
        .icon {
            width: 9px;
            height: 9px;
            margin-left: 11px;
        }
        .icon_rotate{
            transition: transform 0.3s ease-in-out;
            transform: rotate(180deg);
        }

    }

}

.select-item::v-deep {
    padding: 13px 20px;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
}

.select-item-active::v-deep {
    color: #4871C0;
}

.left-icon::v-deep {
    position: absolute;
    height: 120px;
    line-height: 135px;
    top: -4px;
}

.left-1::v-deep {
    left: 16px;
}

.left-2::v-deep {
    left: 66px;
}

.right-1::v-deep {
    right: 16px;
}

.right-2::v-deep {
    right: 66px;
}
.showdate::v-deep {
    position: absolute;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 45px;
    color: #333333;

    span {
        width: 30%;
        text-align: center;
        font-size: 14px;
        font-weight: regular;
    }

    .slicer {
        width: 16px;
        border-top: 0.8px solid #cccccc;
        margin: 0 40px;
    }
}

.van-calendar::v-deep {
    .van-calendar__bottom-info {
        // display: none;
    }

    .van-calendar__header-subtitle {
        height: 100px;
        line-height: 120px;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 600;
        color: #333333;
    }

    .van-calendar__weekday {
        font-size: 13px;
    }
    .van-calendar__month-title{
        font-size: 15px;
    }
}
.van-overlay{
    background: none !important;
}
.bottom-btn::v-deep {
    border-top: 1px solid #EEEEEE;
    height: 50px;
    display: flex;
    font-size: 17px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;

    div {
        flex: 1;
        text-align: center;
        line-height: 50px;
    }

    .sure {
        color: #4A7CEE;
        border-left: 1px solid #EEEEEE;
    }
}

.calender-icon::v-deep {
    width: 16px;
    height: 16px;
    margin: auto;
}

.time-style::v-deep {
    width: 100%;
    margin: auto;
    height: 400px;
    position: relative;
}
</style>