<template>
    <div class="construction-style">
        <headerNav :title="`${clientInfo.consumerName1}的分包单`" leftNav="返回" @leftClick="cancel"></headerNav>
        <div class="content-style">
            <pullRefresh ref="pullRefresh" @refresh="onRefresh">
                <van-list class="vant-list-style" v-model="loading" :finished="finished" @load="onLoad">
                    <template #loading>
                        <div v-if="total">·加载中·</div>
                        <div class="show-img" v-else>
                            <img :src="require('@/assets/subNull.png')" alt="" width="50%">
                            <div>·加载中·</div>
                        </div>
                    </template>
                    <template #finished>
                        <div v-if="total"> ·已加载全部数据· </div>
                        <div class="show-img" v-else>
                            <img :src="require('@/assets/subNull.png')" alt="" width="50%">
                            <div>·暂无数据·</div>
                        </div>
                    </template>
                    <div class="item-style" v-for="(item) in list" :key="item.id">
                        <div class="middle">
                            <div class="title">
                                <span style="color: #999;font-size: 14px;">编号：</span>
                                <span> {{ item.documentNo }} </span>
                            </div>
                            <div style="width: 56px;height: 40px;text-align: right;" @click="onToDetail(item, 'detail')">
                                <van-icon name="arrow" size="20" />
                            </div>
                        </div>
                        <div class="right">
                            <div :class="`right-divideStatus-${classes[item.divideStatus]} right-divideStatus`"
                                @click="onToDetail(item, 'detailList')">
                                {{ item.divideStatus }}<van-icon name="arrow" />
                            </div>
                        </div>
                    </div>
                </van-list>
            </pullRefresh>
        </div>
    </div>
</template>

<script>
import api from '@/api/api.js'
import filterModel from '@/components/filter.vue'
export default {
    components: {
        filterModel,
    },
    computed: {
        clientInfo() {
            let clientInfo = JSON.parse(sessionStorage.getItem('clientInfo'))
            const consumerName1 = clientInfo.consumerName.length > 5 ? `${clientInfo.consumerName.substring(0, 5)}...` : clientInfo.consumerName
            return { ...clientInfo, consumerName1 }
        }
    },
    data() {
        return {
            classes: {
                '待验收': 'yellow',
                '待验证': 'yellow',
                '待上传': 'yellow',
                '未通过': 'red',
                '验收未通过': 'red',
                '验证未通过': 'red',
                '已通过': 'blue',
                '验收已通过': 'blue',
                '验证已通过': 'blue',
            },
            searchValue: '',
            list: [],
            loading: false,
            finished: false,
            error: false, // 是否加载失败
            pageNum: 1, // 分页
            pageSize: 10, // 每页条数
            total: 0 // 数据总条数
        }
    },
    methods: {
        cancel() {
            this.$router.push({ name: "engineeringClicntDetailName" })
        },
        //获取列表
        async getList() {
            try {
                let obj = {
                    page: this.pageNum,
                    limit: this.pageSize,
                    'Q_o.id_EQ': this.clientInfo.orderId, // 订单编号
                }
                let res = await api.getSubpackageList(obj)
                if (res.code == 200) {
                    this.total = res.count;
                    if (!res.data.returnDTOS || !res.data.returnDTOS.length) { // 判断获取数据条数若等于0
                        this.list = []; // 清空数组
                        this.finished = true; // 停止加载
                    } else {
                        // 若数据条数不等于0
                        this.list.push(...res.data.returnDTOS) // 将数据放入list中
                        this.loading = false; // 加载状态结束 
                        // 如果list长度大于等于总数据条数,数据全部加载完成
                        if (this.list.length >= res.count) {
                            this.finished = true; // 结束加载状态
                        }
                    }
                    if (this.$refs.pullRefresh) {
                        this.$refs.pullRefresh.refreshSuccess()
                    }
                }
            } catch (error) {
                this.loading = false
                this.finished = true
                this.error = false
            }
        },
        // 被 @load调用的方法
        onLoad(e) { // 若加载条到了底部
            let timer = setTimeout(() => { // 定时器仅针对本地数据渲染动画效果,项目中axios请求不需要定时器
                this.getList(); // 调用上面方法,请求数据
                this.pageNum++; // 分页数加一
                this.finished && clearTimeout(timer); //清除计时器
            }, 100);
        },
        // 加载失败调用方法
        onRefresh(e) {
            this.finished = false; // 清空列表数据
            this.loading = true; // 将 loading 设置为 true，表示处于加载状态
            this.pageNum = 1; // 分页数赋值为1
            this.list = []; // 清空数组
            this.onLoad(e); // 重新加载数据
        },
        onToDetail(item, type) {
            sessionStorage.setItem("clientInfo1", JSON.stringify(item));
            sessionStorage.setItem("fromRouterNameEngineering", "engineeringSubpackageDetailList");
            if (type === "detail") {
                this.$router.push({ name: "engineeringSubpackageDetail" });
            } else {
                this.$router.push({ name: "engineeringSubpackageDetailView" });
            }
        },
        // 调用拨号功能
        callPhone(phoneNo) {
            if (!phoneNo) return
            window.location.href = 'tel://' + phoneNo
        },
        //权限判断
        permission(v) {
            return this.hasPermission(v)
        }
    },
}
</script>

<style scoped lang="scss">
.construction-style {

    .search-style {
        height: 47px;
        background: #FFFFFF;
        display: flex;
        align-items: center;
        padding: 0 10px;

        .choosetime-input {
            height: 31px;
            line-height: 31px;
            background: #F5F6F8;
            border-radius: 6px;
            padding: 0;
            padding-right: 5px;
            font-size: 13px;
        }

        .search-icon {
            width: 22px;
            height: 22px;
            margin: auto 0;
        }
    }

    .content-style {
        padding: 0 10px;
        height: calc(100vh - 50px);
        overflow: auto;

        .item-style {
            box-sizing: border-box;
            height: 100px;
            background: #FFFFFF;
            padding: 16px;
            margin-top: 10px;
            border-radius: 6px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .middle {
                flex: 1;
                text-align: left;
                display: flex;
                justify-content: space-between;


                .title {
                    flex: 1;
                    font-size: 16px;
                    font-family: Source Han Sans SC;
                    font-weight: 400;
                    color: #333;
                    padding-left: 8px;
                }
            }

            .right {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-end;

                .right-divideStatus {
                    padding: 0 4px 0 10px;
                    height: 20px;
                    line-height: 20px;
                }

                .right-divideStatus-yellow {
                    background-color: #FFF8E6;
                    color: #F7B500;
                }

                .right-divideStatus-red {
                    background-color: #FFECEB;
                    color: #FF3B30;
                }

                .right-divideStatus-blue {
                    background-color: #E8F2FF;
                    color: #1677FF;
                }
            }
        }
    }

    ::v-deep .van-pull-refresh {
        min-height: calc(100vh - 50px);
    }

    .show-img {
        height: calc(100vh - 50px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
</style>
