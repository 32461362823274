<template>
    <div class="main">
        <van-sticky>
            <headerNav leftNav=" " class="nav_b" @leftClick="back" title="渠道数据"></headerNav>
            <div class="tab center flex">
                <div :class=" active == '1' ? 'active':'' " @click="channel('1')">本周</div>
                <div :class=" active == '2' ? 'active':'' " @click="channel('2')">本月</div>
                <div :class=" active == '3' ? 'active':'' " @click="channel('3')">半年</div>
            </div>
        </van-sticky>
        <div class="content">
            <div class="table">
                <div class="table">
                <el-table :data="channelTableData" border style="width: 100%" cell-class-name="table_cell">
                    <el-table-column type="index" width="80" align="center" fixed="left" label="TOP"></el-table-column>
                    <el-table-column prop="channelName" align="left" fixed="left" label="渠道" :width="flexwidth('channelName',channelTableData,'渠道','noSort')"></el-table-column>
                    <el-table-column prop="consume" align="right" label="消耗" sortable :width="flexwidth('consume',channelTableData,'消耗')">
                        <template slot-scope="scope">
                            <span>{{ (scope.row.consume).toFixed(2) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="practicalExpenditure" min-width="140" align="right" label="实际花费" sortable :width="flexwidth('practicalExpenditure',channelTableData,'实际花费')">
                        <template slot-scope="scope">
                            <span>{{ (scope.row.practicalExpenditure).toFixed(2) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="threadNumber" min-width="100" align="left" label="线索量" sortable :width="flexwidth('threadNumber',channelTableData,'线索量')"></el-table-column>
                    <el-table-column prop="practicalThreadCost" align="right" label="实际线索成本" sortable :width="flexwidth('practicalThreadCost',channelTableData,'实际线索成本')">
                        <template slot-scope="scope">
                            <span>{{ (scope.row.practicalThreadCost).toFixed(2) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="practicalValidCost" min-width="160" align="right" label="实际有效成本" sortable :width="flexwidth('practicalValidCost',channelTableData,'实际有效成本')">
                        <template slot-scope="scope">
                            <span>{{ (scope.row.practicalValidCost).toFixed(2) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="practicalShopCost" min-width="160" align="right" label="实际到店成本" sortable :width="flexwidth('practicalShopCost',channelTableData,'实际到店成本')">
                        <template slot-scope="scope">
                            <span>{{ (scope.row.practicalShopCost).toFixed(2) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="practicalSignCost" min-width="160" align="right" label="实际签单成本" sortable :width="flexwidth('practicalSignCost',channelTableData,'实际签单成本')">
                        <template slot-scope="scope">
                            <span>{{ (scope.row.practicalSignCost).toFixed(2) }}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
	import api from '@/api/president.js'
    import moment from 'moment';
    import { Toast } from 'vant';
    import * as echarts from 'echarts';
	import {
		timeFormat
	} from '@/utils/timeCompute'
	export default {
		data() {
			return {
                reportData:'',
                active:'1', //当月数据
                channelTableData: [],

                thisWeek:'',
                thisMonth:'',
                halfYear:'',
            }
		},
		components: {
		},
		created() {
		},
        mounted() {
            this.thisWeek = moment().startOf('week').add(1,'days').format('YYYY-MM-DD')+"~"+moment().endOf('week').add(1,'days').format('YYYY-MM-DD')
            this.thisMonth = moment().format('YYYY-MM')
            if(Number(moment().format('MM')) < 7){
                this.halfYear = moment().format('YYYY')+'-'+'01'+'~'+moment().format('YYYY')+'-'+'06'
            }else{
                this.halfYear = moment().format('YYYY')+'-'+'07'+'~'+moment().format('YYYY')+'-'+'12'
            }

            this.active = this.$route.query.type
            this.getChannelData(this.active)
		},
		methods: {
            //回退
            back () {
                this.$router.go(-1)
            },
            formatDate(date) {
                let month,day
                if((date.getMonth() + 1)<10){
                    month = '0'+ (date.getMonth() + 1)
                }else{
                    month = (date.getMonth() + 1)
                }
                if(date.getDate()<10){
                    day = '0'+date.getDate()
                }else{
                    day = date.getDate()
                }
                return `${date.getFullYear()}-${month}-${day}`;
            },
            // 封装
            handleEcharts (name, option) {
                var chartDom = document.querySelector(name);
                // var chartDom = document.getElementById('main');
                var myChart = echarts.init(chartDom, 'night');
                option && myChart.setOption(option);
            },
            channel(type){ // 展示10条
                this.active = type
                this.getChannelData(this.active)
            },
            getChannelData(type){
                let date =''
                if( type == '1'){
                    date = this.thisWeek
                }else if(type == '2'){
                    date = this.thisMonth+'~'+this.thisMonth
                }else{
                    date = this.halfYear
                }
                let params = {
                    'type':type,
                    'Q_reportData_BW':date
                }
                api.channelDataApi(params).then((res)=>{
                    console.log(res);
                    res.data.forEach((item,index)=>{
                        if( item.channelName && item.channelName.length > 4 ) item.channelName = item.channelName.slice(0,4)+'...'
                        item.consume = Number(item.consume)
                        item.practicalExpenditure = Number(item.practicalExpenditure)
                        item.practicalShopCost = Number(item.practicalShopCost)
                        item.practicalSignCost = Number(item.practicalSignCost)
                        item.practicalThreadCost = Number(item.practicalThreadCost)
                        item.practicalValidCost = Number(item.practicalValidCost)
                    })
                    this.channelTableData = res.data
                })
            },
            
            flexwidth(prop, tableData, title,flag) {
                if (tableData.length === 0 ) return; //表格没数据不做处理
                let flexwidth = 0;//初始化表格列宽
                let columnContent = '';//占位最宽的内容
                let canvas = document.createElement("canvas");
                let context = canvas.getContext("2d");

                var tableDom = document.querySelector('.el-table  .cell');
                
                var style = window.getComputedStyle(tableDom);
                var paddingLeft = parseFloat(style.paddingLeft)
                var paddingRight = parseFloat(style.paddingRight)
                context.font = style.fontSize+" Microsoft YaHei";
                
                if ((prop === '') && title) {//标题长内容少的，取标题的值
                    columnContent = title
                } else {// 获取该列中占位最宽的内容
                    let index = 0;
                    for (let i = 0; i < tableData.length; i++) {
                        const now_temp = tableData[i][prop] + "";
                        const max_temp = tableData[index][prop] + "";
                        const now_temp_w = context.measureText(now_temp).width
                        const max_temp_w = context.measureText(max_temp).width
                        if (now_temp_w > max_temp_w) {
                            index = i;
                        }
                    }
                    columnContent = tableData[index][prop]
                    //比较占位最宽的值跟标题、标题为空的留出四个位置
                    const column_w = context.measureText(columnContent).width
                    let title_w = context.measureText(title).width
                    if(flag != 'noSort'){
                        title_w = context.measureText(title+'排序').width
                    }
                    if (column_w < title_w) {
                        columnContent = title || '留四个字'
                        if(flag != 'noSort'){
                            columnContent = title + '排序'
                        }
                    }
                }
                
                // 计算最宽内容的列宽
                let width = context.measureText(columnContent);
                flexwidth = width.width + paddingLeft + (paddingRight*2)
                console.log(flexwidth);
                // debugger
                return Math.ceil(flexwidth) + "px"
            }
        },
		
	}
</script>

<style scoped lang="scss">
.flex{
    display: flex;
}
.nav_b {
    background: #3975C6;
}

.center{
    display: flex;
    justify-content: center;
    align-items: center;
}
.tab{
    background: #F5F5F5;
    margin:12px 15px 8px;
    justify-content: space-between;
    border-radius: 4px 4px 4px 4px;

    div{
        background: #F8F8F8;
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #545454;
        width: 35%;
        line-height: 36px;
    }
    .active{
        font-size: 14px;
        font-family: PingFang SC-Bold, PingFang SC;
        color: #4871C0;
        background: #FFFFFF;
        border-radius: 4px 4px;
        border: 1px solid #4871C0;
    }
}
.main{
    
    width: 100%;
    height: 100%;
    .content{
        width: 100%;
        height: 100%;
        background: #FFFFFF;
        margin-bottom: 15px;
        
        .amount{
            padding-bottom: 8px;
            color: #FF2B2B;
        }
        .average{
            padding-top: 8px;
        }
    }
}

::v-deep{
    .el-table--border .el-table__cell, .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed{
        border-right: none;
    }
    .el-table th.el-table__cell{
        background: #F5F5F5;
    }
    .el-table .cell{
        font-weight: normal;
        font-size: 14px;
    }
    .van-sticky{
        background: #FFFFFF;
    }
    .el-table .el-table__row .cell{
        padding-right:  18px;
    }
    .table_cell .cell{
        // white-space: nowrap;
        // text-overflow:ellipsis;
        // overflow: hidden;
    }
    body .el-table th.gutter{
        display:table-cell !important;
    }
    body .el-table colgroup.gutter{
        display:table-cell !important;
    }
    table{
        width:100%  !important;
    }
}
</style>