// 人事经理  任务详情
let manageTaskDetailModel = [
  { name:'招聘试岗',type:'invite_job', 
    table:[
      {label:'任务时间',prop:'taskDate',},
      {label:'任务项',prop:'termName',},
      {label:'完成率',prop:'finishRate',suffix:'%',finishStatus:true},
      {label:'执行者',prop:'userName',requir:true,click:'executorPicker'},
      {label:'子任务',prop:'childTaskTotalCount',requir:true,class:'interval',click:'subTask'},
    ],
  },
  { name:'薪资提交',type:'pay_submit', 
    table:[
      {label:'任务时间',prop:'taskDate',},
      {label:'任务项',prop:'termName',},
      {label:'及时率',prop:'finishRate',suffix:'%',finishStatus:true},
      {label:'执行者',prop:'userName',requir:true,click:'executorPicker'},
      {label:'提交时间',prop:'createDate',class:'interval'},
      {label:'附件',prop:'fileList',},
    ],
  },
  { name:'新员工培训',type:'employee_training',
    table:[
      {label:'任务时间',prop:'taskDate',},
      {label:'任务项',prop:'termName',},
      {label:'完成率',prop:'finishRate',suffix:'%',finishStatus:true},
      {label:'执行者',prop:'userName',requir:true,click:'executorPicker'},
      {label:'应培训',prop:'targetValue',class:'interval',unit:'人'},
      {label:'已培训',prop:'finishValue',unit:'人'},
      {label:'附件',prop:'fileList',},
    ],
  },
  { name:'费用报销',type:'cost_payment', 
    table:[
      {label:'任务时间',prop:'taskDate',},
      {label:'任务项',prop:'termName',},
      {label:'完成率',prop:'finishRate',suffix:'%',finishStatus:true},
      {label:'执行者',prop:'userName',requir:true,click:'executorPicker'},
      {label:'应及时报销',prop:'targetValue',class:'interval',unit:'笔'},
      {label:'已及时报销',prop:'finishValue',unit:'笔'},
      {label:'附件',prop:'fileList',},
    ],
  },
  { name:'行政大巡检',type:'admin_inspection',
    table:[
      {label:'任务时间',prop:'taskDate',},
      {label:'任务项',prop:'termName',},
      {label:'完成率',prop:'finishRate',suffix:'%',finishStatus:true},
      {label:'执行者',prop:'userName',requir:true,click:'executorPicker'},
      {label:'应巡检',prop:'targetValue',requir:true,class:'interval',unit:'次',inputFn:true,type:'digit'},
      {label:'已巡检',prop:'finishValue',unit:'次'},
      {label:'附件',prop:'fileList',},
    ],
  },
  { name:'宿舍巡检',type:'dorm_inspection', 
    table:[
      {label:'任务时间',prop:'taskDate',},
      {label:'任务项',prop:'termName',},
      {label:'完成率',prop:'finishRate',suffix:'%',finishStatus:true},
      {label:'执行者',prop:'userName',requir:true,click:'executorPicker'},
      {label:'应巡检',prop:'targetValue',requir:true,class:'interval',unit:'次',inputFn:true,type:'digit'},
      {label:'已巡检',prop:'finishValue',unit:'次'},
      {label:'附件',prop:'fileList',},
    ],
  },
]

// 人事专员  任务详情
let commissionerTaskDetailModel = [
  { name:'招聘试岗',type:'invite_job', 
    table:[
      {label:'任务时间',prop:'taskDate',},
      {label:'任务项',prop:'termName',},
      {label:'完成率',prop:'finishRate',suffix:'%',finishStatus:true},
      {label:'招聘人数',prop:'targetValue',class:'interval',unit:'人'},
      {label:'试岗人数',prop:'finishValue',unit:'人'},
      {label:'附件',prop:'fileList',},
    ],
  },
  { name:'薪资提交',type:'pay_submit', 
    table:[
      {label:'任务时间',prop:'taskDate',},
      {label:'任务项',prop:'termName',},
      {label:'及时率',prop:'finishRate',suffix:'%',finishStatus:true},
      {label:'提交时间',prop:'createDate',class:'interval'},
      {label:'附件',prop:'fileList',},
    ],
  },
  { name:'新员工培训',type:'employee_training',
    table:[
      {label:'任务时间',prop:'taskDate',},
      {label:'任务项',prop:'termName',},
      {label:'完成率',prop:'finishRate',suffix:'%',finishStatus:true},
      {label:'应培训',prop:'targetValue',class:'interval',unit:'人'},
      {label:'已培训',prop:'finishValue',unit:'人'},
      {label:'附件',prop:'fileList',},
    ],
  },
  { name:'费用报销',type:'cost_payment', 
    table:[
      {label:'任务时间',prop:'taskDate',},
      {label:'任务项',prop:'termName',},
      {label:'完成率',prop:'finishRate',suffix:'%',finishStatus:true},
      {label:'应及时报销',prop:'targetValue',class:'interval',unit:'笔'},
      {label:'已及时报销',prop:'finishValue',unit:'笔'},
      {label:'附件',prop:'fileList',},
    ],
  },
  { name:'行政大巡检',type:'admin_inspection',
    table:[
      {label:'任务时间',prop:'taskDate',},
      {label:'任务项',prop:'termName',},
      {label:'完成率',prop:'finishRate',suffix:'%',finishStatus:true},
      {label:'应巡检',prop:'targetValue',requir:true,class:'interval',unit:'次'},
      {label:'已巡检',prop:'finishValue',unit:'次'},
      {label:'附件',prop:'fileList',},
    ],
  },
  { name:'宿舍巡检',type:'dorm_inspection', 
    table:[
      {label:'任务时间',prop:'taskDate',},
      {label:'任务项',prop:'termName',},
      {label:'完成率',prop:'finishRate',suffix:'%',finishStatus:true},
      {label:'应巡检',prop:'targetValue',requir:true,class:'interval',unit:'次'},
      {label:'已巡检',prop:'finishValue',unit:'次'},
      {label:'附件',prop:'fileList',},
    ],
  },
]

export const taskDetailModelFn = (code,params) => {
  let data = {}
  if(code == 'ROLE_201262'){ //人事经理
    manageTaskDetailModel.filter(item => {
      if (item.type == params) {
        data = item
      }
    })
  }else if(code == 'ROLE_477142'){ //人事专员
    commissionerTaskDetailModel.filter(item => {
      if (item.type == params) {
        data = item
      }
    })
  }
  return data
};
