<template>
    <div class="main">
        <van-sticky>
            <headerNav leftNav=" " class="nav_b" @leftClick="back" title="排行榜"></headerNav>
            <van-dropdown-menu class="select" style="width: 75px;" active-color="#1989fa">
                <van-dropdown-item v-model="value4" :options="option4" @change="rankingChange"/>
            </van-dropdown-menu>
        </van-sticky>
        <div class="content">
            <van-tabs v-model="rankActive" color="#EBBA00" title-active-color="#333333" title-inactive-color="#999999" >
                <van-tab title="话务员" name="0">
                    <el-table :data="tableData1" style="width: 100%" v-if="tableData1.length>0" @sort-change="sortChange" :default-sort="{prop:prop1,order:order1}">
                        <el-table-column
                            type="index"
                            label="TOP"
                            align="center"
                            width="60"
                            fixed>
                        </el-table-column>
                        <el-table-column
                            prop="telephonistName"
                            label="姓名"
                            width="100"
                            fixed>
                        </el-table-column>
                        <el-table-column
                            prop="clueValid"
                            label="有效数"
                            sortable
                            align="right"
                            width="100">
                            <template slot-scope="scope">
                                <span class="numColor1">{{ scope.row.clueValid }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="clueValidRate"
                            label="有效率"
                            sortable
                            align="right"
                            width="100">
                            <template slot-scope="scope">
                                <span>{{ scope.row.clueValidRate }}%</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="shopNum"
                            label="到店数"
                            sortable
                            align="right"
                            width="100">
                            <template slot-scope="scope">
                                <span class="numColor2">{{ scope.row.shopNum }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="shopRate"
                            label="到店率"
                            sortable
                            align="right"
                            width="100">
                            <template slot-scope="scope">
                                <span>{{ scope.row.shopRate }}%</span>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="noneData" v-else>
                        <img src="../../assets/none.png" alt="">
                        <div>暂无数据</div>
                    </div>
                </van-tab>
                <van-tab title="签单手" name="1">
                    <el-table :data="tableData2" style="width: 100%" v-if="tableData2.length>0" @sort-change="sortChange" :default-sort="{prop:prop2,order:order2}">
                        <el-table-column
                            type="index"
                            label="TOP"
                            align="center"
                            width="60"
                            fixed>
                        </el-table-column>
                        <el-table-column
                            prop="signName"
                            label="姓名"
                            width="100"
                            fixed>
                        </el-table-column>
                        <el-table-column
                            prop="orderReceivingNum"
                            label="接单数"
                            sortable
                            align="right"
                            width="100">
                        </el-table-column>
                        <el-table-column
                            prop="validClientNum"
                            label="有效数"
                            sortable
                            align="right"
                            width="100">
                        </el-table-column>
                        <el-table-column
                            prop="signNum"
                            label="签单数"
                            sortable
                            align="right"
                            width="100">
                        </el-table-column>
                        <el-table-column
                            prop="signRate"
                            label="签单率"
                            sortable
                            align="right"
                            width="100">
                            <template slot-scope="scope">
                                <span>{{ scope.row.signRate }}%</span>
                            </template>
                        </el-table-column>
                    </el-table> 
                    <div class="noneData" v-else>
                        <img src="../../assets/none.png" alt="">
                        <div>暂无数据</div>
                    </div>
                </van-tab>
                <van-tab title="设计师" name="2">
                    <el-table :data="tableData3" style="width: 100%" v-if="tableData3.length>0" @sort-change="sortChange" :default-sort="{prop:prop3,order:order3}">
                        <el-table-column
                            type="index"
                            label="TOP"
                            align="center"
                            width="60"
                            fixed>
                        </el-table-column>
                        <el-table-column
                            prop="transferName"
                            label="姓名"
                            width="100"
                            fixed>
                        </el-table-column>
                        <el-table-column
                            prop="transferSum"
                            label="转单数"
                            sortable
                            align="right"
                            width="100">
                        </el-table-column>
                        <el-table-column
                            prop="thisMonthPro"
                            label="转单产值(元)"
                            sortable
                            align="right"
                            width="150">
                            <template slot-scope="scope">
                                <span class="numColor3">{{ scope.row.thisMonthPro }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="polishingNum"
                            label="补齐数"
                            sortable
                            align="right"
                            width="100">
                        </el-table-column>
                        <el-table-column
                            prop="polishingValue"
                            label="补齐产值(元)"
                            sortable
                            align="right"
                            width="150">
                            <template slot-scope="scope">
                                <span class="numColor3">{{ scope.row.polishingValue }}</span>
                            </template>
                        </el-table-column>
                    </el-table> 
                    <div class="noneData" v-else>
                        <img src="../../assets/none.png" alt="">
                        <div>暂无数据</div>
                    </div>
                </van-tab>
                <van-tab title="督导" name="3">
                    <el-table :data="tableData4" style="width: 100%" v-if="tableData4.length>0" @sort-change="sortChange" :default-sort="{prop:prop4,order:order4}">
                        <el-table-column
                            type="index"
                            label="TOP"
                            align="center"
                            width="60"
                            fixed>
                        </el-table-column>
                        <el-table-column
                            prop="supervisorName"
                            label="姓名"
                            width="100"
                            fixed>
                        </el-table-column>
                        <el-table-column
                            prop="transferSum"
                            label="转单数"
                            sortable
                            align="right"
                            width="100">
                        </el-table-column>
                        <el-table-column
                            prop="thisMonthPro"
                            label="转单产值(元)"
                            sortable
                            align="right"
                            width="150">
                        </el-table-column>
                        <el-table-column
                            prop="polishingNum"
                            label="补齐数"
                            sortable
                            align="right"
                            width="100">
                        </el-table-column>
                        <el-table-column
                            prop="polishingValue"
                            label="补齐产值(元)"
                            sortable
                            align="right"
                            width="150">
                        </el-table-column>
                    </el-table>
                    <div class="noneData" v-else>
                        <img src="../../assets/none.png" alt="">
                        <div>暂无数据</div>
                    </div>
                </van-tab>
            </van-tabs>
        </div>
    </div>
</template>

<script>
	import api from '@/api/dataBoard.js'
    import moment from 'moment';
    import { Toast } from 'vant';
    import * as echarts from 'echarts';
	import {
		timeFormat
	} from '@/utils/timeCompute'
	export default {
		data() {
			return {
                // 排行榜
                value1: '99', //选择的市场
                value4:'3',
                option4: [
                    { text: '今日', value: '1' },
                    { text: '本周', value: '2' },
                    { text: '本月', value: '3' },
                    { text: '本年', value: '4' },
                ],
                rankActive:0,
                reportDataRank:'',
                reportDataRank1:[],

                tableData1:[],
                tableData2:[],
                tableData3:[],
                tableData4:[],
                // 表格排序字段
                tableSort1:{},
                tableSort2:{},
                tableSort3:{},
                tableSort4:{},

                prop1:'',
                order1:'',
                prop2:'',
                order2:'',
                prop3:'',
                order3:'',
                prop4:'',
                order4:'',
            }
		},
		components: {
		},
		created() {
		},
        mounted() {
            console.log(this.$route.query);

            this.rankActive = this.$route.query.navType
            this.value4 = this.$route.query.dataType
            if(this.value4 == 1){
                this.reportDataRank1 = [moment().format('YYYY-MM-DD'),moment().format('YYYY-MM-DD')]
            }else if(this.value4 == 2){
                this.reportDataRank = moment().format('YYYY-MM-WW')
            }else if(this.value4 == 3){
                this.reportDataRank = moment().format('YYYY-MM')
            }else if(this.value4 == 4){
                this.reportDataRank = moment().format('YYYY')
            }
            this.value1 = this.$route.query.marketCode
            if(this.rankActive == '0'){
                this.prop1 = this.$route.query.prop || ''
                this.order1 = this.$route.query.order|| ''
            }else if(this.rankActive == '1'){
                this.prop2 = this.$route.query.prop || ''
                this.order2 = this.$route.query.order || ''
            }else if(this.rankActive == '2'){
                this.prop3 = this.$route.query.prop || ''
                this.order3 = this.$route.query.order || ''
            }else if(this.rankActive == '3'){
                this.prop4 = this.$route.query.prop || ''
                this.order4 = this.$route.query.order || ''
            }

            this.getRank()
		},
		methods: {
            //回退
            back () {
                this.$router.go(-1)
            },
            formatDate(date) {
                let month,day
                if((date.getMonth() + 1)<10){
                    month = '0'+ (date.getMonth() + 1)
                }else{
                    month = (date.getMonth() + 1)
                }
                if(date.getDate()<10){
                    day = '0'+date.getDate()
                }else{
                    day = date.getDate()
                }
                return `${date.getFullYear()}-${month}-${day}`;
            },
            // 排行榜
            getRank(){
                let params = {
                    "type": this.value4, 
                    "reportData": this.reportDataRank,
                    'startDate':this.reportDataRank1[0] || '',
                    'endDate':this.reportDataRank1[1] || '',
                    "marketCode": this.value1,
                    'ifSelectAllUser': true
                }
                this.getTraffic(params)
                this.getSigning(params)
                this.getTransferOrder(params)
                this.getSupervision(params)
            },
            rankingChange(){
                this.reportDataRank = ''
                this.reportDataRank1 = []
                if(this.value4 == 1){
                    this.reportDataRank1 = [moment().format('YYYY-MM-DD'),moment().format('YYYY-MM-DD')]
                }else if(this.value4 == 2){
                    this.reportDataRank = moment().format('YYYY-MM-WW')
                }else if(this.value4 == 3){
                    this.reportDataRank = moment().format('YYYY-MM')
                }else if(this.value4 == 4){
                    this.reportDataRank = moment().format('YYYY')
                }
                this.getRank()
            },
            getTraffic(params) {
                api.trafficApi(params).then((res)=>{
                    console.log(res);
                    this.tableData1 = res.data||[];
                })
            },
            getSigning(params) {
                api.signingApi(params).then((res)=>{
                    console.log(res);
                    this.tableData2 = res.data||[];
                })
            },
            getTransferOrder(params) {
                api.transferOrderApi(params).then((res)=>{
                    console.log(res);
                    this.tableData3 = res.data||[];
                })
            },
            getSupervision(params) {
                api.supervisionApi(params).then((res)=>{
                    console.log(res.data);
                    this.tableData4 = res.data||[];
                })
            },
            sortChange(column){
                console.log(this.rankActive,column);
                if(this.rankActive == '0'){
                    this.tableSort1 = column
                }else if(this.rankActive == '1'){
                    this.tableSort2 = column
                }else if(this.rankActive == '2'){
                    this.tableSort3 = column
                }else if(this.rankActive == '3'){
                    this.tableSort4 = column
                }
            },
        },
		
	}
</script>

<style scoped lang="scss">
.flex{
    display: flex;
}
.nav_b {
    background: #3975C6;
}

.select{
    position: absolute;
    top: 0;
    right: 0;
}

.noneData{
    width: 100%;
    height: 280px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;
    img{
        width: 200px;
        height: 140px;
    }
    div{
        width: 100%;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        margin-top: 5px;
    }
}

.numColor1{
    color: #438F1D;
}
.numColor2{
    color: #4871C0;
}

.numColor3{
    color: #FA6400;
}

::v-deep{
    .van-dropdown-menu__bar{
        box-shadow:none;
        background-color: #3975c6;
    }
    .van-dropdown-menu__title{
        color: #fff !important;
    }

    .el-table__body-wrapper,.el-table__fixed-body-wrapper{
        max-height: calc(100vh - 146px);
        overflow: auto;
    }
}

</style>
