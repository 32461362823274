<template>
  <div>
    <!-- 子任务详情组件 -->
    <div class="content-element">

      <!-- 标题 -->
      <div class="content-element-top" @click="onClick">
        <div class="content-element-top-left-person">
          <div class="text-name">{{ item.userName }}</div>
          <div class="errro-status" v-if="item.isException == '1'">异常</div>
        </div>
        <div class="content-element-arrows">
          <img src="@/assets/task-management/task-setup-finance-arrows.png" alt="">
        </div>
      </div>

      <!-- 指标内容 -->
      <div class="content-element-bottom" v-if="!taskObj.isSingleIndicator">
        <div class="content-element-bottom-left content-element-bottom-el">
          <div class="count">{{ (item.termId == 'site_routing_inspection' || item.termId == 'site_penalty' ? item.finishRate : item.realityProfitRate) || '-' }}<span>{{ taskObj.leftUnit }}</span></div>
          <div class="text">{{ taskObj.taskTarget1 }}</div>
        </div>
        <div class="content-element-bottom-right content-element-bottom-el" v-if="taskObj.taskTarget2">
          <div class="content-element-bottom-right-el" v-if="taskObj.taskTarget2">
            <div class="count">{{ (item.termId == 'site_routing_inspection' || item.termId == 'site_penalty' ? item.realityProfitRate : item.taskProfitRate) || '-' }}<span>{{ taskObj.rightUnit }}</span></div>
            <div class="text">{{ taskObj.taskTarget2 }}</div>
          </div>
          <div class="content-element-bottom-right-el" v-if="taskObj.taskTarget3">
            <div class="count">{{ item.taskProfitRate }}<span>{{ taskObj.rightUnit }}</span></div>
            <div class="text">{{ taskObj.taskTarget3 }}</div>
          </div>
        </div>
      </div>

      <!-- 适配一个指标展示的内容 -->
      <div class="content-element-bottom-one" v-else>
        <div class="text">最终得分：</div>
        <div class="count">{{item.realityProfitRate}}<span>分</span></div>
      </div>
    </div>
  </div>
</template>

<script>
import { estimateOutputTarget } from '../data.js'
export default {
  name: 'MajordomoItem',
  props: {
    item: {
      type: Object,
      default: {}
    },
    // 判断是哪个指标
    targetType: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      taskObj: {}
    }
  },
  watch: {
    targetType (newValue, oldValue) {
      this.updatedTargetType()
    }
  },
  created () {
    this.updatedTargetType()
  },
  methods: {
    updatedTargetType () {
      this.taskObj = estimateOutputTarget(this.targetType)
    },
    onClick () {
      this.$emit('onClick')
    }
  }
}
</script>

<style lang="scss" scoped src="./index.scss"></style>
<style lang="scss" scoped>
.content-element-top-left-person {
  display: flex;
  align-items: center;
  .text-name {
    font-size: 18px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    color: #1D2129;
    margin-right: 7px;
  }

  .errro-status {
    min-width: 28px;
    height: 14px;
    line-height: 14px;
    font-size: 14px;
    color: #FF6157;
    padding: 2px;
    background: rgba(255, 97, 87, 0.1);

  }
}
</style>