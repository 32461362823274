<template>
  <div class="progress-main">

    <main>
      <div class="progress-main-nav" :class="customerName ? isSuccess ? 'anomaly' : 'user' : 'normal'">
        <div class="progress-main-nav-left" v-if="isShowDay">{{ day }}</div>
        <div class="progress-main-nav-right">
          <div class="progress-main-nav-right-money">{{ unit == '个' ? parseInt(percentageMoney) :
            Number(percentageMoney || 0).toFixed(2) }}
          </div>
          <div class="progress-main-nav-right-text">{{ monthProps || month }}月份完成({{ unit }})</div>
        </div>
        <div class="progress-main-nav-customerName" v-if="customerName">
          <div>{{ customerName }}</div>
        </div>
      </div>
    </main>

    <footer v-if="showFoot">
      <!--进度条 -->
      <div class="progress-foot" v-if="isProgress">
        <div class="progress-foot-progress-box">
          <div class="progress-foot-progress-flot" :style="percentageStyle">
            <span class="text">完成率:</span>
            <span class="rote">{{ perform.percentageRate ? Number(perform.percentageRate).toFixed(2) + '%' : 0 }}</span>
          </div>
          <div class="progress-foot-progress-arrow" :style="percentageArrowStyle"></div>
          <van-progress class="progress-foot-progress"
            :percentage="perform.percentageRate >= 100 ? 100 : perform.percentageRate" stroke-width="14"
            :show-pivot="false" inactive-color="#ADD8FF" />
        </div>
        <div class="progress-foot-bottom">
          <div class="progress-foot-bottom-text">任务目标</div>
          <div class="progress-foot-bottom-money">{{ unit == '个' ? parseInt(perform.taskGoalCount || 0) :
            Number(perform.taskGoalCount || 0).toFixed(2) }}({{ unit }})</div>
        </div>
      </div>

      <!-- 上下级任务指标 -->
      <div class="progress-foot-indicator" v-else>
        <!-- 上级任务 -->
        <div class="progress-foot-indicator-content">
          <div class="progress-foot-indicator-top">
            <!-- 上级 -->
            <div class="progress-foot-indicator-top-img">
              <img src="../../assets/task-management/progress-superior.png" alt="">
            </div>
            <div class="progress-foot-indicator-top-right">
              <div>{{ unit == '个' ? parseInt(subAndSup.supTask || 0) : Number(subAndSup.supTask || 0).toFixed(2) }}</div>
              <span>上级设置任务</span>
            </div>
          </div>
          <div class="progress-foot-indicator-foot">
            <div>完成率：{{ Number(subAndSup.supRate || 0).toFixed(2) }}%</div>
          </div>
        </div>

        <!-- 下级页面 -->
        <div class="progress-foot-indicator-content">
          <div class="progress-foot-indicator-top">
            <!-- 下级 -->
            <div class="progress-foot-indicator-top-img">
              <img src="../../assets/task-management/progress-subordinate.png" alt="">
            </div>
            <div class="progress-foot-indicator-top-right">
              <div>{{ unit == '个' ? parseInt(subAndSup.subTask || 0) : Number(subAndSup.subTask || 0).toFixed(2) }}</div>
              <span>下级设置任务</span>
            </div>
          </div>
          <div class="progress-foot-indicator-foot">
            <div>完成率：{{ Number(subAndSup.subRate || 0).toFixed(2) }}%</div>
          </div>
        </div>
      </div>

    </footer>

  </div>
</template>

<script>
import { timeFormat } from "@/utils/timeCompute";

export default {
  props: {
    /*
       展示哪个内容，哪个内容的参数必传，不然报错  perform or subAndSup
       percentageRate 完成率  
       taskGoalCount  任务目标
    */
    //  进度条
    perform: {
      percentageRate: {
        type: Number || String,
        default: 0 || "0",
      },
      taskGoalCount: {
        type: String,
        default: '0',
      }
    },
    // 总金额
    percentageMoney: {
      type: String,
      default: "0",
    },
    // 上下级任务
    subAndSup: {
      // 上级任务量
      supTask: {
        type: String,
        default: '0',
      },
      // 上级完成率
      supRate: {
        type: Number,
        default: 0,
      },
      // 下级任务量
      subTask: {
        type: String,
        default: '0',
      },
      // 下级完成率
      subRate: {
        type: Number,
        default: 0,
      }
    },
    // 是否显示footer
    showFoot: {
      type: Boolean,
      default: true
    },
    // 控制展示进度条还是上下级任务指标，默认显示进度条
    isProgress: {
      type: Boolean,
      default: true
    },
    // 个人详情时展示名字--默认不展示
    customerName: {
      type: String,
      default: null
    },
    // 当前人是否异常
    isSuccess: {
      type: Boolean,
      default: false
    },
    // 单位
    unit: {
      type: String,
      default: '个'
    },
    // 是否显示日历
    isShowDay: {
      type: Boolean,
      default: true
    },
    // 月份
    monthProps: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      month: 1,
      day: 1
    }
  },
  computed: {
    // 完成率偏移量
    percentageStyle () {
      let leftX = 0
      if (this.perform.percentageRate) {
        let count = Math.ceil(this.perform.percentageRate)
        if (count > 15 && count < 85) {
          leftX = count - 15
        }
        if (count >= 85) {
          leftX = 70
        }
      }
      return {
        left: leftX + '%'
      }

    },
    // 箭头偏移量
    percentageArrowStyle () {
      let leftX = 4
      if (this.perform.percentageRate) {
        let count = Math.ceil(this.perform.percentageRate)
        if (count >= 7 && count < 98) {
          leftX = count - 2
        }
        if (count >= 98) {
          leftX = 94.7
        }
      }
      return {
        left: leftX + '%'
      }

    }
  },
  created () {
    let data = timeFormat(new Date())
    this.month = data.split('-')[1]
    this.day = data.split('-')[2]
  }
}
</script>

<style lang="scss" scoped>
.progress-main::v-deep {
  padding: 12px;
  border-radius: 0px 0px 8px 8px;
  background-color: #3975C6;

  .normal {
    background: url('../../assets/task-management/progress-task-top.png') no-repeat;
  }

  .anomaly {
    background: url('../../assets/task-management/progress-task-top-anomaly.png') no-repeat;
    background-position: 210px -12px !important;
  }

  .user {
    background: url('../../assets/task-management/progress-task-top-user.png') no-repeat;
  }

  .progress-main-nav {
    display: flex;
    align-items: flex-start;
    color: #fff;
    font-weight: 400;
    padding-bottom: 18px;
    background-size: 140px 135px;
    background-position: 210px -8px;

    .progress-main-nav-left {
      width: 28px;
      height: 22px;
      text-align: center;
      font-size: 12px;
      margin: 8.8px 6px 0px 0px;
      padding-top: 7px;
      background: url('../../assets/task-management/progress-task-date.png') no-repeat;
      background-size: contain;
      background-position: 0px -1px;
    }

    .progress-main-nav-right {
      font-family: PingFang SC-Medium, PingFang SC;
      text-align: left;

      .progress-main-nav-right-money {
        font-size: 32px;
      }

      .progress-main-nav-right-text {
        font-size: 13px;
      }
    }

    .progress-main-nav-customerName {
      font-size: 15px;
      font-weight: bold;
      margin: 6% 7.8% 0 auto;
      width: 100px;

      div {
        width: 100%;
        text-align: center;
      }
    }
  }

  .progress-foot {
    width: 331px;
    height: 80px;
    padding: 10px;
    background: url('../../assets/task-management/progress-task-bottom.png');
    background-size: cover;

    .progress-foot-progress-box {
      position: relative;

      .progress-foot-progress-flot {
        position: absolute;
        top: -40px;
        width: 98px;
        height: 32px;
        line-height: 32px;
        color: #fff;
        border-radius: 26px;
        display: flex;
        align-items: center;
        // font-size: 12px;
        background: rgba(14, 139, 255, 0.7);
        box-shadow: 0 0 8px #87C5FF;
        padding-left: 4px;
        justify-content: center;

        span {
          white-space: nowrap;
          overflow: hidden;
          display: block;
        }

        .text {
          // margin-top: 4px;
        }

        .rote {
          font-size: 14px;
          margin-top: -1px;
        }
      }

      .progress-foot-progress-arrow {
        position: absolute;
        top: -9px;
        width: 0;
        height: 0;
        color: #fff;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-bottom: 6px solid rgba(14, 139, 255, 0.6);
        border-radius: 2px;
        transform: rotate(180deg);
      }

      .progress-foot-progress {
        margin-top: 40px;
        border-radius: 27px;
        position: relative;
        overflow: hidden;
        border: 1px solid #ADD8FF;
        background: #DFF1FF;

        .van-progress__portion {
          background: linear-gradient(-45deg, #55ABFA 25%, #3298F6 0,
              #3298F6 50%, #55ABFA 0,
              #55ABFA 75%, #3298F6 0);
          background-size: 35px 35px;
        }

      }
    }


    .progress-foot-bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #545454;
      font-size: 16px;
      font-family: PingFang SC-Medium, PingFang SC;
      margin-top: 7px;

      .progress-foot-bottom-money {
        font-size: 18px;
      }

      .progress-foot-bottom-text {
        font-size: 16px;
      }
    }
  }

  .progress-foot-indicator {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2px;

    .progress-foot-indicator-content {
      width: 140px;
      height: 70px;
      padding: 14px 15px;
      border-radius: 6px;
      background: url('../../assets/task-management/progress-indicator-background.png') no-repeat;
      background-size: cover;

      .progress-foot-indicator-top {
        display: flex;

        .progress-foot-indicator-top-img {
          width: 28px;
          height: 28px;
          padding: 6px;
          background: #D7EFFF;
          border-radius: 2.73px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 28px;
            height: 28px;
          }
        }

        .progress-foot-indicator-top-right {
          margin-left: 4px;
          font-family: Bahnschrift-Regular, Bahnschrift;
          text-align: left;

          div {
            color: #545454;
            font-size: 20px;
          }

          span {
            font-size: 13px;
            color: #8B8F92;
            font-weight: bold;
          }
        }
      }

      .progress-foot-indicator-foot {
        width: 100%;
        text-align: left;
        margin-top: 9px;
        font-family: PingFang SC-Heavy, PingFang SC;

        div {
          min-width: 100px;
          max-width: 123px;
          height: 24px;
          line-height: 24px;
          background: #E1F1FD;
          color: #0E8BFF;
          font-weight: 800;
          font-size: 12px;
        }
      }
    }
  }
}
</style>