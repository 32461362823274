<template>
	<div class="construction-style">
		<headerNav title="在建工程"></headerNav>
		<van-tabs v-model="active" @change="onChange" color='#4871C0'>
			<van-tab title="工程单" name='1'>
				<IndexTab />
			</van-tab>
			<van-tab title="自理销售单" name='2'>
				<IndexTab2 />
			</van-tab>
		</van-tabs>

	</div>
</template>

<script>
import IndexTab2 from './indexTab2.vue'
import IndexTab from './indexTab.vue'
export default {
	data() {
		return {
			active: '1'
		}
	},
	components: {
		IndexTab2,
		IndexTab
	},
	methods: {
		onChange(active) {
			sessionStorage.setItem('ecActive', active)
		}
	},
	mounted() {
		const ecActive = sessionStorage.getItem('ecActive')
		if (ecActive) this.active = ecActive
	}
}
</script>
<style scoped lang="scss">
::v-deep .van-tabs__nav {
	background-color: #3975C6;
}

::v-deep .van-tab {
	color: #fff;
	background-color: #5488CE;
	border-radius: 10px 10px 0px 0px;
    font-size: 18px;
}

::v-deep .van-tab--active {
	color: #333333;
	background-color: #fff;
}

::v-deep .van-tabs__line {
	display: none;
}
</style>