<template>
    <div class="my-filter">
        <div v-for="(item, index) in filterOptions" class="filter-item" @click="handleClick(item, index)" :key="index">
            <span v-if="item.type == 'timeQuantum'" style="display: flex;flex-direction: column;">
                <span>{{ item.label[0] }}</span>
                <span>{{ item.label[1] }}</span>
            </span>
            <span v-else>{{ item.label }}</span>
            <span style="margin-left: 4px;">
                <van-icon style="color: #AAAAAA;" name="arrow-down" />
            </span>
        </div>
        <!-- 其他选择 -->
        <van-action-sheet style="border-radius: 0;" v-model="sheetShow">
            <template #description>
                <div class="sheet-top">
                    <div class="sheet-cancel" @click="handleCancel">取消</div>
                    <div class="sheet-sure" @click="handleConfirm">确定</div>
                </div>
            </template>
            <div class="content">
                <div class="sheet-content">
                    <div :class="['sheet-item', action.activeValue == selectItem.value && 'sheet-item-active']"
                        v-for="selectItem in actions" :key="selectItem.value" @click="handleSelectChange(selectItem)">
                        {{ selectItem.text }}
                        <img v-if="action.activeValue == selectItem.value" class="sheet-item-checked-icon"
                            src="@/assets/ischeck.png" alt="">
                    </div>
                </div>
            </div>
        </van-action-sheet>
        <!-- 可输入可选择 -->
        <van-action-sheet style="padding-top: 10px;height: 465px;" v-model="sheet1Show">
            <template #description>
                <div class="sheet1-top">
                    <van-field class="choose-input" v-model="searchValue" @input="handleInput" clearable
                        placeholder="请输入项目经理名字" center>
                        <template v-slot:right-icon>
                            <div style="height: 100%;display: flex;align-items: center;">
                                <img src="../assets/search.png" class="search-icon" alt="">
                            </div>
                        </template>
                    </van-field>
                </div>
            </template>
            <div class="content">
                <div class="sheet-content sheet1-content">
                    <div :class="['sheet-item', action.activeValue == selectItem.value && 'sheet-item-active']"
                        v-for="selectItem in actions" :key="selectItem.value" @click="handleSelectChange(selectItem)">
                        {{ selectItem.text }}
                        <img v-if="action.activeValue == selectItem.value" class="sheet-item-checked-icon"
                            src="@/assets/ischeck.png" alt="">
                    </div>
                </div>
            </div>
            <div class="sheet1-bottom">
                <div class="sheet-cancel" @click="handleCancel">取消</div>
                <div class="sheet-sure" @click="handleConfirm">确定</div>
            </div>
        </van-action-sheet>
        <!-- 时间选择 -->
        <van-action-sheet style="border-radius: 0;" v-model="sheetTimeShow">
            <div class="time-style">
                <div class="showdate">
                    <span :style="startDate ? '' : 'color: #CCCCCC;'">{{ startDate ? startDate : '开始时间' }}</span>
                    <span class="slicer"></span>
                    <span :style="endDate ? '' : 'color: #CCCCCC;'">{{ endDate ? endDate : '结束时间' }}</span>
                </div>
                <div class="left-icon left-1" @click="handChangeDate('deYear')">
                    <img :src="require('@/assets/icon4.png')" class="calender-icon" />
                </div>
                <div class="left-icon left-2" @click="handChangeDate('deMonth')">
                    <img :src="require('@/assets/icon2.png')" class="calender-icon" />
                </div>
                <div class="left-icon right-2" @click="handChangeDate('adMonth')">
                    <img :src="require('@/assets/icon3.png')" class="calender-icon" />
                </div>
                <div class="left-icon right-1" @click="handChangeDate('adYear')">
                    <img :src="require('@/assets/icon5.png')" class="calender-icon" />
                </div>
                <van-calendar row-height="60" :min-date="minDate" :max-date="maxDate" ref="calender" :poppable="false"
                    color="#4871C0" :show-title="false" :show-mark="false" :show-confirm="false" type="range"
                    :default-date="null" @select="handleSelect" allow-same-day />
            </div>
            <div class="bottom-btn">
                <div class="cancel" @click="handReset">重置</div>
                <div class="sure" @click="handSure">确认</div>
            </div>
        </van-action-sheet>
    </div>
</template>

<script>
import { timeFormat } from '@/utils/timeCompute'
export default {
    data() {
        return {
            filterOptions: [],
            minDate: new Date(new Date().getFullYear() - 10, new Date().getMonth(), 1),//可选最小日期，当前日期十年前
            maxDate: new Date(new Date().getFullYear() + 10, new Date().getMonth(), 30),//可选最大日期，当前日期十年后
            monthCont: 0,//月加减次数
            yearCont: 0,//年加减次数
            year: new Date().getFullYear(),//当前年
            month: new Date().getMonth(),//当前月
            startDate: '',//开始日期
            endDate: '',//结束日期
            sheetShow: false,//弹窗显示
            sheetTimeShow: false,//日期弹窗显示
            action: {}, // 已选择项目
            actions: [], // 选择数组
            searchValue: '',//弹窗输入框值
            sheet1Show: false,//弹窗显示1
        }
    },
    props: {
        //筛选参数
        filterList: {
            type: Array,
            default: () => []
        },
    },
    methods: {
        //筛选项点击事件
        handleClick(item, index) {
            //展示旗袍
            this.action = { ...item, index }
            if (item.type === 'select') {
                this.actions = item.actions
                this.sheetShow = true
            } else if (item.type === 'inputSelect') {
                this.actions = item.actions
                this.sheet1Show = true
                this.searchValue = ''
            } else if (item.type === 'timeQuantum') {
                this.sheetTimeShow = true
                if (!this.startDate && !this.endDate) {
                    setTimeout(() => {
                        this.$refs.calender.scrollToDate(new Date())
                    }, 300);
                }
            }
        },
        //日期改变
        handChangeDate(type) {
            //前一年
            if (type == 'deYear') {
                this.yearCont--;
                this.$refs.calender.scrollToDate(new Date(
                    this.year + this.yearCont,
                    this.month,
                    1
                ))
            }
            //下一年
            if (type == 'adYear') {
                this.yearCont++;
                this.$refs.calender.scrollToDate(new Date(
                    this.year + this.yearCont,
                    this.month,
                    1
                ))
            }
            //前一月
            if (type == 'deMonth') {
                this.monthCont--;
                this.$refs.calender.scrollToDate(new Date(
                    this.year,
                    this.month + this.monthCont,
                    1
                ))
            }
            //下一月
            if (type == 'adMonth') {
                this.monthCont++;
                this.$refs.calender.scrollToDate(new Date(
                    this.year,
                    this.month + this.monthCont,
                    1
                ))
            }
        },
        //日期选择改变
        handleSelect(value) {
            this.startDate = value[0] ? timeFormat(value[0], '.') : ''
            this.endDate = value[1] ? timeFormat(value[1], '.') : ''
        },
        //重置
        handReset() {
            this.startDate = ''
            this.endDate = ''
            this.$refs.calender.reset([])
            this.action.activeValue = '00'
            this.sheetTimeShow = false
            this.handleConfirm()
        },
        //选择日期确认
        handSure() {
            if (this.startDate && this.endDate) {
                this.action.label = [this.startDate, this.endDate]
                this.action.activeValue = [timeFormat(new Date(this.startDate), '-'), timeFormat(new Date(this.endDate), '-')]
                this.handleConfirm()
            }
            this.sheetTimeShow = false
        },
        //选项选择
        handleSelectChange(item) {
            this.action.activeValue = item.value
            this.action.label = item.text
        },
        //输入框变化
        handleInput(value) {
            const arr = this.filterOptions[3].actions.filter(item => item.text.toLowerCase().indexOf(value.toLowerCase()) >= 0)
            this.actions = arr
        },
        //点击取消
        handleCancel() {
            this.sheetShow = false
            this.sheet1Show = false
        },
        //点击确认
        handleConfirm() {
            const { index, label, activeValue, defaultLabel } = this.action
            this.filterOptions[index].activeValue = activeValue
            this.filterOptions[index].label = activeValue === '00' ? defaultLabel : label
            this.sheetShow = false
            this.sheet1Show = false
            this.submit()
        },
        //确认提交
        submit() {
            this.$emit('filterFunction', this.filterOptions)
        }
    },
    mounted() {
        this.startTime = this.endTime = new Date()
    },
    created() {
        this.filterOptions = this.filterList
    }
}
</script>

<style scoped lang="scss">
.my-filter {
    height: 40px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: #FFFFFF;
    border-top: 1px solid #F2F3F5;

    .filter-item {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        .icon {
            width: 9px;
            height: 9px;
            padding-left: 11px;
        }
    }
}

.sheet-top {
    height: 45px;
    line-height: 45px;
    background: #F5F6F8;
    font-size: 16px;
    font-weight: 400;
    display: flex;
    justify-content: space-between;

    .sheet-cancel {
        padding: 0 16px;
        color: #999999;
    }

    .sheet-sure {
        padding: 0 16px;
        color: #4F7AFD;
    }
}

.content {
    padding-bottom: 45px;

    .sheet-content {
        background: #FFFFFF;

        .sheet-item {
            height: 56px;
            line-height: 56px;
            font-size: 16px;
            // font-family: Source Han Sans SC;
            font-weight: 400;
            color: #333333;
            text-align: left;
            margin: 0 15.5px;
            display: flex;
            align-items: center;
            position: relative;

            &:not(:last-child) {
                border-bottom: 1px solid #E6E6E6;
            }

            .sheet-item-checked-icon {
                width: 22px;
                margin: auto 0;
                position: absolute;
                right: 11.5px;
            }
        }

        .sheet-item-active {
            color: #4F7AFD;
        }
    }
}

.time-style {
    height: 380px;

    .showdate {
        position: absolute;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        height: 54px;
        color: #333333;

        span {
            width: 30%;
            text-align: center;
        }

        .slicer {
            width: 16px;
            border-top: 1px solid black;
            margin: 0 40px;
        }
    }

    .left-icon {
        position: absolute;
        height: 120px;
        line-height: 180px;

        .calender-icon::v-deep {
            width: 22px;
            height: 22px;
            margin: auto;
        }
    }

    .left-1 {
        left: 16px;
    }

    .left-2 {
        left: 66px;
    }

    .right-1 {
        right: 16px;
    }

    .right-2 {
        right: 66px;
    }

    .van-calendar::v-deep {
        .van-calendar__bottom-info {
            display: none;
        }

        .van-calendar__header-subtitle {
            height: 120px;
            line-height: 170px;
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
        }
    }
}

.bottom-btn {
    height: 50px;
    display: flex;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    position: relative;

    &::before {
        content: ' ';
        position: absolute;
        top: 0;
        left: 0;
        height: 1px;
        width: 100%;
        background-color: #EEEEEE;
    }

    div {
        flex: 1;
        text-align: center;
        line-height: 50px;
    }

    .sure {
        color: #fff;
        background-color: #3975C6;
    }
}

.sheet1-top {
    padding: 12px 15.5px;

    .choose-input {
        height: 40px;
        line-height: 40px;
        background: #F5F6F8;
        border-radius: 6px;
        padding: 0;
        padding-right: 5px;
        font-size: 13px;

        ::v-deep .van-field__control {
            padding-left: 10px;
        }
    }

    .search-icon {
        width: 22px;
        height: 22px;
        margin: auto 0;
    }
}

.sheet1-content {
    margin-bottom: 52px;
}

.sheet1-bottom {
    width: 100%;
    height: 52px;
    line-height: 52px;
    font-size: 16px;
    display: flex;
    position: absolute;
    bottom: 0;

    .sheet-cancel {
        flex: 1;
        padding: 0 16px;
        background-color: #fff;
        box-shadow: 0 0 4px 4px #eee;
    }

    .sheet-sure {
        flex: 1;
        padding: 0 16px;
        color: #fff;
        background-color: #4F7AFD;
    }
}

::v-deep .van-action-sheet {
    max-height: 430px;

    .van-action-sheet__description {
        padding: 0;

        &::after {
            border: 0;
        }
    }
}
</style>