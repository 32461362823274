<template>
    <div v-if="!isError" class="client-detail">
        <headerNav :leftNav="isDirectRoute ? '' : '返回'" @leftClick="back" title="客户详情" />
        <div style="height: 100vh;" v-if="loading" v-loading="loading"></div>
        <div class="detail-contet" v-else>
            <div class="top">
                <div class="top-left">
                    <div class="name">
                        {{ dataInfo.consumerName }}
                        <template v-if="dataInfo.shopStatus != '10'">
                            <div v-if="dataInfo.allocationStatusSign == '1'" class="blue-tag">已分配</div>
                            <div v-else class="orange-tag">待分配</div>
                        </template>
                    </div>
                    <div class="phone" v-if="dataInfo.phoneNo">
                        <span>手机号</span>
                        <span>{{ dataInfo.phoneNo ? geTel(dataInfo.phoneNo) : '' }}</span>
                    </div>
                    <div class="phone" v-if="dataInfo.shopTime && dataInfo.shopStatus != '13'">
                        <span>到店时间</span>
                        <span>{{ dataInfo.shopTime }}</span>
                    </div>
                    <div class="phone" v-if="dataInfo.signName">
                        <span>签单手</span>
                        <span>{{ dataInfo.signName }}</span>
                    </div>
                    <div class="phone" v-if="dataInfo.signName">
                        <span>签单状态</span>
                        <span>{{ dataInfo.signStatus == '21' ? '已签单' : dataInfo.signStatus == '20' ? '未签单' :
                            dataInfo.signStatus == '29' ? '已丢单' : '' }}</span>
                    </div>
                    <!-- <div class="phone" v-if="dataInfo.shopStatus == '13'">
                        <span>离店时间</span>
                        <span>{{ dataInfo.leaveTime }}</span>
                    </div> -->
                    </div>
                    <div class="top-right">
                        <img :src="require('@/assets/icon10.png')" class="top-icon" alt=""
                            v-if="dataInfo.shopStatus == '10'">
                        <div class="text" v-if="dataInfo.shopStatus == '10'">未到店</div>
                        <img v-if="dataInfo.shopStatus == '11'" :src="require('@/assets/icon12.png')" class="top-icon"
                            alt="">
                        <div class="text blue" v-if="dataInfo.shopStatus == '11'">已到店
                        </div>
                        <img v-if="dataInfo.shopStatus == '12'" :src="require('@/assets/icon16.png')" class="top-icon"
                            alt="">
                        <div class="text green" v-if="dataInfo.shopStatus == '12'">再次到店
                        </div>
                        <img :src="require('@/assets/icon11.png')" class="top-icon" alt=""
                            v-if="dataInfo.shopStatus == '13'">
                        <div class="text gray" v-if="dataInfo.shopStatus == '13'">已离店</div>

                    </div>
                </div>
            </div>
            <div class="center">
                <div class="center-title">
                    <span class="title-left"></span>
                    客户信息
                </div>
                <van-cell v-if="dataInfo.wechatNo" class="cell-style none-arrow" :border="false" title="微信号" is-link
                    :value="dataInfo.wechatNo || '-'" title-class="titleclass" value-class="valueclass" />
                <van-cell v-if="dataInfo.landlineNo" class="cell-style none-arrow" :border="false" title="固定电话" is-link
                    :value="dataInfo.landlineNo || '-'" title-class="titleclass" value-class="valueclass" />
                <van-cell class="cell-style  none-arrow" :border="false" title="邀约码" is-link
                    :value="dataInfo.inviteCode || '-'" title-class="titleclass" value-class="valueclass" />
                <van-cell class="cell-style" :border="false" title="客户意向" is-link :value="dataInfo.intentCodeName || '-'"
                    title-class="titleclass" value-class="valueclass"
                    @click="openConfirm('intentCode', 'intentCodeName')" />
                <van-cell class="cell-style" :border="false" title="到店人数" is-link
                    :value="dataInfo.personQty ? dataInfo.personQty + '人' : '-' || '-'" title-class="titleclass"
                    value-class="valueclass" @click="openConfirm('personQty', 'personQty')" />
                <van-cell class="cell-style" :border="false" title="年龄" is-link
                    :value="dataInfo.age ? dataInfo.age + '岁' : '-' || '-'" title-class="titleclass"
                    value-class="valueclass" @click="openEdit('age')" />
                <van-cell class="cell-style" :border="false" title="职业" is-link :value="dataInfo.occupation || '-'"
                    title-class="titleclass" value-class="valueclass" @click="openEdit('occupation')" />
                <van-cell class="cell-style none-arrow" :border="false" title="客户类型" is-link
                    :value="dataInfo.nominateStyleName || '-'" title-class="titleclass" value-class="valueclass" />
                <van-cell class="cell-style" :border="false" title="关系" is-link :value="dataInfo.customerRelation || '-'"
                    title-class="titleclass" value-class="valueclass" @click="openEdit('customerRelation')" />
                <div class="center-title">
                    <span class="title-left"></span>
                    房屋信息
                </div>
                <van-cell class="cell-style" :border="false" title="建筑面积" is-link :value="dataInfo.areaQty || '-'"
                    title-class="titleclass" value-class="valueclass" @click="openEdit('areaQty')" />
                <van-cell class="cell-style" :border="false" title="户型" is-link :value="dataInfo.houseTypeName || '-'"
                    title-class="titleclass" value-class="valueclass" @click="openConfirm('houseType', 'houseTypeName')" />
                <van-cell class="cell-style" :border="false" title="装修风格" is-link :value="dataInfo.styleName || '-'"
                    title-class="titleclass" value-class="valueclass" @click="openConfirm('styleCode', 'styleCodeName')" />
                <van-cell class="cell-style" :border="false" title="装修地址" is-link :value="dataInfo.detailAddress || '-'"
                    title-class="titleclass" value-class="valueclass" @click="openEdit('detailAddress')" />
                <van-cell class="cell-style" :border="false" title="所属区域" is-link :value="dataInfo.area || '-'"
                    title-class="titleclass" value-class="valueclass" @click="chooseArea()" />
                <van-cell class="cell-style" :border="false" title="备注" is-link :value="dataInfo.remark || '-'"
                    title-class="titleclass" value-class="valueclass" @click="openEdit('remark')" />

            </div>
            <div class="bottom">
                <div v-if="dataInfo.shopStatus == '10' && dataInfo.allocationStatusSign == '0'" @click="arriveStore('0')">到店
                </div>
                <div v-if="dataInfo.shopStatus == '13' && dataInfo.allocationStatusSign == '1'" @click="arriveStore('1')">
                    二次到店
                </div>
                <div v-if="(dataInfo.shopStatus == '11' || dataInfo.shopStatus == '12') && dataInfo.allocationStatusSign == '1'"
                    @click="leaveStore()">离店</div>
                <div class="white-btn"
                    v-if="dataInfo.shopStatus == '11' && dataInfo.allocationStatusSign == '1' && dataInfo.signStatus != '21'"
                    @click="allotRevocation()">撤回
                </div>
                <div v-if="dataInfo.signStatus == '20' && dataInfo.allocationStatusSign == '1'" @click="allotSing('1')">变更
                </div>
                <div v-if="dataInfo.allocationStatusSign != '1' && (dataInfo.shopStatus == '11' || dataInfo.shopStatus == '12')"
                    @click="allotSing('0')">分配
                </div>
            </div>
            <van-dialog v-model="editDialog" show-cancel-button :before-close="dialogSubmit" confirmButtonColor="#1989fa">
                <template #title>
                    <div>{{ getTitle() }}</div>
                </template>
                <div style="padding: 10px 20px;">
                    <van-field v-if="this.popupKey == 'remark' || this.popupKey == 'detailAddress'" v-model="editContent"
                        rows="5" autosize type="textarea" required maxlength="120" :placeholder="placeholder"
                        show-word-limit />
                    <van-field v-else v-model="editContent" required maxlength="20" :placeholder="placeholder"
                        :type="fieldType()" />
                    <!-- <van-field v-if="this.popupKey == 'landlineNo'" v-model="editInfo.landlineNo" required maxlength="20"
                    placeholder="电话号" /> -->
                </div>
            </van-dialog>
            <van-popup v-model="showPicker" position="bottom">
                <van-picker v-if="showPicker" show-toolbar :columns="columns" @confirm="onConfirm"
                    :default-index="showPickerIndex" @cancel="showPicker = false" />
            </van-popup>
            <van-popup v-model="showPickerAllot" position="bottom">
                <van-picker v-if="showPickerAllot" show-toolbar :columns="columns" @confirm="onConfirmAllot"
                    :default-index="showPickerIndex" @cancel="showPickerAllot = false" />
            </van-popup>
            <van-popup v-model="chooseAreaShow" round position="bottom" get-container="body">
                <van-cascader v-model="cascaderValue" title="请选择所在地区" :options="optionsArea" @close="chooseAreaShow = false"
                    @change="onChange" @finish="onFinish" />
            </van-popup>
            <van-dialog v-model="arriveStoreDialog" show-cancel-button :before-close="dialogSubmitArrive"
                confirmButtonColor="#1989fa">
                <template #title>
                    <div v-if="modalType == '0'">到店</div>
                    <div v-if="modalType == '1'">二次到店</div>
                </template>
                <div style="padding: 10px 20px;">
                    <van-field v-if="modalType == '0' || modalType == '1'" v-model="arriveStoreRemark" rows="5" autosize
                        type="textarea" required maxlength="120" placeholder="备注" show-word-limit />
                </div>
            </van-dialog>
            <!-- 到店 -->
            <van-popup v-model="showPickerArrive" position="bottom">
                <van-picker v-if="showPickerArrive" show-toolbar :columns="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                    @confirm="onConfirmArrive" :default-index="showPickerIndex" @cancel="showPickerArrive = false" />
            </van-popup>
        </div>
        <div v-else class="client-detail">
            <headerNav :leftNav="isDirectRoute ? '' : '返回'" @leftClick="back" title="客户详情" />
            <img :src="require('@/assets/icon19.png')" class="empty-style" />
            <div class="empty-text" v-html="errorText"></div>
        </div>
    </div>
</template>

<script>
import api from '../../../api/api.js'
import {
    Toast, Dialog
} from 'vant';
export default {
    data () {
        return {
            dataInfo: {},//客户资料
            showPicker: false,//选择器是否显示
            showPickerIndex: 0,//选择器所选下标
            chooseAreaShow: false,//选择区域是否显示
            showPickerArrive: false,
            chooseAreaValue: [],//选中的区域
            cascaderValue: '',//选择区域绑定值
            optionsArea: [],//选择区域配置列表
            columns: [],//选择器配置
            intentCode: [],//客户意向
            nominateCode: [],//客户类型
            personQty: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            houseType: [],//户型
            province: [],//省
            city: [],//市
            district: [],//区
            popupKey: '',//编辑的字段key
            editDialog: false,//文本编辑是否显示
            editValue: '',//文本编辑内容
            confirmLoading: false,
            modalType: '',//弹窗类型
            editInfo: {
                // wechatNo:'',//微信号
            },
            editContent: '',//编辑内容
            allotSignList: [],//分配列表
            showPickerAllot: false,
            alltoType: '1',//1变更 0 分配
            placeholder: '',//输入框默认值
            arriveStoreRemark: '',//到店备注
            arriveStoreDialog: false,
            loading: false,
            isError: false,
            errorText: `网络错误`,
            isDirectRoute: false,//true直接跳转false间接跳转（从客户列表跳转）
        }
    },
    methods: {
        //返回首页
        back () {
            this.$router.push({
                name: "clientTelephoneOperatorName",
                // name: 'clientSignBillListName'
            })
        },
        //选择省市区
        chooseArea () {
            this.popupKey = 'area'
            this.chooseAreaShow = true
        },
        //省市区选择改变触发
        async onChange (e) {
            if (e.tabIndex == 0) e.selectedOptions[0].children = await this.getArea({ Q_parentCode_EQ: e.value });
            if (e.tabIndex == 1) e.selectedOptions[1].children = await this.getArea({ Q_parentCode_EQ: e.value }, true);
        },
        //省市区选择结束触发
        onFinish ({ selectedOptions }) {
            //所选中的区域
            this.chooseAreaValue = selectedOptions
            this.editIngoChange()
            this.chooseAreaShow = false;
            this.cascaderValue = ''
        },
        //字典（排序）
        async dictionary (code) {
            //客户等级
            let res = await api.dictionary({ ['Q_dict.code_IN']: code })
            return res.data
        },
        //字典
        async getDictionariesList (code) {
            let res = await api.getDictionariesList({ Q_typeCode_IN: code })
            return res.data
        },
        //获取省市区
        async getArea (params, isLast) {
            let res = await api.getArea(params)
            let arr = res.data.map(e => {
                let item = {
                    text: e.name,
                    value: e.code,
                    children: []
                }
                //如果是最后一级删除children
                if (isLast) delete item.children
                return item
            })
            return arr
        },
        async editIngoChange (index) {
            if (this.confirmLoading) return
            let obj = {
                id: this.$route.params.id,
            }
            //装修风格
            if (this.popupKey == 'styleCode') {
                obj.styleCode = this.styleCode[index].code
                obj.styleName = this.styleCode[index].name
            }
            //客户意向
            if (this.popupKey == 'intentCode') {
                obj.intentCode = this.intentCode[index].code
                obj.intentCodeName = this.intentCode[index].name
            }
            //客户类型
            if (this.popupKey == 'nominateCode') {
                obj.nominateCode = this.nominateCode[index].code
                obj.nominateName = this.nominateCode[index].name
            }
            //户型
            if (this.popupKey == 'houseType') {
                obj.houseType = this.houseType[index].code
                obj.houseTypeName = this.houseType[index].name
            }

            //区域
            if (this.popupKey == 'area') {
                obj.provinceCode = this.chooseAreaValue[0].value
                obj.provinceName = this.chooseAreaValue[0].text

                obj.cityCode = this.chooseAreaValue[1].value
                obj.cityName = this.chooseAreaValue[1].text

                obj.regionCode = this.chooseAreaValue[2].value
                obj.regionName = this.chooseAreaValue[2].text
            }
            //到店人数
            if (this.popupKey == 'personQty') {
                obj.personQty = this.personQty[index]
            }
            this.confirmLoading = true
            let res = await api.editClientC(obj)
            if (res.code == 200) {
                this.editDialog = false
                this.confirmLoading = false
                this.showPicker = false
                Toast('编辑成功!');
                this.getClientInfoC()
            }
        },
        //获取编辑title
        getTitle () {
            let title = ''
            if (this.popupKey == 'wechatNo') title = '微信号修改'
            if (this.popupKey == 'landlineNo') title = '固定电话修改'
            if (this.popupKey == 'inviteCode') title = '邀约码修改'
            if (this.popupKey == 'intentCode') title = '客户意向'
            if (this.popupKey == 'personQty') title = '到店人数修改'
            if (this.popupKey == 'age') title = '年龄修改'
            if (this.popupKey == 'occupation') title = '职业修改'
            if (this.popupKey == 'nominateCode') title = '客户类型'
            if (this.popupKey == 'customerRelation') title = '关系修改'
            if (this.popupKey == 'areaQty') title = '建筑面积修改'
            if (this.popupKey == 'houseTyeCode') title = '户型'
            if (this.popupKey == 'styleCode') title = '装修风格'
            if (this.popupKey == 'detailAddress') title = '装修地址修改'
            if (this.popupKey == '备注') title = '备注修改'
            return title
        },
        //打开选择器
        openConfirm (key, value) {
            this.showPicker = true;
            this.popupKey = key
            //根据所选编辑的下拉框进行选择器colums赋值
            this.columns = this[this.popupKey].map((e, i) => {
                // if (e.code == this.dataInfo[key]) {
                //     this.showPickerIndex = i
                // }
                return e.name || e
                // return {
                //     text:e.name,
                //     deptCode:e.code
                // }
            })
        },
        //选择器确认
        onConfirm (value, index) {
            this.showPicker = false;
            this.editIngoChange(index)
        },
        //分配确认
        async onConfirmAllot (value, index) {
            if (this.confirmLoading) return
            let obj = {
                userId: this.allotSignList[index].id,
                consumerId: this.dataInfo.id
            }
            if (this.alltoType == '1') obj.typeCode = 2
            this.confirmLoading = true
            let res = await api.allotSign(obj)
            if (res.code == 200) {
                Toast.success('分配成功!');
                this.confirmLoading = false
                this.showPickerAllot = false
                this.getClientInfoC()
            }
        },
        //打开文本编辑
        openEdit (key) {
            this.editInfo = {}
            this.editContent = ''
            this.popupKey = key
            this.placeholder = String(this.dataInfo[key] ? this.dataInfo[key] : '请输入')
            this.editDialog = true
        },
        //弹窗提交
        async dialogSubmit (action, done) {
            if (action === 'confirm') { // 确认
                let obj = {
                    id: this.$route.params.id,
                }
                //微信号
                if (this.popupKey == 'wechatNo') {
                    obj.wechatNo = this.editContent
                    //微信号验证 不能输入中文
                    let regs = /[\u4E00-\u9FA5\uFE30-\uFFA0]/;
                    if (obj.wechatNo && regs.test(obj.wechatNo)) {
                        /* eslint-disable-next-line */
                        Toast('请输入正确的微信号!')
                        done(false);
                        return
                    }
                }
                //固定电话
                if (this.popupKey == 'landlineNo') {
                    obj.landlineNo = this.editContent || undefined
                    //电话号
                    let regs = /^((0\d{2,3}-\d{7,8}))$/;
                    if (obj.landlineNo && !regs.test(obj.landlineNo)) {
                        /* eslint-disable-next-line */
                        Toast('请输入正确的电话号!')
                        done(false);
                        return
                    }
                }
                //到店人数
                if (this.popupKey == 'personQty') {
                    obj.personQty = this.editContent || undefined
                }
                //年龄
                if (this.popupKey == 'age') {
                    obj.age = this.editContent || undefined
                }
                //职业
                if (this.popupKey == 'occupation') {
                    obj.occupation = this.editContent || undefined
                }
                //关系
                if (this.popupKey == 'customerRelation') {
                    obj.customerRelation = this.editContent || undefined
                }
                //建筑面积
                if (this.popupKey == 'areaQty') {
                    obj.areaQty = this.editContent || undefined
                    if (obj.areaQty) obj.areaQty = this.xiaoshu(obj.areaQty)
                }
                //装修地址
                if (this.popupKey == 'detailAddress') {
                    obj.detailAddress = this.editContent || undefined
                }
                //备注
                if (this.popupKey == 'remark') {
                    obj.remark = this.editContent || undefined
                }
                try {
                    let res = await api.editClientC(obj)
                    if (res.code == 200) {
                        this.editModel = false
                        this.confirmLoading = false
                        this.showPicker = false
                        Toast('编辑成功!');
                    }
                    this.getClientInfoC()
                    done();
                } catch (error) {
                    done();
                }

                // this.onRefresh()
            } else if (action === 'cancel') { // 取消
                done(); // 关闭提示框
            }

        },
        //获取客户信息
        async getClientInfoC () {
            this.loading = true
            try{
                let res = await api.getClientInfoC(this.id)
                setTimeout(() => {
                    this.pageloading = false
                }, 500);
                if (res.code == 200) {
                    this.dataInfo = res.data
                    let area = []
                    if (this.dataInfo.provinceName) area.push(this.dataInfo.provinceName)
                    if (this.dataInfo.cityName) area.push(this.dataInfo.cityName)
                    if (this.dataInfo.regionName) area.push(this.dataInfo.regionName)
                    this.dataInfo.area = area.join('/')
                    this.dataInfo.wechatNo = this.encryptContactWay(this.dataInfo.wechatNo)
                    this.dataInfo.landlineNo = this.encryptContactWay(this.dataInfo.landlineNo)
                    this.loading = false
                } else {
                    //如果报错后返回客户列表页
                    this.loading = false
                    this.isError = true
                    this.errorText = res?.msg
                    this.errorText = this.errorText.replace('【', `【<span style="color:#5894F8">`)
                    this.errorText = this.errorText.replace('】', `</span>】`)
                }
            }finally{
                this.loading = false
            }
        },
        //分配
        async allotSing (type) {
            this.alltoType = type
            this.showPickerAllot = true;
            this.getAllotSignList(this.dataInfo.signId)
        },
        async getAllotSignList (userId) {
            let obj = this.alltoType == '1' ? {
                userId: userId,
                typeCode: 2
            } : {}
            let res = await api.allotSignList(obj)
            if (res.code == 200) {
                this.allotSignList = res.data
                this.columns = res.data.map((e) => {
                    return e.idName
                })
            }
        },
        //到店/二次到店
        //到店人数选择器确认
        async onConfirmArrive (value, index) {
            if (this.confirmLoading) return
            let obj = {
                consumerId: this.dataInfo.id,//客户id
                personQty: index + 1,//到店人数
            }
            this.confirmLoading = true
            let res = await api.arriveStore(obj)
            if (res.code == 200) {
                Toast.success('到店成功!');
                this.confirmLoading = false
                this.showPickerArrive = false
                this.getClientInfoC()
            }
        },
        async arriveStore (type) {
            this.arriveStoreRemark = ''
            // type==0 到店 ==1再次到店
            this.modalType = type
            if (type == 0) {
                this.showPickerArrive = true
                return
            }
            this.arriveStoreDialog = true
        },
        //弹窗提交
        async dialogSubmitArrive (action, done) {
            if (action === 'confirm') { // 确认
                //到店
                if (this.modalType == '0' || this.modalType == '1') {
                    let obj = {
                        consumerId: this.dataInfo.id,//客户id
                        followRemark: this.arriveStoreRemark,//到店备注
                    }
                    let res = this.modalType == '0' ? await api.arriveStore(obj) : await api.arriveStoreAgain(obj)
                    if (res.code == 200) {
                        Toast.success('到店成功!');
                    }
                }
                done();
                this.getClientInfoC()
            } else if (action === 'cancel') { // 取消
                done(); // 关闭提示框
            }

        },
        //离店
        async leaveStore () {
            await api.leaveStore(this.dataInfo.id);
            Toast.success('已离店!');
            this.getClientInfoC()
        },
        //撤回
        async allotRevocation () {
            await api.allotRevocation(this.dataInfo.id)
            Toast.success('已撤回签单分配!');
            this.getClientInfoC()
        },
        //输入框类型
        fieldType () {
            if (this.popupKey == 'age') return 'digit'
            if (this.popupKey == 'personQty') return 'digit'
            if (this.popupKey == 'areaQty') return 'number'
            if (this.popupKey == 'areaQty') return 'textarea'
            return undefined
        },
        geTel (tel) {
            var reg = /^(\d{3})\d{4}(\d{4})$/;
            return tel.replace(reg, "$1****$2");
        },
        xiaoshu (value) {
            value = value.replace(/[^\d.]/g, "");  //清除“数字”和“.”以外的字符
            value = value.replace(/^\./g, "");  //必须保证第一个为数字而不是.
            value = value.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
            value = value.replace(".", "$#$").replace(/\./g, "").replace("$#$", "."); //不能重复出现点
            value = value.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');//只能输入两个小数
            if (value.indexOf(".") < 0 && value != "") {//以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
                value = parseFloat(value);
            }
            return value
        },
        //隐藏联系方式 增加***
        encryptContactWay (s) {
            if (!s) return undefined
            //取三分之一字符长度加密
            const l = Math.ceil(s.length / 3)
            //将字符串转换为数组
            let a = s.split('')
            for (let i = l; i > 0; i--) {
                //从后往前替换字符为*
                a.splice(-i - l, 1, '*')
            }
            return a.join('')
        }
    },
    async created () {
        this.id = this.$route.params.id
        this.isDirectRoute = this.$route.query.isDirectRoute
        this.styleCode = await this.getDictionariesList('DICT997340')//获取装修风格
        this.houseType = await this.dictionary('DICT254116')//获取户型
        this.optionsArea = await this.getArea({ Q_type_EQ: '1' })//获取省
        this.intentCode = await this.getDictionariesList('DICT819278')//客户意向
        this.nominateCode = await this.getDictionariesList('DICT563135')//客户类型
        this.getClientInfoC()
    },

}
</script>

<style lang="scss" scoped>
.client-detail {
    font-family: PingFangSC-Semibold, PingFang SC;

    .detail-contet {
        background: #FFFFFF;
    }

    .top {
        padding: 0 16px 0 20px;
        text-align: left;
        display: flex;
        border-bottom: 1px solid #EEEEEE;
        padding-bottom: 10px;

        .top-left {
            flex: 3;

            .name {
                padding: 20px 0 10px 0;
                font-size: 24px;
                font-weight: 600;
                color: #000000;
                display: flex;

                div {
                    margin: auto 0;
                    margin-left: 18px;
                    padding: 0 8px;
                    height: 19px;
                    line-height: 19px;
                    border-radius: 3px;
                    font-size: 12px;
                }
            }

            .phone {
                font-size: 14px;
                display: flex;
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0;
                }

                span:first-child {
                    color: #999999;
                    width: 85px;
                }

                span:last-child {
                    color: #000000;
                }
            }
        }

        .top-right {
            flex: 1;
            display: flex;
            flex-direction: column;
            // justify-content: center;
            align-items: center;

            .text {
                font-size: 12px;
                color: #FF8450;
                margin-top: 8px;
            }
        }
    }

    .center {
        padding: 0 20px;
        padding-bottom: 20px;
        height: calc(100vh - 300px);
        overflow: auto;

        .center-title {
            padding: 10px 0;
            font-size: 14px;
            color: #000000;
            display: flex;
            align-items: center;

            .title-left {
                display: inline-block;
                width: 3px;
                height: 16px;
                background: #4871C0;
                border-radius: 2px;
                margin-right: 6px;
            }
        }

        .valueclass {
            font-size: 14px;
            color: #000000;
            flex: 4;
            text-align: left;
            padding: 0 12px 0 40px;
        }

        .titleclass {
            font-size: 14px;
            color: #999999;
            text-align: left;
            flex: 1.5;
        }

        .cell-style {
            padding: 0;
            margin: 10px 0;
            border: none !important;

            &::after {
                display: none !important;
            }
        }

        .none-arrow {
            .van-cell__right-icon {
                width: 20px;

                &::before {
                    content: '' !important;
                }
            }
        }
    }

    .bottom {
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 97px;
        border-top: 1px solid #EEEEEE;
        box-shadow: 0px -1px 0px 0px #EEEEEE;
        background: #FFFFFF;
        display: flex;
        justify-content: space-around;

        div {
            flex: 1;
            margin: auto 0;
            width: 100px;
            height: 48px;
            background: #4871C0;
            border-radius: 4px;
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            color: #FFFFFF;
            line-height: 48px;
            text-align: center;
            margin: auto 15px;
        }

        .white-btn {
            border: 1px solid #CFCFCF;
            background: #FFFFFF;
            color: #333333;
        }

    }

    .top-icon {
        margin-top: 25px;
        width: 22px;
        height: 22px;
    }

    .empty-style {
        width: 176px;
        margin-top: 50%;

    }

    .empty-text {
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #BFBFBF;
        margin-top: 25px;
    }
}
</style>
<style lang="scss">
.client-detail {
    .van-field {
        background: #f2f3f5;
    }

    .van-picker-column__item--selected {
        color: #4871C0;
    }
}</style>