<template>
  <div>
    <van-sticky class="topMenu">
      <headerNav leftNav=" " :title="'工地罚金任务设置'" @leftClick="back"></headerNav>
    </van-sticky>

    <main class="content">
      <div>
        <van-form @submit="confirmAdd">
          <van-field 
            :value="form.marketName" 
            label="市场" 
            label-width="140px" 
            :rules="[{ required: true }]" 
            placeholder="请选择"  
            readonly
            name="picker" 
            input-align="right"
            @click="openModal('市场')">
            <i slot="left-icon" style="color:red">*</i>
            <template #button>  <van-icon style="float: right;" name="arrow" /> </template>
          </van-field>

          <van-field 
            :value="form.taskDate" 
            label="任务时间" 
            label-width="140px" 
            :rules="[{ required: true }]" 
            placeholder="请选择"  
            name="picker" 
            readonly
            input-align="right"
            @click="openModal('时间')">
            <i slot="left-icon" style="color:red">*</i>
            <template #button>  <van-icon style="float: right;" name="arrow" /> </template>
          </van-field>

          <van-field 
            v-model="form.taskFine" 
            label="任务罚金" 
            label-width="140px" 
            :rules="[{ required: true,pattern: /^([1-9][0-9]{0,5}|0)$/,message:'请输入最大6位的正整数' }]" 
            placeholder="请输入"  
            type="digit"
            input-align="right">
            <i slot="left-icon" style="color:red">*</i>
            <template #button> <span style="color: #1D2129;">元</span> </template>
          </van-field>
          <!-- 确定 -->
        <div class="footer-add">
          <van-button class="btn" type="info" native-type="confirmAdd" :loading='submitLoading'>确定</van-button>
        </div>
        </van-form>
      </div>
    </main>

      
    <van-popup v-model="showPicker" position="bottom">
      <van-picker
      v-show="isShowContent == '市场'"
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
      <van-datetime-picker
        v-show="isShowContent == '时间'"
        type="year-month"
        ref="myPicker"
        show-toolbar
        v-model="currentDate"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
import api from "@/api/task-management/inquiry.js";
import { Toast } from 'vant';
import { timeFormat } from "@/utils/timeCompute";
import moment from "moment";

export default {
  data() {
    return {
      form:{
        termId:'site_penalty',
        marketCode:null,
        marketName:null,
        taskDate:null,
        taskFine:''
      },
      currentDate: new Date(moment().year(), moment().month()),
      showPicker:false,
      columns:[],
      // 当前打开的弹窗显示市场还是时间
      isShowContent:null,
      // 提交动画
      submitLoading:false,
      // 筛选条件
      fliterObj:{}
    }
  },
  created(){
    let fliter = JSON.parse(localStorage.getItem('TAB_ACTIVE_OBJ'))
    this.fliterObj = fliter
    this.getMarketList()
  },
  methods:{
    back() {
      this.$router.go(-1)
    },
    // 获取市场列表
    async getMarketList(){
      const {data} = await api.getMarketListApi()
      this.columns = data.map(item => ({text:item.markName,value:item.markCode}))
    },
    // 打开弹窗层
    openModal(name){
      this.isShowContent = name
      this.showPicker = true
    },
    // 弹窗确认
    onConfirm(e){
      if(this.isShowContent == '市场'){
        this.form.marketName = e.text
        this.form.marketCode = e.value
      }else{
        this.checkDate(timeFormat(e).slice(0,-3))
      }
      this.showPicker = false
      this.isShowContent = null
    },
    // 校验月份重复
    async checkDate(date){
      const data = await api.checkDateApi({taskDate:date,termId:this.form.termId,monthType:this.fliterObj.monthType,marketCode:this.form.marketCode})
      if(data.code == 200){
        this.form.taskDate = date
      }else{
        this.form.taskDate = ''
        Toast.fail(data.msg)
      }
    },
    async confirmAdd(){
      this.submitLoading = true
      try{
        const data = await api.issueSitePenalty(this.form)
        if(data.code == 200){
          Toast.success('设置任务成功！')
          setTimeout(()=>{
            this.back()
          },1000)
        }
      }finally{
        this.submitLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content::v-deep{
  height: calc(100vh - 50px);
  background: #f5f5f5;
  .van-field__error-message{
    text-align: right;
  }
}
.footer-add {
    height: 66px;
    line-height: 50px;
    width: 100%;
    background: #fff;
    box-shadow: 0px -1px 8px 0px rgba(0, 0, 0, 0.04);
    text-align: center;
    position: fixed;
    bottom: 0px;
    left: 0px;
    padding-bottom: 10px;


    .btn {
      background: #3975c6;
      color: #ffffff;
      border-radius: 4px;
      font-size: 16px;
      font-weight: 500;
      width: 340px;
    }
  }
</style>