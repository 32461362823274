<template>
    <div class="myClient-box">
        <headerNav title="客户管理" />
        <div class="search-style">
            <van-field class="choosetime-input" v-model="searchValue" @keyup.13.native="onRefresh()" clearable
                placeholder="输入客户姓名/联系方式/邀约码搜索">
                <template v-slot:right-icon>
                    <div style="height: 100%;display: flex;align-items: center;">
                        <img src="../../../assets/search.png" class="search-icon" alt="">
                    </div>
                </template>
            </van-field>
        </div>
        <MyFilterModel :filterList="filterCondition" @filterFunction="onRefresh" ref="myFilterModel" />
        <div class="list-box">
            <pullRefresh ref="pullRefresh" @refresh="onRefresh">
                <van-list class="vant-list-style" v-model="loading" :finished="finished"
                    :finished-text="total ? '·已加载全部数据·' : '·暂无数据·'" @load="onLoad">
                    <div :class="item.shopStatus == '10' ? 'list-item orangebgshadow' : item.shopStatus == '11' ? 'list-item bluebgshadow' : item.shopStatus == '12' ? 'list-item greenbgshadow' : item.shopStatus == '13' ? 'list-item graybgshadow' : 'list-item'"
                        v-for="item in clientList">
                        <div class="item-top">
                            <div class="top-name">{{ item.consumerName }}</div>
                            <template v-if="item.shopStatus != '10'">
                                <div v-if="item.allocationStatusSign == '1'" class="top-state blue-tag">已分配</div>
                                <div v-else class="top-state orange-tag">待分配</div>
                            </template>
                            <div class="top-tag orangebg" v-if="item.shopStatus == '10'">未到店</div>
                            <div class="top-tag bluebg" v-if="item.shopStatus == '11'">已到店</div>
                            <div class="top-tag greenbg" v-if="item.shopStatus == '12'">二次到店</div>
                            <div class="top-tag graybg" v-if="item.shopStatus == '13'">已离店</div>

                        </div>
                        <div class="item-center" @click="toDetail(item)">
                            <!-- 手机号 -->
                            <div v-if="item.phoneNo"><img :src="require('@/assets/icon6.png')" class="center-icon" alt="">{{
                                item.phoneNo }}</div>
                            <!-- 微信号 -->
                            <div v-else-if="item.wechatNo"><img :src="require('@/assets/icon18.png')" class="center-icon"
                                    alt="">{{ item.wechatNo }}</div>
                            <!-- 电话号 -->
                            <div v-else-if="item.landlineNo"><img :src="require('@/assets/icon7.png')" class="center-icon"
                                    alt="">{{ item.landlineNo }}</div>

                            <!-- 签单手 -->
                            <div v-if="item.signName"><img :src="require('@/assets/icon17.png')" class="center-icon"
                                    alt="">{{ item.signName }}</div>
                            <!-- 到店时间 -->
                            <div v-else-if="item.shopTime"><img :src="require('@/assets/icon9.png')" class="center-icon"
                                    alt="">{{ item.shopTime }}</div>

                            <!-- 离店时间 -->
                            <!-- <div v-if="item.leaveTime"><img :src="require('@/assets/icon8.png')" class="center-icon"
                                    alt="">{{ item.leaveTime }}</div> -->
                        </div>
                        <div class="item-bottom">
                            <div v-if="item.shopStatus == '10' && item.allocationStatusSign == '0'"
                                @click="arriveStore(item, '0')">到店</div>
                            <div v-if="item.shopStatus == '13' && item.allocationStatusSign == '1'"
                                @click="arriveStore(item, '1')">二次到店</div>
                            <div v-if="item.signStatus == '20' && item.allocationStatusSign == '1'"
                                @click="allotSing(item, '1')">变更</div>
                            <div v-if="(item.shopStatus == '11' || item.shopStatus == '12') && item.allocationStatusSign == '1'"
                                style="color:#333333" @click="leaveStore(item)">离店</div>
                            <div v-if="item.allocationStatusSign != '1' && (item.shopStatus == '11' || item.shopStatus == '12')"
                                @click="allotSing(item, '0')">分配</div>
                            <div @click="toDetail(item)">编辑</div>
                        </div>
                    </div>
                </van-list>
            </pullRefresh>
        </div>
        <van-dialog v-model="billDialog" show-cancel-button :before-close="dialogSubmit" confirmButtonColor="#1989fa">
            <template #title>
                <div v-if="modalType == '0'">到店</div>
                <div v-if="modalType == '1'">二次到店</div>
            </template>
            <div style="padding: 10px 20px;">
                <van-field v-if="modalType == '0'" v-model="peoples" required maxlength="5" placeholder="请输入到店人数"
                    type="digit" />
                <van-field v-if="modalType == '1'" v-model="arriveStoreRemark" rows="5" autosize type="textarea" required
                    maxlength="120" placeholder="备注" show-word-limit />
            </div>
        </van-dialog>
        <!-- 到店 -->
        <van-popup v-model="showPickerArrive" position="bottom">
            <van-picker v-if="showPickerArrive" show-toolbar :columns="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                @confirm="onConfirmArrive" :default-index="showPickerIndex" @cancel="showPickerArrive = false" />
        </van-popup>
        <van-popup v-model="showPicker" position="bottom">
            <van-picker v-if="showPicker" show-toolbar :columns="columns" @confirm="onConfirm"
                :default-index="showPickerIndex" @cancel="showPicker = false" />
        </van-popup>
        <div class="scan-QR" ref="chatbox" @touchstart.prevent="dragx" @touchend.prevent="handleScanQR">
            <img :src="require('@/assets/icon14.png')" class="scan-icon">
            扫一扫
        </div>
    </div>
</template>

<script>
import api from '../../../api/api.js'
import filterModel from '@/components/filter.vue'
import MyFilterModel from '@/components/myFilterModel.vue'
import {
    Toast, Dialog
} from 'vant';
export default {
    data () {
        return {
            searchValue: '',
            list: [],
            action: {},
            actions: [],
            filterTypeId: '',
            filterCondition: [
                {
                    label: '客户意向',
                    field: 'Q_intentCode_IN',
                    type: 'select',
                    activeValue: '00',
                    actions: [
                        { text: '客户意向', value: '00' },
                    ],
                    showPopover: false
                },
                {
                    label: '到店状态',
                    field: 'Q_shopStatus_EQ',
                    type: 'select',
                    activeValue: '00',
                    actions: [
                        { text: '到店状态', value: '00' },
                        { text: '未到店', value: '10', },
                        { text: '已到店', value: '11', },
                        { text: '再次到店', value: '12', },
                        { text: '已离店', value: '13', },
                    ],
                    showPopover: false
                },
                {
                    label: '签单分配',
                    field: 'Q_allocationStatusSign_EQ',
                    type: 'select',
                    activeValue: '00',
                    actions: [
                        { text: '签单分配', value: '00' },
                        { text: '待分配', value: '0', },
                        { text: '已分配', value: '1', },
                    ],
                    showPopover: false
                },
                {
                    label: ['到店时间'],
                    field: 'Q_shopTime_BW',
                    defaultLabel: ['到店时间'],
                    activeValue: '00',
                    type: 'timeQuantum',
                    showPopover: false
                },
            ],
            clientInfo: {},
            billDialog: false,
            isLoading: false,
            loading: false,
            finished: false,
            error: false, // 是否加载失败
            clientList: [],
            pageNum: 1, // 分页
            pageSize: 10, // 每页条数
            total: 0, // 数据总条数
            modalType: '',//弹窗类型
            arriveStoreRemark: '',//到店备注
            showPicker: false,//选择器是否显示
            showPickerArrive: false,//到店人数选择器
            showPickerIndex: 0,//选择器所选下标
            columns: [],//选择器配置
            allotSignList: [],//分配列表
            confirmLoading: false,
            alltoType: '1',//1变更 0 分配
            peoples: 1,//到店人数
            Loop: 0
        }
    },
    components: {
        filterModel,
        MyFilterModel
    },
    methods: {
        //返回首页
        back () {

        },
        async getClientList (e) {
            let obj = {
                page: this.pageNum,
                limit: this.pageSize,
            }
            e?.forEach((element) => {
                obj[element.field] = element.activeValue == '00' ? undefined : element.activeValue
                if (element.type == 'timeQuantum') obj[element.field] = element.activeValue == '00' ? undefined : element.activeValue[0] + ' 00:00:00' + '~' + element.activeValue[1] + ' 23:59:59'
            })
            let res = await api.getClientSList(obj, this.searchValue)
            if (res.code == 200) {
                this.total = res.count;
                if (res.data.length === 0) { // 判断获取数据条数若等于0
                    this.clientList = []; // 清空数组
                    this.finished = true; // 停止加载
                } else {
                    // 若数据条数不等于0
                    this.clientList.push(...res.data) // 将数据放入list中
                    this.loading = false; // 加载状态结束 
                    // 如果list长度大于等于总数据条数,数据全部加载完成
                    if (this.clientList.length >= res.count) {
                        this.finished = true; // 结束加载状态
                    }
                }
                this.isLoading = false
                if (this.$refs.pullRefresh) {
                    this.$refs.pullRefresh.refreshSuccess()
                }
            }
        },
        // 被 @load调用的方法
        onLoad (e) { // 若加载条到了底部
            e = e?e:this.filterCondition
            let timer = setTimeout(() => { // 定时器仅针对本地数据渲染动画效果,项目中axios请求不需要定时器
                // this.getList(); // 调用上面方法,请求数据
                this.getClientList(e)
                this.pageNum++; // 分页数加一
                this.finished && clearTimeout(timer); //清除计时器
            }, 100);
        },
        // 加载失败调用方法
        onRefresh (e) {
            this.finished = false; // 清空列表数据
            this.loading = true; // 将 loading 设置为 true，表示处于加载状态
            this.pageNum = 1; // 分页数赋值为1
            this.clientList = []; // 清空数组
            if (e) this.onLoad(e)
            else this.onLoad(this.$refs.myFilterModel.filterList); // 重新加载数据
        },
        //字典查询 客户意向
        async getDictionariesList () {
            let obj = {
                Q_typeCode_IN: 'DICT819278',
            }
            let res = await api.getDictionariesList(obj)
            let temp = []
            res.data.forEach(e => {
                temp.push({
                    value: e.code,
                    text: e.name
                })
            })
            this.filterCondition.forEach((e) => {
                if (e.field == 'Q_intentCode_IN') {
                    e.actions.push(...temp)

                }
            })
        },
        //弹窗提交
        async dialogSubmit (action, done) {
            if (action === 'confirm') { // 确认
                //到店
                if (this.modalType == '0' || this.modalType == '1') {
                    let obj = {
                        consumerId: this.clientInfo.id,//客户id
                        followRemark: this.arriveStoreRemark,//到店备注
                    }
                    if (this.modalType == '0') obj.personQty = this.peoples//到店人数
                    let res = this.modalType == '0' ? await api.arriveStore(obj) : await api.arriveStoreAgain(obj)
                    if (res.code == 200) {
                        Toast.success('到店成功!');
                    }
                }
                done();
                this.onRefresh()
            } else if (action === 'cancel') { // 取消
                done(); // 关闭提示框
            }

        },
        //离店
        async leaveStore (item) {
            await api.leaveStore(item.id);
            Toast.success('操作成功!');
            this.onRefresh(this.$refs.myFilterModel.filterList)
        },
        //到店
        async arriveStore (item, type) {
            this.clientInfo = item
            // type==0 到店 ==1再次到店
            this.modalType = type
            if (type == 0) {
                this.showPickerArrive = true
                return
            }
            this.billDialog = true
        },
        //分配
        async allotSing (item, type) {
            this.alltoType = type
            this.clientInfo = item
            this.showPicker = true;
            this.getAllotSignList(item.signId)
        },
        //选择器确认
        onConfirm (value, index) {
            this.showPicker = false;
            this.allotSingSubmit(index)
        },
        //到店人数选择器确认
        async onConfirmArrive (value, index) {
            if (this.confirmLoading) return
            let obj = {
                consumerId: this.clientInfo.id,//客户id
                personQty: index + 1,//到店人数
            }
            this.confirmLoading = true
            let res = await api.arriveStore(obj)
            if (res.code == 200) {
                Toast.success('到店成功!');
                this.confirmLoading = false
                this.showPickerArrive = false
                this.onRefresh(this.$refs.myFilterModel.filterList)
            }
        },
        //分配提交
        async allotSingSubmit (index) {
            if (this.confirmLoading) return
            let obj = {
                userId: this.allotSignList[index].id,
                consumerId: this.clientInfo.id
            }
            if (this.alltoType == '1') obj.typeCode = 2
            this.confirmLoading = true
            let res = await api.allotSign(obj)
            if (res.code == 200) {
                Toast.success('分配成功!');
                this.confirmLoading = false
                this.showPicker = false
                this.onRefresh(this.$refs.myFilterModel.filterList)
            }
        },
        async getAllotSignList (userId) {
            let obj = this.alltoType == '1' ? {
                userId: userId,
                typeCode: 2
            } : {}
            let res = await api.allotSignList(obj)
            if (res.code == 200) {
                this.allotSignList = res.data
                this.columns = res.data.map((e) => {
                    return e.idName
                })
            }
        },
        //编辑客户详情
        toDetail (item) {
            this.$router.push({
                name: 'clientTDetailsName',
                params: {
                    id: item.id
                }
            })
        },
        //拖动
        dragx (el) {
            let that = this;
            this.Loop = setTimeout(function () {
                that.Loop = 0;
            }, 100);
            let oDiv = this.$refs.chatbox; //当前元素
            let disY = el.changedTouches[0].clientY - oDiv.offsetTop;
            document.ontouchmove = function (e) {
                //通过事件委托，计算移动的距离
                let t = e.changedTouches[0].clientY - disY;
                if (t < 0) {
                    //和左右距离同理
                    t = 0;
                } else if (t > document.documentElement.clientHeight - oDiv.offsetHeight) {
                    t = document.documentElement.clientHeight - oDiv.offsetHeight;
                }
                //移动当前元素
                oDiv.style.top = t + "px";
            };
            document.ontouchend = function (e) {
                document.ontouchmove = null;
                document.ontouchend = null;
            };
            // 解决有些时候,在鼠标松开的时候,元素仍然可以拖动;
            document.ondragstart = function (ev) {
                ev.preventDefault();
            };
            document.ondragend = function (ev) {
                ev.preventDefault();
            };
            return false;
        },
        //扫码
        handleScanQR () {
            let that = this;
            clearTimeout(this.Loop);
            if (that.Loop !== 0) {
                // console.log('扫码')
                // alert('扫码')
                wx.scanQRCode({
                    needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                    scanType: ["qrCode"], // 可以指定扫二维码还是一维码，默认二者都有
                    success: function (res) {
                        setTimeout(() => {
                            var result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
                            that.$router.push({
                                name: 'clientTDetailsName',
                                params: {
                                    id: result
                                }
                            })
                        }, 1000);
                    }
                });
            }
            return false;
        },
        async getWeiXinInfo () {
            let obj = {
                type: 0,
                agentid: sessionStorage.getItem('APPID'),
                url: location.href.split('#')[0]
            }
            let res = await api.getWxConfig(obj)
            if (res.code == 200) {
                wx.config({
                    beta: true,// 必须这么写，否则wx.invoke调用形式的jsapi会有问题
                    // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    debug: false,
                    // 必填，公众号的唯一标识
                    appId: res.data.appId,
                    // 必填，生成签名的时间戳
                    timestamp: res.data.timestamp,
                    // 必填，生成签名的随机串
                    nonceStr: res.data.noncestr,
                    // 必填，签名
                    signature: res.data.signature,
                    // 必填，需要使用的JS接口列表，所有JS接口列表
                    jsApiList: ['checkJsApi', 'scanQRCode']
                });
                wx.ready(function () {
                    wx.checkJsApi({
                        jsApiList: ['scanQRCode'],
                        success: function (res) {
                            console.log('checkJsApi成功')
                        }
                    });
                });
                wx.error(function (res) {
                    alert("错误信息：" + res.errMsg);//这个地方的好处就是wx.config配置错误，会弹出窗口哪里错误，然后根据微信文档查询即可。
                });
            }
        }
    },
    created () {
        this.getDictionariesList()
        this.getWeiXinInfo()
    }
}
</script>

<style scoped lang="scss">
.myClient-box {
    box-sizing: border-box;
    background-color: #F5F6F8;

    .list-box {
        padding: 10px 10px 0 10px;
        height: calc(100vh - 150px);
        overflow: auto;

        .list-item {
            background: linear-gradient(270deg, #FFFAF7 0%, #FFF2ED 100%);
            // padding: 0 10px;
            border-radius: 6px;
            margin-bottom: 10px;
            overflow: hidden;

            .item-top {
                padding: 0 10px;
                display: flex;
                align-items: center;
                height: 36px;
                position: relative;

                .top-name {
                    font-size: 16px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #000000;
                }

                .top-state {
                    color: #333333;
                    font-size: 12px;
                    background: #FFFFFF;
                    border-radius: 3px;
                    padding: 1px 4px;
                    margin-left: 24px;
                }

                .top-tag {
                    height: 19px;
                    background: #FFF2ED;
                    border-radius: 3px;
                    padding: 1px 4px;
                    position: absolute;
                    right: 15px;
                }
            }

            .item-center {
                padding: 0 10px;
                font-size: 12px;
                width: 100%;

                div {
                    padding: 5px 0;
                    display: flex;
                    justify-content: left;
                }

                .center-icon {
                    width: 16px;
                    height: 16px;
                    margin-right: 20px;

                }
            }

            .item-bottom {
                background-color: #FFFFFF;
                display: flex;
                font-size: 14px;
                color: #4A7CEE;

                div {
                    flex: 1;
                    text-align: center;
                    height: 20px;
                    line-height: 20px;
                    margin: 10px 0;
                    border-right: 1px solid #F2F3F5;
                }

                div:last-child {
                    border: none;
                }
            }
        }
    }

    .dialog-title {
        text-align: left;
        padding-left: 20px;
    }

    .scan-QR {
        width: 90px;
        height: 48px;
        background: #4871C0;
        border-radius: 100px 0px 0px 100px;
        position: fixed;
        right: 0;
        top: 50%;
        font-size: 14px;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 3px 18px 0px rgba(0, 43, 127, 0.32);

        .scan-icon {
            width: 20px;
            height: 20px;
            margin-right: 10px;
        }
    }
}
</style>
<style lang="scss">
.myClient-box {
    .van-field {
        background: #f2f3f5;
    }

    .input-style {
        .van-cell__value {
            border: 1px solid #e5e7eb;
            border-radius: 5px;

            input {
                padding-left: 10px;
            }
        }
    }

    .van-picker-column__item--selected {
        color: #4871C0;
    }
}
</style>