<template>
    <div class="main">
        <van-sticky>
            <headerNav leftNav=" " class="nav_b" @leftClick="back" title="当月数据"></headerNav>
            <div class="tab center flex">
                <div :class=" active1 == '1' ? 'active':'' " @click="monthData('1')">线索量</div>
                <div :class=" active1 == '2' ? 'active':'' " @click="monthData('2')">有效量</div>
                <div :class=" active1 == '3' ? 'active':'' " @click="monthData('3')">到店量</div>
                <div :class=" active1 == '4' ? 'active':'' " @click="monthData('4')">签单量</div>
            </div>
            <div class="tip">
                {{reportData}}月份数据展示
            </div>
        </van-sticky>
        <div class="monthData">
            <div class="table">
                <el-table :data="monthTableData" border style="width: 100%">
                    <el-table-column prop="reportData" align="center" min-width="100" fixed="left" label="日期"></el-table-column>
                    <el-table-column prop="week" min-width="60" align="center" fixed="left" label="星期"></el-table-column>
                    <el-table-column prop="qdNumber" min-width="80" align="right" label="青岛"></el-table-column>
                    <el-table-column prop="jnNumber" min-width="80" align="right" label="济南"></el-table-column>
                    <el-table-column prop="zbNumber" min-width="80" align="right" label="淄博"></el-table-column>
                    <el-table-column prop="lyNumber" min-width="80" align="right" label="临沂"></el-table-column>
                    <el-table-column prop="wfNumber" min-width="80" align="right" label="潍坊"></el-table-column>
                    <el-table-column prop="whNumber" min-width="80" align="right" label="威海"></el-table-column>
                    <el-table-column prop="ytNumber" min-width="80" align="right" label="烟台"></el-table-column>
                    <el-table-column prop="jniNumber" min-width="80" align="right" label="济宁"></el-table-column>
                    <el-table-column prop="ccNumber" min-width="80" align="right" label="长春"></el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>

<script>
	import api from '@/api/president.js'
    import moment from 'moment';
    import { Toast } from 'vant';
    import * as echarts from 'echarts';
	import {
		timeFormat
	} from '@/utils/timeCompute'
	export default {
		data() {
			return {
                reportData:'',
                active1:'1', //当月数据
                monthTableData: [],
            }
		},
		components: {
		},
		created() {
		},
        mounted() {
            this.reportData = moment().format('MM')
            this.active1 = this.$route.query.type
            this.getMonthData()
		},
		methods: {
            //回退
            back () {
                this.$router.go(-1)
            },
            formatDate(date) {
                let month,day
                if((date.getMonth() + 1)<10){
                    month = '0'+ (date.getMonth() + 1)
                }else{
                    month = (date.getMonth() + 1)
                }
                if(date.getDate()<10){
                    day = '0'+date.getDate()
                }else{
                    day = date.getDate()
                }
                return `${date.getFullYear()}-${month}-${day}`;
            },
            // 封装
            handleEcharts (name, option) {
                var chartDom = document.querySelector(name);
                // var chartDom = document.getElementById('main');
                var myChart = echarts.init(chartDom, 'night');
                option && myChart.setOption(option);
            },
            monthData(type){
                this.active1 = type
                this.getMonthData()
            },
            getMonthData(){
                let params = {
                    'ext':this.active1,
                }
                api.monthTableApi(params).then((res)=>{
                    console.log(res);
                    this.monthTableData = res.data
                })
            },
        },
		
	}
</script>

<style scoped lang="scss">
.flex{
    display: flex;
}
.nav_b {
    background: #3975C6;
}

.center{
    display: flex;
    justify-content: center;
    align-items: center;
}

.tab{
    margin:0px 15px 8px;
    justify-content: space-between;
    border-radius: 4px 4px 4px 4px;
    padding-top: 12px;
    div{
        background: #F8F8F8;
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #545454;
        width: 25%;
        line-height: 36px;
    }
    .active{
        font-size: 14px;
        font-family: PingFang SC-Bold, PingFang SC;
        color: #4871C0;
        background: #FFFFFF;
        border-radius: 4px 4px;
        border: 1px solid #4871C0;
    }
}
.tip{
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #6990DB;
    text-align: left;
    margin-left: 12px;
    padding-bottom: 5px;
}
.main{
    background: #F5F5F5;
    width: 100%;
    height: 100%;
    .monthData{
        width: 100%;
        height: 100%;
        background: #FFFFFF;
        margin-bottom: 15px;
        .amount{
            padding-bottom: 8px;
            color: #FF2B2B;
        }
        .average{
            padding-top: 8px;
        }
    }
}

::v-deep{
    .el-table--border .el-table__cell, .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed{
        border-right: none;
    }
    .el-table th.el-table__cell{
        background: #F5F5F5;
    }
    .el-table .cell{
        font-weight: normal;
        font-size: 14px;
    }
    .van-sticky{
        background: #FFFFFF;
    }

    .monthData .el-table__row:nth-last-child(1),.monthTable .el-table__row:nth-last-child(2){
        background: #fdfdfd;
    }
    .monthData .el-table__row:nth-last-child(2) td:nth-child(1){
        color: #FF2B2B;
    }

    body .el-table th.gutter{
        display:table-cell !important;
    }
    body .el-table colgroup.gutter{
        display:table-cell !important;
    }
    table{
        width:100%  !important;
    }
}
</style>
