<template>
  <div class="page">
    <header>
      <div class="avatar">
        <img v-if="isAvatar" src="../../../assets/task-management/my-male.png">
        <img v-else src="../../../assets/task-management/my-female.png">
      </div>
      <div class="name">{{ username }}</div>
      <div class="role">
        <div class="role-optional" v-if="roleList.length>1" @click="switchRole">{{ currentRole }}<van-icon name="arrow-down" /></div>
        <div class="role-unoptional" v-else >{{ currentRole }}</div>
        <van-popup v-model="showRolePopup" position="bottom" :style="{ height: '45%' }" :lazy-render="false">
          <van-picker ref="picker" show-toolbar :columns="roleList" allow-html @confirm="onConfirmRole" title="角色选择" @cancel="showRolePopup = false" />
        </van-popup>
      </div>
    </header>
    <main>
      <ul v-for="(item,index) in itemList" :key="index">
        <li class="cell" @click="handleCell(item.handle)">
          <img :src="item.icon">
          <span>{{item.name}}</span>
        </li>
      </ul>
    </main>
  </div>
</template>

<script>
import api from "@/api/task-management/my.js"
import api1 from "@/api/task-management/board.js";
export default {
  components: {},
  data () {
    return {
      isAvatar:false, //头像的性别判断
      username:"",
      showRolePopup:false, //角色弹窗
      currentRole:"", //当前角色
      roleList:[], //角色列表
      itemList:[
        {
          name:"个人信息",
          icon: require("@/assets/task-management/my-icon-info.png"),
          handle:"info"
        },
        {
          name:"审批待办(后续)",
          icon: require("@/assets/task-management/my-icon-approval.png"),
          handle:"approval"
        },
      ],
      userDetails:{}
    }
  },
  created () {
    // 当前角色
    let role = JSON.parse(localStorage.getItem('USER_ROLE'))
    this.currentRole = role.roleName
    // 角色列表
    let list = JSON.parse(localStorage.getItem('USER_ROLE_LIST'))
    this.roleList = list.map(item => {
      return { text: item.roleName, value: item.roleCode }
    })
    // 用户信息
    this.getUserInfo()
  },
  methods: {
    switchRole(){
      // let role = JSON.parse(localStorage.getItem('USER_ROLE'))
      this.$refs.picker && this.$refs.picker.setValues([this.currentRole]);
      console.log(this.$refs.picker);
      this.showRolePopup = true
    },
    async onConfirmRole(value){
      this.currentRole = value.text
      await api.changeRoleInfoApi({"roleCode":value.value})
      let list = JSON.parse(localStorage.getItem('USER_ROLE_LIST'))
      let userRole = list.filter((item)=>{
        return item.roleCode == value.value
      })
      await api1.getButtonResourceApi({"roleCode":value.value}).then((res)=>{
        if(res.code == 200){
          this.$store.commit('setUserRole', {...userRole[0],btn:res.data});
        }
      })
      this.showRolePopup = false
    },
    handleCell(value){
      if(value == "info"){
        this.$router.push({
          path:"/task-management/my/info",
          query:{info:JSON.stringify(this.userDetails)}
        })
      }
    },
    // 查询用户基本信息
    async getUserInfo(){
      let userInfo = JSON.parse(sessionStorage.getItem('USERINFO'))
      let data = await api.getRoleApi({"Q_id_EQ":userInfo.userId})
      console.log(data);
      if (data) {
        let {idName,gender,mobile,marketName,status} = data.data[0]
        this.isAvatar = gender == "1"?true:false
        this.username = idName
        let role = JSON.parse(localStorage.getItem('USER_ROLE'))
        this.userDetails = {
          idName,
          gender,
          mobile,
          marketName,
          status,
          department:role.departmentName
        }
        console.log(this.userDetails);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.page{
  width: 100%;
  height: calc(100vh - 75px);
  background: #F2F2F2;
  header{
    width: 100%;
    height: 224px;
    background: url("../../../assets/task-management/my-bg.png") no-repeat center;
    background-size: 100% 100%;
    margin-bottom: 16px;
    .avatar{
      margin: 0 auto;
      padding: 30px 0 16px;
      img{
        width: 80px;
        height: 80px;
      }
    }
    .name{
      font-family: PingFang SC, PingFang SC;
      font-weight: bold;
      font-size: 24px;
      color: #FFFFFF;
      line-height: 28px;
      text-align: center;
    }
    .role{
      
      .role-optional,.role-unoptional{
        width: fit-content;
        height: 28px;
        background: rgba(68,129,211,0.8);
        border-radius: 2px 2px 2px 2px;
        margin: 10px auto;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 14px;
        color: #FFFFFF;
        line-height: 28px;
        padding: 0 10px;
      }
      .van-icon {
        margin-left: 3px;
      }
    }
  }
  main{
    width: 100%;
    height: calc(100% - 256px);
    background: #FFFFFF;
    overflow: auto;

    .van-cell{
      padding: 16px 12px;
    }
    .cell{
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 17px;
      color: #333333;
      text-align: left;
      height: 56px;
      line-height: 56px;
      margin: 0 12px;
      box-shadow: inset 0px -1px 0px 0px rgba(51,51,51,0.08);
      display: flex;
      align-items: center;
      img{
        width: 22px;
        height: 22px;
        margin-right: 8px;
      }
    }
  }
}

</style>