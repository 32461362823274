<template>
  <div class="content market-content">
    <headerNav leftNav=" " :title="navTitle" @leftClick="back"></headerNav>
    <main class="content-main">
      <!-- 汇总 -->
      <BoardNavSummarize 
      :imgBackUrlName="typeList.bgIcon" 
      :ratio="indicatorsData.finishRate" 
      :completionsCount="indicatorsData.finishValue"
      :sumTotal="indicatorsData.targetValue"
      :leftIconName="typeList.leftIcon"
      :leftText="typeList.leftText"
      :rightIconName="typeList.rightIcon"
      :rightText="typeList.rightText"
      :unit="typeList.unit"
      :targetName="typeList.operate"
      :showFoot="!typeList.showFoot"
      />
      <div class="content-center-title">
        <div>人员详情</div>
      </div>

      <!-- table -->
      <el-table :data="tableData" style="width: 100%"
        header-cell-class-name="market-title" row-class-name="market-row" row-key="indexCode" :expand-row-keys="defaultExpandList">
        <!-- 自定义暂无数据 -->
        <template slot="empty">
          <div class="empty-no-data">
            <img src="../../../../assets/task-management/no-data.png" alt="">
            <div>暂无数据</div>
          </div>
        </template>
        <el-table-column type="index" label="TOP" align="center" width="43">
          <template slot-scope="props">
            <div v-if="typeList.type != 'invite_job'">
              <div> {{ props.$index + 1 }}</div>
            </div>
            <div v-else>
              <div v-if="props.$index < 3" style="line-height: 1;"><img class="index-img" :src="getImageIndex(props.$index)" alt=""></div>
              <div v-else> {{ props.$index + 1 }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="indexName" label="人员" min-width="60">
          <template slot-scope="props">
            <div class="anomaly">
              <span v-if="typeList.type == 'invite_job'" class="market-content-a" @click="onClickDetails(props.row)">{{ props.row.indexName }}</span>
              <span v-else class="market-content-text" >{{ props.row.indexName }}</span>
              <div class="anomaly-bg" v-if="props.row.isException == '1'">异常</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column v-for="(item,index) in typeList.table" :key="index" :prop="item.prop" :label="item.label" :align="item.align?item.align:'right'" :min-width="item.minWidth">
          <template slot="header" slot-scope="scope">
            {{ item.label }}
            <el-tooltip v-if="item.prompt" class="item" effect="dark" :content="item.prompt" placement="top">
              <img class="table-prompt-icon" src="../../../../assets/task-management/table-prompt-icon.png" alt="">
            </el-tooltip>
          </template>
          <template slot-scope="scope">
            <span >{{ item.prop == "finishRate" ? scope.row[item.prop]+"%" : scope.row[item.prop] }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="handle" label="操作" min-width="38">
          <template slot-scope="scope">
            <span class="market-details" @click="expandControlRow(scope.row)">详情</span>
          </template>
        </el-table-column>
        <!-- 展开行详情 -->
        <el-table-column type="expand" width="1">
          <template slot-scope="scope">
            <div class="market-content-expand">
              <div class="market-content-expand-left">
                <img :src='"@/assets/task-management/"+typeList.detailIcon+".png"' alt="">
                <div>上月{{ typeList.operate }}：{{ scope.row.lastMonthFinishRate }}%</div>
              </div>
              <div class="market-content-expand-right">
                <span class="color-green" v-if="scope.row.lastMonthStatus == '1'">已完成</span>
                <span class="color-red" v-else>未完成</span>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </main>
  </div>
</template>

<script>
import BoardNavSummarize from "@/views/task-management/component/public/module/BoardNavSummarize.vue"
import { employeeModelFn } from "./data.js"
import api from "@/api/task-management/human-resources.js";
import { Toast } from "vant";
import moment from "moment";

export default {
  components: {
    BoardNavSummarize
  },
  data () {
    return {
      // 任务看板路由接参
      routerData: {},
      // 指标
      indicatorsData:{},
      // 市场表格数据
      tableData:[],
      // 展开行id
      defaultExpandList: [],
      // 模块类型数据 
      typeList:{},
    }
  },
  created () {
    let localData = JSON.parse(localStorage.getItem("TABLE_HEADER_CONTENT"));
    this.routerData = localData;
    this.typeList = employeeModelFn(localData.termSlug)
    this.navTitle = this.typeList.name + '详情'
  },
  mounted() {
    this.getTableData()
  },
  methods: {
    back () {
      this.$router.go(-1)
    },
    // 查询表格数据
    async getTableData () {
        Toast.loading({
          message: '加载中...',
          forbidClick: true,
        });
        let params = {
          "type": "2", //1=市场；2=人员；3=任务
          "ext": this.typeList.type,
          "Q_reportData_EQ": moment().format("YYYY-MM"),
          "indexCode":this.$route.query.indexCode
        }
        api.getTasksDetail(params).then((res)=>{
          if (res.code == 200) {
            Toast.clear()
            this.indicatorsData = res.data.statisticsObject
            if(res.data.detailList){
              res.data.detailList.forEach((item,index)=>{
                item.finishRate = parseFloat(item.finishRate).toFixed(2)
              })
            }
            this.tableData = res.data.detailList || []
          }else{
            Toast.fail(res.msg)
          }
        })
    },
    // 查询表格数据详情
    async getRowDetail (value) {
        Toast.loading({
          message: "加载中...",
          forbidClick: true,
        });
        let params = {
          "taskDate": moment().format("YYYY-MM"),
          "indexCode": value,
          "termId":this.typeList.type,
          "type":"2",//1=市场页面:2=人员页面
        }
        api.getLastDetail(params).then((res)=>{
          if (res.code == 200) {
            Toast.clear()
            this.tableData.forEach((item,index)=>{
              if(item.indexCode == value){
                item.lastMonthFinishRate = res.data.finishRate,
                item.lastMonthStatus = res.data.status
                this.$set(this.tableData,index,this.tableData[index])
              }
            })
          }else{
            Toast.fail(res.msg)
          }
        })
    },
    // 控制展开行
    expandControlRow (item) {
      const position = this.defaultExpandList.indexOf(item.indexCode)
      if (position !== -1) {
        this.defaultExpandList.splice(position, 1)
      } else {
        this.defaultExpandList.push(item.indexCode)
        this.getRowDetail(item.indexCode)
      }
    },
    // 获取图片
    getImageIndex (index) {
      return require(`@/assets/task-management/task-details-table-${index + 1}.png`)
    },
    // 点击市场查看部门
    onClickDetails (row) {
      this.$router.push({
        name:"TaskBoardHumanResourcesRecruitmentTrialDetail",
        query:{
          type:this.typeList.type,
          indexCode:row.indexCode,
          marketCode:row.marketCode,
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped src="../../component/public/css/table.scss"></style>
<style lang="scss" scoped>
::v-deep{
  .table-prompt-icon{
    width: 14px;
    height: 14px;
    vertical-align: middle;
  }
  .anomaly {
    position: relative;

    .anomaly-bg {
      height: 19px;
      width: 39px;
      text-align: center;
      color: #fff;
      position: absolute;
      font-size: 12px;
      top: -8px;
      right: -25px;
      line-height: 19px;
      background: url("../../../../assets/task-management/employee-list-anomaly.png")
        no-repeat;
      background-position: 100%;
      background-size: cover;
      z-index: 10;
    }
  }
  .content-main .market-row .cell {
    overflow: visible !important;
  }
  .content-center-title{
    div:nth-child(1){
      position: relative;
      padding-left: 12px;
    }
    div:nth-child(1)::after{
      content: '';
      width: 4px;
      height: 15px;
      background: #0E8BFF;
      border-radius: 1px 1px 1px 1px;
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -7.5px;
    }
  }
  .el-table__cell {
    padding: 3px 0px;
    height: 30px;
    overflow: visible;
    .cell {
      padding: 5px 3px;
      font-weight: 400;
      overflow: visible;
      white-space: nowrap;

      .anomaly {
        .market-content-a,.market-content-text {
          white-space: nowrap;
          // /* 不换行 */
          overflow: hidden;
          // /* 隐藏超出部分 */
          text-overflow: ellipsis;
          display: block;
        }
      }
    }
  }
}
</style>