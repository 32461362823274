<template>
    <div class="construction-style">
        <headerNav title="申领单管理"></headerNav>
        <div class="search-style">
            <van-field class="choosetime-input" v-model="searchValue" @blur="onRefresh()" clearable
                placeholder=" 对姓名、单据编号进行模糊查询">
                <template v-slot:right-icon>
                    <div style="height: 100%;display: flex;align-items: center;">
                        <img src="@/assets/search.png" class="search-icon" alt="">
                    </div>
                </template>
            </van-field>
        </div>
        <filterModel :filterList="filterCondition" @filterFunction="onRefresh" ref="filter"></filterModel>
        <div class="content-style">
            <pullRefresh ref="pullRefresh" @refresh="onRefresh">
                <van-list class="vant-list-style" v-model="loading" :finished="finished"
                    :finished-text="total ? '·已加载全部数据·' : '·暂无数据·'" @load="onLoad">
                    <div class="list-item" v-for="item in list">
                        <div class="item-top">
                            <div class="name">
                                {{ item.consumerName }}
                                <van-tag plain type="primary" style="line-height: 20px;">{{item.nodeName }}</van-tag>
                                <van-tag v-if="item.stockStatus=='1'" round class="right-tag" type="primary" color="rgb(25, 137, 250)"
                                    size="medium">已备货</van-tag>
                                <van-tag v-else-if="item.stockStatus=='2'" round class="right-tag" type="success" color="rgb(25, 137, 250)"
                                    size="medium">已领取</van-tag>
                                <van-tag v-else round class="right-tag" type="warning" size="medium">未备货</van-tag>
                            </div>
                            <div class="item-top-info">
                                <div>
                                    <p>申领人</p>{{ item.createName }}
                                </div>
                                <div>
                                    <p>申领种类</p>{{ item.applyQty }}种
                                </div>
                                <div>
                                    <p>申领时间</p>{{ item.createDate }}
                                </div>
                                <div>
                                    <p>单据编号</p>{{ item.applyCode }}
                                </div>
                            </div>
                        </div>
                        <div class="item-bottom">
                            <div v-if="item.stockStatus=='1'" class="bottom-item-style" @click="changeState(item,'2')">
                                <span class="text">已领取</span>
                            </div>
                            <div v-if="!item.stockStatus || item.stockStatus=='0'" class="bottom-item-style" @click="changeState(item,'1')">
                                <span class="text">已备货</span>

                            </div>
                            <div class="bottom-item-style" style="border: none;" @click="toDetails(item)">
                                <span class="text">详情</span>
                            </div>
                        </div>
                    </div>
                </van-list>
            </pullRefresh>
        </div>
    </div>
</template>

<script>
import api from '@/api/api.js'
import { Toast } from 'vant';
import filterModel from '@/components/filter.vue'
export default {
    data () {
        return {
            searchValue: '',
            list: [],
            action: {},
            actions: [],
            isShowGrade: false,
            isLoading: false,
            loading: false,
            finished: false,
            error: false, // 是否加载失败
            pageNum: 1, // 分页
            pageSize: 10, // 每页条数
            total: 0, // 数据总条数
            filterCondition: [
                {
                    typeId: 'processCode',
                    dictValue: 'default',
                    dictLabel: '状态',
                    default: '状态',
                    actions: [
                        {
                            dictValue: 'default',
                            dictLabel: '全部'
                        },
                        {
                            dictValue: '0',
                            dictLabel: '未备货'
                        },
                        {
                            dictValue: '1',
                            dictLabel: '已备货'
                        },
                        {
                            dictValue: '2',
                            dictLabel: '已领取'
                        },
                    ]
                },
                {
                    typeId: 'date-quantum-1',
                    dictValue: 'default',
                    dictLabel: '申领时间',
                    default: '申领时间',
                    actions: [{
                        dictValue: 'default',
                        dictLabel: '所有日期'
                    }, {
                        dictValue: '1',
                        dictLabel: '自定义日期'
                    }]
                }, {
                    typeId: 'date-quantum-2',
                    dictValue: 'default',
                    dictLabel: '领取时间',
                    default: '领取时间',
                    actions: [{
                        dictValue: 'default',
                        dictLabel: '所有日期'
                    }, {
                        dictValue: '1',
                        dictLabel: '自定义日期'
                    }]
                },],
        }
    },
    components: {
        filterModel
    },
    methods: {
        //获取列表
        async getList (e) {
            let obj = {
                page: this.pageNum,
                limit: this.pageSize,
                Q_managerApplyStatus_IN: "1,2",
                Q_budgetApplyStatus_IN: "1,2",
                content : this.searchValue
            }
            if (e) {
                obj.Q_stockStatus_EQ = e[0].dictValue == "default" ? '' : e[0].dictValue //订单状态
                //申领时间
                if (e[1].dictValue != "default") {
                    obj.Q_createDate_BW = e[1].dictLabel[0] + ' 00:00:00' + '~' + e[1].dictLabel[1] + ' 23:59:59'
                }
                //领取时间
                if (e[2].dictValue != "default") {
                    obj.Q_receiveDate_BW = e[2].dictLabel[0] + ' 00:00:00' + '~' + e[2].dictLabel[1] + ' 23:59:59'
                }
            }
            let res = await api.getStorageList(obj)
            if (res.code == 200) {
                this.total = res.count;
                if (res.data.length === 0) { // 判断获取数据条数若等于0
                    this.list = []; // 清空数组
                    this.finished = true; // 停止加载
                } else {
                    // 若数据条数不等于0
                    this.list.push(...res.data) // 将数据放入list中
                    this.loading = false; // 加载状态结束 
                    // 如果list长度大于等于总数据条数,数据全部加载完成
                    if (this.list.length >= res.count) {
                        this.finished = true; // 结束加载状态
                    }
                }
                this.isLoading = false
                if (this.$refs.pullRefresh) {
                    this.$refs.pullRefresh.refreshSuccess()
                }
            }
        },
        // 被 @load调用的方法
        onLoad (e) { // 若加载条到了底部
            let timer = setTimeout(() => { // 定时器仅针对本地数据渲染动画效果,项目中axios请求不需要定时器
                // this.getList(); // 调用上面方法,请求数据
                this.getList(e)
                this.pageNum++; // 分页数加一
                this.finished && clearTimeout(timer); //清除计时器
            }, 100);
        },
        // 加载失败调用方法
        onRefresh (e) {
            this.finished = false; // 清空列表数据
            this.loading = true; // 将 loading 设置为 true，表示处于加载状态
            this.pageNum = 1; // 分页数赋值为1
            this.list = []; // 清空数组
            this.onLoad(this.$refs.filter.filterCondition); // 重新加载数据
        },
        //改变订单状态
        async changeState (item, state) {
            let obj = {
                id: item.id,
                stockStatus: state,//1，已备货；2，已领取
            }
            let res = await api.changeState(obj)
            if (res.code == 200) {
                let msg = state == '1' ? '备货成功！' : '领取成功'
                this.onRefresh()
                Toast.success(msg);
            }
        },
        //前往订单详情
        toDetails (item) {
            sessionStorage.setItem('materialsDetails', JSON.stringify(item))
            this.$router.push({ name: 'storageDetailsName' })
        },

    },
    created () { },
}
</script>

<style scoped lang="scss">
.construction-style {
    background-color: #F5F6F8;

    .content-style {
        box-sizing: border-box;
        padding: 10px;
        height: calc(100vh - 140px);
        overflow: auto;

        .list-item {
            background: #FFFFFF;
            // padding: 8px;
            margin-bottom: 10px;
            border-radius: 6px;
            text-align: left;

            .name {
                position: relative;
                font-size: 16px;
                color: #333333;
                display: flex;
                align-items: center;

                span {
                    margin-left: 10px;
                    // line-height: 20px;
                }

                .right-tag {
                    position: absolute;
                    right: 0;
                }
            }

            p {
                display: inline-block;
                width: 50px;
                margin: 0;
                margin-top: 5px;
                line-height: 20px;
            }

            .item-top {
                padding: 8px;

                .item-top-info {
                    display: flex;
                    flex-wrap: wrap;

                    div {
                        width: 50%;
                    }
                }
            }

            .item-bottom {
                border-top: 1px solid #ebedf0;
                display: flex;
                align-items: center;
                height: 35px;

                .bottom-item-style {
                    flex: 1;
                    border-right: 1px solid #E6E6E6;
                    text-align: center;

                    .text {
                        font-size: 13px;
                        font-family: Source Han Sans SC;
                        font-weight: 400;
                        color: rgb(25, 137, 250);
                    }
                }


            }
        }
    }
}

.empty {
    padding: 30px;
    font-weight: 400;
    color: #AAAAAA;
}
</style>
