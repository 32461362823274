<template>
    <div>
        <van-list class="vant-list-style" v-model="loading" :finished="finished"
            :finished-text="total ? '·已加载全部数据·' : '·暂无数据·'" @load="onLoad">
            <pullRefresh ref="pullRefresh" @refresh="onRefresh">
                <div v-if="!recordList.length">暂无数据</div>
                <div class="list-item" v-for="(item, index) in recordList">
                    <div class="item-top">
                        <div class="name">{{ item.userName }}</div>
                        <div class="depart">{{ item.departName }} </div>
                        <div class="time">{{ item.createDate }}</div>
                    </div>
                    <div class="item-radio">
                        <div class="radioName">录音名称：{{ item.fileName }}</div>
                        <audio :src="item.fileAdd" :ref="'audio'+index" controls style="height: 30px;width: 100%;"></audio>
                        <div class="btn">
                            <div @click="handleDown(item,'audio'+index)"><img :src="require('@/assets/save_bendi.svg')" width="16px" height="18px" alt="">保存至本地</div>
                            <div @click="handleRenchristen(item)"><img :src="require('@/assets/edit_bendi.svg')" width="16px" height="18px" alt="" >重命名</div>
                        </div>
                    </div>
                </div>
            </pullRefresh>
        </van-list>
        <!-- <van-icon name="add" class="add" size="50" color="#4F7AFD" @click="addAudio" /> -->
        <input type="file" ref="selectAudio" messageAlign="left" name="files" @change="chooseChange"
            v-show="false" />
            <van-dialog v-model="showNameDialog" show-cancel-button confirm-button-color="#206CFE"  @confirm="handleSubmit" @close="handleClose">
                <div class="main_content">
                    <div class="nav">请输入录音名称</div>
                    <div class="center_main">
                        <input type="text" v-model="NameValue" placeholder="请输入录音名称">
                    </div>
                </div>
            </van-dialog>
    </div>
</template>

<script>
import api from '../../../api/api';
import {
    Toast,Dialog
} from 'vant';
export default {
    data () {
        return {
            recordList: [],
            isLoading: false,
            loading: false,
            finished: false,
            error: false, // 是否加载失败
            pageNum: 1, // 分页
            pageSize: 10, // 每页条数
            total: 0, // 数据总条数
            showNameDialog:false,
            NameValue:'',
            rowData:{}
        }
    },
    methods: {
        async getRecordList () {
            let obj = {
                page: this.pageNum,
                limit: this.pageSize,
                Q_typeCode_EQ: 1,
                Q_consumerId_EQ: this.$route.params.id,
            }
            let res = await api.getRecordList(obj)
            if (res.code == 200) {
                this.total = res.count;
                if (res.data.length === 0) { // 判断获取数据条数若等于0
                    this.recordList = []; // 清空数组
                    this.finished = true; // 停止加载
                } else {
                    // 若数据条数不等于0
                    this.recordList.push(...res.data) // 将数据放入list中
                    this.loading = false; // 加载状态结束 
                    // 如果list长度大于等于总数据条数,数据全部加载完成
                    if (this.recordList.length >= res.count) {
                        this.finished = true; // 结束加载状态
                    }
                }
                this.isLoading = false
                if (this.$refs.pullRefresh) {
                    this.$refs.pullRefresh.refreshSuccess()
                }
            }
        },
        // 被 @load调用的方法
        onLoad (e) { // 若加载条到了底部
            let timer = setTimeout(() => { // 定时器仅针对本地数据渲染动画效果,项目中axios请求不需要定时器
                this.getRecordList(); // 调用上面方法,请求数据
                this.pageNum++; // 分页数加一
                this.finished && clearTimeout(timer); //清除计时器
            }, 100);
        },
        // 加载失败调用方法
        onRefresh (e) {
            this.finished = false; // 清空列表数据
            this.loading = true; // 将 loading 设置为 true，表示处于加载状态
            this.pageNum = 1; // 分页数赋值为1
            this.recordList = []; // 清空数组
            this.onLoad(e); // 重新加载数据
        },
        //上传录音
        addAudio(){
            this.$refs.selectAudio.value = ''
            this.$refs.selectAudio.click()
        },
        async chooseChange(e){
            let formData = new FormData();
            formData.append('files', e.target.files[0]);
            formData.append('dir', 'oss');
            let res = await api.upFile(formData)
            if (res.code == 200) {
                let obj = {
                    consumerId: this.$route.params.id,
                    clueId: this.$route.params.clueId,
                    typeCode: 1,//文件类别(0:图片;1:录音;2:视频)
                    urlList: [res.url]
                }
                this.confirmLoading = true
                let res2 = await api.saveFiles(obj)
                if (res2.code == 200) {
                    Toast.success('上传录音成功!');
                    this.onRefresh()
                }
            }
        },
        // 下载文件
        handleDown(row,index){
            fetch(row.fileAdd)
            .then(response => response.blob())
            .then(blob => {
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', row.fileName+'.mp3');
                document.body.appendChild(link);
                link.click();
            })
            .catch(console.error);
        },
        // 重命名
        handleRenchristen(item){
            this.NameValue = ''
            this.rowData = item
            this.showNameDialog = true
        },
        // 弹窗确认
        async handleSubmit(){
            let res = await api.editRadioName({id:this.rowData.id,fileName:this.NameValue})
            console.log(res)
            if(res.code == 200){
                Toast.success('修改成功!');
                this.onRefresh()
            }
        },
        // 弹窗关闭
        handleClose(){
            // this.NameValue = ''
        }
    }
}
</script>

<style lang="scss" scoped>


.main_content{
    margin: 20px;
    text-align: left;
    .nav{
        font-size: 16px;
        color: #000000;
        margin: 10px 0px;
        font-weight: 400;
        font-family: PingFang SC-Regular, PingFang SC;
    }
    .center_main{
        padding: 0px 10px 0px 0px;
        input{
            border: 1px solid #E0E0E0;
            height: 40px;
            width: 100%;
        }
    }
}

.list-item {
    background-color: #FFFFFF;
    margin: 7px;
    margin-bottom: 0;
    border-radius: 8px;
    box-shadow: 1px 1px 6px #E6E6E6;

    .item-top {
        display: flex;
        align-items: center;
        position: relative;
        padding: 10px;
        border-bottom: 1px solid #E6E6E6;
        .name {
            font-size: 17px;
            margin-right: 10px;
        }

        .depart {
            color: #AAAAAA;
        }

        .time {
            position: absolute;
            right: 10px;
            color: #AAAAAA;
        }
    }
    .item-radio{
        padding: 10px 10px;
        .radioName{
            text-align: left;
            color: #333B49;
            font-size: 12px;
            margin-bottom: 2px;
            font-family: PingFang SC-Regular, PingFang SC;
        }
        .btn{
            width: 100%;
            display: flex;
            justify-content: space-around;
            align-items: center;
            color: #4782F3;
            font-size: 15px;
            font-family: PingFang SC-Regular, PingFang SC;
            margin-top: 10px;
            div{
                display: flex;
                align-items: center;
                img{
                    margin-right: 2px;
                    display: inline-block;
                    margin-top: 2px;
                }
            }
        }
    }
}
.add {
    position: fixed;
    bottom: 80px;
    right: 30px;
    z-index: 10;
}
</style>