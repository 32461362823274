<template>
    <div class="main_box">
        <headerNav title="消息详情" @leftClick="back"></headerNav>
        <div class="height_style1">
            <div class="info_main">
                <div class="info_nav">
                    <div class="info_leftS">
                        <div class="info_leftS_tit1">{{
                            `【${customerDetails.consumerName}】【${customerDetails.maintainContract}】审核` }}</div>
                        <div class="info_leftS_tit2">
                            <div class="info_leftS_tit2L">合同号</div>
                            <div class="info_leftS_tit2R">{{ customerDetails.maintainContract }}</div>
                        </div>
                    </div>
                    <div class="info_right" v-if="customerDetails.typeCodeName === '入库单'">
                        <img src="../../assets/comfime.svg" alt="" class="img"
                            v-if="customerDetails.deepStatus == 'ITEM446651'">
                        <img src="../../assets/comfimeNo.svg" alt="" class="img"
                            v-if="customerDetails.deepStatus == 'ITEM507884'">
                    </div>
                    <div class="info_right" v-else>
                        <img src="../../assets/comfime.svg" alt="" class="img"
                            v-if="customerDetails.orderStatusName == '已通过'">
                        <img src="../../assets/comfimeNo.svg" alt="" class="img"
                            v-if="customerDetails.orderStatusName == '未通过'">
                    </div>
                </div>
                <div class="info_content">
                    <div class="cont_main" v-if="isSub">
                        <div class="cont_flex">
                            <div class="flex_a">客户姓名:</div>
                            <div class="flex_b">{{ customerDetails.consumerName }}</div>
                        </div>
                        <div class="cont_flex">
                            <div class="flex_a">合同号:</div>
                            <div class="flex_b">{{ customerDetails.maintainContract }}</div>
                        </div>
                        <div class="cont_flex">
                            <div class="flex_a">单据类型:</div>
                            <div class="flex_b">{{ customerDetails.typeCodeName }}</div>
                        </div>
                        <div class="cont_flex">
                            <div class="flex_a">品牌:</div>
                            <div class="flex_b">{{ customerDetails.brandName || customerDetails.item[0].brandName }}</div>
                        </div>
                        <div class="cont_flex">
                            <div class="flex_a">供应商:</div>
                            <div class="flex_b">{{ customerDetails.supplierName }}</div>
                        </div>
                        <div class="cont_flex">
                            <div class="flex_a">项目经理:</div>
                            <div class="flex_b">{{ customerDetails.projectName }}</div>
                        </div>
                        <div class="cont_flex">
                            <div class="flex_a">深化:</div>
                            <div class="flex_b">{{ customerDetails.deepenName }}</div>
                        </div>
                        <div class="cont_flex">
                            <div class="flex_a">装修地址:</div>
                            <div class="flex_b">{{ customerDetails.detailAddress }}</div>
                        </div>
                    </div>
                    <div class="content_bottom" @click="handleChangeSub">
                        <div class="flex">
                            {{ isSub ? '收起更多' : '查看更多' }}
                            <img :class="isSub ? '' : 'jiantou'" :src="require('@/assets/sqgdpng.png')" alt=""
                                style="width:12px;height:12px;margin-left:4px">
                        </div>
                    </div>
                </div>
            </div>

            <div class="center_info">
                <div class="center_count">
                    <div class="left">
                        <div class="left_a">{{ customerDetails.orderName }}</div>
                        <div class="left_c">单据编号：{{ customerDetails.orderNo }}</div>
                    </div>
                </div>
                <div class="center_count">
                    <div class="left">
                        <div class="left_a">合计</div>
                        <div class="left_b">数量/价格</div>
                    </div>
                    <div class="right">
                        <div class="right_a">{{ quantity }}</div>
                        <div class="right_b">{{ '￥' + total }}</div>
                    </div>
                </div>
            </div>

            <div class="bottom_table">
                <div class="table_mian">
                    <table v-if="customerDetails.typeCodeName === '入库单'">
                        <thead>
                            <tr class="tr_th">
                                <th style="width:60px;text-align:center;padding-left:15px">序号</th>
                                <th style="width:150px;text-align:left;padding-left:15px">材料名称</th>
                                <th style="width:60px;text-align:right;padding-left:15px">数量</th>
                                <th style="width:60px;text-align:left;padding-left:15px">单位</th>
                                <th style="width:80px;text-align:left;padding-left:15px">型号</th>
                                <th style="width:60px;text-align:left;padding-left:15px">规格尺寸</th>
                                <th style="width:100px;text-align:left;padding-left:15px">单价</th>
                                <th style="width:60px;text-align:right;padding-left:15px">小计</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in customerDetails.item" class="main_tr" :key="'rol1-' + index">
                                <!-- 序号 -->
                                <td style="text-align:center;padding-left:15px">{{ index + 1 }}</td>
                                <!-- 材料名称 -->
                                <td style="text-align:left;padding-left:15px">{{ item.itemName }}</td>
                                <!-- 数量 -->
                                <td style="text-align:right;padding-left:15px">{{ item.productQty }}</td>
                                <!-- 单位 -->
                                <td style="text-align:left;padding-left:15px">{{ item.unitName }}</td>
                                <!-- 型号 -->
                                <td style="text-align:left;padding-left:15px">{{ item.modelSpec }}</td>
                                <!-- 规格尺寸 -->
                                <td style="text-align:left;padding-left:15px">{{ item.itemSize }}</td>
                                <!-- 单价 -->
                                <td style="text-align:left;padding-left:15px">{{ item.lastPrice }}</td>
                                <!-- 小计 -->
                                <td style="text-align:right;padding-left:15px">{{ item.lastSubtotal }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <table v-else>
                        <thead>
                            <tr class="tr_th">
                                <th style="width:60px;text-align:center;padding-left:15px">序号</th>
                                <th style="width:150px;text-align:left;padding-left:15px">材料编号</th>
                                <th style="width:100px;text-align:left;padding-left:15px">品牌</th>
                                <th style="width:100px;text-align:right;padding-left:15px">套餐类型</th>
                                <th style="width:100px;text-align:center;padding-left:15px">样式图</th>
                                <th style="width:150px;text-align:left;padding-left:15px">商品名称</th>
                                <th style="width:80px;text-align:left;padding-left:15px">规格</th>
                                <!-- <th style="width:60px;text-align:left;padding-left:15px">尺寸</th> -->
                                <th style="width:60px;text-align:right;padding-left:15px">数量</th>
                                <th style="width:60px;text-align:left;padding-left:15px">单位</th>
                                <th style="width:100px;text-align:left;padding-left:15px">成本单价(元)</th>
                                <th style="width:60px;text-align:right;padding-left:15px">小计</th>
                                <th style="width:150px;text-align:center;padding-left:15px">备注</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in customerDetails.item" class="main_tr" :key="'rol1-' + index">
                                <!-- 序号 -->
                                <td style="text-align:center;padding-left:15px">{{ index + 1 }}</td>
                                <!-- 材料编号 -->
                                <td style="text-align:left;padding-left:15px">
                                    <van-popover v-model="item.showPopover" theme="dark" trigger="click" placement="top"
                                        :offset="[-8, 8]">
                                        <div
                                            style="padding: 4px 8px;font-size: 14px;font-family: PingFang SC-Bold, PingFang SC;">
                                            {{ item.itemNo }}
                                        </div>
                                        <template #reference>{{ item.itemNo }}</template>
                                    </van-popover>
                                </td>
                                <!-- 品牌 -->
                                <td style="text-align:left;padding-left:15px">{{ item.brandName }}</td>
                                <!-- 套餐类型 -->
                                <td style="text-align:right;padding-left:15px;font-weight:mediu">{{ item.allianceName }}
                                </td>
                                <!-- 样式图 -->
                                <td style="text-align:center;padding-left:15px;font-weight:mediu">
                                    <div @click="onPreview(item.imgUrl)"
                                        style="display: flex;align-items: center;justify-content: center;">
                                        <img :src="item.imgUrl" :width="56" :height="22" alt="">
                                    </div>
                                </td>
                                <!-- 商品名称 -->
                                <td style="text-align:left;padding-left:15px">{{ item.itemName }}</td>
                                <!-- 规格 -->
                                <td style="text-align:left;padding-left:15px">{{ item.modelSpec }}</td>
                                <!-- 尺寸 -->
                                <!-- <td style="text-align:left;padding-left:15px">{{ item.itemSize }}</td> -->
                                <!-- 数量 -->
                                <td style="text-align:right;padding-left:15px">{{ item.productQty }}</td>
                                <!-- 单位 -->
                                <td style="text-align:left;padding-left:15px">{{ item.unitName }}</td>
                                <!-- 成本单价(元) -->
                                <td style="text-align:left;padding-left:15px">{{ item.lastPrice }}</td>
                                <!-- 小计 -->
                                <td style="text-align:right;padding-left:15px">{{ item.lastSubtotal }}</td>
                                <!-- 备注 -->
                                <td style="text-align:center;padding-left:15px">{{ item.remark }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="bottom_info">
                <div class="btm_flex">
                    <div class="flex_a">单据备注:</div>
                    <div class="flex_b">{{ customerDetails.remark }}</div>
                </div>
                <div class="btm_flex btm_flex_img">
                    <div class="flex_a flex_text">附件信息:</div>
                    <div class="flex_b flex_img">
                        <div v-for="item in imgCopy5" class="img">
                            <van-image @click="onPreview(item.fileAdd)" :src="item.fileAdd"
                                style="width: 100%;height: 100%;" />
                        </div>
                        <div class="img img1" v-if="imgMore" @click="onImgMore">查看更多</div>
                    </div>
                </div>
                <div class="btm_flex">
                    <div class="flex_a">总计金额:</div>
                    <div class="flex_c">￥{{ total }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ImagePreview } from 'vant'
import api from '@/api/api.js'
import api2 from '@/api/dataBoard.js'

export default {
    components: {
    },
    data() {
        return {
            id: '',
            logId: '',
            messageId: '',
            orderNoticeType: '',
            isSub: false,
            dataTable: [],
            // 详情传值
            customerDetails: {},
            // 显示提交或者下单分包
            isShowPlace: '',
            // 销售单id
            customerId: '',
            // 消息内容
            msgConent: {},
            // 合计
            total: 0,
            // 增减量
            quantity: 0,
            // 图片控制
            imgMore: false,
            imgCopy5: [],
        }
    },
    props: {

    },
    methods: {
        back() {
            this.$router.go(-1)
        },
        // 切换收起展开
        handleChangeSub() {
            this.isSub = !this.isSub
        },
        // 查询详情
        async handleDetails() {
            const data = await api.orderDetailListPro(this.id)
            this.customerDetails = data.data
            if (this.customerDetails?.itemf?.length > 5) {
                this.imgMore = true
                this.imgCopy5 = this.customerDetails?.itemf.slice(0, 5)
            } else {
                this.imgCopy5 = this.customerDetails?.itemf || []
            }
            let totalC = 0, quantityC = 0, item = [];
            if (data.data.typeCodeName === "入库单") {
                item = (data.data.item ?? []).map(item1 => ({ ...item1, lastPrice: item1.primeCost, lastSubtotal: item1.primeSubtotal }))
            } else if (data.data.typeCodeName !== "入库单" && data.data.priceStyle == 0) {
                item = (data.data.item ?? []).map(item1 => ({ ...item1, lastPrice: item1.primeCost, lastSubtotal: item1.primeSubtotal }))
            } else {
                item = (data.data.item ?? []).map(item1 => ({ ...item1, lastPrice: item1.sellingPrice, lastSubtotal: item1.subtotal }))
            }
            item.forEach(item2 => {
                totalC += Math.round(item2.lastSubtotal * 100 || 0) / 100
                quantityC += (item2.productQty || 0)
            })
            this.customerDetails = { ...data.data, item }
            this.total = Math.round(totalC * 100) / 100
            this.quantity = Math.round(quantityC * 1000) / 1000
            let res = await api2.messageApi(this.logId)
            this.msgConent = res.data
            await api.messageRead({ logId: this.logId, messageId: this.messageId, sendChannel: '0' })
        },
        onPreview(url) {
            ImagePreview({
                images: [url],
                closeable: true,
                showIndex: false
            });
        },
        onImgMore() {
            this.imgMore = false
            this.imgCopy5 = this.customerDetails?.itemf || []
        }
    },
    mounted() {

    },
    created() {
        // this.id = '9865a1e8-ab8f-467f-b2e0-ea976cd8e3a8'
        // this.logId = '01a21f52b11ad2516bc044f24019c49a'
        this.id = this.$route.params.id
        this.logId = this.$route.params.logId
        this.messageId = this.$route.params.messageId
        this.handleDetails()
    }
}
</script>

<style lang="scss" scoped>
.main_box {
    background: #F5F5F5;

    .color_blue {
        color: #3975C6;
    }

    .color_red {
        color: #EB5757;
    }

    .info_main {
        text-align: left;
        background: #3975C6;
        color: #fff;
        padding: 12px;
        font-family: PingFang SC-Heavy, PingFang SC;

        .info_nav {
            display: flex;
            align-items: center;

            .info_leftS {
                flex: 1;

                .info_leftS_tit1 {
                    font-size: 18px;
                    line-height: 21px;
                    font-weight: bold;
                    margin-left: -10px;
                }

                .info_leftS_tit2 {
                    margin-top: 10px;
                    height: 22px;
                    display: flex;
                    align-items: center;

                    .info_leftS_tit2L {
                        width: 41px;
                        height: 17px;
                        line-height: 17px;
                        font-size: 11px;
                        color: #3975C6;
                        background: #FFFFFF;
                        border-radius: 1px;
                        text-align: center;
                    }

                    .info_leftS_tit2R {
                        margin-left: 8px;
                        height: 21px;
                        font-size: 15px;
                    }
                }
            }

            .info_right {
                margin-left: 10px;
                width: 48px;
                height: 48px;
            }
        }

        .info_content {
            margin-top: 16px;

            .cont_main {
                border-radius: 4px;
                background: #2569C3;

                .cont_flex {
                    display: flex;
                    justify-content: space-between;
                    color: #FFFFFF;
                    padding: 8px;
                    font-family: PingFang SC-Regular, PingFang SC;

                    .flex_a {
                        width: 80px;
                        font-size: 14px;
                    }

                    .flex_b {
                        max-width: calc(100% - 80px);
                        font-size: 16px;
                    }
                }

                .cont_flex_img {
                    display: flex;
                    justify-content: space-between;

                    .flex_text {
                        white-space: nowrap;
                    }

                    .flex_img {
                        display: flex;
                        flex-wrap: wrap;
                        width: 232px;

                        .img {
                            // display: inline-block;
                            margin: 0px 6px 6px 6px;
                        }
                    }
                }
            }

            .content_bottom {
                width: 100%;
                color: #ECF4FF;
                font-size: 12px;
                margin: 12px 0px 0px 0px;

                .flex {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-family: PingFang SC-Regular, PingFang SC;
                }

                .jiantou {
                    transform: rotate(180deg);
                    transition: all 0.3s;
                }
            }
        }

    }

    .height_style {
        height: calc(100vh - 150px);
        overflow: auto;
    }

    .height_style1 {
        min-height: calc(100vh - 51px);
        overflow: auto;
    }

    .center_info {
        margin-top: 16px;
        text-align: left;
        font-family: PingFang SC-Bold, PingFang SC;

        .center_count {
            background: #fff;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 8px 12px;

            &:last-child {
                border-top: 1px solid #eee;
            }

            .left {
                display: flex;
                align-items: flex-end;
                color: #333333;

                .left_a {
                    font-size: 18px;
                    font-weight: bold;
                    margin-right: 4px;
                }

                .left_b {
                    color: #868686;
                    font-size: 12px;
                }

                .left_c {
                    margin-left: 10px;
                    width: 190px;
                    height: 18px;
                    line-height: 18px;
                    font-size: 13px;
                }
            }

            .right {
                display: flex;
                align-items: flex-end;
                font-size: 20px;

                .right_a {
                    color: #333333;
                    margin-right: 16px;
                }

                .right_b {
                    color: #3975C6;
                    font-weight: bold;
                }
            }
        }
    }


    .bottom_table {
        width: 100%;
        overflow-x: auto;

        .table_mian {
            width: 751px;
        }

        .sticky-col {
            position: sticky;
            left: 0;
            z-index: 1;
            background-color: #f5f5f5;

        }

        .fixed_column {
            position: sticky;
            left: 0;
            z-index: 1;
            /* 将固定列设置为最上层 */
            background: #EEEEEE;
        }

        .fixed_column_td {
            position: sticky;
            left: 0;
            z-index: 1;
            background: #fff;
            /* 将固定列设置为最上层 */
        }

        table {
            border-collapse: collapse; //将边框合并
            // display: block;
            width: 100%;
            white-space: nowrap;
            table-layout: fixed;
            background: #FFFFFF;
        }

        th {
            padding: 0;
            font-family: PingFang SC-Medium, PingFang SC;
            font-size: 13px;
        }

        td {
            font-family: PingFang SC-Regular, PingFang SC;
        }

        td,
        th {
            /* 设置 td 元素的文本超出部分以省略号形式展示 */
            text-overflow: ellipsis;
            /* 设置 td 元素的文本不换行 */
            white-space: nowrap;
            overflow: hidden;
        }

        .van-popover__wrapper {
            width: 100%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            transform: translateY(2px);
        }

        .fixed_column,
        .fixed_column+td {
            width: 100px;
            /* 设置第一列单元格宽度 */
            text-align: center;
        }

        .fixed_column.dragging {
            box-shadow: 2px 0 6px rgba(0, 0, 0, 0.2);
        }

        .tr_th {
            background: #EEEEEE;
            color: #1D2129;
            width: 751px;
            height: 49px;
        }

        .main_tr {
            border-bottom: 1px solid #E5E6EB;
            height: 49px;
            color: #1D2129;
            font-size: 14px;
        }
    }

    .bottom_info {
        text-align: left;
        padding: 16px;
        margin-top: 8px;
        background-color: #fff;
        line-height: 24px;

        .btm_flex {
            display: flex;
            justify-content: space-between;
            font-family: PingFang SC-Regular, PingFang SC;
            color: #1D2129;
            font-size: 14px;
            margin-bottom: 10px;

            .flex_a {
                width: 70px;
            }

            .flex_b {
                flex: 1;
            }

            .flex_c {
                flex: 1;
                font-weight: 600;
            }
        }

        .btm_flex_img {
            margin-bottom: 10px;
            display: flex;
            justify-content: space-between;

            .flex_text {
                white-space: nowrap;
            }

            .flex_img {
                display: flex;
                flex-wrap: wrap;

                .img {
                    width: 80px;
                    height: 80px;
                    margin: 0 10px 10px 0;
                    border-radius: 4px;
                    overflow: hidden;
                }

                .img1 {
                    box-sizing: border-box;
                    border: 1px solid #eee;
                    line-height: 80px;
                    text-align: center;
                    font-size: 12px;
                }
            }
        }
    }
}
</style>