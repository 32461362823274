<template>
  <div class="content market-content">
    <headerNav leftNav=" " :title="navTitle" @leftClick="back"></headerNav>
    <main class="content-main">
      <!-- 汇总 -->
      <BoardNavSummarize 
      :imgBackUrlName="typeList.bgIcon" 
      :ratio="indicatorsData.finishRate" 
      :completionsCount="indicatorsData.finishValue"
      :sumTotal="indicatorsData.targetValue"
      :leftIconName="typeList.leftIcon"
      :leftText="typeList.leftText"
      :rightIconName="typeList.rightIcon"
      :rightText="typeList.rightText"
      :unit="typeList.unit"
      :targetName="typeList.operate"
      :customerName="typeList.type =='invite_job'?subRouterData.indexName:indicatorsData.userName"
      :isAbnormity="indicatorsData.isException == '1'&&typeList.type != 'invite_job'?true:false"
      :showFoot="typeList.type == 'pay_submit'?false:true"
      />
      <div class="task-title">
        <div class="task-title-icon"></div>
        <div>任务成果上传记录</div>
      </div>

      <!-- list -->
      <div class="task-content">
        <div class="task-content-list" v-for="(item,index) in list" :key="index">
          <div class="task-content-list-title">
            <div class="task-content-list-title-left">
              <img :src="require(`@/assets/task-management/${typeList.detailIcon}.png`)">
              <div class="date">{{ item.createDate.split(' ')[0].replaceAll('/','.') }}</div>
              <div class="time">{{ item.createDate.split(' ')[1] }}</div>
            </div>
            <div class="task-content-list-title-right">
              <div style="background: #FF8C21;" v-if="item.finalStatus == 0">待审批</div>
              <div style="background: #17CA5E;" v-else-if="item.finalStatus == 1">已通过</div>
              <div style="background: #FF6157;" v-else-if="item.finalStatus == 2">已驳回</div>
              <div style="background: #999999;" v-else-if="item.finalStatus == 3">已失效</div>
            </div>
          </div>
          <div>
            <div v-if="typeList.showType == '1'" class="task-content-list-content1">
              <span v-if="typeList.type=='invite_job'">试岗人员:</span>
              <span v-else>{{ typeList.leftText }}:</span>
              <span class="task-content-list-content-num">{{ item.finishValue }}</span>{{ typeList.unit}}
            </div>
            <div v-if="typeList.showType == '2'" class="task-content-list-content2">
              <div>
                <div><span>{{ item.targetValue }}</span>{{ typeList.unit }}</div>
                <div>{{ typeList.rightText }}</div>
              </div>
              <div>
                <div><span>{{ item.finishValue }}</span>{{ typeList.unit }}</div>
                <div>{{ typeList.leftText }}</div>
              </div>
              <p></p>
              <p></p>
            </div>
          </div>
          <div class="task-content-list-img">
            <div>附件{{ item.fileInfoList.length <= 0 ? "":"("+item.fileInfoList.length+")" }}</div>
            <div v-if="item.fileInfoList.length > 0">
              <img :src="img.fileUrl" :alt="img.fileName" v-for="(img,num) in item.fileInfoList" @click ="clickImg(item.fileInfoList,num)">
            </div>
          </div>
          <div class="task-content-list-detail" @click="goExamineDetail(item)">查看详情<van-icon style="float: right;" name="arrow" /></div>
        </div>

        <div class="empty-no-data" v-if="list.length<=0">
          <img src="../../../../assets/task-management/no-data.png" alt="">
          <div>暂无数据</div>
        </div>
      </div>

    </main>
    <!-- 底部按钮 -->
    <footer>
      <div @click="uploadTask">
        任务成果上传
      </div>
    </footer>
  </div>
</template>

<script>
import BoardNavSummarize from "@/views/task-management/component/public/module/BoardNavSummarize.vue"
import { employeeSingleModelFn } from "./data.js"
import api from "@/api/task-management/human-resources.js";
import { Toast,ImagePreview } from "vant";
import moment from "moment";

export default {
  components: {
    BoardNavSummarize,
    ImagePreview,
  },
  data () {
    return {
      // 任务看板路由接参
      routerData: {},
      // 上一级路由接参
      subRouterData: {},
      // 指标
      indicatorsData:{},
      // 结果列表数据
      list:[],
      // 展开行id
      defaultExpandList: [],
      // 模块类型数据 
      typeList:{},
    }
  },
  created () {
    let localData = JSON.parse(localStorage.getItem("TABLE_HEADER_CONTENT"));
    this.routerData = localData;
    this.typeList = employeeSingleModelFn(localData.termSlug)
    this.navTitle = this.typeList.name + '详情'
    if(this.$route.query.item){
      this.subRouterData = JSON.parse(this.$route.query.item)
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    back () {
      this.$router.go(-1)
    },
    // 查询表格数据
    async getData () {
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      let params = {
        "type": "1", //1=看板；2=任务中心
        "ext": this.typeList.type,
        "Q_reportData_EQ": moment().format("YYYY-MM"),
        "taskItemId":this.subRouterData?.indexCode, // 招聘试岗指标时 传参
        "taxonomy":"4" //4=人事，7=产品
      }
      api.getApproveList(params).then((res)=>{
        if (res.code == 200) {
          Toast.clear()
          this.indicatorsData = res.data.statisticsObject
          this.list = res.data.approveList
        }else{
          Toast.fail(res.msg)
        }
      })
    },
    clickImg(data,num){
      let img = []
      data.forEach((item,index) => {
        img.push(item.fileUrl)
      });
      ImagePreview({
        images: img,
        startPosition: num,
        closeable: true,
        loop:false,
        getContainer:()=>{return document.querySelector('.task-content');}
      })
    },
    // 点击查看详情
    goExamineDetail (value) {
      this.$router.push({
        name:'TaskBoardHumanResourcesExamineDetail',
        query: {
          item:JSON.stringify(value),
        }
      })
    },
    // 任务成果上传
    uploadTask(){
      this.$router.push({
        name:'TaskBoardHumanResourcesUploadTask',
        query:{
          type:this.typeList.type,
          previousItem:JSON.stringify(this.subRouterData),
          currentItem:JSON.stringify(this.indicatorsData)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped src="../../component/public/css/table.scss"></style>
<style lang="scss" scoped>
  .content-main{
    height: calc(100vh - 140px) !important;
  }
  .table-prompt-icon{
    width: 14px;
    height: 14px;
    vertical-align: middle;
  }
  .task-title{
    font-size: 18px;
    font-family: PingFang SC, PingFang SC;
    font-weight: bold;
    color: #333333;
    text-align: left;
    padding-top: 16px;
    display: flex;
    align-items: center;

    .task-title-icon{
      width: 4px;
      height: 15px;
      background: #0E8BFF;
      margin-left: 10px;
      margin-right: 8px;
    }
  }

  .task-content{
    width: 100%;
    height: calc(100vh - 180px);
    overflow: auto;
    margin-top: 8px;
    .task-content-list{
      width: 100%;
      background: #FFFFFF;
      border-radius: 8px 8px;
      padding: 16px 12px 0;
      box-sizing: border-box;
      text-align: left;
      margin-bottom: 10px;
      .task-content-list-title{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;

        .task-content-list-title-left{
          display: flex;
          align-items: center;
          font-size: 23px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          color: #1D2129;
          img{
            width: 24px;
            height: 24px;
            margin-right: 4px;
          }
          .time{
            height: 22px;
            line-height: 22px;
            font-size: 15px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            color: #8C8C8C;
            background: #F9F9F9;
            border-radius: 3px 3px 3px 3px;
            padding: 0 4px;
            margin-left: 8px;
          }
        }
        .task-content-list-title-right{
          div{
            font-size: 15px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            height: 24px;
            line-height: 24px;
            border-radius: 4px 4px;
            padding: 0 8px;
          }
        }
      }
      .task-content-list-content1{
        height: 56px;
        background: #F9F9F9;
        box-shadow: 0px 5px 7px 0px rgba(254,242,222,0.2);
        border-radius: 8px 8px 8px 8px;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #545454;
        padding-left: 16px;
        display: flex;
        align-items: center;
        .task-content-list-content-num{
          font-size: 22px;
          font-family: Bahnschrift, Bahnschrift;
          font-weight: normal;
          color: #1D2129;
        }
      }
      .task-content-list-content2{
        height: 88px;
        background: #F9F9F9;
        box-shadow: 0px 5px 7px 0px rgba(254,242,222,0.2);
        border-radius: 8px 8px 8px 8px;
        display: flex;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #545454;
        position: relative;
        span{
          font-size: 22px;
          font-family: Bahnschrift, Bahnschrift;
          font-weight: normal;
          color: #1D2129;
        }
        div:nth-child(1){
          width: 40%;
          padding: 16px 0 0 10px;
          border-right:1px dashed #E2E2E2;
          div{
            padding: 0;
            border:0;
            margin-bottom: 4px;
          }
        }
        div:nth-child(2){
          width: 60%;
          padding: 16px 0 0 10px;
          div{
            padding: 0;
            border:0;
            margin-bottom: 4px;
          }
        }
        p{
          margin: 0;
          width: 15px;
          height: 15px;
          background: #FFFFFF;
          border-radius: 50%;
          position: absolute;
          top: -10%;
          left: 38.5%;
        }
        p:nth-of-type(1){
          top: unset;
          bottom: -10% !important;

        }

      }
      .task-content-list-img{
        min-height: 40px;
        display: flex;
        margin-top: 20px;
        font-size: 16px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #545454;
        div:nth-child(1){
          width: 20%;
        }
        div:nth-child(2){
          width: 80%;
          img{
            width: 110px;
            height: 110px;
            border-radius: 4px 4px 4px 4px;
            opacity: 1;
            border: 1px solid rgba(217,217,217,0.4);
            margin: 0 10px 10px 0;
          }
        }
      }
      .task-content-list-detail{
        height: 22px;
        font-size: 16px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #545454;
        padding: 12px 0;
        border-top: 1px solid #EBEDF0;
      }
    }

    .empty-no-data {
      margin-top: 100px ;
      div {
        color: #5E5E5E;
        font-size: 14px;
        margin-top: 0px ;
      }
    }
  }

  footer{
    div{
      width: 95%;
      height: 44px;
      background: #3975C6;
      border-radius: 4px 4px 4px 4px;
      margin: 12px auto 30px;
      font-size: 18px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 44px;
      
    }
  }
  ::v-deep {
    .van-image-preview__index{
      top: 95% !important;
    }
  }

</style>