<template>
  <div class="main">
    <van-sticky class="topMenu">
      <headerNav leftNav=" " :title="'任务管理中心'" @leftClick="back" @rightClick="onClickExamine" :rightNav="'审批'"
        :rightIcon="'task-setup-finance-check'" :hintData="approvalCount" :showRightContent="examineBtn">
      </headerNav>
    </van-sticky>

    <main>
      <van-tabs v-model="tabActive" @click="onClickTab" color="#3DA2FF" title-active-color="#3DA2FF" title-inactive-color="#333333">
        <van-tab title="本月任务" name="1">
          <div class="content content-height" ref="scrollArea1" @scroll="handleScroll">
            <!-- 每一项任务 -->
            <div class="content-element" v-for="(item, index) in list" :key="index" @click="onClickTaskDetails(item)">
              <div class="content-element-title">
                <img :src="require(`@/assets/task-management/product-board-${item.termId.replaceAll('_','-')}-list-icon.png`)" alt="">
                <div>{{item.termName}} <span>{{ item.taskDate }}</span></div>
              </div>
              <div class="content-element-data">
                <div>
                  <span class="content-element-data-count">{{ (item.finishRate).toFixed(2) }}%</span>
                  <span class="content-element-data-text">{{ item.rateName}}</span>
                </div>
                <div>
                  <span class="content-element-data-count">{{ item.standardRate }}</span>
                  <span class="content-element-data-text">{{ item.standardRateName}}</span>
                </div>
              </div>
            </div>
            <!-- 暂无任务 -->
            <div class="no-data" v-if="isData && list.length <= 0">
              <img src="@/assets/task-management/task-setup-nodata.png" alt="">
              <div>暂无任务</div>
            </div>
          </div>
        </van-tab>
        <van-tab title="历史任务" name="3">
          <div class="content content-height" ref="scrollArea3" @scroll="handleScroll">
            <!-- 每一项任务 -->
            <div class="content-element" v-for="(item, index) in list" :key="index" @click="onClickTaskDetails(item)">
              <div class="content-element-title">
                <img :src="require(`@/assets/task-management/product-board-${item.termId.replaceAll('_','-')}-list-icon.png`)" alt="">
                <div>{{item.termName}} <span>{{ item.taskDate }}</span></div>
              </div>
              <div class="content-element-data">
                <div>
                  <span class="content-element-data-count">{{ (item.finishRate).toFixed(2) }}%</span>
                  <span class="content-element-data-text">{{ item.rateName}}</span>
                </div>
                <div>
                  <span class="content-element-data-count">{{ item.standardRate }}</span>
                  <span class="content-element-data-text">{{ item.standardRateName}}</span>
                </div>
              </div>
            </div>
            <!-- 暂无任务 -->
            <div class="no-data" v-if="isData && list.length <= 0">
              <img src="@/assets/task-management/task-setup-nodata.png" alt="">
              <div>暂无任务</div>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </main>
  </div>
</template>

<script>
import api from "@/api/task-management/human-resources.js";
import { Toast,ImagePreview } from "vant";
import moment from "moment";

export default {
  data () {
    return {
      examineBtn:true,
      tabActive: 1,
      list:[],
      approvalCount:0,
      // 当前登录人角色
      userRole: {},
      page: 1, // 当前页码
      limit: 30, //条数
      isLoading: false, // 是否正在加载数据
      isData:false,
      dataLength:0,
    }
  },
  mounted() {
    localStorage.removeItem("SUB_TASK_OBJ")
    localStorage.removeItem("SUB_TASK_LIST")
    this.userRole = JSON.parse(localStorage.getItem("USER_ROLE"));
    if(this.userRole.roleCode != "ROLE_560934"){
      this.examineBtn = false
    }
    let tabActiveObj = JSON.parse(localStorage.getItem('TAB_ACTIVE_OBJ'))||""
    if( tabActiveObj ){
      this.tabActive = tabActiveObj.tabActive
    }else{
      this.tabActive = 1
    }
    this.getData()
    this.getApprovalCount()
  },
  methods: {
    back () {
      this.$router.go(-1)
    },
    // 查询表格数据
    async getData () {
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      let params = {
        "monthType": this.tabActive,
        "page":this.page,
      }
      api.getTaskList(params).then((res)=>{
        if (res.code == 200) {
          Toast.clear()
          this.dataLength = res.data.length
          this.list.push(...res.data)
        }else{
          Toast.fail(res.msg)
        }
      }).finally(()=>{
        this.isData = this.list.length > 0?false:true
      })
    },
    // 审批数量查询
    getApprovalCount(){
      let params = {
        "taxonomy": "7",//4=人事；5=财务；6=预审；7=产品
      }
      api.getPendingApprovalCount(params).then((res)=>{
        if (res.code == 200) {
          this.approvalCount = res.data
        }else{
          Toast.fail(res.msg)
        }
      })
    },
    // 切换Tab
    onClickTab(name, title){
      localStorage.setItem("TAB_ACTIVE_OBJ",JSON.stringify({tabActive:name}))
      this.page = 1
      this.list = []
      this.$nextTick(() => {
        this.$refs['scrollArea'+name].scrollTop = 0 // 将滚动位置设置为 0
      })
      this.getData()
    },
    // 下拉 加载更多
    async handleScroll(e) {
      const el = e.target
      if ((el.scrollTop + el.clientHeight)+1 >= el.scrollHeight && !this.isLoading && this.dataLength >= this.limit) {
        this.page++; // 加载下一页数据
        this.loading = true
        await this.getData();
        this.loading = false
      }
    },
    // 点击详情
    onClickTaskDetails (item) {
      this.$router.push({ 
        name: 'TaskSetupProductTaskDetails',
        query: {
          active: this.userRole.roleCode == 'ROLE_560934' ? this.tabActive:'3',
          item:JSON.stringify(item),
        }
      })
    },
    // 点击审批
    onClickExamine () {
      this.$router.push({ 
        name: 'TaskSetupProductExamineList',
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.main::v-deep {
  height: 100vh;
  overflow: hidden;

  .van-tab {
    font-size: 17px;
    flex: none;
    width: 25%;
  }

  .van-tab--active {
    font-size: 18px;
  }

  .no-data {
    height: 100%;
    img {
      margin: 50% auto 0;
      width: 210px;
      height: 137px;
    }

    div {
      font-size: 16px;
      font-family: Bahnschrift, Bahnschrift;
      font-weight: 400;
      color: rgba(51, 51, 51, 0.5);
    }
  }

  .content {
    // height: calc(100vh - 190px);
    background: #F5F5F5;
    overflow: auto;

    .content-element {
      background: #ffffff;
      padding: 12px 18px 16px;
      margin-top: 16px;
      width: 100%;
      border-radius: 8px 8px;
      box-sizing: border-box;
      position: relative;

      .content-element-title{
        display: flex;
        align-items: center;
        font-size: 18px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #1D2129;
        position: relative;
        img{
          width: 24px;
          height: 24px;
        }
        span{
          background: #F9F9F9;
          border-radius: 3px 3px 3px 3px;
          color: #8C8C8C;
          display: inline-block;
          padding: 0 4px;
          font-weight: 400;
        }
        ::after{
          content: "";
          width: 16px;
          height: 16px;
          background:url("../../../../assets/task-management/task-setup-finance-arrows.png") no-repeat center;
          background-size: 100% 100%;
          position: absolute;
          right: 0;
          top: 50%;
          margin-top: -8px;
        }
      }
    }
    .content-element-data{
      height: 88px;
      background: #F9F9F9;
      box-shadow: 0px 5px 7px 0px rgba(254,242,222,0.2);
      border-radius: 8px 8px 8px 8px;
      margin-top: 10px;
      display: flex;
      position: relative;
      text-align: left;
      .content-element-data-count{
        font-size: 22px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #1D2129;
        width: 100%;
        box-sizing: border-box;
      }
      .content-element-data-text{
        font-size: 14px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #545454;
        padding-left: 12px;
        box-sizing: border-box;
      }

      div:nth-child(1){
        width: 35%;
        border-right: 1px dashed #E2E2E2;
        box-sizing: border-box;
        span{
          display: block;
          width: 100%;
        }
        .content-element-data-count{
          font-family: Bahnschrift, Bahnschrift;
          font-weight: normal;
          font-size: 27px;
          color: #1281E7;
          padding: 16px 0 4px 12px;
        }
      }
      
      div:nth-child(2){
        width: 60%;
        box-sizing: border-box;
        span{
          display: block;
          width: 100%;
        }
        .content-element-data-count{
          padding: 18px 0 6px 12px;
        }
      }

      &::after{
        content: "";
        width: 15px;
        height: 15px;
        background: #FFFFFF;
        border-radius: 50%;
        position: absolute;
        top: -10%;
        left: 32.5%;
      }
      &::before{
        content: "";
        width: 15px;
        height: 15px;
        background: #FFFFFF;
        border-radius: 50%;
        position: absolute;
        left: 32.5%;
        bottom: -10%;
      }
    }

  }
  
  .content-height{
    height: calc(100vh - 94px);
  }

  footer{
    div{
      width: 95%;
      height: 44px;
      background: #3975C6;
      border-radius: 4px 4px 4px 4px;
      margin: 12px auto 30px;
      font-size: 18px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 44px;
    }
  }

}
</style>