<template>
  <div class="main">
    <headerNav leftNav=" " @leftClick="back"  :title="'子任务详情'"
      @rightClick="onClickDelete" :rightNav="'删除'" :rightIcon="'delete-icon'" :showRightContent="upActiveTab == '3'?false:true">
    </headerNav>
    <main>
      <div class="content" v-if="upActiveTab != '3'">
        <van-cell-group class="cell-group-top cell-group-height">
          <template v-for="(item,index) in tableModel">
            <van-cell :class="item.class" v-if="!item.requir&&!Array.isArray(item.value)" :title="item.label" title-class="cell-group-title" value-class="cell-group-right">
              <template #right-icon>
                <span v-if="item.unit" class="unit">{{ item.unit }}</span>
              </template>
              <template #default>
                <div>{{ (item.suffix?(item.value+item.suffix):item.value) }}</div>
              </template>
            </van-cell>
            <van-cell :class="item.class" v-if="Array.isArray(item.value)" :title="item.label" title-class="cell-group-title" value-class="cell-group-right">
              <template #default>
                <div class="file-content">
                  <img class="img" v-for="(item1,index1) in item.value" :key="index1" :src="item1.fileUrl" @click="imagePreview(item1.fileUrl)">
                </div>
              </template>
            </van-cell>
            <van-field :class="item.class" v-if="item.requir" :type="item.type" :maxlength="item.maxlength" v-model="subTaskInfo[item.prop]" @click="cellFn(item.click)" @input="item.inputFn?inputFn($event,item):''" :label="item.label" placeholder="请输入" @focus="item.click?forbid():''">
              <i slot="left-icon" style="color: red">*</i>
              <template #button v-if="!item.unit"><van-icon name="arrow" /></template>
              <template #button v-else><span style="color:#000">{{ item.unit }}</span></template>
            </van-field>
          </template>
        </van-cell-group>

        <div class="footer" >
          <div class="footer-btn" @click="updateTask" :class="isModify?'':'untouched'">确认</div>
        </div>
      </div>
      <div class="content" v-if="upActiveTab == '3'">
        <van-cell-group class="cell-group-top cell-group-height">
          <template v-for="(item,index) in tableModel">
            <van-cell :class="item.class" v-if="!Array.isArray(item.value)" :title="item.label" title-class="cell-group-title" value-class="cell-group-right">
              <template #right-icon>
                <span v-if="item.unit" class="unit">{{ item.unit }}</span>
              </template>
              <template #default>
                <div v-if="item.finishStatus" class="finishStatus" :class="subTaskInfo['finishStatus']?'completed':'incomplete'">{{ subTaskInfo["finishStatus"] ? "已完成":"未完成"}}</div>
                <div>{{ (item.suffix?(item.value+item.suffix):item.value) }}</div>
              </template>
            </van-cell>
            <van-cell :class="item.class" v-if="Array.isArray(item.value)" :title="item.label" title-class="cell-group-title" value-class="cell-group-right">
              <template #default>
                <div class="file-content">
                  <img class="img" v-for="(item1,index1) in item.value" :key="index1" :src="item1.fileUrl" @click="imagePreview(item1.fileUrl)">
                </div>
              </template>
            </van-cell>
          </template>
        </van-cell-group>
      </div>
    </main>

    <!-- 执行者弹窗 -->
    <van-popup v-model="showExecutorPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="executorList"
        @confirm="onConfirmTaskType"
        @cancel="showExecutorPicker = false"
      />
    </van-popup>

    <!-- 删除弹窗 -->
    <van-dialog v-model="dialog" :closeOnClickOverlay="true" confirmButtonColor="#3975C6" show-cancel-button
      @cancel="dialogClose" @confirm="dialogConfirm">
      <div class="dialog-main">
        <div class="dialog-main-reject">
          您确认“ <span>删除</span> ” 该任务吗？
        </div>
      </div>
    </van-dialog>

  </div>
</template>

<script>
import api from "@/api/task-management/human-resources.js";
import { Toast,ImagePreview } from "vant";
import moment from "moment";

export default {
  data () {
    return {
      // 上级页面的Tab type
      upActiveTab:'',
      // 模块类型数据 
      tableModel:[
        {label:'招聘部门',prop:'targetDepartmentName',type:'text'},
        {label:'招聘职位',prop:'targetRoleName',type:'text',},
        {label:'完成率',prop:'finishRate',type:'digit',suffix:'%',finishStatus:true},
        {label:'执行者',prop:'userName',type:'text',requir:true,click:'executorPicker'},
        {label:'招聘人数',prop:'targetValue',type:'digit',maxlength:3,requir:true,class:'interval',unit:'人',inputFn:true},
        {label:'试岗人数',prop:'finishValue',type:'digit',maxlength:3,unit:'人'},
        {label:'附件',prop:'fileList',type:'file'},
      ],
      originalInfo:{}, //原始子任务数据
      subTaskInfo:{}, // 子任务数据
      showExecutorPicker: false, //执行者弹窗
      executorList:[], //执行者数据
      dialog: false, // 删除弹窗
      isModify:false, //是否修改
    }
  },
  created() {
    this.upActiveTab = this.$route.query.upActiveTab
  },
  mounted() {
    this.getData()
    this.getExecutorList()
  },
  methods: {
    back () {
      this.$router.go(-1)
    },
    // 获取详情
    getData () {
      if(!this.$route.query.id){
        let list = JSON.parse(localStorage.getItem("SUB_TASK_LIST"))
        this.originalInfo = JSON.parse(JSON.stringify(list[this.$route.query.index]))
        this.subTaskInfo = list[this.$route.query.index]
        this.tableModel.forEach((item,index) => {
          item.value = this.subTaskInfo[item.prop] == ''?'-':this.subTaskInfo[item.prop]
          if(item.prop == "finishRate"){
            item.value = item.value ? parseFloat(item.value).toFixed(2) : '-'
          }else{
            item.value = item.value ? item.value : '-'
          }
          this.subTaskInfo[item.prop] = item.value
        });
        this.$forceUpdate()
      }else{
        Toast.loading({
          message: '加载中...',
          forbidClick: true,
        });
        let params = {
          "id": this.$route.query.id,
        }
        api.getSubTaskDetail(params).then((res)=>{
          if (res.code == 200) {
            Toast.clear()
            this.originalInfo = JSON.parse(JSON.stringify(res.data))
            this.subTaskInfo = res.data
            this.tableModel.forEach((item,index) => {
              item.value = this.subTaskInfo[item.prop]?this.subTaskInfo[item.prop]:''
              if(item.prop == "finishRate"){
                item.value = item.value ? parseFloat(item.value).toFixed(2) : '-'
              }else{ 
                item.value = item.value ? item.value : '-'
              }
              this.subTaskInfo[item.prop] = item.value
            });
            this.$forceUpdate()
          }else{
            Toast.fail(res.msg)
          }
        })
      }
    },
    // 获取执行者数据
    getExecutorList(){
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      let params = {}
      api.getExecutorList(params).then((res)=>{
        if (res.code == 200) {
          Toast.clear()
          let arr = []
          res.data.forEach(item => {
            arr.push({
              text:item.name,
              value:item.code,
            })
          });
          this.executorList = arr
        }else{
          Toast.fail(res.msg)
        }
      })
    },
    // 输入框 点击
    cellFn(item){
      if(item == 'executorPicker'){ //执行者弹窗 打开
        this.showExecutorPicker = true
      }
    },
    // 输入框内容改变
    inputFn(value,item){
      if(item.type == "digit" && Number(value) <= 0){
        Toast.fail('输入的数量不能为空或零')
        this.subTaskInfo[item.prop] = ""
        this.isModify = false
      }
      if(item.prop == "targetValue" && this.originalInfo.targetValue != value){
        this.isModify = true
      }else{
        this.isModify = false
      }
      if(value == ''|| this.subTaskInfo[item.prop] == ""){
        this.isModify = false
      }
    },
    // 执行者弹窗 确认
    onConfirmTaskType(value){
      if(this.originalInfo.userName != value.text && this.originalInfo.userId != value.value){
        this.isModify = true
      }
      this.subTaskInfo.userName = value.text;
      this.subTaskInfo.userId = value.value;
      this.showExecutorPicker = false;
    },
    // 预览图片
    imagePreview(url){
      ImagePreview({
        images: [url],
        closeable: true,
        showIndex: false
      });
    },
    // 禁止弹出软键盘
    forbid(){
      document.activeElement.blur()
    },
    // 打开删除弹窗
    onClickDelete(){
      this.dialog = true
    },
    // 删除弹窗关闭
    dialogClose () {},
    // 删除弹窗确认
    dialogConfirm(){
      if(!this.$route.query.id){
        let list = JSON.parse(localStorage.getItem("SUB_TASK_LIST"))
        list.splice(this.$route.query.index, 1);
        localStorage.setItem("SUB_TASK_LIST",JSON.stringify(list))
        Toast.success('操作成功');
        let _this = this;
        setTimeout(()=>{
          _this.back()
        },1000)
      }else{
        Toast.loading({
          message: '加载中...',
          forbidClick: true,
        });
        let params = {
          "id":this.$route.query.id,
        }
        api.removeSubTask(params).then((res)=>{
          if (res.code == 200) {
            Toast.success('操作成功');
            let _this = this;
            setTimeout(()=>{
              _this.back()
            },1000)
          }else{
            Toast.fail(res.msg)
          }
        })
      }
    },
    // 遍历对象改变'-'为''
    objChange(obj){
      for (let key in obj) {
        if (obj.hasOwnProperty(key) && obj[key] === '-') { // 判断属性值是否为 "-"
          obj[key] = ''; // 将属性值改为 ""
        }
      }
    },
    // 修改子任务
    updateTask(){
      if(!this.isModify){
        return false
      }
      if(!this.$route.query.id){
        let list = JSON.parse(localStorage.getItem("SUB_TASK_LIST"))
        this.objChange(this.subTaskInfo)
        list[this.$route.query.index] = this.subTaskInfo
        localStorage.setItem("SUB_TASK_LIST",JSON.stringify(list))
        Toast.success('操作成功');
        let _this = this;
        setTimeout(()=>{
          _this.back()
        },1000)
      }else{
        Toast.loading({
          message: '加载中...',
          forbidClick: true,
        });
        let params = {
          ...this.subTaskInfo,
        }
        api.updateSubTask(params).then((res)=>{
          if (res.code == 200) {
            Toast.success('操作成功');
            let _this = this;
            setTimeout(()=>{
              _this.back()
            },1000)
          }else{
            Toast.fail(res.msg)
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.main::v-deep {
  background: #F5F5F5;
  height: 100vh;

  .content {
    height: calc(100vh - 51px);
    overflow: auto;

    .cell-group-top {
      border-radius: 0px 0px 8px 8px;
      overflow: auto;
    }

    .cell-group-height{
      max-height: calc(100% - 90px);
    }

    .cell-group-center {
      border-radius: 8px;
      overflow: hidden;
      margin: 8px 0;

      .accessory {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .accessory-img {
          width: 20px;
          height: 24px;
          margin-right: 4px;
        }

        .accessory-text {
          font-size: 14px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          color: #0084F4;
        }
      }
    }

    .cell-group-bottom {
      border-radius: 8px;
      overflow: hidden;

      .mistakes {
        span {
          color: #ED4040;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
        }
      }

      .postpone {
        span {
          color: #FF8C21;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
        }
      }

      .final-score {
        span {
          color: #0084F4;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
        }
      }
    }

    .cell-group-title {
      font-size: 16px;
      font-family: PingFang TC, PingFang TC;
      font-weight: 400;
      color: #999999;
      text-align: left;
    }

    .cell-group-right,.van-field__control {
      font-size: 16px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #000000;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .finishStatus{
        width: 52px;
        line-height: 22px;
        font-size: 12px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        text-align: center;
        margin-right: 16px;
        border-radius: 4px;
      }
      .completed{
        background: #17CA5E;
      }
      .incomplete{
        background: #FF6259;
      }
    }
    .van-field__label {
      font-size: 16px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      color: #1d2129;
    }
    .van-field__value {
      font-size: 16px;
    }

    .footer{
      width: 100%;
      background: #fff;
      padding: 11px 18px 30px;
      box-sizing: border-box;
      box-shadow: 0px -1px 8px 0px rgba(0,0,0,0.04);
      position: absolute;
      bottom: 0;
      .footer-btn{
        line-height: 44px;
        font-size: 18px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        background: #3975C6;
        border-radius: 4px 4px 4px 4px;
      }
      .untouched{
        opacity: .5;
      }
    }
  }

  .van-field__control{
    text-align: right;
  }
  
  .van-field__button{
    color: #000 !important;
  }
  .unit{
    color: #000 !important;
    padding-left: 8px !important;
  }
  .van-cell__value{
    overflow: visible;
  }
  .file-content{
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    width: 255px;

    .img{
      width: 72px;
      height: 72px;
      margin:0 0 11px 11px;
      border-radius: 4px;
      border: 1px solid rgba(217,217,217,0.4);
    }
  }
  .interval{
    border-top: 10px solid #F5F5F5;
  }
  .dialog-main {
    padding: 16px;
    border-bottom: 1px solid #EEEEEE;
    .dialog-main-reject {
      font-size: 16px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      color: #000000;
      margin: 20px 0px;

      span {
        color: #ED4040;
      }
    }
  }
  .van-dialog__cancel {
    font-size: 18px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    color: #333333;
  }
  .van-dialog__confirm {
    font-size: 18px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    color: #3975C6;
  }
}
</style>