<template>
  <div class="main">
    <headerNav leftNav=" " :title="'任务详情'" @leftClick="back"></headerNav>
    <main>
      <div class="content">
        <van-cell-group class="cell-group-top">
          <van-cell title="任务时间" :value="routerData.taskTime" title-class="cell-group-title" value-class="cell-group-right" />
          <van-cell title="市场" :value="routerData.marketName" title-class="cell-group-title" value-class="cell-group-right" />
          <van-cell title="执行者" :value="routerData.userName || '-'" title-class="cell-group-title" value-class="cell-group-right" />
        </van-cell-group>
        <van-cell-group class="cell-group-center">
          <van-cell title="提交时间" :value="routerData.submitTime?routerData.submitTime.slice(0, -3):'-'" title-class="cell-group-title" value-class="cell-group-right" />
          <van-cell v-if="routerData.submitTime" title="附件" title-class="cell-group-title" value-class="cell-group-right">
            <template #default>
              <div class="accessory">
                <img class="accessory-img" src="@/assets/task-management/task-setup-file-xlsx.png" alt="">
                <div class="accessory-text">
                  <div @click="onClickDownFile(routerData.taskFileInfoList)">{{ routerData.taskFileInfoList[0].fileName }}</div>
                </div>
              </div>
            </template>
          </van-cell>
        </van-cell-group>
        <van-cell-group class="cell-group-bottom" v-if="routerData.submitTime">
          <van-cell title="错漏处(1处1分)" title-class="cell-group-title" value-class="cell-group-right">
            <template #default>
              <div class="mistakes">
                <span>{{ routerData.errorCount }}</span>处(扣<span>{{ routerData.errorCount }}</span>分)
              </div>
            </template>
          </van-cell>
          <van-cell title="延期提交(1天2分)" title-class="cell-group-title" value-class="cell-group-right">
            <template #default>
              <div class="postpone">
                <span>{{ routerData.delayCount }}</span>天(扣<span>{{ (routerData.delayCount * 2) || 0 }}</span>分)
              </div>
            </template>
          </van-cell>
          <van-cell title="最终得分(满分100)" value="张三" title-class="cell-group-title" value-class="cell-group-right">
            <template #default>
              <div class="final-score">
                <span>{{ routerData.score }}</span> 分
              </div>
            </template>
          </van-cell>
        </van-cell-group>
      </div>
    </main>
  </div>
</template>

<script>
import { Toast } from "vant";
import { downloadFile } from "@/utils/timeCompute.js";
import api from "@/api/task-management/inquiry.js";

export default {
  data () {
    return {
      routerData:{}
    }
  },
  created(){
    this.routerData = JSON.parse(this.$route.query.row)
  },
  methods: {
    back () {
      this.$router.go(-1)
    },
    downloadFile(fileUrl) {
      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = '';
      link.click();
    },
    // 下载文件
    onClickDownFile(file){
      let pre_prod_host = window.location.host == "m.hejiahejiazhuang.com" ?process.env.VUE_APP_BASE_RUL_PROD : process.env.VUE_APP_BASE_RUL_PRE
      let url = process.env.NODE_ENV === "production" ?pre_prod_host+process.env.VUE_APP_SYS_API: process.env.VUE_APP_SYS_API
      this.downloadFile(url+`/tools/oss/ali/downOSSFile/public?fileName=${file[0].fileUrl.split('/').pop()}&dir=oss`)
    } 
  }
}
</script>

<style lang="scss" scoped>
.main::v-deep {
  background: #F5F5F5;
  height: 100vh;

  .content {
    height: calc(100vh - 60px);
    overflow: auto;

    .cell-group-top {
      border-radius: 0px 0px 8px 8px;
      overflow: hidden;
    }

    .cell-group-center {
      border-radius: 8px;
      overflow: hidden;
      margin: 8px 0;

      .accessory {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .accessory-img {
          width: 20px;
          height: 24px;
          margin-right: 4px;
        }

        .accessory-text {
          font-size: 14px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          max-width: 200px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          div{
          color: #0084F4;
          }
        }
      }
    }

    .cell-group-bottom {
      border-radius: 8px;
      overflow: hidden;

      .mistakes {
        span {
          color: #ED4040;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
        }
      }

      .postpone {
        span {
          color: #FF8C21;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
        }
      }

      .final-score {
        span {
          color: #0084F4;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
        }
      }
    }

    .cell-group-title {
      font-size: 16px;
      font-family: PingFang TC, PingFang TC;
      font-weight: 400;
      color: #999999;
      text-align: left;
    }

    .cell-group-right {
      font-size: 16px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      color: #000000;
    }
  }

}
</style>