<template>
  <div class="content employees-content">
    <van-sticky class="topMenu">
      <headerNav leftNav=" " :title="targetObj.navTitle" @leftClick="back"></headerNav>
    </van-sticky>

    <main class="content-main">
      <!-- 汇总 -->
      <BoardNavSummarize 
      :imgBackUrlName="targetObj.imgUrl" 
      :ratio="taskPlanObj.timelySumRate" 
      :completionsCount="taskPlanObj.timelyCompletionSum" 
      :sumTotal="taskPlanObj.cashierTotalSum"
      :leftIconName="targetObj.leftIconName" 
      :leftText="targetObj.collect1" 
      :rightIconName="targetObj.rightIconName" :rightText="targetObj.collect2" :unit="targetObj.unit"
      :targetName="targetObj.collect3" 
      :navTitleUnit="targetObj.value == 'market_summarize'? ' ':'%'"/>

      <!-- 人员详情 -->
      <div class="content-center-title">
        <div class="left-line"></div>
        <div>人员详情</div>
      </div>

      <!-- table -->
      <el-table :data="userTableData" header-cell-class-name="market-title" row-class-name="market-row" row-key="userId" :expand-row-keys="defaultExpandList" :highlight-current-row="false">
        <!-- 自定义暂无数据 -->
        <template slot="empty">
          <div class="empty-no-data">
            <img src="../../../../assets/task-management/no-data.png" alt="" />
            <div>暂无数据</div>
          </div>
        </template>
        <el-table-column type="index" label="TOP" align="center" width="40">
          <template slot-scope="props">
            <div v-if="props.$index < 3" style="line-height: 1">
              <img class="index-img" :src="getImageIndex(props.$index)" alt="" />
            </div>
            <div v-else>{{ props.$index + 1 }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="userName" label="人员" min-width="60" show-overflow-tooltip>
          <template slot-scope="props">
            <div class="anomaly">
              <span class="market-content-a" @click="onClickPersonnelDetails(props.row)">
                {{ props.row.userName }}
              </span>
              <div class="anomaly-bg" v-if="props.row.isSpecial == '1'">
                异常
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column :prop=" targetObj.value == 'market_summarize' ? 'errorCount': 'timelyCompletion'" :label="targetObj.tableColumn1" align="right" min-width="85">
        </el-table-column>
        <el-table-column :prop="targetObj.value == 'market_summarize'? 'delayCount': 'cashierTotal'" :label="targetObj.tableColumn2" align="right" min-width="85">
        </el-table-column>
        <el-table-column align="right" min-width="85">
          <template slot="header">
            <div class="table-custom">
              <span>{{ targetObj.tableColumn3 }}</span>
              <!-- 综述展示得分规则 -->
              <el-tooltip
                class="tooltip-content"
                effect="dark"
                placement="top-end"
                :offset="-10"
                v-if="targetObj.value == 'market_summarize'"
              >
                <div slot="content">
                  满分100分；以延期提交(1天2分)、 <br />错漏处(1处1分)进行减分
                </div>
                <img class="table-img" src="../../../../assets/task-management/table-hint.png" alt />
              </el-tooltip>
            </div>
          </template>
          <template slot-scope="scope">
            <span class="complete" v-if="targetObj.value == 'market_summarize'">{{ scope.row.scoreInt }}</span>
            <span class="complete" v-else>{{ scope.row.timelyRate + (scope.row.timelyRate != '-'? '%':'') }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="handle" label="操作" min-width="38">
          <template slot-scope="scope">
            <span class="market-details" @click="expandControlRow(scope.row)">详情</span>
          </template>
        </el-table-column>
        <!-- 展开行详情 -->
        <el-table-column type="expand" width="1">
          <template slot-scope="props">
            <div class="market-content-expand">
              <div class="market-content-expand-left">
                <!-- 判断是综述就显示最终得分 -->
                <img v-if="targetObj.value == 'market_summarize'" src="@/assets/task-management/task-board-finance-table-expant-icon2.png" alt="" />
                <img v-else src="@/assets/task-management/task-board-finance-table-expant-icon.png" alt="" />
                <div>
                  {{
                    targetObj.value == "market_summarize"
                      ? "上月最终得分"
                      : "上月及时率"
                  }}：{{
                    targetObj.value == "market_summarize"
                      ? props.row.scoreSum
                      : props.row.lastMonthRate
                  }}{{ targetObj.value == "market_summarize" ? "分" : "%" }}
                </div>
              </div>
              <div class="market-content-expand-right">
                <span class="color-green" v-if="props.row.whetherAccomplish == '0'">已完成</span>
                <span class="color-red" v-if="props.row.whetherAccomplish == '1'">未完成</span>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </main>
  </div>
</template>

<script>
import BoardNavSummarize from "@/views/task-management/component/public/module/BoardNavSummarize.vue";
import { estimateOutput } from "./data.js";
import api from "@/api/task-management/finance.js";
import { Toast } from "vant";

export default {
  components: {
    BoardNavSummarize,
  },
  data() {
    return {
      // 任务看板路由接参
      routerData: {},
      // 表格数据
      userTableData: [],
      // 展开行id-
      defaultExpandList: [],
      // 任务设置弹窗
      setTaskDialogVisible: false,
      taskDialogFrom: {
        value: "",
      },
      // 清空form校验
      fromRestFields: 0,
      // 表头动态展示内容
      outputCount: {},
      // 判读任务设置是否有权限
      setTaskRole: false,
      // 顶部任务产值目标
      taskPlanObj: {},
      // 任务设置当前行数据
      setRowsData: {},
      // 当前指标
      targetObj: {},
      /* 表格详情 */
      tableDetails: {},
    };
  },
  created() {
    let localData = JSON.parse(localStorage.getItem("TABLE_HEADER_CONTENT"));
    let userData = JSON.parse(localStorage.getItem("MARKET_DATA"));
    this.routerData = { ...localData, ...userData };
    this.targetObj = estimateOutput(localData.termSlug);
    this.getTableData();
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    // 查询表格数据
    async getTableData() {
      try {
        Toast.loading({
          message: "加载中...",
          forbidClick: true,
        });
        const { data } = await api.detailListApi({
          ext: this.routerData.termSlug,
          Q_reportData_EQ: this.routerData.Q_reportData_EQ,
          type: "2",
          marketCode: this.routerData.marketCode,
        });
        this.taskPlanObj = data;
        this.userTableData = data.data01;
      } finally {
        Toast.clear();
      }
    },
    // 控制展开行
    expandControlRow(item) {
      const position = this.defaultExpandList.indexOf(item.userId);
      if (position !== -1) {
        this.defaultExpandList.splice(position, 1);
      } else {
        this.defaultExpandList.push(item.userId);
        this.getExpandRowDetails(item);
      }
    },
    /* 查询展开行详情 */
    async getExpandRowDetails(row) {
      const { data } = await api.lastMonthDetail({
        termSlug: this.routerData.termSlug,
        Q_reportData_EQ: this.routerData.Q_reportData_EQ,
        value: row.userId,
      });
      this.userTableData = this.userTableData.map((item) => {
        if (item.userId == row.userId) {
          item = { ...item, ...data };
        }
        return item;
      });
    },
    // 获取图片
    getImageIndex(index) {
      return require(`@/assets/task-management/task-details-table-${
        index + 1
        }.png`);
    },
    // 任务设置
    setTask(row) {
      this.setRowsData = row;
      this.setTaskDialogVisible = true;
    },
    // 点击人员查看人员详情
    onClickPersonnelDetails(row) {
      if (this.targetObj.value == "market_summarize") {
        this.$router.push({
          path: "/task-management/board/finance/market-overview",
          query: { item: JSON.stringify(row) },
        });
      } else {
        this.$router.push({
          path: "/task-management/board/finance/employee-single",
          query: { item: JSON.stringify(row) },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped src="../../component/public/css/table.scss"></style>
<style lang="scss" scoped>
.employees-content::v-deep {
  .content-main {
  }
  .anomaly {
    position: relative;

    .anomaly-bg {
      height: 19px;
      width: 39px;
      text-align: center;
      color: #fff;
      position: absolute;
      font-size: 12px;
      top: -8px;
      right: -25px;
      line-height: 19px;
      background: url("../../../../assets/task-management/employee-list-anomaly.png")
        no-repeat;
      background-position: 100%;
      background-size: cover;
      z-index: 10;
    }
  }
  .content-main .market-row .cell {
    overflow: visible;
  }
  .el-table__cell {
    padding: 3px 0px;
    height: 30px;
    overflow: visible;
    .cell {
      padding: 5px 3px;
      font-weight: 400;
      overflow: visible;
      white-space: nowrap;

      .anomaly {
        .market-content-a {
          white-space: nowrap;
          // /* 不换行 */
          overflow: hidden;
          // /* 隐藏超出部分 */
          text-overflow: ellipsis;
          display: block;
        }
      }
    }
  }
}
</style>
