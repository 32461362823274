//格式化日期 
export function getBeforeDate(time, division) {
	let n = 0;
	let d = new Date(time);
	let year = d.getFullYear();
	let mon = d.getMonth() + 1;
	let day = d.getDate();
	if (day <= n) {
		if (mon > 1) {
			mon = mon - 1;
		} else {
			year = year - 1;
			mon = 12;
		}
	}
	d.setDate(d.getDate() - n);
	let s = year + division + (mon < 10 ? ('0' + mon) : mon) + division + (day < 10 ? ('0' + day) : day);
	return s
}
//判断是昨天还是今天
export function isToday(str) {
	let d = new Date(str).setHours(0, 0, 0, 0);
	let today = new Date().setHours(0, 0, 0, 0);

	let obj = {
		'-86400000': '昨天',
		0: '今天',
		86400000: '明天',
	};
	return obj[d - today] || str;
};
//格式化日期
export function timeFormat(val,division,type) {
	let year = val.getFullYear()
	let month = val.getMonth() + 1
	let day = val.getDate()
	let hour = val.getHours()
	let minute = val.getMinutes()
    let second = val.getSeconds()
	if (month >= 1 && month <= 9) {
		month = `0${month}`
	}
	if (day >= 1 && day <= 9) {
		day = `0${day}`
	}
	if (hour >= 0 && hour <= 9) {
		hour = `0${hour}`
	}
	if (minute >= 0 && minute <= 9) {
		minute = `0${minute}`
	}
    if (second >= 0 && second <= 9) {
        second = `0${second}`
    }
	let time 
	if(type=='yy-mm-dd HH:mm:ss'){
        time = `${year}${division || '-'}${month}${division || '-'}${day} ${hour}:${minute}:${second}`
	}else{
		time = `${year}${division || '-'}${month}${division || '-'}${day}`
	}
	return time
}

// 下载文件
export const downloadFile = (response, fileName) =>{
	// const blob = new Blob([response],{type:'utf8'});
	// // 创建下载链接
	// const downloadLink = URL.createObjectURL(blob);
	// // 创建一个隐藏的<a>标签，设置下载链接和文件名，并模拟点击进行下载
	// const a = document.createElement('a');
	// a.href = downloadLink;
	// a.download = fileName;
	// a.style.display = 'none';
	// document.body.appendChild(a);
	// a.click();
	// document.body.removeChild(a);

	// // 释放下载链接资源
	// URL.revokeObjectURL(downloadLink);

	// 返回格式是文件流格式
// 在请求拿到文件流res以后，使用a标签下载
let blob = new Blob([ response], {
	type: `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;`
})
var a = document.createElement('a');
document.body.appendChild(a);
a.style = 'display: none';
var url = window.URL.createObjectURL(blob);
a.href = url;
a.setAttribute('download', fileName)
a.click();
a.remove();
window.URL.revokeObjectURL(url);

}