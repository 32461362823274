<template>
    <div class="finishUpProject-style">
        <headerNav :title="title" leftNav="返回" @leftClick="cancel"></headerNav>
        <div :class="showUpBtn ? 'content-style' : 'content-style1'">
            <van-field v-if="routerName == '深化'" class="main_top_reamrk" input-align="left" :autosize="true" v-model="checkRemark" rows="2" type="textarea" maxlength="200" placeholder="请输入验收的备注说明" show-word-limit :disabled="checkedDisabled" />
            <van-cell-group class="mian_top">
                <van-cell title="关联客户:" :value="detailInfo.consumerName || ''" size="large" center />
                <van-cell title="上传时间:" :value="detailInfo.imgDate || ''" center />
                <van-cell title="备注:" :value="detailInfo.remark || ''" center />
                <div class="careful-style" v-if="showCheck">
                    <van-cell title="验收人:" :value="detailInfo.carefulName || ''" center>
                        <img :src="require('@/assets/carefulYes.svg')" v-if="/已通过/.test(detailInfo.divideStatus)">
                        <img :src="require('@/assets/carefulNo.svg')" v-if="/未通过/.test(detailInfo.divideStatus)">
                        <span style="margin-left: 8px;"> {{ detailInfo.carefulName || '' }} </span>
                    </van-cell>
                    <van-cell title="验收时间:" :value="detailInfo.carefulDate || ''" center />
                    <van-cell title="验收意见:" :value="detailInfo.carefulRemark || ''" center />
                </div>
                <div class="photo-style">
                    <div class="label">现场照片:</div>
                    <div class="value">
                        <div class="img-style" :src="item" v-for="(item, index) in fileList" :key="index"
                            @click="onPreview(item)">
                            <van-image :src="item" fit="cover" width="100%" height="100%" />
                        </div>
                        <div class="more-style" v-if="showMore" @click="onShowMore"> 查看更多 </div>
                    </div>
                </div>
            </van-cell-group>
        </div>

        <div class="submit-box" v-if="showUpBtn && routerName != '深化'">
            <van-button type="primary" block color="#4871C0" @click="onSubmit">重新上传</van-button>
        </div>
        <div v-if="routerName == '深化'" class="checkedBtn">
            <van-button type="primary" block color="#F96D64" :class="checkedDisabled?'btned':'btn'" @click="handleChecked(2)" :disabled="checkedDisabled">不通过</van-button>
            <van-button type="primary" block color="#15D057" :class="checkedDisabled?'btned':'btn'" @click="handleChecked(3)" :disabled="checkedDisabled">通过</van-button>
        </div>
    </div>
</template>

<script>
import api from '@/api/api.js'
import { ImagePreview } from 'vant';
export default {
    data() {
        return {
            clientInfo: {},
            detailInfo: {},
            fileList: [],
            showCheck: false,
            showUpBtn: false,
            showMore: false,
            // 判断是否是深化
            routerName:'',
            // 控制按钮禁用
            checkedDisabled:false,
            // 验收的备注说明
            checkRemark:''
        }
    },
    computed: {
        title() {
            let title = this.clientInfo.divideStatus
            if (/通过/.test(this.clientInfo.divideStatus)) {
                this.showCheck = true
            }
            if (/未通过/.test(this.clientInfo.divideStatus) || this.routerName == '深化') {
                this.showUpBtn = true
            }
            if (/待验/.test(this.clientInfo.divideStatus)) {
                title = '查看详情'
            }
            return title
        }
    },
    methods: {
        cancel() {
            this.$router.back()
            // this.$router.push({ name: "engineeringConstructionName", })
        },
        onPreview(url) {
            ImagePreview({
                images: [url],
                closeable: true,
                showIndex: false
            });
        },
        onShowMore() {
            this.fileList = this.detailInfo.imgUrlList
            this.showMore = false
        },
        onSubmit() {
            this.$router.push({ name: "engineeringSubpackageHandle", })
        },
        async onGetDetail() {
            const res = await api.getSubpackageImgDetail(this.clientInfo.id)
            if (res.code == 200) {
                this.detailInfo = res.data
                if (res.data.imgUrlList && res.data.imgUrlList.length > 8) {
                    this.fileList = res.data.imgUrlList.filter((_, index) => (index < 8))
                    this.showMore = true
                } else {
                    this.fileList = res.data.imgUrlList || []
                }
            }
        },
        // 审核
        async handleChecked(status){
            const res = await api.checkSubpackaged({carefulRemark:this.checkRemark,carefulStatus:status,sellDivideId:this.clientInfo.id})
            if(res.code == 200){
                this.checkedDisabled = true
            }
        }
    },
    created() {
        this.clientInfo = JSON.parse(sessionStorage.getItem('clientInfo1'))
        this.routerName = this.$route.query.routerName
        this.onGetDetail()
    },
    watch: {
        showMore(newVal) {
            if (newVal) {
                this.$nextTick(() => {
                    const width1 = window.screen.width - (20 * 2)
                    document.getElementsByClassName('more-style')[0].style.width = width1 / 3 - 8 + 'px'
                    document.getElementsByClassName('more-style')[0].style.height = width1 / 3 - 8 + 'px'
                })
            }
        },
        fileList(newVal) {
            if (newVal.length) {
                newVal.forEach((_, index) => {
                    this.$nextTick(() => {
                        const width1 = window.screen.width - (20 * 2)
                        document.getElementsByClassName('img-style')[index].style.width = width1 / 3 - 8 + 'px'
                        document.getElementsByClassName('img-style')[index].style.height = width1 / 3 - 8 + 'px'
                    })
                })
            }
        }
    }
}
</script>

<style scoped lang="scss">
.finishUpProject-style {
    .content-style {
        height: calc(100vh - 110px);
        padding-bottom: 60px;
        overflow: auto;
        background-color: #F5F5F5;
        font-size: 16px;
    }

    .content-style1 {
        height: calc(100vh - 50px);
        padding-bottom: 0px;
        overflow: auto;
        background-color: #F5F5F5;
        font-size: 16px;
    }
    .mian_top{
        margin: 12px 8px;
        border-radius: 8px;
        overflow: hidden;
    }
    .main_top_reamrk{
        margin: 12px 8px 0px 8px;
        border-radius: 8px;
        width: auto;
        overflow: hidden;
        font-size: 12px !important;
    }

    [class*=van-hairline]::after {
        content: none;
    }

    .careful-style {
        position: relative;

        &::after {
            position: absolute;
            box-sizing: border-box;
            content: ' ';
            pointer-events: none;
            right: 0.426667rem;
            bottom: 0;
            left: 0.426667rem;
            border-bottom: 0.026667rem solid #ebedf0;
            -webkit-transform: scaleY(.5);
            transform: scaleY(.5);
        }
    }

    .photo-style {
        position: relative;
        box-sizing: border-box;
        width: 100%;
        padding: 0.266667rem 0.426667rem;
        overflow: hidden;
        color: #323233;
        font-size: 0.373333rem;
        line-height: 0.64rem;
        background-color: #fff;
        text-align: left;

        .label {
            font-size: 14px;
            color: #999;
        }

        .value {
            margin: 8px 0;
            display: flex;
            flex-flow: row wrap;
        }

        .img-style {
            display: inline-block;
            margin: 0 8px 8px 0;
            border-radius: 5px;
            overflow: hidden;
        }

        .more-style {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #eee;
            border-radius: 5px;
            font-size: 16px;
        }
    }

    .submit-box {
        width: calc(100% - 80px);
        padding: 0 40px;
        position: fixed;
        bottom: 18px;

        :deep(.van-button) {
            border-radius: 10px;
        }
    }
    .checkedBtn{
        display: flex;
        justify-content: space-around;
        height: 78px;
        background: #ffffff;
        padding: 10px 20px 0px;
        position: fixed;
        bottom: 0px;
        box-shadow: -2px 0px 4px 0px rgba(104,104,104,0.25);
        .btn{
            border-radius: 10px;
            width: 148px;
            height: 58px;
            line-height: 58px;
            margin: 0px 10px;
        }
        .btned{
            color: #6C6C6C !important;
            background: #CECECE !important;
            border: 1px solid #CECECE !important;
            border-radius: 10px;
            width: 148px;
            height: 58px;
            line-height: 58px;
            margin: 0px 10px;
        }
    }

    ::v-deep .van-cell__title {
        flex: none;
        width: 100px;
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #999;
    }

    ::v-deep .van-cell__value {
        display: flex;
        align-items: center;
        min-height: 38px;
        justify-content: flex-end;
        font-size: 16px;
        color: #333;
        word-break: break-all;
    }
    ::v-deep .van-cell__value--alone{
        display: block;
        width: 100%;
    }
    ::v-deep .van-field__body{
        font-size: 14px;
    }
}
</style>