<template>
  <div class="content">
    <headerNav
      leftNav=" "
      title="新建子任务"
      @leftClick="back"
      :rightNav="'添加任务'"
      :rightIcon="'sub-task-add-icon'"
      @rightClick="addTask"
    ></headerNav>
    <main class="content-main">
      <van-cell title="任务时间" title-class="cell-group-title" value-class="cell-group-right" :value="$route.query.taskDate" />
      <van-cell title="任务项" title-class="cell-group-title" value-class="cell-group-right" :value="$route.query.termName" />
      <van-form @submit="onSubmitTaskSet" :key="fromRestFields">
        <div v-for="(item,index) in fromArr" :key="index">
          <div class="sub-task">
            <div class="sub-task-title">子任务{{ index+1 }}</div>
            <div class="sub-task-icon" @click="deleteSubTask(index)" v-if="fromArr.length>1"><img src="../../../../assets/task-management/delete-icon.png"></div>
          </div>
          <van-field
            clickable
            readonly
            name="userName"
            :value="item.userName"
            label="执行者"
            placeholder="请选择"
            :rules="[{ required: true, message: '' }]"
            @click="showExecutorPickerFn(item,index)"
            @focus="forbid"
          >
            <i slot="left-icon" style="color: red">*</i>
            <template #button><van-icon name="arrow" /></template>
          </van-field>
          <van-field
            readonly
            v-model="$route.query.standardRate"
            name="standardRate"
            type="text"
            maxlength="6"
            label="标准发生率"
            placeholder="请输入"
            :rules="[{ required: true, message: '' }]"
            @input="formatter($event,item,index)"
          >
            <i slot="left-icon" style="color: red">*</i>
          </van-field>
        </div>

        <div class="my-task-modification-popup-button">
          <van-button native-type="submit">确认</van-button>
        </div>
      </van-form>
    </main>

    <van-popup v-model="showExecutorPicker" position="bottom">
      <van-picker
        ref="executorPicker"
        show-toolbar
        title="执行者"
        :columns="executorList"
        @confirm="onConfirmExecutor"
        @cancel="showExecutorPicker = false"
      />
    </van-popup>

  </div>
</template>

<script>
import moment from "moment";
import { Toast } from "vant";
import api from "@/api/task-management/human-resources.js";

export default {
  components: {},
  data() {
    return {
      executorList:[], //执行者数据
      showExecutorPicker: false, //执行者弹窗
      showExecutorIndex:0, //第几个执行者弹窗

      fromRestFields: 0,
      fromValue: {
        userId:"",
        userName:"",
      },
      fromArr:[
        {
          userId:"",
          userName:"",
        },
      ],
      // 子任务数据
      subTaskList:[]
    };
  },
  created() {},
  mounted() {
    let _this = this;
    this.getExecutorList()
  },
  methods: {
    // 路由返回
    back() {
      this.$router.go(-1);
    },
    // 添加任务
    addTask(){
      if(this.fromArr.length>50){
        Toast.fail('创建子任务数量不能超过50个！')
        return false
      }
      this.fromArr.push({
        userId:"",
        userName:"",
      })
    },
    // 删除子任务
    deleteSubTask(index){
      this.fromArr.splice(index,1)
    },
    // 获取执行者数据
    getExecutorList(){
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      let params = {}
      api.getExecutorList(params).then((res)=>{
        if (res.code == 200) {
          Toast.clear()
          let arr = []
          res.data.forEach(item => {
            arr.push({
              text:item.name,
              value:item.code,
            })
          });
          this.executorList = arr
        }else{
          Toast.fail(res.msg)
        }
      })
    },
    // 打开执行者弹窗
    showExecutorPickerFn(item,index){ 
      this.showExecutorPicker = true
      this.showExecutorIndex = index
      this.executorList.forEach((item1,index1)=>{
        if(item.userId  && item.userId == item1.value){
          this.$refs.executorPicker.setValues([item.userName])
        }
      })
    },
    // 执行者弹窗 确认
    onConfirmExecutor(value){
      this.fromArr[this.showExecutorIndex].userName = value.text;
      this.fromArr[this.showExecutorIndex].userId = value.value;
      this.showExecutorPicker = false;
    },
    // 禁止弹出软键盘
    forbid(){
      document.activeElement.blur()
    },
    formatter(value,item,index){
      if( value == '' ){
        Toast.fail('输入的数量不能为空')
        this.fromArr[index].standardRate = ""
      }
    },
    // form表单 提交
    onSubmitTaskSet(values) {
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      let params={
        "taskId": this.$route.query.taskId,
        "itemList":this.fromArr
      }
      api.saveSubTask(params).then((res)=>{
        Toast.clear()
        if (res.code == 200) {
          Toast.success('操作成功');
          let _this = this;
          setTimeout(()=>{
            _this.back()
          },1000)
        }else{
          Toast.fail(res.msg)
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.content ::v-deep {
  height: 100vh;
  overflow: auto;
  background: #f3f5f7;
  .van-form {
    height: calc(100vh - 228px);
    overflow: auto;
    .interval {
      border-top: 8px solid #f3f5f7;
    }
  }
  
  .content-main{
    .sub-task{
      height: 24px;
      padding: 7px 11px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .sub-task-title{
        font-size: 16px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #333333;
        position: relative;
        padding-left: 6px;
        &::after{
          content: ""; 


          width: 4px;
          height: 16px;
          background: #0E8BFF;
          border-radius: 2px 2px 2px 2px;
          position: absolute;
          left: 0;
          top: 50%;
          margin-top: -7px;
        }
      }
      .sub-task-icon{
        width: 20px;
        height: 20px;
        img{
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .my-task-modification-popup-button{
    width: 100%;
    padding: 10px 18px 30px;
    position: fixed;
    bottom: 0;
    box-shadow: 0px -1px 8px 0px rgba(0,0,0,0.04);
    box-sizing: border-box;
    background: #ffffff;
    .van-button{
      width: 100%;
      height: 50px;
      font-size: 18px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      background: #4871C0;
      border-radius: 4px 4px 4px 4px;
    }
  }

  .van-field__control{
    text-align: right;
  }

  .cell-group-title {
    font-size: 16px;
    font-family: PingFang TC, PingFang TC;
    font-weight: 400;
    color: #999999;
    text-align: left;
  }

  .cell-group-right,.van-field__control {
    font-size: 16px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #000000;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .van-field__label {
    font-size: 16px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    color: #1d2129;
  }
  .van-field__value {
    font-size: 16px;
  }
}
</style>
