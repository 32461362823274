<template>
  <div class="main">
    <van-sticky class="topMenu">
      <headerNav leftNav=" " :title="'审批详情'" @leftClick="back"></headerNav>
    </van-sticky>
    <main>
      <div class="main-style">
        <div class="content">
          <!-- 顶部 -->
          <div class="content-top">
            <div class="content-top-left">
              <div class="content-top-left-img">
                <img src="@/assets/task-management/task-setup-finance-check-details.png" alt />
              </div>
              <div class="content-top-left-text">
                <div class="title">{{ taskUploadRecord.userName }}的任务结果</div>
                <div class="submit-name">
                  提交人
                  <span>{{ taskUploadRecord.userName }}</span>
                </div>
              </div>
            </div>
            <div class="content-top-right">{{ taskUploadRecord.createDate.replace(/\//g, "-") }}</div>
          </div>
          <!-- 任务项 -->
          <div class="content-center" v-if="isExpanded">
            <!-- 综述报告 -->
            <div v-if="taskUploadRecord.termId == 'market_summarize'">
              <div class="content-center-details">
                <div class="left">任务项</div>
                <div class="right">{{ taskUploadRecord.termName }}</div>
              </div>
              <div class="content-center-details">
                <div class="left">附件</div>
                <div class="right accessory" @click="onClickDownFile(taskUploadRecord.fileInfoList)">
                  <img src="@/assets/task-management/task-setup-file-xlsx.png" alt />
                  <div class="file">{{ taskUploadRecord.fileInfoList && taskUploadRecord.fileInfoList[0].fileName }}</div>
                </div>
              </div>
              <div class="content-center-details">
                <div class="left">错漏处(1处1分)</div>
                <div class="right">
                  <div v-if="taskUploadRecord.errorCount != '-'">{{ taskUploadRecord.errorCount }}处(扣{{ taskUploadRecord.errorCount}}分)</div>
                  <div v-else>{{ taskUploadRecord.errorCount }}</div>
                </div>
              </div>
              <div class="content-center-details">
                <div class="left">延期提交(1天2分)</div>
                <div class="right">
                  <div v-if="taskUploadRecord.delayCount != '-'">{{ taskUploadRecord.delayCount }}天(扣{{ taskUploadRecord.delayCount * 2 }}分)</div>
                  <div v-else>{{ taskUploadRecord.delayCount }}</div>
                </div>
              </div>
              <div class="content-center-details">
                <div class="left">最终得分(100分)</div>
                <div class="right">
                  <div v-if="taskUploadRecord.score != '-'">{{ taskUploadRecord.score }}分</div>
                  <div v-else>{{ taskUploadRecord.score }}</div>
                </div>
              </div>
            </div>

            <!-- 其他任务项 -->
            <div v-else>
              <div class="content-center-details">
                <div class="left">任务项</div>
                <div class="right">{{ taskUploadRecord.termName }}</div>
              </div>
              <div class="content-center-details" v-if="taskObj.label2">
                <div class="left">{{ taskObj.label2 }}</div>
                <div class="right">{{ (taskObj.unit == '%'?Number(taskUploadRecord.taskFine || 0).toFixed(2):taskUploadRecord.taskFine) || 0 }}{{ taskObj.unit }}</div>
              </div>
              <div class="content-center-details" v-if="taskObj.label3">
                <div class="left">{{ taskObj.label3 }}</div>
                <div class="right">{{ (taskObj.unit == '%'?Number(taskUploadRecord.realityAccomplish || 0).toFixed(2):taskUploadRecord.realityAccomplish) || 0 }}{{ taskObj.unit }}</div>
              </div>
              <div class="content-center-details content-center-details-img">
                <div class="left">附件</div>
                <div class="right-img">
                  <img v-for="(item, index) in taskUploadRecord.fileInfoList" :key="index" @click="onPreview(item.fileUrl)" width="100" height="100" :src="item.fileUrl" />
                </div>
              </div>
            </div>
          </div>

          <!-- 收起展开 -->
          <div class="content-center-bottom" @click="onClickExpand">
            <img :src="require(`@/assets/task-management/${!isExpanded ? 'task-zhankai' : 'task-shouqi'}.png`)" alt />
          </div>
        </div>

        <!-- 审批流程 -->
        <div class="bottom-check">
          <div class="bottom-check-title">
            <div class="left-line"></div>
            <div class="text">审批流程</div>
          </div>

          <div class="steps-content">
            <van-steps direction="vertical" :active="stepData.length -1" active-color="#0E8BFF">
              <van-step v-for="(item, index) in stepData" :key="index">
                <template #active-icon>
                  <div class="steps-active-icon">
                    <img v-if="item.manageCheckStatus == '0'" src="@/assets/task-management/task-setup-steps-review.png" alt />
                    <img v-if="item.manageCheckStatus == '1'" src="@/assets/task-management/task-setup-steps-confrim.png" alt />
                    <img v-if="item.manageCheckStatus == '2'" src="@/assets/task-management/task-setup-steps-reject.png" alt />
                    <img v-if="item.manageCheckStatus == '3'" src="@/assets/task-management/task-setup-steps-lose.png" alt />
                  </div>
                </template>
                <div class="step-main">
                  <div class="step-main-left">
                    <div class="step-main-left-text" :class="stepData.length == index + 1 ? 'step-main-left-text-active' : ''">
                      {{ item.manageName }}
                      <span>{{ item.manageRoleName }}</span>
                    </div>
                    <div class="step-main-left-date">{{ item.manageTime }}</div>
                  </div>
                  <div class="step-main-right">
                    <span v-if="item.manageCheckStatus == '0'" class="orange">待审核</span>
                    <span v-if="item.manageCheckStatus == '1'" class="green">已通过</span>
                    <span v-if="item.manageCheckStatus == '2'" class="red">已驳回</span>
                    <span v-if="item.manageCheckStatus == '3'" class="gay">已失效</span>
                  </div>
                </div>
              </van-step>
            </van-steps>
          </div>
        </div>

        <!-- 审批 -->
        <div class="footer-handle" v-if="isShowCheckBtn">
          <van-button class="btn" type="default" @click="onHandleExamine('驳回')">驳回</van-button>
          <van-button class="btn" color="#4871C0" @click="onHandleExamine('通过')" type="info">通过</van-button>
        </div>
      </div>
    </main>

    <!-- 审批确认弹窗 -->
    <van-dialog v-model="examineDialog" :closeOnClickOverlay="true" confirmButtonColor="#3975C6" show-cancel-button @close="examineDialogClose" @confirm="confirmAgree" @cancel="cancelRefuse">
      <div class="dialog-main">
        <!-- 驳回 -->
        <div class="dialog-main-reject" v-if="!examineDialogTitle">
          您确认“
          <span>驳回</span> ” {{ taskUploadRecord.userName }}的审批请求吗？
        </div>
        <!-- 通过 -->
        <div class="dialog-main-reject" v-if="taskUploadRecord.termId != 'market_summarize' && examineDialogTitle">
          您确认“
          <span style="color: green;">通过</span> ” {{ taskUploadRecord.userName }}的审批请求吗？
        </div>
        <!-- 市场综述 -->
        <div class="dialog-main-agree" v-if="taskUploadRecord.termId == 'market_summarize' && examineDialogTitle">
          <div class="dialog-main-agree-title">{{ examineDialogTitle }}</div>
          <div class="dialog-from">
            <van-form @submit="confirmAgree">
              <van-field v-model="examineDialogFrom.value" label-width="53%" placeholder="请输入" :rules="[{ required: true,pattern:/^(?:[1-9][0-9]?|100|0)$/,message:'请输入0-100的正整数'}]" type="digit" @input="fieldChange">
                <i slot="left-icon" style="color: red">*</i>
                <template #label>
                  <div class="faults">
                    错漏处
                    <span>(1处1分)</span>
                  </div>
                </template>
                <template #button>
                  <span style="color: #333">处</span>
                </template>
              </van-field>
              <div class="from-custom">
                <div class="from-custom-left">延期提交(1天2分)</div>
                <div class="from-custom-right">
                  {{ examineDialogFrom.daysDeferred }}天
                  <span>(扣{{ examineDialogFrom.daysDeferred * 2 || 0 }}分)</span>
                </div>
              </div>
              <div class="from-custom">
                <div class="from-custom-left">最终得分(满分100)</div>
                <div class="from-custom-right">{{ finalScore }}分</div>
              </div>
            </van-form>
          </div>
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { ImagePreview, Toast } from "vant";
import api from "@/api/task-management/finance.js";
import { taskCheckListDetails } from "./data.js";
import { downloadFile } from "@/utils/timeCompute.js";
export default {
  data() {
    return {
      isExpanded: false,
      stepData: [],
      // 审批弹窗
      examineDialog: false,
      //弹窗标题
      examineDialogTitle: "",
      // 审批内容
      examineDialogFrom: {
        value: '',
        daysDeferred: 0,
        finalScore: 0
      },
      // 任务上传记录详情
      taskUploadRecord: {},
      // 当前审批指标项
      taskObj: {},
      // 判断是否显示审批按钮
      isShowCheckBtn: false,
    };
  },
  created() {
    let role = JSON.parse(localStorage.getItem('USER_ROLE'))
    let record = JSON.parse(localStorage.getItem("TASK_UPLOAD_RECORD"));
    this.taskUploadRecord = { ...role, ...record }
    if(this.taskUploadRecord.termId == 'cost_payment'){
      this.taskUploadRecord.taskFine = this.taskUploadRecord.targetValue
      this.taskUploadRecord.realityAccomplish = this.taskUploadRecord.finishValue
    }
    this.taskObj = taskCheckListDetails(this.taskUploadRecord.termId);
    this.getExamineSchedule();
  },
  computed: {
    // 计算最终得分
    finalScore() {
      this.examineDialogFrom.finalScore =
        100 -
        this.examineDialogFrom.value -
        this.examineDialogFrom.daysDeferred * 2;
      return this.examineDialogFrom.finalScore < 0 ? 0 : this.examineDialogFrom.finalScore;
    }
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    // 查询审批进度
    async getExamineSchedule() {
      try{
        Toast.loading({
          message: "加载中...",
          forbidClick: true,
        });
        const { data } = await api.getListByTaskId(this.taskUploadRecord.id);
        const result = data.find(item => item.manageRoleCode == this.taskUploadRecord.roleCode)
        this.isShowCheckBtn = result && result.manageCheckStatus == '0' ? true : false
        this.stepData = data;
      }finally{
        Toast.clear();
      }
    },
    // 控制展开收起
    onClickExpand() {
      this.isExpanded = !this.isExpanded;
    },
    // 错漏处改变
    fieldChange(e) {
    },
     // 查看大图
     onPreview (url) {
      ImagePreview({
        images: [url],
        closeable: true,
        showIndex: false
      });
    },
    // 审批
    onHandleExamine(type) {
      this.examineDialog = true;
      if (type == "驳回") {
        this.examineDialogTitle = "";
      } else {
        this.examineDialogTitle = "任务成果评分";
        this.examineDialogFrom.daysDeferred = this.taskUploadRecord.delayCount;
      }
    },
    // 确认
    async confirmAgree() {
      /* 1 通过  2 驳回 */
      let params = {
        termId: this.taskUploadRecord.termId,
        id: this.taskUploadRecord.id,
        errorCount: this.examineDialogFrom.value || 0,
        delayCount: this.taskUploadRecord.delayCount,
        score: this.examineDialogFrom.finalScore,
        result: !this.examineDialogTitle ? "2" : "1"
      };
      let rule = /^(?:[1-9][0-9]?|100|0)$/
      if( this.taskUploadRecord.termId == 'market_summarize' && this.examineDialogTitle && !rule.test(this.examineDialogFrom.value)){
        Toast.fail('错漏处只能输入0-100的整数，请重新输入')
        this.examineDialogFrom.value = null
        this.examineDialog = true
        return
      }
      try {
        const data = await api.checkFlowResult(params);
        if (data.code == 200) {
          Toast.success("操作成功");
          this.getExamineSchedule();
        }
      } finally {
        this.examineDialog = false;
      }
    },
    // 拒绝
    cancelRefuse() { },
    // 弹窗关闭
    examineDialogClose() {
      this.examineDialogFrom = {
        value: '',
        daysDeferred: 0,
        finalScore: 0
      }
    },
    downloadFile(fileUrl) {
      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = '';
      link.click();
    },
    // 下载文件
    onClickDownFile(file){
      let pre_prod_host = window.location.host == "m.hejiahejiazhuang.com" ?process.env.VUE_APP_BASE_RUL_PROD : process.env.VUE_APP_BASE_RUL_PRE
      let url = process.env.NODE_ENV === "production" ?pre_prod_host+process.env.VUE_APP_SYS_API: process.env.VUE_APP_SYS_API
      this.downloadFile(url+`/tools/oss/ali/downOSSFile/public?fileName=${file[0].fileUrl.split('/').pop()}&dir=oss`)
    } 
  }
};
</script>

<style lang="scss" scoped>
.main::v-deep {
  background: #f5f5f5;
  height: 100vh;

  .main-style {
    height: calc(100vh - 50px);
    overflow: auto;
    margin-top: -1px;
  }

  .content {
    background: #3975c6;
    border-radius: 0px 0px 5px 5px;
    padding: 8px 12px;

    .content-top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;

      .content-top-left {
        display: flex;
        align-items: flex-start;
        text-align: left;

        .content-top-left-img {
          img {
            width: 18px;
            height: 18px;
            margin: 6px 4px 0 0;
            vertical-align: middle;
          }
        }

        .content-top-left-text {
          .title {
            font-size: 20px;
            font-family: PingFang TC, PingFang TC;
            font-weight: 500;
            color: #ffffff;
            margin-bottom: 9px;
          }

          .submit-name {
            font-size: 16px;
            font-family: PingFang TC, PingFang TC;
            font-weight: 400;
            color: #d7e3f4;

            span {
              font-weight: 500;
              margin-left: 16px;
            }
          }
        }
      }

      .content-top-right {
        font-size: 14px;
        font-family: PingFang TC, PingFang TC;
        font-weight: 400;
        color: #ffffff;
        margin-top: 5px;
      }
    }

    .content-center {
      padding: 9px 8px;
      background: #336cc2;
      border-radius: 4px;
      margin-bottom: 8px;

      .content-center-details {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        padding: 8px 0px;
        // height: 38px;

        .left {
          font-size: 14px;
        }

        .right {
          font-size: 16px;
          font-weight: 500;
        }

        .right-img {
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          max-width: 250px;
          border-radius: 4px;
          overflow: hidden;

          img {
            width: 72px;
            height: 72px;
            margin: 0 0 11px 11px;
            border-radius: 4px;
          }
        }

        .accessory {
          display: flex;
          align-items: center;

          img {
            width: 14px;
            height: 16px;
            margin-right: 3px;
          }

          .file {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 200px;
          }
        }
      }

      .content-center-details-img {
        align-items: flex-start;
      }
    }

    .content-center-bottom {
      img {
        width: 16px;
        height: 16px;
      }
    }
  }

  .bottom-check {
    background: #ffffff;
    border-radius: 8px;
    margin-top: 8px;

    .bottom-check-title {
      display: flex;
      padding: 14px;
      align-items: center;
      border: 1px solid #eeeeee;

      .left-line {
        width: 4px;
        height: 16px;
        background: #4871c0;
        border-radius: 20px;
      }

      .text {
        font-size: 16px;
        font-family: PingFang TC, PingFang TC;
        font-weight: 500;
        color: #333333;
        margin-left: 3px;
      }
    }
  }

  .steps-content {
    .van-step__line {
      left: -4.5%;
      top: 20px;
    }

    .steps-active-icon {
      img {
        width: 12px;
        height: 12px;
      }
    }

    .step-main {
      display: flex;
      justify-content: space-between;

      .step-main-left {
        text-align: left;

        .step-main-left-text {
          font-size: 16px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #323233;
          margin-bottom: 8px;

          span {
            width: 64px;
            height: 20px;
            background: rgba(217, 217, 217, 0.2);
            border-radius: 4px;
            font-size: 14px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #8c8c8c;
            padding: 0px 4px;
          }
        }

        .step-main-left-text-active {
          color: #0e8bff !important;

          span {
            background: rgba(14, 139, 255, 0.1);
            color: #0e8bff !important;
          }
        }

        .step-main-left-date {
          font-size: 12px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #969799;
        }
      }

      .step-main-right {
        span {
          width: 58px;
          height: 24px;
          font-size: 14px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          padding: 3px 8px;
          border-radius: 4px;
        }

        .green {
          background: #ecf8f0;
          color: #38b865;
        }

        .orange {
          background: #fff4e9;
          color: #ff9c3f;
        }

        .red {
          background: rgba(255, 97, 87, 0.15);
          color: #ff6157;
        }

        .gay {
          background: #f5f5f5;
          color: #999999;
        }
      }
    }
  }

  .footer-handle {
    width: 100%;
    height: 66px;
    background: #ffffff;
    box-shadow: 0px -1px 8px 0px rgba(0, 0, 0, 0.04);
    position: fixed;
    bottom: 1px;
    left: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    z-index: 100000;
    padding-bottom: 10px;

    .btn {
      width: 164px;
      height: 46px;
      border-radius: 4px;
      font-size: 18px;
    }
  }

  .dialog-main {
    padding: 16px;
    border-bottom: 1px solid #eeeeee;

    .dialog-main-reject {
      font-size: 16px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      color: #000000;
      margin: 20px 0px;

      span {
        color: #ed4040;
      }
    }

    .dialog-main-agree {
      .dialog-main-agree-title {
        font-size: 16px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        color: #323233;
      }

      .dialog-from {
        margin-top: 20px;

        .van-cell::after {
          border: 0;
        }
      }
    }

    .faults {
      font-size: 16px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      color: #333333;

      span {
        color: #999999;
        font-weight: 400;
      }
    }

    .from-custom {
      display: flex;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #999999;
      align-items: center;
      justify-content: space-between;
      padding: 11px 18px;

      .from-custom-left {
        font-size: 16px;
        width: 61%;
        text-align: left;
      }

      .from-custom-right {
        font-size: 14px;
        color: #333333;
        text-align: left;
        width: 38.5%;
        // flex: 4;

        span {
          color: #999999;
        }
      }
    }
  }

  .van-dialog__cancel {
    font-size: 18px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    color: #333333;
  }

  .van-dialog__confirm {
    font-size: 18px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    color: #3975c6;
  }
}
</style>
