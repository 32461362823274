<template>
	<div class="audit-resulse-style">
		<headerNav title="整改" leftNav="取消" @leftClick="cancel" rightNav="提交" @rightClick="submit"></headerNav>
		<div class="input-box upPhoto-style">
			<div class="input-text">整改照片
				<span class="xing-style">*</span>
			</div>
			<div class="input-style">
				<van-uploader :after-read="afterRead" multiple>
					<van-button class="button-style" type="primary"></van-button>
					<img src="@/assets/upPhoto.png" class="choose-icon" alt="">
				</van-uploader>

			</div>
		</div>
		<div class="photo-box" v-show="fileList.length">
			<div class="photo-style" v-for="(item, index) in fileList" style="position: relative;">
				<div class="del-icon" @click="delPhoto(index)">×</div>
				<img style="width: 100%;height: 100%;" :src="item.url" alt="" @click="photoPreview(index)">
				<div class="img-overlay" v-if="item.status === 'uploading'">
					<van-loading size="20" color="#f5f5f5" />
				</div>
				<div class="img-overlay" v-if="item.status === 'failed'">
					<van-icon name="fail" size="20" color="#f5f5f5" />
				</div>
			</div>
		</div>
		<div class="input-textarea-box">
			<div class="input-text">备注信息</div>
			<div class="input-style">
				<van-form ref="vantForm" @submit="onSubmit">
					<van-field class="input-style" v-model="remark" rows="3" autosize type="textarea" maxlength="200"
						name="remark" placeholder="请输入" />
				</van-form>
			</div>
		</div>
	</div>
</template>

<script>
import { Toast, ImagePreview } from 'vant';
import api from '@/api/api.js'
export default {
	data() {
		return {
			info: {},
			remark: '',
			fileList: [],
		}
	},
	methods: {
		submit() {
			this.$refs.vantForm.submit()
		},
		cancel() {
			this.$router.go(-1)
		},
		async onSubmit(val) {
			if (this.fileList.length > 0) {
				const isUplod = this.fileList.some(item => item.status === 'uploading')
				const isFail = this.fileList.some(item => item.status === 'failed')
				if (!isUplod && !isFail) {
					const imgList = this.fileList.map(e => ({ webUrl: e.url }))
					let obj = {
						itemId: this.$route.query.id,
						remark: this.remark,
						imgDTOS: imgList,
						projectid: this.$route.query.projectid
					}
					let res = await api.amendUp(obj)
					if (res.code == 200) {
						Toast.success('提交成功！')
						this.$router.go(-1)
					}
				} else if (isUplod) {
					Toast.fail('图片上传未完成，请等待...')
				} else if (isFail) {
					Toast.fail('图片上传失败\n请重新上传')
				}
			} else {
				Toast.fail('请上传整改照片！')
			}
		},
		async afterRead(file) {
			let fileList = Array.isArray(file) ? file : [file]
			if (fileList.length > 9) {
				Toast.fail('最多一次上传九张图片！')
				fileList = fileList.splice(0, 9)
			}
			const confirmFileList = fileList.map(item => ({ url: item.content, status: 'uploading' }))
			this.fileList = this.fileList.concat(confirmFileList)
			const indexR = this.fileList.length - fileList.length
			fileList.forEach((e, index) => {
				// 此时可以自行将文件上传至服务器
				let formData = new FormData();
				formData.append('files', e.file);
				formData.append('dir', 'oss');
				new Promise((reslove) => {
					const res = api.upFile(formData)
					reslove(res)
				}).then(res => {
					if (res.code == 200) {
						this.fileList[indexR + index].url = res.url
						this.fileList[indexR + index].status = 'done'
					} else {
						this.fileList[indexR + index].status = 'failed'
					}
				})
			})
		},
		delPhoto(index) {
			this.fileList.splice(index, 1)
		},
		photoPreview(index) {
			let o = {
				images: this.fileList.map(item=>item.url),
				startPosition: index
			}
			ImagePreview(o);
		},
	},
	mounted() {
		// this.info = JSON.parse(sessionStorage.getItem('abarbeitungInfo'))
	},
}
</script>

<style scoped lang="scss">
.audit-resulse-style {
	.input-textarea-box {
		background: #FFFFFF;
		margin-top: 10px;
		padding: 0 18px;
	}

	.input-box {
		margin-top: 10px;
	}

	.upPhoto-style {
		padding-left: 18px;
	}

	.input-textarea-box {
		padding: 0 18px;
		margin-top: 10px;
		background: #FFFFFF;
	}

	.photo-box {
		background: #FFFFFF;
		display: flex;
		padding: 0 18px;
		padding-bottom: 18px;
		flex-wrap: wrap;

		.photo-style {
			width: 48px;
			height: 48px;
			margin-right: 19px;
			margin-top: 10px;
			position: relative;

			.img-overlay {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(0, 0, 0, 0.65);
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}

		.center-style {
			margin: 0 20px;
		}

		.del-icon {
			width: 16px;
			height: 16px;
			background: #000000;
			color: #FFFFFF;
			opacity: 0.8;
			border-radius: 50%;
			font-size: 9px;
			line-height: 16px;
			position: absolute;
			right: -6px;
			top: -6px;
			text-align: center;
			border: none;
			border-radius: 50%;
			z-index: 1;
		}
	}
}
</style>
