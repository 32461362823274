let list = [
  {
    userCode:'ROLE_603933',//运营总裁
    isBtn:false,
    isSearch:false,
    tabList:[
      { 
        name:'指标',
        code:'target',
        lable:'',
        value:'',
        list:[
          { text: "全部", value: "9" },
          { text: "转单产值", value: "1" },
          { text: "补齐产值", value: "2" },
        ],
      },  
      { 
        name:'月份',
        code:'month',
        lable:'',
        value:'',
      },
    ],
  },
  {
    userCode:'ROLE_768022',//总经理
    isBtn:true,
    isSearch:false,
    tabList:[
      { 
        name:'指标',
        code:'target',
        lable:'',
        value:'',
        list:[
          { text: "全部", value: "9" },
          { text: "转单产值", value: "1" },
          { text: "补齐产值", value: "2" },
        ],
        list1:[
          { text: "全部", value: "9" },
          { text: "转单产值", value: "1" },
          { text: "补齐产值", value: "2" },
        ],
      },  
      { 
        name:'月份',
        code:'month',
        lable:'',
        value:'',
      },
    ],
  },
  {
    userCode:'ROLE_886285',//前台总监
    isBtn:true,
    isSearch:false,
    tabList:[
      { 
        name:'指标',
        code:'target',
        lable:'',
        value:'',
        list:[
          { text: "全部", value: "9" },
          { text: "转单产值", value: "1" },
          { text: "补齐产值", value: "2" },
        ],
        list1:[
          { text: "全部", value: "9" },
          { text: "转单产值", value: "1" },
          { text: "补齐产值", value: "2" },
          { text: "转单量", value: "3" },
          { text: "补齐量", value: "4" },
          { text: "签单量", value: "5" },
          { text: "到店量", value: "6" },
          { text: "线索量", value: "7" },
        ],
      },  
      { 
        name:'月份',
        code:'month',
        lable:'',
        value:'',
      },
    ],
  },
  {
    userCode:'ROLE_113490',//督导经理
    isBtn:true,
    isSearch:true,
    tabList:[
      { 
        name:'指标',
        code:'target',
        lable:'',
        value:'',
        list:[
          { text: "全部", value: "9" },
          { text: "转单产值", value: "1" },
          { text: "补齐产值", value: "2" },
          { text: "转单量", value: "3" },
          { text: "补齐量", value: "4" },
        ],
        list1:[
          { text: "全部", value: "9" },
          { text: "转单产值", value: "1" },
          { text: "补齐产值", value: "2" },
          { text: "转单量", value: "3" },
          { text: "补齐量", value: "4" },
        ],
      },  
      { 
        name:'月份',
        code:'month',
        lable:'',
        value:'',
      },
    ],
  },
  {
    userCode:'ROLE_327530',//转单经理
    isBtn:true,
    isSearch:true,
    tabList:[
      { 
        name:'指标',
        code:'target',
        lable:'',
        value:'',
        list:[
          { text: "全部", value: "9" },
          { text: "转单产值", value: "1" },
          { text: "补齐产值", value: "2" },
          { text: "转单量", value: "3" },
          { text: "补齐量", value: "4" },
        ],
        list1:[
          { text: "全部", value: "9" },
          { text: "转单产值", value: "1" },
          { text: "补齐产值", value: "2" },
          { text: "转单量", value: "3" },
          { text: "补齐量", value: "4" },
        ],
      },  
      { 
        name:'月份',
        code:'month',
        lable:'',
        value:'',
      },
    ],
  },
  {
    userCode:'ROLE_553504',//签单经理
    isBtn:true,
    isSearch:true,
    tabList:[  
      { 
        name:'月份',
        code:'month',
        lable:'',
        value:'',
      },
    ],
  },
  {
    userCode:'ROLE_670920',//话务经理
    isBtn:true,
    isSearch:true,
    tabList:[  
      { 
        name:'月份',
        code:'month',
        lable:'',
        value:'',
      },
    ],
  },
]

export const adjustFn = (code) => {
  let data = {}
  list.filter(item => {
    if (item.userCode == code) {
      data = item
    }
  })
  return data
};