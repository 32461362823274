// 产品总监 看板（市场）
let marketModel = [
  { name:'延期发生率',type:'delay_happen', leftText:'达标市场', rightText:'未达标市场', unit:'个', operate:'发生率',
    bgIcon:'product-delay-happen-bg-icon',
    leftIcon:'task-board-inquiry-dabiao1',
    rightIcon:'task-board-inquiry-dabiao2',
    table:[
      {label:'工地延期数',prop:'finishValue', minWidth:"80"},
      {label:'工地总数',prop:'targetValue', minWidth:"80"},
      {label:'实际发生率',prop:'finishRate', minWidth:"80"},
    ],
    detailIcon:'product-board-detail-icon1',
  },
  { name:'延期解决率',type:'delay_solve', leftText:'达标市场',rightText:'未达标市场', unit:'个', operate:'解决率',
    bgIcon:'product-delay-solve-bg-icon',
    leftIcon:'task-board-inquiry-dabiao1',
    rightIcon:'task-board-inquiry-dabiao2',
    table:[
      {label:'解决工地数',prop:'finishValue', minWidth:"80"},
      {label:'需解决总数',prop:'targetValue', minWidth:"80"},
      {label:'实际解决率',prop:'finishRate', minWidth:"80"},
    ],
    detailIcon:'product-board-detail-icon2',
  },
  { name:'在建售后发生率',type:'after_sale_building',leftText:'达标市场',rightText:'未达标市场', unit:'个', operate:'发生率',
    bgIcon:'product-after-sale-building-bg-icon',
    leftIcon:'task-board-inquiry-dabiao1',
    rightIcon:'task-board-inquiry-dabiao2',
    table:[
      {label:'工地售后数',prop:'finishValue', minWidth:"80"},
      {label:'工地总数',prop:'targetValue', minWidth:"80"},
      {label:'实际发生率',prop:'finishRate', minWidth:"80"},
    ],
    detailIcon:'product-board-detail-icon3',
  },
  { name:'完工售后发生率',type:'after_sale_finish', leftText:'达标市场',rightText:'未达标市场', unit:'个', operate:'发生率',
    bgIcon:'product-after-sale-finish-bg-icon',
    leftIcon:'task-board-inquiry-dabiao1',
    rightIcon:'task-board-inquiry-dabiao2',
    table:[
      {label:'工地售后数',prop:'finishValue', minWidth:"80"},
      {label:'工地总数',prop:'targetValue', minWidth:"80"},
      {label:'实际发生率',prop:'finishRate', minWidth:"80"},
    ],
    detailIcon:'product-board-detail-icon3',
  },
  { name:'售后解决率',type:'after_sale_solve',leftText:'达标市场',rightText:'未达标市场', unit:'个', operate:'解决率',
    bgIcon:'product-after-sale-solve-bg-icon',
    leftIcon:'task-board-inquiry-dabiao1',
    rightIcon:'task-board-inquiry-dabiao2',
    table:[
      {label:'解决工地数',prop:'finishValue', minWidth:"80"},
      {label:'需解决总数',prop:'targetValue', minWidth:"80"},
      {label:'实际解决率',prop:'finishRate', minWidth:"80"},
    ],
    detailIcon:'product-board-detail-icon2',
  },
  { name:'投诉发生率',type:'complaint_happen', leftText:'达标市场',rightText:'未达标市场', unit:'个', operate:'发生率',
    bgIcon:'product-complaint-happen-bg-icon',
    leftIcon:'task-board-inquiry-dabiao1',
    rightIcon:'task-board-inquiry-dabiao2',
    table:[
      {label:'工地投诉数',prop:'finishValue', minWidth:"80"},
      {label:'工地总数',prop:'targetValue', minWidth:"80"},
      {label:'实际发生率',prop:'finishRate', minWidth:"80"},
    ],
    detailIcon:'product-board-detail-icon4',
  },
  { name:'投诉解决率',type:'complaint_solve',leftText:'达标市场',rightText:'未达标市场', unit:'个', operate:'解决率',
    bgIcon:'product-complaint-solve-bg-icon',
    leftIcon:'task-board-inquiry-dabiao1',
    rightIcon:'task-board-inquiry-dabiao2',
    table:[
      {label:'解决工地数',prop:'finishValue', minWidth:"80"},
      {label:'需解决总数',prop:'targetValue', minWidth:"80"},
      {label:'实际解决率',prop:'finishRate', minWidth:"80"},
    ],
    detailIcon:'product-board-detail-icon2',
  },
]
export const marketModelFn = (params) => {
  let data = {}
  marketModel.filter(item => {
    if (item.type == params) {
      data = item
    }
  })
  return data
};

// 产品经理 看板（人员）
let employeeModel = [
  { name:'延期发生率',type:'delay_happen', leftText:'达标市场', rightText:'未达标市场', unit:'个', operate:'发生率',
    bgIcon:'product-delay-happen-bg-icon',
    leftIcon:'task-board-inquiry-dabiao1',
    rightIcon:'task-board-inquiry-dabiao2',
    table:[
      {label:'工地延期数',prop:'finishValue', minWidth:"80"},
      {label:'工地总数',prop:'targetValue', minWidth:"80"},
      {label:'实际发生率',prop:'finishRate', minWidth:"80"},
    ],
    detailIcon:'product-board-detail-icon1',
  },
  { name:'延期解决率',type:'delay_solve', leftText:'达标市场',rightText:'未达标市场', unit:'个', operate:'解决率',
    bgIcon:'product-delay-solve-bg-icon',
    leftIcon:'task-board-inquiry-dabiao1',
    rightIcon:'task-board-inquiry-dabiao2',
    table:[
      {label:'解决工地数',prop:'finishValue', minWidth:"80"},
      {label:'需解决总数',prop:'targetValue', minWidth:"80"},
      {label:'实际解决率',prop:'finishRate', minWidth:"80"},
    ],
    detailIcon:'product-board-detail-icon2',
  },
  { name:'在建售后发生率',type:'after_sale_building',leftText:'达标市场',rightText:'未达标市场', unit:'个', operate:'发生率',
    bgIcon:'product-after-sale-building-bg-icon',
    leftIcon:'task-board-inquiry-dabiao1',
    rightIcon:'task-board-inquiry-dabiao2',
    table:[
      {label:'工地售后数',prop:'finishValue', minWidth:"80"},
      {label:'工地总数',prop:'targetValue', minWidth:"80"},
      {label:'实际发生率',prop:'finishRate', minWidth:"80"},
    ],
    detailIcon:'product-board-detail-icon3',
  },
  { name:'完工售后发生率',type:'after_sale_finish', leftText:'达标市场',rightText:'未达标市场', unit:'个', operate:'发生率',
    bgIcon:'product-after-sale-finish-bg-icon',
    leftIcon:'task-board-inquiry-dabiao1',
    rightIcon:'task-board-inquiry-dabiao2',
    table:[
      {label:'工地售后数',prop:'finishValue', minWidth:"80"},
      {label:'工地总数',prop:'targetValue', minWidth:"80"},
      {label:'实际发生率',prop:'finishRate', minWidth:"80"},
    ],
    detailIcon:'product-board-detail-icon3',
  },
  { name:'售后解决率',type:'after_sale_solve',leftText:'达标市场',rightText:'未达标市场', unit:'个', operate:'解决率',
    bgIcon:'product-after-sale-solve-bg-icon',
    leftIcon:'task-board-inquiry-dabiao1',
    rightIcon:'task-board-inquiry-dabiao2',
    table:[
      {label:'解决工地数',prop:'finishValue', minWidth:"80"},
      {label:'需解决总数',prop:'targetValue', minWidth:"80"},
      {label:'实际解决率',prop:'finishRate', minWidth:"80"},
    ],
    detailIcon:'product-board-detail-icon2',
  },
  { name:'投诉发生率',type:'complaint_happen', leftText:'达标市场',rightText:'未达标市场', unit:'个', operate:'发生率',
    bgIcon:'product-complaint-happen-bg-icon',
    leftIcon:'task-board-inquiry-dabiao1',
    rightIcon:'task-board-inquiry-dabiao2',
    table:[
      {label:'工地投诉数',prop:'finishValue', minWidth:"80"},
      {label:'工地总数',prop:'targetValue', minWidth:"80"},
      {label:'实际发生率',prop:'finishRate', minWidth:"80"},
    ],
    detailIcon:'product-board-detail-icon4',
  },
  { name:'投诉解决率',type:'complaint_solve',leftText:'达标市场',rightText:'未达标市场', unit:'个', operate:'解决率',
    bgIcon:'product-complaint-solve-bg-icon',
    leftIcon:'task-board-inquiry-dabiao1',
    rightIcon:'task-board-inquiry-dabiao2',
    table:[
      {label:'解决工地数',prop:'finishValue', minWidth:"80"},
      {label:'需解决总数',prop:'targetValue', minWidth:"80"},
      {label:'实际解决率',prop:'finishRate', minWidth:"80"},
    ],
    detailIcon:'product-board-detail-icon2',
  },
]
export const employeeModelFn = (params) => {
  let data = {}
  employeeModel.filter(item => {
    if (item.type == params) {
      data = item
    }
  })
  return data
};

// 物料、家具主管 看板（详情）
let employeeSingleModel = [
  { name:'延期发生率',type:'delay_happen',operate:'发生率',
    bgIcon:'product-delay-happen-bg-icon',
    detailIcon:"product-board-delay-happen-list-icon",
    table:[
      {label:'实际发生率',prop:'finishRate',unit:'%'},
      {label:'工地延期数',prop:'finishValue',unit:'个'},
      {label:'工地总数',prop:'targetValue',unit:'个'},
    ],
  },
  { name:'延期解决率',type:'delay_solve',operate:'解决率',
    bgIcon:'product-delay-solve-bg-icon',
    detailIcon:"product-board-delay-solve-list-icon",
    table:[
      {label:'实际解决率',prop:'finishRate',unit:'%'},
      {label:'解决工地数',prop:'finishValue',unit:'个'},
      {label:'需解决总数',prop:'targetValue',unit:'个'},
    ],
  },
  { name:'在建售后发生率',type:'after_sale_building',operate:'发生率',
    bgIcon:'product-after-sale-building-bg-icon',
    detailIcon:"product-board-after-sale-building-list-icon",
    table:[
      {label:'实际发生率',prop:'finishRate',unit:'%'},
      {label:'工地售后数',prop:'finishValue',unit:'个'},
      {label:'工地总数',prop:'targetValue',unit:'个'},
    ],
  },
  { name:'完工售后发生率',type:'after_sale_finish',operate:'发生率',
    bgIcon:'product-after-sale-finish-bg-icon',
    detailIcon:"product-board-after-sale-finish-list-icon",
    table:[
      {label:'实际发生率',prop:'finishRate',unit:'%'},
      {label:'工地售后数',prop:'finishValue',unit:'个'},
      {label:'工地总数',prop:'targetValue',unit:'个'},
    ],
  },
  { name:'售后解决率',type:'after_sale_solve',operate:'解决率',
    bgIcon:'product-after-sale-solve-bg-icon',
    detailIcon:"product-board-after-sale-solve-list-icon",
    table:[
      {label:'实际解决率',prop:'finishRate',unit:'%'},
      {label:'解决工地数',prop:'finishValue',unit:'个'},
      {label:'需解决总数',prop:'targetValue',unit:'个'},
    ],
  },
  { name:'投诉发生率',type:'complaint_happen',operate:'发生率',
    bgIcon:'product-complaint-happen-bg-icon',
    detailIcon:"product-board-complaint-happen-list-icon",
    table:[
      {label:'实际发生率',prop:'finishRate',unit:'%'},
      {label:'工地投诉数',prop:'finishValue',unit:'个'},
      {label:'工地总数',prop:'targetValue',unit:'个'},
    ],
  },
  { name:'投诉解决率',type:'complaint_solve',operate:'解决率',
    bgIcon:'product-complaint-solve-bg-icon',
    detailIcon:"product-board-complaint-solve-list-icon",
    table:[
      {label:'实际解决率',prop:'finishRate',unit:'%'},
      {label:'解决工地数',prop:'finishValue',unit:'个'},
      {label:'需解决总数',prop:'targetValue',unit:'个'},
    ],
  },
]
export const employeeSingleModelFn = (params) => {
  let data = {}
  employeeSingleModel.filter(item => {
    if (item.type == params) {
      data = item
    }
  })
  return data
};

// 提交上传
let uploadTaskModel = [
  { name:'延期发生率',type:'delay_happen',
    formList:[
      {label:'标准发生率',prop:'standardRate',type:'text',readonly:true},
      {label:'实际发生率',prop:'finishRate',type:'text',unit:'%',readonly:true},
      {label:'工地延期数',prop:'finishValue',type:'digit',class:'interval',requir:true,unit:'个',maxlength:'3'},
      {label:'工地总数',prop:'targetValue',type:'digit',requir:true,unit:'个',maxlength:'5'},
    ],
  },
  { name:'延期解决率',type:'delay_solve',
    formList:[
      {label:'标准解决率',prop:'standardRate',type:'text',readonly:true},
      {label:'实际解决率',prop:'finishRate',type:'text',unit:'%',readonly:true},
      {label:'解决工地数',prop:'finishValue',type:'digit',class:'interval',requir:true,unit:'个',maxlength:'3'},
      {label:'需解决总数',prop:'targetValue',type:'digit',requir:true,unit:'个',maxlength:'3'},
    ],
  },
  { name:'在建售后发生率',type:'after_sale_building',
    formList:[
      {label:'标准发生率',prop:'standardRate',type:'text',readonly:true},
      {label:'实际发生率',prop:'finishRate',type:'text',unit:'%',readonly:true},
      {label:'工地售后数',prop:'finishValue',type:'digit',class:'interval',requir:true,unit:'个',maxlength:'3'},
      {label:'工地总数',prop:'targetValue',type:'digit',requir:true,unit:'个',maxlength:'3'},
    ],
  },
  { name:'完工售后发生率',type:'after_sale_finish',
    formList:[
      {label:'标准发生率',prop:'standardRate',type:'text',readonly:true},
      {label:'实际发生率',prop:'finishRate',type:'text',unit:'%',readonly:true},
      {label:'工地售后数',prop:'finishValue',type:'digit',class:'interval',requir:true,unit:'个',maxlength:'3'},
      {label:'工地总数',prop:'targetValue',type:'digit',requir:true,unit:'个',maxlength:'5'},
    ],
  },
  { name:'售后解决率',type:'after_sale_solve',
    formList:[
      {label:'标准解决率',prop:'standardRate',type:'text',readonly:true},
      {label:'实际解决率',prop:'finishRate',type:'text',unit:'%',readonly:true},
      {label:'解决工地数',prop:'finishValue',type:'digit',class:'interval',requir:true,unit:'个',maxlength:'3'},
      {label:'需解决总数',prop:'targetValue',type:'digit',requir:true,unit:'个',maxlength:'5'},
    ],
  },
  { name:'投诉发生率',type:'complaint_happen',
    formList:[
      {label:'标准发生率',prop:'standardRate',type:'text',readonly:true},
      {label:'实际发生率',prop:'finishRate',type:'text',unit:'%',readonly:true},
      {label:'工地投诉数',prop:'finishValue',type:'digit',class:'interval',requir:true,unit:'个',maxlength:'3'},
      {label:'工地总数',prop:'targetValue',type:'digit',requir:true,unit:'个',maxlength:'5'},
    ],
  },
  { name:'投诉解决率',type:'complaint_solve',
    formList:[
      {label:'标准解决率',prop:'standardRate',type:'text',readonly:true},
      {label:'实际解决率',prop:'finishRate',type:'text',unit:'%',readonly:true},
      {label:'解决工地数',prop:'finishValue',type:'digit',class:'interval',requir:true,unit:'个',maxlength:'3'},
      {label:'需解决总数',prop:'targetValue',type:'digit',requir:true,unit:'个',maxlength:'3'},
    ],
  },
]
export const uploadTaskModelFn = (params) => {
  let data = {}
  uploadTaskModel.filter(item => {
    if (item.type == params) {
      data = item
    }
  })
  return data
};

// 审批详情
let examineDetailModel = [
  { name:'延期发生率',type:'delay_happen',
    table:[
      {label:'任务项',prop:'termName',},
      {label:'标准发生率',prop:'standardRate',},
      {label:'实际发生率',prop:'finishRate',unit:'%'},
      {label:'工地延期数',prop:'finishValue',unit:'个'},
      {label:'工地总数',prop:'targetValue',unit:'个'},
    ],
  },
  { name:'延期解决率',type:'delay_solve',
    table:[
      {label:'任务项',prop:'termName',},
      {label:'标准解决率',prop:'standardRate',},
      {label:'实际解决率',prop:'finishRate',unit:'%'},
      {label:'解决工地数',prop:'finishValue',unit:'个'},
      {label:'需解决总数',prop:'targetValue',unit:'个'},
    ],
  },
  { name:'在建售后发生率',type:'after_sale_building',
    table:[
      {label:'任务项',prop:'termName',},
      {label:'标准发生率',prop:'standardRate',},
      {label:'实际发生率',prop:'finishRate',unit:'%'},
      {label:'工地售后数',prop:'finishValue',unit:'个'},
      {label:'工地总数',prop:'targetValue',unit:'个'},
    ],
  },
  { name:'完工售后发生率',type:'after_sale_finish',
    table:[
      {label:'任务项',prop:'termName',},
      {label:'标准发生率',prop:'standardRate',},
      {label:'实际发生率',prop:'finishRate',unit:'%'},
      {label:'工地售后数',prop:'finishValue',unit:'个'},
      {label:'工地总数',prop:'targetValue',unit:'个'},
    ],
  },
  { name:'售后解决率',type:'after_sale_solve',
    table:[
      {label:'任务项',prop:'termName',},
      {label:'标准解决率',prop:'standardRate',},
      {label:'实际解决率',prop:'finishRate',unit:'%'},
      {label:'解决工地数',prop:'finishValue',unit:'个'},
      {label:'需解决总数',prop:'targetValue',unit:'个'},
    ],
  },
  { name:'投诉发生率',type:'complaint_happen',
    table:[
      {label:'任务项',prop:'termName',},
      {label:'标准发生率',prop:'standardRate',},
      {label:'实际发生率',prop:'finishRate',unit:'%'},
      {label:'工地投诉数',prop:'finishValue',unit:'个'},
      {label:'工地总数',prop:'targetValue',unit:'个'},
    ],
  },
  { name:'投诉解决率',type:'complaint_solve',
    table:[
      {label:'任务项',prop:'termName',},
      {label:'标准解决率',prop:'standardRate',},
      {label:'实际解决率',prop:'finishRate',unit:'%'},
      {label:'解决工地数',prop:'finishValue',unit:'个'},
      {label:'需解决总数',prop:'targetValue',unit:'个'},
    ],
  },
]
export const examineDetailModelFn = (params) => {
  let data = {}
  examineDetailModel.filter(item => {
    if (item.type == params) {
      data = item
    }
  })
  return data
};