<template>
    <div v-loading="pageloading" v-if="pageloading" style="height: 20vh;" element-loading-text="加载中..."></div>
    <div v-else>
    <div v-if="!isError" class="myClientDetails">
        <headerNav :leftNav="isDirectRoute ? '' : '返回'" @leftClick="back" title="客户详情" />
        <div class="top-info">
            <div class="top_div">
                <h1>{{ clientInfo.consumerName?clientInfo.consumerName:'未命名' }} 
                <span class="span-text" :style="clientInfo.intentCode == 'ITEM101346' ? 'color:#4F7AFD' : 'color:#F59A23'">{{ clientInfo.intentCodeName }}</span>
            </h1>
            <van-tag plain type="primary" class="tag_top" v-if="!isClaimCustomer">已分配</van-tag>
            </div>
            <div class="phone_info">
                <span style="width:45%">客户电话：{{ clientInfo.phoneNo }}</span>
                <span style="width:50%" v-if="clientInfo.signTime">签单时间：{{ clientInfo.signTime }}</span> 
                <span style="width:50%" v-else>接单时间：{{ clientInfo.allocationTimeSign }}</span>
            </div>
            <div class="qiandan" v-if="!isClaimCustomer">
                <span class="one_span">签单手：{{ clientInfo.signName }}</span>
                <span></span>
            </div>
        </div>
        <van-tabs v-model="tabActive" swipeable title-active-color="#4F7AFD" class="tab-style" >
            <van-tab title="基础信息" forceRender >
                <div :class="objData.allocationStatusSignFlag != '1'?'tab-content':'tab-content1'">
                    <clientData ref="clientData" v-if="objData.allocationStatusSignFlag != '1'" :disabledStatus="objData.allocationStatusSignFlag"/>
                    <div class="absence" v-else>
                        <img :src="require('@/assets/absenceError.png')" alt="">
                        <p>暂无权限</p>
                    </div>
                </div>
            </van-tab>
            <van-tab title="跟进信息" :disabled="objData.allocationStatusSignFlag != '2' && routerType == 'sign'" forceRender >
                <div class="tab-content">
                    <followList ref="followList"/>
                </div>
            </van-tab>
            <van-tab title="图片历史" :disabled="objData.allocationStatusSignFlag != '2' && routerType == 'sign'" forceRender >
                <div class="tab-content">
                    <photosList ref="photosList"/>
                </div>
            </van-tab>
            <van-tab title="录音记录" :disabled="objData.allocationStatusSignFlag != '2' && routerType == 'sign'" forceRender >
                <div class="tab-content">
                    <recordList ref="recordList"/>
                </div>
            </van-tab>
            <van-tab title="联系人列表" :disabled="objData.allocationStatusSignFlag != '2' && routerType == 'sign'" forceRender >
                <div class="tab-content">
                    <linkmanList ref="linkmanList"/>
                </div>
            </van-tab>
        </van-tabs>
        <div class="bottom" v-if="objData.allocationStatusSignFlag == '2' || !routerType">
            <div v-if="!clientInfo.signTime && !clientInfo.singTime" @click="signBill()">签单</div>
            <div v-if="!clientInfo.signTime && !clientInfo.singTime" @click="loseBill()">丢单</div>
            <div @click="follow()">跟进</div>
        </div>
        <div class="bottom" v-if="routerType == 'sign' && objData.allocationStatusSignFlag == '0'">
            <van-button type="info" color="#4871C0" class="btn_loading" :loading="getLoading" loading-text="领取中..." @click="handleClaimcust">领取客户</van-button>
        </div>
        <van-dialog v-model="billDialog" show-cancel-button :before-close="dialogSubmit" confirmButtonColor="#1989fa">
            <template #title>
                <div v-if="modalType == 'lose'" class="dialog-title">丢单原因</div>
                <div v-if="modalType == 'follow'" class="dialog-title">跟进信息</div>
                <div v-if="modalType == 'sign'">签单</div>
                <div v-if="modalType == 'sign1'">签单</div>
            </template>
            <div style="padding: 10px 20px;">
                <van-field v-if="modalType == 'lose'" v-model="singCase" rows="2" autosize type="textarea" required
                    maxlength="120" placeholder="​请输入丢单原因" show-word-limit />
                <van-field v-if="modalType == 'follow'" v-model="followRemark" rows="2" autosize type="textarea" required
                    maxlength="10" placeholder="​请输入跟进信息" show-word-limit />
                <van-field v-if="modalType == 'sign'" v-model="earnest" class="input-style" colon type="number" label="定金"
                    clearable label-width="40" @input="xiaoshu" style="background-color: #fff;" maxlength="11" />
            </div>
        </van-dialog>
        <!-- 显示未完成跟进的客户信息 -->
        <van-dialog v-model="historyCusotmerShow" class="signModal" show-cancel-button confirmButtonText="完成跟单" confirmButtonColor="#4871C0" cancelButtonText="跟进" @confirm="handleHistoryOK" @cancel="flowUpInfo">
            <div class="content blue_background">
                <div class="top_main">
                    <img :src="require('@/assets/bluetip.png')" alt="">
                    <span>提示</span>
                </div>
                <p class="end_p">您当前有未完成的订单，不能领取新的客户</p>
            </div>
            <div class="center_main">
                <p>{{ consumerName }}</p>
                <div>
                    <span class="one_span top">手机号</span>
                    <span>{{ phoneNo }}</span>
                </div>
                <div>
                    <span class="one_span">接单时间</span>
                    <span>{{ allocationTimeSign }}</span>
                </div>
            </div>
        </van-dialog>
        <!-- 判断是否分配 -->
        <van-dialog v-model="signShow" class="signModal" :overlay="false" :show-cancel-button="false" :show-confirm-button="false"	>
            <div class="content" :class="!customerStatus?'blue_background':'green_background'">
                <div class="top_main">
                    <img :src="!customerStatus?require('@/assets/bluetip.png'):require('@/assets/greentip.png')" alt="">
                    <span>提示</span>
                </div>
                <p style="margin-bottom:40px">{{!customerStatus?'该用户已分配':'成功领取'}}</p>
                <!-- <img class="close_img" @click="handlecloseTip2" :src="require('@/assets/closeModal.png')" alt=""> -->
            </div>
        </van-dialog>
    </div>
    <div v-else class="client-detail">
        <headerNav :leftNav="isDirectRoute ? '' : '返回'" @leftClick="back" title="客户详情" />
        <img :src="require('@/assets/icon19.png')" class="empty-style" />
        <div class="empty-text">该客户为<span>【{{ errorText }}】</span>市场客户</div>
    </div>
    <img :src="require('@/assets/Uploadrecording.svg')" class="add" @click="addAudioPrent" />    
    </div>
</template>

<script>
import Uploadrecording from '@/assets/Uploadrecording.svg'
import api from '../../../api/api.js'
import clientData from './clientData.vue'
import followList from './followList.vue'
import photosList from './photosList.vue'
import linkmanList from './linkmanList.vue'
import recordList from './recordList.vue'
import {
    Toast, Dialog,Notify
} from 'vant';
export default {
    created() {
        this.id = this.$route.params.id
        this.routerType = this.$route.params.type
        this.isDirectRoute = this.$route.query.isDirectRoute
        this.getClientInfo()
    },
    data() {
        return {
            id: '',//客户id
            intentCode: '',
            intentCodeName: '',
            allocationTimeSign: '',//接单时间
            flag: 0,// 判断是否是夜间签单的详情
            clientInfo: {},
            tabActive: 0,
            modalType: 'sign',//sign签单 lose丢单
            billDialog: false,
            singCase: '',//丢单原因
            earnest: '',//定金
            followRemark: '',//跟进信息
            // 历史客户id
            historyId:'',
            // 历史重复w未完成客户信息弹窗
            historyCusotmerShow:false,
            // 领取客户
            signShow:false,
            // 客户是否已分配
            customerStatus:false,
            // 判断是否已经领取客户
            isClaimCustomer:false,
            // 未完成跟单客户信息
            consumerName:'',
            phoneNo:'',
            routerType:null,
            // 领取loading
            getLoading:false,
            isError: false,
            errorText: '',
            objData:{},
            isDirectRoute:false,//true直接跳转false间接跳转（从客户列表跳转）
            pageloading: true
        }
    },
    components: {
        followList,
        photosList,
        linkmanList,
        recordList,
        clientData,
    },
    methods: {
        //返回首页
        back() {
            this.$router.push({
                name: "clientSignBillListName",
            })
        },
        addAudioPrent(){
            this.tabActive = 3
            setTimeout(()=>{
                this.$refs.recordList.addAudio()    
            },200)
        },
        //获取客户信息
        async getClientInfo () {
            // 扫码跳转
            if( this.routerType && this.routerType == 'sign'){
                // allocationStatusSignFlag  0 可领取该客户 1 已分配签单 2已领取该客户
                // 判断是否有未跟进完成的客户
                // 判断该客户是否已经分配
                let {data} = await api.getScanInfomation(this.id)
                if(data.marketFlag){
                    this.isError = true
                    this.errorText = data.marketName
                    setTimeout(() => {
                        this.pageloading = false
                    }, 500);
                    return
                }
                this.objData = data
                // 可以领取该客户
                if(data.allocationStatusSignFlag == '0'){
                    this.intentCode = data.infoModel.intentCode
                    this.intentCodeName = data.infoModel.intentCodeName
                    this.allocationTimeSign = data.infoModel.allocationTimeSign
                    this.clientInfo = data.infoModel
                    // 判断是否有未完成跟单的
                    if(data.isHistory){
                        this.historyId = data.historyModel.id
                        this.consumerName = data.historyModel.consumerName
                        this.phoneNo = data.historyModel.phoneNo
                        this.allocationTimeSign = data.historyModel.allocationTimeSign
                        this.historyCusotmerShow = true
                    }

                }else if(data.allocationStatusSignFlag == '1'){
                // 已经分配签单手了，不能领取客户了
                    this.intentCodeName = data.intentCodeName
                    this.allocationTimeSign = data.allocationTimeSign
                    this.consumerName = data.consumerName
                    this.phoneNo = data.phoneNo
                    this.customerStatus = false
                    this.isClaimCustomer = false
                    this.clientInfo = data
                    this.signShow = true
                    setTimeout(()=>{
                        this.signShow = false
                    },2000)
                }else{
                // 第二次扫码进入，已经领取客户了，就展示当前客户的信息
                    this.clientInfo = data
                    // this.customerStatus = false
                    this.isClaimCustomer = false
                }
                setTimeout(() => {
                    this.pageloading = false
                }, 500);
            }else{
                let res = await api.getClientInfo(this.id)
                setTimeout(() => {
                    this.pageloading = false
                }, 500);
                if (res.code == 200) {
                    this.clientInfo = res.data
                }
            }
        },
        /* 继续跟进历史客户 */
        async flowUpInfo(){
            let {data} = await api.getScanInfomation(this.historyId)
            this.intentCode = data.intentCode
            this.intentCodeName = data.intentCodeName
            this.allocationTimeSign = data.allocationTimeSign
            this.clientInfo = data
            this.objData = data
            this.historyCusotmerShow = false
            this.$set(this.objData,allocationStatusSignFlag,data.allocationStatusSignFlag)
        },
        // 完成跟单
        async handleHistoryOK(){
            let { data } = await api.documentaried(this.historyId)
            this.getClientInfo()
            this.historyCusotmerShow = false
        },
        // 领取客户
        async handleClaimcust(){
            this.getLoading = true
            let { data } = await api.claimcust(this.id)
            this.getClientInfo()
            this.customerStatus = true
            this.getLoading = false
            this.signShow = true
            this.objData = data
            setTimeout(()=>{
                this.signShow = false
            },2000)
        },
        // 领取成功弹窗关闭
        handlecloseTip(){
            this.historyCusotmerShow = false
        },
        // 重复客户弹窗关闭
        handlecloseTip2(){
            this.signShow = false
        },
        //丢单
        loseBill() {
            this.modalType = 'lose'
            this.singCase = ''
            this.billDialog = true
        },
        //签单
        signBill() {
            this.modalType = 'sign'
            this.billDialog = true
        },
        //夜间签单
        nigntSignBill() {
            this.modalType = 'sign1'
            this.billDialog = true
        },
        //跟进
        follow() {
            this.followRemark = ''
            this.modalType = 'follow'
            this.billDialog = true
        },
        //签单/丢单/跟进提交
        async dialogSubmit(action, done) {
            if (action === 'confirm') { // 确认
                //丢单
                if (this.modalType == 'lose') {
                    if (!this.singCase) {
                        Toast('请输入丢单原因!');
                        done(false)
                        return
                    }
                    let obj = {
                        consumerId: this.id,//客户id
                        singCase: this.singCase,//丢单原因
                    }
                    let res = await api.loseBill(obj)
                    if (res.code == 200) {
                        Toast.success('丢单成功!');
                        this.getClientInfo()
                    }
                }
                //签单
                if (this.modalType == 'sign') {
                    if (!this.earnest) {
                        Toast('请输入定金!');
                        done(false)
                        return
                    }
                    let obj = {
                        consumerId: this.clientInfo.id,//客户id
                        reserveAmount: this.earnest,//定金
                    }
                    let res = await api.signBill(obj)
                    if (res.code == 200) {
                        Toast.success('签单成功!');
                        this.getClientInfo()
                    }
                }
                //签单
                if (this.modalType == 'sign1') {
                    if (!this.earnest) {
                        Toast('请输入定金!');
                        done(false)
                        return
                    }
                    let obj = {
                        consumerId: this.clientInfo.id,//客户id
                        reserveAmount: this.earnest,//定金
                    }
                    let res = await api.signBill1(obj)
                    if (res.code == 200) {
                        Toast.success('签单成功!');
                        this.getClientInfo()
                    }
                }
                //跟进
                if (this.modalType == 'follow') {
                    if(!this.followRemark){
                        Notify('请输入跟进信息');
                        done(false)
                        return
                    }
                    let obj = {
                        consumerId: this.clientInfo.id,
                        clueId: this.clientInfo.clueId,
                        advanceRemark: this.followRemark,
                        typeCode: 2
                    }
                    let res = await api.followAdd(obj)
                    if (res.code == 200) {
                        Toast.success('提交成功!');
                        if (this.tabActive == 1) {
                            this.$refs.followList.onRefresh()
                        }
                    }
                }
                done();
                this.onRefresh()
            } else if (action === 'cancel') { // 取消
                done(); // 关闭提示框
            }

        },
        xiaoshu(value) {
            value = value.replace(/[^\d.]/g, "");  //清除“数字”和“.”以外的字符
            value = value.replace(/^\./g, "");  //必须保证第一个为数字而不是.
            value = value.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
            value = value.replace(".", "$#$").replace(/\./g, "").replace("$#$", "."); //不能重复出现点
            value = value.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');//只能输入两个小数
            if (value.indexOf(".") < 0 && value != "") {//以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
                value = parseFloat(value);
            }
            if (value > 99999999.99) value = 99999999.99
            this.earnest = value
        },
    },
}
</script>

<style scoped lang="scss">
.client-detail{
    .empty-style {
        width: 176px;
        margin-top: 50%;
    }
    .empty-text {
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #BFBFBF;
        margin-top: 25px;
        span{
            color: #6099F8;
        }
    }
}
.myClientDetails {
    background-color: #F5F6F8;
    height: 100vh;
    box-sizing: border-box;
    overflow: hidden;
    .top-info {
        .phone_info{
            display: flex;
            width: 100%;
            margin-left: 15px;
            span{
                text-align: left;
                flex: none;
            }
        }
        h1 {
            line-height: 60px;
            margin: 0;

            .span-text {
                font-size: 10px;
                font-weight: 400;
            }
        }

        background: #FFFFFF;
        height: 110px;

        div {
            display: flex;

            span {
                flex: 1;
            }
        }
        .top_div{
            display: flex;
            margin-left: 36%;
            align-items: center;
            .tag_top{
                margin-left: auto;
                flex: none;
                height: 20px;
                margin-right: 25px;
            }
        }
        
        .qiandan{
            margin: 5px 0px 0px 15px;
            span{
                width: 50%;
            }
            .one_span{
                text-align: left;
                flex: none;
            }
        }
    }

    .tab-content {
        height: calc(100vh - 265px);
        overflow: auto;
        // background-color: #FFFFFF;
    }
    .tab-content1{
        overflow: hidden;
    }
    .absence{
        width: 200px;
        height: 139px;
        margin:150px auto;
        text-align: center;
        img{
            width: 100%;
        }
        p{
            font-size: 11px;
            color: #999999;
        }
    }
    .bottom {
        height: 70px;
        position: fixed;
        bottom: 0;
        display: flex;
        width: 100%;
        background-color: #FFFFFF;
        justify-content: center;
        align-items: center;
        div {
            flex: 1;
            font-size: 15px;
            height: 100%;
            line-height: 70px;
        }
        .btn_loading div{
            line-height: 35px;
        }
    }

}
.signModal{
    border-radius: 10px;
    overflow: hidden;
    width: 70%;
    box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.05),0 0 6px rgba(0,0,0,0.05), 0 0 6px rgba(0,0,0,0.05);
    .content{
        width: 100%;
        border-radius: 10px;
        background-repeat: no-repeat;
        background-size:cover;
        .close_img{
            width: 31px;
            height: 31px;
            position: absolute;
            bottom: -70px;
            left: 45%;
        }
        .top_main{
            display: flex;
            align-items: center;
            padding: 20px 0px;
            width:100px;
            margin: 0 auto;
            span{
                color: #333333;
                font-size: 16px;
            }
            img{
                width: 31px;
                height: 31px;
                margin-right: 20px;
            }
        }
        p{
            color: #999999;
        }
        .end_p{
            font-size: 14px;
            margin: 0px 20px;
        }
    }
    .center_main{
        margin: 20px auto;
        width: 200px;
        // height: 50px;
        background: #F5F6F8;
        padding: 20px 10px 20px 20px;
        text-align: left;
        p{
            font-size: 24px;
            color: #000000;
            font-weight: bold;
            margin: 0px 0px 8px 0px;
        }
        div{
            font-size: 14px;
            margin: 10px 0px;
        }
        .one_span{
            color: #999999;
            margin-right: 10px;
        }
        .top{
            margin-right: 24px;
        }
    }
    .blue_background{
        background-image: url('../../../assets/blueback.png');
    }
    .green_background{
        background-image: url('../../../assets/greenback.png');
    }
}
.add {
    position: fixed;
    bottom: 136px;
    right: 27px;
    z-index: 10;
    width: 50px;
    height: 50px;
}
</style>
<style lang="scss">
.myClientDetails {
    .van-tab {
        padding: 0;
        font-size: 13px;
    }

    .van-tabs__line {
        background: #4F7AFD;
    }

    .van-pull-refresh__track {
        padding-bottom: 10px;
    }

    .van-field {
        background: #f2f3f5;
    }

    .input-style {
        .van-cell__value {
            border: 1px solid #e5e7eb;
            border-radius: 5px;

            input {
                padding-left: 10px;
            }
        }
    }
}
</style>
