<template>
	<div class="selectTime-style">
		<van-popup v-model="showPicker" round position="bottom" @click-overlay="onCancel">
			<div class="title-top">
				<div style="padding: 8px;" @click="onCancel">取消</div>
				<div class="tt-center">
					<div>{{ selctDateS }}</div>
					<div>{{ selectTimeS }}</div>
				</div>
				<div style="padding: 8px;" @click="onConfirm">确认</div>
			</div>
			<div class="empty"></div>
			<div class="title">
				<img src="@/assets/date1.png" alt="" style="width: 16px;">
				<span>选择日期</span>
			</div>
			<van-datetime-picker :visible-item-count="5" :item-height="40" :show-toolbar="false" type="date"
				:min-date="minDate" ref="dateRef" />
			<div class="empty"></div>
			<div class="title">
				<img src="@/assets/time1.png" alt="" style="width: 16px;">
				<span>选择时间</span>
			</div>
			<van-picker :visible-item-count="5" :item-height="40" :columns="columns2" ref="timeRef"></van-picker>
		</van-popup>
	</div>
</template>

<script>
import moment from 'moment'
export default {
	props: {
		timeShow: {
			type: [Boolean],
			default: false
		}
	},
	data() {
		return {
			showPicker: false,
			minDate: null,
			columns2: [
				{
					values: [],
					defaultIndex: 0,
				},
				{
					values: [],
					defaultIndex: 0,
				},
				{
					values: [],
					defaultIndex: 0,
				},
			],
			selctDateS: '',
			selectTimeS: '',
			selctDate: '',
			selectTime: '',
		}
	},
	watch: {
		timeShow(newVal) {
			this.showPicker = newVal;
			if (newVal && !this.selctDate.length) {
				this.onSetInitTime()
				this.selctDateS = moment().format('YYYY年MM月DD日')
				this.selectTimeS = moment().format('HH点mm分ss秒')
			}
		},
		selctDate: {
			handler: function (newVal) {
				const date = newVal.length ? newVal : moment().format('YYYY-MM-DD')
				const [y, m, d] = date.split('-')
				this.selctDateS = `${y}年${m}月${d}日`
			},
			immediate: true
		},
		selectTime: {
			handler: function (newVal) {
				const time = newVal.length ? newVal : moment().format('HH:mm:ss')
				const [h, m, s] = time.split(':')
				this.selectTimeS = `${this.onTimeFormat(h)}点${this.onTimeFormat(m)}分${this.onTimeFormat(s)}秒`
			},
			immediate: true
		},
	},
	created() {
		this.onInit()
	},
	methods: {
		onInit() {
			const year = new Date().getFullYear()
			const month = new Date().getMonth()
			this.minDate = new Date(year, month, 1)
			this.columns2[0].values = this.handleFormat(24, '时')
			this.columns2[1].values = this.handleFormat(60, '分')
			this.columns2[2].values = this.handleFormat(60, '秒')
		},
		handleFormat(num, unit) {
			let arr = []
			for (let i = 0; i < num; i++) {
				const str = i <= 9 ? `0${i}${unit}` : `${i}${unit}`
				arr.push(str)
			}
			return arr
		},
		onSetInitTime() {
			const hour = moment().hour()
			const minute = moment().minute()
			const second = moment().second()
			this.columns2[0].defaultIndex = hour
			this.columns2[1].defaultIndex = minute
			this.columns2[2].defaultIndex = second
		},
		onTimeFormat(val) {
			return `${val}`.length > 1 ? val : `0${val}`
		},
		onConfirm() {
			this.showPicker = false
			this.$emit('update:timeShow', false)
			this.selctDate = this.$refs.dateRef.getPicker().getValues().join('-')
			this.selectTime = this.$refs.timeRef.getIndexes().map(item => {
				return this.onTimeFormat(item)
			}).join(':')
			this.$emit('confirm', this.selctDate + ' ' + this.selectTime)
		},
		onCancel() {
			this.showPicker = false
			this.$emit('update:timeShow', false)
		}
	},
}
</script>

<style scoped lang="scss">
.title-top {
	padding: 12px 8px;
	height: 40px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 14px;

	.tt-center {
		display: flex;
		flex-direction: column;
		font-size: 18px;
	}
}

.empty {
	height: 16px;
	background-color: #f2f2f2;
}

.title {
	font-size: 16px;
	padding: 10px 16px;
	display: flex;
	align-items: center;

	img {
		margin-right: 4px;
	}
}
</style>
