<template>
  <div class="content">
    <headerNav
      leftNav=" "
      :title="navTitle"
      @leftClick="back"
    ></headerNav>
    <main class="content-main">
      <van-form @submit="onSubmitTaskSet" :key="fromRestFields">
        <van-field
          readonly
          disabled
          name="taskDate"
          :value="taskSetList.taskDate"
          label=" 时间"
          placeholder="请选择"
          class="from-item"
          @focus="forbid"
        >
          <i slot="left-icon" style="visibility: hidden;">*</i>
        </van-field>
        <div v-for="(item,index) in dataList" :key="index">
          <div v-if="item.termId == 'transfer_output'||item.termId == 'supply_output'">
            <div class="task—allocation">
              <div class="task—allocation-title">
                <div></div><span>{{item.termName}}</span>
              </div>
              <div class="task—allocation-describe">上级指派任务：未分配 <span>{{ undistributed }}</span> 万元; 已分配 <span>{{ assigned1 }}</span>万元</div>
            </div>
            <van-field v-for="(item1,index1) in item.nodeList" :key="index1"
              v-model="item1.productionJobValue"
              :name="item1.departmentId"
              type="digit"
              maxlength="6"
              :label="item1.departmentName"
              placeholder="请输入"
              :rules="[{ required: true, message: '' }]"
              @input="changeAssignedFn(item)"
            >
              <i slot="left-icon" style="color: red">*</i>
              <template #button>万元</template>
            </van-field>
          </div>
          <div class="from-item1" v-else-if="item.termId == 'transfer_count' ||item.termId == 'supply_count'">
            <div class="task—allocation">
              <div class="task—allocation-title">
                <div></div><span>{{item.termName}}</span>
              </div>
              <div class="task—allocation-describe">已分配任务 <span>{{ assigned2 }}</span>个</div>
            </div>
            <van-field v-for="(item2,index2) in item.nodeList" :key="index2"
              v-model="item2.productionJobValue"
              :name="item2.departmentId"
              type="digit"
              maxlength="5"
              :label="item2.departmentName"
              placeholder="请输入"
              :rules="[{ required: true, message: '' }]"
              @input="changeAssignedFn(item)"
            >
              <i slot="left-icon" style="color: red">*</i>
              <template #button>个</template>
            </van-field>
          </div>
          <van-field v-else
            v-model="item.productionJobValue"
            :name="item.termId"
            type="digit"
            maxlength="5"
            :label="item.termName"
            placeholder="请输入"
            :rules="[{ required: true, message: '' }]"
            class="from-item"
          >
            <i slot="left-icon" style="color: red">*</i>
            <template #button>个</template>
          </van-field>
        </div>
        
        <div class="task-button">
          <span class="task-button-tip">*提示：提交后需上级进行审批，审批通过任务生效</span>
          <div class="my-task-modification-popup-button">
            <van-button native-type="button" @click="back">取消</van-button>
            <van-button native-type="submit">确认</van-button>
          </div>
        </div>
      </van-form>

    </main>

    <!-- 日期弹窗 -->
    <van-popup
      v-model="datePopupShow"
      class="my-date-popup"
      overlay-class="my-popup-cover"
      position="bottom"
      :safe-area-inset-bottom="true"
      :lazy-render="false"
    >
      <div class="my-date-popup-box">
        <div class="my-date-popup-content">
          <van-picker
            show-toolbar
            title="选择日期"
            :columns="columns"
            toolbar-position="top"
            ref="myPicker"
            style="height: 100%"
            @confirm="dateSureSearch"
            @cancel="dateRestSearch"
          >
          </van-picker>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import moment from "moment";
import { List, Toast } from 'vant';
import api from "@/api/task-management/setup.js";
export default {
  components: {},
  data() {
    return {
      navTitle:'下级任务设置',
      datePopupShow: false,
      year: moment().year(),
      month: moment().month() + 1,
      columns: [
        { values: [], defaultIndex: moment().year() },
        { values: [], defaultIndex: moment().month() + 1 },
      ],
      queryInfo:{},
      fromRestFields: 0,
      taskSetList: {
        id:"",
        roleCode:"",
        taskDate: "",
      },
      undistributed:0,
      undistributedTransition:0,
      assigned1:0,
      assigned2:0,
      dataList:[],
    };
  },
  created() {
    this.setDateColumns()
  },
  mounted() {
    let _this = this;
    this.queryInfo = JSON.parse(this.$route.query.item)
    let {taskDate,productionJobValue,id,roleCode} = this.queryInfo
    this.taskSetList = {
      ...this.taskSetList,
      id,
      roleCode,
      taskDate,
    }
    this.undistributed = productionJobValue
    this.undistributedTransition = productionJobValue
    this.getTaskValue(id)
  },
  methods: {
    // 路由返回
    back() {
      this.$router.go(-1);
    },
    //初始化日期选择范围
    setDateColumns() {
      let y = [];
      let nowYear = moment().year() + 2;
      for (let i = nowYear; i > nowYear - 21; i--) {
        y.push(i);
      }
      let m = [];
      for (let i = 1; i < 13; i++) {
        i = i < 10 ? "0" + i : i.toString()
        m.push(i);
      }
      this.columns[0].values = y;
      this.columns[1].values = m;
    },
    //打开日期选择
    openChooseDate(type) {
      this.year = this.taskSetList.taskDate ? this.taskSetList.taskDate.split('-')[0] : moment().year()
      this.month = this.taskSetList.taskDate ? this.taskSetList.taskDate.split('-')[1] : (moment().month() + 1 ).toString()
      this.$refs.myPicker && this.$refs.myPicker.setValues([Number(this.year), this.month]);
      this.datePopupShow = true;
    },
    // 日期弹窗 取消
    dateRestSearch() {
      this.year = moment().year();
      this.month = moment().month() + 1 ;
      this.datePopupShow = false;
    },
    // 日期弹窗 确认
    dateSureSearch() {
      this.year = this.$refs.myPicker.getValues()[0];
      this.month = this.$refs.myPicker.getValues()[1];
      let date = this.year + "-" + this.month;
      this.taskSetList.taskDate = date
      //确认
      this.datePopupShow = false;
    },
    // 禁止弹出软键盘
    forbid(){
      document.activeElement.blur()
    },
    // 查询子级任务列表
    getTaskValue(id){
      let params = {
        taskParentId:id,
        taskType:"D",
        roleCode:this.queryInfo.roleCode
      }
      api.getTaskMainById(params).then((res)=>{
        if (res.code == 200) {
          res.data && res.data.forEach((item)=>{
            if(item.termId == 'transfer_output'||item.termId == 'supply_output'){
              this.changeAssignedFn(item)
            }
            if(item.termId == 'transfer_count' ||item.termId == 'supply_count'){
              this.assigned2 = item.productionJobValue
              this.changeAssignedFn(item)
            }
            item.productionJobValue = item.productionJobValue == 0?"":item.productionJobValue
            item.nodeList&&item.nodeList.forEach((node)=>{
              node.productionJobValue = node.productionJobValue == 0?"":node.productionJobValue
            })
          })
          this.dataList = res.data
        }else{
          Toast.fail(res.msg)
        }
      })
    },
    // 计算已分配
    changeAssignedFn(item){
      if(item.termId == 'transfer_output'||item.termId == 'supply_output'){
        this.assigned1 = item.nodeList.reduce((acc, obj) =>acc + Number(obj.productionJobValue||0), 0);
        this.undistributed =this.undistributedTransition - this.assigned1;
        if(this.undistributed <= 0){
          this.undistributed = 0
        }
      }else if(item.termId == 'transfer_count' ||item.termId == 'supply_count'){
        this.assigned2 = item.nodeList.reduce((acc, obj) =>acc + Number(obj.productionJobValue||0), 0);
      }
    },
    // ---- form表单 提交---------
    onSubmitTaskSet(values) {
      if(this.undistributed > 0){
        Toast.fail('任务未分配完，请重新分配');
        return false
      }
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      let multiTerm = {}
      let multiTerm2 = {}
      this.dataList.forEach((item)=>{
        multiTerm[item.termId]=item.productionJobValue.toString()
        if(item.termId == 'transfer_output'||item.termId == 'supply_output'){
          multiTerm2[item.termId] = {}
          item.nodeList.forEach((node)=>{
            multiTerm2[item.termId][node.departmentId]=node.productionJobValue.toString()
          })
          multiTerm[item.termId] = this.assigned1.toString()
          multiTerm2[item.termId] = JSON.stringify(multiTerm2[item.termId])
        }
        if(item.termId == 'transfer_count' ||item.termId == 'supply_count'){
          multiTerm2[item.termId] = {}
          item.nodeList.forEach((node)=>{
            multiTerm2[item.termId][node.departmentId]=node.productionJobValue.toString()
          })
          multiTerm[item.termId] = this.assigned2.toString()
          multiTerm2[item.termId] = JSON.stringify(multiTerm2[item.termId])
        }
      })
      this.taskSetList = {
        ...this.taskSetList,
        multiTerm:JSON.stringify(multiTerm),
        multiTerm2:JSON.stringify(multiTerm2),
      }
      api.setTasks(this.taskSetList).then((res)=>{
        Toast.clear()
        if (res.code == 200) {
          Toast.success('操作成功');
          let _this = this;
          setTimeout(()=>{
            _this.back()
          },1000)
        }else{
          Toast.fail(res.msg)
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.content ::v-deep {
  height: 100vh;
  overflow: auto;
  background: #f3f5f7;
  .van-form {
    height: calc(100vh - 165px);
    overflow: auto;
    .from-item {
      border-bottom: 8px solid #f3f5f7;
      .van-field__label{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .from-item1{
      margin-bottom: 10px;
    }
  }

  .task-button{
    width: 100%;
    padding: 10px 18px 30px;
    position: fixed;
    bottom: 0;
    background: #FFFFFF;
    box-shadow: 0px -1px 8px 0px rgba(0,0,0,0.04);
    box-sizing: border-box;
    .task-button-tip{
      font-size: 12px;
      color: #999999;
      font-family: PingFang SC-Medium, PingFang SC;
      text-align: center;
    }
  }

  .my-task-modification-popup-button{
    margin-top: 10px;
    display: flex;
    gap: 12px;
    .van-button{
      width: 50%;
      height: 50px;
      font-size: 18px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      
      &:nth-child(2){
        color: #FFFFFF;
        background: #4871C0;
        border-radius: 4px 4px 4px 4px;
      }
    }
  }

  .task-prompt{
    height: 24px;
    font-size: 12px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    text-align: center;
    color: #4871C0;
  }

  .task—allocation{
    margin:8px 0 8px 14px;
    .task—allocation-title{
      line-height: 24px;
      font-size: 16px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
      text-align: left;
      display: flex;
      align-items: center;
      & > div{
        width: 4px;
        height: 17px;
        background: #0E8BFF;
        border-radius: 18px 18px 18px 18px;
        margin-right: 6px;
      }

    }
    .task—allocation-describe{
      line-height: 24px;
      font-size: 12px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #999999;
      text-align: left;
      margin-left: 10px;
      & > span{
        color: #4871C0;
      }
    }
  }
}
</style>
