<template>
  <div class="construction-style">
    <div class="search-style">
      <van-field class="choosetime-input" v-model="searchValue" @blur="onRefresh()" @clear="onClear" clearable
        placeholder="输入客户姓名/联系方式搜索" center>
        <template v-slot:right-icon>
          <div style="height: 100%; display: flex; align-items: center">
            <img src="../../../assets/search.png" class="search-icon" alt="" />
          </div>
        </template>
      </van-field>
    </div>
    <FilterModel :filterList="filterCondition" @filterFunction="onRefresh" ref="filterModel" />
    <div class="content-style">
      <pullRefresh ref="pullRefresh" @refresh="onRefresh">
        <van-list class="vant-list-style" v-model="loading" :finished="finished" @load="onLoad">
          <template #loading>
            <div v-if="total">·加载中·</div>
            <div class="show-img" v-else>
              <img :src="require('@/assets/subNull.png')" alt="" width="50%">
              <div>·加载中·</div>
            </div>
          </template>
          <template #finished>
            <div v-if="total"> ·已加载全部数据· </div>
            <div class="show-img" v-else>
              <img :src="require('@/assets/subNull.png')" alt="" width="50%">
              <div>·暂无数据·</div>
            </div>
          </template>
          <div class="item-style" v-for="item in list" :key="item.id">
            <div class="left">
              <div class="left-avatar">
                {{ item.consumerName.length > 2 ? item.consumerName.substring(0, 2) : item.consumerName }}
              </div>
            </div>
            <div class="right">
              <div class="top">
                <div style="flex: 1;display: flex;">
                  <div class="name">{{ item.consumerName }}</div>
                  <div class="phone" @click="callPhone(item.phoneNo)">
                    <img :src="require('@/assets/phoneNo.png')" alt="" width="16" height="16" />
                    <span style="margin-left: 8px"> {{ item.phoneNo }} </span>
                  </div>
                </div>
                <div @click="onToDetail(item, 'detail')" style="padding-left: 12px;">
                  <van-icon name="arrow" size="20" />
                </div>
              </div>
              <div class="bottom">
                <div class="title">编号： {{ item.orderCode }}</div>
                <div :class="`right-divideStatus-${classes[item.divideStatus]} right-divideStatus`"
                  @click="onToDetail(item, 'detailList')">
                  {{ item.divideStatus }}<van-icon name="arrow" />
                </div>
              </div>
            </div>
          </div>
        </van-list>
      </pullRefresh>
    </div>
  </div>
</template>

<script>
import api from "@/api/api.js";
import FilterModel from "@/components/filterModel1.vue";
export default {
  data() {
    return {
      classes: {
        '待验收': "yellow",
        '待验证': "yellow",
        '待上传': "yellow",
        '未通过': "red",
        '验证未通过': "red",
        '验收未通过': "red",
        '已通过': "blue",
        '验证已通过': "blue",
        '验收已通过': "blue",
      },
      searchValue: "",
      list: [],
      filterCondition: [
        {
          label: "分包单状态",
          defaultLabel: "分包单状态",
          field: "Q_divideStatus_EQ",
          type: "select",
          activeValue: "00",
          actions: [
            { value: "00", text: "所有状态" },
            { value: "0", text: "待上传" },
            { value: "1", text: "待验收" },
            { value: "2", text: "验收未通过" },
            { value: "3", text: "验收已通过" },
          ],
        },
        {
          label: "下拨状态",
          defaultLabel: "下拨状态",
          field: "Q_f.allocateStatus_EQ",
          type: "select",
          activeValue: "00",
          actions: [{ value: "00", text: "所有状态" }],
        },
        {
          label: ['下单日期'],
          defaultLabel: ['下单日期'],
          field: 'Q_belowData_BW',
          type: 'timeQuantum',
          activeValue: '00',
        },
      ],
      isLoading: false,
      loading: false,
      finished: false,
      error: false, // 是否加载失败
      pageNum: 1, // 分页
      pageSize: 10, // 每页条数
      total: 0, // 数据总条数
    };
  },
  components: {
    FilterModel,
  },
  methods: {
    //返回首页
    back() {
      this.$router.push({ name: "indexName" });
    },
    onClear() {
      this.searchValue = ""
    },
    //获取列表
    async getList(e) {
      try {
        let obj = {
          page: this.pageNum,
          limit: this.pageSize,
          ext: this.searchValue,
        };
        this.filterCondition?.forEach((item) => {
          obj[item.field] = item.activeValue == '00' ? undefined : item.activeValue
          if (item.type == 'timeQuantum') obj[item.field] = item.activeValue == '00' ? undefined : item.activeValue[0] + ' 00:00:00' + '~' + item.activeValue[1] + ' 23:59:59'
        })
        let res = await api.getSubpackageList(obj);
        if (res.code == 200) {
          this.total = res.count;
          if (!res.data.returnDTOS?.length) {
            // 判断获取数据条数若等于0
            this.list = []; // 清空数组
            this.finished = true; // 停止加载
          } else {
            // 若数据条数不等于0
            this.list.push(...res.data.returnDTOS); // 将数据放入list中
            this.loading = false; // 加载状态结束
            // 如果list长度大于等于总数据条数,数据全部加载完成
            if (this.list.length >= res.count) {
              this.finished = true; // 结束加载状态
            }
          }
          this.isLoading = false;
          if (this.$refs.pullRefresh) {
            this.$refs.pullRefresh.refreshSuccess();
          }
        }
      } catch (error) {
        this.isLoading = false
        this.loading = false
        this.finished = true
        this.error = false
      }
    },
    // 被 @load调用的方法
    onLoad(e) {
      // 若加载条到了底部
      let timer = setTimeout(() => {
        // 定时器仅针对本地数据渲染动画效果,项目中axios请求不需要定时器
        // this.getList(); // 调用上面方法,请求数据
        this.getList(e);
        this.pageNum++; // 分页数加一
        this.finished && clearTimeout(timer); //清除计时器
      }, 100);
    },
    // 加载失败调用方法
    onRefresh(e) {
      this.finished = false; // 清空列表数据
      this.loading = true; // 将 loading 设置为 true，表示处于加载状态
      this.pageNum = 1; // 分页数赋值为1
      this.list = []; // 清空数组
      this.onLoad(e); // 重新加载数据
    },
    onToDetail(item, type) {
      sessionStorage.setItem("clientInfo1", JSON.stringify(item));
      sessionStorage.setItem("fromRouterNameEngineering", "engineeringConstructionName");
      if (type === "detail") {
        this.$router.push({ name: "engineeringSubpackageDetail" });
      } else if (item.divideStatus === "待上传") {
        this.$router.push({ name: "engineeringSubpackageHandle" });
      } else {
        this.$router.push({ name: "engineeringSubpackageDetailView" });
      }
    },
    //下拨状态列表
    async getDictionariesList() {
      let obj = {
        Q_typeCode_IN: "DICT454518",
      };
      let res = await api.getDictionariesList(obj);
      if (res.code == 200) {
        let temp = [];
        res.data.forEach((e) => {
          temp.push({ value: e.code, text: e.name });
        });
        this.filterCondition[1].actions.push(...temp);
      }
    },
    // 调用拨号功能
    callPhone(phoneNo) {
      if (!phoneNo) return;
      window.location.href = "tel://" + phoneNo;
    },
    //权限判断
    permission(v) {
      return this.hasPermission(v);
    },
  },
  mounted() {
    this.getDictionariesList(); //下拨状态列表
  },
};
</script>

<style scoped lang="scss">
.construction-style {
  .search-style {
    height: 56px;
    background: #ffffff;
    display: flex;
    align-items: center;
    padding: 0 10px;

    .choosetime-input {
      height: 40px;
      line-height: 40px;
      background: #f5f6f8;
      border-radius: 6px;
      padding: 0;
      padding-right: 5px;
      font-size: 13px;

      ::v-deep .van-field__control {
        padding-left: 10px;
      }
    }

    .search-icon {
      width: 22px;
      height: 22px;
      margin: auto 0;
    }
  }

  .filter-style {
    height: 47px;
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1px;

    .filter-item {
      width: 124px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid #e6e6e6;

      .item-style {
        display: inline-block;
        // width: 50px;
        margin-right: 4px;
      }

      .icon-style {
        display: inline-block;
        // height: 40px;
        // line-height: 40px;
      }
    }

    .already-filter {
      color: #4f7afd;
    }

    .content {
      padding-bottom: 81px;

      .sheet-top {
        position: relative;
        height: 45px;
        line-height: 45px;
        background: #f5f6f8;
        font-size: 16px;
        // font-family: Source Han Sans SC;
        font-weight: 400;

        .sheet-cancel {
          position: absolute;
          left: 28px;
          color: #999999;
        }

        .sheet-sure {
          position: absolute;
          right: 28px;
          color: #4f7afd;
        }
      }

      .sheet-content {
        background: #ffffff;

        .sheet-item {
          height: 45px;
          line-height: 45px;
          font-size: 16px;
          // font-family: Source Han Sans SC;
          font-weight: 400;
          color: #333333;
          text-align: left;
          margin: 0 15.5px;
          border-bottom: 1px solid #e6e6e6;
          display: flex;
          align-items: center;
          position: relative;

          .sheet-item-checked-icon {
            width: 22px;
            margin: auto 0;
            position: absolute;
            right: 11.5px;
          }
        }
      }
    }
  }

  .content-style {
    padding: 0 10px;
    height: calc(100vh - 190px);
    overflow: auto;

    .item-style {
      background: #ffffff;
      padding: 16px;
      margin-top: 10px;
      border-radius: 6px;
      display: flex;
      justify-content: space-between;

      .left {
        .left-avatar {
          width: 60px;
          height: 60px;
          border-radius: 100%;
          color: #fff;
          background-color: #4871c0;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 16px;
        }
      }

      .right {
        flex: 1;
        text-align: left;
        margin-left: 14px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .top {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .name {
            font-size: 16px;
          }

          .phone {
            margin-left: 14px;
            font-size: 12px;
            display: flex;
            color: #1677ff;
            align-items: center;
          }
        }

        .title {
          font-size: 12px;
          font-family: Source Han Sans SC;
          font-weight: 400;
          color: #999;
          margin-bottom: 2px;
        }

        .bottom {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;

          .right-divideStatus {
            padding: 0 4px 0 10px;
            height: 20px;
            line-height: 20px;
          }

          .right-divideStatus-yellow {
            background-color: #fff8e6;
            color: #f7b500;
          }

          .right-divideStatus-red {
            background-color: #ffeceb;
            color: #ff3b30;
          }

          .right-divideStatus-blue {
            background-color: #e8f2ff;
            color: #1677ff;
          }
        }
      }

    }
  }

  ::v-deep .van-pull-refresh {
    min-height: calc(100vh - 190px);
  }

  .show-img {
    height: calc(100vh - 190px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
</style>
