<template>
    <div class="messageInfo-box">
        <headerNav title="消息详情" />
        <div :class="clientInfo.divideStatus != '待上传' ? 'show_main_content' : 'main_content'">
            <!-- 信息 -->
            <AcceptanceSubcontract ref="compontDetails"></AcceptanceSubcontract>
            <!-- 新分包单上传现场图片 -->
            <div class="content-style">
                <div class="upPhoto-style">
                    <div class="input-text">
                        <span style="color:red">* </span>
                        <span>现场照片：</span>
                        <span style="font-size: 12px;color: #999;">最多上传30张,.PNG/JPG.</span>
                    </div>
                    <div class="up-style" v-if="clientInfo.divideStatus == '待上传'">
                        <van-uploader :after-read="afterRead" multiple :max-count="30" :max-size="10 * 1024 * 1024"
                            v-model="fileList">
                            <div class="up-style-img">
                                <img :src="require('@/assets/camera1.png')" alt="" width="20px" />
                                <span>上传图片</span>
                            </div>
                        </van-uploader>
                    </div>
                    <div class="cont_img" v-else>
                        <van-image @click="onPreview(item)" v-for="(item, index) in imageList.slice(0, 8)" width="104"
                            height="104" :src="item" class="img" :key="index" />
                        <div class="see_more" v-if="imageList.length > 8 && !showMoreImg" @click="showMoreImg = true">
                            查看更多</div>
                        <van-image @click="onPreview(item)" v-for="(item, index2) in imageList.slice(8)" v-if="showMoreImg"
                            width="104" height="104" :src="item" class="img" :key="index2" />
                    </div>
                </div>

                <div class="textarea-style">
                    <div class="input-text">备注：</div>
                    <div style="flex:1" v-if="clientInfo.divideStatus == '待上传'">
                        <van-field class="input-style" v-model="remark" rows="3" autosize maxlength="200" show-word-limit
                            type="textarea" name="备注信息" placeholder="请输入" />
                    </div>
                    <div v-else class="remark_bottom">
                        {{ remark }}
                    </div>
                </div>

            </div>
        </div>
        <div v-show="clientInfo.divideStatus == '待上传'">
            <van-button type="info" class="bottom_btn" @click="onSubmit">上传</van-button>
        </div>
    </div>
</template>
<script>
import api from '@/api/api.js'
import { ImagePreview,Toast } from 'vant';
import AcceptanceSubcontract from './Component/AcceptanceSubcontract.vue'
export default {
    components: {
        AcceptanceSubcontract
    },
    data () {
        return {
            id: '',
            logId:'',
            msgInfo: {},
            clientInfo:{},
            activeNames: [''],
            radio: '',
            fileList: [],
            remark: '',
            imageList: [],
            // 控制显示更多图片
            showMoreImg: false,
            // 审核意见
            checkRemark: '',
            // 控制是否点击确认
            isClickOk: false
        }
    },
    methods: {
        async afterRead (file) {
            const fileList = Array.isArray(file) ? file : [file]
            fileList.forEach(async (e, index) => {
                // 此时可以自行将文件上传至服务器
                const formData = new FormData();
                formData.append('files', e.file);
                formData.append('dir', 'oss');
                const res = await api.upFile(formData)
                if (res.code == 200) {
                    const indexR = this.fileList.length - fileList.length + index
                    this.fileList[indexR].url = res.url
                }
            })
        },
        async onSearch () {
           const {data}  = await api.imgDetail(this.id)
           console.log(data,'大傻逼覅发大水')
           this.clientInfo = data
           this.imageList = data.imgUrlList
           this.remark = data.remark
        },
        async onSubmit () {
            if (this.fileList.length > 0) {
                const obj = {
                    sellDivideId: this.id,
                    imgUrlList: this.fileList.map(item => item.url),
                    remark: this.remark,
                }
                console.log(obj)
                const res = await api.addSubpackageImgDetail(obj)
                if (res.code == 200) {
                    Toast.success('提交成功！')
                    this.onSearch()
                    this.$refs.compontDetails.handleDetails()
                }
            } else {
                Toast.fail('请上传图片！')
            }
        },
        onPreview (url) {
            ImagePreview({
                images: [url],
                closeable: true,
                showIndex: false
            });
        },
    },
    mounted () {

    },
    created () {
        this.id = this.$route.params.id
        this.logId = this.$route.params.logId
        this.onSearch()
    }
}
</script>

<style scoped lang="scss">
.messageInfo-box {
    width: 100%;
    overflow: hidden;

    .main_content {
        width: 100%;
        overflow-x: auto;
        height: 83vh;
        background: #F5F5F5;

    }

    .show_main_content {
        height: calc(100vh - 50px);
        width: 100%;
        overflow-x: auto;
        background: #F5F5F5;
    }
}

.main_bottom {
    padding: 24px 16px;
    display: flex;
    justify-content: space-around;

    .div_border {
        border: 1px solid #D4D6D9;
        padding: 10px 30px;
        border-radius: 4px;
        margin: 0px 15px;
        font-size: 18px;
    }
}

.bottom_btn {
    background: #3A72C6;
    margin: 20px 0px;
    width: 295px;
    border-radius: 10px
}

.check_main {
    background: #fff;
    margin-top: 8px;

    .title_p {
        color: #333333;
        font-size: 16px;
        font-weight: 500;
        text-align: left;
        padding: 16px 16px 10px 16px;
        margin: 0;
    }

    .check_main_cont {
        color: #333333;
        font-size: 16px;
        padding: 10px 16px;

        .div_flex {
            display: flex;
            justify-content: space-between;
            padding-bottom: 16px;
        }

        .beizhu {
            text-align: justify;
            flex: 3;
        }

        .remark {
            flex: 7;
            text-align: left;
        }

        .div_img {
            text-align: center;

            .cont_img {
                margin-top: 8px;
                display: flex;
                flex-wrap: wrap;
            }

            .img {
                margin: 6px 6px;
                border-radius: 5px;
            }

            .see_more {
                width: 100px;
                height: 100px;
                border: 1px solid #EEEEEE;
                border-radius: 5px;
                line-height: 100px;
                text-align: center;
                margin: 5px 5px;
            }
        }

        .check_opinion {
            text-align: left;
            margin-top: 26px;

            .input_style {
                margin-top: 8px;
                padding: 8px;
                border-radius: 5px;
                background-color: #F8FAFF;
                font-weight: 400;
            }

            .remark_bottom {
                margin-top: 8px;
            }
        }
    }

}

.content-style {
    height: 68vh;
    overflow: auto;
    background-color: #fff;
    text-align: left;
    font-size: 16px;
}

.upPhoto-style {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .input-text {
        height: 32px;
        line-height: 32px;
        padding: 0 16px;
    }

    .up-style {
        padding-left: 28px;

        .up-style-img {
            border: 1px dashed #eee;
            background-color: #F8FAFF;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            margin: 0 8px 8px 0;
            width: 104px;
            height: 104px;

            span {
                margin-top: 10px;
            }
        }

        ::v-deep .van-uploader__preview-image {
            border-radius: 5px;
            overflow: hidden;
            width: 104px;
            height: 104px;
        }

        ::v-deep .van-uploader__preview-delete {
            width: 20px;
            height: 20px;
            top: 8px;
            right: 8px;
            border-radius: 100%;

            .van-uploader__preview-delete-icon,
            .van-icon {
                font-size: 24px;
            }
        }
    }

    .cont_img {
        margin-top: 8px;
        display: flex;
        flex-wrap: wrap;
        padding: 10px 16px;
    }

    .img {
        margin: 6px 6px;
        border-radius: 5px;
    }

    .see_more {
        width: 100px;
        height: 100px;
        border: 1px solid #EEEEEE;
        border-radius: 5px;
        line-height: 100px;
        text-align: center;
        margin: 5px 5px;
    }
}


.textarea-style {
    padding: 0 16px 0 28px;

    .input-style {
        margin-top: 8px;
        padding: 8px;
        border-radius: 5px;
        background-color: #F8FAFF;
    }
}
</style>

