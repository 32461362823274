<template>
    <div>
        <van-list class="vant-list-style" v-model="loading" :finished="finished"
            :finished-text="total ? '·已加载全部数据·' : '·暂无数据·'" @load="onLoad">
            <pullRefresh ref="pullRefresh" @refresh="onRefresh">
                <div v-if="!photosList.length">暂无数据</div>
                <div style="display: flex;flex-wrap: wrap;">
                    <div v-for="(item, index) in photosList" class="list-box">
                        <div class="list-item">
                            <div>
                                <van-image class="img-style" :src="item.fileAdd"
                                    @click="photoPreview([item.fileAdd])" />

                            </div>
                            <div class="content">
                                <div>
                                    <span class="name">{{ item.userName }}</span>
                                    <span class="depart">{{ item.departName }}</span>
                                </div>
                                <div class="time">{{ item.createDate }}</div>
                                <div class="filename">{{ item.fileName }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </pullRefresh>
        </van-list>
        <van-icon name="add" class="add" size="50" color="#4F7AFD" @click="add" />
        <van-popup v-model="addModal" get-container="body" duration="0">
            <div style="height: 100vh;width: 100vw;">
                <headerNav title="添加图片">
                    <template>
                        <van-icon name="cross" @click="closeAdd" />
                    </template>
                </headerNav>
                <div>
                    <div class="input-box upPhoto-style">
                        <div class="input-text">选择图片</div>
                        <div class="input-style">
                            <van-uploader :after-read="afterRead">
                                <van-button class="button-style" type="primary"></van-button>
                                <img src="@/assets/upPhoto.png" class="choose-icon" alt="">
                            </van-uploader>
                        </div>
                    </div>
                    <div class="photo-box" v-show="fileList.length">
                        <div class="photo-style" v-for="(item, index) in fileList" style="position: relative;">
                            <!-- <div class="del-icon" @click="delPhoto(index)">×</div> -->
                            <img style="width: 100%;height: 100%;" :src="item" alt=""
                                @click="photoPreview(fileList, index)">
                        </div>
                    </div>
                    <div class="bottom-btn">
                        <div @click="closeAdd" style="border-right: 1px solid #E6E6E6">取消</div>
                        <div @click="submitAdd" style="background: #4F7AFD;color: #ffffff;">确定</div>
                    </div>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import api from '../../../api/api';
import {
    Toast, ImagePreview
} from 'vant';
export default {
    data () {
        return {
            photosList: [],
            fileList: [],
            addModal: false,
            isLoading: false,
            loading: false,
            finished: false,
            error: false, // 是否加载失败
            pageNum: 1, // 分页
            pageSize: 10, // 每页条数
            total: 0, // 数据总条数
            confirmLoading: false,
        }
    },
    methods: {
        async getphotosList () {
            let obj = {
                page: this.pageNum,
                limit: this.pageSize,
                Q_consumerId_EQ: this.$route.params.id,
                Q_typeCode_EQ: 0
            }
            let res = await api.getphotosList(obj)
            if (res.code == 200) {
                this.total = res.count;
                if (res.data.length === 0) { // 判断获取数据条数若等于0
                    this.photosList = []; // 清空数组
                    this.finished = true; // 停止加载
                } else {
                    // 若数据条数不等于0
                    this.photosList.push(...res.data) // 将数据放入list中
                    this.loading = false; // 加载状态结束 
                    // 如果list长度大于等于总数据条数,数据全部加载完成
                    if (this.photosList.length >= res.count) {
                        this.finished = true; // 结束加载状态
                    }
                }
                this.isLoading = false
                if (this.$refs.pullRefresh) {
                    this.$refs.pullRefresh.refreshSuccess()
                }
            }
        },
        // 被 @load调用的方法
        onLoad (e) { // 若加载条到了底部
            let timer = setTimeout(() => { // 定时器仅针对本地数据渲染动画效果,项目中axios请求不需要定时器
                this.getphotosList(); // 调用上面方法,请求数据
                this.pageNum++; // 分页数加一
                this.finished && clearTimeout(timer); //清除计时器
            }, 100);
        },
        // 加载失败调用方法
        onRefresh (e) {
            this.finished = false; // 清空列表数据
            this.loading = true; // 将 loading 设置为 true，表示处于加载状态
            this.pageNum = 1; // 分页数赋值为1
            this.photosList = []; // 清空数组
            this.onLoad(e); // 重新加载数据
        },
        //图片预览
        photoPreview (urlList, index = 0) {
            let o = {
                images: urlList,
                startPosition: index
            }
            ImagePreview(o);
        },
        //添加图片
        add () {
            this.fileList = []
            this.addModal = true
        },
        closeAdd () {
            this.addModal = false
        },
        //文件改变
        async submitAdd () {
            if (this.confirmLoading) return
            if (!this.fileList.length) {
                Toast('请选择图片!');
                return
            }
            let obj = {
                consumerId: this.$route.params.id,
                clueId: this.$route.params.clueId,
                typeCode: 0,//文件类别(0:图片;1:录音;2:视频)
                urlList: this.fileList
            }
            this.confirmLoading = true
            let res = await api.saveFiles(obj)
            if (res.code == 200) {
                Toast.success('上传成功!');
                this.addModal = false
                this.confirmLoading = false
                this.onRefresh()
            }
        },
        async afterRead (file) {
            // 此时可以自行将文件上传至服务器
            let formData = new FormData();
            formData.append('files', file.file);
            formData.append('dir', 'oss');
            let res = await api.upFile(formData)
            if (res.code == 200) {
                this.fileList.push(res.url)
            }
        },
        delPhoto (index) {
            this.fileList.splice(index, 1)
        },

    }
}
</script>

<style lang="scss" scoped>
.list-box {
    width: 50%;
    padding: 8px;
    box-sizing: border-box;
}

.list-item {
    background-color: #FFFFFF;
    border-radius: 8px;
    box-shadow: 1px 1px 6px #E6E6E6;
    display: inline-block;
    width: 100%;
    // padding: 5px 0;
    padding-bottom: 5px;
    overflow: hidden;

    .content {
        padding: 0 5px;

        div {
            text-align: left;
            margin-top: 2px;
        }
    }

    .img-style {
        width: 100%;
        height: 100px;
        // width: 80px;
    }

    .name {
        font-size: 17px;
        margin-right: 10px;
    }

    .depart {
        color: #AAAAAA;
        text-align: right;
    }

    .time {
        text-align: right;
        color: #AAAAAA;
    }

    .filename {
        color: #AAAAAA;
        overflow: hidden; //文本超出隐藏
        text-overflow: ellipsis; //多的用...来显示
        white-space: nowrap
    }
}

.add {
    position: fixed;
    bottom: 80px;
    right: 30px;
}

.input-box {
    margin-top: 10px;
}

.input-text {
    text-align: center;
    width: 90px;
}

.photo-box {
    background: #FFFFFF;
    display: flex;
    padding: 0 18px;
    padding-bottom: 18px;
    flex-wrap: wrap;

    // justify-content: space-between;
    .photo-style {
        width: 25%;
        height: 80px;
        margin-top: 10px;
        padding-right: 5px;
        box-sizing: border-box;
    }

    .center-style {
        margin: 0 20px;
    }

    .del-icon {
        width: 16px;
        height: 16px;
        background: #000000;
        color: #FFFFFF;
        opacity: 0.8;
        border-radius: 50%;
        font-size: 9px;
        line-height: 16px;
        position: absolute;
        right: -6px;
        top: -6px;
        text-align: center;
        border: none;
        border-radius: 50%;
    }
}

.bottom-btn {
    height: 40px;
    position: fixed;
    bottom: 0;
    display: flex;
    width: 100%;
    background-color: #FFFFFF;
    text-align: center;
    border-top: 1px solid #E6E6E6;

    div {
        flex: 1;
        font-size: 13px;
        height: 100%;
        line-height: 40px;
    }
}
</style>