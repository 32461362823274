<template>
    <div class="myClient-box">
        <headerNav title="客户管理" />
        <div class="search-style">
            <van-field class="choosetime-input" v-model="searchValue" @keyup.13.native="onRefresh()" clearable
                placeholder="输入客户姓名/联系方式/邀约码查询">
                <template v-slot:right-icon>
                    <div style="height: 100%;display: flex;align-items: center;">
                        <img src="../../../assets/search.png" class="search-icon" alt="">
                    </div>
                </template>
            </van-field>
        </div>
        <filterModel :filterList="filterCondition" @filterFunction="onRefresh" ref="filter"></filterModel>
        <div class="list-box">
            <pullRefresh ref="pullRefresh" @refresh="onRefresh">
                <van-list class="vant-list-style" v-model="loading" :finished="finished"
                    :finished-text="total ? '·已加载全部数据·' : '·暂无数据·'" @load="onLoad">
                    <div class="list-item" v-for="item in clientList">
                        <div class="item-top" @click="details(item)">
                            <div class="name">
                                {{ item.consumerName }}
                                <van-tag v-if="item.intentCode" plain
                                    :color="item.intentCode == 'ITEM101346' ? '#4F7AFD' : '#F59A23'">{{
                                        item.intentCodeName
                                    }}</van-tag>
                                <van-tag v-if="item.signStatus == '20'" round class="right-tag" type="primary"
                                    color="#fde9d0" text-color="#F59A23">未签单</van-tag>
                                <van-tag v-if="item.signStatus == '21'" round class="right-tag" type="primary"
                                    color="#c8deff" text-color="#4F7AFD">已签单</van-tag>
                            </div>
                            <div v-if="item.phoneNo">
                                <p>手机号</p>{{ item.phoneNo }}
                            </div>
                            <div v-if="item.landlineNo">
                                <p>固话</p>{{ item.landlineNo }}
                            </div>
                            <div v-if="item.wechatNo">
                                <p>微信号</p>{{ item.wechatNo }}
                            </div>
                            <div v-if="item.otherNo">
                                <p>其他</p>{{ item.otherNo }}
                            </div>
                            <div>
                                <p>接单时间</p>{{ item.allocationTimeSign }}
                            </div>
                        </div>
                        <div class="item-bottom">
                            <div class="bottom-item-style" @click="loseBill(item)"
                                v-if="!item.signTime && !item.singTime">
                                <span class="text">丢单</span>
                            </div>
                            <div class="bottom-item-style" @click="signBill(item)"
                                v-if="!item.signTime && !item.singTime">
                                    <span class=" text">签单</span>
                            </div>
                            <div class="bottom-item-style" @click="signBill(item,'signAgain')"
                                v-if="item.signStatus == '21'">
                                    <span class=" text">再次签单</span>
                            </div>
                            <div class="bottom-item-style" @click="follow(item)">
                                <span class="text">跟进</span>
                            </div>
                            <div class="bottom-item-style" @click="overFollow(item)" v-if="item.signProcessStatus != '1'">
                                <span class="text">完成跟单</span>
                            </div>
                            <!-- <div class="bottom-item-style" style="border: none;" @click="details(item)">
                                <span class="text">详情</span>
                            </div> -->
                        </div>
                        <img :src="require('@/assets/xzjt.png')" alt="" class="right-arrow" @click="details(item)">
                    </div>
                </van-list>
            </pullRefresh>
        </div>
        <van-dialog v-model="billDialog" show-cancel-button :before-close="dialogSubmit" confirmButtonColor="#1989fa" @close="handleClose">
            <template #title>
                <div v-if="modalType == 'lose'" class="dialog-title">丢单原因</div>
                <div v-if="modalType == 'follow'" class="dialog-title">跟进信息</div>
                <div v-if="modalType == 'sign'">签单</div>
                <div v-if="modalType == 'signAgain'">再次签单</div>
            </template>
            <div style="padding: 10px 20px;">
                <van-field v-if="modalType == 'lose'" v-model="singCase" rows="2" autosize type="textarea" required
                    maxlength="120" placeholder="​请输入丢单原因" show-word-limit />
                <van-field v-if="modalType == 'follow'" v-model="followRemark" rows="2" autosize type="textarea" required
                    maxlength="120" placeholder="​请输入跟进信息" show-word-limit />
                <van-field v-if="modalType == 'sign' || modalType == 'signAgain'" v-model="earnest" class="input-style" colon type="number" label="定金"
                    @input="xiaoshu" clearable label-width="40" style="background-color: #fff;" maxlength="11" />
            </div>
        </van-dialog>
        <div class="scan-QR" ref="chatbox" @touchstart.prevent="dragx" @touchend.prevent="handleScanQR">
            <img :src="require('@/assets/icon14.png')" class="scan-icon">
            扫一扫
        </div>
        <!-- 签单手领取客户 -->
        <van-dialog v-model="signShow" class="signModal" :show-cancel-button="false" :show-confirm-button="false"	>
            <div class="content" :class="customerStatus?'blue_background':'green_background'">
                <div class="top_main">
                    <img :src="customerStatus?require('@/assets/bluetip.png'):require('@/assets/greentip.png')" alt="">
                    <span>提示</span>
                </div>
                <p>{{customerStatus?'该用户已分配':'成功领取'}}</p>
                <img class="close_img" @click="handlecloseTip" :src="require('@/assets/closeModal.png')" alt="">
            </div>
        </van-dialog>
    </div>
</template>

<script>
import api from '../../../api/api.js'
import filterModel from '@/components/filter.vue'
import {
    Toast, Dialog,Notify
} from 'vant';
export default {
    data() {
        return {
            searchValue: '',
            searchValue1: '',
            list: [],
            action: {},
            actions: [],
            filterTypeId: '',
            filterCondition: [{
                typeId: 'intentCodeName',
                dictValue: 'default',
                dictLabel: '客户意向',
                default: '客户意向',
                actions: [
                    {
                        dictValue: 'default',
                        dictLabel: '全部'
                    },
                ]
            },
            {
                typeId: 'processCode',
                dictValue: 'default',
                dictLabel: '跟进状态',
                default: '跟进状态',
                actions: [
                    {
                        dictValue: 'default',
                        dictLabel: '全部'
                    },
                    {
                        dictValue: '1',
                        dictLabel: '已跟进'
                    },
                    {
                        dictValue: '0',
                        dictLabel: '未跟进'
                    },
                ]
            }, {
                typeId: 'signStatus',
                dictValue: 'default',
                dictLabel: '签单状态',
                default: '签单状态',
                actions: [
                    {
                        dictValue: 'default',
                        dictLabel: '全部'
                    },
                    {
                        dictValue: '21',
                        dictLabel: '已签单'
                    },
                    {
                        dictValue: '20',
                        dictLabel: '未签单'
                    },
                ]
            },
            {
                typeId: 'date-quantum',
                dictValue: 'default',
                dictLabel: '接单时间',
                default: '接单时间',
                actions: [{
                    dictValue: 'default',
                    dictLabel: '所有日期'
                }, {
                    dictValue: '1',
                    dictLabel: '自定义日期'
                }]
            },],
            filterCondition1: [{
                typeId: 'Q_intentCode_IN',
                dictValue: 'default',
                dictLabel: '客户意向',
                default: '客户意向',
                actions: [
                    {
                        dictValue: 'default',
                        dictLabel: '全部'
                    },
                ]
            },],
            clientInfo: {},
            clientInfo1: {},
            modalType: 'sign',//sign签单 lose丢单
            modalType1: 'sign',//sign签单 lose丢单
            billDialog: false,
            billDialog1: false,
            singCase: '',//丢单原因
            earnest: '',//定金
            earnest1: '',//定金
            followRemark: '',//跟进信息
            isLoading: false,
            loading: false,
            loading1: false,
            finished: false,
            finished1: false,
            error: false, // 是否加载失败
            clientList: [],
            nightList: [],
            pageNum: 1, // 分页
            pageSize: 10, // 每页条数
            total: 0, // 数据总条数
            Loop: 0,
            // 签单手扫二维码弹窗
            signShow:false,
            // 客户是否已分配
            customerStatus:false
        }
    },
    components: {
        filterModel
    },
    methods: {
        //返回首页
        back() {

        },

        async getClientList(e) {
            let obj = {
                page: this.pageNum,
                limit: this.pageSize,
            }
            if (e) {
                obj.Q_intentCode_IN = e[0].dictValue == "default" ? '' : e[0].dictValue //客户意向
                obj.Q_signStatus_EQ = e[2].dictValue == "default" ? '' : e[2].dictValue //签单状态
                obj.Q_signProcessStatus_EQ = e[1].dictValue == "default" ? '' : e[1].dictValue //跟进状态
                let d = []
                if (e[3].dictValue != "default") {
                    obj.Q_allocationTimeSign_BW = e[3].dictLabel[0] + ' 00:00:00' + '~' + e[3].dictLabel[1] + ' 23:59:59'
                }
            }
            let res = await api.getClientList(obj, this.searchValue)
            if (res.code == 200) {
                this.total = res.count;
                if (res.data.length === 0) { // 判断获取数据条数若等于0
                    this.clientList = []; // 清空数组
                    this.finished = true; // 停止加载
                } else {
                    // 若数据条数不等于0
                    this.clientList.push(...res.data) // 将数据放入list中
                    this.loading = false; // 加载状态结束 
                    // 如果list长度大于等于总数据条数,数据全部加载完成
                    if (this.clientList.length >= res.count) {
                        this.finished = true; // 结束加载状态
                    }
                }
                this.isLoading = false
                if (this.$refs.pullRefresh) {
                    this.$refs.pullRefresh.refreshSuccess()
                }
            }
        },
        async getNightList(e) {
            let obj = {
                page: this.pageNum1,
                limit: this.pageSize1,
            }
            if (e) {
                obj.Q_intentCode_IN = e[0].dictValue == "default" ? '' : e[0].dictValue //客户意向
            }
            let res = await api.getNightList(obj, this.searchValue1)
            if (res.code == 200) {
                this.total1 = res.count;
                if (res.data.length === 0) { // 判断获取数据条数若等于0
                    this.nightList = []; // 清空数组
                    this.finished1 = true; // 停止加载
                } else {
                    // 若数据条数不等于0
                    this.nightList.push(...res.data) // 将数据放入list中
                    this.loading1 = false; // 加载状态结束 
                    // 如果list长度大于等于总数据条数,数据全部加载完成
                    if (this.nightList.length >= res.count) {
                        this.finished1 = true; // 结束加载状态
                    }
                }
                this.isLoading = false
                if (this.$refs.pullRefresh1) {
                    this.$refs.pullRefresh1.refreshSuccess()
                }
            }
        },
        // 被 @load调用的方法
        onLoad(e) { // 若加载条到了底部
            let timer = setTimeout(() => { // 定时器仅针对本地数据渲染动画效果,项目中axios请求不需要定时器
                // this.getList(); // 调用上面方法,请求数据
                this.getClientList(e)
                this.pageNum++; // 分页数加一
                this.finished && clearTimeout(timer); //清除计时器
            }, 100);
        },
        // 被 @load调用的方法
        onLoad1(e) { // 若加载条到了底部
            let timer1 = setTimeout(() => { // 定时器仅针对本地数据渲染动画效果,项目中axios请求不需要定时器
                // this.getList(); // 调用上面方法,请求数据
                this.getNightList(e)
                this.pageNum1++; // 分页数加一
                // this.
                this.finished1 && clearTimeout(timer1); //清除计时器
            }, 100);
        },
        // 加载失败调用方法
        onRefresh(e) {
            this.finished = false; // 清空列表数据
            this.loading = true; // 将 loading 设置为 true，表示处于加载状态
            this.pageNum = 1; // 分页数赋值为1
            this.clientList = []; // 清空数组
            this.onLoad(this.$refs.filter.filterCondition); // 重新加载数据
        },
        // 夜间到访签单失败调用方法
        onRefresh1(e) {
            this.finished1 = false; // 清空列表数据
            this.loading1 = true; // 将 loading 设置为 true，表示处于加载状态
            this.pageNum1 = 1; // 分页数赋值为1
            this.nightList = [];// 清空夜间到访2222222数据数组
            this.onLoad1(this.$refs.filter1.filterCondition); // 重新加载数据
        },
        //字典查询 客户意向
        async getDictionariesList() {
            let obj = {
                Q_typeCode_IN: 'DICT819278',
            }
            let res = await api.getDictionariesList(obj)
            let temp = []
            res.data.forEach(e => {
                temp.push({
                    dictValue: e.code,
                    dictLabel: e.name
                })
            })
            this.filterCondition[0].actions.push(...temp)
            this.filterCondition1[0].actions.push(...temp)
        },
        //丢单
        loseBill(item) {
            this.clientInfo = item
            this.modalType = 'lose'
            this.singCase = ''
            this.billDialog = true
        },
        //签单
        signBill (item,type) {
            this.clientInfo = item
            this.modalType = type == 'signAgain'?'signAgain':'sign'
            this.billDialog = true
        },
        // 夜间签单
        signBill1(item) {
            this.clientInfo1 = item
            this.modalType1 = 'sign'
            this.billDialog1 = true
        },
        //签单/丢单/跟进提交
        async dialogSubmit(action, done) {
            if (action === 'confirm') { // 确认
                //丢单
                if (this.modalType == 'lose') {
                    if (!this.singCase) {
                        Toast('请输入丢单原因!');
                        done(false)
                        return
                    }
                    let obj = {
                        consumerId: this.clientInfo.id,//客户id
                        singCase: this.singCase,//丢单原因
                    }
                    let res = await api.loseBill(obj)
                    if (res.code == 200) {
                        Toast.success('丢单成功!');
                    }
                }
                //签单
                if (this.modalType == 'sign' || this.modalType == 'signAgain') {
                    if (!this.earnest) {
                        Toast('请输入定金!');
                        done(false)
                        return
                    }
                    let obj = {
                        consumerId: this.clientInfo.id,//客户id
                        reserveAmount: this.earnest,//定金
                    }
                    let res = await api.signBill(obj)
                    if (res.code == 200) {
                        Toast.success('签单成功!');
                    }
                }
                //跟进
                if (this.modalType == 'follow') {
                    if(!this.followRemark){
                        Notify('请输入跟进信息');
                        done(false)
                        return
                    }
                    let obj = {
                        consumerId: this.clientInfo.id,
                        clueId: this.clientInfo.clueId,
                        advanceRemark: this.followRemark,
                        typeCode: 2
                    }
                    let res = await api.followAdd(obj)
                    if (res.code == 200) {
                        Toast.success('提交成功!');
                    }
                }
                done();
                this.onRefresh()
            } else if (action === 'cancel') { // 取消
                done(); // 关闭提示框
            }

        },
        // 弹窗关闭
        handleClose(){
            this.earnest = ''
        },
        //跟进
        follow(item) {
            this.clientInfo = item
            this.followRemark = ''
            this.modalType = 'follow'
            this.billDialog = true
        },
        //完成跟单
        overFollow (item) {
            Dialog.confirm({
                message: '是否确认完成该客户的跟单',
                confirmButtonColor: '#4871C0',
                beforeClose: async (action, done) => {
                    if (action === 'confirm') {
                        let res = await api.overFollow(item.id)
                        if (res.code == 200) {
                            Toast('操作成功')
                            done()
                            this.onRefresh()
                        } else {
                            done(false);
                        }
                    } else {
                        done()
                    }
                },
            }).catch(() => {
                // on cancel
            });
        },
        //详情
        details(item,flag,timeFlag) {
            this.$router.push({
                name: 'clientDetailsName',
                params: {
                    id: item.id,
                    // type:'sign'
                },
                query: {
                    flag,
                    timeFlag
                }
            })
        },
        xiaoshu(value) {
            value = value.replace(/[^\d.]/g, "");  //清除“数字”和“.”以外的字符
            value = value.replace(/^\./g, "");  //必须保证第一个为数字而不是.
            value = value.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
            value = value.replace(".", "$#$").replace(/\./g, "").replace("$#$", "."); //不能重复出现点
            value = value.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');//只能输入两个小数
            if (value.indexOf(".") < 0 && value != "") {//以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
                value = parseFloat(value);
            }
            if (value > 99999999.99) value = 99999999.99
            this.earnest = value
        },
        //拖动
        dragx (el) {
            let that = this;
            this.Loop = setTimeout(function () {
                that.Loop = 0;
            }, 100);
            let oDiv = this.$refs.chatbox; //当前元素
            let disY = el.changedTouches[0].clientY - oDiv.offsetTop;
            document.ontouchmove = function (e) {
                //通过事件委托，计算移动的距离
                let t = e.changedTouches[0].clientY - disY;
                if (t < 0) {
                    //和左右距离同理
                    t = 0;
                } else if (t > document.documentElement.clientHeight - oDiv.offsetHeight) {
                    t = document.documentElement.clientHeight - oDiv.offsetHeight;
                }
                //移动当前元素
                oDiv.style.top = t + "px";
            };
            document.ontouchend = function (e) {
                document.ontouchmove = null;
                document.ontouchend = null;
            };
            // 解决有些时候,在鼠标松开的时候,元素仍然可以拖动;
            document.ondragstart = function (ev) {
                ev.preventDefault();
            };
            document.ondragend = function (ev) {
                ev.preventDefault();
            };
            return false;
        },
        //扫码
        handleScanQR () {
            let that = this;
            clearTimeout(this.Loop);
            if (that.Loop !== 0) {
                // console.log('扫码')
                // alert('扫码')
                wx.scanQRCode({
                    needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                    scanType: ["qrCode"], // 可以指定扫二维码还是一维码，默认二者都有
                    success: function (res) {
                        setTimeout(() => {
                            var result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
                            that.$router.push({
                                name: 'clientDetailsName',
                                params: {
                                    id: result,
                                    type:'sign',
                                }
                            })
                        }, 1000);
                    }
                });
            }
            return false;
        },
        async getWeiXinInfo () {
            let obj = {
                type: 0,
                agentid: sessionStorage.getItem('APPID'),
                url: location.href.split('#')[0]
            }
            let res = await api.getWxConfig(obj)
            if (res.code == 200) {
                wx.config({
                    beta: true,// 必须这么写，否则wx.invoke调用形式的jsapi会有问题
                    // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    debug: false,
                    // 必填，公众号的唯一标识
                    appId: res.data.appId,
                    // 必填，生成签名的时间戳
                    timestamp: res.data.timestamp,
                    // 必填，生成签名的随机串
                    nonceStr: res.data.noncestr,
                    // 必填，签名
                    signature: res.data.signature,
                    // 必填，需要使用的JS接口列表，所有JS接口列表
                    jsApiList: ['checkJsApi', 'scanQRCode']
                });
                wx.ready(function () {
                    wx.checkJsApi({
                        jsApiList: ['scanQRCode'],
                        success: function (res) {
                            console.log('checkJsApi成功')
                        }
                    });
                });
                wx.error(function (res) {
                    alert("错误信息：" + res.errMsg);//这个地方的好处就是wx.config配置错误，会弹出窗口哪里错误，然后根据微信文档查询即可。
                });
            }
        },
        /* 关闭扫码弹窗 */
        handlecloseTip(){
            this.signShow = false
        }
    },
    created() {
        this.getDictionariesList()
        this.getWeiXinInfo()
    }
}
</script>

<style scoped lang="scss">
.myClient-box {
    box-sizing: border-box;
    background-color: #F5F6F8;

    .list-box {
        padding: 10px 10px 0 10px;
        height: calc(100vh - 150px);
        overflow: auto;

        .list-item {
            background: #FFFFFF;
            // padding: 8px;
            margin-bottom: 10px;
            border-radius: 6px;
            text-align: left;
            position: relative;

            .name {
                position: relative;
                font-size: 16px;
                color: #333333;
                display: flex;
                align-items: center;

                span {
                    margin-left: 10px;
                    line-height: 20px;
                }

                .right-tag {
                    position: absolute;
                    right: 0;
                }
            }

            p {
                display: inline-block;
                width: 70px;
                margin: 0;
                margin-top: 5px;
                line-height: 20px;
            }

            .item-top {
                padding: 8px;
            }

            .item-bottom {
                border-top: 1px solid #ebedf0;
                display: flex;
                align-items: center;
                height: 35px;

                .bottom-item-style {
                    flex: 1;
                    border-right: 1px solid #E6E6E6;
                    text-align: center;

                    .text {
                        font-size: 13px;
                        font-family: Source Han Sans SC;
                        font-weight: 400;
                        color: #4F7AFD;
                    }

                    .text1 {
                        font-size: 13px;
                        font-family: Source Han Sans SC;
                        font-weight: 400;
                        color: #cccccc;
                    }
                }

                div:last-child {
                    border: none;
                }
            }

            .right-arrow {
                top: 30%;
                width: 20px;
                height: 20px;
                position: absolute;
                z-index: 1;
                right: 15px;
            }
        }
    }

    .dialog-title {
        text-align: left;
        padding-left: 20px;
    }
    .scan-QR {
        width: 90px;
        height: 48px;
        background: #4871C0;
        border-radius: 100px 0px 0px 100px;
        position: fixed;
        right: 0;
        top: 50%;
        font-size: 14px;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 3px 18px 0px rgba(0, 43, 127, 0.32);
        z-index: 2;
        .scan-icon {
            width: 20px;
            height: 20px;
            margin-right: 10px;
        }
    }
}
.signModal{
    border-radius: 10px;
    overflow: visible;
    width: 70%;
    .content{
        height: 147px;
        width: 100%;
        border-radius: 10px;
        // background-image: url('../../../assets/blueback.png');
        background-repeat: no-repeat;
        background-size:cover;
        .close_img{
            width: 31px;
            height: 31px;
            position: absolute;
            bottom: -70px;
            left: 45%;
        }
        .top_main{
            display: flex;
            align-items: center;
            padding: 20px 0px;
            width:100px;
            margin: 0 auto;
            span{
                color: #333333;
                font-size: 16px;
            }
            img{
                width: 31px;
                height: 31px;
                margin-right: 20px;
            }
        }
        p{
            color: #999999;
        }
    }
    .blue_background{
        background-image: url('../../../assets/blueback.png');
    }
    .green_background{
        background-image: url('../../../assets/greenback.png');
    }
}
</style>
<style lang="scss">
.myClient-box {
    .van-field {
        background: #f2f3f5;
    }

    .input-style {
        .van-cell__value {
            border: 1px solid #e5e7eb;
            border-radius: 5px;

            input {
                padding-left: 10px;
            }
        }
    }
}
</style>