<template>
    <div class="projectplan-style">
        <headerNav title="查看" leftNav="返回" @leftClick="back"></headerNav>
        <div class="content-style">
            <pullRefresh ref="pullRefresh" @refresh="onRefresh">
                <van-list class="vant-list-style" v-model="loading" :finished="finished"
                    :finished-text="total ? '·已加载全部数据·' : '·暂无数据·'" @load="onLoad">
                    <div class="item-style" v-for="(item, index) in list" :key="index">
                        <div class="img-style">
                            <img :src="item.webUrl" alt="" style="width: 100%;height: 100%;" @click="photoPreview(item)">
                        </div>
                        <div class="item-center">
                            <div class="item-center-row">
                                <div class="label">动作：</div>
                                <div class="value">{{ item.operateName }}</div>
                            </div>
                            <div class="item-center-row">
                                <div class="label">操作人：</div>
                                <div class="value">{{ item.createName }}</div>
                            </div>
                            <div class="item-center-row">
                                <div class="label">操作时间：</div>
                                <div class="value">{{ item.createDate }}</div>
                            </div>
                        </div>
                    </div>
                </van-list>
            </pullRefresh>
        </div>
    </div>
</template>

<script>
import api from '@/api/api.js'
import { Dialog, Toast, ImagePreview } from 'vant'
export default {
    data() {
        return {
            list: [],
            info: {},
            projectPlanInfo: {},
            isLoading: false,
            loading: false,
            finished: false,
            error: false, // 是否加载失败
            pageNum: 1, // 分页
            pageSize: 10, // 每页条数
            total: 0 // 数据总条数
        }
    },
    methods: {
        back() {
            this.$router.push({
                name: "deepenCheckProjectPlanName",
            })
        },
        //获取列表
        async getList() {
            let obj = {
                Q_nodeId_EQ: this.projectPlanInfo.id,
                page: this.pageNum,
                limit: this.pageSize
            }
            let res = await api.checkProjectPlanRecord(obj)
            if (res.code == 200) {
                const arr = res.data || [];
                this.total = arr.length;
                if (res.data.length === 0) { // 判断获取数据条数若等于0
                    this.list = []; // 清空数组
                    this.finished = true; // 停止加载
                } else {
                    // 若数据条数不等于0
                    this.list.push(...arr) // 将数据放入list中
                    // this.loading = false; // 加载状态结束 
                    // 如果list长度大于等于总数据条数,数据全部加载完成
                    if (this.list.length >= arr.length) {
                        this.finished = true; // 结束加载状态
                    }
                }
                if (this.$refs.pullRefresh) {
                    this.$refs.pullRefresh.refreshSuccess()
                }
            }
        },
        // 被 @load调用的方法
        onLoad() { // 若加载条到了底部
            let timer = setTimeout(() => { // 定时器仅针对本地数据渲染动画效果,项目中axios请求不需要定时器
                // this.getList(); // 调用上面方法,请求数据
                this.getList()
                this.pageNum++; // 分页数加一
                this.finished && clearTimeout(timer); //清除计时器
            }, 100);
        },
        // 加载失败调用方法
        onRefresh() {
            this.finished = false; // 清空列表数据
            this.loading = true; // 将 loading 设置为 true，表示处于加载状态
            this.pageNum = 1; // 分页数赋值为1
            this.list = []; // 清空数组
            this.onLoad(); // 重新加载数据
        },
        //图片预览
        photoPreview(item) {
            let o = {
                images: [item.webUrl],
                showIndex: false
            }
            ImagePreview(o);
        },
    },
    mounted() {
        this.projectPlanInfo = JSON.parse(sessionStorage.getItem('projectPlanInfo'))
        this.info = JSON.parse(sessionStorage.getItem('clientInfo'))
    },
}
</script>

<style scoped lang="scss">
.projectplan-style {
    .content-style {
        overflow: auto;
        margin-top: 10px;

        .item-style {
            padding: 12px;
            display: flex;
            margin-bottom: 16px;
            background: #FFFFFF;

            .img-style {
                width: 72px;
                height: 72px;
                border-radius: 4px;
                overflow: hidden;
            }

            .item-center {
                font-size: 14px;
                flex: 1;
                margin-left: 12px;
                text-align: left;

                .item-center-row {
                    margin-top: 4px;
                    display: flex;

                    .label {
                        color: #888;
                    }
                    .value{
                        flex: 1;
                    }
                }
            }

            .item-right {
                width: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: red;
            }
        }
    }

    ::v-deep .van-pull-refresh {
        min-height: calc(100vh - 108px);
    }

    .buttons {
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 48px;
        line-height: 48px;
        display: flex;
        background-color: #fff;
        border-top: 1px solid #eee;

        div {
            flex: 1;
        }

        div:nth-child(2) {
            border-left: 1px solid #eee;
        }
    }
}
</style>
