<template>
    <div class="finishUpProject-style">
        <headerNav title="待上传" leftNav="返回" @leftClick="cancel"></headerNav>
        <div class="content-style">
            <img :src="require('@/assets/tobeupdated.png')" alt="" width="50%">
            <p>待项目经理上传中...</p>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            // 区分是深化还是工程
            routerName:'',
        }
    },
    methods: {
        cancel() {
            this.$router.back()
        },
    },
    created() {
        this.routerName = this.$route.query.routerName
    },
}
</script>

<style scoped lang="scss">
.finishUpProject-style {
    .content-style {
        height: calc(100vh - 155px);
        overflow: auto;
        padding-bottom: 20px;
        margin: auto;
        background: #fff;
        padding-top: 82px;
        img{
            width: 287px;
            height: 254px;
        }
        p{
            color: #333333;
            font-size: 18px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
        }
    }

}
</style>
