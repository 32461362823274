<template>
    <div>
        <headerNav title="单据详情" leftNav="返回" @leftClick="back"></headerNav>
        <div class="content-style">
            <div class="item-style">
                <div class="item-top">
                    <van-tag v-if="materialsDetails.stockStatus=='1'" round type="primary" size="medium">已备货</van-tag>
                    <van-tag v-else-if="materialsDetails.stockStatus=='2'" round  type="success" size="medium">已领取</van-tag>
                    <van-tag v-else round  type="warning" size="medium">未备货</van-tag>
                </div>
                <div class="item-content">
                    <div>单据编号:{{ materialsDetails.applyCode }}</div>
                    <div>申领种类:{{ materialsDetails.applyQty }}种</div>
                    <div>工程阶段:{{ materialsDetails.nodeName }}</div>
                    <div>申领时间:{{ materialsDetails.createDate }}</div>
                    <div style="width: 100%">订单备注:{{ materialsDetails.remark }}</div>
                    <div v-if="materialsDetails.receiveDate">领取时间:{{ materialsDetails.receiveDate }}</div>
                    <div  v-if="materialsDetails.stockDate">备货时间:{{ materialsDetails.stockDate }}</div>
                </div>
            </div>
            <div class="content-list">
                <div class="list-item" v-for="(item, index) in list">
                    <van-image class="left" :src="item.imgUrl" @click="photoPreview(item.imgUrl)" />
                    <div class="center">
                        <div class="name">{{ item.itemName }}</div>
                        <div>{{ item.unitName }}</div>
                    </div>
                    <div class="right">
                        {{ item.itemQty }}
                    </div>
                </div>
            </div>
        </div>
        <div class="bottom-btn">
            <div class="btn-box" v-if="materialsDetails.stockStatus=='1'">
                <van-button type="info" :loading="getLoading" loading-text="领取中..." round @click="changeState(materialsDetails,'2')">已领取</van-button>
            </div>
            <div class="btn-box" v-if="!materialsDetails.stockStatus || materialsDetails.stockStatus=='0'">
                <van-button type="info" :loading="stockLoading" loading-text="备货中..." round @click="changeState(materialsDetails,'1')">已备货</van-button>
            </div>
        </div>
    </div>
</template>

<script>
import { Toast, ImagePreview } from 'vant';
import api from '@/api/api.js'
export default {
    data () {
        return {
            list: [],
            materialsDetails: {},
            stockLoading:false,
            getLoading:false,
        }
    },
    methods: {
        //返回
        back () {
            this.$router.push({ name: 'storageListName' })
        },
        //获取详情
        async getMaterialsDetails () {
            let obj = {
                Q_applyId_EQ: this.materialsDetails.id
            }
            let res = await api.getMaterialsDetails(obj)
            if (res.code == 200) {
                this.list = res.data
            }
        },
        //改变订单状态
        async changeState (item, state) {
            if (state == 1) this.stockLoading = true
            if (state == 2) this.getLoading = true
            let obj = {
                id: item.id,
                stockStatus: state,//1，已备货；2，已领取
            }
            try {
                let res = await api.changeState(obj)
                if (res.code == 200) {
                    if (state == 1) this.stockLoading = false
                    if (state == 2) this.getLoading = false
                    let msg = state == '1' ? '备货成功！' : '领取成功'
                    Toast.success(msg);
                    this.$router.push({ name: 'storageListName' })
                }
            } catch (error) {
                if (state == 1) this.stockLoading = false
                if (state == 2) this.getLoading = false
            }
        },
        photoPreview (url) {
            ImagePreview([url]);
        },
    },
    created () {
        this.materialsDetails = JSON.parse(sessionStorage.getItem('materialsDetails'))
        this.getMaterialsDetails()
    },
}
</script>

<style lang="scss" scoped>
.content-style {
    font-size: 12px;
    padding-top: 10px;
    height: calc(100vh - 60px);
    overflow: auto;

    .item-style {
        padding-top: 10px;
        background: #FFFFFF;
        border-radius: 6px;
        box-shadow: 0px 6px 8px 2px rgba(0, 0, 0, 0.1);
        height: 130px;
        overflow: auto;

        .item-top {
            text-align: right;
            padding-right: 10px;
        }

        .item-content {
            padding: 0 10px;
            display: flex;
            flex-flow: wrap;

            div {
                width: 50%;
                margin: 5px 0;
                text-align: left;
            }
        }
    }

    .content-list {
        margin-top: 10px;
        height: calc(100vh - 210px);
        overflow: auto;
        background: #FFFFFF;

        .list-item {
            border-bottom: 1px solid #E6E6E6;
            height: 70px;
            display: flex;
            padding: 10px 10px;
            align-items: center;

            .left {
                width: 60px;
                height: 60px;
            }

            .center {
                width: calc(100% - 100px);
                padding: 0 5px;

                div {
                    text-align: left;
                }

                .name {
                    height: 30px;
                }
            }

            .right {
                width: 40px;
            }
        }
    }
}

.bottom-btn {
    position: fixed;
    bottom: 20px;
    width: 100%;
    display: flex;
    .btn-box{
        flex:1;
        justify-content: center;
    }
    button {
        width: 80%;
    }
}
</style>