<template>
  <div class="content">
    <headerNav leftNav=" " :title="title" @leftClick="back"></headerNav>
    <main class="content-main" :class="isShowDay == '3'?'content-main-history':''" >
      <!-- 步骤条 -->
      <TaskProgress :perform="{ taskGoalCount: Number(outputValue.productionJobValue||0).toFixed(2), percentageRate: Number(outputValue.finishRate||0) }" 
      :percentageMoney="Number(outputValue.finishValue||0).toFixed(2)" :unit="unit" :isShowDay="isShowDay== '1'?true:false" :monthProps="month"/>

      <!-- 督导详情 -->
      <div class="content-center-title">
        <div class="left-line"></div>
        <div>督导详情</div>
      </div>

      <!-- table -->
      <el-table :data="supervisorData" style="width: 100%" header-cell-class-name="market-title"
        row-class-name="market-row" row-key="id" >
        <template slot="empty">
          <div class="empty-no-data">
            <img src="../../../assets/task-management/no-data.png" alt="">
            <div>暂无数据</div>
          </div>
        </template>
        <el-table-column type="index" label="TOP" align="center" width="40">
          <template slot-scope="props">
            <div style="line-height: 1;" v-if="props.$index < 3">
              <img class="index-img"  :src="getImageIndex(props.$index)" alt="">
            </div>
            <div v-else> {{ props.$index + 1 }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="departmentName" label="部门" min-width="44">
          <template slot-scope="scope">
            <span class="market-content-a">{{ scope.row.departmentName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="finishValue" :label="outputCount.transferProductionValue" align="right" min-width="85"></el-table-column>
        <el-table-column prop="productionJobValue" :label="outputCount.taskProductionValue" align="right" min-width="85"></el-table-column>
        <el-table-column prop="finishRate" label="完成率" align="right" min-width="67"></el-table-column>
      </el-table>

      <!-- 转单详情 -->
      <div class="content-center-title">
        <div class="left-line"></div>
        <div>转单详情</div>
        <!-- <div class="right-button" v-if="visibleDistribute&&isShowDay != '3'" @click="taskAllocation">任务分配</div> -->
      </div>

      <!-- table -->
      <div class="table-list">
        <el-table :data="transferTableData" style="width: 100%" header-cell-class-name="market-title"
          row-class-name="market-row" row-key="id">
          <template slot="empty">
            <div class="empty-no-data">
              <img src="../../../assets/task-management/no-data.png" alt="">
              <div>暂无数据</div>
            </div>
          </template>
          <el-table-column type="index" label="TOP" align="center" width="40">
            <template slot-scope="props">
              <div style="line-height: 1;" v-if="props.$index < 3">
                <img class="index-img"  :src="getImageIndex(props.$index)" alt="">
              </div>
              <div v-else> {{ props.$index + 1 }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="departmentName" label="部门" min-width="44">
            <template slot-scope="scope">
              <span class="market-content-a">{{ scope.row.departmentName }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="finishValue" :label="outputCount.transferProductionValue" align="right" min-width="85"> </el-table-column>
          <el-table-column prop="productionJobValue" :label="outputCount.taskProductionValue" align="right" min-width="85"></el-table-column>
          <el-table-column prop="finishRate" label="完成率" align="right" min-width="67"></el-table-column>
        </el-table>
      </div>
    </main>
    <div class="bottom-button" v-if="isShowDay != '3'&&updateStatus">
      <div @click="updataTask">任务修改</div>
    </div>
    <div class="bottom-button" v-else-if="isShowDay != '3'&&!updateStatus">
      <div class="disable">任务修改</div>
    </div>

  </div>
</template>

<script>
import { Toast } from 'vant';
import api from "@/api/task-management/setup.js";
import TaskProgress from "@/components/task-management/task-progress.vue";
import estimateOutput from "../component/data.js"
export default {
  components: {
    TaskProgress
  },
  data () {
    return {
      title:'转单产值详情',
      queryInfo:{},
      taskTypeList:[
        { text: "转单产值", value: "transfer_output", unit:'万',max:5 },
        { text: "补齐产值", value: "supply_output", unit:'万',max:5 },
        { text: "转单量", value: 'transfer_count', unit:'个',max:4 },
        { text: "补齐量", value: 'supply_count', unit:'个',max:4 },
        { text: "签单量", value: 'signed_count', unit:'个',max:4 },
        { text: "到店量", value: 'shop_count', unit:'个',max:4 },
        { text: "线索量", value: 'clue_count', unit:'个',max:4 },
      ],
      isShowDay:'',
      month:'',
      unit:"万",
      // 产值详情 (进度条)
      outputValue:{},
      // 表格数据
      supervisorData: [],
      transferTableData:[],
      // 表头动态展示内容
      outputCount: {},
      // 任务修改按钮状态
      updateStatus:false
    }
  },
  created () { },
  mounted() {
    let _this = this;
    this.queryInfo = JSON.parse(this.$route.query.item)
    let {id,roleCode,termId,taskDate,productionJobValue,finishRate,finishValue,} = this.queryInfo
    this.month = taskDate.split('-')[1]
    this.outputCount = estimateOutput(termId)
    this.taskTypeList.forEach((item) => {
      if(item.value == termId){
        this.unit = item.unit
        this.title = item.text + "详情"
      }
    });
    this.isShowDay = this.$route.query.type
    this.getTableList(id)
  },
  methods: {
    back () {
      this.$router.go(-1)
    },
    // 获取图片
    getImageIndex (index) {
      return require(`@/assets/task-management/task-details-table-${index + 1}.png`)
    },
    // 获取表格数据
    getTableList(id){
      let params = {
        'Q_id_EQ':id,
        ext:JSON.stringify({'monthType':this.$route.query.type})
      }
      api.getTransferDetail(params).then((res)=>{
        Toast.loading({
          message: '加载中...',
          forbidClick: true,
        });
        if(res.code == 200){
          Toast.clear()
          this.supervisorData = [...res.data.duDaoData]
          this.outputValue = {
            ...this.supervisorData[0],
            unit:this.unit
          }
          let duDaoData = [...res.data.duDaoData]
          duDaoData.forEach((item)=>{
            if(item.departmentName.indexOf('部') != -1){
              item.departmentName = item.departmentName.slice(0,item.departmentName.indexOf('部'))
            }
            if(this.title.indexOf('产值') != -1){
              item.finishValue = item.finishValue == 0 ?'-':(item.finishValue).toFixed(2)
              item.productionJobValue = item.productionJobValue == 0 ?'-':(item.productionJobValue).toFixed(2)
            }else{
              item.finishValue = item.finishValue == 0 ?'-':item.finishValue 
              item.productionJobValue = item.productionJobValue == 0 ?'-':item.productionJobValue
            }
            item.finishRate = item.finishRate == 0 ?'-':(item.finishRate).toFixed(2) +'%'
          })
          res.data.transferData.forEach((item)=>{
            if(item.departmentName.indexOf('部') != -1){
              item.departmentName = item.departmentName.slice(0,item.departmentName.indexOf('部'))
            }
            if(this.title.indexOf('产值') != -1){
              item.finishValue = item.finishValue == 0 ?'-':(item.finishValue).toFixed(2)
              item.productionJobValue = item.productionJobValue == 0 ?'-':(item.productionJobValue).toFixed(2)
            }else{
              item.finishValue = item.finishValue == 0 ?'-':item.finishValue 
              item.productionJobValue = item.productionJobValue == 0 ?'-':item.productionJobValue
            }
            item.finishRate = item.finishRate == 0 ?'-':(item.finishRate).toFixed(2) +'%'
          })
          this.transferTableData = res.data.transferData || []
          this.updateStatus = res.data.allowEdit
        }else{
          Toast.fail(res.msg)
        }
      })
    },
    // 任务修改
    updataTask(){
      this.$router.push({
        name:'TaskManagementSetUpTaskModifyForegroundDirector',
        query:{
          item:JSON.stringify(this.queryInfo),
          detailItem:JSON.stringify(this.supervisorData),
          type:this.$route.query.type
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.content ::v-deep {

  .empty-no-data {
    img {
      width: 160px;
      height: 150px;
    }

    div {
      color: #5E5E5E;
      font-size: 14px;
      margin-top: -40px;
    }
  }
  .content-main {
    background: #F5F5F5;
    height: calc(100vh - 135px);
    overflow: auto;

    .content-center-title {
      height: 47px;
      line-height: 47px;
      display: flex;
      align-items: center;
      background: #fff;
      padding: 0px 10px;
      margin-top: 16px;
      position: relative;

      .left-line {
        width: 4px;
        height: 18px;
        background: #0E8BFF;
        margin-right: 8px;
      }

      div {
        color: #333333;
        font-size: 18px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: 550;
      }

      .right-button{
        width: 78px;
        line-height: 28px;
        background: #0E8BFF;
        border-radius: 4px 4px 4px 4px;
        font-size: 14px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #FFFFFF;
        position: absolute;
        right: 10px;
      }
    }

    .market-title {
      background-color: #F5F5F5;
      color: #1D2129;
      font-size: 14px;
      font-family: PingFang SC-Bold, PingFang SC;
      padding: 0px 0px;

      .cell {
        font-size: 13px;
        font-weight: 500;
        padding: 10px 3px;
      }
    }
    
    .table-list{
      .el-table__body-wrapper {
        overflow: auto;
        height: calc(100vh - 375px);
      }
    }

    .market-row {
      font-size: 16px;
      color: #1D2129;

      .el-table__cell {
        padding: 3px 0px;
        height: 30px;
      }

      .cell {
        font-weight: 400;
        padding: 5px 3px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .el-table__expand-colum {
      visibility: hidden;
    }

    .market-details {
      color: #0E8BFF;
      font-size: 13px;
    }

    .market-content-a {
      font-size: 13px;
      font-weight: 400;
    }

    .index-img {
      width: 20px;
      height: 20px;
    }

    .market-content-expand {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 11px 20px;
      border-bottom: 1px solid #F1F1F1;

      .market-content-expand-left {
        display: flex;
        align-items: center;

        img {
          width: 20px;
          height: 20px;
        }

        div {
          color: #545454;
          font-size: 15px;
          margin-left: 10px;
        }
      }

      .market-content-expand-right {
        width: 46px;
        height: 22px;
        line-height: 22px;
        font-size: 13px;
        text-align: center;
        font-weight: bold;
        font-family: PingFang SC-Bold, PingFang SC;

        span {
          padding: 2px 3px;
        }

        .color-red {
          color: #FF6157;
          background: #FAEBEA;
        }

        .color-green {
          color: #17CA5E;
          background: #E3F5EA;
        }
      }
    }

    .market-content-expand-row {
      display: flex;
      justify-content: end;
      margin: 14px 11px 4px;

      .market-content-expand-seting {
        display: flex;
        align-items: center;
        width: 91px;
        height: 28px;
        line-height: 28px;
        border: 1px solid #3DA2FF;
        color: #0E8BFF;
        font-size: 14px;
        border-radius: 4px;
        padding-left: 6px;
        box-sizing: border-box;
        cursor: pointer;
        user-select: none;

        img {
          width: 18px;
          height: 18px;
          margin: 1px 2px 0px 0;
        }
      }
    }
  }

  .content-main-history{
    height: calc(100vh - 52px);
  }

  .set-task-dialog {
    .set-task-dialog-nav {
      text-align: center;
      margin: 12px 0px;
      padding: 0 17px 12px 17px;
      font-weight: bold;
      color: #424242;
      font-size: 16px;
      border-bottom: 1px solid #DFDFDF;

      .set-task-dialog-nav-close {
        float: right;
        font-size: 15px;
      }
    }

    .set-task-dialog-mian {
      padding: 16px 0 0;

      .van-field__body {
        border-bottom: 1px solid #BEBEBE;
      }

      .van-field__label {
        margin-right: 0;
        color: #252523;
        font-size: 16px;
      }

      .van-cell::after {
        border: 0;
      }

      .set-task-dialog-foot {
        // width: 100%;
        text-align: center;
        padding: 0px 14px 18px 15px;
        margin-top: 16px;

        .btn {
          border-radius: 4px;
          // width: 290px;
          // height: 44px;
          background: #3975C6;
        }
      }

    }

  }

  .bottom-button{
    width: 100%;
    background: #FFFFFF;
    padding:10px 18px 30px;
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    left: 0;
    & > div{
      line-height: 44px;
      background: #4871C0;
      border-radius: 8px 8px 8px 8px;
      font-size: 18px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      text-align: center;
    }
    .disable{
      background: #ccc;
    }
  }
}
</style>