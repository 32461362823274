<template>
    <div class="main_box">
        <headerNav leftNav="返回" title="销售单详情" @leftClick="back"></headerNav>
        <div :class="isShowPlace == '提交' || isShowPlace == '下单/分包' ? 'height_style' : 'height_style1'">
            <div class="info_main">
                <div class="info_nav">
                    <div class="info_left">
                        {{ customerDetails.consumerName && customerDetails.consumerName.length > 2 ?
                            customerDetails.consumerName.substring(0, 2)
                            : customerDetails.consumerName }}
                    </div>
                    <div class="info_right">
                        <div class="right_flex">
                            <div class="right_a">{{ customerDetails.consumerName }}</div>
                            <div class="right_b">
                                <img src="../../../assets/phoneDeepen.png" alt="" class="img">
                                <span>{{ customerDetails.phoneNo }}</span>
                            </div>
                        </div>
                        <div class="right_bottom">
                            <span class="span_back">合同号</span>
                            <span class="span_code">{{ customerDetails.maintainContract ? customerDetails.maintainContract :
                                customerDetails.reserveContract }}</span>
                        </div>
                    </div>
                </div>
                <div class="info_content">
                    <div class="cont_nav">
                        <div class="cont_mian fenge">
                            <div class="cont_main_top">{{ customerDetails.orderTypeName }}</div>
                            <div class="cont_main_bottom">订单类型</div>
                        </div>
                        <div class="cont_mian fenge">
                            <div class="cont_main_top">{{ customerDetails.amount }}</div>
                            <div class="cont_main_bottom">总金额</div>
                        </div>
                        <div class="cont_mian fenge">
                            <div class="cont_main_top">{{ customerDetails.orderStatusName }}</div>
                            <div class="cont_main_bottom">状态</div>
                        </div>
                        <div class="cont_mian">
                            <div class="cont_main_top">{{ customerDetails.receiptStatus == 1 ? '已完成' : '未完成' }}</div>
                            <div class="cont_main_bottom">收款状态</div>
                        </div>
                    </div>
                    <div class="cont_main" v-if="isSub">
                        <div class="cont_flex">
                            <div class="flex_a">提交日期:</div>
                            <div class="flex_b">{{ customerDetails.submitDate }}</div>
                        </div>
                        <div class="cont_flex">
                            <div class="flex_a">创建日期:</div>
                            <div class="flex_b">{{ customerDetails.createDate }}</div>
                        </div>
                        <div class="cont_flex">
                            <div class="flex_a">收款进度:</div>
                            <div class="flex_b">{{ customerDetails.receiptProcess + '%' }}</div>
                        </div>
                        <div class="cont_flex">
                            <div class="flex_a">是否下单/分包:</div>
                            <div class="flex_b">{{ customerDetails.isAllot == '0' ? '否' : '是' }}</div>
                        </div>
                        <div class="cont_flex">
                            <div class="flex_a">制单人:</div>
                            <div class="flex_b">{{ customerDetails.createName }}</div>
                        </div>
                    </div>
                    <div class="content_bottom" @click="handleChangeSub">
                        <div class="flex">
                            {{ isSub ? '收起更多' : '查看更多' }}
                            <img :class="isSub ? '' : 'jiantou'" src="../../../assets/sqgdpng.png" alt=""
                                style="width:12px;height:12px;margin-left:4px">
                        </div>
                    </div>
                </div>
            </div>
            <div class="bottom_table">
                <el-table :data="dataTable" style="width: 100%" v-if="dataTable.length > 0"
                    header-row-class-name="header_style">
                    <el-table-column prop="itemName" label="项目名称" width="140" align="left" fixed="left">
                        <template slot-scope="scope">
                            <el-tooltip :content="scope.row.itemName" placement="top">
                                <div class="content_over">{{ scope.row.itemName }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column prop="itemNo" label="定额编号" align="left" width="100">
                        <template slot-scope="scope">
                            <el-tooltip :content="scope.row.itemNo" placement="top">
                                <div class="content_over">{{ scope.row.itemNo }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column prop="itemUnit" label="单位" align="center" width="100">
                    </el-table-column>
                    <el-table-column prop="amount" label="增减量" align="center" width="100">
                        <template slot-scope="scope">
                            <span :class="scope.row.amount && Number(scope.row.amount) > 0 ? '' : 'color_red'">{{
                                scope.row.amount }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="sellingPrice" label="单价" align="right" width="100">
                    </el-table-column>
                    <el-table-column prop="sellingTotal" label="合计价" align="right" width="100">
                    </el-table-column>
                    <el-table-column prop="remark" label="说明" align="left" width="130">
                        <template slot-scope="scope">
                            <el-tooltip :content="scope.row.remark" placement="top">
                                <div class="content_over">{{ scope.row.remark }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column prop="createDate" label="下单日期" align="left" width="180">
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div class="bottom" v-if="isShowPlace == '提交' || isShowPlace == '下单/分包'">
            <van-button class="bottom_btn" @click="handleSubmit(isShowPlace)">{{ isShowPlace }}</van-button>
        </div>
        <!-- 查看分包单 -->
        <div class="subpackage" v-if="customerDetails.isAllot == '1' && customerDetails.packageId">
            <img src="../../../assets/ckfbd.png" alt="" class="img">
            <van-button class="subpackage_btn" @click="handleSubpackage">查看分包单</van-button>
        </div>
    </div>
</template>

<script>
import { Dialog, Toast } from 'vant'
import api from '../../../api/api'
export default {
    components: {
    },
    data () {
        return {
            isSub: false,
            dataTable: [],
            // 详情传值
            customerDetails: {},
            // 显示提交或者下单分包
            isShowPlace: '',
            // 销售单id
            customerId: '',
            // 是否滚动
            iscroll: false
        }
    },
    props: {

    },
    methods: {
        back () {
            this.$router.go(-1)
        },

        // 切换收起展开
        handleChangeSub () {
            this.isSub = !this.isSub
        },
        // 查询详情
        async handleDetails () {
            const { data } = await api.SearchDetails(this.customerId)
            console.log(data, "详情")
            this.customerDetails = data
            if (this.customerDetails.orderStatus == '1000' || this.customerDetails.orderStatus == '3000' || this.customerDetails.orderStatus == '5000' || this.customerDetails.orderStatus == '7000') {
                this.isShowPlace = '提交'
            }
            if (this.customerDetails.showAllot == '1' && this.customerDetails.orderStatus != '9000') {
                this.isShowPlace = '下单/分包'
            }
        },
        // 查询表格数据
        async handleTableData () {
            const { data } = await api.queryTableSale(this.customerId)
            data.map(item => {
                item.showPopover1 = false
                item.showPopover2 = false
                item.showPopover3 = false
                item.showPopover4 = false
            })
            this.dataTable = data

        },
        // 监听表格滚动
        handleTableScroll () {
            const tableContainer = this.$refs.tableContainer;
            const scrollLeft = tableContainer.scrollLeft;
            this.iscroll = scrollLeft > 0
        },
        // 提交
        async handleSubmit (name) {
            await Dialog.confirm({
                title: name == '下单/分包' ? '确认下单吗？' : '确认提交给预审吗？',
                confirmButtonColor: '#0066FF'
            })
            if (name == '下单/分包') {
                await api.palceSale([this.customerId])
            } else {
                await api.submitSale({ ids: [this.customerId] })
            }
            Toast.success('操作成功!');
            await this.handleDetails()
            this.$set(this, 'isShowPlace', '');

        },
        // 查看分包单
        handleSubpackage () {
            this.customerDetails.id = this.customerDetails.packageId
            sessionStorage.setItem("clientInfo1", JSON.stringify(this.customerDetails));
            sessionStorage.setItem("fromRouterNameEngineering", "engineeringSubpackageDetailList");
            this.$router.push({ name: "engineeringSubpackageDetail", query: { routerName: '深化' } });
        }
    },
    mounted () {

    },
    created () {
        console.log(this.$route.params, "l;iuyop")
        this.customerId = this.$route.params.id
        this.handleTableData()
        this.handleDetails()
    }
}
</script>

<style lang="scss" scoped>
.main_box {
    .color_blue {
        color: #3975C6;
    }

    .color_red {
        color: #EB5757;
    }

    .info_main {
        background: #3975C6;
        padding: 12px;

        .info_nav {
            display: flex;
            align-items: center;

            .info_left {
                width: 72px;
                height: 72px;
                border-radius: 100%;
                border: 1.5px solid #9AC5FF;
                background: #FFFFFF;
                color: #3975C6;
                font-weight: bold;
                line-height: 72px;
                text-align: center;
                font-size: 22px;
                font-family: PingFang SC-Heavy, PingFang SC;
                // flex: 3;
            }

            .info_right {
                margin-left: 12px;
                width: 265px;

                .right_flex {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .right_a {
                        font-size: 22px;
                        color: #FFFFFF;
                        font-family: PingFang SC-Bold, PingFang SC;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    .right_b {
                        // width: 116px;
                        height: 24px;
                        // line-height: 24px;
                        border-radius: 44px 44px 44px 44px;
                        font-size: 14px;
                        color: #FFFFFF;
                        background: #1D5CAE;
                        font-family: PingFang SC-Bold, PingFang SC;
                        box-sizing: border-box;
                        display: flex;
                        align-items: center;
                        padding: 0px 8px;

                        .img {
                            width: 15px;
                            height: 15px;
                            margin-right: 1px;
                            // vertical-align: middle;
                        }
                    }
                }

                .right_bottom {
                    text-align: left;
                    margin-top: 8px;
                    display: flex;
                    align-items: center;

                    .span_back {
                        background: #FFFFFF;
                        border-radius: 1px;
                        margin-right: 6px;
                        color: #3975C6;
                        font-size: 11px;
                        padding: 1px 4px;
                        font-family: PingFang SC-Regular, PingFang SC;
                    }

                    .span_code {
                        color: #FFFFFF;
                        font-size: 15px;
                        font-family: PingFang SC-Medium, PingFang SC;
                    }
                }
            }
        }

        .info_content {
            margin-top: 16px;

            .cont_nav {
                background: #2569C3;
                border-radius: 4px;
                display: flex;
                justify-content: space-around;
                color: #FFFFFF;

                .cont_mian {
                    padding: 12px 5px;
                    position: relative;
                    // flex: 1;

                    .cont_main_top {
                        font-size: 15px;
                        font-weight: medium;
                        margin-bottom: 2px;
                        font-family: PingFang SC-Medium, PingFang SC;
                        flex-wrap: nowrap;
                    }

                    .cont_main_bottom {
                        font-size: 13px;
                        color: #FBFBFB;
                        font-family: PingFang SC-Regular, PingFang SC;
                    }
                }

                .fenge::after {
                    position: absolute;
                    content: '';
                    width: 1px;
                    height: 18px;
                    opacity: 1;
                    right: -10px;
                    top: 26px;
                    // border: 1px solid #3F77C0;
                    background: #3F77C0;
                }
            }

            .cont_main {
                border-radius: 4px;
                background: #2569C3;
                margin-top: 1px;
                padding: 9px 8px;

                .cont_flex {
                    display: flex;
                    justify-content: space-between;
                    margin: 9px 0px;

                    .flex_a {
                        color: #FFFFFF;
                        font-size: 13px;
                        font-family: PingFang SC-Regular, PingFang SC;

                    }

                    .flex_b {
                        font-size: 15px;
                        color: #FFFFFF;
                        font-weight: medium;
                        font-family: PingFang SC-Medium, PingFang SC;
                    }
                }
            }

            .content_bottom {
                width: 100%;
                color: #ECF4FF;
                font-size: 12px;
                margin: 12px 0px 0px 0px;

                .flex {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-family: PingFang SC-Regular, PingFang SC;
                }

                .jiantou {
                    transform: rotate(180deg);
                    transition: all 0.3s;
                }
            }
        }

    }

    .height_style {
        height: calc(100vh - 150px);
        overflow: auto;
    }

    .height_style1 {
        height: calc(100vh - 51px);
        overflow: auto;
    }

    .bottom_table {
        width: 100%;
        overflow-x: auto;
        margin-top: 12px;

        .header_style {
            th {
                background: #eeeeee;
                font-family: PingFang SC-Medium, PingFang SC;
                font-size: 13px;
            }
        }
    }

    .bottom {
        position: fixed;
        bottom: 0;
        height: 100px;
        background: #FFFFFF;
        width: 100%;
        line-height: 80px;
        z-index: 2;

        .bottom_btn {
            background: #3975C6;
            color: #ffffff;
            width: 299px;
            border-radius: 5px;
            height: 58px;
        }
    }

    .subpackage {
        position: fixed;
        bottom: 120px;
        right: 10px;
        position: relative;
        opacity: 1;

        .img {
            width: 72px;
            height: 72px;
            right: 10px;
            position: absolute;
            right: 15px;

        }

        .subpackage_btn {
            border: 1px solid #1677FF;
            border-radius: 64px;
            color: #1677FF;
            width: 105px;
            height: 28px;
            font-size: 14px;
            position: absolute;
            right: 0px;
            top: 50px;
            box-shadow: 0px 3px 18px 0px #CFE0FF;
        }
    }
}

.content_over {
    white-space: nowrap;
    /* 不换行 */
    overflow: hidden;
    /* 超出部分隐藏 */
    text-overflow: ellipsis;
    width: 140px;
}
</style>
<style lang="scss">

.el-table th.el-table__cell {
    background: #eeeeee !important;
    height: 50px;
    color: #1D2129;
    font-size: 13px;
    font-family: PingFang SC-Medium, PingFang SC;
}

.el-table .cell {
    color: #1D2129;
    font-size: 14px;
    white-space: nowrap;
    /* 不换行 */
    overflow: hidden;
    /* 超出部分隐藏 */
    text-overflow: ellipsis;
}
</style>