<template>
    <div>
        <div v-if="!linkmanList.length">暂无数据</div>
        <div v-for="(item, index) in linkmanList" class="list-item">
            <span class="time">{{ item.createDate }}</span>
            <div class="name">{{ item.contactName }}</div>
            <div class="relation">
                关系：{{ item.contactRelation }}
                <van-icon name="delete-o" color="#AAAAAA" size="20" @click="delLinkman(item)" />
            </div>
            <div class="phone">联系电话：{{ item.phoneNo }}</div>
        </div>
        <van-icon name="add" class="add" size="50" color="#4F7AFD" @click="add" />
        <van-popup v-model="addModal" get-container="body" duration="0" :overlay="false" class="linkman-popup">
            <div style="height: 100vh;width: 100vw;">
                <headerNav title="添加联系人">
                    <template>
                        <van-icon name="cross" @click="closeAdd" />
                    </template>
                </headerNav>
                <van-form ref="form" @submit="onSubmit">
                    <van-field v-model="contactRelation" name="contactRelation" label="关系" placeholder="关系"
                        class="input-style" :rules="[{ required: true, message: '请输入关系' }]" maxLength="10" />
                    <van-field v-model="contactName" name="contactName" label="姓名" placeholder="姓名" class="input-style"
                        :rules="[{ required: true, message: '请输入姓名' }]" maxLength="10" />
                    <van-field v-model="phoneNo" name="phoneNo" label="联系方式" placeholder="联系方式" class="input-style"
                        :rules="[{ required: true, message: '请输入联系方式' }, { validator: phontRule, message: '请输入正确的手机号' }]" />
                </van-form>
                <div class="bottom-btn">
                    <div @click="closeAdd" style="border-right: 1px solid #E6E6E6">取消</div>
                    <div @click="submitAdd" style="background: #4F7AFD;color: #ffffff;">确定</div>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import api from '../../../api/api';
import {
    Toast, Dialog
} from 'vant';
export default {
    data () {
        return {
            linkmanList: [],
            addModal: false,
            contactRelation: '',//关系
            contactName: '',//姓名
            phoneNo: '',//联系方式
            confirmLoading: false,
        }
    },
    methods: {
        async getLinkmanList () {
            let res = await api.getLinkmanList(this.$route.params.id)
            if (res.code == 200) {
                this.linkmanList = res.data
            }
        },
        delLinkman (item) {
            Dialog.confirm({
                title: '提示',
                message: '确定删除该联系人？',
                beforeClose: async (action, done) => {
                    if (action === 'confirm') {
                        let res = await api.delLinkman(item.id)
                        if (res.code == 200) {
                            Toast('删除成功！')
                            done()
                            this.getLinkmanList()
                        } else {
                            done(false);
                        }
                    } else {
                        done()
                    }
                },
            }).catch(() => {
                // on cancel
            });
        },
        //添加联系人
        add () {
            this.contactRelation = ''//关系
            this.contactName = ''//姓名
            this.phoneNo = ''//联系方式
            this.addModal = true
            this.$nextTick(() => {
                this.$refs.form.resetValidation()
            })
        },
        //关闭弹窗
        closeAdd () {
            this.addModal = false
        },
        //手机号验证
        phontRule (val) {
            return /^((0\d{2,3}-\d{7,8})|(1\d{10}))$/.test(val)
        },
        //提交按钮
        submitAdd () {
            this.$refs.form.submit()
        },
        //提交确定
        async onSubmit (e) {
            if (this.confirmLoading) return
            e.customerId = this.$route.params.id
            e.isCorrelation = 0
            this.confirmLoading = true
            let res = await api.addLinkman(e)
            if (res.code == 200) {
                Toast('添加成功！')
                this.getLinkmanList()
                this.addModal = false
                this.confirmLoading = false
            }
        }
    },
    created () {
        this.getLinkmanList()
    }
}
</script>

<style lang="scss" scoped>
.list-item {
    background-color: #FFFFFF;
    border-radius: 8px;
    box-shadow: 1px 1px 6px #E6E6E6;
    text-align: left;
    margin-bottom: 0;
    margin: 8px;
    padding: 10px;
    position: relative;

    .time {
        color: #AAAAAA;
        position: absolute;
        top: 10px;
        right: 30px;
    }

    .name {
        font-size: 17px;
        margin-right: 10px;
    }

    .relation,
    .phone {
        font-size: 12px;
        color: #AAAAAA;
        position: relative;
        margin-top: 5px;

        i {
            position: absolute;
            right: 0;
        }
    }
}

.add {
    position: fixed;
    bottom: 80px;

    right: 30px;
}

.bottom-btn {
    height: 40px;
    position: fixed;
    bottom: 0;
    display: flex;
    width: 100%;
    background-color: #FFFFFF;
    text-align: center;
    border-top: 1px solid #E6E6E6;

    div {
        flex: 1;
        font-size: 13px;
        height: 100%;
        line-height: 40px;
    }
}
</style>
<style lang="scss">

.linkman-popup {
    .input-style {
        .van-cell__value {
            border: 1px solid #e5e7eb;
            border-radius: 5px;
            line-height: 20px;
            padding: 3px;
        }
        .van-cell__title{
            line-height: 28px;
        }
    }
}
</style>