<template>
    <div class="messageInfo-box">
        <headerNav title="消息详情" />
        <div class="main_content">
            <!-- 申请财务放款 -->
            <div class="main_cont">
                <p class="p_1"><span class="span_p">合同号</span>{{ msgInfo.maintainContract }}</p>
                <p class="p_2">
                    <span class="span_left">{{ msgConent.messageSuggest }}</span>
                    <!-- <van-tag plain color="#3CC593" size="medium">审核通过</van-tag>
                    <van-tag plain color="#FF2D2D" v-show="false" size="medium">审核未通过</van-tag> -->
                </p>
            </div>
            <div class="content_info">
                <van-collapse v-model="activeNames">
                    <van-collapse-item title="基本资料" name="1">
                        <div class="cont_div">
                            <div class="cont_label">客户姓名：</div>
                            <div class="cont_value">{{ msgInfo.consumerName }}</div>
                        </div>
                        <div class="cont_div">
                            <div class="cont_label">合同号：</div>
                            <div class="cont_value">{{ msgInfo.maintainContract }}</div>
                        </div>
                        <div class="cont_div">
                            <div class="cont_label">项目经理：</div>
                            <div class="cont_value">{{ msgInfo.allocate?.managerName }}</div>
                        </div>
                    </van-collapse-item>
                    <van-collapse-item title="下拨申请" name="2" style="margin-top: 8px;">
                        <div class="cont_div cont_div1">
                            <div class="cont_label">下拨阶段：</div>
                            <div class="cont_value">
                                <span :style="setColor(msgInfo.allocate?.nodeCode)">{{ msgInfo.allocate?.nodeName }}</span>
                            </div>
                        </div>
                        <!-- 非完工 -->
                        <div class="cont_div cont_div1" v-if="msgInfo.allocate?.nodeName !== '完工'">
                            <div class="cont_label">预算下拨金额(元)：</div>
                            <div class="cont_value">{{ (msgInfo.budgetAllocateTotal * 1 || 0).toFixed(2) }}</div>
                        </div>
                        <div class="cont_div cont_div1" v-if="msgInfo.allocate?.nodeName !== '完工'">
                            <div class="cont_label">当前下拨比例：</div>
                            <div class="cont_value">{{ msgInfo.allocate?.ratioQtyStr }}</div>
                        </div>
                        <!-- 完工 -->
                        <div class="cont_div cont_div1" v-if="msgInfo.allocate?.nodeName == '完工'">
                            <div class="cont_label">下拨总用款(元)：</div>
                            <div class="cont_value">{{ ((msgInfo.budgetAllocateTotal * 1 || 0) + (msgInfo.changeTotal * 1 || 0)).toFixed(2) }}</div>
                        </div>
                        <div class="cont_div cont_div2" v-if="msgInfo.allocate?.nodeName == '完工'">
                            <div class="cont_label1">预算下拨金额(元)：</div>
                            <div class="cont_value1">{{ (msgInfo.budgetAllocateTotal * 1 || 0).toFixed(2) }}，</div>
                            <div class="cont_label1">调整金额(元)：</div>
                            <div class="cont_value1">{{ (msgInfo.changeTotal * 1 || 0).toFixed(2) }}</div>
                        </div>
                        <div class="cont_div cont_div1" v-if="msgInfo.allocate?.nodeName == '完工'">
                            <div class="cont_label">实际放款(元)：</div>
                            <div class="cont_value">{{ (msgInfo.grantFeeTotal * 1 || 0).toFixed(2) }}</div>
                        </div>
                    </van-collapse-item>
                </van-collapse>
            </div>
            <div class="content_info1" v-if="msgInfo.allocate?.allocateStatus == 'ITEM060831'">
                <div class="ci1_title">财务放款</div>
                <div class="ci1_text">
                    <div class="ci1t_label">下拨申请金额(元)：</div>
                    <div class="ci1t_value">{{ (msgInfo.allocate?.applyFee * 1 || 0).toFixed(2) }}</div>
                </div>
                <div class="ci1_text" v-if="msgInfo.allocate?.nodeName == '完工'">
                    <div class="ci1t_label">辅料领用金额(元)：</div>
                    <div class="ci1t_value">{{ (msgInfo.allocate?.extraAmount* 1 || 0).toFixed(2) }}</div>
                </div>
                <div class="ci1_text">
                    <div class="ci1t_label">奖励金额（元）：</div>
                    <div class="ci1t_value">{{ (msgInfo.allocate?.awardFee * 1 || 0).toFixed(2) }}</div>
                </div>
                <div class="ci1_text">
                    <div class="ci1t_label">扣款金额（元）：</div>
                    <div class="ci1t_value">{{ (msgInfo.allocate?.punishFee * 1 || 0).toFixed(2) }}</div>
                </div>
                <div class="ci1_text" v-if="msgInfo.allocate?.nodeName == '完工'">
                    <div class="ci1t_label">调整放款金额(元)：</div>
                    <div class="ci1t_value">{{ (msgInfo.allocate?.adjustAmount * 1 || 0).toFixed(2) }}</div>
                </div>
                <div class="ci1_text" v-if="msgInfo.allocate?.nodeName == '完工'">
                    <div class="ci1t_label">调整原因：</div>
                    <div class="ci1t_value">{{ msgInfo.allocate?.adjustRemark || '' }}</div>
                </div>
                <div class="ci1_text" v-if="msgInfo.allocate?.nodeName == '完工'">
                    <div class="ci1t_label">工程放款金额(元)：</div>
                    <div class="ci1t_value">{{ grantFee3 || '0.00' }}</div>
                </div>
                <div class="ci1_text" v-if="msgInfo.allocate?.nodeName == '完工'">
                    <div class="ci1t_label">质保金(元)：</div>
                    <div class="ci1t_value">{{ (msgInfo.retentionTotal * 1 || 0).toFixed(2) }}</div>
                </div>
                <div class="ci1_text" v-if="msgInfo.allocate?.nodeName == '完工'">
                    <div class="ci1t_label">风险金(元)：</div>
                    <div class="ci1t_value">{{ (msgInfo.riskTotal * 1 || 0).toFixed(2) }}</div>
                </div>
                <div class="ci1_text">
                    <div class="ci1t_label">财务放款金额（元）：</div>
                    <div class="ci1t_value">{{ (msgInfo.allocate?.grantFee * 1 || 0).toFixed(2) }}</div>
                </div>
                <div class="ci1_text">
                    <div class="ci1t_label">放款备注：</div>
                    <div class="ci1t_value">{{ msgInfo.allocate?.remark || '' }}</div>
                </div>
            </div>
            <div class="content_info2" v-else>
                <div class="ci2_title">财务放款</div>
                <van-cell-group>
                    <van-field v-model="formData.extraAmount" label="辅料领用金额" input-align="right" placeholder="请输入"
                        @blur="e => handleChange(e, 'extraAmount')" v-if="msgInfo.allocate?.nodeName == '完工'">
                        <template #extra>&nbsp;&nbsp;元</template>
                    </van-field>
                    <van-field v-model="formData.awardFee" label="奖励金额" input-align="right" placeholder="请输入"
                        @blur="e => handleChange(e, 'awardFee')">
                        <template #extra>&nbsp;&nbsp;元</template>
                    </van-field>
                    <van-field v-model="formData.punishFee" label="扣款金额" input-align="right" placeholder="请输入"
                        @blur="e => handleChange(e, 'punishFee')">
                        <template #extra>&nbsp;&nbsp;元</template>
                    </van-field>
                    <van-field v-model="formData.adjustAmount" label="调整放款金额" input-align="right" placeholder="请输入"
                        @blur="e => handleChange(e, 'adjustAmount')" v-if="msgInfo.allocate?.nodeName == '完工'">
                        <template #extra>&nbsp;&nbsp;元</template>
                    </van-field>
                    <div class="adjust-remark-style">
                        <van-field v-model="formData.adjustRemark" label="调整原因" input-align="right" placeholder="请输入"
                            rows="1" autosize type="textarea" maxlength="200"
                            v-if="msgInfo.allocate?.nodeName == '完工' && Number(formData.adjustAmount)"></van-field>
                    </div>
                    <van-field v-model="grantFee3" disabled label="工程放款金额" input-align="right" placeholder="请输入"
                        v-if="msgInfo.allocate?.nodeName == '完工'">
                        <template #extra><span style="color: #c8c9cc;">&nbsp;&nbsp;元</span></template>
                    </van-field>
                    <van-field v-model="formData.retentionTotal" label="质保金" input-align="right" placeholder="请输入"
                        @blur="e => handleChange(e, 'retentionTotal')" v-if="msgInfo.allocate?.nodeName == '完工'">
                        <template #extra>&nbsp;&nbsp;元</template>
                    </van-field>
                    <van-field v-model="formData.riskTotal" label="风险金" input-align="right" placeholder="请输入"
                        @blur="e => handleChange(e, 'riskTotal')" v-if="msgInfo.allocate?.nodeName == '完工'">
                        <template #extra>&nbsp;&nbsp;元</template>
                    </van-field>
                    <van-field v-model="formData.remark" label="放款备注" input-align="right" placeholder="请输入" rows="1"
                        autosize type="textarea" maxlength="200"></van-field>
                </van-cell-group>
            </div>
        </div>
        <div class="messageInfo-bottom" v-if="msgInfo.allocate?.allocateStatus != 'ITEM060831'">
            <div class="mb-top">
                <div class="mbt-left">
                    <div class="mbt-tit">下拨申请金额(元)</div>
                    <div class="mbt-txt">{{ (msgInfo.allocate?.applyFee || 0).toFixed(2) }}</div>
                </div>
                <div class="mbt-right">
                    <div class="mbt-tit">财务放款金额(元)</div>
                    <div class="mbt-txt mbt-txt1">{{ grantFee || '0.00' }}</div>
                </div>
            </div>
            <div class="mb-bottom">
                <van-button class="bottom_btn" @click="onClose">取消</van-button>
                <van-button type="info" class="bottom_btn" @click="onSubmit">确认</van-button>
            </div>
        </div>
    </div>
</template>
<script>
import api from '@/api/api.js'
import api2 from '@/api/dataBoard.js'
import { Toast, Notify } from 'vant';
import AcceptanceSubcontract from './Component/AcceptanceSubcontract.vue'
export default {
    components: {
        AcceptanceSubcontract
    },
    data() {
        return {
            id: '',
            logId: '',
            projectId: '',
            nodeCode: '',
            msgInfo: { allocate: {} },
            // 消息标题
            msgConent: {},
            // 折叠面板
            activeNames: [''],
            // 表单数据
            formData: {},
        }
    },
    computed: {
        // 财务放款金额
        grantFee() {
            const { awardFee = 0, punishFee = 0, extraAmount = 0, riskTotal = 0, retentionTotal = 0, adjustAmount = 0 } = this.formData
            if (this.msgInfo.allocate?.nodeName == '完工') {
                return ((this.msgInfo.allocate?.applyFee * 1 || 0) + (awardFee * 1 || 0) - (punishFee * 1 || 0) - (extraAmount * 1 || 0) - (riskTotal * 1 || 0) - (retentionTotal * 1 || 0) + (adjustAmount * 1 || 0) || 0).toFixed(2)
            }
            return ((this.msgInfo.allocate?.applyFee * 1 || 0) + (awardFee * 1 || 0) - (punishFee * 1 || 0)).toFixed(2)
        },
        // 工程放款金额
        grantFee3() {
            if (this.msgInfo.allocate?.allocateStatus == 'ITEM060831') {
                const { applyFee = 0, awardFee = 0, punishFee = 0, extraAmount = 0, adjustAmount = 0 } = this.msgInfo?.allocate || {}
                return ((applyFee * 1 || 0) + (awardFee * 1 || 0) - (punishFee * 1 || 0) - (extraAmount * 1 || 0) + (adjustAmount * 1 || 0) || 0).toFixed(2)
            }
            const { awardFee = 0, punishFee = 0, extraAmount = 0, adjustAmount = 0 } = this.formData
            return ((this.msgInfo.allocate?.applyFee * 1 || 0) + (awardFee * 1 || 0) - (punishFee * 1 || 0) - (extraAmount * 1 || 0) + (adjustAmount * 1 || 0) || 0).toFixed(2)
        }
    },
    methods: {
        async onSearch() {
            let res = await api2.messageApi(this.logId)
            const { data } = await api.getAllocateTotal({ nodeCode: this.nodeCode, projectId: this.projectId, id: this.id })
            this.msgConent = res.data
            this.msgInfo = data
            this.formData = {
                awardFee: (Number(data.allocate?.awardFee) || 0) > 0 ? data.allocate?.awardFee : '',
                punishFee: (Number(data.allocate?.punishFee) || 0) > 0 ? data.allocate?.punishFee : '',
                remark: data.allocate?.remark || '',
                riskTotal: (Number(data.riskTotal) || 0) > 0 ? data.riskTotal : '',
                retentionTotal: (Number(data.retentionTotal) || 0) > 0 ? data.retentionTotal : '',
                extraAmount: (Number(data.extraAmount) || 0) > 0 ? data.extraAmount : '',
                adjustAmount: (Number(data.adjustAmount) || 0) > 0 ? data.adjustAmount : '',
                adjustRemark: data.allocate?.adjustRemark || '',
            }
        },
        // 提交
        async onSubmit() {
            let obj = {
                id: this.id,
                projectId: this.projectId || this.msgInfo.projectId,
                orderId: this.orderId || this.msgInfo.orderId,
                nodeCode: this.nodeCode || this.msgInfo.allocate?.nodeCode
            }
            let { awardFee = 0, punishFee = 0, remark, extraAmount = 0, riskTotal = 0, retentionTotal = 0, adjustAmount = 0, adjustRemark } = this.formData
            if (this.msgInfo.allocate?.nodeName == '完工') {
                if(adjustAmount?.length){
                    if(!adjustRemark.trim()?.length){
                        Toast.fail('请输入调整原因');
                        this.formData.adjustRemark = ''
                        adjustRemark = ''
                        return;
                    }
                }else{
                    this.formData.adjustRemark = ''
                    adjustRemark = ''
                }
                obj = {
                    ...obj,
                    awardFee,
                    extraAmount,
                    grantFee: ((this.msgInfo.allocate?.applyFee * 1 || 0) + (awardFee * 1 || 0) - (punishFee * 1 || 0) - (extraAmount * 1 || 0) - (riskTotal * 1 || 0) - (retentionTotal * 1 || 0) + (adjustAmount * 1 || 0)).toFixed(2),
                    retentionTotal,
                    punishFee,
                    remark,
                    retentionTotal,
                    riskTotal,
                    adjustAmount,
                    adjustRemark,
                    applyAmount: this.msgInfo.allocate?.applyFee,
                }
            } else {
                obj = {
                    ...obj,
                    awardFee,
                    grantFee: ((this.msgInfo.allocate?.applyFee * 1 || 0) + (awardFee * 1 || 0) - (punishFee * 1 || 0)).toFixed(2),
                    punishFee,
                    remark,
                    applyAmount: this.msgInfo.allocate?.applyFee,
                }
            }
            const data = await api.financeLoan(obj)
            if (data.code == 200) {
                Toast.success('放款成功！')
                this.onSearch()
            }
        },
        // 退出当前页
        onClose() {
            window.close()
            wx.closeWindow()
        },
        // 输入判断
        handleChange(e, type) {
            let { value } = e.target
            const regex = type === 'adjustAmount' ? /^-?\d{1,8}(\.\d{1,2})?$/ig : /^\d{1,8}(\.\d{1,2})?$/ig;
            if (value?.length && !regex.test(value)) {
                Toast.fail('请输入正确的金额');
                this.formData[type] = ''
            }
        },
        // 设置颜色样式
        setColor(nodeCode) {
            let style = {}
            switch (nodeCode) {
                case 'ITEM364311':
                    style = { background: 'rgba(64,158,255,0.1)', color: '#409EFF', padding:'0 4px' }
                    break;
                case 'ITEM616642':
                    style = { background: 'rgba(255,159,36,0.1)', color: '#F6910F', padding:'0 4px' }
                    break;
                case 'ITEM911020':
                    style = { background: 'rgba(237,64,64,0.1)', color: '#ED4040', padding:'0 4px' }
                    break;
                case 'ITEM799338':
                    style = { background: 'rgba(56,184,153,0.1)', color: '#38B899', padding:'0 4px' }
                    break;
                case 'ITEM698273':
                    style = { background: '#EDFBF2', color: '#38B865', padding:'0 4px' }
                    break;
                case 'OTHER':
                    style = { background: 'rgba(117,123,130,0.1)', color: '#6A7B8A', padding:'0 4px' }
                    break;
                default:
                    break;
            }
            return style
        }
    },
    mounted() {

    },
    created() {
        //this.id = this.$route.params.id
        //this.logId = this.$route.params.logId
        //this.projectId = this.$route.params.projectId
        //this.nodeCode = this.$route.params.nodeCode
        this.id = this.$route.query.id
        this.logId = this.$route.query.logId
        this.projectId = this.$route.query.projectId
        this.nodeCode = this.$route.query.nodeCode
        this.onSearch()
    }
}
</script>

<style scoped lang="scss">
.messageInfo-box {
    width: 100%;
    overflow: hidden;

    .main_content {
        width: 100%;
        overflow-x: auto;
        height: calc(100vh - 50px);
        background: #F5F5F5;
    }
}

.main_cont {
    background: #fff;
    height: 50px;
    padding: 16px 12px;
    text-align: left;
    margin-bottom: 8px;

    .p_1 {
        margin: 0px 0px 8px 0px;
        color: #999999;
        font-size: 14px;
        display: flex;
        align-items: center;

        .span_p {
            width: 44px;
            text-align: center;
            height: 18px;
            background: #ECF2FA;
            color: #3975C6;
            font-size: 12px;
            margin-right: 12px;
            line-height: 18px;
            padding: 2px;
        }
    }

    .p_2 {
        display: flex;
        justify-content: space-between;
        margin: 0px;

        .span_left {
            color: #000000;
            font-size: 16px;
            font-weight: 600;
        }
    }
}

.content_info {
    text-align: left;

    .cont_div {
        display: flex;
        padding: 8px;
        margin: 0;

        &:first-child {
            margin-top: -8px;
        }

        .cont_label {
            flex: 4;
            color: #333;
        }

        .cont_value {
            text-align: right;
            flex: 6;
            color: #333;
            word-wrap: break-word;
            word-break: break-all;
        }
    }

    .cont_div1{
        .cont_label{
            flex: none;
        } 
        .cont_value {
            flex: auto;
            text-align: left;
        }
    }

    .cont_div2{
        display: block;
        padding: 0 8px;
        margin: 0 8px 8px;
        line-height: 24px;
        font-size: 12px;
        color: #174D7B;
        font-weight: 500;
        background: #F2F8FF;
        div{
            display: inline-block;
        }
    }

    ::v-deep .van-cell {
        padding: 16px 20px;

        &::after {
            border: none;
        }

        .van-cell__title {
            font-weight: 600;
        }
    }

    ::v-deep .van-collapse-item__content {
        padding: 0 12px 8px;
    }
}

.content_info1 {
    background-color: #fff;
    padding: 8px 12px;
    margin: 8px 0 40px;
    text-align: left;

    .ci1_title {
        padding: 8px;
        font-size: 16px;
        font-weight: 600;
        color: #333;
    }

    .ci1_text {
        padding: 8px;
        display: flex;
        justify-content: space-between;
        font-size: 14px;

        .ci1t_label {
            min-width: 120px;
        }

        .ci1t_value {
            flex: 1;
            text-align: right;
            word-wrap: break-word;
            word-break: break-all;
        }
    }

    ::v-deep .van-cell {
        padding: 16px 20px;

        .van-cell__title {
            font-weight: 600;
        }
    }

    ::v-deep .van-collapse-item__content {
        padding: 0 12px 8px;
    }
}

.content_info2 {
    margin: 8px 0 170px;
    background-color: #fff;
    text-align: left;
    border-radius: 8px 8px 0 0;

    .ci2_title {
        padding: 10px 20px;
        font-size: 16px;
        font-weight: 600;
        color: #333;
    }

    ::v-deep .van-field .van-field__label {
        color: #333;
    }

    ::v-deep .van-cell {
        padding: 16px 16px;
    }

    .adjust-remark-style {
        margin: 0 16px;

        ::v-deep .van-cell {
            background-color: #F1F1F1;
            padding: 10px 8px;
        }
    }
}

.messageInfo-bottom {
    border-radius: 8px 8px 0 0;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #f5f5f5;
    background-color: #fff;
    box-shadow: 0px -1px 8px 0px rgba(0, 0, 0, 0.04);

    .mb-top {
        display: flex;
        padding: 6px 0;

        .mbt-left {
            flex: 1;
        }

        .mbt-right {
            flex: 1;
        }

        .mbt-tit {
            color: #999;
            font-size: 14px;
        }

        .mbt-tit {
            font-size: 14px;
        }

        .mbt-txt {
            margin-top: 4px;
            font-size: 16px;
            color: #333;
        }

        .mbt-txt1 {
            color: #ED4040;
        }
    }

    .mb-bottom {
        padding: 10px 16px;
        display: flex;
        justify-content: space-between;
        box-shadow: 0px -1px 8px 0px rgba(0, 0, 0, 0.04);

        .bottom_btn {
            width: calc(50% - 8px);
            font-size: 18px;
            border-radius: 4px;
        }

        .bottom_btn:last-child {
            background-color: #3975C6;
        }
    }
}
</style>
