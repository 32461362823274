<template>
  <div class="main">
    <headerNav leftNav=" " :title="'任务详情'" @leftClick="back"></headerNav>
    <main>
      <div class="content" v-if="upActiveTab != '3'">
        <van-cell-group class="cell-group-top cell-group-height">
          <template v-for="(item,index) in typeList.table">
            <van-cell :class="item.class" v-if="!item.requir&&!Array.isArray(item.value)" :title="item.label" title-class="cell-group-title" value-class="cell-group-right">
              <template #right-icon>
                <span v-if="item.unit" class="unit">{{ item.unit }}</span>
              </template>
              <template #default>
                <div>{{ (item.suffix?(item.value+item.suffix):item.value) }}</div>
              </template>
            </van-cell>
            <van-cell :class="item.class" v-if="Array.isArray(item.value)" :title="item.label" title-class="cell-group-title" value-class="cell-group-right">
              <template #default>
                <div class="file-content">
                  <img class="img" v-for="(item1,index1) in item.value" :key="index1" :src="item1.fileUrl" @click="imagePreview(item1.fileUrl)">
                </div>
              </template>
            </van-cell>
            <van-field :class="item.class" v-if="item.requir" :type="item.type?item.type:'text'" v-model="routeQueryInfo[item.prop]" @click="cellFn(item.click)" :label="item.label" placeholder="请输入" @focus="item.click?forbid():''" @input="item.inputFn?inputFn($event,item):''">
              <i slot="left-icon" style="color: red">*</i>
              <template #button v-if="!item.unit"><van-icon name="arrow" /></template>
              <template #button v-else><span style="color:#000">{{ item.unit }}</span></template>
            </van-field>
          </template>
        </van-cell-group>

        <div class="footer" >
          <div class="footer-btn" @click="updateTask" :class="isModify?'':'untouched'">确认</div>
        </div>
      </div>

      <div class="content" v-if="upActiveTab == '3'">
        <van-cell-group class="cell-group-top cell-group-height">
          <template v-for="(item,index) in typeList.table">
            <van-cell :class="item.class" v-if="!Array.isArray(item.value)" :title="item.label" title-class="cell-group-title" value-class="cell-group-right" @click="cellFn(item.click)">
              <template #right-icon>
                <span v-if="item.unit" class="unit">{{ item.unit }}</span>
                <span v-if="item.prop == 'childTaskTotalCount'"><van-icon name="arrow" /></span>
              </template>
              <template #default>
                <div v-if="item.finishStatus" class="finishStatus" :class="routeQueryInfo.finishStatus?'completed':'incomplete'">{{routeQueryInfo.finishStatus ? "已完成":"未完成"}}</div>
                <div>{{ (item.suffix?(item.value+item.suffix):item.value) }}</div>
              </template>
            </van-cell>
            <van-cell :class="item.class" v-if="Array.isArray(item.value)" :title="item.label" title-class="cell-group-title" value-class="cell-group-right">
              <template #default>
                <div class="file-content">
                  <img class="img" v-for="(item1,index1) in item.value" :key="index1" :src="item1.fileUrl" @click="imagePreview(item1.fileUrl)">
                </div>
              </template>
            </van-cell>
          </template>
        </van-cell-group>
      </div>

    </main>

    <van-popup v-model="showExecutorPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="executorList"
        @confirm="onConfirmTaskType"
        @cancel="showExecutorPicker = false"
      />
    </van-popup>

  </div>
</template>

<script>
import api from "@/api/task-management/human-resources.js";
import { taskDetailModelFn } from "./data.js"
import { Toast,ImagePreview } from "vant";
import moment from "moment";

export default {
  data () {
    return {
      active:'',// 上级页面的Tab active
      routeQueryInfo:{},// 路由携带信息 row
      originalInfo:{}, //拷贝路由 原始数据
      typeList:{},// 模块类型数据 
      showExecutorPicker: false, //执行者弹窗
      executorList:[], //执行者数据
      isModify:false, //是否修改
      // 当前登录人信息
      roleData:null
    }
  },
  async created() {
    this.upActiveTab = this.$route.query.active
  },
  async mounted() {
    this.getTaskDetail()
    this.getExecutorList()
  },
  methods: {
    back () {
      this.$router.go(-1)
    },
    // 任务详情数据查询
    getTaskDetail(){
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      let info = JSON.parse(this.$route.query.item)
      let params = {
        id:info.id,
        itemId:info.itemId,
      }
      api.getTaskDetail(params).then((res)=>{
        if (res.code == 200) {
          Toast.clear()
          this.originalInfo = JSON.parse(JSON.stringify(res.data))
          this.routeQueryInfo = res.data
          this.roleData = JSON.parse(localStorage.getItem("USER_ROLE"));
          this.typeList = taskDetailModelFn(this.roleData.roleCode,this.routeQueryInfo.termId)
          this.typeList.table.forEach((item,index) => {
            item.value = this.routeQueryInfo[item.prop]?this.routeQueryInfo[item.prop]:''
            if(item.prop == "childTaskTotalCount"){
              item.value = item.value ? item.value : '0'
            }else if(item.prop == "finishRate"){
              item.value = item.value ? parseFloat(item.value).toFixed(2) : '-'
            }else{
              item.value = item.value ? item.value : '-'
            }
            this.routeQueryInfo[item.prop] = item.value
          });
        }else{
          Toast.fail(res.msg)
        }
      })
    },
    // 获取执行者数据
    getExecutorList(){
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      let params = {}
      api.getExecutorList(params).then((res)=>{
        if (res.code == 200) {
          Toast.clear()
          let arr = []
          res.data.forEach(item => {
            arr.push({
              text:item.name,
              value:item.code,
            })
          });
          this.executorList = arr
        }else{
          Toast.fail(res.msg)
        }
      })
    },
    // 执行者弹窗 打开
    cellFn(item){
      if(this.upActiveTab != '3' && item == 'executorPicker'){
        this.showExecutorPicker = true
      }else if(item == 'subTask'){
        this.$router.push({
          name:'TaskSetupHumanResourcesSubTask',
          query:{
            upActiveTab:this.upActiveTab,
            taskId:this.routeQueryInfo.id,
          }
        })
      }
    },
    // 输入框内容改变
    inputFn(value,item){
      if(item.type == "digit" && Number(value) <= 0){
        Toast.fail('输入的数量不能为空或零')
        this.routeQueryInfo[item.prop] = ""
        this.isModify = false
      }
      if(item.prop == "targetValue" && this.originalInfo.targetValue != value){
        this.isModify = true
      }else{
        this.isModify = false
      }
      if(value == '' || this.routeQueryInfo[item.prop] == ""){
        this.isModify = false
      }
    },
    // 执行者弹窗 确认
    onConfirmTaskType(value){
      if(this.originalInfo.userName != value.text && this.originalInfo.userId != value.value){
        this.isModify = true
      }
      this.routeQueryInfo.userName = value.text;
      this.routeQueryInfo.userId = value.value;
      this.showExecutorPicker = false;
    },
    // 预览图片
    imagePreview(url){
      ImagePreview({
        images: [url],
        closeable: true,
        showIndex: false
      });
    },
    // 禁止弹出软键盘
    forbid(){
      document.activeElement.blur()
    },
    // 遍历对象改变'-'为''
    objChange(obj){
      for (let key in obj) {
        if (obj.hasOwnProperty(key) && obj[key] === '-') { // 判断属性值是否为 "-"
          obj[key] = ''; // 将属性值改为 ""
        }
      }
    },
    updateTask(){
      if(!this.isModify){
        return false
      }
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      this.objChange(this.routeQueryInfo)
      let params = {
        ...this.routeQueryInfo,
      }
      api.updateTask(params).then((res)=>{
        if (res.code == 200) {
          Toast.success('操作成功');
          let _this = this;
          setTimeout(()=>{
            _this.back()
          },1000)
        }else{
          Toast.fail(res.msg)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.main::v-deep {
  background: #F5F5F5;
  height: 100vh;

  .content {
    height: calc(100vh - 51px);
    overflow: auto;

    .cell-group-top {
      border-radius: 0px 0px 8px 8px;
      overflow: auto;
    }

    .cell-group-height{
      max-height: calc(100% - 90px);
    }

    .cell-group-center {
      border-radius: 8px;
      overflow: hidden;
      margin: 8px 0;

      .accessory {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .accessory-img {
          width: 20px;
          height: 24px;
          margin-right: 4px;
        }

        .accessory-text {
          font-size: 14px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          color: #0084F4;
        }
      }
    }

    .cell-group-bottom {
      border-radius: 8px;
      overflow: hidden;

      .mistakes {
        span {
          color: #ED4040;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
        }
      }

      .postpone {
        span {
          color: #FF8C21;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
        }
      }

      .final-score {
        span {
          color: #0084F4;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
        }
      }
    }

    .cell-group-title {
      font-size: 16px;
      font-family: PingFang TC, PingFang TC;
      font-weight: 400;
      color: #999999;
      text-align: left;
    }

    .cell-group-right,.van-field__control {
      font-size: 16px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #000000;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .finishStatus{
        width: 52px;
        line-height: 22px;
        font-size: 12px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        text-align: center;
        margin-right: 16px;
        border-radius: 4px;
      }
      .completed{
        background: #17CA5E;
      }
      .incomplete{
        background: #FF6259;
      }
    }
    .van-field__label{
      font-size: 16px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      color: #1d2129;
    }
    .van-field__value {
      font-size: 16px;
    }

    .footer{
      width: 100%;
      background: #fff;
      padding: 11px 18px 30px;
      box-sizing: border-box;
      box-shadow: 0px -1px 8px 0px rgba(0,0,0,0.04);
      position: absolute;
      bottom: 0;
      .footer-btn{
        line-height: 44px;
        font-size: 18px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        background: #3975C6;
        border-radius: 4px 4px 4px 4px;
      }
      .untouched{
        opacity: .5;
      }
    }
  }

  .van-field__control{
    text-align: right;
  }
  
  .van-field__button{
    color: #000 !important;
  }
  .unit{
    color: #000 !important;
    padding-left: 8px !important;
  }
  .van-cell__value{
    overflow: visible;
  }
  .file-content{
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    width: 255px;

    .img{
      width: 72px;
      height: 72px;
      margin:0 0 11px 11px;
      border-radius: 4px;
      border: 1px solid rgba(217,217,217,0.4);
    }
  }
  .interval{
    border-top: 10px solid #F5F5F5;
  }

  
}
</style>