<template>
  <div class="main">
    <van-sticky class="topMenu">
      <headerNav leftNav=" " :title="targetObj.navTitle" @leftClick="back"></headerNav>
    </van-sticky>
    <main class="main-content">
      <!-- 汇总 -->
      <BoardNavSummarize :imgBackUrlName="targetObj.imgUrl" :ratio="taskPlanObj.ysFinishRate" :showFoot="false"
        :targetName="targetObj.value == 'overview_report'? targetObj.collect4 : targetObj.collect3" :navTitleUnit="targetObj.value == 'overview_report'? ' ':'%'" />

      <!-- 任务结果上传记录 -->
      <div class="task-title">
        <div class="task-title-icon"></div>
        <div>任务成果上传记录</div>
      </div>
      <!-- list -->
      <div class="task-content">
       <div v-if="upLoadList.length">
        <div class="task-content-list" v-for="(item,index) in upLoadList" :key="index">
          <div class="task-content-list-title">
            <div class="task-content-list-title-left">
              <img :src="require(`@/assets/task-management/${item.termId}.png`)">
              <div class="date">{{ item.createDate.replace(/\//g, ".").split(' ')[0] }}</div>
              <div class="time">{{ item.createDate.replace(/\//g, ".").split(' ')[1] }}</div>
            </div>
            <div class="task-content-list-title-right">
              <div v-if="item.finalStatus == '0'" style="background: #FF8C21;">待审核</div>
              <div v-if="item.finalStatus == '1'" style="background: #17CA5E;">已通过</div>
              <div v-if="item.finalStatus == '2'" style="background: #FF6157;">已驳回</div>
              <div v-if="item.finalStatus == '3'" style="background: #999999;">已失效</div>
            </div>
          </div>
          <div>
            <div v-if="taskTargetObj.isShowTarget == 1" class="task-content-list-content1">
              <span class="text">{{ taskTargetObj.typeName1 }}:</span>
              <span class="task-content-list-content-num">{{ item.realityProfitRate || '-'}}</span> {{ taskTargetObj.unit }}
            </div>
            <div v-if="taskTargetObj.isShowTarget == 2" class="task-content-list-content2">
              <div>
                <div><span>{{ item.taskFine || '-' }}</span>{{ taskTargetObj.unit }}</div>
                <div>{{ taskTargetObj.typeName1 }}</div>
              </div>
              <div>
                <div><span>{{ item.realityAccomplish || '-' }}</span>{{ taskTargetObj.unit }}</div>
                <div>{{ taskTargetObj.typeName2 }}</div>
              </div>
            </div>
          </div>
          <!-- 图片 -->
          <div class="task-content-list-img" v-if="taskTargetObj.type == 'image'">
            <div>附件{{ item.fileInfoList && item.fileInfoList.length <= 0 ? "" : `(${item.fileInfoList && item.fileInfoList.length ? item.fileInfoList.length : 0})` }}</div>
            <div v-if="item.fileInfoList && item.fileInfoList.length > 0">
              <img :src="img.fileUrl" v-for="(img, num) in item.fileInfoList.slice(0, 3)" @click="onPreview(img.fileUrl)">
              <!-- 查看更多-->
              <div class="view-more" v-if="item.fileInfoList && item.fileInfoList.length > 3 && !showMoreImg" @click="showMoreImg = true">
                <div class="img"><img src="@/assets/task-management/task-board-img-more.png"></div>
                <div class="text">查看更多</div>
              </div>
              <img v-for="(img, index2) in item.fileInfoList.slice(3)" v-if="item.fileInfoList && showMoreImg" :src="img.fileUrl" class="img" :key="index2" @click="onPreview(img.fileUrl)" />
            </div>
          </div>
          <!-- 文件 -->
          <div class="task-content-list-file" v-if="taskTargetObj.type == 'file'">
            <div class="task-content-list-file-text">附件：</div>
            <div class="accessory">
              <img class="accessory-img" src="@/assets/task-management/task-setup-file-xlsx.png" alt="">
              <div class="accessory-text" @click="onClickDownFile(item.fileInfoList)">
                {{ item.fileInfoList && item.fileInfoList.length ? item.fileInfoList[0].fileName:''  }}
              </div>
            </div>
          </div>
          <div class="task-content-list-detail" @click="onClickDetails(item)">查看详情
            <van-icon style="float: right;" name="arrow" />
          </div>
        </div>
       </div>
        <div class="no-data" v-else>
          <img src="@/assets/task-management/task-setup-nodata.png" alt />
          <div>暂无数据</div>
        </div>
      </div>
    </main>

    <!-- 底部按钮 -->
    <div class="footer">
      <van-button type="info" class="btn" @click="taskUpload">任务成果上传</van-button>
    </div>
  </div>
</template>

<script>
import BoardNavSummarize from "@/views/task-management/component/public/module/BoardNavSummarize.vue"
import { estimateOutput, estimateOutputTarget } from "./data.js"
import { ImagePreview, Toast } from 'vant';
import api from "@/api/task-management/finance.js";

export default {
  components: {
    BoardNavSummarize
  },
  data() {
    return {
      // 指标信息
      targetObj: {},
      // 任务上传信息
      taskTargetObj: {},
      taskPlanObj: {}, // 汇总信息
      // 附件集合
      fileInfoList: [],
      // 控制显示更多图片
      showMoreImg: false,
      routerData: {},
      upLoadList: [],  // 上传记录
    }
  },
  created() {
    let localData = JSON.parse(localStorage.getItem("TABLE_HEADER_CONTENT"));
    this.routerData = localData;
    // 指标信息
    this.targetObj = estimateOutput(localData.termSlug)
    // 任务成果上传
    this.taskTargetObj = estimateOutputTarget(localData.termSlug)
    this.getTableData()
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    // 查询数据
    async getTableData() {
      try {
        Toast.loading({
          message: "加载中...",
          forbidClick: true,
        });
        const { data } = await api.getApproveList({
          ext: this.routerData.termSlug,
          Q_reportData_EQ: this.routerData.Q_reportData_EQ,
          type: "1",
          taxonomy: "6",
        });
        this.taskPlanObj = data.statisticsObject
        this.upLoadList = data.approveList
        console.log(this.upLoadList.length);
      } finally {
        Toast.clear();
      }
    },
    // 查看大图
    onPreview(url) {
      ImagePreview({
        images: [url],
        closeable: true,
        showIndex: false
      });
    },
    downloadFile(fileUrl) {
      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = '';
      link.click();
    },
    onClickDownFile(file) {
      let pre_prod_host = window.location.host == "m.hejiahejiazhuang.com" ? process.env.VUE_APP_BASE_RUL_PROD : process.env.VUE_APP_BASE_RUL_PRE
      let url = process.env.NODE_ENV === "production" ? pre_prod_host + process.env.VUE_APP_SYS_API : process.env.VUE_APP_SYS_API
      this.downloadFile(url + `/tools/oss/ali/downOSSFile/public?fileName=${file[0].fileUrl.split('/').pop()}&dir=oss`)
    },
    // 查看详情
    onClickDetails(item) {
      localStorage.setItem('TASK_UPLOAD_RECORD', JSON.stringify(item))
      this.$router.push({ path: '/task-management/setup/inquiry/details/examine-details' })
    },
    // 任务成果上传
    taskUpload() {
      this.$router.push({ path: '/task-management/board/inquiry/upload-task', query: { row: JSON.stringify(this.taskPlanObj) } })
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  background: #f5f5f5;

  .main-content {
    margin-top: -1px;
  }
  .no-data {
    margin: 120px auto;
    height: 100%;
    width: 200px;
    img {
      width: 210px;
      height: 137px;
    }

    div {
      font-size: 16px;
      font-family: Bahnschrift, Bahnschrift;
      font-weight: 400;
      color: rgba(51, 51, 51, 0.5);
    }
  }
  .task-title {
    font-size: 18px;
    font-family: PingFang SC, PingFang SC;
    font-weight: bold;
    color: #333333;
    text-align: left;
    padding-top: 16px;
    display: flex;
    align-items: center;

    .task-title-icon {
      width: 4px;
      height: 15px;
      background: #0e8bff;
      margin-left: 10px;
      margin-right: 8px;
    }
  }

  .task-content {
    width: 100%;
    height: calc(100vh - 160px);
    overflow: auto;
    margin-top: 8px;
    padding-bottom: 76px;

    .task-content-list {
      width: 100%;
      background: #ffffff;
      border-radius: 8px 8px;
      padding: 16px 12px 0;
      box-sizing: border-box;
      text-align: left;
      margin-bottom: 10px;

      .task-content-list-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;

        .task-content-list-title-left {
          display: flex;
          align-items: center;
          font-size: 23px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          color: #1d2129;

          img {
            width: 24px;
            height: 24px;
            margin-right: 4px;
          }

          .time {
            height: 22px;
            line-height: 22px;
            font-size: 15px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            color: #8c8c8c;
            background: #f9f9f9;
            border-radius: 3px 3px 3px 3px;
            padding: 0 4px;
            margin-left: 8px;
          }
        }

        .task-content-list-title-right {
          div {
            font-size: 15px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            height: 24px;
            line-height: 24px;
            border-radius: 4px 4px;
            padding: 0 8px;
          }
        }
      }

      .task-content-list-content1 {
        height: 56px;
        background: #f9f9f9;
        box-shadow: 0px 5px 7px 0px rgba(254, 242, 222, 0.2);
        border-radius: 8px 8px 8px 8px;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #545454;
        padding-left: 16px;
        display: flex;
        align-items: center;
        .text {
          margin-right: 3px;
        }

        .task-content-list-content-num {
          font-size: 22px;
          font-family: Bahnschrift, Bahnschrift;
          font-weight: normal;
          color: #1d2129;
        }
      }

      .task-content-list-content2 {
        height: 88px;
        background: #f9f9f9;
        box-shadow: 0px 5px 7px 0px rgba(254, 242, 222, 0.2);
        border-radius: 8px 8px 8px 8px;
        display: flex;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #545454;
        position: relative;

        span {
          font-size: 22px;
          font-family: Bahnschrift, Bahnschrift;
          font-weight: normal;
          color: #1d2129;
        }

        div:nth-child(1) {
          width: 45%;
          padding: 16px 0 0 10px;
          border-right: 1px dashed #e2e2e2;

          div {
            padding: 0;
            border: 0;
            margin-bottom: 4px;
          }
        }

        div:nth-child(2) {
          width: 60%;
          padding: 16px 0 0 10px;

          div {
            padding: 0;
            border: 0;
            margin-bottom: 4px;
          }
        }

        p {
          margin: 0;
          width: 15px;
          height: 15px;
          background: #ffffff;
          border-radius: 50%;
          position: absolute;
          top: -10%;
          left: 38.5%;
        }

        p:nth-of-type(1) {
          top: unset;
          bottom: -10% !important;
        }
      }

      .task-content-list-img {
        min-height: 40px;
        display: flex;
        margin-top: 20px;
        font-size: 16px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #545454;

        div:nth-child(1) {
          width: 20%;
        }

        div:nth-child(2) {
          width: 80%;

          img {
            width: 110px;
            height: 110px;
            border-radius: 4px 4px 4px 4px;
            opacity: 1;
            border: 1px solid rgba(217, 217, 217, 0.4);
            margin: 0 10px 6px 0;
          }
        }

        .view-more {
          width: 110px;
          height: 110px;
          border: 1px solid #d9d9d9;
          background: #fcfdff;
          float: right;
          margin-right: 47px;
          text-align: center;
          border-radius: 4px;

          .img {
            width: 100%;
            margin-top: 24px;

            img {
              width: 32px;
              height: 32px;
              margin: 0;
            }
          }

          .text {
            width: 100%;
            font-size: 14px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #545454;
          }
        }
      }

      .task-content-list-file {
        display: flex;
        align-items: center;
        margin: 14px 0 15px;

        .task-content-list-file-text {
          font-size: 16px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          color: #545454;
        }

        .accessory {
          display: flex;
          align-items: center;
          justify-content: flex-end;

          .accessory-img {
            width: 18px;
            height: 18px;
            margin-right: 4px;
          }

          .accessory-text {
            font-size: 14px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            color: #0084f4;
          }
        }
      }

      .task-content-list-detail {
        height: 22px;
        font-size: 16px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #545454;
        padding: 12px 0;
        border-top: 1px solid #ebedf0;
      }
    }
  }

  .footer {
    text-align: center;
    width: 100%;
    background: #fff;
    height: 67px;
    position: fixed;
    bottom: 0px;
    left: 0px;
    padding-bottom: 10px;

    .btn {
      width: 95%;
      height: 44px;
      background: #3975c6;
      border-radius: 4px 4px 4px 4px;
      margin: 12px auto 30px;
      font-size: 18px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 44px;
    }
  }
}
</style>