import { render, staticRenderFns } from "./IndexTab.vue?vue&type=template&id=44fb1ebc&scoped=true&"
import script from "./IndexTab.vue?vue&type=script&lang=js&"
export * from "./IndexTab.vue?vue&type=script&lang=js&"
import style0 from "./IndexTab.vue?vue&type=style&index=0&id=44fb1ebc&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44fb1ebc",
  null
  
)

export default component.exports