<template>
    <div class="messageInfo-box">
        <headerNav title="消息详情" />
        <div :class="msgInfo.accountStatusName != '待审核' ? 'show_main_content' : 'main_content'">
            <!-- 预订款缴费审核 -->
            <div class="main_cont">
                <p class="p_2">
                    <span class="span_left">{{ msgConent.messageSuggest }}</span>
                </p>
            </div>
            <div class="content_info">
                <van-collapse v-model="activeNames">
                    <van-collapse-item title="基本信息" name="1">
                        <div class="cont_div">
                            <div class="cont_label">记录编号</div>
                            <div class="cont_value">{{ msgInfo.returnNo }}</div>
                        </div>
                        <div class="cont_div">
                            <div class="cont_label">单据编号</div>
                            <div class="cont_value">{{ msgInfo.paperNo }}</div>
                        </div>
                        <div class="cont_div">
                            <div class="cont_label">客户姓名</div>
                            <div class="cont_value">{{ msgInfo.consumerName }}</div>
                        </div>
                        <div class="cont_div">
                            <div class="cont_label">预定合同号</div>
                            <div class="cont_value">{{ msgInfo.reserveContract }}</div>
                        </div>
                        <div class="cont_div">
                            <div class="cont_label">施工合同号</div>
                            <div class="cont_value">{{ msgInfo.contractNo }}</div>
                        </div>
                        <div class="cont_div">
                            <div class="cont_label">收款类型</div>
                            <div class="cont_value">{{ msgInfo.feeTypeName }}</div>
                        </div>
                        <div class="cont_div">
                            <div class="cont_label">发起人</div>
                            <div class="cont_value">{{ msgInfo.returnNo }}</div>
                        </div>
                        <div class="cont_div">
                            <div class="cont_label">收款金额(元)</div>
                            <div class="cont_value">{{ msgInfo.realityAmount }}</div>
                        </div>
                        <div class="cont_div">
                            <div class="cont_label">收款方式</div>
                            <div class="cont_value">{{ msgInfo.collectStyleName }}</div>
                        </div>
                        <div class="cont_div">
                            <div class="cont_label">状态</div>
                            <div class="cont_value" :class="msgInfo.accountStatusName == '待审核'?'color_orange': msgInfo.accountStatusName == '未到账'?'color_red':'color_blue'">{{ msgInfo.accountStatusName }}</div>
                        </div>
                        <div class="cont_div">
                            <div class="cont_label">放款备注</div>
                            <div class="cont_value">{{ msgInfo.remark }}</div>
                        </div>
                        <div class="cont_div">
                            <div class="cont_label">缴费凭证</div>
                            <div class="cont_value cont_img">
                                <van-image v-if="msgInfo.itemp" @click="onPreview(item.fileAdd)" v-for="(item, index) in msgInfo.itemp.slice(0, 2)"
                                    width="64" height="64" :src="item.fileAdd" class="img" :key="index" />
                                <div class="see_more" v-if="msgInfo.itemp && msgInfo.itemp.length > 2 && !showMoreImg"
                                    @click="showMoreImg = true">
                                    查看更多</div>
                                <van-image @click="onPreview(item.fileAdd)" v-for="(item, index2) in msgInfo.itemp.slice(2)"
                                    v-if="msgInfo.itemp && showMoreImg" width="64" height="64" :src="item.fileAdd" class="img" :key="index2" />
                            </div>
                        </div>
                    </van-collapse-item>
                </van-collapse>
            </div>
        </div>
        <div v-show="msgInfo.accountStatusName == '待审核'">
            <div class="main_bottom">
                <van-radio-group v-model="radio" direction="horizontal">
                    <div class="div_border">
                        <van-radio name="ITEM044518" icon-size="20px">未到账</van-radio>
                    </div>
                    <div class="div_border">
                        <van-radio name="ITEM673970" icon-size="20px">到账</van-radio>
                    </div>
                </van-radio-group>
            </div>
            <van-button type="info" class="bottom_btn" :disabled="!radio" @click="onSubmit">确认</van-button>
        </div>
    </div>
</template>
<script>
import api from '@/api/api.js'
import api2 from '@/api/dataBoard.js'
import { ImagePreview,Toast } from 'vant';
import AcceptanceSubcontract from './Component/AcceptanceSubcontract.vue'
export default {
    components: {
        AcceptanceSubcontract
    },
    data () {
        return {
            id: '',
            logId:'',
            // 消息详情
            msgInfo: {},
            // 消息标题
            msgConent:{},
            activeNames: ['1'],
            radio: '',
            fileList: [],
            remark: '',
            // 控制显示更多图片
            showMoreImg: false,
            // 审核意见
            checkRemark: '',
            // 控制是否点击确认
            isClickOk: false,
            // 下拨付款金额
            paymentMoney: ''
        }
    },
    methods: {
        // 查询详情
        async handleDetails () {
            let params = {
                Q_id_EQ: this.id
            }
            const { data } = await api.supplierPage(params)
            let res = await api2.messageApi(this.logId)
            this.msgConent = res.data
            this.msgInfo = data[0]
        },
        async onSubmit () {
            this.isClickOk = true
            const data = await api.payExamine({
                id: this.msgInfo.id,
                orderId: this.msgInfo.orderId,
                planId: this.msgInfo.planId,
                accountStatus: this.radio,
                realityAmount: this.msgInfo.realityAmount
            })
            if(data.code == 200){
                Toast.success('操作成功!');
                this.handleDetails()
            }
            
        },
        onPreview (url) {
            ImagePreview({
                images: [url],
                closeable: true,
                showIndex: false
            });
        },
    },
    mounted () {

    },
    created () {
        this.id = this.$route.params.id
        this.logId = this.$route.params.logId
        this.handleDetails()
    }
}
</script>
<style lang="scss" src="./Component/index.scss"></style>
<style scoped lang="scss">
.messageInfo-box {
    width: 100%;
    overflow: hidden;

    .main_content {
        width: 100%;
        overflow-x: auto;
        height: 73vh;
        background: #F5F5F5;

    }

    .show_main_content {
        height: calc(100vh - 50px);
        width: 100%;
        overflow-x: auto;
        background: #F5F5F5;
    }
}

.main_cont {
    background: #fff;
    height: 20px;
    padding: 16px;
    text-align: left;
    margin-bottom: 8px;

    .p_1 {
        margin: 0px 0px 8px 0px;
        color: #999999;
        font-size: 14px;
    }

    .p_2 {
        display: flex;
        justify-content: space-between;
        margin: 0px;

        .span_left {
            color: #000000;
            font-size: 16px;
            font-weight: 600;
        }
    }
}

.cont_img {
    display: flex;
    flex-wrap: wrap;
}

.img {
    margin: 6px 6px;
    border-radius: 5px;
}

.see_more {
    width: 60px;
    height: 60px;
    border: 1px solid #EEEEEE;
    border-radius: 5px;
    line-height: 60px;
    text-align: center;
    margin: 5px 5px;
}


.main_bottom {
    padding: 24px 16px 20px 16px;
    display: flex;
    justify-content: space-around;

    .div_border {
        border: 1px solid #D4D6D9;
        padding: 10px 25px;
        border-radius: 4px;
        margin: 0px 15px;
        font-size: 18px;
    }
}

.bottom_btn {
    background: #3A72C6;
    margin: 20px 0px;
    width: 136px;
    border-radius: 10px
}

.check_opinion {
    text-align: left;
    margin-top: 8px;
    background: #fff;
    padding: 10px 16px;
    font-size: 16px;
    color: #333333;

    .input_style {
        margin-top: 8px;
        padding: 8px;
        border-radius: 5px;
        background-color: #F8FAFF;
        font-weight: 400;
        margin-bottom: 8px;
    }

    .remark_bottom {
        margin-top: 8px;
    }
}

.money_style {
    margin-top: 8px;
    font-size: 16px;
    font-weight: 400;
    color: #333333;

    .van-cell {
        &::before {
            position: absolute;
            left: 8px;
            color: #ee0a24;
            font-size: 14px;
            content: '*';
        }
    }
}

.money_style1 {
    margin-top: 8px;
    font-size: 16px;
    font-weight: 400;
    color: #333333;
}
.color_orange{
    color: #F7B500 !important;
}
.color_blue{
    color: #4871C0 !important;
}
.color_red{
    color: #FF2D2D !important;
}
</style>

