<template>
  <div class="main">
    <headerNav leftNav=" " :title="'任务结果上传'" @leftClick="back"></headerNav>
    <main class="content-main">
      <div class="upload-content">
        <div class="upload-content-left">
          <div class="icon">*</div>
          <div class="text">附件</div>
        </div>
        <van-uploader v-model="fileList" :before-read="beforeReadFile" :after-read="afterReadFile" accept=".xlsx,.xls,.docx">
          <div class="upload-content-right">
            <div class="file">{{ fileInfo.name || "请选择" }}</div>
            <div class="img">
              <img src="@/assets/task-management/task-setup-finance-arrows.png" alt="" />
            </div>
          </div>
        </van-uploader>
      </div>
      <!-- 提示 -->
      <div class="upload-hint">
        <div class="upload-hint-icon">
          <img src="@/assets/task-management/task-board-upload-hint-icon.png" alt="" />
        </div>
        <div class="upload-hint-text">
          注：任务审核将以系统提交时间以及综述错误处进行评分，满分<span class="count">100</span>，延迟提交<span>2分/天</span>，错误<span>1分/处</span>。
          <div>请选择.xls/.xlsx/.docx格式文件上传</div>
        </div>
      </div>
    </main>
    <!-- 提交 -->
    <div class="footer-upload">
      <van-button class="btn" type="info" @click="submitUploadFile" :loading="submitLoading">提交</van-button>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import api from "@/api/task-management/finance.js";

export default {
  data() {
    return {
      // 上传文件列表
      fileList: [],
      // 上传的文件信息
      fileInfo: {},
      // 上传后返回的文件地址
      fileAddress: "",
      // 提交按钮loading
      submitLoading: false
    };
  },
  created() { },
  methods: {
    back() {
      this.$router.go(-1);
    },
    // 上传前处理
    beforeReadFile(file) {
      let typeList = ['xlsx','xls','docx']
      let type = file.name.split('.')[1]
      if(typeList.includes(type)){
        this.fileInfo = file;
      }else{
        Toast.fail("只能支持.xls/.xlsx/.docx格式的文件");
        this.fileInfo = {}
      }
    },
    // 上传后处理
    afterReadFile(file) {
    },
    // 上传到oss服务器
    async upLoadFile(file) {
      let formData = new FormData();
      formData.append("files", file);
      formData.append("name", "files");
      formData.append("dir", "oss");
      api
        .upLoad(formData)
        .then((res) => {
          return res;
        })
        .then((res) => {
          let obj = {
            fileList: [{ fileName: this.fileInfo.name, fileUrl: res.url }],
            termSlug: "market_summarize",
          };
          this.submitLoading = true
            api.taskJzResultSubmit(obj).then((res) => {
              if (res.code == 200) {
                Toast.success("上传成功");
                setTimeout(() => {
                  this.back();
                  this.submitLoading = false
                }, 1000);
              }
            }).catch(() =>{
              this.submitLoading = false
            });
        }).catch(() => {
        })
    },
    // 提交
    async submitUploadFile() {
      if (!this.fileInfo.name) {
        Toast.fail("请上传文件后再提交");
        return;
      }
      this.submitLoading = true
      this.upLoadFile(this.fileInfo);
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  .content-main {
    background: #f5f5f5;
    height: calc(100vh - 50px);
    overflow: auto;
  }

  .upload-content {
    height: 24px;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;

    .upload-content-left {
      display: flex;

      .icon {
        color: #ff6259;
        font-size: 14px;
      }

      .text {
        color: #333333;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
      }
    }

    .upload-content-right {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      color: #999999;

      .img {
        img {
          width: 16px;
          height: 16px;
          vertical-align: middle;
        }
      }
      .file {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 280px;
      }
    }
  }

  .upload-hint {
    display: flex;
    align-items: flex-start;
    padding: 16px 12px;

    .upload-hint-icon {
      margin-right: 4px;
      margin-top: -2px;

      img {
        width: 14px;
        height: 14px;
        vertical-align: middle;
      }
    }

    .upload-hint-text {
      font-size: 12px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      color: #545454;
      text-align: left;
      width: 300px;

      span {
        color: #ff6157;
        font-weight: 500;
      }

      .count {
        color: #545454;
      }
    }
  }

  .footer-upload {
    height: 66px;
    line-height: 50px;
    width: 100%;
    background: #fff;
    box-shadow: 0px -1px 8px 0px rgba(0, 0, 0, 0.04);
    text-align: center;
    position: fixed;
    bottom: 0px;
    left: 0px;

    .btn {
      background: #3975c6;
      color: #ffffff;
      border-radius: 4px;
      font-size: 16px;
      font-weight: 500;
      width: 340px;
    }
  }
}
</style>
 