// php
import Vue from "vue"
import * as API from "../index.js";
import {
  buildParam
} from "@/utils/index"
const BaseApi = process.env.VUE_APP_BASE_API
const SysApi = process.env.VUE_APP_SYS_API

// 用户切换角色
const changeRoleInfo = "/task/taskMain/changeRoleInfo"
// 查询用户基本信息
const getRole = "/backend/sys-user/r01"
// 查询对应角色权限
const role = "/task/taskMain/role"

export default {
  // 用户切换角色
  changeRoleInfoApi: (params) => {
    return API.GET(`${BaseApi}${changeRoleInfo}`, params)
  },
  // 查询用户基本信息
  getRoleApi: (params) => {
    buildParam(params)
    return API.POST(`${SysApi}${getRole}`, params)
  },
  // 根据登录人查询角色等级
  roleApi: (params) => {
    return API.GET(`${BaseApi}${role}`, params)
  }
}
