<template>
  <div class="main">
    <headerNav leftNav=" " :title="'审批'" @leftClick="back"></headerNav>
    <main ref="mainContentDiv" class="content-main">
      <div class="content" ref="mainContent" v-if="listData.length">
        <div class="content-element" v-for="(item, index) in listData" :key="index">
          <!-- 日期 -->
          <div class="content-element-date">{{ item.createDate2 }}</div>
          <!-- 审批内容 -->
          <div class="content-element-main" @click="onClickDetails(item)">
            <div class="content-element-main-top">
              <div class="content-element-main-top-left" :class="item.termId">{{ item.termName }}</div>
              <div class="content-element-main-top-center">{{ '任务成果审核' }}</div>
              <div v-if="item.finalStatus == '0'" class="content-element-main-top-right content-element-main-top-right-orange">待审核</div>
              <div v-if="item.finalStatus == '1'" class="content-element-main-top-right content-element-main-top-right-green">已通过</div>
              <div v-if="item.finalStatus == '2'" class="content-element-main-top-right content-element-main-top-right-red">已驳回</div>
              <div v-if="item.finalStatus == '3'" class="content-element-main-top-right content-element-main-top-right-gay">已失效</div>
            </div>
            <div class="content-element-main-center">
              提交人：
              <span>{{ item.userName }}</span>
            </div>
            <div class="content-element-main-bottom">
              <div>查看详情</div>
              <img src="@/assets/task-management/task-setup-finance-arrows.png" alt />
            </div>
          </div>
        </div>
      </div>
      <!-- 暂无数据 -->
      <div v-if="!listData.length && !isData" class="no-data-bg">
        <div class="no-data" >
          <img src="@/assets/task-management/task-setup-nodata2.png" alt />
          <div>暂无审批</div>
        </div>
       </div>
    </main>
  </div>
</template>

<script>
import { Toast } from "vant";
import api from "@/api/task-management/inquiry.js";
import { timeFormat } from "@/utils/timeCompute";
import api2 from "@/api/task-management/finance.js";

export default {
  data() {
    return {
      listData: [],
      isData:true
    };
  },
  created() {
    this.getCheckList();
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    // 查询审批列表
    async getCheckList() {
      try{
        Toast.loading({
          message: '加载中...',
          forbidClick: true,
        });
        const { data } = await api2.getApproveCheck({
          // ext: "market_summarize",
          Q_reportData_EQ: timeFormat(new Date()).slice(0, -3),
          type: "2",
          taxonomy: "6"
        });
        this.listData = data.approveList;
        this.$nextTick(() => {
          const wrappers = this.$refs.mainContentDiv;
          const wrapper = this.$refs.mainContent;
          wrappers.scrollTop = wrapper?wrapper.clientHeight : 0
        });
      }finally {
        Toast.clear()
        this.isData = this.listData.length
      }
      
    },
    // 查看审批详情
    onClickDetails(item) {
      localStorage.setItem("TASK_UPLOAD_RECORD", JSON.stringify(item));
      this.$router.push({
        path: "/task-management/setup/inquiry/details/examine-details"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.main::v-deep {
  background: #f5f5f5;
  .content-main{
    height: calc(100vh - 50px);
    overflow: auto;
  }
  .no-data-bg{
    height: calc(100vh - 190px);
    background: #fff;
    padding-top: 140px;
    display: flex;
    justify-content: center;
  } 
  .no-data {
    width: 160px;
    height: 150px;
    img {
      width: 100%;
      height: 100%;
    }

    div {
      font-size: 16px;
      font-family: Bahnschrift, Bahnschrift;
      font-weight: 400;
      color: rgba(51, 51, 51, 0.5);
    }
  }
  .content {
    margin-top: 10px;
   
    .content-element {
      .content-element-date {
        font-size: 13px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #8c8c8c;
        margin: 7px 0px;
      }

      .content-element-main {
        background: #ffffff;
        border-radius: 8px;
        padding: 18px 12px 8px;
        margin-bottom: 16px;

        .content-element-main-top {
          display: flex;
          align-items: center;

          .content-element-main-top-left {
            width: 72px;
            height: 24px;
            line-height: 25px;
            border-radius: 4px;
            font-size: 16px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            margin-right: 4px;
          }

          .overview_report {
            color: #0e8bff;
            background: rgba(14, 139, 255, 0.1);
          }

          .site_penalty,
          .balance_profit {
            color: #ff6157;
            background: rgba(255, 97, 87, 0.1);
          }

          .settle_accounts_profit {
            color: #17ca5e;
            background: rgba(23, 202, 94, 0.1);
          }

          .site_routing_inspection {
            color: #cc6afb;
            background: rgba(204, 106, 251, 0.1);
          }

          .estimate_profit {
            color: #ff8c21;
            background: rgba(255, 140, 33, 0.1);
          }

          .content-element-main-top-center {
            font-size: 18px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            color: #1d2129;
            height: 24px;
            line-height: 25px;
          }

          .content-element-main-top-right {
            width: 60px;
            height: 24px;
            line-height: 25px;
            border-radius: 4px;
            margin-left: auto;
            font-size: 15px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            color: #fff;
          }

          .content-element-main-top-right-green {
            background: #17ca5e;
          }

          .content-element-main-top-right-red {
            background: #ff6157;
          }

          .content-element-main-top-right-orange {
            background: #ff8c21;
          }

          .content-element-main-top-right-gay {
            background: #999999;
          }
        }

        .content-element-main-center {
          font-size: 16px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #545454;
          text-align: left;
          margin: 14px 0px;

          span {
            color: #1d2129;
          }
        }

        .content-element-main-bottom {
          border-top: 1px solid #ebedf0;
          display: flex;
          justify-content: space-between;
          font-size: 16px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #545454;
          padding-top: 10px;

          img {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }
}
</style>
