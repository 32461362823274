<template>
  <div class="main">
    <van-sticky class="topMenu">
      <headerNav leftNav=" " :title="navTitle" @leftClick="back"></headerNav>
    </van-sticky>

    <main>
      <van-form @submit="confirmAdd">
      <div class="content">
        <!-- form表单 -->
        <van-cell-group class="cell-group-center">
          <template v-for="(item, FormIndex) in formList">

            <van-field v-if="item.require" :key="FormIndex" v-model="item.value" :label="item.label" label-width="140px"
              :rules="[{ required: true,pattern:item.pattern?item.pattern:null,message:item.message?item.message:'请输入' }]" type="digit" placeholder="请输入" input-align="right" @input="handleInput(FormIndex,$event)" >
              <i slot="left-icon" style="color:red">*</i>
              <template #button> <span style="color: #1D2129;">{{ item.unit }}</span> </template>
            </van-field>

            <van-cell v-if="!item.require" :key="FormIndex"
              :title="item.label + (item.fileType == 'image' ? `(${item.value ? item.value.length:0})` : '')"
              :value="item.value + (item.unit || '')" :class="item.class" title-class="cell-group-title" value-class="cell-group-right">
              <template #default v-if="item.fileType">
                <!-- 市场 -->
                <div class="default-style"  v-if="item.fileType== 'tag'">
                  <div class="step-main-right" v-if="taskData.roleCode == 'ROLE_922446' && taskData.city == 1">
                    <!-- 经理查看-展示市场 -->
                    <div v-if="item.city" class="city">市场</div>
                  </div>
                  <div>{{ item.value }}</div>
                </div>
                <!-- 是否展示完成 -->
                <div class="default-style" v-if="item.fileType == 'status'">
                  <div class="step-main-right" v-if="taskData.monthType == '3'">
                    <!-- 完成率 -->
                    <div v-if="taskData.isAccomplish == '0'" class="green">已完成</div>
                    <div v-if="taskData.isAccomplish == '1'" class="red">未完成</div>
                  </div>
                  <div>{{ item.value }}%</div>
                </div>
                <!-- 文件 -->
                <div class="accessory" v-if="item.fileType == 'file'">
                  <img class="accessory-img" src="@/assets/task-management/task-setup-file-xlsx.png" alt=""  v-if="item.value">
                  <div class="accessory-text" @click="onClickDownFile(item.value)">
                   <div>{{ item.value && item.value.length ? item.value[0].fileName : '-' }}</div>
                  </div>
                </div>
                <!-- 图片 -->
                <div class="img-content" v-if="item.fileType == 'image'">
                  <div v-if="item.value != '-'" :class="(item.value && item.value.length > 3) ? 'img' : ''">
                    <img v-for="(itemUrl, imgIndex) in item.value" :key="imgIndex" :src="itemUrl.fileUrl" alt=""
                      @click="onPreview(itemUrl.fileUrl)">
                  </div>
                  <div v-else>-</div>
                </div>
                <!-- 综述详情 -->
                <div class="from-custom" v-if="item.fileType == 'score'">
                  <div class="from-custom-right">
                    <span :class="item.color">{{ item.value }}</span>{{ item.unit }}
                    <span v-if="item.deduct" class="all-count">(扣<span :class="item.color">{{ item.deduct
                    }}</span>分)</span>
                  </div>
                </div>
              </template>
            </van-cell>

          </template>
        </van-cell-group>

        <!-- 子任务设置 -->
        <van-cell-group class="cell-group-bottom" v-if="taskData.roleCode == 'ROLE_922446' && taskData.city == 1">
          <van-cell title="子任务" title-class="cell-group-title" value-class="cell-group-right">
            <template #default>
              <div class="default-subtask" @click="onClickChildrenTask">
                <div class="count">
                  {{ taskChildrenNum }}
                </div>
                <img class="img" src="@/assets/task-management/task-setup-finance-arrows.png" alt="">
              </div>
            </template>
          </van-cell>
        </van-cell-group>

      </div>
      <!-- 确定 -->
      <div class="footer-add" v-if="taskData.roleCode == 'ROLE_097074' && taskData.termId != 'site_routing_inspection' &&  taskData.termId != 'overview_report'">
          <van-button class="btn" type="info" native-type="submit" :loading="submitLoading">确定</van-button>
        </div>
      </van-form>
    </main>
  </div>
</template>

<script>
import { estimateTaskTarget, estimateTaskTargetManager } from '../data.js'
import { ImagePreview, Toast } from 'vant';
import api from "@/api/task-management/inquiry.js";

export default {
  data() {
    return {
      from: {
        value: ''
      },
      // 指标展示内容
      taskObj: {},
      // 指标详情
      taskData: {},
      formList:[],
      // 导航标题
      navTitle:'',
      // 确定按钮loading
      submitLoading:false,
      // 存放筛选条件
      homeFliter:null,
      // 子任务数量
      taskChildrenNum:0
    }
  },
  created() {
    let role = JSON.parse(localStorage.getItem("USER_ROLE"));
    let taskData = JSON.parse(localStorage.getItem('INQUIRY_TASK_DETAILS'))
    this.taskObj = estimateTaskTargetManager(role.roleCode,taskData.termId,this.taskData.city)
    this.taskData = { ...role, ...taskData }
    this.getTaskNumber()
    this.navTitle = role.roleCode == 'ROLE_097074' ? taskData.termName + `${taskData.termId == 'overview_report' ?'任务详情':'任务设置'}`:'任务详情'
    this.formList =  this.taskObj.formList
    this.formList.map(item =>{
      item.value = this.taskData[item.props]
      if(taskData.termId == 'overview_report'){
        if(item.props == 'finishRate' || item.props == 'accomplish'){
          item.deduct = this.taskData[item.props]
        }else if(item.props == 'taskProfitRate' || item.props == 'task'){
          item.deduct = (this.taskData[item.props] * 2) || 0
        }else{
          item.value = this.taskData[item.props]
        }
      }
      item = {...item,...this.taskData}
    })

  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    handleInput(index,v) {
    this.formList[index].value = v
    this.$forceUpdate();
  },
  // 获取子任务数量
  async getTaskNumber(){
    const {data} = await api.getTaskMainItemNumber(this.taskData.taskId)
    this.taskChildrenNum = data
  },
    // 查看大图
    onPreview(url) {
      ImagePreview({
        images: [url],
        closeable: true,
        showIndex: false
      });
    },
    // 确定
    async confirmAdd(){
      let obj = {
        id:this.taskData.taskMainId,
        taskFine:this.formList[3].value,
        productionJobValue:this.formList[3].value,
        termId:this.taskData.termId,
        approveId:this.taskData.approveId
      }
      this.submitLoading = true

      try{
        const data = await api.updateTaskMainZb(obj)
        if(data.code == 200){
          Toast.success('设置成功！')
          setTimeout(()=>{
            this.back()
          },1000)
        }
      }finally{
        this.submitLoading = false
      }
    },
    downloadFile(fileUrl) {
      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = '';
      link.click();
    },
    // 下载文件
    onClickDownFile(file){
      let pre_prod_host = window.location.host == "m.hejiahejiazhuang.com" ?process.env.VUE_APP_BASE_RUL_PROD : process.env.VUE_APP_BASE_RUL_PRE
      let url = process.env.NODE_ENV === "production" ?pre_prod_host+process.env.VUE_APP_SYS_API: process.env.VUE_APP_SYS_API
      this.downloadFile(url+`/tools/oss/ali/downOSSFile/public?fileName=${file[0].fileUrl.split('/').pop()}&dir=oss`)
    },
    // 子任务
    onClickChildrenTask() {
      this.$router.push({ path: '/task-management/setup/inquiry/details/subtask-list' })
    }
  }
}
</script>

<style lang="scss" scoped>
.main::v-deep {
  background: #f5f5f5;
  height: 100vh;
  .van-field__error-message{
    text-align: right;
  }
  .content {
    height: calc(100vh - 60px);
    overflow: auto;

    .van-field {
      font-size: 16px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      color: #1d2129;
    }

    .van-cell__value {
      overflow: visible;
      font-size: 18px;
      color: #333;
    }
    .van-field__label{
      color: #333;
    }

    .van-cell {
      padding: 15px 16px;
    }

    .cell-group-top {
      border-radius: 0px 0px 8px 8px;
      overflow: hidden;

    }
    .default-style {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .step-main-right {
          div {
            width: 60px;
            height: 24px;
            line-height: 24px;
            border-radius: 4px;
            margin-left: auto;
            font-size: 15px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            color: #ffffff !important;
            text-align: center;
            margin-right: 5px;
          }

          .green {
            background: #17ca5e;
          }

          .red {
            background: #ff6157;
          }

          .city {
            width: 44px;
            height: 22px;
            line-height: 22px;
            background: rgba(242, 109, 12, 0.1);
            color: #f26d0c !important;
            font-size: 18px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            border-radius: 3px;
          }
        }
      }
    .cell-group-center {
      border-radius: 8px;
      overflow: hidden;
      margin-bottom: 16px;

      .accessory {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .accessory-img {
          width: 20px;
          height: 24px;
          margin-right: 4px;
        }

        .accessory-text {
          font-size: 14px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          color: #0084f4;
        }
      }

      .img-content {
        width: 252px;

        .img {
          display: flex;
          flex-wrap: wrap;
        }

        img {
          width: 72px;
          height: 72px;
          border-radius: 4px;
          margin: 0px 0px 12px 12px;
        }
      }

      .from-custom {
        display: flex;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #999999;
        align-items: center;
        justify-content: flex-end;

        .from-custom-right {
          font-size: 18px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          color: #333333;
          display: flex;
          align-items: center;

          .red {
            color: #ff6157;
          }

          .orange {
            color: #ff8c21;
          }

          .blue {
            color: #3da2ff;
          }

          .all-count {
            margin-left: 2px;
          }
        }
      }
    }

    .cell-group-bottom {
      .cell-group-title {
        color: #1d2129;
      }

      .default-subtask {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .img {
          width: 16px;
          height: 16px;
          vertical-align: middle;
        }

        .count {
          font-size: 20px;
          font-family: Bahnschrift, Bahnschrift;
          font-weight: normal;
          color: #1d2129;
          margin-bottom: -2px;
        }
      }
    }

    .cell-group-title {
      font-size: 16px;
      font-family: PingFang TC, PingFang TC;
      font-weight: 400;
      color: #999999;
      text-align: left;
    }
    
    .cell-group-right {
      font-size: 18px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #333;
    }
    .interval{
      border-bottom: 16px solid #f5f5f5;
    }
  }
  .footer-add {
    height: 66px;
    line-height: 50px;
    width: 100%;
    background: #fff;
    box-shadow: 0px -1px 8px 0px rgba(0, 0, 0, 0.04);
    text-align: center;
    position: fixed;
    bottom: 0px;
    left: 0px;
    padding-bottom: 10px;


    .btn {
      background: #3975c6;
      color: #ffffff;
      border-radius: 4px;
      font-size: 16px;
      font-weight: 500;
      width: 340px;
    }
  }
}
</style>