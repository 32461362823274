// 任务列表
let taskResults = [
  {
    label: '预估利润',
    value: 'estimate_profit',
    leftUnit: '%',
    rightUnit: '%',
    taskTarget1: '实际利润率',
    field1:'reality',
    taskTarget2: '任务利润率',
    field2:'task',
    examineDetails: [  // 审批详情展示内容
      { label: '任务项', props: 'termName',fileType: 'normal' },
      { label: '任务利润率', props: 'targetValue', unit: '%',fileType: 'normal' },
      { label: '实际利润率', props: 'finishRate', unit: '%',fileType: 'normal' },
      { label: '附件', props: 'fileInfoList', fileType: 'file' },
    ]
  },
  {
    label: '结算利润',
    value: 'settle_accounts_profit',
    leftUnit: '%',
    rightUnit: '%',
    taskTarget1: '实际利润率',
    field1:'reality',
    taskTarget2: '任务利润率',
    field2:'task',
    examineDetails: [  // 审批详情展示内容
    { label: '任务项', props: 'termName',fileType: 'normal' },
    { label: '任务利润率', props: 'targetValue', unit: '%',fileType: 'normal' },
    { label: '实际利润率', props: 'finishRate', unit: '%',fileType: 'normal' },
    { label: '附件', props: 'fileInfoList', fileType: 'file' },
    ]
  },
  {
    label: '结转利润',
    value: 'balance_profit',
    leftUnit: '%',
    rightUnit: '%',
    taskTarget1: '实际利润率',
    field1:'reality',
    taskTarget2: '任务利润率',
    field2:'task',
    examineDetails: [  // 审批详情展示内容
    { label: '任务项', props: 'termName',fileType: 'normal' },
    { label: '任务利润率', props: 'taskFine', unit: '%',fileType: 'normal' },
    { label: '实际利润率', props: 'realityProfitRate', unit: '%',fileType: 'normal' },
    { label: '附件', props: 'fileInfoList', fileType: 'file' },
    ]
  },
  {
    label: '工地巡检',
    value: 'site_routing_inspection',
    leftUnit: '%',
    rightUnit: '次',
    taskTarget1: '完成率',
    field1:'accomplish',
    taskTarget2: '实际完成',
    field2:'reality',
    taskTarget3: '应巡检',
    field3:'task',
    examineDetails: [  // 审批详情展示内容
      { label: '任务项', props: 'termName',fileType: 'normal' },
      { label: '应巡检次数', props: 'taskFine', unit: '次',fileType: 'normal' },
      { label: '实际完成', props: 'realityAccomplish', unit: '次',fileType: 'normal' },
      { label: '附件', props: 'fileInfoList', fileType: 'image' },
    ]
  },
  {
    label: '工地罚金',
    value: 'site_penalty',
    leftUnit: '%',
    rightUnit: '元',
    taskTarget1: '完成率',
    field1:'accomplish',
    taskTarget2: '实际完成',
    field2:'reality',
    taskTarget3: '任务罚金',
    field3:'task',
    examineDetails: [  // 审批详情展示内容
      { label: '任务项', props: 'termName',fileType: 'normal' },
      { label: '任务罚金', props: 'taskFine', unit: '元',fileType: 'normal' },
      { label: '实际完成', props: 'realityAccomplish', unit: '元',fileType: 'normal' },
      { label: '附件', props: 'fileInfoList', fileType: 'image' },
    ]
  },
  {
    label: '综述报告',
    value: 'overview_report',
    leftUnit: '分',
    taskTarget1: '最终得分',
    field1:'reality',
    isSingleIndicator: true,
    examineDetails: [  // 审批详情展示内容
      { label: '任务项', props: 'termName',fileType: 'normal' },
      { label: '附件', props: 'fileInfoList',fileType: 'image' },
      { label: '错漏处(1处1分)', props: 'errorCount', deduct: 2, unit: '处',fileType: 'score' },
      { label: '延期提交(1次2分)', props: 'delayCount', deduct: 2, unit: '次',fileType: 'score' },
      { label: '最终得分(满分100)', props: 'score', unit: '分',fileType: 'normal' },
    ]
  },
]
export function estimateOutputTarget (value) {
  let data = {}
  taskResults.filter(item => {
    if (item.value == value) {
      data = item
    }
  })
  return data
}

// ======================预审总监================================
// 任务设置
let taskSetting = [
  {
    label: '预估利润',
    value: 'estimate_profit',
    formList: [
      { label: '任务时间', props: 'taskTime' },
      { label: '市场', props: 'marketName' },
      { label: '完成率', props: 'accomplish', unit: '%', status: '1',class:'interval',fileType:'status' },
      { label: '任务利润率', props: 'task', unit: '%',require: true  },
      { label: '实际利润率', props: 'reality', unit: '%' },
    ]
  },
  {
    label: '结算利润',
    value: 'settle_accounts_profit',
    formList: [
      { label: '任务时间', props: 'taskTime' },
      { label: '市场', props: 'marketName' },
      { label: '完成率', props: 'accomplish', unit: '%', status: '1',class:'interval',fileType:'status' },
      { label: '任务利润率', props: 'task', unit: '%',require: true  },
      { label: '实际利润率', props: 'reality', unit: '%' },
    ]
  },
  {
    label: '结转利润',
    value: 'balance_profit',
    formList: [
      { label: '任务时间', props: 'taskTime' },
      { label: '市场', props: 'marketName' },
      { label: '完成率', props: 'accomplish', unit: '%', status: '1',class:'interval',fileType:'status' },
      { label: '任务利润率', props: 'task', unit: '%',require: true  },
      { label: '实际利润率', props: 'reality', unit: '%' },
    ]
  },
  {
    label: '工地巡检',
    value: 'site_routing_inspection',
    formList: [
      { label: '任务时间', props: 'taskTime' },
      { label: '市场', props: 'marketName' },
      { label: '完成率', props: 'accomplish', unit: '%', status: '1',class:'interval',fileType:'status' },
      { label: '应巡检次数', props: 'task', unit: '次'  },
      { label: '实际完成', props: 'reality', unit: '次' },
      { label: '附件', props: 'fileList', fileType: 'image'},
    ]
  },
  {
    label: '工地罚金',
    value: 'site_penalty',
    formList: [
      { label: '任务时间', props: 'taskTime' },
      { label: '市场', props: 'marketName' },
      { label: '完成率', props: 'accomplish', unit: '%', status: '1',class:'interval',fileType:'status' },
      { label: '任务罚金', props: 'task', unit: '元',require: true,pattern: /^([1-9][0-9]{0,5}|0)$/,message:'请输入最大6位的正整数'  },
      { label: '实际完成', props: 'reality', unit: '元' },
      { label: '附件', props: 'fileList', fileType: 'image'},
    ]
  },
  {
    label: '综述报告',
    value: 'overview_report',
    formList: [
      { label: '任务时间', props: 'taskTime' },
      { label: '市场', props: 'marketName' },
      { label: '提交时间', props: 'taskSubmitTime' },
      {label: '附件',props:'fileList', fileType: 'image'},
      { label: '错漏处(1处1分)', props: 'accomplish', color: 'red', fileType: 'score', unit: '处',deduct:0 },
      { label: '延期提交(1次2分)', props: 'task', color: 'orange', fileType: 'score', unit: '次',deduct:0 },
      { label: '最终得分(满分100)', props: 'reality', color: 'blue', fileType: 'score', unit: '分' },
    ]
  },
]

// ====================预审经理=================================
// 市场
let taskSettingManager = [
  {
    label: '预估利润',
    value: 'estimate_profit',
    formList: [
      { label: '任务时间', props: 'taskTime' },
      { label: '任务项', props: 'termName',city:'市场',fileType:'tag' },
      { label: '执行者', props: 'userName' },
      { label: '完成率', props: 'accomplish', unit: '%', status: '1',class:'interval',fileType:'status' },
      { label: '任务利润率', props: 'task', unit: '%' },
      { label: '实际利润率', props: 'reality', unit: '%' },
    ],
  },
  {
    label: '结算利润',
    value: 'settle_accounts_profit',
    formList: [
      { label: '任务时间', props: 'taskTime' },
      { label: '任务项', props: 'termName',city:'市场',fileType:'tag' },
      { label: '执行者', props: 'userName' },
      { label: '完成率', props: 'accomplish', unit: '%', status: '1',class:'interval',fileType:'status' },
      { label: '任务利润率', props: 'task', unit: '%' },
      { label: '实际利润率', props: 'reality', unit: '%' },
    ],
  },
  {
    label: '结转利润',
    value: 'balance_profit',
    formList: [
      { label: '任务时间', props: 'taskTime' },
      { label: '任务项', props: 'termName',city:'市场',fileType:'tag' },
      { label: '执行者', props: 'userName' },
      { label: '完成率', props: 'accomplish', unit: '%', status: '1',class:'interval',fileType:'status' },
      { label: '任务利润率', props: 'task', unit: '%' },
      { label: '实际利润率', props: 'reality', unit: '%' },
    ],
  },
  {
    label: '工地巡检',
    value: 'site_routing_inspection',
    formList: [
      { label: '任务时间', props: 'taskTime' },
      { label: '任务项', props: 'termName',city:'市场',fileType:'tag' },
      { label: '执行者', props: 'userName' },
      { label: '完成率', props: 'accomplish', unit: '%', status: '1',class:'interval',fileType:'status' },
      { label: '应巡检工地次数', props: 'task', unit: '次' },
      { label: '实际巡检次数', props: 'reality', unit: '次' },
    ],
  },
  {
    label: '工地罚金',
    value: 'site_penalty',
    formList: [
      { label: '任务时间', props: 'taskTime' },
      { label: '任务项', props: 'termName',city:'市场',fileType:'tag' },
      { label: '执行者', props: 'userName' },
      { label: '完成率', props: 'accomplish', unit: '%', status: '1',class:'interval',fileType:'status' },
      { label: '任务罚金', props: 'task', unit: '元' },
      { label: '实际完成', props: 'reality', unit: '元'},
    ]
  },
  {
    label: '综述报告',
    value: 'overview_report',
    formList: [
      { label: '任务时间', props: 'taskTime' },
      { label: '任务项', props: 'termName',city:'市场',fileType:'tag' },
      { label: '执行者', props: 'userName',class:'interval' },
      { label: '提交时间', props: 'taskSubmitTime' },
      {label: '附件',props:'fileList', fileType: 'image'},
      { label: '错漏处(1处1分)', props: 'accomplish', color: 'red', fileType: 'score', unit: '处',deduct:0 },
      { label: '延期提交(1次2分)', props: 'task', color: 'orange', fileType: 'score', unit: '次',deduct:0 },
      { label: '最终得分(满分100)', props: 'reality', color: 'blue', fileType: 'score', unit: '分'},
    ]
  },
]
// 个人
let taskSettingManager2 = [
  {
    label: '预估利润',
    value: 'estimate_profit',
    formList: [
      { label: '任务时间', props: 'taskTime' },
      { label: '任务项', props: 'termName',city:'市场',fileType:'tag' },
      { label: '完成率', props: 'accomplish', unit: '%', status: '1',class:'interval',fileType:'status' },
      { label: '任务利润率', props: 'task', unit: '%' },
      { label: '实际利润率', props: 'reality', unit: '%' },
    ],
  },
  {
    label: '结算利润',
    value: 'settle_accounts_profit',
    formList: [
      { label: '任务时间', props: 'taskTime' },
      { label: '任务项', props: 'termName',city:'市场',fileType:'tag' },
      { label: '完成率', props: 'accomplish', unit: '%', status: '1',class:'interval',fileType:'status' },
      { label: '任务利润率', props: 'task', unit: '%' },
      { label: '实际利润率', props: 'reality', unit: '%' },
    ],
  },
  {
    label: '结转利润',
    value: 'balance_profit',
    formList: [
      { label: '任务时间', props: 'taskTime' },
      { label: '任务项', props: 'termName',city:'市场',fileType:'tag' },
      { label: '完成率', props: 'accomplish', unit: '%', status: '1',class:'interval',fileType:'status' },
      { label: '任务利润率', props: 'task', unit: '%' },
      { label: '实际利润率', props: 'reality', unit: '%' },
    ],
  },
  {
    label: '工地巡检',
    value: 'site_routing_inspection',
    formList: [
      { label: '任务时间', props: 'taskTime' },
      { label: '任务项', props: 'termName',city:'市场',fileType:'tag' },
      { label: '完成率', props: 'accomplish', unit: '%', status: '1',class:'interval',fileType:'status' },
      { label: '应巡检工地次数', props: 'task', unit: '次' },
      { label: '实际巡检次数', props: 'reality', unit: '次' },
      { label: '附件', props: 'fileList', fileType: 'image'},

    ],
  },
  {
    label: '工地罚金',
    value: 'site_penalty',
    formList: [
      { label: '任务时间', props: 'taskTime' },
      { label: '任务项', props: 'termName',city:'市场',fileType:'tag' },
      { label: '完成率', props: 'accomplish', unit: '%', status: '1',class:'interval',fileType:'status' },
      { label: '任务罚金', props: 'task', unit: '元' },
      { label: '实际完成', props: 'reality', unit: '元'},
      { label: '附件', props: 'fileList', fileType: 'image'},
    ]
  },
  {
    label: '综述报告',
    value: 'overview_report',
    formList: [
      { label: '任务时间', props: 'taskTime' },
      { label: '任务项', props: 'termName',city:'市场',fileType:'tag' },
      { label: '提交时间', props: 'taskSubmitTime',class:'interval' },
      {label: '附件',props:'fileList', fileType: 'image'},
      { label: '错漏处(1处1分)', props: 'accomplish', color: 'red', fileType: 'score', unit: '处',deduct:0 },
      { label: '延期提交(1次2分)', props: 'task', color: 'orange', fileType: 'score', unit: '次',deduct:0 },
      { label: '最终得分(满分100)', props: 'reality', color: 'blue', fileType: 'score', unit: '分'},
    ]
  },
]
// ===============预审专员=======================
let taskSettingZooid = [
  {
    label: '预估利润',
    value: 'estimate_profit',
    formList: [
      { label: '任务时间', props: 'taskTime' },
      { label: '任务项', props: 'termName',city:'市场',fileType:'tag' },
      { label: '完成率', props: 'accomplish', unit: '%', status: '1',class:'interval',fileType:'status' },
      { label: '任务利润率', props: 'task', unit: '%' },
      { label: '实际利润率', props: 'reality', unit: '%' },
    ],
  },
  {
    label: '结算利润',
    value: 'settle_accounts_profit',
    formList: [
      { label: '任务时间', props: 'taskTime' },
      { label: '任务项', props: 'termName',city:'市场',fileType:'tag' },
      { label: '完成率', props: 'accomplish', unit: '%', status: '1',class:'interval',fileType:'status' },
      { label: '任务利润率', props: 'task', unit: '%' },
      { label: '实际利润率', props: 'reality', unit: '%' },
    ],
  },
  {
    label: '结转利润',
    value: 'balance_profit',
    formList: [
      { label: '任务时间', props: 'taskTime' },
      { label: '任务项', props: 'termName',city:'市场',fileType:'tag' },
      { label: '完成率', props: 'accomplish', unit: '%', status: '1',class:'interval',fileType:'status' },
      { label: '任务利润率', props: 'task', unit: '%' },
      { label: '实际利润率', props: 'reality', unit: '%' },
      { label: '附件', props: 'fileList', fileType: 'file' },
    ],
  },
  {
    label: '工地巡检',
    value: 'site_routing_inspection',
    formList: [
      { label: '任务时间', props: 'taskTime' },
      { label: '任务项', props: 'termName',city:'市场',fileType:'tag' },
      { label: '完成率', props: 'accomplish', unit: '%', status: '1',class:'interval',fileType:'status' },
      { label: '应巡检工地次数', props: 'task', unit: '次' },
      { label: '实际巡检次数', props: 'reality', unit: '次' },
      { label: '附件', props: 'fileList', fileType: 'image' },
    ],
  },
  {
    label: '工地罚金',
    value: 'site_penalty',
    formList: [
      { label: '任务时间', props: 'taskTime' },
      { label: '任务项', props: 'termName',city:'市场',fileType:'tag' },
      { label: '完成率', props: 'accomplish', unit: '%', status: '1',class:'interval',fileType:'status' },
      { label: '任务罚金', props: 'task', unit: '元' },
      { label: '实际完成', props: 'reality', unit: '元'},
      { label: '附件', props: 'fileList', fileType: 'image' },
    ]
  },
  {
    label: '综述报告',
    value: 'overview_report',
    formList: [
      { label: '任务时间', props: 'taskTime' },
      { label: '任务项', props: 'termName',city:'市场',fileType:'tag',class:'interval' },
      { label: '提交时间', props: 'taskSubmitTime' },
      {label: '附件',props:'fileList', fileType: 'image'},
      { label: '错漏处(1处1分)', props: 'accomplish', color: 'red', fileType: 'score', unit: '处',deduct:0 },
      { label: '延期提交(1次2分)', props: 'task', color: 'orange', fileType: 'score', unit: '次',deduct:0 },
      { label: '最终得分(满分100)', props: 'reality', color: 'blue', fileType: 'score', unit: '分'},
    ]
  },
]

export function estimateTaskTargetManager (code,value,type) {
  let data = {}
  // 总监
  if(code == 'ROLE_097074'){
    taskSetting.filter(item => {
      if (item.value == value) {
        data = item
      }
    })
    return data
  }else if(code == 'ROLE_922446'){ // 经理
    let arr = type == 1 ? taskSettingManager : taskSettingManager2
    arr.filter(item => {
      if (item.value == value) {
        data = item
      }
    })
    return data
  }else{
    taskSettingZooid.filter(item => { //  专员
      if (item.value == value) {
        data = item
      }
    })
    return data
  }
  
}

// 新建子任务
let subTaskAdd = [
  {
    label: '预估利润',
    value: 'estimate_profit',
    taskUser:'执行者',
    taskUserValue:'',
    taskRoate:'任务利润率',
    taskRoateValue:'41',
    fileName:'taskProfitRate',
    unit:'%',
  },
  {
    label: '结算利润',
    value: 'settle_accounts_profit',
    taskUser:'执行者',
    taskUserValue:'',
    taskRoate:'任务利润率',
    taskRoateValue:'41',
    fileName:'taskProfitRate',
    unit:'%'
  },
  {
    label: '结转利润',
    value: 'balance_profit',
    taskUser:'执行者',
    taskUserValue:'',
    taskRoate:'任务利润率',
    taskRoateValue:'43',
    fileName:'taskProfitRate',
    unit:'%'
  },
  {
    label: '工地巡检',
    value: 'site_routing_inspection',
    taskUser:'执行者',
    taskUserValue:'',
  },
  {
    label: '工地罚金',
    value: 'site_penalty',
    taskUser:'执行者',
    taskUserValue:'',
    taskRoate:'任务罚金',
    taskRoateValue:'',
    unit:'元',
    fileName:'taskFine'
  },
  {
    label: '综述报告',
    value: 'overview_report',
    taskUser:'执行者', 
    taskUserValue:'',
  },
]

export function estimateSubTask (value) {
  let data = {}
  subTaskAdd.filter(item => {
    if (item.value == value) {
      data = item
    }
  })
  return data
}

// 子任务详情

let subtaskDetails = [
  {
    label: '预估利润',
    value: 'estimate_profit',
    formList: [
      { label: '执行者', props: 'userName' },
      { label: '完成率', props: 'accomplish', unit: '%', status: '1',class:'interval',fileType:'status' },
      { label: '任务利润率', props: 'taskProfitRate', unit: '%',require:true },
      { label: '实际利润率', props: 'realityProfitRate', unit: '%' },
    ],
    
  },
  {
    label: '结算利润',
    value: 'settle_accounts_profit',
    formList: [
      { label: '执行者', props: 'userName' },
      { label: '完成率', props: 'accomplish', unit: '%', status: '1',class:'interval',fileType:'status' },
      { label: '任务利润率', props: 'taskProfitRate', unit: '%',require:true },
      { label: '实际利润率', props: 'realityProfitRate', unit: '%' },
    ],
  },
  {
    label: '结转利润',
    value: 'balance_profit',
    formList: [
      { label: '执行者', props: 'userName' },
      { label: '完成率', props: 'accomplish', unit: '%', status: '1',class:'interval',fileType:'status' },
      { label: '任务利润率', props: 'taskProfitRate', unit: '%',require:true },
      { label: '实际利润率', props: 'realityProfitRate', unit: '%' },
    ],
  },
  {
    label: '工地巡检',
    value: 'site_routing_inspection',
    formList: [
      { label: '执行者', props: 'userName' },
      { label: '完成率', props: 'finishRate', unit: '%', status: '1',class:'interval',fileType:'status' },
      { label: '应巡检工地次数', props: 'taskProfitRate', unit: '次' },
      { label: '实际巡检次数', props: 'realityProfitRate', unit: '次' },
      { label: '附件', props: 'fileList',fileType: 'image' },
    ],
  },
  {
    label: '工地罚金',
    value: 'site_penalty',
    formList: [
      { label: '执行者', props: 'userName' },
      { label: '完成率', props: 'finishRate', unit: '%', status: '1',class:'interval',fileType:'status' },
      { label: '任务罚金', props: 'taskProfitRate', unit: '元',require:true },
      { label: '实际完成', props: 'realityProfitRate', unit: '元' },
      { label: '附件', props: 'fileList',fileType: 'image' },
    ],
  },
  {
    label: '综述报告',
    value: 'overview_report',
    formList: [
      { label: '任务项', props: 'termName' },
      { label: '执行者', props: 'userName',class:'interval' },
      { label: '提交时间', props: 'taskSubmitTime' },
      { label: '附件', props: 'fileList',fileType: 'image' },
      { label: '错漏处(1处1分)', props: 'finishRate', deduct: 0, color: 'red', fileType: 'score', unit: '处' },
      { label: '延期提交(1次2分)', props: 'taskProfitRate', deduct: 0, color: 'orange', fileType: 'score', unit: '次' },
      { label: '最终得分(满分100)', props: 'realityProfitRate', color: 'blue', fileType: 'score', unit: '分' },
    ],
  },
]

export function subTaskDetails (value) {
  let data = {}
  subtaskDetails.filter(item => {
    if (item.value == value) {
      data = item
    }
  })
  return data
}